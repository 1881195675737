// Generated by ReScript, PLEASE EDIT WITH CARE


function fromString(x) {
  switch (x) {
    case "address" :
        return "address";
    case "audio" :
        return "audio";
    case "bar_code" :
        return "bar_code";
    case "barcode-scanner" :
        return "barcode-scanner";
    case "column-count-insights" :
        return "column-count-insights";
    case "content-image" :
        return "content-image";
    case "currency" :
        return "currency";
    case "date" :
        return "date";
    case "duration" :
        return "duration";
    case "email_address" :
        return "email_address";
    case "heading" :
        return "heading";
    case "icon" :
        return "icon";
    case "illustration" :
        return "illustration";
    case "image" :
        return "image";
    case "json" :
        return "json";
    case "location" :
        return "location";
    case "lookup" :
        return "lookup";
    case "manual-http-request" :
        return "manual-http-request";
    case "multi_select" :
        return "multi_select";
    case "multi_text" :
        return "multi_text";
    case "nearby-places" :
        return "nearby-places";
    case "number" :
        return "number";
    case "paragraph" :
        return "paragraph";
    case "percent" :
        return "percent";
    case "phone_number" :
        return "phone_number";
    case "places" :
        return "places";
    case "qr-code" :
        return "qr-code";
    case "qr-code-reader" :
        return "qr-code-reader";
    case "rating" :
        return "rating";
    case "related-list" :
        return "related-list";
    case "rollup-new" :
        return "rollup-new";
    case "stripe" :
        return "stripe";
    case "switch" :
        return "switch";
    case "text" :
        return "text";
    case "type-created-by" :
        return "type-created-by";
    case "type-created-date" :
        return "type-created-date";
    case "type-created-from-column-view" :
        return "type-created-from-column-view";
    case "type-created-from-row" :
        return "type-created-from-row";
    case "type-created-from-view" :
        return "type-created-from-view";
    case "type-created-in-view" :
        return "type-created-in-view";
    case "type-current-logged-in-user" :
        return "type-current-logged-in-user";
    case "type-file" :
        return "type-file";
    case "type-last-updated-by" :
        return "type-last-updated-by";
    case "type-last-updated-date" :
        return "type-last-updated-date";
    case "type-owned-by" :
        return "type-owned-by";
    case "type-row-json-data" :
        return "type-row-json-data";
    case "type-row-membership" :
        return "type-row-membership";
    case "type-row-membership-history" :
        return "type-row-membership-history";
    case "type-select-view" :
        return "type-select-view";
    case "url" :
        return "url";
    case "video" :
        return "video";
    case "youtube-video" :
        return "youtube-video";
    default:
      return ;
  }
}

function unsafeFromString(x) {
  return x;
}

var textBasedCodes = [
  "text",
  "multi_text",
  "heading",
  "paragraph",
  "url",
  "email_address",
  "phone_number",
  "address",
  "barcode-scanner",
  "bar_code",
  "qr-code",
  "qr-code-reader"
];

var numberBasedCodes = [
  "number",
  "percent",
  "currency"
];

var boolBasedCodes = ["switch"];

var imageBasedCodes = [
  "image",
  "content-image"
];

var dateBasedCodes = [
  "date",
  "type-created-date",
  "type-last-updated-date"
];

var rowBasedCodes = [
  "multi_select",
  "type-created-by",
  "type-last-updated-by",
  "type-owned-by",
  "type-created-from-row",
  "type-created-from-view",
  "type-created-in-view",
  "type-created-from-column-view"
];

var svgBasedCodes = [
  "icon",
  "illustration"
];

var jsonBasedCodes = [
  "type-row-json-data",
  "json"
];

function getHomogeneousCodesForDynamicFiltering(code) {
  if (code === "multi_text" || code === "bar_code" || code === "address" || code === "qr-code" || code === "email_address" || code === "qr-code-reader" || code === "url" || code === "barcode-scanner" || code === "heading" || code === "paragraph" || code === "text" || code === "phone_number") {
    return textBasedCodes;
  } else if (code === "switch") {
    return boolBasedCodes;
  } else if (code === "illustration" || code === "icon") {
    return svgBasedCodes;
  } else if (code === "type-row-json-data" || code === "json") {
    return jsonBasedCodes;
  } else if (code === "image" || code === "content-image") {
    return imageBasedCodes;
  } else if (code === "type-created-in-view" || code === "type-created-by" || code === "type-owned-by" || code === "type-created-from-column-view" || code === "type-last-updated-by" || code === "multi_select" || code === "type-created-from-row" || code === "type-created-from-view") {
    return rowBasedCodes;
  } else if (code === "type-file") {
    return ["type-file"];
  } else if (code === "type-row-membership-history" || code === "related-list" || code === "manual-http-request" || code === "stripe" || code === "type-row-membership" || code === "column-count-insights") {
    return [];
  } else if (code === "type-current-logged-in-user" || code === "rollup-new" || code === "lookup") {
    return [];
  } else if (code === "location") {
    return ["location"];
  } else if (code === "type-select-view") {
    return ["type-select-view"];
  } else if (code === "youtube-video") {
    return ["youtube-video"];
  } else if (code === "number" || code === "currency" || code === "percent") {
    return numberBasedCodes;
  } else if (code === "duration") {
    return ["duration"];
  } else if (code === "audio") {
    return ["audio"];
  } else if (code === "nearby-places") {
    return ["nearby-places"];
  } else if (code === "video") {
    return ["video"];
  } else if (code === "places") {
    return ["places"];
  } else if (code === "rating") {
    return ["rating"];
  } else {
    return dateBasedCodes;
  }
}

function getDataType(componentTypeCode) {
  if (componentTypeCode === "multi_text" || componentTypeCode === "rating" || componentTypeCode === "places" || componentTypeCode === "bar_code" || componentTypeCode === "illustration" || componentTypeCode === "address" || componentTypeCode === "qr-code" || componentTypeCode === "duration" || componentTypeCode === "email_address" || componentTypeCode === "qr-code-reader" || componentTypeCode === "url" || componentTypeCode === "barcode-scanner" || componentTypeCode === "youtube-video" || componentTypeCode === "type-select-view" || componentTypeCode === "location" || componentTypeCode === "heading" || componentTypeCode === "paragraph" || componentTypeCode === "text" || componentTypeCode === "json" || componentTypeCode === "icon" || componentTypeCode === "phone_number") {
    return "string";
  } else if (componentTypeCode === "switch") {
    return "boolean";
  } else if (componentTypeCode === "image" || componentTypeCode === "content-image") {
    return "image";
  } else if (componentTypeCode === "type-current-logged-in-user" || componentTypeCode === "type-row-membership-history" || componentTypeCode === "nearby-places" || componentTypeCode === "related-list" || componentTypeCode === "manual-http-request" || componentTypeCode === "stripe" || componentTypeCode === "type-row-membership" || componentTypeCode === "column-count-insights" || componentTypeCode === "type-row-json-data") {
    return "no-data";
  } else if (componentTypeCode === "type-created-in-view" || componentTypeCode === "type-created-by" || componentTypeCode === "type-owned-by" || componentTypeCode === "type-created-from-column-view" || componentTypeCode === "type-last-updated-by" || componentTypeCode === "multi_select" || componentTypeCode === "type-created-from-row" || componentTypeCode === "type-created-from-view") {
    return "table-link";
  } else if (componentTypeCode === "type-file") {
    return "file";
  } else if (componentTypeCode === "lookup") {
    return "column-link";
  } else if (componentTypeCode === "number" || componentTypeCode === "currency" || componentTypeCode === "percent") {
    return "number";
  } else if (componentTypeCode === "rollup-new") {
    return "rollup";
  } else if (componentTypeCode === "audio") {
    return "audio";
  } else if (componentTypeCode === "video") {
    return "video";
  } else {
    return "date";
  }
}

export {
  fromString ,
  unsafeFromString ,
  getHomogeneousCodesForDynamicFiltering ,
  getDataType ,
}
/* No side effect */
