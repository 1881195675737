// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSDateTime from "../../relay-appsync/src/RelayAppSync__AWSDateTime.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"view_id":{"c":"DecidrNodeId.RelayAppSync"},"to":{"c":"RelayAppSync.AWSDateTime"},"from":{"c":"RelayAppSync.AWSDateTime"},"":{"f":""}}};

var fragmentConverterMap = {
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.parse,
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": {
    "plural": true
  },
  "name": "SpacesBlockRowMembershipList_rowMembership",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "View",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "args": null,
      "kind": "FragmentSpread",
      "name": "SpacesBlockRowMembershipListItem_rowMembership"
    }
  ],
  "type": "RowMembership",
  "abstractKey": null
};

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* DecidrNodeId Not a pure module */
