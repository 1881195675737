// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Blocks__View from "@pageblocks/blocks/src/Blocks__View.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Blocks__Image from "@pageblocks/blocks/src/Blocks__Image.bs.js";
import * as RescriptLodash from "../../../rescript-lodash/src/RescriptLodash.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as DecidrRenderer__IconBlock from "../../../renderer/src/blocks/DecidrRenderer__IconBlock.bs.js";
import * as DecidrRenderer__TextBlock from "../../../renderer/src/blocks/DecidrRenderer__TextBlock.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "../row/renderer/Spaces__Row__Renderer__BlockContext.bs.js";

var contextSchema = {
  TAG: "Object",
  _0: [
    {
      key: "name",
      value: Spaces__Row__Renderer__BlockContext.$$Text.schema
    },
    {
      key: "image",
      value: Spaces__Row__Renderer__BlockContext.$$Image.schema
    }
  ]
};

var node_block = {
  meta: {
    version: Blocks__View.version
  },
  code: Blocks__View.blockCode,
  settings: {
    spacing: 12,
    alignItems: "center",
    backgroundColor: "white",
    orientation: "horizontal",
    paddingLeft: 12,
    paddingRight: 12,
    paddingTop: 12,
    paddingBottom: 12,
    borderRadius: 6,
    borderWidth: 1,
    borderColor: "transparent"
  },
  triggerActions: null
};

var node_children = [
  {
    id: "3",
    label: "3",
    block: {
      meta: {
        version: Blocks__Image.version
      },
      code: Blocks__Image.blockCode,
      settings: {
        backgroundColor: Primitives__Styling.colorValue("neutral_7"),
        width: 60,
        height: 60,
        backgroundImage: {
          __pbBind: [
            "image",
            "imageUrl"
          ]
        },
        borderRadius: 6
      },
      triggerActions: null
    },
    children: []
  },
  {
    id: "4",
    label: "4",
    block: {
      meta: {
        version: DecidrRenderer__TextBlock.version
      },
      code: DecidrRenderer__TextBlock.blockCode,
      settings: {
        text: {
          __pbBind: [
            "name",
            "text"
          ]
        },
        color: "black",
        kind: "body2",
        fontSize: 14,
        fontWeight: "500",
        flex: 1,
        numberOfLines: 1
      },
      triggerActions: null
    }
  }
];

var node = {
  id: "root",
  label: "root",
  block: node_block,
  children: node_children
};

function getTriggerActions(node) {
  return Core__Option.getOr(Core__JSON.Decode.object(node.block.triggerActions), {});
}

function getTrigger(triggerActions, triggerName) {
  return Core__Option.getOr(Core__Option.flatMap(triggerActions[triggerName], Core__JSON.Decode.object), {});
}

function getTriggerActionsList(trigger) {
  return Core__Option.getOr(Core__Option.flatMap(trigger["actions"], Core__JSON.Decode.array), []);
}

function extendTriggerWithActions(trigger, extendedActions) {
  return Object.fromEntries(Object.entries(trigger).concat([[
                    "actions",
                    extendedActions
                  ]]));
}

function extendTriggerActions(node, triggerName, newActions) {
  var triggerActions = getTriggerActions(node);
  var trigger = getTrigger(triggerActions, triggerName);
  var actions = getTriggerActionsList(trigger);
  var deduplicatedNewActions = [];
  newActions.forEach(function (newAction) {
        var newActionCode = Core__Option.flatMap(Core__JSON.Decode.object(newAction), (function (__x) {
                return __x["code"];
              }));
        if (newActionCode === undefined) {
          return ;
        }
        var hasAction = actions.some(function (action) {
              var code = Core__Option.flatMap(Core__JSON.Decode.object(action), (function (__x) {
                      return __x["code"];
                    }));
              if (code !== undefined) {
                return code === newActionCode;
              } else {
                return false;
              }
            });
        if (!hasAction) {
          deduplicatedNewActions.push(newAction);
          return ;
        }
        
      });
  var extendedActions = actions.concat(deduplicatedNewActions);
  var extendedTrigger = extendTriggerWithActions(trigger, extendedActions);
  return Object.fromEntries(Object.entries(triggerActions).concat([[
                    triggerName,
                    extendedTrigger
                  ]]));
}

function makeAbsoluteRightButton(icon, action) {
  return {
          id: "62",
          label: "62",
          block: {
            meta: {
              version: Blocks__View.version
            },
            code: Blocks__View.blockCode,
            settings: {
              justifyContent: "center",
              position: "absolute",
              zIndex: 20,
              right: 8,
              top: 0,
              bottom: 0
            },
            triggerActions: null
          },
          children: [{
              id: "63",
              label: "63",
              block: {
                meta: {
                  version: Blocks__View.version
                },
                code: Blocks__View.blockCode,
                settings: {
                  width: 30,
                  height: 30,
                  alignItems: "center",
                  justifyContent: "center",
                  borderWidth: 1,
                  borderRadius: 8,
                  borderColor: "lightgrey",
                  backgroundColor: "white"
                },
                triggerActions: {
                  onClick: {
                    actions: [action]
                  }
                }
              },
              children: [{
                  id: "10",
                  label: "10",
                  block: {
                    meta: {
                      version: DecidrRenderer__IconBlock.version
                    },
                    code: DecidrRenderer__IconBlock.blockCode,
                    settings: {
                      icon: icon,
                      size: "ic_3"
                    },
                    triggerActions: null
                  },
                  children: []
                }]
            }]
        };
}

function make(node) {
  var navigateToRowOnClickAction = Object.fromEntries([
        [
          "id",
          "1235"
        ],
        [
          "code",
          "navigate-to-row"
        ]
      ]);
  if (node.block.code !== Blocks__View.blockCode) {
    return node;
  }
  var newrecord = Caml_obj.obj_dup(node);
  var init = node.block;
  newrecord.block = {
    meta: init.meta,
    code: init.code,
    settings: init.settings,
    triggerActions: extendTriggerActions(node, "onClick", [navigateToRowOnClickAction])
  };
  return newrecord;
}

var ViewDataItemLayout = {
  make: make
};

function make$1(node) {
  var navigateToRowOnClickAction = Object.fromEntries([
        [
          "id",
          "1235"
        ],
        [
          "code",
          "open-related-row"
        ]
      ]);
  if (node.block.code !== Blocks__View.blockCode) {
    return node;
  }
  var newrecord = Caml_obj.obj_dup(node);
  var init = node.block;
  newrecord.block = {
    meta: init.meta,
    code: init.code,
    settings: init.settings,
    triggerActions: extendTriggerActions(node, "onClick", [navigateToRowOnClickAction])
  };
  return newrecord;
}

var RelatedDataItemLayout = {
  make: make$1
};

function make$2(node, withClickActionOpt) {
  var withClickAction = withClickActionOpt !== undefined ? withClickActionOpt : true;
  var unlinkRowAction = Object.fromEntries([
        [
          "id",
          "1001"
        ],
        [
          "code",
          "unlink-row"
        ]
      ]);
  var unlinkButtonNode = makeAbsoluteRightButton("unlink", unlinkRowAction);
  var openLinkedRowOnClickAction = Object.fromEntries([
        [
          "id",
          "1235"
        ],
        [
          "code",
          "open-linked-row"
        ]
      ]);
  if (node.block.code !== Blocks__View.blockCode) {
    return node;
  }
  var newrecord = Caml_obj.obj_dup(node);
  newrecord.children = Core__Option.getOr(node.children, []).concat([unlinkButtonNode]);
  var tmp;
  if (withClickAction) {
    var init = node.block;
    tmp = {
      meta: init.meta,
      code: init.code,
      settings: init.settings,
      triggerActions: extendTriggerActions(node, "onClick", [openLinkedRowOnClickAction])
    };
  } else {
    tmp = node.block;
  }
  newrecord.block = tmp;
  return newrecord;
}

var MultiSelectItemLayout = {
  make: make$2
};

function make$3(node) {
  var openLinkedRowOnClickAction = Object.fromEntries([
        [
          "id",
          "12350"
        ],
        [
          "code",
          "open-linked-row"
        ]
      ]);
  var openLinkedRowButtonNode = makeAbsoluteRightButton("v-more", openLinkedRowOnClickAction);
  var navigateToRowOnClickAction = Object.fromEntries([
        [
          "id",
          "123500"
        ],
        [
          "code",
          "navigate-to-row"
        ]
      ]);
  if (node.block.code !== Blocks__View.blockCode) {
    return node;
  }
  var newrecord = Caml_obj.obj_dup(node);
  newrecord.children = Core__Option.getOr(node.children, []).concat([openLinkedRowButtonNode]);
  var init = node.block;
  newrecord.block = {
    meta: init.meta,
    code: init.code,
    settings: init.settings,
    triggerActions: extendTriggerActions(node, "onClick", [navigateToRowOnClickAction])
  };
  return newrecord;
}

var MultiSelectInlineItemLayout = {
  make: make$3
};

function make$4(node) {
  var openLinkedRowOnClickAction = Object.fromEntries([
        [
          "id",
          "1235"
        ],
        [
          "code",
          "open-linked-row"
        ]
      ]);
  if (node.block.code !== Blocks__View.blockCode) {
    return node;
  }
  var newrecord = Caml_obj.obj_dup(node);
  var init = node.block;
  newrecord.block = {
    meta: init.meta,
    code: init.code,
    settings: init.settings,
    triggerActions: extendTriggerActions(node, "onClick", [openLinkedRowOnClickAction])
  };
  return newrecord;
}

var SystemLinkItemLayout = {
  make: make$4
};

function make$5(node) {
  var unlinkRowAction = Object.fromEntries([
        [
          "id",
          "1001"
        ],
        [
          "code",
          "unlink-row"
        ]
      ]);
  var unlinkButtonNode = makeAbsoluteRightButton("x", unlinkRowAction);
  if (node.block.code !== Blocks__View.blockCode) {
    return node;
  }
  var newrecord = Caml_obj.obj_dup(node);
  newrecord.children = Core__Option.getOr(node.children, []).concat([unlinkButtonNode]);
  var init = node.block;
  newrecord.block = {
    meta: init.meta,
    code: init.code,
    settings: RescriptLodash.merge([
          node.block.settings,
          {
            minWidth: 200,
            maxWidth: 250
          }
        ]),
    triggerActions: null
  };
  return newrecord;
}

var CriteriaArgumentItemLayout = {
  make: make$5
};

var Utils = {
  extendTriggerActions: extendTriggerActions
};

export {
  contextSchema ,
  node ,
  Utils ,
  ViewDataItemLayout ,
  RelatedDataItemLayout ,
  MultiSelectItemLayout ,
  MultiSelectInlineItemLayout ,
  SystemLinkItemLayout ,
  CriteriaArgumentItemLayout ,
}
/* node Not a pure module */
