// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSDateTime from "../../relay-appsync/src/RelayAppSync__AWSDateTime.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"view":{"f":""},"to":{"c":"RelayAppSync.AWSDateTime"},"from":{"c":"RelayAppSync.AWSDateTime"}}};

var fragmentConverterMap = {
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesBlockRowMembershipListItem_rowMembership",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "from",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "to",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "View",
      "kind": "LinkedField",
      "name": "view",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SpacesViewBasicInfo_view"
        }
      ],
      "storageKey": null
    }
  ],
  "type": "RowMembership",
  "abstractKey": null
};

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* RescriptRelay Not a pure module */
