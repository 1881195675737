// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function focusField(passedRef) {
  Core__Option.forEach(Caml_option.nullable_to_opt(passedRef.current), (function (passedRef) {
          passedRef.focus();
        }));
}

function blurField(passedRef) {
  Core__Option.forEach(Caml_option.nullable_to_opt(passedRef.current), (function (passedRef) {
          passedRef.blur();
        }));
}

function blurFields(passedRefs) {
  passedRefs.forEach(blurField);
}

function ignoreRefType(x) {
  return x;
}

export {
  focusField ,
  blurField ,
  blurFields ,
  ignoreRefType ,
}
/* No side effect */
