// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesChannelMutationsLeaveMutation_graphql from "../../../relay-codegen/generated/SpacesChannelMutationsLeaveMutation_graphql.bs.js";
import * as SpacesChannelMutations_createChannelMutation_graphql from "../../../relay-codegen/generated/SpacesChannelMutations_createChannelMutation_graphql.bs.js";
import * as SpacesChannelMutations_updateChannelMutation_graphql from "../../../relay-codegen/generated/SpacesChannelMutations_updateChannelMutation_graphql.bs.js";
import * as SpacesChannelMutations_addBotsToChannelMutation_graphql from "../../../relay-codegen/generated/SpacesChannelMutations_addBotsToChannelMutation_graphql.bs.js";
import * as SpacesChannelMutations_addMembersToChannelMutation_graphql from "../../../relay-codegen/generated/SpacesChannelMutations_addMembersToChannelMutation_graphql.bs.js";
import * as SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql from "../../../relay-codegen/generated/SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql.bs.js";

var convertVariables = SpacesChannelMutations_createChannelMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesChannelMutations_createChannelMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesChannelMutations_createChannelMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesChannelMutations_createChannelMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesChannelMutations_createChannelMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useCreateChannel() {
  var match = use();
  var createChannel = match[0];
  var handleCreatingChannel = function (organizationId, name, connectionsToUpdate, channelType, model, resourceId, onCompleted, onError, updater) {
    createChannel({
          channelType: channelType,
          model: model,
          name: name,
          organizationId: organizationId,
          relayConnectionsToUpdate: connectionsToUpdate,
          resourceId: resourceId
        }, undefined, undefined, updater, (function (param, errors) {
            var channel = param.createChannel.channel;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating channel", "Spaces__Channel__Mutations useCreateChannel", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(channel.id);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating channel", "Spaces__Channel__Mutations useCreateChannel", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleCreatingChannel,
          match[1]
        ];
}

var convertVariables$1 = SpacesChannelMutations_updateChannelMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesChannelMutations_updateChannelMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesChannelMutations_updateChannelMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesChannelMutations_updateChannelMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesChannelMutations_updateChannelMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useUpdateChannel() {
  var match = use$1();
  var updateChannel = match[0];
  var handleUpdateChannel = function (channelId, model, onCompleted, onError, updater) {
    updateChannel({
          channelId: channelId,
          model: model
        }, undefined, undefined, updater, (function (param, errors) {
            var channel = param.updateChannel.channel;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating channel", "Spaces__Channel__Mutations useUpdateChannel", onError, (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(channel.id);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating channel", "Spaces__Channel__Mutations useUpdateChannel", onError);
          }), undefined);
  };
  return [
          handleUpdateChannel,
          match[1]
        ];
}

function useUpdateChannelModel() {
  var match = useUpdateChannel();
  var updateChannel = match[0];
  var handleUpdateModel = function (channelId, model, onCompleted, onError, updater) {
    updateChannel(channelId, model, onCompleted, onError, updater);
  };
  return [
          handleUpdateModel,
          match[1]
        ];
}

var convertVariables$2 = SpacesChannelMutations_addBotsToChannelMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesChannelMutations_addBotsToChannelMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesChannelMutations_addBotsToChannelMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesChannelMutations_addBotsToChannelMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesChannelMutations_addBotsToChannelMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function useAddBots() {
  var match = use$2();
  var addBots = match[0];
  var handleAddBots = function (channelId, botIds, onCompleted, onError) {
    addBots({
          botIds: botIds,
          channelId: channelId
        }, undefined, undefined, undefined, (function (param, errors) {
            var channel = param.addBotsToChannel.channel;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error adding bot to channel", "Spaces__Channel__Mutations useAddBots", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(channel.id);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error adding bot to channel", "Spaces__Channel__Mutations useAddBots", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleAddBots,
          match[1]
        ];
}

function useAddBot() {
  var match = useAddBots();
  var addBots = match[0];
  var handleAddBot = function (channelId, botId, onCompleted, onError) {
    addBots(channelId, [botId], onCompleted, onError);
  };
  return [
          handleAddBot,
          match[1]
        ];
}

var convertVariables$3 = SpacesChannelMutations_addMembersToChannelMutation_graphql.Internal.convertVariables;

var convertResponse$3 = SpacesChannelMutations_addMembersToChannelMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$3 = SpacesChannelMutations_addMembersToChannelMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$3, SpacesChannelMutations_addMembersToChannelMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, SpacesChannelMutations_addMembersToChannelMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

function useAddMembers() {
  var match = use$3();
  var addMembers = match[0];
  var handleAddMembers = function (channelId, memberIds, onCompleted, onError) {
    addMembers({
          channelId: channelId,
          memberIds: memberIds
        }, undefined, undefined, undefined, (function (param, errors) {
            var channel = param.addMembersToChannel.channel;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error adding member to channel", "Spaces__Channel__Mutations useAddMembers", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(channel.id);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error adding member to channel", "Spaces__Channel__Mutations useAddMembers", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleAddMembers,
          match[1]
        ];
}

function useAddMember() {
  var match = useAddMembers();
  var addMembers = match[0];
  var handleAddMember = function (channelId, memberId, onCompleted, onError) {
    addMembers(channelId, [memberId], onCompleted, onError);
  };
  return [
          handleAddMember,
          match[1]
        ];
}

var convertVariables$4 = SpacesChannelMutationsLeaveMutation_graphql.Internal.convertVariables;

var convertResponse$4 = SpacesChannelMutationsLeaveMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$4 = SpacesChannelMutationsLeaveMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$4, SpacesChannelMutationsLeaveMutation_graphql.node, convertResponse$4, convertWrapRawResponse$4);

var use$4 = RescriptRelay_Mutation.useMutation(convertVariables$4, SpacesChannelMutationsLeaveMutation_graphql.node, convertResponse$4, convertWrapRawResponse$4);

function useLeaveChannel() {
  var match = use$4();
  var leaveChannel = match[0];
  var handleLeaveChannel = function (channelId, organizationMemberId, onCompleted, onError) {
    leaveChannel({
          channelId: channelId
        }, undefined, undefined, (function (store, _response) {
            RelayUtils.Helpers.removeFromArray(store, channelId, organizationMemberId, "humanMembers");
          }), (function (param, errors) {
            var leaveChannel = param.leaveChannel;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error leaving chat", "Spaces__Channel__Mutations useLeaveChannel", (function (message, reason) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message, reason);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(leaveChannel.channelId);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error leaving chat", "Spaces__Channel__Mutations useLeaveChannel", (function (message, reason) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message, reason);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleLeaveChannel,
          match[1]
        ];
}

var convertVariables$5 = SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql.Internal.convertVariables;

var convertResponse$5 = SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$5 = SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$5, SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql.node, convertResponse$5, convertWrapRawResponse$5);

var use$5 = RescriptRelay_Mutation.useMutation(convertVariables$5, SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation_graphql.node, convertResponse$5, convertWrapRawResponse$5);

function useRemoveOrganizationMember() {
  var match = use$5();
  var removeOrganizationMember = match[0];
  var handleRemoveOrganizationMember = function (channelId, organizationMemberId, isAi, onCompleted, onError) {
    removeOrganizationMember({
          channelId: channelId,
          organizationMemberId: organizationMemberId
        }, undefined, undefined, (function (store, _response) {
            RelayUtils.Helpers.removeFromArray(store, channelId, organizationMemberId, isAi ? "aiMembers" : "humanMembers");
          }), (function (param, errors) {
            var match = param.removeOrganizationMemberFromChannel;
            var removedOrganizationMemberId = match.removedOrganizationMemberId;
            var channelId = match.channelId;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error removing organization member from chat", "Spaces__Channel__Mutations useRemoveOrganizationMember", (function (message, reason) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message, reason);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(channelId, removedOrganizationMemberId);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error removing organization member from chat", "Spaces__Channel__Mutations useRemoveOrganizationMember", (function (message, reason) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message, reason);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleRemoveOrganizationMember,
          match[1]
        ];
}

export {
  useCreateChannel ,
  useUpdateChannelModel ,
  useAddBots ,
  useAddBot ,
  useAddMembers ,
  useAddMember ,
  useLeaveChannel ,
  useRemoveOrganizationMember ,
}
/*  Not a pure module */
