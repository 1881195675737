// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";

var IconProps;

var make = Tamagui.Button;

export {
  IconProps ,
  make ,
}
/* make Not a pure module */
