// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";

var route = "route";

var sourceViewId = "sourceViewId";

var callerRowId = "ms-link-flow::rowId";

var callerColumnId = "ms-link-flow::columnId";

var callerColumnViewId = "ms-link-flow::columnViewId";

var viewIdToOpen = "viewIdToOpen";

var channelId = "channelId";

var columnViewId = "columnViewId";

var addBlockDirection = "add-block::direction";

var tableLinkInfoId = "tableLinkInfoId";

var columnViewName = "columnViewName";

var addBlockKind = "add-block::kind";

var addBlockComponentTypeId = "add-block::componentTypeId";

var addBlockComponentTypeCode = "add-block::componentTypeCode";

function PayloadKey_toString(prim) {
  return prim;
}

var PayloadKey = {
  route: route,
  sourceViewId: sourceViewId,
  callerRowId: callerRowId,
  callerColumnId: callerColumnId,
  callerColumnViewId: callerColumnViewId,
  relatedListColumnId: "related-list::columnId",
  viewIdToOpen: viewIdToOpen,
  channelId: channelId,
  columnViewId: columnViewId,
  addBlockDirection: addBlockDirection,
  tableLinkInfoId: tableLinkInfoId,
  columnViewName: columnViewName,
  addBlockKind: addBlockKind,
  addBlockComponentTypeId: addBlockComponentTypeId,
  addBlockComponentTypeCode: addBlockComponentTypeCode,
  toString: PayloadKey_toString
};

function getPayloadValue(payload, key) {
  return Core__Option.flatMap(Core__JSON.Decode.object(payload), (function (__x) {
                return __x[PayloadKey_toString(key)];
              }));
}

function getMultiSelectActionMetadata(payload) {
  var sourceViewId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, sourceViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
  var callerRowId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, callerRowId), Core__JSON.Decode.string), DecidrInternalId.fromString);
  var callerColumnId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, callerColumnId), Core__JSON.Decode.string), DecidrInternalId.fromString);
  var callerColumnViewId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, callerColumnViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
  return {
          sourceViewId: sourceViewId$1,
          callerRowId: callerRowId$1,
          callerColumnId: callerColumnId$1,
          callerColumnViewId: callerColumnViewId$1
        };
}

function parseAddBlockDirection(direction) {
  if (direction === undefined) {
    return "after";
  }
  switch (direction) {
    case "after" :
        return "after";
    case "before" :
        return "before";
    default:
      return "after";
  }
}

function parseAddBlockKind(kind) {
  if (kind === undefined) {
    return ;
  }
  switch (kind) {
    case "lookup" :
        return "lookup";
    case "rollup" :
        return "rollup";
    default:
      return ;
  }
}

function parseAction(action) {
  var action$1 = Core__JSON.Decode.object(action);
  var id = Core__Option.flatMap(Core__Option.flatMap(action$1, (function (__x) {
              return __x["id"];
            })), Core__JSON.Decode.string);
  var code = Core__Option.flatMap(Core__Option.flatMap(action$1, (function (__x) {
              return __x["code"];
            })), Core__JSON.Decode.string);
  var payload = Core__Option.getOr(Core__Option.flatMap(action$1, (function (__x) {
              return __x["payload"];
            })), null);
  if (id === undefined) {
    return ;
  }
  if (code === undefined) {
    return ;
  }
  switch (code) {
    case "add-column-view" :
        var columnViewId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, columnViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        var direction = parseAddBlockDirection(Core__Option.flatMap(getPayloadValue(payload, addBlockDirection), Core__JSON.Decode.string));
        var addBlockComponentTypeId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, addBlockComponentTypeId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        var addBlockComponentTypeCode$1 = Core__Option.flatMap(getPayloadValue(payload, addBlockComponentTypeCode), Core__JSON.Decode.string);
        var sourceViewId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, sourceViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        var tableLinkInfoId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, tableLinkInfoId), Core__JSON.Decode.string), DecidrInternalId.fromString);
        var columnViewName$1 = Core__Option.flatMap(getPayloadValue(payload, columnViewName), Core__JSON.Decode.string);
        var kind = parseAddBlockKind(Core__Option.flatMap(getPayloadValue(payload, addBlockKind), Core__JSON.Decode.string));
        if (columnViewId$1 === undefined) {
          return ;
        }
        var columnViewId$2 = Caml_option.valFromOption(columnViewId$1);
        if (kind !== undefined) {
          if (kind === "rollup") {
            if (columnViewName$1 !== undefined && tableLinkInfoId$1 !== undefined && sourceViewId$1 !== undefined && addBlockComponentTypeId$1 !== undefined) {
              return {
                      TAG: "AddRollupColumnView",
                      columnViewName: columnViewName$1,
                      tableLinkInfoId: Caml_option.valFromOption(tableLinkInfoId$1),
                      sourceViewId: Caml_option.valFromOption(sourceViewId$1),
                      relativeToColumnViewId: columnViewId$2,
                      direction: direction,
                      rollupComponentTypeId: Caml_option.valFromOption(addBlockComponentTypeId$1)
                    };
            } else {
              return ;
            }
          } else if (kind === "lookup" && columnViewName$1 !== undefined && tableLinkInfoId$1 !== undefined && addBlockComponentTypeId$1 !== undefined) {
            return {
                    TAG: "AddLookupColumnView",
                    columnViewName: columnViewName$1,
                    tableLinkInfoId: Caml_option.valFromOption(tableLinkInfoId$1),
                    relativeToColumnViewId: columnViewId$2,
                    direction: direction,
                    lookupComponentTypeId: Caml_option.valFromOption(addBlockComponentTypeId$1)
                  };
          } else {
            return ;
          }
        } else {
          return {
                  TAG: "AddColumnView",
                  relativeToColumnViewId: columnViewId$2,
                  direction: direction,
                  componentTypeId: addBlockComponentTypeId$1,
                  componentTypeCode: addBlockComponentTypeCode$1
                };
        }
    case "close-link-rows-modal" :
        return "CloseLinkRowsModal";
    case "delete-column-view" :
        var columnViewId$3 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, columnViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return Core__Option.map(columnViewId$3, (function (columnViewId) {
                      return {
                              TAG: "DeleteColumnView",
                              columnViewId: columnViewId
                            };
                    }));
    case "edit-column-view" :
        var columnViewId$4 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, columnViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return Core__Option.map(columnViewId$4, (function (columnViewId) {
                      return {
                              TAG: "EditColumnView",
                              columnViewId: columnViewId
                            };
                    }));
    case "hide-column-view" :
        var columnViewId$5 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, columnViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return Core__Option.map(columnViewId$5, (function (columnViewId) {
                      return {
                              TAG: "HideColumnView",
                              columnViewId: columnViewId
                            };
                    }));
    case "link-rows" :
        var payload$1 = getMultiSelectActionMetadata(payload);
        var match = payload$1.sourceViewId;
        var match$1 = payload$1.callerRowId;
        var match$2 = payload$1.callerColumnId;
        var match$3 = payload$1.callerColumnViewId;
        if (match !== undefined && match$1 !== undefined && match$2 !== undefined && match$3 !== undefined) {
          return {
                  TAG: "LinkRows",
                  sourceViewId: Caml_option.valFromOption(match),
                  callerRowId: Caml_option.valFromOption(match$1),
                  callerColumnId: Caml_option.valFromOption(match$2),
                  callerColumnViewId: Caml_option.valFromOption(match$3)
                };
        } else {
          return ;
        }
    case "navigate" :
        var route$1 = Core__Option.flatMap(getPayloadValue(payload, route), Core__JSON.Decode.string);
        return Core__Option.map(route$1, (function (route) {
                      return {
                              TAG: "Navigate",
                              route: route
                            };
                    }));
    case "navigate-to-row" :
        return "NavigateToRow";
    case "navigate-to-view" :
        var viewIdToOpen$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, viewIdToOpen), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return {
                TAG: "NavigateToView",
                viewId: viewIdToOpen$1
              };
    case "open-column-view-settings" :
        var columnViewId$6 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, columnViewId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return Core__Option.map(columnViewId$6, (function (columnViewId) {
                      return {
                              TAG: "OpenColumnViewRecord",
                              columnViewId: columnViewId
                            };
                    }));
    case "open-linked-row" :
        return "OpenLinkedRow";
    case "open-related-row" :
        return "OpenRelatedRow";
    case "open-source-view-settings" :
        var payload$2 = getMultiSelectActionMetadata(payload);
        var sourceViewId$2 = payload$2.sourceViewId;
        if (sourceViewId$2 !== undefined) {
          return {
                  TAG: "OpenSourceViewSettings",
                  sourceViewId: Caml_option.valFromOption(sourceViewId$2)
                };
        } else {
          return ;
        }
    case "toggle-cell-channel" :
        var channelId$1 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, channelId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return Core__Option.map(channelId$1, (function (channelId) {
                      return {
                              TAG: "ToggleCellChannel",
                              channelId: channelId
                            };
                    }));
    case "toggle-column-view-channel" :
        var channelId$2 = Core__Option.flatMap(Core__Option.flatMap(getPayloadValue(payload, channelId), Core__JSON.Decode.string), DecidrNodeId.fromString);
        return Core__Option.map(channelId$2, (function (channelId) {
                      return {
                              TAG: "ToggleColumnViewChannel",
                              channelId: channelId
                            };
                    }));
    case "unlink-row" :
        return "UnlinkRow";
    default:
      return ;
  }
}

export {
  PayloadKey ,
  parseAction ,
}
/* DecidrNodeId Not a pure module */
