// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as Belt_Array from "rescript/lib/es6/belt_Array.js";
import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SpacesViewDataPaginatedQuery_graphql from "./SpacesViewDataPaginatedQuery_graphql.bs.js";

var Types = {};

function unwrap_fragment_data_edges_node_cells(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "CurrencyCell",
              "NumberCell",
              "PercentCell",
              "TextCell"
            ]);
}

var fragmentConverter = {"__root":{"viewType_id":{"c":"DecidrNodeId.RelayAppSync"},"id":{"c":"DecidrNodeId.RelayAppSync"},"data_edges_node_id":{"c":"DecidrNodeId.RelayAppSync"},"data_edges_node_cells_TextCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"data_edges_node_cells_TextCell":{"f":""},"data_edges_node_cells_PercentCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"data_edges_node_cells_PercentCell":{"f":""},"data_edges_node_cells_NumberCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"data_edges_node_cells_NumberCell":{"f":""},"data_edges_node_cells_CurrencyCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"data_edges_node_cells_CurrencyCell":{"f":""},"data_edges_node_cells":{"u":"fragment_data_edges_node_cells"},"data_edges_node":{"f":""},"coreConfig":{"c":"RelayAppSync.AWSJSON"},"columnViews_id":{"c":"DecidrNodeId.RelayAppSync"},"columnViews_componentType_id":{"c":"DecidrNodeId.RelayAppSync"},"columnViews_columnId":{"c":"DecidrNodeId.RelayAppSync"},"columnViews":{"f":""}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse,
  fragment_data_edges_node_cells: unwrap_fragment_data_edges_node_cells
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

function makeConnectionId(connectionParentDataId, searchTermOpt, caller) {
  var searchTerm = searchTermOpt !== undefined ? searchTermOpt : "";
  var searchTerm$1 = searchTerm;
  var args = {
    searchTerm: searchTerm$1,
    caller: caller
  };
  return RelayRuntime.ConnectionHandler.getConnectionID(connectionParentDataId, "SpacesView_view_data", args);
}

function getConnectionNodes(connection) {
  return Belt_Array.keepMap(connection.edges, (function (edge) {
                return edge.node;
              }));
}

var Utils = {
  getConnectionNodes: getConnectionNodes
};

function makeNode(rescript_graphql_node_SpacesViewDataPaginatedQuery) {
  return ((function(){
var v0 = [
  "data"
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnId",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "kind": "InlineDataFragmentSpread",
  "name": "SpacesTextCell_textCell",
  "selections": [
    (v6/*: any*/),
    (v7/*: any*/),
    (v2/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v10 = {
  "kind": "InlineDataFragmentSpread",
  "name": "SpacesNumberCell_numberCell",
  "selections": [
    (v9/*: any*/),
    (v7/*: any*/),
    (v2/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percent",
  "storageKey": null
},
v12 = {
  "kind": "InlineDataFragmentSpread",
  "name": "SpacesPercentCell_percentCell",
  "selections": [
    (v11/*: any*/),
    (v7/*: any*/),
    (v2/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v14 = {
  "kind": "InlineDataFragmentSpread",
  "name": "SpacesCurrencyCell_currencyCell",
  "selections": [
    (v13/*: any*/),
    (v7/*: any*/),
    (v2/*: any*/)
  ],
  "args": null,
  "argumentDefinitions": ([]/*: any*/)
},
v15 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SpacesCache_row"
},
v16 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "SpacesFileInfo_fileInfo",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mimetype",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      (v4/*: any*/)
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v17 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SpacesMultiSelectCellCache_multiSelectCell"
},
v18 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SpacesLookupCellCache_lookupCell"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "after"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "caller"
    },
    {
      "defaultValue": 24,
      "kind": "LocalArgument",
      "name": "first"
    },
    {
      "defaultValue": "",
      "kind": "LocalArgument",
      "name": "searchTerm"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "first",
        "cursor": "after",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "first",
          "cursor": "after"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [
        "node"
      ],
      "operation": rescript_graphql_node_SpacesViewDataPaginatedQuery,
      "identifierInfo": {
        "identifierField": "id",
        "identifierQueryVariableName": "id"
      }
    }
  },
  "name": "SpacesViewData_view",
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ColumnView",
      "kind": "LinkedField",
      "name": "columnViews",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SpacesCache_columnView"
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        (v2/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "ComponentType",
          "kind": "LinkedField",
          "name": "componentType",
          "plural": false,
          "selections": [
            (v1/*: any*/),
            (v3/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": "ImageInfo",
              "kind": "LinkedField",
              "name": "icon",
              "plural": false,
              "selections": [
                (v4/*: any*/)
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewType",
      "args": null,
      "concreteType": "ViewType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": [
        (v1/*: any*/),
        (v3/*: any*/)
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coreConfig",
      "storageKey": null
    },
    {
      "alias": "data",
      "args": [
        {
          "kind": "Variable",
          "name": "caller",
          "variableName": "caller"
        },
        {
          "kind": "Variable",
          "name": "searchTerm",
          "variableName": "searchTerm"
        }
      ],
      "concreteType": "RowConnection",
      "kind": "LinkedField",
      "name": "__SpacesView_view_data_connection",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "RowEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "Row",
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                (v1/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "cells",
                  "plural": true,
                  "selections": [
                    (v5/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v8/*: any*/),
                        (v2/*: any*/),
                        (v6/*: any*/)
                      ],
                      "type": "TextCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v10/*: any*/),
                        (v2/*: any*/),
                        (v9/*: any*/)
                      ],
                      "type": "NumberCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v12/*: any*/),
                        (v2/*: any*/),
                        (v11/*: any*/)
                      ],
                      "type": "PercentCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v14/*: any*/),
                        (v2/*: any*/),
                        (v13/*: any*/)
                      ],
                      "type": "CurrencyCell",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SpacesRowListData_row",
                  "selections": [
                    (v15/*: any*/),
                    (v1/*: any*/),
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": null,
                      "kind": "LinkedField",
                      "name": "cells",
                      "plural": true,
                      "selections": [
                        (v5/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesCells_cell",
                          "selections": [
                            (v5/*: any*/),
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                (v8/*: any*/)
                              ],
                              "type": "TextCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                (v10/*: any*/)
                              ],
                              "type": "NumberCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                (v12/*: any*/)
                              ],
                              "type": "PercentCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                (v14/*: any*/)
                              ],
                              "type": "CurrencyCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesSwitchCell_switchCell",
                                  "selections": [
                                    {
                                      "alias": "switched",
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "switch",
                                      "storageKey": null
                                    },
                                    (v7/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "SwitchCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesDateCell_dateCell",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "kind": "ScalarField",
                                      "name": "date",
                                      "storageKey": null
                                    },
                                    (v7/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "DateCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesImageCell_imageCell",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "ImageInfo",
                                      "kind": "LinkedField",
                                      "name": "image",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "kind": "InlineDataFragmentSpread",
                                          "name": "SpacesImageInfo_imageInfo",
                                          "selections": [
                                            (v1/*: any*/),
                                            (v4/*: any*/),
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "Size",
                                              "kind": "LinkedField",
                                              "name": "size",
                                              "plural": false,
                                              "selections": [
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "width",
                                                  "storageKey": null
                                                },
                                                {
                                                  "alias": null,
                                                  "args": null,
                                                  "kind": "ScalarField",
                                                  "name": "height",
                                                  "storageKey": null
                                                }
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "args": null,
                                          "argumentDefinitions": []
                                        }
                                      ],
                                      "storageKey": null
                                    },
                                    (v7/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "ImageCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesAudioCell_audioCell",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "FileInfo",
                                      "kind": "LinkedField",
                                      "name": "audio",
                                      "plural": false,
                                      "selections": (v16/*: any*/),
                                      "storageKey": null
                                    },
                                    (v7/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "AudioCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesVideoCell_videoCell",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "FileInfo",
                                      "kind": "LinkedField",
                                      "name": "video",
                                      "plural": false,
                                      "selections": (v16/*: any*/),
                                      "storageKey": null
                                    },
                                    (v7/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "VideoCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesFileCell_fileCell",
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "FileInfo",
                                      "kind": "LinkedField",
                                      "name": "file",
                                      "plural": false,
                                      "selections": (v16/*: any*/),
                                      "storageKey": null
                                    },
                                    (v7/*: any*/),
                                    (v2/*: any*/)
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "FileCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                (v17/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesMultiSelectCellData_multiSelectCell",
                                  "selections": [
                                    (v17/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "MultiSelectData",
                                      "kind": "LinkedField",
                                      "name": "data",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "View",
                                          "kind": "LinkedField",
                                          "name": "sourceView",
                                          "plural": false,
                                          "selections": [
                                            (v1/*: any*/),
                                            {
                                              "alias": "viewType",
                                              "args": null,
                                              "concreteType": "ViewType",
                                              "kind": "LinkedField",
                                              "name": "type",
                                              "plural": false,
                                              "selections": [
                                                (v3/*: any*/)
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "concreteType": "SelectedRowInfo",
                                          "kind": "LinkedField",
                                          "name": "selectedRows",
                                          "plural": true,
                                          "selections": [
                                            {
                                              "alias": null,
                                              "args": null,
                                              "concreteType": "Row",
                                              "kind": "LinkedField",
                                              "name": "row",
                                              "plural": false,
                                              "selections": [
                                                (v15/*: any*/),
                                                (v1/*: any*/)
                                              ],
                                              "storageKey": null
                                            }
                                          ],
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "MultiSelectCell",
                              "abstractKey": null
                            },
                            {
                              "kind": "InlineFragment",
                              "selections": [
                                (v7/*: any*/),
                                (v2/*: any*/),
                                (v18/*: any*/),
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesLookupCellData_lookupCell",
                                  "selections": [
                                    (v18/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "LookupCellData",
                                      "kind": "LinkedField",
                                      "name": "data",
                                      "plural": false,
                                      "selections": [
                                        (v5/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "type": "LookupCell",
                              "abstractKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                },
                (v5/*: any*/)
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "cursor",
              "storageKey": null
            }
          ],
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "PageInfo",
          "kind": "LinkedField",
          "name": "pageInfo",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "endCursor",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "hasNextPage",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "View",
  "abstractKey": null
};
})());
}

var node = makeNode(SpacesViewDataPaginatedQuery_graphql.node);

var wrap_fragment_data_edges_node_cells = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_data_edges_node_cells ,
  wrap_fragment_data_edges_node_cells ,
  Internal ,
  makeConnectionId ,
  Utils ,
  node ,
}
/* node Not a pure module */
