// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNativeMmkv from "react-native-mmkv";

var storage = new ReactNativeMmkv.MMKV(undefined);

function getString(key) {
  return storage.getString(key);
}

function setString(key, value) {
  storage.set(key, value);
}

function getFloat(key) {
  return storage.getNumber(key);
}

function setFloat(key, value) {
  storage.set(key, value);
}

function getInt(key) {
  return Core__Option.flatMap(storage.getNumber(key), (function (value) {
                if (isFinite(value)) {
                  return value;
                }
                
              }));
}

function setInt(key, value) {
  storage.set(key, value);
}

function getBool(key) {
  return storage.getBoolean(key);
}

function setBool(key, value) {
  storage.set(key, value);
}

function deleteKey(key) {
  storage.delete(key);
}

function clearAll() {
  storage.clearAll();
}

export {
  storage ,
  getString ,
  setString ,
  getFloat ,
  setFloat ,
  getInt ,
  setInt ,
  getBool ,
  setBool ,
  deleteKey ,
  clearAll ,
}
/* storage Not a pure module */
