// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ExpoNetwork from "expo-network";

function getNetworkStateAsync(prim) {
  return ExpoNetwork.getNetworkStateAsync();
}

function getIpAddressAsync(prim) {
  return ExpoNetwork.getIpAddressAsync();
}

export {
  getNetworkStateAsync ,
  getIpAddressAsync ,
}
/* expo-network Not a pure module */
