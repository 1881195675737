// Generated by ReScript, PLEASE EDIT WITH CARE

import LodashMerge from "lodash.merge";
import * as Caml_splice_call from "rescript/lib/es6/caml_splice_call.js";

function castToObject(x) {
  return x;
}

function merge(xs) {
  return Caml_splice_call.spliceApply(LodashMerge, [[{}].concat(xs.map(castToObject))]);
}

export {
  merge ,
}
/* lodash.merge Not a pure module */
