// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__YStack from "../../../rescript-tamagui/src/yStack/RescriptTamagui__YStack.bs.js";

function Primitives__Group(props) {
  var cursor = props.cursor;
  var __hideBreakpoints = props.hideBreakpoints;
  var onPress = props.onPress;
  var __children = props.children;
  var __specificStyles = props.specificStyles;
  var __style = props.style;
  var __reverse = props.reverse;
  var __orientation = props.orientation;
  var orientation = __orientation !== undefined ? __orientation : "vertical";
  var reverse = __reverse !== undefined ? __reverse : false;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var specificStyles = __specificStyles !== undefined ? __specificStyles : TamaguiConfig.Utils.emptySpecificStyles;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var hideBreakpoints = __hideBreakpoints !== undefined ? __hideBreakpoints : [];
  var newrecord = Caml_obj.obj_dup(style);
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle((newrecord.gap = Core__Option.map(props.gap, Primitives__Styling.Gap.toRNGap), newrecord.flexDirection = orientation === "horizontal" ? (
                reverse ? "row-reverse" : "row"
              ) : (
                reverse ? "column-reverse" : "column"
              ), newrecord.alignItems = ReactNative.Platform.OS === "ios" ? Core__Option.map(style.alignItems, (function (alignItems) {
                      if (alignItems === "baseline") {
                        return "center";
                      } else {
                        return alignItems;
                      }
                    })) : style.alignItems, newrecord), Caml_option.some(Primitives__Styling.makeSpecificStyles({
                    s: Primitives__Styling.withDisplayNone(specificStyles.s, hideBreakpoints.includes("s")),
                    m: Primitives__Styling.withDisplayNone(specificStyles.m, hideBreakpoints.includes("m")),
                    l: Primitives__Styling.withDisplayNone(specificStyles.l, hideBreakpoints.includes("l")),
                    xl: Primitives__Styling.withDisplayNone(specificStyles.xl, hideBreakpoints.includes("xl"))
                  })), undefined));
  var style$1 = stylesDict["common"];
  return JsxRuntime.jsx(RescriptTamagui__YStack.make, {
              children: Caml_option.some(children),
              onPress: onPress,
              onHoverIn: props.onHoverIn,
              onHoverOut: props.onHoverOut,
              onLongPress: props.onLongPress,
              style: Core__Option.map(style$1, (function (__x) {
                      return Primitives__Styling.Utils.omitStyleKeys(__x, [
                                  "backgroundColor",
                                  "borderColor",
                                  "borderWidth"
                                ]);
                    })),
              hoverStyle: props.hoverStyle,
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              accessibilityRole: props.accessibilityRole,
              href: props.href,
              cur: cursor !== undefined ? cursor : (
                  onPress !== undefined ? "pointer" : undefined
                ),
              bc: Core__Option.flatMap(style$1, (function (s) {
                      return s.backgroundColor;
                    })),
              boc: Core__Option.flatMap(style$1, (function (s) {
                      return s.borderColor;
                    })),
              bw: Core__Option.flatMap(style$1, (function (s) {
                      return s.borderWidth;
                    }))
            });
}

var make = Primitives__Group;

export {
  make ,
}
/* react-native Not a pure module */
