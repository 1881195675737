// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Core__String from "@rescript/core/src/Core__String.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Utils__Array from "../../../utils/src/array/Utils__Array.bs.js";
import * as Spaces__Cells from "../cells/Spaces__Cells.bs.js";
import LodashIsequal from "lodash.isequal";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as Renderer__Schema from "@pageblocks/renderer/src/schema/Renderer__Schema.bs.js";
import * as Spaces__DateCell from "../cells/Spaces__DateCell.bs.js";
import * as Spaces__FileCell from "../cells/Spaces__FileCell.bs.js";
import * as Spaces__FileInfo from "../fileInfo/Spaces__FileInfo.bs.js";
import * as Spaces__TextCell from "../cells/Spaces__TextCell.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Spaces__AudioCell from "../cells/Spaces__AudioCell.bs.js";
import * as Spaces__ImageCell from "../cells/Spaces__ImageCell.bs.js";
import * as Spaces__ImageInfo from "../imageInfo/Spaces__ImageInfo.bs.js";
import * as Spaces__VideoCell from "../cells/Spaces__VideoCell.bs.js";
import * as Spaces__NumberCell from "../cells/Spaces__NumberCell.bs.js";
import * as Spaces__SwitchCell from "../cells/Spaces__SwitchCell.bs.js";
import * as Spaces__PercentCell from "../cells/Spaces__PercentCell.bs.js";
import * as Spaces__CurrencyCell from "../cells/Spaces__CurrencyCell.bs.js";
import * as Spaces__MultiSelectCell from "../cells/Spaces__MultiSelectCell.bs.js";
import * as Spaces__Row__Renderer__Utils from "./renderer/Spaces__Row__Renderer__Utils.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "./renderer/Spaces__Row__Renderer__BlockContext.bs.js";

var rootKey = "root";

function get(coreConfig, layout) {
  var layout$1 = Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
              return __x[layout];
            })), Core__JSON.Decode.object);
  var fieldMapping = Core__Option.getOr(Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(layout$1, (function (__x) {
                              return __x["fieldMapping"];
                            })), Core__JSON.Decode.object), (function (prim) {
                      return Object.entries(prim);
                    })), (function (__x) {
                  return Core__Array.filterMap(__x, (function (param) {
                                var key = param[0];
                                return Core__Option.map(Core__Option.flatMap(Core__JSON.Decode.string(param[1]), DecidrInternalId.fromString), (function (value) {
                                              return [
                                                      key,
                                                      value
                                                    ];
                                            }));
                              }));
                })), (function (prim) {
              return Object.fromEntries(prim);
            })), {});
  var contextSchema = Core__Option.flatMap(Core__Option.flatMap(layout$1, (function (__x) {
              return __x["contextSchema"];
            })), Renderer__Schema.fromJson);
  var node = Core__Option.flatMap(Core__Option.flatMap(layout$1, (function (__x) {
              return __x["node"];
            })), (function (prim) {
          return prim;
        }));
  return {
          fieldMapping: fieldMapping,
          contextSchema: contextSchema,
          node: node
        };
}

var LayoutElement = {
  get: get
};

var validComponentTypesPerSchema = [
  [
    Spaces__Row__Renderer__BlockContext.$$Text.schema,
    [
      "text",
      "multi_text",
      "heading",
      "paragraph",
      "url",
      "email_address",
      "address",
      "qr-code",
      "qr-code-reader",
      "bar_code",
      "barcode-scanner",
      "youtube-video",
      "places",
      "icon",
      "illustration",
      "phone_number",
      "nearby-places",
      "rating",
      "duration",
      "location",
      "stripe",
      "json"
    ]
  ],
  [
    Spaces__Row__Renderer__BlockContext.$$Date.schema,
    ["date"]
  ],
  [
    Spaces__Row__Renderer__BlockContext.SelectView.schema,
    ["type-select-view"]
  ],
  [
    Spaces__Row__Renderer__BlockContext.$$Number.schema,
    [
      "number",
      "percent",
      "currency"
    ]
  ],
  [
    Spaces__Row__Renderer__BlockContext.Switch.schema,
    ["switch"]
  ],
  [
    Spaces__Row__Renderer__BlockContext.$$Image.schema,
    [
      "image",
      "content-image"
    ]
  ],
  [
    Spaces__Row__Renderer__BlockContext.$$File.schema,
    [
      "type-file",
      "video",
      "audio"
    ]
  ],
  [
    Spaces__Row__Renderer__BlockContext.MultiSelect.schema,
    ["multi_select"]
  ],
  [
    Spaces__Row__Renderer__BlockContext.SystemLink.schema,
    [
      "type-created-by",
      "type-last-updated-by",
      "type-owned-by",
      "type-created-from-row",
      "type-created-from-view",
      "type-created-in-view",
      "type-created-from-column-view",
      "type-current-logged-in-user"
    ]
  ],
  [
    Spaces__Row__Renderer__BlockContext.RelatedList.schema,
    ["related-list"]
  ],
  [
    Spaces__Row__Renderer__BlockContext.ReadOnlyDate.schema,
    [
      "type-created-date",
      "type-last-updated-date"
    ]
  ]
];

function make(schema) {
  if (typeof schema !== "object") {
    switch (schema) {
      case "String" :
          return "String";
      case "Float" :
          return "Float";
      case "Bool" :
          return "Bool";
      case "Unknown" :
          return "Unknown";
      
    }
  } else {
    if (schema.TAG !== "Object") {
      return {
              TAG: "List",
              _0: make(schema._0)
            };
    }
    var ignoredKeysInComparison = [Spaces__Row__Renderer__BlockContext.Common.Keys.componentTypeCode];
    var objectSchema = Utils__Array.toSorted(schema._0.filter(function (param) {
                var key = param.key;
                return !ignoredKeysInComparison.some(function (ignoredKey) {
                            return ignoredKey === key;
                          });
              }), (function (x1, x2) {
              return Core__String.compare(x1.key, x2.key);
            })).map(function (param) {
          return {
                  key: param.key,
                  value: make(param.value)
                };
        });
    return {
            TAG: "Object",
            _0: objectSchema
          };
  }
}

function compare(s1, s2) {
  return LodashIsequal(s1, s2);
}

var ComparableContextSchema = {
  make: make,
  compare: compare
};

function isComponentTypeValid(schema, componentTypeCode, componentTypesForSchemaOpt) {
  var componentTypesForSchema = componentTypesForSchemaOpt !== undefined ? componentTypesForSchemaOpt : validComponentTypesPerSchema;
  return Core__Option.getOr(Core__Option.map(componentTypesForSchema.find(function (param) {
                      return LodashIsequal(make(param[0]), make(schema));
                    }), (function (param) {
                    return param[1].includes(componentTypeCode);
                  })), false);
}

function guessFieldMapping(columnInfos, layoutSchema) {
  var mappings = {};
  var guess = function (key, schema, columnInfos) {
    if (typeof schema !== "object") {
      if (schema === "Unknown") {
        return ;
      }
      var columnInfo = columnInfos.find(function (param) {
            if (Object.values(mappings).includes(param.columnId)) {
              return false;
            } else {
              return isComponentTypeValid(schema, param.componentTypeCode, undefined);
            }
          });
      return Core__Option.forEach(columnInfo, (function (param) {
                    mappings[key] = param.columnId;
                  }));
    } else {
      if (schema.TAG !== "Object") {
        return ;
      }
      var objectSchema = schema._0;
      var columnInfo$1 = columnInfos.find(function (param) {
            var isComponentTypeValid$1 = isComponentTypeValid({
                  TAG: "Object",
                  _0: Utils__Array.toSorted(objectSchema, (function (x1, x2) {
                          return Core__String.compare(x1.key, x2.key);
                        }))
                }, param.componentTypeCode, undefined);
            if (Object.values(mappings).includes(param.columnId)) {
              return false;
            } else {
              return isComponentTypeValid$1;
            }
          });
      if (columnInfo$1 !== undefined) {
        mappings[key] = columnInfo$1.columnId;
      } else {
        objectSchema.forEach(function (param) {
              guess(param.key, param.value, columnInfos);
            });
      }
      return ;
    }
  };
  guess(rootKey, layoutSchema, columnInfos);
  return mappings;
}

function findNestedSchemaByKey(k, schema) {
  if (typeof schema !== "object" || schema.TAG !== "Object") {
    return ;
  } else {
    return Core__Option.map(schema._0.find(function (param) {
                    return k === param.key;
                  }), (function (param) {
                  return param.value;
                }));
  }
}

function withoutInvalidFieldMapping(fieldMapping, columnInfos, layoutSchema) {
  return Object.fromEntries(Core__Array.reduce(Object.entries(fieldMapping), [], (function (acc, param) {
                    var columnId = param[1];
                    var field = param[0];
                    var columnInfo = columnInfos.find(function (c) {
                          return columnId === c.columnId;
                        });
                    var schema = field === rootKey ? layoutSchema : findNestedSchemaByKey(field, layoutSchema);
                    if (columnInfo === undefined) {
                      return acc;
                    }
                    if (schema === undefined) {
                      return acc;
                    }
                    var tmp;
                    tmp = typeof schema !== "object" || schema.TAG !== "Object" ? schema : ({
                          TAG: "Object",
                          _0: Utils__Array.toSorted(schema._0, (function (x1, x2) {
                                  return Core__String.compare(x1.key, x2.key);
                                }))
                        });
                    if (isComponentTypeValid(tmp, columnInfo.componentTypeCode, undefined)) {
                      return acc.concat([[
                                    field,
                                    columnInfo.columnId
                                  ]]);
                    } else {
                      return acc;
                    }
                  })));
}

function findCellByColumnId(cells, givenColumnId) {
  return cells.find(function (cell) {
              var cellSignature = Spaces__Cells.getCellSignature(cell);
              return Core__Option.getOr(Core__Option.map(cellSignature, (function (param) {
                                return param.columnId === givenColumnId;
                              })), false);
            });
}

function makeUnsupportedCellTypeError(componentTypeCode) {
  return "Unsupported cell type for block code: " + componentTypeCode;
}

function makeBlockData(cell, componentTypeCode, tableLinkInfo, columnLinkInfo, viewId, rowId, columnId) {
  if (componentTypeCode === "switch") {
    if (cell !== undefined) {
      if (cell.__typename === "SwitchCell") {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Switch",
                  _0: Spaces__SwitchCell.DataFragment.readInline(cell.fragmentRefs).switched
                }
              };
      } else {
        return {
                TAG: "Error",
                _0: makeUnsupportedCellTypeError(componentTypeCode)
              };
      }
    } else {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Switch",
                _0: undefined
              }
            };
    }
  }
  if (componentTypeCode === "type-last-updated-date" || componentTypeCode === "type-created-date") {
    if (cell !== undefined) {
      if (cell.__typename === "DateCell") {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "ReadOnlyDate",
                  _0: Spaces__DateCell.DataFragment.readInline(cell.fragmentRefs).date
                }
              };
      } else {
        return {
                TAG: "Error",
                _0: makeUnsupportedCellTypeError(componentTypeCode)
              };
      }
    } else {
      return {
              TAG: "Ok",
              _0: {
                TAG: "ReadOnlyDate",
                _0: undefined
              }
            };
    }
  }
  if (componentTypeCode === "image" || componentTypeCode === "content-image") {
    if (cell === undefined) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Image",
                _0: undefined
              }
            };
    }
    if (cell.__typename !== "ImageCell") {
      return {
              TAG: "Error",
              _0: makeUnsupportedCellTypeError(componentTypeCode)
            };
    }
    var image = Spaces__ImageCell.DataFragment.readInline(cell.fragmentRefs).image;
    var imageInfo = Spaces__ImageInfo.ImageInfoFragment.readInline(image.fragmentRefs);
    return {
            TAG: "Ok",
            _0: {
              TAG: "Image",
              _0: {
                id: DecidrNodeId.magicallyToInternalId(imageInfo.id),
                url: imageInfo.url,
                size: {
                  width: imageInfo.size.width,
                  height: imageInfo.size.height
                }
              }
            }
          };
  }
  if (componentTypeCode === "type-row-json-data") {
    return {
            TAG: "Ok",
            _0: {
              TAG: "Text",
              _0: DecidrInternalId.toString(rowId)
            }
          };
  }
  if (componentTypeCode === "type-current-logged-in-user" || componentTypeCode === "type-created-in-view" || componentTypeCode === "type-created-by" || componentTypeCode === "type-owned-by" || componentTypeCode === "type-created-from-column-view" || componentTypeCode === "type-last-updated-by" || componentTypeCode === "type-created-from-view") {
    if (tableLinkInfo === undefined) {
      return {
              TAG: "Error",
              _0: "Missing table link info for " + componentTypeCode
            };
    }
    var selectedRowId = cell !== undefined && cell.__typename === "MultiSelectCell" ? Spaces__MultiSelectCell.DataFragment.readInline(cell.fragmentRefs).data.selectedRows.map(function (param) {
              return DecidrNodeId.magicallyToInternalId(param.row.id);
            })[0] : undefined;
    return {
            TAG: "Ok",
            _0: {
              TAG: "SystemLink",
              _0: {
                sourceViewId: tableLinkInfo.sourceViewId,
                selectedRowId: selectedRowId
              }
            }
          };
  }
  if (componentTypeCode === "video" || componentTypeCode === "audio" || componentTypeCode === "type-file") {
    if (cell === undefined) {
      return {
              TAG: "Ok",
              _0: {
                TAG: "File",
                _0: undefined
              }
            };
    }
    switch (cell.__typename) {
      case "AudioCell" :
          var audio = Spaces__AudioCell.DataFragment.readInline(cell.fragmentRefs).audio;
          var fileInfo = Spaces__FileInfo.FileInfoFragment.readInline(audio.fragmentRefs);
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "File",
                    _0: {
                      url: fileInfo.url,
                      mimetype: fileInfo.mimetype,
                      size: fileInfo.size
                    }
                  }
                };
      case "FileCell" :
          var file = Spaces__FileCell.DataFragment.readInline(cell.fragmentRefs).file;
          var fileInfo$1 = Spaces__FileInfo.FileInfoFragment.readInline(file.fragmentRefs);
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "File",
                    _0: {
                      url: fileInfo$1.url,
                      mimetype: fileInfo$1.mimetype,
                      size: fileInfo$1.size
                    }
                  }
                };
      case "VideoCell" :
          var video = Spaces__VideoCell.DataFragment.readInline(cell.fragmentRefs).video;
          var fileInfo$2 = Spaces__FileInfo.FileInfoFragment.readInline(video.fragmentRefs);
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "File",
                    _0: {
                      url: fileInfo$2.url,
                      mimetype: fileInfo$2.mimetype,
                      size: fileInfo$2.size
                    }
                  }
                };
      default:
        return {
                TAG: "Error",
                _0: makeUnsupportedCellTypeError(componentTypeCode)
              };
    }
  } else {
    if (componentTypeCode === "lookup") {
      if (columnLinkInfo === undefined) {
        return {
                TAG: "Error",
                _0: "Missing column link info for " + componentTypeCode
              };
      }
      var referencedColumnViewId = columnLinkInfo.referencedColumnViewId;
      if (referencedColumnViewId === undefined) {
        if (columnLinkInfo.primitiveColumnViewId !== undefined) {
          return {
                  TAG: "Error",
                  _0: "Missing referencedColumnViewId for " + componentTypeCode
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Missing both primitiveColumnViewId and referencedColumnViewId for " + componentTypeCode
                };
        }
      }
      var primitiveColumnViewId = columnLinkInfo.primitiveColumnViewId;
      if (primitiveColumnViewId !== undefined) {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Lookup",
                  referencedColumnViewId: Caml_option.valFromOption(referencedColumnViewId),
                  primitiveColumnViewId: Caml_option.valFromOption(primitiveColumnViewId),
                  tableLinkInfoId: columnLinkInfo.tableLinkInfoId
                }
              };
      } else {
        return {
                TAG: "Error",
                _0: "Missing primitiveColumnViewId for " + componentTypeCode
              };
      }
    }
    if (componentTypeCode === "type-row-membership-history" || componentTypeCode === "type-row-membership") {
      return {
              TAG: "Ok",
              _0: {
                TAG: "Text",
                _0: DecidrInternalId.toString(rowId)
              }
            };
    }
    if (componentTypeCode === "type-created-from-row") {
      if (cell === undefined) {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "SystemLink",
                  _0: undefined
                }
              };
      }
      if (cell.__typename !== "MultiSelectCell") {
        return {
                TAG: "Error",
                _0: makeUnsupportedCellTypeError(componentTypeCode)
              };
      }
      var match = Spaces__MultiSelectCell.DataFragment.readInline(cell.fragmentRefs);
      var match$1 = match.data;
      return {
              TAG: "Ok",
              _0: {
                TAG: "SystemLink",
                _0: {
                  sourceViewId: match$1.sourceView.id,
                  selectedRowId: match$1.selectedRows.map(function (param) {
                          return DecidrNodeId.magicallyToInternalId(param.row.id);
                        })[0]
                }
              }
            };
    }
    if (componentTypeCode === "multi_select") {
      if (tableLinkInfo === undefined) {
        return {
                TAG: "Error",
                _0: "Missing table link info for " + componentTypeCode
              };
      }
      var selectedRowIds = cell !== undefined ? (
          cell.__typename === "MultiSelectCell" ? Spaces__MultiSelectCell.DataFragment.readInline(cell.fragmentRefs).data.selectedRows.map(function (param) {
                  return DecidrNodeId.magicallyToInternalId(param.row.id);
                }) : []
        ) : [];
      return {
              TAG: "Ok",
              _0: {
                TAG: "MultiSelect",
                sourceViewId: tableLinkInfo.sourceViewId,
                destinationViewId: viewId,
                rowId: rowId,
                columnId: columnId,
                selectedRowIds: selectedRowIds,
                tableLinkInfoId: tableLinkInfo.id
              }
            };
    }
    if (componentTypeCode === "number" || componentTypeCode === "currency" || componentTypeCode === "percent") {
      if (cell === undefined) {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Number",
                  _0: undefined
                }
              };
      }
      switch (cell.__typename) {
        case "CurrencyCell" :
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Number",
                      _0: Spaces__CurrencyCell.DataFragment.readInline(cell.fragmentRefs).currency
                    }
                  };
        case "NumberCell" :
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Number",
                      _0: Spaces__NumberCell.DataFragment.readInline(cell.fragmentRefs).number
                    }
                  };
        case "PercentCell" :
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Number",
                      _0: Spaces__PercentCell.DataFragment.readInline(cell.fragmentRefs).percent
                    }
                  };
        default:
          return {
                  TAG: "Error",
                  _0: makeUnsupportedCellTypeError(componentTypeCode)
                };
      }
    } else {
      if (componentTypeCode === "rollup-new") {
        if (columnLinkInfo !== undefined) {
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "Rollup",
                    tableLinkInfoId: columnLinkInfo.tableLinkInfoId
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Missing column link info for " + componentTypeCode
                };
        }
      }
      if (componentTypeCode === "related-list") {
        if (tableLinkInfo !== undefined) {
          return {
                  TAG: "Ok",
                  _0: {
                    TAG: "RelatedList",
                    sourceViewId: tableLinkInfo.sourceViewId,
                    tableLinkInfoId: tableLinkInfo.id,
                    callerRowId: rowId
                  }
                };
        } else {
          return {
                  TAG: "Error",
                  _0: "Missing table link info for " + componentTypeCode
                };
        }
      }
      if (cell === undefined) {
        return {
                TAG: "Ok",
                _0: {
                  TAG: "Text",
                  _0: undefined
                }
              };
      }
      switch (cell.__typename) {
        case "DateCell" :
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Text",
                      _0: Spaces__DateCell.DataFragment.readInline(cell.fragmentRefs).date
                    }
                  };
        case "TextCell" :
            return {
                    TAG: "Ok",
                    _0: {
                      TAG: "Text",
                      _0: Spaces__TextCell.DataFragment.readInline(cell.fragmentRefs).text
                    }
                  };
        default:
          return {
                  TAG: "Error",
                  _0: makeUnsupportedCellTypeError(componentTypeCode)
                };
      }
    }
  }
}

function makeRowContext(fieldMapping, rowId, cells, columnViews, viewId, organizationId) {
  var blockSpecificFields = Core__Array.filterMap(Object.entries(fieldMapping), (function (param) {
          var columnId = param[1];
          var key = param[0];
          var columnView = columnViews.find(function (columnView) {
                return columnView.columnId === columnId;
              });
          return Core__Option.map(columnView, (function (param) {
                        var componentTypeCode = param.componentTypeCode;
                        var columnId = param.columnId;
                        var cell = findCellByColumnId(cells, columnId);
                        var data = makeBlockData(cell, componentTypeCode, param.tableLinkInfo, param.columnLinkInfo, viewId, rowId, columnId);
                        var data$1;
                        if (data.TAG === "Ok") {
                          data$1 = data._0;
                        } else {
                          ErrorHandling__UnexpectedError.handle("Error making block data", "Spaces__Row__Utils makeRowContext", {
                                columnId: columnId,
                                rowId: rowId,
                                message: data._0
                              });
                          data$1 = undefined;
                        }
                        var dataEntries = Spaces__Row__Renderer__Utils.makeContextDataEntries(data$1, componentTypeCode);
                        var entries = dataEntries.concat(Spaces__Row__Renderer__Utils.makeContextCommonEntries({
                                  id: param.id,
                                  name: param.name,
                                  columnId: columnId,
                                  iconUrl: param.componentTypeIconUrl,
                                  componentTypeCode: componentTypeCode
                                }));
                        return [
                                key,
                                Object.fromEntries(entries)
                              ];
                      }));
        }));
  return Renderer__Context.fromJson(Object.fromEntries([
                    [
                      Spaces__Row__Renderer__BlockContext.Common.Keys.organizationId,
                      DecidrNodeId.toString(organizationId)
                    ],
                    [
                      Spaces__Row__Renderer__BlockContext.Common.Keys.rowId,
                      DecidrInternalId.toString(rowId)
                    ],
                    [
                      Spaces__Row__Renderer__BlockContext.Common.Keys.viewId,
                      DecidrNodeId.toString(viewId)
                    ]
                  ].concat(blockSpecificFields)));
}

export {
  LayoutElement ,
  validComponentTypesPerSchema ,
  ComparableContextSchema ,
  guessFieldMapping ,
  withoutInvalidFieldMapping ,
  findCellByColumnId ,
  makeBlockData ,
  makeRowContext ,
}
/* DecidrNodeId Not a pure module */
