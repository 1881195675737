// Generated by ReScript, PLEASE EDIT WITH CARE

import Format from "date-fns/format";
import IsValid from "date-fns/isValid";
import ParseISO from "date-fns/parseISO";
import FormatISO from "date-fns/formatISO";

function format(prim0, prim1) {
  return Format(prim0, prim1);
}

function formatISO(prim) {
  return FormatISO(prim);
}

function parseISO(prim) {
  return ParseISO(prim);
}

function isValid(prim) {
  return IsValid(prim);
}

export {
  format ,
  formatISO ,
  parseISO ,
  isValid ,
}
/* date-fns/format Not a pure module */
