import { SizableText, SizableTextProps } from "tamagui";
import merge from "lodash.merge";
import {
  // FontSize_fontLetterSpacingTokens,
  FontSize_fontLineHeightTokens,
  FontSize_fontSizeTokens,
  FontSize_t as font,
} from "@decidr/tamagui-config/src/tokens/TamaguiConfig__Tokens.gen";

const mergeResponsiveStyles = merge;

type BaseTextProps = Omit<SizableTextProps, "fs" | "fontSize" | "size"> & {
  kind?: font;
};

// Commented out `letterSpacing` for now as it looks better without
export const makeTextResponsiveStyles = (font: font) => {
  return {
    $s: {
      fontSize: FontSize_fontSizeTokens[`font_s_${font}`],
      lineHeight: FontSize_fontLineHeightTokens[`font_s_${font}`],
      // letterSpacing: FontSize_fontLetterSpacingTokens[`font_s_${font}`],
    },
    $m: {
      fontSize: FontSize_fontSizeTokens[`font_m_${font}`],
      lineHeight: FontSize_fontLineHeightTokens[`font_m_${font}`],
      // letterSpacing: FontSize_fontLetterSpacingTokens[`font_m_${font}`],
    },
    $l: {
      fontSize: FontSize_fontSizeTokens[`font_l_${font}`],
      lineHeight: FontSize_fontLineHeightTokens[`font_l_${font}`],
      // letterSpacing: FontSize_fontLetterSpacingTokens[`font_l_${font}`],
    },
    $xl: {
      fontSize: FontSize_fontSizeTokens[`font_xl_${font}`],
      lineHeight: FontSize_fontLineHeightTokens[`font_xl_${font}`],
      // letterSpacing: FontSize_fontLetterSpacingTokens[`font_xl_${font}`],
    },
  };
};

export const BaseText = ({
  $s,
  $m,
  $l,
  $xl,
  kind = "body2",
  tag,
  ...props
}: BaseTextProps) => {
  return (
    <SizableText
      pe="none"
      selectable={false}
      {...mergeResponsiveStyles(
        {
          $s,
          $m,
          $l,
          $xl,
        },
        makeTextResponsiveStyles(kind)
      )}
      {...props}
      tag={tag ?? "span"}
      componentName="BaseText"
      fontFamily="$decidrFont"
      accessibilityRole="text"
      m={0}
    />
  );
};
