// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Primitives__Icon from "./Primitives__Icon";
import * as RescriptTamagui__Icon from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon.bs.js";

var make = Primitives__Icon.Icon;

var LogOut = RescriptTamagui__Icon.LogOut;

var Rocket = RescriptTamagui__Icon.Rocket;

var ArrowLeft = RescriptTamagui__Icon.ArrowLeft;

var ArrowUp = RescriptTamagui__Icon.ArrowUp;

var ArrowDown = RescriptTamagui__Icon.ArrowDown;

var ChevronDown = RescriptTamagui__Icon.ChevronDown;

var ChevronUp = RescriptTamagui__Icon.ChevronUp;

var ChevronRight = RescriptTamagui__Icon.ChevronRight;

var ThumbsUp = RescriptTamagui__Icon.ThumbsUp;

var ListChecks = RescriptTamagui__Icon.ListChecks;

var CalendarX = RescriptTamagui__Icon.CalendarX;

var Box = RescriptTamagui__Icon.Box;

var Users = RescriptTamagui__Icon.Users;

var Truck = RescriptTamagui__Icon.Truck;

var Megaphone = RescriptTamagui__Icon.Megaphone;

var X = RescriptTamagui__Icon.X;

var Send = RescriptTamagui__Icon.Send;

var BarChart3 = RescriptTamagui__Icon.BarChart3;

var Hash = RescriptTamagui__Icon.Hash;

var Settings = RescriptTamagui__Icon.Settings;

var Search = RescriptTamagui__Icon.Search;

var Eye = RescriptTamagui__Icon.Eye;

var Edit = RescriptTamagui__Icon.Edit;

var EyeOff = RescriptTamagui__Icon.EyeOff;

var ChevronLeft = RescriptTamagui__Icon.ChevronLeft;

var Link = RescriptTamagui__Icon.Link;

var User = RescriptTamagui__Icon.User;

var Bot = RescriptTamagui__Icon.Bot;

var Lock = RescriptTamagui__Icon.Lock;

var SmilePlus = RescriptTamagui__Icon.SmilePlus;

var MessagesSquare = RescriptTamagui__Icon.MessagesSquare;

var Forward = RescriptTamagui__Icon.Forward;

var Smile = RescriptTamagui__Icon.Smile;

var MoreVertical = RescriptTamagui__Icon.MoreVertical;

var Play = RescriptTamagui__Icon.Play;

var DownloadCloud = RescriptTamagui__Icon.DownloadCloud;

var Plus = RescriptTamagui__Icon.Plus;

var Home = RescriptTamagui__Icon.Home;

var Bell = RescriptTamagui__Icon.Bell;

var Menu = RescriptTamagui__Icon.Menu;

var Trash2 = RescriptTamagui__Icon.Trash2;

var ListTree = RescriptTamagui__Icon.ListTree;

var Shield = RescriptTamagui__Icon.Shield;

var Database = RescriptTamagui__Icon.Database;

var Check = RescriptTamagui__Icon.Check;

var PanelLeftClose = RescriptTamagui__Icon.PanelLeftClose;

var PanelRightClose = RescriptTamagui__Icon.PanelRightClose;

var MoreHorizontal = RescriptTamagui__Icon.MoreHorizontal;

var ListFilter = RescriptTamagui__Icon.ListFilter;

var ArrowDownNarrowWide = RescriptTamagui__Icon.ArrowDownNarrowWide;

var Info = RescriptTamagui__Icon.Info;

var Sparkles = RescriptTamagui__Icon.Sparkles;

var Unlink = RescriptTamagui__Icon.Unlink;

var Binary = RescriptTamagui__Icon.Binary;

var CaseSensitive = RescriptTamagui__Icon.CaseSensitive;

var Upload = RescriptTamagui__Icon.Upload;

var MessageCircle = RescriptTamagui__Icon.MessageCircle;

var Table = RescriptTamagui__Icon.Table;

var Columns = RescriptTamagui__Icon.Columns;

var Rows = RescriptTamagui__Icon.Rows;

var Component = RescriptTamagui__Icon.Component;

var ArrowLeftRight = RescriptTamagui__Icon.ArrowLeftRight;

var Expand = RescriptTamagui__Icon.Expand;

var Copy = RescriptTamagui__Icon.Copy;

var ArrowDownFromLine = RescriptTamagui__Icon.ArrowDownFromLine;

var ArrowUpFromLine = RescriptTamagui__Icon.ArrowUpFromLine;

var RefreshCw = RescriptTamagui__Icon.RefreshCw;

var FileSearch = RescriptTamagui__Icon.FileSearch;

var Calculator = RescriptTamagui__Icon.Calculator;

var Crown = RescriptTamagui__Icon.Crown;

var Percent = RescriptTamagui__Icon.Percent;

var DollarSign = RescriptTamagui__Icon.DollarSign;

var ArrowUpAZ = RescriptTamagui__Icon.ArrowUpAZ;

var ArrowDownAZ = RescriptTamagui__Icon.ArrowDownAZ;

var UserCheck2 = RescriptTamagui__Icon.UserCheck2;

var FileCog = RescriptTamagui__Icon.FileCog;

var $$Text = RescriptTamagui__Icon.$$Text;

var Heading1 = RescriptTamagui__Icon.Heading1;

var $$Image = RescriptTamagui__Icon.$$Image;

var GripVertical = RescriptTamagui__Icon.GripVertical;

var FileSymlink = RescriptTamagui__Icon.FileSymlink;

var LayoutList = RescriptTamagui__Icon.LayoutList;

var AlertTriangle = RescriptTamagui__Icon.AlertTriangle;

var MailX = RescriptTamagui__Icon.MailX;

var Mail = RescriptTamagui__Icon.Mail;

var MailCheck = RescriptTamagui__Icon.MailCheck;

var MailWarning = RescriptTamagui__Icon.MailWarning;

var UserX2 = RescriptTamagui__Icon.UserX2;

var UserCog2 = RescriptTamagui__Icon.UserCog2;

var UserMinus2 = RescriptTamagui__Icon.UserMinus2;

var UserPlus2 = RescriptTamagui__Icon.UserPlus2;

var MessageSquarePlus = RescriptTamagui__Icon.MessageSquarePlus;

export {
  LogOut ,
  Rocket ,
  ArrowLeft ,
  ArrowUp ,
  ArrowDown ,
  ChevronDown ,
  ChevronUp ,
  ChevronRight ,
  ThumbsUp ,
  ListChecks ,
  CalendarX ,
  Box ,
  Users ,
  Truck ,
  Megaphone ,
  X ,
  Send ,
  BarChart3 ,
  Hash ,
  Settings ,
  Search ,
  Eye ,
  Edit ,
  EyeOff ,
  ChevronLeft ,
  Link ,
  User ,
  Bot ,
  Lock ,
  SmilePlus ,
  MessagesSquare ,
  Forward ,
  Smile ,
  MoreVertical ,
  Play ,
  DownloadCloud ,
  Plus ,
  Home ,
  Bell ,
  Menu ,
  Trash2 ,
  ListTree ,
  Shield ,
  Database ,
  Check ,
  PanelLeftClose ,
  PanelRightClose ,
  MoreHorizontal ,
  ListFilter ,
  ArrowDownNarrowWide ,
  Info ,
  Sparkles ,
  Unlink ,
  Binary ,
  CaseSensitive ,
  Upload ,
  MessageCircle ,
  Table ,
  Columns ,
  Rows ,
  Component ,
  ArrowLeftRight ,
  Expand ,
  Copy ,
  ArrowDownFromLine ,
  ArrowUpFromLine ,
  RefreshCw ,
  FileSearch ,
  Calculator ,
  Crown ,
  Percent ,
  DollarSign ,
  ArrowUpAZ ,
  ArrowDownAZ ,
  UserCheck2 ,
  FileCog ,
  $$Text ,
  Heading1 ,
  $$Image ,
  GripVertical ,
  FileSymlink ,
  LayoutList ,
  AlertTriangle ,
  MailX ,
  Mail ,
  MailCheck ,
  MailWarning ,
  UserX2 ,
  UserCog2 ,
  UserMinus2 ,
  UserPlus2 ,
  MessageSquarePlus ,
  make ,
}
/* make Not a pure module */
