import "@tamagui/core/reset.css";

import "raf/polyfill"; // Needed for Reanimated 2.x

import "../styles/globals.css";

import { useMemo, useRef } from "react";
import { NextThemeProvider, useRootTheme } from "@tamagui/next-theme";
import Head from "next/head";
import { Router } from "next/router";
import { Roboto } from "next/font/google";
import { Amplify } from "aws-amplify";
import { SolitoAppProps } from "solito";

import { AppProvider } from "@decidr/app/src/provider";
import { trackPageView } from "@decidr/app/src/analytics/App__Analytics__Utils.gen";
import { useSendEvents } from "@decidr/analytics/src/hooks/useSendEvents";
import { registerBlocks } from "@decidr/renderer/src/utils/DecidrRenderer__BlockRegistry.gen";
import { registerBlocks as registerSpaceBlocks } from "@decidr/spaces/src/row/renderer/Spaces__Row__Renderer__BlockIndex.gen";
import {
  baseAmplifyConfig,
  defaultAmplifyLibraryOptions,
} from "@decidr/app/src/amplifyConfig";

const isSSR = typeof window === "undefined";

registerBlocks();
registerSpaceBlocks();

const roboto = Roboto({
  weight: ["400", "500", "700"],
  style: "normal",
  subsets: ["latin"],
  display: "swap",
});

Amplify.configure(baseAmplifyConfig, defaultAmplifyLibraryOptions);

if (!isSSR) {
  // routeChangeComplete from next router can't register initial page loads
  // so we check if the page is loaded and then send the page event that matches the url
  // This will fix initial page loads or refreshes
  trackPageView({
    path: window.location.pathname,
    referrer: document.referrer,
    search: window.location.search,
    title: document.title,
  });

  Router.events.on("routeChangeComplete", url => {
    // url is typed as any, so we just want to make sure that it is a string
    if (typeof url === "string") {
      trackPageView({
        path: url,
        referrer: document.referrer,
        search: window.location.search,
        title: document.title,
      });
    }
  });
}

function ThemeProvider({
  children,
  initialRelayStore,
}: {
  children: React.ReactNode;
  initialRelayStore?: Record<string, Record<string, string>>;
}) {
  const [theme, setTheme] = useRootTheme({ fallback: "light" });

  return (
    <NextThemeProvider
      forcedTheme="light"
      onChangeTheme={theme => {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any, @typescript-eslint/no-unsafe-argument
        setTheme(theme as any);
      }}
    >
      <AppProvider
        disableRootThemeClass
        defaultTheme={theme}
        initialRelayStore={initialRelayStore}
      >
        {children}
      </AppProvider>
    </NextThemeProvider>
  );
}

interface PageProps {
  __relayStore__?: Record<string, Record<string, string>>;
}

function MyApp({ Component, pageProps, router }: SolitoAppProps) {
  const { __relayStore__ } = pageProps as PageProps;
  const relayStoreRef = useRef<Record<string, PageProps["__relayStore__"]>>({});
  useMemo(() => {
    const key = (router as unknown as { _key: string })._key;
    // NOTE: This flag expected that the store will not be reapplied in the same history
    // example) pagination of infinite loading connection
    if (!relayStoreRef.current[key]) {
      relayStoreRef.current[key] = __relayStore__;
    }
  }, [__relayStore__, router]);

  useSendEvents();

  return (
    <>
      <Head>
        {/* TODO AB Check whether maximum-scale is acceptable */}
        <meta
          name="viewport"
          content="width=device-width,initial-scale=1,maximum-scale=1"
        />
        <style jsx global>{`
          html {
            font-family: ${roboto.style.fontFamily};
          }
        `}</style>
      </Head>
      <ThemeProvider initialRelayStore={__relayStore__}>
        <Component {...pageProps} />
      </ThemeProvider>
    </>
  );
}

export default MyApp;
