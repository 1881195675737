// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

function makeRefetchVariables(rowId, skipQuery, viewId) {
  return {
          rowId: rowId,
          skipQuery: skipQuery,
          viewId: viewId
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

function unwrap_response_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["View"]);
}

var variablesConverter = {"__root":{"viewId":{"c":"DecidrNodeId.RelayAppSync"},"rowId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"node_View_row_cellsJson":{"c":"RelayAppSync.AWSJSON"},"node_View_row":{"f":""},"node":{"u":"response_node"}}};

var wrapResponseConverterMap = {
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.serialize,
  response_node: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"node_View_row_cellsJson":{"c":"RelayAppSync.AWSJSON"},"node_View_row":{"f":""},"node":{"u":"response_node"}}};

var responseConverterMap = {
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse,
  response_node: unwrap_response_node
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipQuery"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "viewId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "viewId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v5 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "rowId"
  }
],
v6 = {
  "alias": null,
  "args": [
    {
      "kind": "Literal",
      "name": "maxDepth",
      "value": 5
    }
  ],
  "kind": "ScalarField",
  "name": "cellsJson",
  "storageKey": "cellsJson(maxDepth:5)"
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesBlockRowJsonDataQuery",
    "selections": [
      {
        "condition": "skipQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "Row",
                    "kind": "LinkedField",
                    "name": "row",
                    "plural": false,
                    "selections": [
                      {
                        "args": null,
                        "kind": "FragmentSpread",
                        "name": "SpacesCache_row"
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "View",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesBlockRowJsonDataQuery",
    "selections": [
      {
        "condition": "skipQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v3/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": (v5/*: any*/),
                    "concreteType": "Row",
                    "kind": "LinkedField",
                    "name": "row",
                    "plural": false,
                    "selections": [
                      (v7/*: any*/),
                      {
                        "alias": null,
                        "args": null,
                        "kind": "ScalarField",
                        "name": "viewId",
                        "storageKey": null
                      },
                      (v6/*: any*/)
                    ],
                    "storageKey": null
                  }
                ],
                "type": "View",
                "abstractKey": null
              },
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "e08760cd911227c3d2a4929944f4cd50",
    "id": null,
    "metadata": {},
    "name": "SpacesBlockRowJsonDataQuery",
    "operationKind": "query",
    "text": "query SpacesBlockRowJsonDataQuery(\n  $viewId: ID!\n  $rowId: ID!\n  $skipQuery: Boolean!\n) {\n  node(id: $viewId) @skip(if: $skipQuery) {\n    __typename\n    ... on View {\n      row(id: $rowId) {\n        ...SpacesCache_row\n        cellsJson(maxDepth: 5)\n        id\n      }\n    }\n    id\n  }\n}\n\nfragment SpacesCache_row on Row {\n  id\n  viewId\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

var wrap_response_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_response_node ,
  wrap_response_node ,
  Internal ,
  Utils ,
  node ,
  load ,
  queryRefToObservable ,
  queryRefToPromise ,
}
/* node Not a pure module */
