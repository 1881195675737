// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__ScrollView from "../../../rescript-tamagui/src/scrollView/RescriptTamagui__ScrollView.bs.js";

function Primitives__ScrollView(props) {
  var flex = props.flex;
  var __children = props.children;
  var __specificStyles = props.specificStyles;
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var specificStyles = __specificStyles !== undefined ? __specificStyles : TamaguiConfig.Utils.emptySpecificStyles;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var newrecord = Caml_obj.obj_dup(style);
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle((newrecord.gap = Core__Option.map(props.gap, Primitives__Styling.Gap.toRNGap), newrecord), Caml_option.some(Primitives__Styling.makeSpecificStyles(specificStyles)), undefined));
  var style$1 = stylesDict["common"];
  return JsxRuntime.jsx(RescriptTamagui__ScrollView.make, {
              horizontal: props.horizontal,
              children: Caml_option.some(children),
              style: Core__Option.map(style$1, (function (__x) {
                      return Primitives__Styling.Utils.omitStyleKeys(__x, [
                                  "alignItems",
                                  "backgroundColor",
                                  "borderColor"
                                ]);
                    })),
              contentContainerStyle: Core__Option.map(style$1, (function (s) {
                      return {
                              alignItems: s.alignItems,
                              flex: flex
                            };
                    })),
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              bc: Core__Option.flatMap(style$1, (function (s) {
                      return s.backgroundColor;
                    })),
              boc: Core__Option.flatMap(style$1, (function (s) {
                      return s.borderColor;
                    }))
            });
}

var make = Primitives__ScrollView;

export {
  make ,
}
/* TamaguiConfig Not a pure module */
