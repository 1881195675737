// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__Icon__MessageCircle from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__MessageCircle.bs.js";

function Spaces__Ui__ChannelButton$UnreadMessagesIndicator(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color("secondary_1"),
                borderRadius: 8,
                height: Caml_option.some(Primitives__Styling.dp(8)),
                position: "absolute",
                right: Caml_option.some(Primitives__Styling.dp(-1)),
                top: Caml_option.some(Primitives__Styling.dp(-1)),
                width: Caml_option.some(Primitives__Styling.dp(8))
              }
            });
}

var UnreadMessagesIndicator = {
  make: Spaces__Ui__ChannelButton$UnreadMessagesIndicator
};

function Spaces__Ui__ChannelButton(props) {
  var __icon = props.icon;
  var numberOfUnreadMessages = props.numberOfUnreadMessages;
  var __creatingChannel = props.creatingChannel;
  var __disabled = props.disabled;
  var onPress = props.onPress;
  var disabled = __disabled !== undefined ? __disabled : false;
  var creatingChannel = __creatingChannel !== undefined ? __creatingChannel : false;
  var icon = __icon !== undefined ? __icon : RescriptTamagui__Icon__MessageCircle.make;
  return JsxRuntime.jsxs(Primitives__UnstyledButton.make, {
              children: [
                numberOfUnreadMessages !== undefined && numberOfUnreadMessages !== 0 ? JsxRuntime.jsx(Spaces__Ui__ChannelButton$UnreadMessagesIndicator, {}) : null,
                JsxRuntime.jsx(Primitives__Icon.make, {
                      size: "ic_3",
                      color: disabled || creatingChannel ? Primitives__Styling.color({
                              NAME: "icon",
                              VAL: {
                                NAME: "neutral",
                                VAL: "subdued"
                              }
                            }) : Core__Option.getOr(props.color, Primitives__Styling.color("neutral_1")),
                      icon: icon
                    })
              ],
              style: props.style,
              onPress: (function (e) {
                  if (disabled || creatingChannel) {
                    return ;
                  } else {
                    return onPress(e);
                  }
                })
            });
}

var make = Spaces__Ui__ChannelButton;

export {
  UnreadMessagesIndicator ,
  make ,
}
/* Primitives__Icon Not a pure module */
