// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Blocks__Utils from "@pageblocks/blocks/src/utils/Blocks__Utils.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Spaces__Block__Common from "../../block/Spaces__Block__Common.bs.js";
import * as DecidrRenderer__Actions from "../../../../renderer/src/actions/DecidrRenderer__Actions.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as Spaces__Row__Renderer__BlockUtils from "./Spaces__Row__Renderer__BlockUtils.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "./Spaces__Row__Renderer__BlockContext.bs.js";

var blockCode = "view-navigator";

function Spaces__Row__Renderer__ViewNavigatorBlock$Component(props) {
  var handleAction = props.handleAction;
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var onClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(props.triggerActions), (function (__x) {
                  return __x["onClick"];
                })), Blocks__Utils.getActions), []);
  var viewIdToOpen = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.SelectView.Keys.viewId), DecidrNodeId.fromString);
  var handleClick = function () {
    if (onClickActions.length !== 0 && handleAction !== undefined) {
      Spaces__Block__Common.extendActionsPayloadWithEntries(onClickActions, [[
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.viewIdToOpen),
                Core__Option.getOr(Core__Option.map(Core__Option.map(viewIdToOpen, DecidrNodeId.toString), (function (prim) {
                            return prim;
                          })), null)
              ]]).forEach(function (__x) {
            handleAction(__x);
          });
      return ;
    }
    
  };
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: Core__Option.getOr(props.children, null),
              onPress: (function (_e) {
                  handleClick();
                })
            });
}

var Component = {
  make: Spaces__Row__Renderer__ViewNavigatorBlock$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var Context;

var version = 1;

var minRendererVersion = Renderer__Version.current;

var ContextKeys;

export {
  Context ,
  blockCode ,
  version ,
  minRendererVersion ,
  ContextKeys ,
  Component ,
  descriptor ,
}
/* DecidrNodeId Not a pure module */
