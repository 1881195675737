// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as RelayStoreHelpers from "./relayStoreHelpers";

function readCachedViewIds(prim) {
  return RelayStoreHelpers.readCachedViewIds(prim);
}

function getCachedRowsToUpdate(prim0, prim1) {
  return RelayStoreHelpers.getCachedRowsToUpdate(prim0, prim1);
}

function getCachedViewIdsFromRowId(prim0, prim1) {
  return RelayStoreHelpers.getCachedViewIdsFromRowId(prim0, prim1);
}

function readCachedTableLinkInfosToUpdate(prim0, prim1) {
  return RelayStoreHelpers.readCachedTableLinkInfosToUpdate(prim0, prim1);
}

function readCachedTableLinkInfosByColumnId(prim0, prim1) {
  return RelayStoreHelpers.readCachedTableLinkInfosByColumnId(prim0, prim1);
}

function readCachedColumnsByColumnLinkInfoTableLinkId(prim0, prim1) {
  return RelayStoreHelpers.readCachedColumnsByColumnLinkInfoTableLinkId(prim0, prim1);
}

function getAllViewsToUpdate(store, allCachedViewIds, columnId, callerRowId) {
  return Core__Array.filterMap(allCachedViewIds, (function (cachedViewId) {
                return Core__Option.flatMap(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(cachedViewId))), (function (cachedViewRP) {
                              var columnViews = Core__Option.getOr((function (__x) {
                                        return RelayUtils.RecordProxy.getLinkedRecords(__x, "columnViews", Core__Option.map(callerRowId, (function (callerRowId) {
                                                          return {
                                                                  callerRowId: callerRowId
                                                                };
                                                        })));
                                      })(cachedViewRP), []);
                              var columnViewsWithHiddenFalse = Core__Option.getOr((function (__x) {
                                        return RelayUtils.RecordProxy.getLinkedRecords(__x, "columnViews", {
                                                    showHidden: false
                                                  });
                                      })(cachedViewRP), []);
                              var columnViewsWithHiddenTrue = Core__Option.getOr((function (__x) {
                                        return RelayUtils.RecordProxy.getLinkedRecords(__x, "columnViews", {
                                                    showHidden: true
                                                  });
                                      })(cachedViewRP), []);
                              var allColumnViews = columnViews.concat(columnViewsWithHiddenFalse, columnViewsWithHiddenTrue);
                              var hasCellColumn = allColumnViews.filter(function (columnViewRP) {
                                      return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(columnViewRP, (function (__x) {
                                                            return RelayUtils.RecordProxy.getValueBool(__x, "isHidden", undefined);
                                                          })), (function (isHidden) {
                                                        return !isHidden;
                                                      })), false);
                                    }).some(function (columnViewRP) {
                                    return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(columnViewRP, (function (__x) {
                                                          return RelayUtils.RecordProxy.getValueString(__x, "columnId", undefined);
                                                        })), (function (cvColumnId) {
                                                      return cvColumnId === DecidrInternalId.toString(columnId);
                                                    })), false);
                                  });
                              if (hasCellColumn) {
                                return Caml_option.some(cachedViewId);
                              }
                              
                            }));
              }));
}

export {
  readCachedViewIds ,
  getCachedRowsToUpdate ,
  getCachedViewIdsFromRowId ,
  readCachedTableLinkInfosToUpdate ,
  readCachedTableLinkInfosByColumnId ,
  readCachedColumnsByColumnLinkInfoTableLinkId ,
  getAllViewsToUpdate ,
}
/* RelayUtils Not a pure module */
