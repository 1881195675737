// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Utils__Ignored from "../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as ExpoClipboard from "expo-clipboard";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__IconButton from "../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as Primitives__ScrollView from "../../../primitives/src/scrollView/Primitives__ScrollView.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as RescriptTamagui__Icon__Copy from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Copy.bs.js";
import * as SpacesBlockRowJsonDataQuery_graphql from "../../../relay-codegen/generated/SpacesBlockRowJsonDataQuery_graphql.bs.js";

function Spaces__Block__RowJsonData$CopyJsonButton(props) {
  var __showToast = props.showToast;
  var jsonData = props.jsonData;
  var showToast = __showToast !== undefined ? __showToast : false;
  var match = Primitives__DecidrToast.use();
  var show = match.show;
  return JsxRuntime.jsx(Primitives__IconButton.make, {
              kind: "secondary",
              icon: RescriptTamagui__Icon__Copy.make,
              onPress: (function (param) {
                  Utils__Ignored.promise(ExpoClipboard.setStringAsync(JSON.stringify(jsonData), undefined).then(function (param) {
                            if (showToast) {
                              return show("Copied to clipboard", {
                                          message: "JSON data has been copied to you clipboard",
                                          duration: 3000
                                        });
                            }
                            
                          }));
                }),
              size: "small"
            });
}

var convertVariables = SpacesBlockRowJsonDataQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockRowJsonDataQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockRowJsonDataQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockRowJsonDataQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockRowJsonDataQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockRowJsonDataQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockRowJsonDataQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockRowJsonDataQuery_graphql.node, convertVariables);

function Spaces__Block__RowJsonData$JsonDataDisplay(props) {
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use({
        rowId: DecidrNodeId.magicallyFromInternalId(props.rowId),
        skipQuery: !isAuthenticated,
        viewId: props.viewId
      }, "network-only", undefined, undefined);
  var jsonData = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(match$1.node, (function (node) {
                  if (node.__typename === "View") {
                    return node.row;
                  }
                  
                })), (function (param) {
              return param.cellsJson;
            })), null);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              style: {
                flex: 1
              },
              children: [
                JsxRuntime.jsx(Primitives__Group.make, {
                      style: {
                        position: "absolute",
                        right: Caml_option.some(Primitives__Styling.space("cs_2")),
                        top: Caml_option.some(Primitives__Styling.space("cs_2")),
                        zIndex: 5
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__RowJsonData$CopyJsonButton, {
                                jsonData: jsonData
                              }))
                    }),
                JsxRuntime.jsx(Primitives__ScrollView.make, {
                      style: {
                        borderColor: Primitives__Styling.color({
                              NAME: "border",
                              VAL: {
                                NAME: "neutral",
                                VAL: "default"
                              }
                            }),
                        borderTopWidth: 1
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                style: {
                                  backgroundColor: Primitives__Styling.color({
                                        NAME: "background",
                                        VAL: "default"
                                      }),
                                  flex: 1,
                                  padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                                },
                                children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                          children: Caml_option.some(JSON.stringify(jsonData, null, 2))
                                        }))
                              }))
                    })
              ]
            });
}

function Spaces__Block__RowJsonData$FullJsonPreviewModal(props) {
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Primitives__Modal.LongContentWebFix.make, {
                    children: JsxRuntime.jsxs(Primitives__Group.make, {
                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                          style: {
                            backgroundColor: Primitives__Styling.color({
                                  NAME: "surface",
                                  VAL: "default"
                                }),
                            flex: 1
                          },
                          children: [
                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                  style: {
                                    fontWeight: 500,
                                    paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3"))
                                  },
                                  kind: "body1",
                                  children: "JSON viewer"
                                }),
                            JsxRuntime.jsx(Primitives__Suspense.make, {
                                  children: JsxRuntime.jsx(Spaces__Block__RowJsonData$JsonDataDisplay, {
                                        rowId: props.rowId,
                                        viewId: props.viewId
                                      })
                                })
                          ]
                        })
                  }),
              show: props.show,
              onClose: props.onClose,
              maxWidth: 700
            });
}

function Spaces__Block__RowJsonData(props) {
  var match = React.useState(function () {
        return false;
      });
  var setPreview = match[1];
  return JsxRuntime.jsxs(Primitives__Group.make, {
              children: [
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "See JSON",
                      style: {
                        alignSelf: "flex-start"
                      },
                      onPress: (function (param) {
                          setPreview(function (param) {
                                return true;
                              });
                        }),
                      size: "small"
                    }),
                JsxRuntime.jsx(Spaces__Block__RowJsonData$FullJsonPreviewModal, {
                      rowId: props.rowId,
                      viewId: props.viewId,
                      show: match[0],
                      onClose: (function () {
                          setPreview(function (param) {
                                return false;
                              });
                        })
                    })
              ]
            });
}

var make = Spaces__Block__RowJsonData;

export {
  make ,
}
/* use Not a pure module */
