// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Select from "../../../primitives/src/select/Primitives__Select.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__View__Mutations from "../view/Spaces__View__Mutations.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesBlockColumnCountInsightsQuery_graphql from "../../../relay-codegen/generated/SpacesBlockColumnCountInsightsQuery_graphql.bs.js";
import * as SpacesBlockColumnCountInsightsContentQuery_graphql from "../../../relay-codegen/generated/SpacesBlockColumnCountInsightsContentQuery_graphql.bs.js";
import * as SpacesBlockColumnCountInsightsColumnViewPicker_query_graphql from "../../../relay-codegen/generated/SpacesBlockColumnCountInsightsColumnViewPicker_query_graphql.bs.js";

function Spaces__Block__ColumnCountInsights$Labeled(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      children: Caml_option.some(props.label)
                    }),
                props.children
              ]
            });
}

var Labeled = {
  make: Spaces__Block__ColumnCountInsights$Labeled
};

var convertFragment = SpacesBlockColumnCountInsightsColumnViewPicker_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockColumnCountInsightsColumnViewPicker_query_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesBlockColumnCountInsightsColumnViewPicker_query_graphql.node, convertFragment);
}

var QueryFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

function Spaces__Block__ColumnCountInsights$ColumnViewPicker(props) {
  var __title = props.title;
  var keepColumnViewBy = props.keepColumnViewBy;
  var onSelect = props.onSelect;
  var title = __title !== undefined ? __title : "Select Column View";
  var match = use(props.queryRef);
  var columnViews = Core__Option.getOr(Core__Option.map(match.node, (function (node) {
              if (node.__typename === "View") {
                return node.columnViews;
              } else {
                return [];
              }
            })), []);
  return JsxRuntime.jsx(Primitives__Select.make, {
              title: title,
              data: keepColumnViewBy !== undefined ? Core__Array.filterMap(columnViews, keepColumnViewBy) : columnViews,
              selectedItem: props.selectedItem,
              renderItem: (function (param) {
                  var close = param.close;
                  var item = param.item;
                  return JsxRuntime.jsx(Primitives__Select.Item.make, {
                              selected: Core__Option.map(param.selectedItem, (function (selectedItem) {
                                      if (selectedItem.TAG === "ColumnView") {
                                        return selectedItem._0 === item.id;
                                      } else {
                                        return selectedItem._0 === DecidrNodeId.magicallyToInternalId(item.columnId);
                                      }
                                    })),
                              text: item.name,
                              onPress: (function () {
                                  onSelect(item);
                                  close();
                                })
                            });
                }),
              keyExtractor: (function (param) {
                  return DecidrNodeId.toString(param.id);
                }),
              trigger: (function (selectedItem, show) {
                  return JsxRuntime.jsx(Primitives__Select.Trigger.make, {
                              text: Core__Option.getOr(Core__Option.flatMap(selectedItem, (function (selectedItem) {
                                          var tmp;
                                          if (selectedItem.TAG === "ColumnView") {
                                            var id = selectedItem._0;
                                            tmp = columnViews.find(function (columnView) {
                                                  return id === columnView.id;
                                                });
                                          } else {
                                            var id$1 = selectedItem._0;
                                            tmp = columnViews.find(function (columnView) {
                                                  return id$1 === DecidrNodeId.magicallyToInternalId(columnView.columnId);
                                                });
                                          }
                                          return Core__Option.map(tmp, (function (param) {
                                                        return param.name;
                                                      }));
                                        })), title),
                              onPress: (function (_e) {
                                  show();
                                }),
                              style: {
                                alignSelf: "flex-start"
                              },
                              borderColor: Primitives__Styling.color({
                                    NAME: "border",
                                    VAL: {
                                      NAME: "neutral",
                                      VAL: "default"
                                    }
                                  })
                            });
                }),
              emptyDataText: "No Column Views. Create some first!"
            });
}

var ColumnViewPicker = {
  QueryFragment: QueryFragment,
  make: Spaces__Block__ColumnCountInsights$ColumnViewPicker
};

var columnId = "column_count_insights::column_id";

var SerializationKeys = {
  columnId: columnId
};

function serialize(config) {
  return Object.fromEntries([[
                columnId,
                Core__Option.getOr(Core__Option.map(Core__Option.map(config.columnId, DecidrInternalId.toString), (function (prim) {
                            return prim;
                          })), null)
              ]]);
}

function deserialize(json) {
  var configDict = Core__JSON.Classify.classify(json);
  if (typeof configDict === "object" && configDict.TAG === "Object") {
    var columnId$1 = Core__Option.flatMap(Core__Option.flatMap(configDict._0[columnId], Core__JSON.Decode.string), DecidrInternalId.fromString);
    return {
            columnId: columnId$1
          };
  }
  ErrorHandling__UnexpectedError.handle("Invalid configuration for Column Count Insights", "Spaces__Block__ColumnCountInsights Configuration deserialize", {
        json: json
      });
}

var Configuration = {
  SerializationKeys: SerializationKeys,
  serialize: serialize,
  deserialize: deserialize
};

var convertVariables = SpacesBlockColumnCountInsightsQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockColumnCountInsightsQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesBlockColumnCountInsightsQuery_graphql.Internal.convertWrapRawResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockColumnCountInsightsQuery_graphql.node, convertResponse);

var useLoader = RescriptRelay_Query.useLoader(convertVariables, SpacesBlockColumnCountInsightsQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded = RescriptRelay_Query.usePreloaded(SpacesBlockColumnCountInsightsQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

var $$fetch = RescriptRelay_Query.$$fetch(SpacesBlockColumnCountInsightsQuery_graphql.node, convertResponse, convertVariables);

var fetchPromised = RescriptRelay_Query.fetchPromised(SpacesBlockColumnCountInsightsQuery_graphql.node, convertResponse, convertVariables);

var retain = RescriptRelay_Query.retain(SpacesBlockColumnCountInsightsQuery_graphql.node, convertVariables);

var InsightsQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapRawResponse,
  use: use$1,
  useLoader: useLoader,
  usePreloaded: usePreloaded,
  $$fetch: $$fetch,
  fetchPromised: fetchPromised,
  retain: retain
};

function Spaces__Block__ColumnCountInsights$Insights(props) {
  var match = use$1({
        columnId: DecidrNodeId.magicallyFromInternalId(props.columnId),
        skipQuery: !props.isAuthenticated
      }, "store-and-network", undefined, undefined);
  var columnCountInsights = match.columnCountInsights;
  var match$1 = React.useState(function () {
        return false;
      });
  if (columnCountInsights === undefined) {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                children: "No Insights found for this block"
              });
  }
  var setShowAll = match$1[1];
  var showAll = match$1[0];
  if (columnCountInsights.dataType === "unsupported") {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                children: "Unsupported data type"
              });
  }
  var insights = columnCountInsights.insights;
  if (insights.length === 0) {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                children: "No Insights found for this block"
              });
  }
  var insightsToDisplay = insights.length <= 6 || showAll ? insights : insights.slice(0, 6);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              style: {
                backgroundColor: Primitives__Styling.color("neutral_7"),
                borderRadius: Primitives__Styling.radius("rad_2"),
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: [
                insightsToDisplay.map(function (insight) {
                      return JsxRuntime.jsxs(Primitives__Group.make, {
                                  gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                  orientation: "horizontal",
                                  style: {
                                    backgroundColor: Primitives__Styling.color({
                                          NAME: "surface",
                                          VAL: "default"
                                        }),
                                    justifyContent: "space-between",
                                    padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                                  },
                                  children: [
                                    JsxRuntime.jsx(Primitives__DecidrText.make, {
                                          kind: "body1",
                                          children: Caml_option.some(insight.value)
                                        }),
                                    JsxRuntime.jsxs(Primitives__Group.make, {
                                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                          orientation: "horizontal",
                                          style: {
                                            borderRadius: Primitives__Styling.radius("rad_1"),
                                            alignItems: "center"
                                          },
                                          children: [
                                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                  children: "Count:",
                                                  color: Primitives__Styling.color({
                                                        NAME: "text",
                                                        VAL: {
                                                          NAME: "on_surface",
                                                          VAL: "subdued"
                                                        }
                                                      })
                                                }),
                                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                  kind: "body1",
                                                  children: Caml_option.some(insight.count)
                                                })
                                          ]
                                        })
                                  ]
                                }, insight.value);
                    }),
                ReactUtils__SimpleReact.whenTrue(insights.length > 6, {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Primitives__Button.make, {
                                      title: showAll ? "Collapse" : "Show all",
                                      style: {
                                        alignSelf: "center"
                                      },
                                      onPress: (function (param) {
                                          setShowAll(function (prev) {
                                                return !prev;
                                              });
                                        })
                                    });
                        })
                    })
              ]
            });
}

var Insights = {
  make: Spaces__Block__ColumnCountInsights$Insights
};

var convertVariables$1 = SpacesBlockColumnCountInsightsContentQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockColumnCountInsightsContentQuery_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesBlockColumnCountInsightsContentQuery_graphql.Internal.convertWrapRawResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockColumnCountInsightsContentQuery_graphql.node, convertResponse$1);

var useLoader$1 = RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockColumnCountInsightsContentQuery_graphql.node, (function (prim) {
        return prim;
      }));

var usePreloaded$1 = RescriptRelay_Query.usePreloaded(SpacesBlockColumnCountInsightsContentQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

var $$fetch$1 = RescriptRelay_Query.$$fetch(SpacesBlockColumnCountInsightsContentQuery_graphql.node, convertResponse$1, convertVariables$1);

var fetchPromised$1 = RescriptRelay_Query.fetchPromised(SpacesBlockColumnCountInsightsContentQuery_graphql.node, convertResponse$1, convertVariables$1);

var retain$1 = RescriptRelay_Query.retain(SpacesBlockColumnCountInsightsContentQuery_graphql.node, convertVariables$1);

var ContentQuery = {
  Operation: undefined,
  Types: undefined,
  convertVariables: convertVariables$1,
  convertResponse: convertResponse$1,
  convertWrapRawResponse: convertWrapRawResponse$1,
  use: use$2,
  useLoader: useLoader$1,
  usePreloaded: usePreloaded$1,
  $$fetch: $$fetch$1,
  fetchPromised: fetchPromised$1,
  retain: retain$1
};

function makeSettingsStoreKey(columnViewId) {
  return "block-settings::" + DecidrNodeId.toString(columnViewId);
}

function Spaces__Block__ColumnCountInsights$Content(props) {
  var viewId = props.viewId;
  var columnViewId = props.columnViewId;
  var toast = Primitives__DecidrToast.use();
  var match = Spaces__View__Mutations.useUpdateCoreConfig();
  var updateCoreConfig = match[0];
  var match$1 = Auth__Hooks.useAuth();
  var authStatus = match$1.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$2 = use$2({
        skipQuery: !isAuthenticated,
        viewId: viewId
      }, undefined, undefined, undefined);
  var match$3 = React.useState(function () {
        
      });
  var setSelectedColumnId = match$3[1];
  var selectedColumnId = match$3[0];
  var coreConfig = Core__Option.getOr(Core__Option.flatMap(match$2.node, (function (node) {
              if (node.__typename === "View") {
                return node.coreConfig;
              }
              
            })), {});
  var storedSettingsJson = Core__Option.flatMap(Core__JSON.Decode.object(coreConfig), (function (__x) {
          return __x[makeSettingsStoreKey(columnViewId)];
        }));
  var config = Core__Option.getOr(Core__Option.flatMap(storedSettingsJson, deserialize), {
        columnId: undefined
      });
  var columnId = config.columnId;
  if (columnId !== undefined) {
    return JsxRuntime.jsx(Primitives__Suspense.make, {
                children: JsxRuntime.jsx(Spaces__Block__ColumnCountInsights$Insights, {
                      columnId: Caml_option.valFromOption(columnId),
                      isAuthenticated: isAuthenticated
                    })
              });
  } else {
    return JsxRuntime.jsxs(Primitives__Group.make, {
                gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                children: [
                  JsxRuntime.jsx(Spaces__Block__ColumnCountInsights$Labeled, {
                        label: "Column",
                        children: JsxRuntime.jsx(React.Suspense, {
                              children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__ColumnCountInsights$ColumnViewPicker, {
                                        queryRef: match$2.fragmentRefs,
                                        selectedItem: Core__Option.map(selectedColumnId, (function (x) {
                                                return {
                                                        TAG: "Column",
                                                        _0: x
                                                      };
                                              })),
                                        onSelect: (function (param) {
                                            var columnId = param.columnId;
                                            setSelectedColumnId(function (param) {
                                                  return Caml_option.some(DecidrNodeId.magicallyToInternalId(columnId));
                                                });
                                          })
                                      })),
                              fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Select.TriggerLoading.make, {}))
                            })
                      }),
                  JsxRuntime.jsx(Primitives__Button.make, {
                        title: "Save",
                        style: {
                          alignSelf: "flex-start"
                        },
                        onPress: (function (param) {
                            var configJson = serialize({
                                  columnId: selectedColumnId
                                });
                            var key = makeSettingsStoreKey(columnViewId);
                            var entry = [
                              key,
                              configJson
                            ];
                            var coreConfigDict = Core__Option.getOr(Core__JSON.Decode.object(coreConfig), {});
                            var newCoreConfig = Object.fromEntries(Object.entries(coreConfigDict).concat([entry]));
                            updateCoreConfig(viewId, newCoreConfig, (function () {
                                    toast.show("Configuration saved", {
                                          message: "The configuration was saved"
                                        });
                                  }), (function (message) {
                                    toast.error("Error saving configuration", message);
                                  }), undefined);
                          }),
                        busy: match[1]
                      })
                ]
              });
  }
}

var Content = {
  make: Spaces__Block__ColumnCountInsights$Content
};

function fromString(method) {
  switch (method) {
    case "DELETE" :
        return "DELETE";
    case "GET" :
        return "GET";
    case "POST" :
        return "POST";
    case "PUT" :
        return "PUT";
    default:
      return ;
  }
}

function fromStringOr(method, $$default) {
  return Core__Option.getOr(fromString(method), $$default);
}

var Method = {
  fromString: fromString,
  fromStringOr: fromStringOr
};

function Spaces__Block__ColumnCountInsights(props) {
  return JsxRuntime.jsx(Primitives__Suspense.make, {
              children: JsxRuntime.jsx(Spaces__Block__ColumnCountInsights$Content, {
                    columnViewId: props.columnViewId,
                    viewId: props.viewId
                  })
            });
}

var maxItemsToDisplay = 6;

var make = Spaces__Block__ColumnCountInsights;

export {
  Labeled ,
  ColumnViewPicker ,
  Configuration ,
  InsightsQuery ,
  maxItemsToDisplay ,
  Insights ,
  ContentQuery ,
  makeSettingsStoreKey ,
  Content ,
  Method ,
  make ,
}
/* use Not a pure module */
