import {
  getString,
  setString,
  getFloat,
  setFloat,
} from "@decidr/storage/src/Storage.gen";

import {
  userIdKey,
  userEmailKey,
  userPhoneKey,
  anonymousIdKey,
  pageViewEventKey,
  identifyEventKey,
  readMessageEventKey,
  networkInfoKey,
  lastBulkSendTimestampKey,
} from "../contants";
import { EventPayload, EventType, AnalyticsStorage } from "../types";

// LS  =>
//    ${pageViewEventKey}: [ { full event object }, { full event object }}]
//    ${identifyEventKey}: [ { full event object }, { full event object }}]
//    ${readMessageEventKey}: [ { full event object }, { full event object }}]
//

export const getUserProperties: AnalyticsStorage["getUserProperties"] = () => {
  const userId = getString(userIdKey);
  const userEmail = getString(userEmailKey);
  const userPhone = getString(userPhoneKey);

  if (userId != undefined || userEmail != undefined || userPhone != undefined) {
    return {
      id: userId ?? undefined,
      email: userEmail ?? undefined,
      phone: userPhone ?? undefined,
    };
  }
};

export const setUserProperties: AnalyticsStorage["setUserProperties"] = ({
  id,
  email,
  phone,
}) => {
  if (id != undefined) setString(userIdKey, id);
  if (email != undefined) setString(userEmailKey, email);
  if (phone != undefined) setString(userPhoneKey, phone);
};

export const getAnonymousId: AnalyticsStorage["getAnonymousId"] = () => {
  return getString(anonymousIdKey);
};

export const setAnonymousId: AnalyticsStorage["setAnonymousId"] =
  anonymousId => {
    setString(anonymousIdKey, anonymousId);
  };

export const getLastBulkSendTimestamp: AnalyticsStorage["getLastBulkSendTimestamp"] =
  () => {
    return getFloat(lastBulkSendTimestampKey);
  };

export const setLastBulkSendTimestamp: AnalyticsStorage["setLastBulkSendTimestamp"] =
  () => {
    setFloat(lastBulkSendTimestampKey, Date.now());
  };

export const getAllEventsToSend: AnalyticsStorage["getAllEventsToSend"] =
  () => {
    const _updateEventsWhenAddingToUnion = (event: EventType): string => {
      // Don't forget to add the new event type when getting items from local storage
      switch (event.type) {
        case "PageView":
          return pageViewEventKey;
        case "Identify":
          return identifyEventKey;
        case "ReadMessage":
          return readMessageEventKey;
      }
    };

    const events: EventPayload[] = [];

    const pageViews = getString(pageViewEventKey);
    if (pageViews != undefined) {
      events.push(...(JSON.parse(pageViews) as EventPayload[]));
    }
    const identifies = getString(identifyEventKey);
    if (identifies != undefined) {
      events.push(...(JSON.parse(identifies) as EventPayload[]));
    }
    const readMessages = getString(readMessageEventKey);
    if (readMessages != undefined) {
      events.push(...(JSON.parse(readMessages) as EventPayload[]));
    }
    return events;
  };

export const modifyOrAddEventsLocally: AnalyticsStorage["modifyOrAddEventsLocally"] =
  events => {
    // Map events by type
    const mappedEvents = events.reduce((acc, event) => {
      return {
        ...acc,
        // While iterating over empty object, value might be undefined
        // but we type it as defined so we don't need multiple checks later
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        [event.type]: [...(acc[event.type] ?? []), event],
      };
    }, {} as Record<EventPayload["type"], EventPayload[]>);

    // Iterate over mapped events and update local storage
    Object.entries(mappedEvents).forEach(([eventType, events]) => {
      switch (eventType) {
        case "PageView":
          const pageViews = getString(pageViewEventKey);
          if (pageViews != undefined) {
            const pageViewsArray: EventPayload[] = JSON.parse(pageViews);
            setString(
              pageViewEventKey,
              JSON.stringify(pageViewsArray.concat(events))
            );
          } else {
            setString(pageViewEventKey, JSON.stringify(events));
          }
          break;
        case "Identify":
          const identifies = getString(identifyEventKey);
          if (identifies != undefined) {
            const identifiesArray: EventPayload[] = JSON.parse(identifies);
            setString(
              identifyEventKey,
              JSON.stringify(identifiesArray.concat(events))
            );
          } else {
            setString(identifyEventKey, JSON.stringify(events));
          }
          break;

        case "ReadMessage":
          const readMessages = getString(readMessageEventKey);
          if (readMessages != undefined) {
            const readMessagesArray: EventPayload[] = JSON.parse(readMessages);
            // Check if the message is already in the array
            const duplicateEventIds: string[] = [];
            const messagesToSave = readMessagesArray.map(message => {
              const foundEvent = events.find(
                event =>
                  event.properties.messageId === message.properties.messageId
              );
              if (foundEvent != undefined) {
                message.properties.timeSpent += foundEvent.properties.timeSpent;
                duplicateEventIds.push(foundEvent.eventId);
              }
              return message;
            });

            setString(
              readMessageEventKey,
              JSON.stringify(
                messagesToSave.concat(
                  events.filter(
                    event => !duplicateEventIds.includes(event.eventId)
                  )
                )
              )
            );
          } else {
            setString(readMessageEventKey, JSON.stringify(events));
          }
          break;
      }
    });
  };

export const removeEvents: AnalyticsStorage["removeEvents"] = eventIds => {
  const _updateEventsWhenAddingToUnion = (event: EventType): string => {
    // Don't forget to add the new event type when getting items from local storage
    switch (event.type) {
      case "PageView":
        return pageViewEventKey;
      case "Identify":
        return identifyEventKey;
      case "ReadMessage":
        return readMessageEventKey;
    }
  };
  const pageViews = getString(pageViewEventKey);
  if (pageViews != undefined) {
    const pageViewsArray: EventPayload[] = JSON.parse(pageViews);
    const filteredPageViews = pageViewsArray.filter(
      (event: EventPayload) => !eventIds.includes(event.eventId)
    );
    setString(pageViewEventKey, JSON.stringify(filteredPageViews));
  }
  const identifies = getString(identifyEventKey);
  if (identifies != undefined) {
    const identifiesArray: EventPayload[] = JSON.parse(identifies);
    const filteredIdentifies = identifiesArray.filter(
      (event: EventPayload) => !eventIds.includes(event.eventId)
    );
    setString(identifyEventKey, JSON.stringify(filteredIdentifies));
  }
  const readMessages = getString(readMessageEventKey);
  if (readMessages != undefined) {
    const readMessagesArray: EventPayload[] = JSON.parse(readMessages);
    const filteredReadMessages = readMessagesArray.filter(
      (event: EventPayload) => !eventIds.includes(event.eventId)
    );
    setString(readMessageEventKey, JSON.stringify(filteredReadMessages));
  }
};

export const getNetworkInfo: AnalyticsStorage["getNetworkInfo"] = () => {
  const networkInfo = getString(networkInfoKey);
  if (networkInfo != undefined) {
    return JSON.parse(networkInfo);
  }
};

export const setNetworkInfo: AnalyticsStorage["setNetworkInfo"] =
  networkInfo => {
    setString(networkInfoKey, JSON.stringify(networkInfo));
  };

const storage: AnalyticsStorage = {
  getUserProperties,
  setUserProperties,
  getAnonymousId,
  setAnonymousId,
  getLastBulkSendTimestamp,
  setLastBulkSendTimestamp,
  getAllEventsToSend,
  modifyOrAddEventsLocally,
  removeEvents,
  getNetworkInfo,
  setNetworkInfo,
};

export default storage;
