// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Nextjs from "@sentry/nextjs";

function captureLog(message, debugContext, extraData) {
  if (extraData !== undefined) {
    console.log("[" + debugContext + "] " + message, Caml_option.valFromOption(extraData));
  } else {
    console.log("[" + debugContext + "] " + message);
  }
}

function captureError(message, debugContext, errorResponse) {
  var stringifiedErrorResponse = JSON.stringify(errorResponse);
  var stringifiedErrorResponse$1 = stringifiedErrorResponse !== undefined ? stringifiedErrorResponse : (captureLog("Failed to stringify errorResponse", "[ErrorReportingInternal] captureError", undefined), "Failed to stringify errorResponse");
  Nextjs.captureMessage(message, {
        level: "error",
        contexts: {
          "error-info": {
            message: message,
            location: debugContext,
            response: errorResponse,
            stringifiedResponse: stringifiedErrorResponse$1
          }
        }
      });
}

function captureErrorWithExtraData(message, debugContext, extraDataOpt) {
  var extraData = extraDataOpt !== undefined ? Caml_option.valFromOption(extraDataOpt) : ({});
  var stringifiedExtraData = JSON.stringify(extraData);
  var stringifiedExtraData$1 = stringifiedExtraData !== undefined ? stringifiedExtraData : (captureLog("Failed to stringify extraData", "[ErrorReportingInternal] captureErrorWithExtraData", undefined), "Failed to stringify extraData");
  Nextjs.captureMessage(message, {
        level: "error",
        contexts: {
          "error-info": {
            message: message,
            location: debugContext,
            extraData: extraData,
            stringifiedExtraData: stringifiedExtraData$1
          }
        }
      });
}

function captureWarning(message, debugContext, extraData) {
  var stringifiedExtraData = JSON.stringify(extraData);
  var stringifiedExtraData$1 = stringifiedExtraData !== undefined ? stringifiedExtraData : (captureLog("Failed to stringify extraData", "[ErrorReportingInternal] captureWarning", undefined), "Failed to stringify extraData");
  Nextjs.captureMessage(message, {
        level: "warning",
        contexts: {
          "warning-info": {
            message: message,
            location: debugContext,
            extraData: extraData,
            stringifiedExtraData: stringifiedExtraData$1
          }
        }
      });
}

export {
  captureError ,
  captureErrorWithExtraData ,
  captureLog ,
  captureWarning ,
}
/* @sentry/nextjs Not a pure module */
