// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as Primitives__Modal from "../../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__Error__Message from "../../../../primitives/src/error/message/Primitives__Error__Message.bs.js";

function Spaces__Ui__SimpleOneInputModal$Content(props) {
  var __buttonTitle = props.buttonTitle;
  var __initialValue = props.initialValue;
  var onButtonPress = props.onButtonPress;
  var initialValue = __initialValue !== undefined ? __initialValue : "";
  var buttonTitle = __buttonTitle !== undefined ? __buttonTitle : "Create";
  var match = Primitives__Input.use(initialValue, undefined, undefined);
  var value = match.value;
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_1")),
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                borderRadius: Primitives__Styling.radius("rad_4"),
                minWidth: Caml_option.some(Primitives__Styling.dp(250)),
                padding: Caml_option.some(Primitives__Styling.space("ss_2")),
                paddingTop: Caml_option.some(Primitives__Styling.space("ss_4"))
              },
              children: [
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: value,
                      onChange: match.onChange,
                      onSubmitEditing: (function () {
                          if (value.trim() === "") {
                            return ;
                          } else {
                            return onButtonPress(value, (function (message) {
                                          setError(function (param) {
                                                return message;
                                              });
                                        }), (function () {
                                          setError(function (param) {
                                                
                                              });
                                        }));
                          }
                        }),
                      label: props.label,
                      placeholder: props.placeholder,
                      autoFocus: true
                    }),
                JsxRuntime.jsx(Primitives__Error__Message.make, {
                      error: match$1[0]
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: buttonTitle,
                      onPress: (function (param) {
                          setError(function (param) {
                                
                              });
                          onButtonPress(value, (function (message) {
                                  setError(function (param) {
                                        return message;
                                      });
                                }), (function () {
                                  setError(function (param) {
                                        
                                      });
                                }));
                        }),
                      disabled: value.trim() === "",
                      busy: props.loading
                    })
              ]
            });
}

function Spaces__Ui__SimpleOneInputModal(props) {
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Spaces__Ui__SimpleOneInputModal$Content, {
                    placeholder: props.placeholder,
                    label: props.label,
                    onButtonPress: props.onButtonPress,
                    loading: props.loading,
                    initialValue: props.initialValue,
                    buttonTitle: props.buttonTitle
                  }),
              show: props.show,
              onClose: props.onClose,
              title: props.title,
              maxWidth: 400
            });
}

var make = Spaces__Ui__SimpleOneInputModal;

export {
  make ,
}
/* react Not a pure module */
