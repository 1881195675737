// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as DateTime from "../../../date-time/src/DateTime.bs.js";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__View from "../view/Spaces__View.bs.js";
import * as App__DateUtils from "../../../app/src/utils/date/App__DateUtils.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Primitives__DecidrImage from "../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as SpacesBlockRowMembership_query_graphql from "../../../relay-codegen/generated/SpacesBlockRowMembership_query_graphql.bs.js";
import * as SpacesBlockRowMembershipLiveQuery_graphql from "../../../relay-codegen/generated/SpacesBlockRowMembershipLiveQuery_graphql.bs.js";
import * as SpacesBlockRowMembershipHistoryQuery_graphql from "../../../relay-codegen/generated/SpacesBlockRowMembershipHistoryQuery_graphql.bs.js";
import * as SpacesBlockRowMembershipHistory_query_graphql from "../../../relay-codegen/generated/SpacesBlockRowMembershipHistory_query_graphql.bs.js";
import * as SpacesBlockRowMembershipList_rowMembership_graphql from "../../../relay-codegen/generated/SpacesBlockRowMembershipList_rowMembership_graphql.bs.js";
import * as SpacesBlockRowMembershipListItem_rowMembership_graphql from "../../../relay-codegen/generated/SpacesBlockRowMembershipListItem_rowMembership_graphql.bs.js";

function Spaces__Block__RowMembership$LabeledText(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              orientation: "horizontal",
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      kind: "body3",
                      children: Caml_option.some(props.label)
                    }),
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        fontWeight: 500
                      },
                      kind: "body3",
                      children: Caml_option.some(props.text)
                    })
              ]
            });
}

var convertFragment = SpacesBlockRowMembershipListItem_rowMembership_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockRowMembershipListItem_rowMembership_graphql.node, convertFragment, fRef);
}

function Spaces__Block__RowMembership$RowMembershipListItem(props) {
  var match = use(props.rowMembershipRef);
  var match$1 = Spaces__View.BasicInfo.use(match.view.fragmentRefs);
  var image = match$1.image;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                borderColor: Primitives__Styling.color({
                      NAME: "border",
                      VAL: {
                        NAME: "neutral",
                        VAL: "default"
                      }
                    }),
                borderRadius: Primitives__Styling.radius("rad_3"),
                borderWidth: 1,
                alignItems: "center",
                padding: Caml_option.some(Primitives__Styling.space("cs_4"))
              },
              specificStyles: {
                s: {
                  alignItems: "flex-start",
                  flexDirection: "column"
                }
              },
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "center",
                        flex: 1
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__Group.make, {
                              style: {
                                borderRadius: Primitives__Styling.radius("rad_3"),
                                alignItems: "center",
                                justifyContent: "center",
                                overflow: "hidden"
                              },
                              children: Caml_option.some(image !== undefined ? JsxRuntime.jsx(Primitives__DecidrImage.make, {
                                          resizeMode: "contain",
                                          tintColor: Primitives__Styling.colorValue("neutral_4"),
                                          src: image.url,
                                          size: {
                                            TAG: "Square",
                                            _0: Primitives__Styling.dp(38)
                                          },
                                          alt: "View image"
                                        }) : JsxRuntime.jsx(Primitives__Group.make, {
                                          style: {
                                            backgroundColor: Primitives__Styling.color({
                                                  NAME: "background",
                                                  VAL: "default"
                                                }),
                                            height: Caml_option.some(Primitives__Styling.dp(38)),
                                            width: Caml_option.some(Primitives__Styling.dp(38))
                                          }
                                        }))
                            }),
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                flex: 1
                              },
                              children: Caml_option.some(match$1.name)
                            })
                      ]
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      children: [
                        JsxRuntime.jsx(Spaces__Block__RowMembership$LabeledText, {
                              label: "From:",
                              text: App__DateUtils.getFullDateTimeFromDate(match.from)
                            }),
                        ReactUtils__SimpleReact.renderIfSome(match.to, (function (to) {
                                return JsxRuntime.jsx(Spaces__Block__RowMembership$LabeledText, {
                                            label: "To:",
                                            text: App__DateUtils.getFullDateTimeFromDate(to)
                                          });
                              }))
                      ]
                    })
              ]
            });
}

var convertFragment$1 = SpacesBlockRowMembershipList_rowMembership_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockRowMembershipList_rowMembership_graphql.node, convertFragment$1, fRef);
}

function keyExtractor(param) {
  var from = DateTime.toIsoString(param.from);
  var to = Core__Option.map(param.to, DateTime.toIsoString);
  var viewId = DecidrNodeId.toString(param.view.id);
  if (to !== undefined) {
    return viewId + "/" + from + "/" + to;
  } else {
    return viewId + "/" + from;
  }
}

function Spaces__Block__RowMembership$RowMembershipList(props) {
  var rowMemberships = use$1(props.rowMembershipsRef);
  var match = React.useState(function () {
        return false;
      });
  var setShowAll = match[1];
  var showAll = match[0];
  var rowMembershipsToDisplay = rowMemberships.length <= 6 || showAll ? rowMemberships : rowMemberships.slice(0, 6);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                rowMembershipsToDisplay.map(function (rowMembership) {
                      return JsxRuntime.jsx(Spaces__Block__RowMembership$RowMembershipListItem, {
                                  rowMembershipRef: rowMembership.fragmentRefs
                                }, keyExtractor(rowMembership));
                    }),
                ReactUtils__SimpleReact.whenTrue(rowMemberships.length > 6, {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Primitives__Button.make, {
                                      title: showAll ? "Collapse" : "Show all",
                                      style: {
                                        alignSelf: "center"
                                      },
                                      onPress: (function (param) {
                                          setShowAll(function (prev) {
                                                return !prev;
                                              });
                                        })
                                    });
                        })
                    })
              ]
            });
}

var convertFragment$2 = SpacesBlockRowMembershipHistory_query_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockRowMembershipHistory_query_graphql.node, convertFragment$2, fRef);
}

function Spaces__Block__RowMembership$History$Disconnected(props) {
  var match = use$2(props.queryRef);
  return JsxRuntime.jsx(Spaces__Block__RowMembership$RowMembershipList, {
              rowMembershipsRef: match.rowMembershipHistory.map(function (x) {
                    return x.fragmentRefs;
                  })
            });
}

var convertVariables = SpacesBlockRowMembershipHistoryQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockRowMembershipHistoryQuery_graphql.Internal.convertResponse;

var use$3 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockRowMembershipHistoryQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockRowMembershipHistoryQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockRowMembershipHistoryQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockRowMembershipHistoryQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockRowMembershipHistoryQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockRowMembershipHistoryQuery_graphql.node, convertVariables);

function Spaces__Block__RowMembership$History(props) {
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$3({
        rowId: DecidrNodeId.magicallyFromInternalId(props.rowId),
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(Primitives__Suspense.make, {
              children: JsxRuntime.jsx(Spaces__Block__RowMembership$History$Disconnected, {
                    queryRef: match$1.fragmentRefs
                  })
            });
}

var convertFragment$3 = SpacesBlockRowMembership_query_graphql.Internal.convertFragment;

function use$4(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockRowMembership_query_graphql.node, convertFragment$3, fRef);
}

function Spaces__Block__RowMembership$Live$Disconnected(props) {
  var match = use$4(props.queryRef);
  return JsxRuntime.jsx(Spaces__Block__RowMembership$RowMembershipList, {
              rowMembershipsRef: match.rowMembership.map(function (x) {
                    return x.fragmentRefs;
                  })
            });
}

var convertVariables$1 = SpacesBlockRowMembershipLiveQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockRowMembershipLiveQuery_graphql.Internal.convertResponse;

var use$5 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockRowMembershipLiveQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockRowMembershipLiveQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockRowMembershipLiveQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockRowMembershipLiveQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesBlockRowMembershipLiveQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesBlockRowMembershipLiveQuery_graphql.node, convertVariables$1);

function Spaces__Block__RowMembership$Live(props) {
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$5({
        rowId: DecidrNodeId.magicallyFromInternalId(props.rowId),
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(Primitives__Suspense.make, {
              children: JsxRuntime.jsx(Spaces__Block__RowMembership$Live$Disconnected, {
                    queryRef: match$1.fragmentRefs
                  })
            });
}

var $$History = {
  make: Spaces__Block__RowMembership$History
};

var Live = {
  make: Spaces__Block__RowMembership$Live
};

export {
  $$History ,
  Live ,
}
/* use Not a pure module */
