// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as SpacesMultiSelectCellData_multiSelectCell_graphql from "../../../relay-codegen/generated/SpacesMultiSelectCellData_multiSelectCell_graphql.bs.js";
import * as SpacesMultiSelectCellCache_multiSelectCell_graphql from "../../../relay-codegen/generated/SpacesMultiSelectCellCache_multiSelectCell_graphql.bs.js";

var convertFragment = SpacesMultiSelectCellCache_multiSelectCell_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesMultiSelectCellCache_multiSelectCell_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesMultiSelectCellCache_multiSelectCell_graphql.node, convertFragment);
}

var MultiSelectCellCacheFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt
};

var convertFragment$1 = SpacesMultiSelectCellData_multiSelectCell_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesMultiSelectCellData_multiSelectCell_graphql.node, convertFragment$1, fRef);
}

function useOpt$1(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesMultiSelectCellData_multiSelectCell_graphql.node, convertFragment$1);
}

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(SpacesMultiSelectCellData_multiSelectCell_graphql.node, convertFragment$1, fRef);
}

var DataFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment$1,
  use: use$1,
  useOpt: useOpt$1,
  readInline: readInline
};

export {
  MultiSelectCellCacheFragment ,
  DataFragment ,
}
/* RescriptRelay_Fragment Not a pure module */
