// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UiReactNative from "@aws-amplify/ui-react-native";

function useAuth(prim) {
  return UiReactNative.useAuthenticator();
}

export {
  useAuth ,
}
/* @aws-amplify/ui-react-native Not a pure module */
