// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Renderer__SettingsJSON from "@pageblocks/renderer/src/Renderer__SettingsJSON.bs.js";
import * as ChatPerPage from "./ts-blocks/ChatPerPage";
import * as DecidrRenderer__Renderer from "../renderer/DecidrRenderer__Renderer.bs.js";
import * as DecidrRenderer__Blocks__Utils from "./DecidrRenderer__Blocks__Utils.bs.js";
import * as DecidrRenderer__Blocks__SurveyAndChatUtils from "./utils/DecidrRenderer__Blocks__SurveyAndChatUtils.bs.js";

var blockCode = "chat-per-page";

var aiName = "aiName";

var aiImage = "aiImage";

var userName = "userName";

var userImage = "userImage";

var showSideContent = "showSideContent";

var sideContentSource = "sideContentSource";

var sideContentPosition = "sideContentPosition";

var prompt = "prompt";

var assistantId = "assistantId";

var starterMessage = "starterMessage";

var submitFlow = "submitFlow";

var pages = "pages";

var columnView = "columnView";

function parseSubmitFlow(x) {
  switch (x) {
    case "chat" :
        return "chat";
    case "question" :
        return "question";
    case "thread" :
        return "thread";
    default:
      return ;
  }
}

var make = ChatPerPage.ChatPerPage;

function DecidrRenderer__Blocks__ChatPerPage$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return DecidrRenderer__Blocks__Utils.getString(settings, context, code);
  };
  var getBool = function (code) {
    return DecidrRenderer__Blocks__Utils.getBool(settings, context, code);
  };
  var getObject = function (code) {
    return DecidrRenderer__Blocks__Utils.getObject(settings, context, code);
  };
  var getList = function (code) {
    return DecidrRenderer__Blocks__Utils.getList(settings, context, code);
  };
  var getNode = function (code) {
    return DecidrRenderer__Blocks__Utils.getNode(settings, context, code);
  };
  var viewId = getString("viewId");
  var organizationId = getString("organizationId");
  var aiName$1 = getString(aiName);
  var aiImage$1 = getString(aiImage);
  var userName$1 = getString(userName);
  var userImage$1 = getString(userImage);
  var showSideContent$1 = getBool(showSideContent);
  var sideContentSource$1 = getString(sideContentSource);
  var sideContentPosition$1 = getString(sideContentPosition);
  var prompt$1 = getString(prompt);
  var assistantId$1 = getString(assistantId);
  var starterMessage$1 = getString(starterMessage);
  var columnViews = getObject("columnViews");
  var submitFlow$1 = getString(submitFlow);
  var assistantMessageBlock = getNode("assistantMessageBlock");
  var userMessageBlock = getNode("userMessageBlock");
  var pages$1 = getList(pages);
  var aiName$2 = Core__Option.getOr(aiName$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultAiName);
  var aiImage$2 = Core__Option.getOr(aiImage$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultAiImage);
  var userName$2 = Core__Option.getOr(userName$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultUserName);
  var userImage$2 = Core__Option.getOr(userImage$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultUserImage);
  var showSideContent$2 = showSideContent$1 !== undefined ? (
      showSideContent$1 ? true : false
    ) : sideContentSource$1 !== undefined;
  var sideContentPosition$2 = DecidrRenderer__Blocks__SurveyAndChatUtils.parseSideContentPositionWithDefault(sideContentPosition$1);
  var prompt$2 = Core__Option.getOr(prompt$1, "");
  var columnViews$1 = Core__Array.filterMap(Core__Array.filterMap(Object.entries(Core__Option.getOr(columnViews, {})), (function (param) {
              return Core__JSON.Decode.object(param[1]);
            })), DecidrRenderer__Blocks__Utils.parseColumnView);
  var submitFlow$2 = Core__Option.flatMap(submitFlow$1, parseSubmitFlow);
  var pages$2 = Core__Option.getOr(Core__Option.map(pages$1, (function (__x) {
              return Core__Array.filterMap(__x, (function (__x) {
                            var sideContentSource$2 = DecidrRenderer__Blocks__Utils.getString(Renderer__Settings.deserialize(Renderer__SettingsJSON.fromJson(__x)), context, sideContentSource);
                            var sideContentType = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap((function (__x) {
                                              return __x["sideContentType"];
                                            })(__x), Core__JSON.Decode.string), (function (x) {
                                        switch (x) {
                                          case "iframe" :
                                              return "iframe";
                                          case "image" :
                                              return "image";
                                          default:
                                            return ;
                                        }
                                      })), "image");
                            var columnView$1 = Core__Option.flatMap(DecidrRenderer__Blocks__Utils.getObject(Renderer__Settings.deserialize(Renderer__SettingsJSON.fromJson(__x)), context, columnView), DecidrRenderer__Blocks__Utils.parseColumnView);
                            if (columnView$1 !== undefined) {
                              return {
                                      sideContentSource: sideContentSource$2,
                                      sideContentType: sideContentType,
                                      columnView: columnView$1
                                    };
                            }
                            
                          }));
            })), []);
  if (starterMessage$1 !== undefined && assistantMessageBlock !== undefined && userMessageBlock !== undefined && assistantId$1 !== undefined) {
    return JsxRuntime.jsx(make, {
                showSideContent: showSideContent$2,
                prompt: prompt$2,
                starterMessage: starterMessage$1,
                columnViews: columnViews$1,
                AssistantMessageComponent: (function (param) {
                    return JsxRuntime.jsx(DecidrRenderer__Renderer.make, {
                                node: assistantMessageBlock.node,
                                context: Caml_option.some(DecidrRenderer__Blocks__SurveyAndChatUtils.makeMessageContext(context, param.content, aiName$2, aiImage$2))
                              });
                  }),
                UserMessageComponent: (function (param) {
                    return JsxRuntime.jsx(DecidrRenderer__Renderer.make, {
                                node: userMessageBlock.node,
                                context: Caml_option.some(DecidrRenderer__Blocks__SurveyAndChatUtils.makeMessageContext(context, param.content, userName$2, userImage$2))
                              });
                  }),
                assistantId: assistantId$1,
                sideContentPosition: sideContentPosition$2,
                viewId: viewId,
                organizationId: organizationId,
                submitFlow: submitFlow$2,
                pages: pages$2
              });
  } else {
    return null;
  }
}

var Component = {
  make: DecidrRenderer__Blocks__ChatPerPage$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [{
    code: "general",
    name: "General",
    settings: [
      {
        code: aiName,
        name: "AI Name",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: aiImage,
        name: "AI Image",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: userName,
        name: "User Name",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: userImage,
        name: "User Image",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: showSideContent,
        name: "Show Side Content",
        dataType: {
          TAG: "Bool",
          _0: {
            defaultValue: true
          }
        }
      },
      {
        code: sideContentPosition,
        name: "Side Content Position",
        dataType: {
          TAG: "Enum",
          _0: {
            values: [
              {
                value: "left",
                display: "Left"
              },
              {
                value: "right",
                display: "Right"
              }
            ],
            allowEmpty: true
          }
        }
      },
      {
        code: pages,
        name: "Pages",
        dataType: {
          TAG: "List",
          _0: {
            itemSchema: [
              {
                key: sideContentSource,
                dataType: {
                  TAG: "String",
                  _0: {}
                }
              },
              {
                key: "sideContentType",
                dataType: {
                  TAG: "String",
                  _0: {}
                }
              },
              {
                key: columnView,
                dataType: {
                  TAG: "Object",
                  _0: [
                    {
                      key: "id",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    },
                    {
                      key: "name",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    },
                    {
                      key: "column_id",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    },
                    {
                      key: "component_type_code",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    }
                  ]
                }
              }
            ],
            defaultValues: [],
            allowEmpty: true
          }
        }
      },
      {
        code: prompt,
        name: "Prompt",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: assistantId,
        name: "Assistant ID",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: starterMessage,
        name: "Starter Message",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: submitFlow,
        name: "Submit Answers Flow",
        dataType: {
          TAG: "Enum",
          _0: {
            values: [
              {
                value: "chat",
                display: "All at Once"
              },
              {
                value: "thread",
                display: "One by One"
              }
            ],
            allowEmpty: true
          }
        }
      }
    ]
  }];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

var minRendererVersion = Renderer__Version.current;

export {
  blockCode ,
  version ,
  minRendererVersion ,
  descriptor ,
}
/* make Not a pure module */
