// Generated by ReScript, PLEASE EDIT WITH CARE


function componentTypeSupportsAutoFill(componentTypeCode) {
  return !(componentTypeCode === "image" || componentTypeCode === "type-current-logged-in-user" || componentTypeCode === "places" || componentTypeCode === "video" || componentTypeCode === "type-created-in-view" || componentTypeCode === "bar_code" || componentTypeCode === "type-row-membership-history" || componentTypeCode === "nearby-places" || componentTypeCode === "related-list" || componentTypeCode === "audio" || componentTypeCode === "rollup-new" || componentTypeCode === "illustration" || componentTypeCode === "manual-http-request" || componentTypeCode === "qr-code" || componentTypeCode === "duration" || componentTypeCode === "stripe" || componentTypeCode === "type-created-by" || componentTypeCode === "type-owned-by" || componentTypeCode === "type-created-from-column-view" || componentTypeCode === "qr-code-reader" || componentTypeCode === "type-last-updated-date" || componentTypeCode === "type-last-updated-by" || componentTypeCode === "barcode-scanner" || componentTypeCode === "type-select-view" || componentTypeCode === "location" || componentTypeCode === "type-created-from-row" || componentTypeCode === "type-row-membership" || componentTypeCode === "lookup" || componentTypeCode === "column-count-insights" || componentTypeCode === "type-file" || componentTypeCode === "type-created-from-view" || componentTypeCode === "type-row-json-data" || componentTypeCode === "content-image" || componentTypeCode === "type-created-date" || componentTypeCode === "icon");
}

export {
  componentTypeSupportsAutoFill ,
}
/* No side effect */
