// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as Event$ReactNative from "rescript-react-native/src/apis/Event.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Spinner from "../spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as RescriptTamagui__Button from "../../../rescript-tamagui/src/button/RescriptTamagui__Button.bs.js";

Event$ReactNative.EventMethods({});

var IconProps = {};

var spinnerContainerStyle_bottom = Caml_option.some(Primitives__Styling.dp(0));

var spinnerContainerStyle_height = Caml_option.some(Primitives__Styling.dp(17.5));

var spinnerContainerStyle_left = Caml_option.some(Primitives__Styling.dp(0));

var spinnerContainerStyle_position = "relative";

var spinnerContainerStyle_right = Caml_option.some(Primitives__Styling.dp(0));

var spinnerContainerStyle_top = Caml_option.some(Primitives__Styling.dp(0));

var spinnerContainerStyle = {
  bottom: spinnerContainerStyle_bottom,
  height: spinnerContainerStyle_height,
  left: spinnerContainerStyle_left,
  position: spinnerContainerStyle_position,
  right: spinnerContainerStyle_right,
  top: spinnerContainerStyle_top
};

function Primitives__Button(props) {
  var __scaleOnHover = props.scaleOnHover;
  var __hideBreakpoints = props.hideBreakpoints;
  var __busy = props.busy;
  var __disabled = props.disabled;
  var __size = props.size;
  var __kind = props.kind;
  var onPress = props.onPress;
  var __specificStyles = props.specificStyles;
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var specificStyles = __specificStyles !== undefined ? __specificStyles : TamaguiConfig.Utils.emptySpecificStyles;
  var kind = __kind !== undefined ? __kind : "primary";
  var size = __size !== undefined ? __size : "large";
  var disabled = __disabled !== undefined ? __disabled : false;
  var busy = __busy !== undefined ? __busy : false;
  var hideBreakpoints = __hideBreakpoints !== undefined ? __hideBreakpoints : [];
  var scaleOnHover = __scaleOnHover !== undefined ? __scaleOnHover : false;
  var large = React.useMemo((function () {
          return size === "large";
        }), [size]);
  var primary = React.useMemo((function () {
          return kind === "primary";
        }), [kind]);
  var newrecord = Caml_obj.obj_dup(style);
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle((newrecord.paddingTop = Caml_option.some(Core__Option.getOr(style.paddingTop, Primitives__Styling.space(large ? "cs_3" : "cs_2"))), newrecord.paddingRight = Caml_option.some(Core__Option.getOr(style.paddingRight, Primitives__Styling.space(large ? "cs_6" : "cs_5"))), newrecord.paddingLeft = Caml_option.some(Core__Option.getOr(style.paddingLeft, Primitives__Styling.space(large ? "cs_6" : "cs_5"))), newrecord.paddingBottom = Caml_option.some(Core__Option.getOr(style.paddingBottom, Primitives__Styling.space(large ? "cs_3" : "cs_2"))), newrecord.justifyContent = "center", newrecord.gap = Primitives__Styling.Gap.toRNGap(Primitives__Styling.gap("cs_1")), newrecord.flexDirection = "row", newrecord.alignItems = "center", newrecord.borderRadius = Primitives__Styling.radius("rad_5"), newrecord.borderColor = primary ? undefined : Primitives__Styling.color(busy ? ({
                        NAME: "border",
                        VAL: {
                          NAME: "neutral",
                          VAL: "pressed"
                        }
                      }) : ({
                        NAME: "border",
                        VAL: {
                          NAME: "neutral",
                          VAL: "default"
                        }
                      })), newrecord.backgroundColor = Primitives__Styling.color(primary ? (
                    disabled ? ({
                          NAME: "action",
                          VAL: {
                            NAME: "primary",
                            VAL: "disabled"
                          }
                        }) : ({
                          NAME: "action",
                          VAL: {
                            NAME: "primary",
                            VAL: "default"
                          }
                        })
                  ) : ({
                      NAME: "background",
                      VAL: "default"
                    })), newrecord), Caml_option.some(Primitives__Styling.makeSpecificStyles({
                    s: Primitives__Styling.withDisplayNone(specificStyles.s, hideBreakpoints.includes("s")),
                    m: Primitives__Styling.withDisplayNone(specificStyles.m, hideBreakpoints.includes("m")),
                    l: Primitives__Styling.withDisplayNone(specificStyles.l, hideBreakpoints.includes("l")),
                    xl: Primitives__Styling.withDisplayNone(specificStyles.xl, hideBreakpoints.includes("xl"))
                  })), undefined));
  var style$1 = stylesDict["common"];
  var iconColor = React.useMemo((function () {
          return Primitives__Styling.color(primary ? (
                        disabled ? ({
                              NAME: "text",
                              VAL: {
                                NAME: "on_primary",
                                VAL: "disabled"
                              }
                            }) : ({
                              NAME: "text",
                              VAL: {
                                NAME: "on_primary",
                                VAL: "default"
                              }
                            })
                      ) : (
                        disabled ? ({
                              NAME: "action",
                              VAL: {
                                NAME: "neutral",
                                VAL: "disabled"
                              }
                            }) : ({
                              NAME: "action",
                              VAL: {
                                NAME: "neutral",
                                VAL: "default"
                              }
                            })
                      ));
        }), [
        primary,
        disabled
      ]);
  return JsxRuntime.jsx(RescriptTamagui__Button.make, {
              space: 0,
              style: Core__Option.map(style$1, (function (__x) {
                      return Primitives__Styling.Utils.omitStyleKeys(__x, [
                                  "backgroundColor",
                                  "borderColor",
                                  "borderWidth"
                                ]);
                    })),
              hoverStyle: !disabled ? ({
                    borderColor: Primitives__Styling.color(primary ? "primary_0" : ({
                              NAME: "border",
                              VAL: {
                                NAME: "neutral",
                                VAL: "hover"
                              }
                            })),
                    transform: scaleOnHover ? [{
                          scale: 1.01
                        }] : undefined
                  }) : undefined,
              focusStyle: !disabled ? ({
                    borderColor: Primitives__Styling.color(primary ? "primary_0" : ({
                              NAME: "border",
                              VAL: {
                                NAME: "neutral",
                                VAL: "hover"
                              }
                            })),
                    transform: scaleOnHover ? [{
                          scale: 1.01
                        }] : undefined
                  }) : undefined,
              pressStyle: {
                backgroundColor: Primitives__Styling.color(primary ? ({
                          NAME: "action",
                          VAL: {
                            NAME: "primary",
                            VAL: "pressed"
                          }
                        }) : ({
                          NAME: "background",
                          VAL: "subdued"
                        }))
              },
              disabled: disabled,
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              children: Caml_option.some(busy ? JsxRuntime.jsx(Primitives__Group.make, {
                          specificStyles: {
                            l: spinnerContainerStyle,
                            xl: spinnerContainerStyle
                          },
                          children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                                    size: "small",
                                    color: Primitives__Styling.color(primary ? "neutral_9" : "neutral_2")
                                  }))
                        }) : ReactUtils__SimpleReact.renderIfSome(props.title, (function (title) {
                            return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                        style: {
                                          fontWeight: 500
                                        },
                                        kind: "button",
                                        children: Caml_option.some(title),
                                        color: Primitives__Styling.color(primary ? (
                                                disabled ? ({
                                                      NAME: "text",
                                                      VAL: {
                                                        NAME: "on_primary",
                                                        VAL: "disabled"
                                                      }
                                                    }) : ({
                                                      NAME: "text",
                                                      VAL: {
                                                        NAME: "on_primary",
                                                        VAL: "default"
                                                      }
                                                    })
                                              ) : (
                                                disabled ? ({
                                                      NAME: "action",
                                                      VAL: {
                                                        NAME: "neutral",
                                                        VAL: "disabled"
                                                      }
                                                    }) : ({
                                                      NAME: "text",
                                                      VAL: {
                                                        NAME: "on_background",
                                                        VAL: "default"
                                                      }
                                                    })
                                              ))
                                      });
                          }))),
              onPress: (function (e) {
                  if (!busy && !disabled) {
                    return onPress(e);
                  }
                  
                }),
              icon: !busy ? Core__Option.map(props.icon, (function (icon) {
                        return function (_props) {
                          return icon({
                                      size: "ic_1",
                                      color: iconColor
                                    });
                        };
                      })) : undefined,
              iconAfter: !busy ? Core__Option.map(props.iconAfter, (function (icon) {
                        return function (_props) {
                          return icon({
                                      size: "ic_1",
                                      color: iconColor
                                    });
                        };
                      })) : undefined,
              unstyled: true,
              cur: "pointer",
              bc: Core__Option.flatMap(style$1, (function (s) {
                      return s.backgroundColor;
                    })),
              boc: Core__Option.getOr(Core__Option.flatMap(style$1, (function (s) {
                          return s.borderColor;
                        })), Primitives__Styling.color("transparent")),
              bw: Core__Option.getOr(Core__Option.flatMap(style$1, (function (s) {
                          return s.borderWidth;
                        })), 1),
              focusable: props.focusable
            });
}

function Event_bubbles(prim) {
  return Caml_option.nullable_to_opt(prim.bubbles);
}

function Event_cancelable(prim) {
  return Caml_option.nullable_to_opt(prim.cancelable);
}

function Event_currentTarget(prim) {
  return prim.currentTarget;
}

function Event_defaultPrevented(prim) {
  return Caml_option.nullable_to_opt(prim.defaultPrevented);
}

function Event_dispatchConfig(prim) {
  return prim.dispatchConfig;
}

function Event_eventPhase(prim) {
  return Caml_option.nullable_to_opt(prim.eventPhase);
}

function Event_preventDefault(prim) {
  prim.preventDefault();
}

function Event_isDefaultPrevented(prim) {
  return prim.isDefaultPrevented();
}

function Event_stopPropagation(prim) {
  prim.stopPropagation();
}

function Event_isPropagationStopped(prim) {
  return prim.isPropagationStopped();
}

function Event_isTrusted(prim) {
  return Caml_option.nullable_to_opt(prim.isTrusted);
}

function Event_nativeEvent(prim) {
  return prim.nativeEvent;
}

function Event_persist(prim) {
  prim.persist();
}

function Event_target(prim) {
  return Caml_option.nullable_to_opt(prim.target);
}

function Event_timeStamp(prim) {
  return prim.timeStamp;
}

function Event__type(prim) {
  return Caml_option.nullable_to_opt(prim.type);
}

var $$Event = {
  bubbles: Event_bubbles,
  cancelable: Event_cancelable,
  currentTarget: Event_currentTarget,
  defaultPrevented: Event_defaultPrevented,
  dispatchConfig: Event_dispatchConfig,
  eventPhase: Event_eventPhase,
  preventDefault: Event_preventDefault,
  isDefaultPrevented: Event_isDefaultPrevented,
  stopPropagation: Event_stopPropagation,
  isPropagationStopped: Event_isPropagationStopped,
  isTrusted: Event_isTrusted,
  nativeEvent: Event_nativeEvent,
  persist: Event_persist,
  target: Event_target,
  timeStamp: Event_timeStamp,
  _type: Event__type
};

var make = Primitives__Button;

export {
  $$Event ,
  IconProps ,
  make ,
}
/*  Not a pure module */
