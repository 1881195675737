// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrRenderer__Actions from "../actions/DecidrRenderer__Actions.bs.js";

function makePayloadEntries(entries) {
  return entries.map(function (param) {
              return [
                      DecidrRenderer__Actions.PayloadKey.toString(param[0]),
                      param[1]
                    ];
            });
}

function contextSchemaToJson(contextSchema) {
  if (typeof contextSchema === "object") {
    if (contextSchema.TAG === "Object") {
      return Object.fromEntries(contextSchema._0.map(function (param) {
                      return [
                              param.key,
                              contextSchemaToJson(param.value)
                            ];
                    }));
    } else {
      return [contextSchemaToJson(contextSchema._0)];
    }
  }
  switch (contextSchema) {
    case "String" :
        return "";
    case "Float" :
        return 0;
    case "Bool" :
        return false;
    case "Unknown" :
        return null;
    
  }
}

function nodeToJson(node) {
  return Core__Option.flatMap(JSON.stringify(node), (function (jsonString) {
                try {
                  return JSON.parse(jsonString);
                }
                catch (exn){
                  return ;
                }
              }));
}

export {
  makePayloadEntries ,
  contextSchemaToJson ,
  nodeToJson ,
}
/* DecidrRenderer__Actions Not a pure module */
