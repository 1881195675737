// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"id":{"c":"DecidrNodeId.RelayAppSync"},"coreConfig":{"c":"RelayAppSync.AWSJSON"}}};

var fragmentConverterMap = {
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse,
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesUiBlockMenuItemsViewSettingsMenu_view",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coreConfig",
      "storageKey": null
    }
  ],
  "type": "View",
  "abstractKey": null
};

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* DecidrNodeId Not a pure module */
