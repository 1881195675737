// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__Cells from "../../cells/Spaces__Cells.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Row__Utils from "../Spaces__Row__Utils.bs.js";
import * as DecidrRenderer__Renderer from "../../../../renderer/src/renderer/DecidrRenderer__Renderer.bs.js";

function Spaces__Row__ItemRenderer(props) {
  var viewId = props.viewId;
  var rowId = props.rowId;
  var cells = Core__Option.getOr(props.cells, []);
  var cells$1 = cells.map(function (param) {
        return Spaces__Cells.CellFragment.readInline(param.fragmentRefs);
      });
  var context = Spaces__Row__Utils.makeRowContext(props.fieldMapping, rowId, cells$1, props.columnViews, viewId, DecidrNodeId.empty);
  return JsxRuntime.jsx(DecidrRenderer__Renderer.make, {
              node: props.itemLayout,
              context: Caml_option.some(context),
              handleAction: Core__Option.map(props.handleRowAction, (function (handleRowAction) {
                      return function (action) {
                        handleRowAction(viewId, rowId, action);
                      };
                    }))
            });
}

var make = Spaces__Row__ItemRenderer;

export {
  make ,
}
/* DecidrNodeId Not a pure module */
