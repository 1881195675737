// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment_cell_cell(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["MultiSelectCell"]);
}

function unwrap_fragment_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["View"]);
}

var fragmentConverter = {"__root":{"node_View_id":{"c":"DecidrNodeId.RelayAppSync"},"node_View_coreConfig":{"c":"RelayAppSync.AWSJSON"},"node_View":{"f":""},"node":{"u":"fragment_node"},"cell_cell_MultiSelectCell_data_selectedRows_row_id":{"c":"DecidrNodeId.RelayAppSync"},"cell_cell_MultiSelectCell_data_selectedRows_row":{"f":""},"cell_cell_MultiSelectCell_data_selectedRows_id":{"c":"DecidrNodeId.RelayAppSync"},"cell_cell":{"u":"fragment_cell_cell"}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment_cell_cell: unwrap_fragment_cell_cell,
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse,
  fragment_node: unwrap_fragment_node
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "kind": "Variable",
  "name": "rowId",
  "variableName": "callerRowId"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerColumnId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerColumnViewId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerRowId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerViewId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "viewId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesRowRendererLinkRowsModalData_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "viewId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        {
          "kind": "InlineFragment",
          "selections": [
            (v1/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coreConfig",
              "storageKey": null
            },
            {
              "args": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "columnViewId",
                      "variableName": "callerColumnViewId"
                    },
                    (v2/*: any*/),
                    {
                      "kind": "Variable",
                      "name": "viewId",
                      "variableName": "callerViewId"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "caller"
                }
              ],
              "kind": "FragmentSpread",
              "name": "SpacesViewData_view"
            }
          ],
          "type": "View",
          "abstractKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "columnId",
          "variableName": "callerColumnId"
        },
        (v2/*: any*/)
      ],
      "concreteType": "CellPayload",
      "kind": "LinkedField",
      "name": "cell",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "cell",
          "plural": false,
          "selections": [
            (v0/*: any*/),
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "MultiSelectData",
                  "kind": "LinkedField",
                  "name": "data",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "concreteType": "SelectedRowInfo",
                      "kind": "LinkedField",
                      "name": "selectedRows",
                      "plural": true,
                      "selections": [
                        (v1/*: any*/),
                        {
                          "alias": null,
                          "args": null,
                          "concreteType": "Row",
                          "kind": "LinkedField",
                          "name": "row",
                          "plural": false,
                          "selections": [
                            {
                              "args": null,
                              "kind": "FragmentSpread",
                              "name": "SpacesCache_row"
                            },
                            (v1/*: any*/)
                          ],
                          "storageKey": null
                        }
                      ],
                      "storageKey": null
                    }
                  ],
                  "storageKey": null
                }
              ],
              "type": "MultiSelectCell",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());

var wrap_fragment_cell_cell = RescriptRelay_Internal.wrapUnion;

var wrap_fragment_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_cell_cell ,
  wrap_fragment_cell_cell ,
  unwrap_fragment_node ,
  wrap_fragment_node ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
