// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function getHoursAndMinutesFromDate(prim) {
  return Index.getHoursAndMinutesFromDate(prim);
}

function getDayAndDateFromDate(prim) {
  return Index.getDayAndDateFromDate(prim);
}

function getDateFromDate(prim) {
  return Index.getDateFromDate(prim);
}

function getFullDateTimeFromDate(prim) {
  return Index.getFullDateTimeFromDate(prim);
}

export {
  getHoursAndMinutesFromDate ,
  getDayAndDateFromDate ,
  getDateFromDate ,
  getFullDateTimeFromDate ,
}
/* ./index Not a pure module */
