// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Utils__Time from "../../../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__View from "../../view/Spaces__View.bs.js";
import * as Blocks__Utils from "@pageblocks/blocks/src/utils/Blocks__Utils.bs.js";
import LodashDebounce from "lodash.debounce";
import * as DecidrInternalId from "../../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as Primitives__Modal from "../../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Search from "../../../../primitives/src/search/Primitives__Search.bs.js";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Primitives__Spinner from "../../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as DecidrRenderer__Actions from "../../../../renderer/src/actions/DecidrRenderer__Actions.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Cells__Mutations from "../../cells/Spaces__Cells__Mutations.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as Spaces__Row__Renderer__BlockUtils from "./Spaces__Row__Renderer__BlockUtils.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "./Spaces__Row__Renderer__BlockContext.bs.js";
import * as SpacesRowRendererLinkRowsModalQuery_graphql from "../../../../relay-codegen/generated/SpacesRowRendererLinkRowsModalQuery_graphql.bs.js";
import * as SpacesRowRendererLinkRowsModalData_query_graphql from "../../../../relay-codegen/generated/SpacesRowRendererLinkRowsModalData_query_graphql.bs.js";

var convertFragment = SpacesRowRendererLinkRowsModalData_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesRowRendererLinkRowsModalData_query_graphql.node, convertFragment, fRef);
}

var performSearch = LodashDebounce((function (searchTerm, searchFn) {
        searchFn(searchTerm, undefined);
      }), Utils__Time.secondInMs);

var developerSettingsNumberOfLinksStoreKey = "developer_settings_number_of_links";

function getNumberOfAllowedLinks(coreConfig) {
  return Core__Option.map(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[developerSettingsNumberOfLinksStoreKey];
                      })), Core__JSON.Decode.$$float), (function (prim) {
                return prim | 0;
              }));
}

function Spaces__Row__Renderer__LinkRowsModal$Data(props) {
  var onLinkRows = props.onLinkRows;
  var match = use(props.queryRef);
  var node = match.node;
  var cell = match.cell.cell;
  var match$1 = Primitives__Input.use("", undefined, undefined);
  var onChange = match$1.onChange;
  var value = match$1.value;
  var numberOfAllowedLinks = node !== undefined && node.__typename === "View" ? getNumberOfAllowedLinks(node.coreConfig) : undefined;
  var match$2;
  if (cell !== undefined) {
    if (cell.__typename === "MultiSelectCell") {
      var selectedRows = cell.data.selectedRows;
      match$2 = [
        DecidrInternalId.$$Set.fromArray(selectedRows.map(function (param) {
                  return DecidrNodeId.magicallyToInternalId(param.row.id);
                })),
        selectedRows
      ];
    } else {
      match$2 = [
        DecidrInternalId.$$Set.empty,
        []
      ];
    }
  } else {
    match$2 = [
      DecidrInternalId.$$Set.empty,
      []
    ];
  }
  var selectedRowsInfo = match$2[1];
  var alreadySelectedRows = match$2[0];
  var match$3 = React.useState(function () {
        return alreadySelectedRows;
      });
  var setSelectedRows = match$3[1];
  var selectedRows$1 = match$3[0];
  var match$4 = React.useState(function () {
        
      });
  var setError = match$4[1];
  var handleOnChange = function (searchTerm, searchFn) {
    onChange(searchTerm);
    performSearch(searchTerm, searchFn);
  };
  if (node !== undefined) {
    if (node.__typename === "View") {
      return JsxRuntime.jsxs(Primitives__Group.make, {
                  gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                  style: {
                    backgroundColor: Primitives__Styling.color({
                          NAME: "background",
                          VAL: "default"
                        }),
                    flex: 1
                  },
                  children: [
                    JsxRuntime.jsx(Spaces__View.Data.ListLayout.make, {
                          viewRef: node.fragmentRefs,
                          fixedHeaderComponent: (function (param) {
                              var handleSearch = param.handleSearch;
                              return JsxRuntime.jsxs(Primitives__Group.make, {
                                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                          orientation: "horizontal",
                                          style: {
                                            paddingBottom: Caml_option.some(Primitives__Styling.space("cs_2")),
                                            paddingLeft: Caml_option.some(Primitives__Styling.space("cs_4")),
                                            paddingRight: Caml_option.some(Primitives__Styling.dp(50)),
                                            paddingTop: Caml_option.some(Primitives__Styling.space("ss_1"))
                                          },
                                          children: [
                                            JsxRuntime.jsx(Primitives__Search.make, {
                                                  value: value,
                                                  onChange: (function (__x) {
                                                      handleOnChange(__x, handleSearch);
                                                    }),
                                                  placeholder: "Search...",
                                                  onClear: (function () {
                                                      handleOnChange("", handleSearch);
                                                    }),
                                                  searching: param.searching,
                                                  style: {
                                                    flex: 1
                                                  }
                                                }),
                                            JsxRuntime.jsx(Primitives__Group.make, {
                                                  style: {
                                                    alignSelf: "center",
                                                    paddingLeft: Caml_option.some(Primitives__Styling.space("cs_2"))
                                                  },
                                                  children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                            children: Caml_option.some("Selected: " + DecidrInternalId.$$Set.size(selectedRows$1).toString())
                                                          }))
                                                })
                                          ]
                                        });
                            }),
                          handleRowAction: (function (param, rowId, action, param$1) {
                              var action$1 = DecidrRenderer__Actions.parseAction(action);
                              if (action$1 === undefined) {
                                return ;
                              }
                              if (typeof action$1 === "object") {
                                return ;
                              }
                              if (action$1 !== "NavigateToRow") {
                                return ;
                              }
                              var match = DecidrInternalId.$$Set.has(selectedRows$1, rowId);
                              if (match) {
                                if (numberOfAllowedLinks === 1) {
                                  return ;
                                }
                                return setSelectedRows(function (__x) {
                                            return DecidrInternalId.$$Set.remove(__x, rowId);
                                          });
                              }
                              if (numberOfAllowedLinks === undefined) {
                                return setSelectedRows(function (__x) {
                                            return DecidrInternalId.$$Set.add(__x, rowId);
                                          });
                              }
                              if (numberOfAllowedLinks === 0) {
                                return ;
                              }
                              if (numberOfAllowedLinks !== 1) {
                                if (numberOfAllowedLinks > DecidrInternalId.$$Set.size(selectedRows$1)) {
                                  return setSelectedRows(function (__x) {
                                              return DecidrInternalId.$$Set.add(__x, rowId);
                                            });
                                } else {
                                  return ;
                                }
                              }
                              var rowIdsToRemove = Core__Array.filterMap(DecidrInternalId.$$Set.toArray(alreadySelectedRows), (function (rowIdToRemove) {
                                      return Core__Option.map(selectedRowsInfo.find(function (selectedRowInfo) {
                                                      return Caml_obj.equal(DecidrNodeId.magicallyToInternalId(selectedRowInfo.row.id), rowIdToRemove);
                                                    }), (function (param) {
                                                    return DecidrNodeId.magicallyToInternalId(param.id);
                                                  }));
                                    }));
                              onLinkRows([rowId], rowIdsToRemove, (function (message) {
                                      setError(function (param) {
                                            return message;
                                          });
                                    }));
                            }),
                          modifyItemLayout: (function (rowId) {
                              return DecidrInternalId.$$Set.has(selectedRows$1, rowId);
                            })
                        }),
                    JsxRuntime.jsxs(Primitives__Group.make, {
                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                          style: {
                            alignItems: "flex-end",
                            paddingBottom: Caml_option.some(Primitives__Styling.space("cs_4")),
                            paddingLeft: Caml_option.some(Primitives__Styling.space("cs_4")),
                            paddingRight: Caml_option.some(Primitives__Styling.space("cs_4"))
                          },
                          children: [
                            ReactUtils__SimpleReact.renderIfSome(match$4[0], (function (error) {
                                    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                style: {
                                                  fontWeight: 500
                                                },
                                                children: Caml_option.some(error),
                                                color: Primitives__Styling.color({
                                                      NAME: "text",
                                                      VAL: {
                                                        NAME: "error",
                                                        VAL: "default"
                                                      }
                                                    })
                                              });
                                  })),
                            JsxRuntime.jsx(Primitives__Button.make, {
                                  title: "Save",
                                  onPress: (function (param) {
                                      var rowIdsToAdd = DecidrInternalId.$$Set.toArray(DecidrInternalId.$$Set.diff(selectedRows$1, alreadySelectedRows));
                                      var rowIdsToRemove = Core__Array.filterMap(DecidrInternalId.$$Set.toArray(DecidrInternalId.$$Set.diff(alreadySelectedRows, selectedRows$1)), (function (rowIdToRemove) {
                                              return Core__Option.map(selectedRowsInfo.find(function (selectedRowInfo) {
                                                              return Caml_obj.equal(DecidrNodeId.magicallyToInternalId(selectedRowInfo.row.id), rowIdToRemove);
                                                            }), (function (x) {
                                                            return DecidrNodeId.magicallyToInternalId(x.id);
                                                          }));
                                            }));
                                      setError(function (param) {
                                            
                                          });
                                      onLinkRows(rowIdsToAdd, rowIdsToRemove, (function (message) {
                                              setError(function (param) {
                                                    return message;
                                                  });
                                            }));
                                    }),
                                  size: "small",
                                  disabled: DecidrInternalId.$$Set.eq(alreadySelectedRows, selectedRows$1),
                                  busy: props.linking
                                })
                          ]
                        })
                  ]
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a View", "Spaces__Row__Renderer__LinkRowsModal Data", {
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Row__Renderer__LinkRowsModal Data", undefined);
  return null;
}

function Spaces__Row__Renderer__LinkRowsModal$Loading(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                padding: Caml_option.some(Primitives__Styling.space("ss_2"))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                        size: "small"
                      }))
            });
}

var convertVariables = SpacesRowRendererLinkRowsModalQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesRowRendererLinkRowsModalQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesRowRendererLinkRowsModalQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesRowRendererLinkRowsModalQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesRowRendererLinkRowsModalQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesRowRendererLinkRowsModalQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesRowRendererLinkRowsModalQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesRowRendererLinkRowsModalQuery_graphql.node, convertVariables);

function Spaces__Row__Renderer__LinkRowsModal$ConnectedData(props) {
  var onLinkRowsCompleted = props.onLinkRowsCompleted;
  var callerColumnViewId = props.callerColumnViewId;
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var match = Spaces__Cells__Mutations.useUpdateMultiSelectCell();
  var linkRows = match[0];
  var match$1 = use$1({
        callerColumnId: DecidrNodeId.magicallyFromInternalId(callerColumnId),
        callerColumnViewId: callerColumnViewId,
        callerRowId: DecidrNodeId.magicallyFromInternalId(callerRowId),
        callerViewId: props.callerViewId,
        sourceViewId: props.sourceViewId
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(Spaces__Row__Renderer__LinkRowsModal$Data, {
                        queryRef: match$1.fragmentRefs,
                        onLinkRows: (function (rowIdsToAdd, rowIdsToRemove, onError) {
                            linkRows(callerRowId, callerColumnId, callerColumnViewId, undefined, rowIdsToAdd, rowIdsToRemove, onError, onLinkRowsCompleted);
                          }),
                        linking: match[1]
                      })),
              fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Row__Renderer__LinkRowsModal$Loading, {}))
            });
}

var blockCode = "modal-link-rows-block";

function Spaces__Row__Renderer__LinkRowsModal$Component(props) {
  var handleAction = props.handleAction;
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var onCloseModalActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(props.triggerActions), (function (__x) {
                  return __x["onCloseModal"];
                })), Blocks__Utils.getActions), []);
  var sourceViewIdToOpen = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.LinkRowsModal.Keys.sourceViewIdToOpen), DecidrNodeId.fromString);
  var callerRowId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.LinkRowsModal.Keys.callerRowId), DecidrInternalId.fromString);
  var callerColumnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.LinkRowsModal.Keys.callerColumnId), DecidrInternalId.fromString);
  var callerColumnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.LinkRowsModal.Keys.callerColumnViewId), DecidrNodeId.fromString);
  var callerViewId = Spaces__Row__Renderer__BlockUtils.getViewId(context);
  var handleOnClose = function () {
    if (onCloseModalActions.length !== 0 && handleAction !== undefined) {
      onCloseModalActions.forEach(function (__x) {
            handleAction(__x);
          });
      return ;
    }
    
  };
  var show = Core__Option.isSome(sourceViewIdToOpen) && Core__Option.isSome(callerRowId) && Core__Option.isSome(callerColumnId) && Core__Option.isSome(callerColumnViewId) && Core__Option.isSome(callerViewId);
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Primitives__Modal.LongContentWebFix.make, {
                    children: sourceViewIdToOpen !== undefined && callerColumnId !== undefined && callerRowId !== undefined && callerColumnViewId !== undefined && callerViewId !== undefined ? JsxRuntime.jsx(Primitives__Group.make, {
                            style: {
                              backgroundColor: Primitives__Styling.color({
                                    NAME: "surface",
                                    VAL: "default"
                                  }),
                              flex: 1
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Spaces__Row__Renderer__LinkRowsModal$ConnectedData, {
                                      sourceViewId: Caml_option.valFromOption(sourceViewIdToOpen),
                                      callerRowId: Caml_option.valFromOption(callerRowId),
                                      callerColumnId: Caml_option.valFromOption(callerColumnId),
                                      callerColumnViewId: Caml_option.valFromOption(callerColumnViewId),
                                      callerViewId: Caml_option.valFromOption(callerViewId),
                                      onLinkRowsCompleted: handleOnClose
                                    }))
                          }) : null
                  }),
              show: show,
              onClose: handleOnClose,
              maxWidth: 600
            });
}

var Component = {
  make: Spaces__Row__Renderer__LinkRowsModal$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

export {
  developerSettingsNumberOfLinksStoreKey ,
  getNumberOfAllowedLinks ,
  blockCode ,
  version ,
  descriptor ,
}
/* performSearch Not a pure module */
