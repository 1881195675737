// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ErrorReporting from "../../error-reporting/src/ErrorReporting.bs.js";

var debugPrefix = "[RelayMutationError]";

function handle(errorResponse, message, debugContext, onError) {
  var reason = Core__Option.flatMap(Core__Option.flatMap(errorResponse.errors, (function (__x) {
              return __x[0];
            })), (function (error) {
          return error.message;
        }));
  ErrorReporting.captureError(message, debugPrefix + " " + debugContext, errorResponse);
  console.log(debugPrefix, debugContext, message, errorResponse);
  Core__Option.forEach(onError, (function (onError) {
          onError(message, reason);
        }));
}

export {
  handle ,
}
/* ErrorReporting Not a pure module */
