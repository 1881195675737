// Generated by ReScript, PLEASE EDIT WITH CARE


var minuteInMs = 60 * 1000;

var hourInMs = 60 * minuteInMs;

var dayInMs = 24 * hourInMs;

var secondInMs = 1000;

var secondsInMinute = 60;

var minutesInHour = 60;

var hoursInDay = 24;

export {
  secondInMs ,
  secondsInMinute ,
  minuteInMs ,
  minutesInHour ,
  hourInMs ,
  hoursInDay ,
  dayInMs ,
}
/* No side effect */
