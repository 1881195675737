// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as SpacesCells_cell_graphql from "../../../relay-codegen/generated/SpacesCells_cell_graphql.bs.js";

var convertFragment = SpacesCells_cell_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesCells_cell_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesCells_cell_graphql.node, convertFragment);
}

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(SpacesCells_cell_graphql.node, convertFragment, fRef);
}

var CellFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readInline: readInline
};

function getCellSignature(cell) {
  if (cell.__typename === "__unselected") {
    return ;
  } else {
    return {
            rowId: DecidrNodeId.magicallyToInternalId(cell.rowId),
            columnId: DecidrNodeId.magicallyToInternalId(cell.columnId)
          };
  }
}

export {
  CellFragment ,
  getCellSignature ,
}
/* DecidrNodeId Not a pure module */
