// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";

function Primitives__Toggle(props) {
  var value = props.value;
  var color = value ? Primitives__Styling.color("secondary_2") : Primitives__Styling.color({
          NAME: "background",
          VAL: "subdued"
        });
  return JsxRuntime.jsx(Tamagui.Switch, {
              checked: value,
              onCheckedChange: props.onChange,
              size: "$2",
              children: Caml_option.some(JsxRuntime.jsx(Tamagui.Switch.Thumb, {
                        animation: "quick",
                        bg: Primitives__Styling.color({
                              NAME: "surface",
                              VAL: "default"
                            })
                      })),
              bg: color,
              focusStyle: {
                borderColor: color,
                borderWidth: 2
              }
            });
}

var make = Primitives__Toggle;

export {
  make ,
}
/* tamagui Not a pure module */
