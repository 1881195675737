// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__Image from "../../../rescript-tamagui/src/image/RescriptTamagui__Image.bs.js";

function mergeSpecificStylesAndResponsiveSize(specificStyles, responsiveSizes) {
  var ss = Core__Option.getOr(specificStyles, TamaguiConfig.Utils.emptySpecificStyles);
  var newrecord = Caml_obj.obj_dup(Core__Option.getOr(ss.s, Primitives__Styling.empty));
  var newrecord$1 = Caml_obj.obj_dup(Core__Option.getOr(ss.m, Primitives__Styling.empty));
  var newrecord$2 = Caml_obj.obj_dup(Core__Option.getOr(ss.l, Primitives__Styling.empty));
  var newrecord$3 = Caml_obj.obj_dup(Core__Option.getOr(ss.xl, Primitives__Styling.empty));
  return {
          s: (newrecord.width = responsiveSizes.s.width, newrecord.height = responsiveSizes.s.height, newrecord),
          m: (newrecord$1.width = responsiveSizes.m.width, newrecord$1.height = responsiveSizes.m.height, newrecord$1),
          l: (newrecord$2.width = responsiveSizes.l.width, newrecord$2.height = responsiveSizes.l.height, newrecord$2),
          xl: (newrecord$3.width = responsiveSizes.xl.width, newrecord$3.height = responsiveSizes.xl.height, newrecord$3)
        };
}

function make(param) {
  var specificStyles = param.specificStyles;
  var alt = param.alt;
  var size = param.size;
  var hideBreakpoints = Core__Option.getOr(param.hideBreakpoints, []);
  var specificStyles$1;
  specificStyles$1 = size.TAG === "Responsive" ? mergeSpecificStylesAndResponsiveSize(specificStyles, size._0) : Core__Option.getOr(specificStyles, TamaguiConfig.Utils.emptySpecificStyles);
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle(Core__Option.getOr(param.style, Primitives__Styling.empty), Caml_option.some(Primitives__Styling.makeSpecificStyles({
                    s: Primitives__Styling.withDisplayNone(specificStyles$1.s, hideBreakpoints.includes("s")),
                    m: Primitives__Styling.withDisplayNone(specificStyles$1.m, hideBreakpoints.includes("m")),
                    l: Primitives__Styling.withDisplayNone(specificStyles$1.l, hideBreakpoints.includes("l")),
                    xl: Primitives__Styling.withDisplayNone(specificStyles$1.xl, hideBreakpoints.includes("xl"))
                  })), undefined));
  var style = Core__Option.getOr(stylesDict["common"], Primitives__Styling.empty);
  var style$1 = Primitives__Styling.Utils.omitStyleKeys(style, [
        "backgroundColor",
        "borderColor",
        "borderWidth"
      ]);
  var tmp;
  switch (size.TAG) {
    case "Square" :
        var size$1 = size._0;
        var newrecord = Caml_obj.obj_dup(style$1);
        newrecord.width = Caml_option.some(size$1);
        newrecord.height = Caml_option.some(size$1);
        tmp = newrecord;
        break;
    case "WidthAutoHeight" :
        var newrecord$1 = Caml_obj.obj_dup(style$1);
        newrecord$1.width = Caml_option.some(size._0);
        newrecord$1.flex = 1;
        tmp = newrecord$1;
        break;
    case "HeightAutoWidth" :
        var newrecord$2 = Caml_obj.obj_dup(style$1);
        newrecord$2.height = Caml_option.some(size._0);
        tmp = newrecord$2;
        break;
    case "Rect" :
        var newrecord$3 = Caml_obj.obj_dup(style$1);
        newrecord$3.width = Caml_option.some(size.width);
        newrecord$3.height = Caml_option.some(size.height);
        tmp = newrecord$3;
        break;
    case "Responsive" :
        tmp = style$1;
        break;
    
  }
  return JsxRuntime.jsx(RescriptTamagui__Image.make, {
              accessibilityLabel: alt,
              accessible: param.accessible,
              alt: alt,
              blurRadius: param.blurRadius,
              capInsets: param.capInsets,
              crossOrigin: param.crossOrigin,
              fadeDuration: param.fadeDuration,
              height: param.height,
              onError: param.onError,
              onLayout: param.onLayout,
              onLoad: param.onLoad,
              onLoadEnd: param.onLoadEnd,
              onLoadStart: param.onLoadStart,
              onPartialLoad: param.onPartialLoad,
              onProgress: param.onProgress,
              progressiveRenderingEnabled: param.progressiveRenderingEnabled,
              referrerPolicy: param.referrerPolicy,
              resizeMethod: param.resizeMethod,
              resizeMode: param.resizeMode,
              srcSet: param.srcSet,
              style: tmp,
              testID: param.testID,
              tintColor: param.tintColor,
              width: param.width,
              source: {
                uri: param.src
              },
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              bc: style.backgroundColor,
              boc: style.borderColor,
              bw: style.borderWidth
            });
}

export {
  mergeSpecificStylesAndResponsiveSize ,
  make ,
}
/* TamaguiConfig Not a pure module */
