// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";

var Trigger = {
  make: Tamagui.Dialog.Trigger
};

var Portal = {
  make: Tamagui.Dialog.Portal
};

var Overlay = {
  make: Tamagui.Dialog.Overlay
};

var Content = {
  make: Tamagui.Dialog.Content
};

var Title = {
  make: Tamagui.Dialog.Title
};

var Description = {
  make: Tamagui.Dialog.Description
};

var Close = {
  make: Tamagui.Dialog.Close
};

var Adapt_Contents = {
  make: Tamagui.Dialog.Adapt.Contents
};

var Adapt_make = Tamagui.Dialog.Adapt;

var Adapt = {
  Contents: Adapt_Contents,
  make: Adapt_make
};

var Sheet_Frame = {
  make: Tamagui.Dialog.Sheet.Frame
};

var Sheet_Overlay = {
  make: Tamagui.Dialog.Sheet.Overlay
};

var Sheet_make = Tamagui.Dialog.Sheet;

var Sheet = {
  Frame: Sheet_Frame,
  Overlay: Sheet_Overlay,
  make: Sheet_make
};

var make = Tamagui.Dialog;

export {
  Trigger ,
  Portal ,
  Overlay ,
  Content ,
  Title ,
  Description ,
  Close ,
  Adapt ,
  Sheet ,
  make ,
}
/* Trigger Not a pure module */
