// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";

function Spaces__Ui__Shared$SimpleListItem(props) {
  var onPress = props.onPress;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__Group.make, {
                    style: {
                      backgroundColor: Primitives__Styling.color({
                            NAME: "surface",
                            VAL: "default"
                          }),
                      borderRadius: Primitives__Styling.radius("rad_3"),
                      padding: Caml_option.some(Primitives__Styling.space("cs_4"))
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                              children: Caml_option.some(props.title)
                            }))
                  }),
              onPress: (function (param) {
                  onPress();
                })
            });
}

var SimpleListItem = {
  make: Spaces__Ui__Shared$SimpleListItem
};

function Spaces__Ui__Shared$SimpleListContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "default"
                    }),
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: Caml_option.some(props.children)
            });
}

var SimpleListContainer = {
  make: Spaces__Ui__Shared$SimpleListContainer
};

export {
  SimpleListItem ,
  SimpleListContainer ,
}
/* Primitives__Group Not a pure module */
