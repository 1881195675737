// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as Utils__Ignored from "../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Select from "../../../primitives/src/select/Primitives__Select.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as Spaces__View__Mutations from "../view/Spaces__View__Mutations.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesBlockHttpRequestTriggerExecutorQuery_graphql from "../../../relay-codegen/generated/SpacesBlockHttpRequestTriggerExecutorQuery_graphql.bs.js";
import * as SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql from "../../../relay-codegen/generated/SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.bs.js";
import * as SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql from "../../../relay-codegen/generated/SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.bs.js";

function Spaces__Block__HttpRequestTrigger$Labeled(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      children: Caml_option.some(props.label)
                    }),
                props.children
              ]
            });
}

function Spaces__Block__HttpRequestTrigger$Tab(props) {
  var __selected = props.selected;
  var onPress = props.onPress;
  var selected = __selected !== undefined ? __selected : false;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__DecidrText.make, {
                    style: {
                      fontWeight: 500
                    },
                    children: Caml_option.some(props.title),
                    color: Primitives__Styling.color(selected ? "primary_1" : "neutral_3")
                  }),
              style: {
                borderBottomWidth: 2,
                borderColor: Primitives__Styling.color(selected ? "primary_1" : "neutral_5"),
                borderWidth: 0,
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              onPress: (function (param) {
                  onPress();
                })
            });
}

var availableMethods = [
  "GET",
  "POST",
  "PUT",
  "DELETE"
];

function getMethod(method) {
  return Core__Option.flatMap(method, (function (method) {
                return availableMethods.find(function (availableMethod) {
                            return availableMethod === method;
                          });
              }));
}

function getMethodWithDefault(method) {
  return Core__Option.getOr(getMethod(method), "GET");
}

function Spaces__Block__HttpRequestTrigger$MethodPicker(props) {
  var onSelect = props.onSelect;
  var selectedMethod = getMethodWithDefault(props.selectedMethod);
  return JsxRuntime.jsx(Primitives__Select.make, {
              title: "Select HTTP method",
              data: availableMethods,
              selectedItem: selectedMethod,
              renderItem: (function (param) {
                  var close = param.close;
                  var item = param.item;
                  return JsxRuntime.jsx(Primitives__Select.Item.make, {
                              selected: Core__Option.map(param.selectedItem, (function (selectedItem) {
                                      return selectedItem === item;
                                    })),
                              text: item,
                              onPress: (function () {
                                  onSelect(item);
                                  close();
                                })
                            });
                }),
              keyExtractor: (function (method) {
                  return method;
                }),
              trigger: (function (selectedItem, show) {
                  return JsxRuntime.jsx(Primitives__Select.Trigger.make, {
                              text: selectedItem !== undefined ? selectedItem : "Select HTTP method",
                              onPress: (function (_e) {
                                  show();
                                }),
                              style: {
                                alignSelf: "flex-start"
                              },
                              borderColor: Primitives__Styling.color({
                                    NAME: "border",
                                    VAL: {
                                      NAME: "neutral",
                                      VAL: "default"
                                    }
                                  })
                            });
                }),
              emptyDataText: "No HTTP methods available"
            });
}

var endpoint = "http_request_trigger::endpoint";

var method = "http_request_trigger::method";

function serialize(config) {
  return Object.fromEntries([
              [
                endpoint,
                Core__Option.getOr(Core__Option.map(config.endpoint, (function (prim) {
                            return prim;
                          })), null)
              ],
              [
                method,
                Core__Option.getOr(Core__Option.map(config.method, (function (prim) {
                            return prim;
                          })), null)
              ]
            ]);
}

function deserialize(json) {
  var configDict = Core__JSON.Classify.classify(json);
  if (typeof configDict === "object" && configDict.TAG === "Object") {
    var configDict$1 = configDict._0;
    var endpoint$1 = Core__Option.flatMap(configDict$1[endpoint], Core__JSON.Decode.string);
    var method$1 = Core__Option.flatMap(configDict$1[method], Core__JSON.Decode.string);
    return {
            endpoint: endpoint$1,
            method: method$1
          };
  }
  ErrorHandling__UnexpectedError.handle("Invalid configuration for HTTP Request Trigger", "Spaces__Block__HttpRequestTrigger Configuration deserialize", {
        json: json
      });
}

var convertVariables = SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockHttpRequestTriggerConfiguratorQuery_graphql.node, convertVariables);

function makeSettingsStoreKey(columnViewId) {
  return "block-settings::" + DecidrNodeId.toString(columnViewId);
}

function Spaces__Block__HttpRequestTrigger$Configurator(props) {
  var viewId = props.viewId;
  var columnViewId = props.columnViewId;
  var toast = Primitives__DecidrToast.use();
  var match = Spaces__View__Mutations.useUpdateCoreConfig();
  var updateCoreConfig = match[0];
  var match$1 = Auth__Hooks.useAuth();
  var authStatus = match$1.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$2 = use({
        skipQuery: !isAuthenticated,
        viewId: viewId
      }, undefined, undefined, undefined);
  var coreConfig = Core__Option.getOr(Core__Option.flatMap(match$2.node, (function (node) {
              if (node.__typename === "View") {
                return node.coreConfig;
              }
              
            })), {});
  var storedSettingsJson = Core__Option.flatMap(Core__JSON.Decode.object(coreConfig), (function (__x) {
          return __x[makeSettingsStoreKey(columnViewId)];
        }));
  var match$3 = React.useState(function () {
        return Core__Option.getOr(Core__Option.flatMap(storedSettingsJson, deserialize), {
                    endpoint: undefined,
                    method: undefined
                  });
      });
  var setConfig = match$3[1];
  var config = match$3[0];
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
              children: [
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: Core__Option.getOr(config.endpoint, ""),
                      onChange: (function (value) {
                          setConfig(function (param) {
                                return {
                                        endpoint: value,
                                        method: config.method
                                      };
                              });
                        }),
                      label: "Endpoint",
                      placeholder: "https://example.com/api/my-api-endpoint"
                    }),
                JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger$Labeled, {
                      label: "HTTP Method",
                      children: JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger$MethodPicker, {
                            selectedMethod: config.method,
                            onSelect: (function (method) {
                                setConfig(function (param) {
                                      return {
                                              endpoint: config.endpoint,
                                              method: method
                                            };
                                    });
                              })
                          })
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: "Save",
                      style: {
                        alignSelf: "flex-start"
                      },
                      onPress: (function (param) {
                          var configJson = serialize(config);
                          var key = makeSettingsStoreKey(columnViewId);
                          var entry = [
                            key,
                            configJson
                          ];
                          var coreConfigDict = Core__Option.getOr(Core__JSON.Decode.object(coreConfig), {});
                          var newCoreConfig = Object.fromEntries(Object.entries(coreConfigDict).concat([entry]));
                          updateCoreConfig(viewId, newCoreConfig, (function () {
                                  toast.show("Configuration saved", {
                                        message: "The configuration was saved"
                                      });
                                }), (function (message) {
                                  toast.error("Error saving configuration", message);
                                }), undefined);
                        }),
                      busy: match[1]
                    })
              ]
            });
}

function fromString(method) {
  switch (method) {
    case "DELETE" :
        return "DELETE";
    case "GET" :
        return "GET";
    case "POST" :
        return "POST";
    case "PUT" :
        return "PUT";
    default:
      return ;
  }
}

function fromStringOr(method, $$default) {
  return Core__Option.getOr(fromString(method), $$default);
}

var convertVariables$1 = SpacesBlockHttpRequestTriggerExecutorQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockHttpRequestTriggerExecutorQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockHttpRequestTriggerExecutorQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockHttpRequestTriggerExecutorQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockHttpRequestTriggerExecutorQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockHttpRequestTriggerExecutorQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesBlockHttpRequestTriggerExecutorQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesBlockHttpRequestTriggerExecutorQuery_graphql.node, convertVariables$1);

var convertVariables$2 = SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.Internal.convertResponse;

RescriptRelay_Query.useQuery(convertVariables$2, SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.node, convertResponse$2);

RescriptRelay_Query.useLoader(convertVariables$2, SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.node, convertResponse$2, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.node, convertResponse$2, convertVariables$2);

var fetchPromised = RescriptRelay_Query.fetchPromised(SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.node, convertResponse$2, convertVariables$2);

RescriptRelay_Query.retain(SpacesBlockHttpRequestTriggerExecutorRowJsonQuery_graphql.node, convertVariables$2);

function Spaces__Block__HttpRequestTrigger$Executor(props) {
  var columnViewId = props.columnViewId;
  var viewId = props.viewId;
  var rowId = props.rowId;
  var toast = Primitives__DecidrToast.use();
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var environment = RescriptRelay.useEnvironmentFromContext();
  var match$1 = use$1({
        skipQuery: !isAuthenticated,
        viewId: viewId
      }, undefined, undefined, undefined);
  var getRowJsonData = function () {
    return fetchPromised(environment, {
                  rowId: DecidrNodeId.magicallyFromInternalId(rowId),
                  skipQuery: !isAuthenticated,
                  viewId: viewId
                }, undefined, "network-only").then(function (param) {
                return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(param.node, (function (node) {
                                      if (node.__typename === "View") {
                                        return node.row;
                                      }
                                      
                                    })), (function (param) {
                                  return param.cellsJson;
                                })), null);
              });
  };
  var coreConfig = Core__Option.getOr(Core__Option.flatMap(match$1.node, (function (node) {
              if (node.__typename === "View") {
                return node.coreConfig;
              }
              
            })), {});
  var storedSettingsJson = Core__Option.flatMap(Core__JSON.Decode.object(coreConfig), (function (__x) {
          return __x[makeSettingsStoreKey(columnViewId)];
        }));
  var config = Core__Option.flatMap(storedSettingsJson, deserialize);
  var disabled = config !== undefined && config.endpoint !== undefined ? config.method === undefined : true;
  return JsxRuntime.jsx(Primitives__Button.make, {
              title: "Run",
              onPress: (function (param) {
                  if (config === undefined) {
                    return toast.error("Missing or incomplete configuration", "Please configure the trigger");
                  }
                  var endpoint = config.endpoint;
                  if (endpoint === undefined) {
                    return toast.error("Missing or incomplete configuration", "Please configure the trigger");
                  }
                  var method = config.method;
                  if (method !== undefined) {
                    console.log("Triggering HTTP Request with endpoint: " + endpoint + " and method: " + method);
                    return Utils__Ignored.promise(getRowJsonData().then(function (rowJson) {
                                    Utils__Ignored.promiseWithPayload(Core__Promise.$$catch(fetch(endpoint, {
                                                    method: fromStringOr(method, "GET"),
                                                    body: Caml_option.some(JSON.stringify(rowJson)),
                                                    headers: Caml_option.some(new Headers({
                                                              "Content-type": "application/json",
                                                              accept: "application/json"
                                                            }))
                                                  }).then(function (resp) {
                                                  if (resp.ok) {
                                                    return resp.json().then(function (json_) {
                                                                toast.show("HTTP Request successfully triggered", {
                                                                      message: "The server responded with a success"
                                                                    });
                                                                return Promise.resolve(json_);
                                                              });
                                                  } else {
                                                    Utils__Ignored.promise(resp.json().then(function (_response) {
                                                              toast.error("HTTP Request failed", "The server responded with an error");
                                                            }));
                                                    return Promise.resolve(null);
                                                  }
                                                }), (function (exn) {
                                                if (exn.RE_EXN_ID === "JsError") {
                                                  toast.error("HTTP Request failed", exn._1.message);
                                                } else {
                                                  toast.error("HTTP Request failed", "The server did not respond");
                                                }
                                                return Promise.resolve(null);
                                              })));
                                  }));
                  } else {
                    return toast.error("Missing or incomplete configuration", "Please configure the trigger");
                  }
                }),
              disabled: disabled
            });
}

function Spaces__Block__HttpRequestTrigger(props) {
  var viewId = props.viewId;
  var columnViewId = props.columnViewId;
  var match = React.useState(function () {
        return "Execution";
      });
  var setMode = match[1];
  var mode = match[0];
  var tmp;
  tmp = mode === "Execution" ? JsxRuntime.jsx(Primitives__Group.make, {
          style: {
            alignItems: "flex-start"
          },
          children: Caml_option.some(JsxRuntime.jsx(Primitives__Suspense.make, {
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Button.make, {
                              title: "Run",
                              onPress: (function (param) {
                                  
                                }),
                              disabled: true
                            })),
                    children: JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger$Executor, {
                          rowId: props.rowId,
                          viewId: viewId,
                          columnViewId: columnViewId
                        })
                  }))
        }) : JsxRuntime.jsx(Primitives__Suspense.make, {
          children: JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger$Configurator, {
                columnViewId: columnViewId,
                viewId: viewId
              })
        });
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_6")),
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      orientation: "horizontal",
                      children: [
                        JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger$Tab, {
                              title: "Trigger",
                              onPress: (function () {
                                  setMode(function (param) {
                                        return "Execution";
                                      });
                                }),
                              selected: mode === "Execution"
                            }),
                        JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger$Tab, {
                              title: "Configure",
                              onPress: (function () {
                                  setMode(function (param) {
                                        return "Configuration";
                                      });
                                }),
                              selected: mode === "Configuration"
                            })
                      ]
                    }),
                tmp
              ]
            });
}

var make = Spaces__Block__HttpRequestTrigger;

export {
  make ,
}
/* use Not a pure module */
