// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Spaces__Ui__Shared from "../shared/Spaces__Ui__Shared.bs.js";
import * as DecidrErrorBoundary from "../../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Spinner from "../../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__TextButton from "../../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Primitives__Error__Message from "../../../../primitives/src/error/message/Primitives__Error__Message.bs.js";
import * as Spaces__Ui__PlatformPicker from "../platformPicker/Spaces__Ui__PlatformPicker.bs.js";
import * as Spaces__Ui__CreateViewModal from "../createViewModal/Spaces__Ui__CreateViewModal.bs.js";
import * as Spaces__Ui__CreateSpaceModal from "../createSpaceModal/Spaces__Ui__CreateSpaceModal.bs.js";
import * as Spaces__Ui__CreateTableModal from "../createTableModal/Spaces__Ui__CreateTableModal.bs.js";
import * as Spaces__Ui__SpaceTablePicker from "../spaceTablePicker/Spaces__Ui__SpaceTablePicker.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as Spaces__Ui__PlatformSpacePicker from "../platformSpacePicker/Spaces__Ui__PlatformSpacePicker.bs.js";
import * as SpacesUiViewPickerFlowViewSelector_query_graphql from "../../../../relay-codegen/generated/SpacesUiViewPickerFlowViewSelector_query_graphql.bs.js";
import * as SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql from "../../../../relay-codegen/generated/SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.bs.js";

var convertFragment = SpacesUiViewPickerFlowViewSelector_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesUiViewPickerFlowViewSelector_query_graphql.node, convertFragment, fRef);
}

function Spaces__Ui__ViewPickerFlow$ViewSelector(props) {
  var onSelectView = props.onSelectView;
  var match = use(props.queryRef);
  var node = match.node;
  if (node !== undefined) {
    if (node.__typename === "Table") {
      return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListContainer.make, {
                  children: node.views.map(function (param) {
                        var name = param.name;
                        var id = param.id;
                        return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListItem.make, {
                                    onPress: (function () {
                                        onSelectView(id, name);
                                      }),
                                    title: name
                                  }, DecidrNodeId.toString(id));
                      })
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a Table", "Spaces__Ui__ViewPickerFlow ViewSelector", {
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Ui__ViewPickerFlow ViewSelector", undefined);
  return null;
}

var convertVariables = SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesUiViewPickerFlowConnectedViewSelectorQuery_graphql.node, convertVariables);

function Spaces__Ui__ViewPickerFlow$ConnectedViewSelector(props) {
  var match = Auth__Hooks.useAuth();
  var match$1 = use$1({
        skipQuery: match.authStatus !== "authenticated",
        tableId: props.tableId
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "Spaces__Ui__ViewPickerFlow ViewSelector",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__ViewPickerFlow$ViewSelector, {
                              queryRef: match$1.fragmentRefs,
                              onSelectView: props.onSelectView
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                              size: "small"
                            }))
                  }),
              FallbackComponent: (function (param) {
                  return JsxRuntime.jsx(Primitives__Error__Message.make, {
                              error: "Error loading views"
                            });
                })
            });
}

function Spaces__Ui__ViewPickerFlow$BackButton(props) {
  return JsxRuntime.jsx(Primitives__Button.make, {
              title: "Back",
              onPress: props.onPress,
              kind: "secondary",
              size: "small"
            });
}

function Spaces__Ui__ViewPickerFlow(props) {
  var headingPaddingHorizontal = props.headingPaddingHorizontal;
  var __includeSpaceBackButton = props.includeSpaceBackButton;
  var __initialFlow = props.initialFlow;
  var onSelectView = props.onSelectView;
  var initialFlow = __initialFlow !== undefined ? __initialFlow : "SelectPlatform";
  var includeSpaceBackButton = __includeSpaceBackButton !== undefined ? __includeSpaceBackButton : true;
  var match = React.useState(function () {
        return initialFlow;
      });
  var setFlow = match[1];
  var flow = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setShowModal = match$1[1];
  var showModal = match$1[0];
  var tmp;
  if (typeof flow !== "object") {
    tmp = null;
  } else {
    switch (flow.TAG) {
      case "SelectSpace" :
          tmp = ReactUtils__SimpleReact.whenTrue(includeSpaceBackButton, {
                LAZY_DONE: false,
                VAL: (function () {
                    return JsxRuntime.jsx(Spaces__Ui__ViewPickerFlow$BackButton, {
                                onPress: (function (param) {
                                    setFlow(function (param) {
                                          return "SelectPlatform";
                                        });
                                  })
                              });
                  })
              });
          break;
      case "SelectTable" :
          var platformId = flow.platformId;
          tmp = JsxRuntime.jsx(Spaces__Ui__ViewPickerFlow$BackButton, {
                onPress: (function (param) {
                    setFlow(function (param) {
                          return {
                                  TAG: "SelectSpace",
                                  platformId: platformId
                                };
                        });
                  })
              });
          break;
      case "SelectView" :
          var spaceId = flow.spaceId;
          var platformId$1 = flow.platformId;
          tmp = JsxRuntime.jsx(Spaces__Ui__ViewPickerFlow$BackButton, {
                onPress: (function (param) {
                    setFlow(function (param) {
                          return {
                                  TAG: "SelectTable",
                                  platformId: platformId$1,
                                  spaceId: spaceId
                                };
                        });
                  })
              });
          break;
      
    }
  }
  var tmp$1;
  if (typeof flow !== "object") {
    tmp$1 = "Select platform";
  } else {
    switch (flow.TAG) {
      case "SelectSpace" :
          tmp$1 = "Select space";
          break;
      case "SelectTable" :
          tmp$1 = "Select table";
          break;
      case "SelectView" :
          tmp$1 = "Select view";
          break;
      
    }
  }
  var tmp$2;
  if (typeof flow !== "object") {
    tmp$2 = null;
  } else {
    switch (flow.TAG) {
      case "SelectSpace" :
          var platformId$2 = flow.platformId;
          tmp$2 = JsxRuntime.jsx(Spaces__Ui__CreateSpaceModal.make, {
                show: showModal,
                onClose: (function () {
                    setShowModal(function (param) {
                          return false;
                        });
                  }),
                platformId: platformId$2,
                onCompleted: (function (spaceId, param) {
                    setShowModal(function (param) {
                          return false;
                        });
                    setFlow(function (param) {
                          return {
                                  TAG: "SelectTable",
                                  platformId: platformId$2,
                                  spaceId: spaceId
                                };
                        });
                  })
              });
          break;
      case "SelectTable" :
          var spaceId$1 = flow.spaceId;
          var platformId$3 = flow.platformId;
          tmp$2 = JsxRuntime.jsx(Spaces__Ui__CreateTableModal.make, {
                show: showModal,
                onClose: (function () {
                    setShowModal(function (param) {
                          return false;
                        });
                  }),
                onCompleted: (function (param, tableId) {
                    setShowModal(function (param) {
                          return false;
                        });
                    setFlow(function (param) {
                          return {
                                  TAG: "SelectView",
                                  platformId: platformId$3,
                                  spaceId: spaceId$1,
                                  tableId: tableId
                                };
                        });
                  }),
                spaceId: spaceId$1
              });
          break;
      case "SelectView" :
          tmp$2 = JsxRuntime.jsx(Spaces__Ui__CreateViewModal.make, {
                show: showModal,
                onClose: (function () {
                    setShowModal(function (param) {
                          return false;
                        });
                  }),
                tableId: flow.tableId,
                onCompleted: (function (viewId, viewName) {
                    setShowModal(function (param) {
                          return false;
                        });
                    onSelectView(viewId, viewName);
                  })
              });
          break;
      
    }
  }
  var tmp$3;
  if (typeof flow !== "object") {
    tmp$3 = JsxRuntime.jsx(Spaces__Ui__PlatformPicker.make, {
          onSelectPlatform: (function (platformId) {
              setFlow(function (param) {
                    return {
                            TAG: "SelectSpace",
                            platformId: platformId
                          };
                  });
            })
        });
  } else {
    switch (flow.TAG) {
      case "SelectSpace" :
          var platformId$4 = flow.platformId;
          tmp$3 = JsxRuntime.jsx(Spaces__Ui__PlatformSpacePicker.make, {
                platformId: platformId$4,
                onSelectSpace: (function (spaceId, param) {
                    setFlow(function (param) {
                          return {
                                  TAG: "SelectTable",
                                  platformId: platformId$4,
                                  spaceId: spaceId
                                };
                        });
                  })
              });
          break;
      case "SelectTable" :
          var spaceId$2 = flow.spaceId;
          var platformId$5 = flow.platformId;
          tmp$3 = JsxRuntime.jsx(Spaces__Ui__SpaceTablePicker.make, {
                spaceId: spaceId$2,
                onSelectTable: (function (tableId) {
                    setFlow(function (param) {
                          return {
                                  TAG: "SelectView",
                                  platformId: platformId$5,
                                  spaceId: spaceId$2,
                                  tableId: tableId
                                };
                        });
                  })
              });
          break;
      case "SelectView" :
          tmp$3 = JsxRuntime.jsx(Spaces__Ui__ViewPickerFlow$ConnectedViewSelector, {
                tableId: flow.tableId,
                onSelectView: onSelectView
              });
          break;
      
    }
  }
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      orientation: "horizontal",
                      style: {
                        justifyContent: "space-between",
                        paddingLeft: headingPaddingHorizontal,
                        paddingRight: headingPaddingHorizontal
                      },
                      children: [
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                              orientation: "horizontal",
                              style: {
                                alignItems: "center"
                              },
                              children: [
                                tmp,
                                JsxRuntime.jsx(Primitives__DecidrText.make, {
                                      style: {
                                        fontWeight: 500
                                      },
                                      children: Caml_option.some(tmp$1)
                                    })
                              ]
                            }),
                        tmp$2,
                        ReactUtils__SimpleReact.whenTrue(flow !== "SelectPlatform", {
                              LAZY_DONE: false,
                              VAL: (function () {
                                  var tmp;
                                  if (typeof flow !== "object") {
                                    tmp = "";
                                  } else {
                                    switch (flow.TAG) {
                                      case "SelectSpace" :
                                          tmp = "+ Create space";
                                          break;
                                      case "SelectTable" :
                                          tmp = "+ Create table";
                                          break;
                                      case "SelectView" :
                                          tmp = "+ Create view";
                                          break;
                                      
                                    }
                                  }
                                  return JsxRuntime.jsx(Primitives__TextButton.make, {
                                              title: tmp,
                                              onPress: (function (param) {
                                                  setShowModal(function (param) {
                                                        return true;
                                                      });
                                                }),
                                              kind: "primary"
                                            });
                                })
                            })
                      ]
                    }),
                tmp$3
              ]
            });
}

var make = Spaces__Ui__ViewPickerFlow;

export {
  make ,
}
/* use Not a pure module */
