// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Spinner from "../spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";

function Primitives__Suspense(props) {
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(props.children),
              fallback: Caml_option.some(Core__Option.getOr(props.fallback, JsxRuntime.jsx(Primitives__Group.make, {
                            style: {
                              padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                            },
                            children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                                      size: "small"
                                    }))
                          })))
            });
}

var make = Primitives__Suspense;

export {
  make ,
}
/* react Not a pure module */
