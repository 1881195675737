/* eslint-disable @typescript-eslint/no-unnecessary-condition */

import { useEffect, useState } from "react";
import {
  Message,
  MessageObject,
  ResponseObject,
  useMessages,
  useScrollToBottom,
} from "../utils/hooks";
import { columnView as ColumnView } from "@decidr/renderer/src/blocks/DecidrRenderer__Blocks__Utils.gen";
import { parsePartialJson } from "../utils/parse-partial-json";

type Page = {
  sideContentSource: string;
  sideContentType?: "image" | "iframe";
  columnView: ColumnView;
};

// a function to find the page closes to the given index which has sideContentSource
const findClosestColumnView = (
  pages: Page[],
  columnViews: ColumnView[],
  index: number
): Page | undefined => {
  const columnView = columnViews[index];
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (columnView !== undefined) {
    const page = pages.find(page => page.columnView.id === columnView.id);
    if (page !== undefined && page.sideContentSource) {
      return page;
    }
    if (index > 0) {
      return findClosestColumnView(pages, columnViews, index - 1);
    }
    return undefined;
  }
  return undefined;
};

const getMessagesForColumnView = (
  messages: Message[],
  columnViewIndexNumber: number,
  submitFlow: "chat" | "thread" | "question"
) => {
  const columnViewIndex = columnViewIndexNumber.toString();

  const columnViewRelatedMessages = [];
  let withinRange = false;

  for (const [messageIndex, message] of messages.entries()) {
    const currentObject = parsePartialJson(message.raw);
    let messageColumnViewIndex =
      submitFlow === "chat" || submitFlow === "question"
        ? (currentObject as MessageObject)?.column
        : (currentObject as ResponseObject)?.next?.column;

    if (messageColumnViewIndex === undefined) {
      const previousMessageRaw = messages[messageIndex - 1]?.raw;
      if (previousMessageRaw !== undefined && previousMessageRaw !== "") {
        const previousMessageObject = parsePartialJson(previousMessageRaw);
        messageColumnViewIndex =
          submitFlow === "chat" || submitFlow === "question"
            ? (previousMessageObject as MessageObject)?.column
            : (previousMessageObject as ResponseObject)?.next?.column;
      }
    }

    if (messageColumnViewIndex === columnViewIndex) {
      withinRange = true;
    } else if (withinRange && messageColumnViewIndex !== undefined) {
      break;
    }

    if (withinRange) {
      columnViewRelatedMessages.push(message);
    }
  }

  return columnViewRelatedMessages;
};

type ChatProps = {
  showSideContent: boolean;
  prompt: string;
  starterMessage: string;
  columnViews: ColumnView[];
  AssistantMessageComponent: ({ content }: { content: string }) => JSX.Element;
  UserMessageComponent: ({ content }: { content: string }) => JSX.Element;
  assistantId: string;
  sideContentPosition: "left" | "right";
  viewId: string;
  organizationId: string;
  submitFlow: "chat" | "thread" | "question" | undefined;
  pages: Page[];
};

export const ChatPerPage = ({
  showSideContent,
  prompt,
  starterMessage,
  columnViews,
  AssistantMessageComponent,
  UserMessageComponent,
  assistantId,
  sideContentPosition,
  viewId,
  organizationId,
  submitFlow = "chat",
  pages,
}: ChatProps) => {
  const { ref: chatContainerRef, scrollToBottom } = useScrollToBottom();
  const [input, setInput] = useState("");
  const { state, handleSubmit } = useMessages({
    starterMessage,
    columnViews,
    scrollToBottom,
    prompt,
    resetInput: () => setInput(""),
    assistantId,
    viewId,
    organizationId,
    submitFlow,
  });

  const [columnViewIndex, setColumnViewIndex] = useState<number>(0);

  useEffect(() => {
    if (state.columnViewId !== undefined && state.columnViewId !== "") {
      setColumnViewIndex(Number(state.columnViewId));
    }
  }, [state.columnViewId]);

  const pageWithSideContent = findClosestColumnView(
    pages,
    columnViews,
    columnViewIndex
  );

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action to avoid newline
      handleSubmit(input); // Submit form
    }
  };

  const handleFormSubmission = (
    e: React.FormEvent<HTMLFormElement>,
    input: string
  ) => {
    e.preventDefault();
    handleSubmit(input);
  };

  const messages = getMessagesForColumnView(
    state.messages,
    columnViewIndex,
    submitFlow
  );

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        maxHeight: "100vh",
        flex: pageWithSideContent
          ? pageWithSideContent.sideContentType === "iframe"
            ? 1
            : undefined
          : undefined,
        flexDirection: sideContentPosition === "right" ? "row" : "row-reverse",
      }}
    >
      <div style={{ flex: 1, display: "flex" }}>
        <div
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            ref={chatContainerRef}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              maxHeight: "80vh",
              padding: "16px 0 120px 0",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "640px",
                margin: "0 auto",
                gap: "12px",
                padding: "0px 24px",
              }}
            >
              {messages.map(({ role, content }, index) => {
                if (state.state === "WaitingResponse" && content === "") {
                  return null;
                }
                if (role === "assistant") {
                  return (
                    <AssistantMessageComponent
                      key={content + index}
                      content={content}
                    />
                  );
                }
                return (
                  <UserMessageComponent
                    key={content + index}
                    content={content}
                  />
                );
              })}
            </div>
          </div>
          <form onSubmit={e => handleFormSubmission(e, input)}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 24px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  maxWidth: "640px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <textarea
                  style={{
                    border: "1px solid #ededed",
                    borderRadius: "12px",
                    boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    opacity: state.state === "Done" ? 0.5 : 1,
                    cursor: state.state === "Done" ? "not-allowed" : "default",
                  }}
                  disabled={state.state === "Done"}
                  value={input}
                  placeholder="Say something..."
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {pageWithSideContent && showSideContent ? (
        <div className="chat-right-side-content" style={{ flex: 1 }}>
          {pageWithSideContent.sideContentType === "image" ? (
            <img
              src={pageWithSideContent.sideContentSource}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <iframe
              src={pageWithSideContent.sideContentSource}
              width="100%"
              height="100%"
              style={{
                borderWidth: 0,
                borderRight:
                  sideContentPosition === "left"
                    ? "1px solid #ededed"
                    : undefined,
                borderLeft:
                  sideContentPosition === "right"
                    ? "1px solid #ededed"
                    : undefined,
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
