// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__InputUtils from "../../../primitives/src/input/Primitives__InputUtils.bs.js";
import * as Renderer__SettingsJSON from "@pageblocks/renderer/src/Renderer__SettingsJSON.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as DecidrRenderer__Actions__Row from "../actions/DecidrRenderer__Actions__Row.bs.js";
import * as DecidrRenderer__Blocks__Utils from "./DecidrRenderer__Blocks__Utils.bs.js";
import * as DecidrRenderer__Blocks__SurveyAndChatUtils from "./utils/DecidrRenderer__Blocks__SurveyAndChatUtils.bs.js";

var blockCode = "survey";

var viewId = "viewId";

var organizationId = "organizationId";

var questionGroups = "questionGroups";

var columnView = "columnView";

var foregroundColor = "foregroundColor";

var submittedTitle = "submittedTitle";

var submittedSubtitle = "submittedSubtitle";

var flow = "flow";

function DecidrRenderer__Blocks__Survey$SubmittedNotice(props) {
  var foregroundColor = props.foregroundColor;
  var subtitle = props.subtitle;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        fontWeight: 500,
                        textAlign: "center"
                      },
                      kind: "title3",
                      children: Caml_option.some(props.title),
                      color: foregroundColor
                    }),
                subtitle !== undefined ? JsxRuntime.jsx(Primitives__DecidrText.make, {
                        style: {
                          textAlign: "center"
                        },
                        children: Caml_option.some(subtitle),
                        color: foregroundColor
                      }) : null
              ]
            });
}

function DecidrRenderer__Blocks__Survey$RequiredIndicator(props) {
  var required = props.required;
  if (required !== undefined && required) {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                children: " *",
                color: "red"
              });
  } else {
    return null;
  }
}

function DecidrRenderer__Blocks__Survey$SkipButton(props) {
  if (props.hide) {
    return null;
  }
  var onPress = props.onPress;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__DecidrText.make, {
                    style: {
                      fontWeight: 500
                    },
                    children: "Skip",
                    color: props.foregroundColor
                  }),
              style: {
                alignSelf: "center"
              },
              onPress: (function (_e) {
                  onPress();
                })
            });
}

function DecidrRenderer__Blocks__Survey$Pager(props) {
  var questionGroups = props.questionGroups;
  var buttonStyle = props.buttonStyle;
  var organizationId = props.organizationId;
  var viewId = props.viewId;
  var foregroundColor = props.foregroundColor;
  var toast = Primitives__DecidrToast.use();
  var match = React.useState(function () {
        return {
                currentQuestionGroupIndex: 0,
                answerGroups: questionGroups.map(function (param) {
                      return {
                              answers: param.questions.map(function (question) {
                                    return {
                                            question: question,
                                            fieldRef: React.createRef(),
                                            answer: ""
                                          };
                                  })
                            };
                    }),
                submitting: false,
                submitted: false
              };
      });
  var setState = match[1];
  var state = match[0];
  var handleSubmitAnswers = function (viewId, organizationId, answerGroups, submitDisabled) {
    if (viewId === undefined) {
      return toast.error("Missing required configuration for this action", undefined);
    }
    if (organizationId === undefined) {
      return toast.error("Missing required configuration for this action", undefined);
    }
    if (submitDisabled) {
      return ;
    }
    setState(function (state) {
          return {
                  currentQuestionGroupIndex: state.currentQuestionGroupIndex,
                  answerGroups: state.answerGroups,
                  submitting: true,
                  submitted: state.submitted
                };
        });
    var answerColumnViews = Core__Array.filterMap(answerGroups.map(function (param) {
                return param.answers;
              }).flat(), (function (param) {
            var answer = param.answer;
            var question = param.question;
            var match = question.choices;
            var answer$1 = match !== undefined && match.multiple ? answer.split(DecidrRenderer__Blocks__SurveyAndChatUtils.ChoicesInput.answerSeparator).join(",") : answer;
            return Core__Option.map(question.columnView, (function (columnView) {
                          return [
                                  answer$1,
                                  columnView
                                ];
                        }));
          }));
    DecidrRenderer__Actions__Row.createRow({
          cells: answerColumnViews.map(function (param) {
                var columnView = param[1];
                return {
                        columnId: columnView.columnId,
                        columnViewId: columnView.id,
                        value: param[0],
                        componentTypeCode: columnView.componentTypeCode
                      };
              }),
          viewId: viewId
        }, organizationId, (function () {
            setState(function (prev) {
                  return {
                          currentQuestionGroupIndex: 0,
                          answerGroups: prev.answerGroups.map(function (param) {
                                return {
                                        answers: param.answers.map(function (answer) {
                                              return {
                                                      question: answer.question,
                                                      fieldRef: answer.fieldRef,
                                                      answer: ""
                                                    };
                                            })
                                      };
                              }),
                          submitting: false,
                          submitted: true
                        };
                });
          }), (function () {
            setState(function (state) {
                  return {
                          currentQuestionGroupIndex: state.currentQuestionGroupIndex,
                          answerGroups: state.answerGroups,
                          submitting: false,
                          submitted: state.submitted
                        };
                });
          }), (function () {
            setState(function (state) {
                  return {
                          currentQuestionGroupIndex: state.currentQuestionGroupIndex,
                          answerGroups: state.answerGroups,
                          submitting: false,
                          submitted: state.submitted
                        };
                });
          }));
  };
  var handleNext = function () {
    setState(function (state) {
          return {
                  currentQuestionGroupIndex: state.currentQuestionGroupIndex + 1 | 0,
                  answerGroups: state.answerGroups,
                  submitting: state.submitting,
                  submitted: state.submitted
                };
        });
  };
  if (state.submitted) {
    return JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$SubmittedNotice, {
                title: props.submittedTitle,
                subtitle: props.submittedSubtitle,
                foregroundColor: foregroundColor
              });
  }
  var answeringQuestionGroup = state.answerGroups[state.currentQuestionGroupIndex];
  if (answeringQuestionGroup === undefined) {
    return null;
  }
  var nextOrSubmitDisabled = answeringQuestionGroup.answers.some(function (param) {
        if (Core__Option.getOr(param.question.required, false)) {
          return param.answer.trim() === "";
        } else {
          return false;
        }
      });
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
              children: [
                answeringQuestionGroup.answers.map(function (answeringQuestion, answerIndex) {
                      return JsxRuntime.jsxs(Primitives__Group.make, {
                                  gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                  children: [
                                    JsxRuntime.jsxs(Primitives__DecidrText.make, {
                                          style: {
                                            fontWeight: 500
                                          },
                                          children: [
                                            answeringQuestion.question.title,
                                            JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$RequiredIndicator, {
                                                  required: answeringQuestion.question.required
                                                })
                                          ],
                                          color: foregroundColor
                                        }),
                                    JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils.MultipleChoicesNotice.make, {
                                          choices: answeringQuestion.question.choices,
                                          foregroundColor: foregroundColor
                                        }),
                                    JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils.AnswerInput.make, {
                                          choices: answeringQuestion.question.choices,
                                          value: answeringQuestion.answer,
                                          placeholder: Core__Option.getOr(answeringQuestion.question.placeholder, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultPlaceholder),
                                          autoFocus: answerIndex === 0 && state.currentQuestionGroupIndex !== 0,
                                          onChange: (function (value) {
                                              var currentQuestionGroupIndex = state.currentQuestionGroupIndex;
                                              setState(function (state) {
                                                    return {
                                                            currentQuestionGroupIndex: state.currentQuestionGroupIndex,
                                                            answerGroups: state.answerGroups.map(function (answerGroup, index) {
                                                                  if (index === currentQuestionGroupIndex) {
                                                                    return {
                                                                            answers: answerGroup.answers.map(function (answer, answerGroupAnswerIndex) {
                                                                                  if (answerIndex === answerGroupAnswerIndex) {
                                                                                    return {
                                                                                            question: answer.question,
                                                                                            fieldRef: answer.fieldRef,
                                                                                            answer: value
                                                                                          };
                                                                                  } else {
                                                                                    return answer;
                                                                                  }
                                                                                })
                                                                          };
                                                                  } else {
                                                                    return answerGroup;
                                                                  }
                                                                }),
                                                            submitting: state.submitting,
                                                            submitted: state.submitted
                                                          };
                                                  });
                                            }),
                                          onSubmitEditing: (function () {
                                              var currentQuestionGroupIndex = state.currentQuestionGroupIndex;
                                              var answerGroups = state.answerGroups;
                                              var submitting = state.submitting;
                                              if ((currentQuestionGroupIndex + 1 | 0) >= questionGroups.length) {
                                                if (submitting) {
                                                  return ;
                                                } else {
                                                  return handleSubmitAnswers(viewId, organizationId, answerGroups, nextOrSubmitDisabled);
                                                }
                                              }
                                              if ((answerIndex + 1 | 0) >= answeringQuestionGroup.answers.length) {
                                                if (!nextOrSubmitDisabled) {
                                                  return handleNext();
                                                } else {
                                                  return ;
                                                }
                                              }
                                              var nextAnswer = answeringQuestionGroup.answers[answerIndex + 1 | 0];
                                              Core__Option.forEach(nextAnswer, (function (nextAnswer) {
                                                      Primitives__InputUtils.focusField(nextAnswer.fieldRef);
                                                    }));
                                            }),
                                          ref: Primitives__InputUtils.ignoreRefType(answeringQuestion.fieldRef)
                                        })
                                  ]
                                }, answeringQuestion.question.title + "-" + answerIndex.toString());
                    }),
                (state.currentQuestionGroupIndex + 1 | 0) < questionGroups.length ? JsxRuntime.jsxs(Primitives__Group.make, {
                        gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                        children: [
                          JsxRuntime.jsx(Primitives__Button.make, {
                                title: "Next",
                                style: buttonStyle,
                                onPress: (function (_e) {
                                    handleNext();
                                  }),
                                disabled: nextOrSubmitDisabled
                              }),
                          JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$SkipButton, {
                                hide: answeringQuestionGroup.answers.some(function (param) {
                                      return Core__Option.getOr(param.question.required, false);
                                    }),
                                onPress: (function (_e) {
                                    handleNext();
                                  }),
                                foregroundColor: foregroundColor
                              })
                        ]
                      }) : JsxRuntime.jsx(Primitives__Button.make, {
                        title: "Submit",
                        style: buttonStyle,
                        onPress: (function (_e) {
                            handleSubmitAnswers(viewId, organizationId, state.answerGroups, nextOrSubmitDisabled);
                          }),
                        disabled: nextOrSubmitDisabled,
                        busy: state.submitting
                      })
              ]
            });
}

function DecidrRenderer__Blocks__Survey$Form(props) {
  var questionGroups = props.questionGroups;
  var organizationId = props.organizationId;
  var viewId = props.viewId;
  var foregroundColor = props.foregroundColor;
  var toast = Primitives__DecidrToast.use();
  var match = React.useState(function () {
        return {
                answers: questionGroups.map(function (param) {
                        return param.questions.map(function (question) {
                                    return {
                                            question: question,
                                            fieldRef: React.createRef(),
                                            answer: ""
                                          };
                                  });
                      }).flat(),
                submitting: false,
                submitted: false
              };
      });
  var setState = match[1];
  var state = match[0];
  var handleSubmitAnswers = function (viewId, organizationId, answers) {
    if (viewId === undefined) {
      return toast.error("Missing required configuration for this action", undefined);
    }
    if (organizationId === undefined) {
      return toast.error("Missing required configuration for this action", undefined);
    }
    if (answers.some(function (param) {
            if (Core__Option.getOr(param.question.required, false)) {
              return param.answer.trim() === "";
            } else {
              return false;
            }
          })) {
      return toast.error("Missing Required Fields", "Please fill out all required fields");
    }
    setState(function (state) {
          return {
                  answers: state.answers,
                  submitting: true,
                  submitted: state.submitted
                };
        });
    var answerColumnViews = Core__Array.filterMap(answers, (function (param) {
            var answer = param.answer;
            var question = param.question;
            var match = question.choices;
            var answer$1 = match !== undefined && match.multiple ? answer.split(DecidrRenderer__Blocks__SurveyAndChatUtils.ChoicesInput.answerSeparator).join(",") : answer;
            return Core__Option.map(question.columnView, (function (columnView) {
                          return [
                                  answer$1,
                                  columnView
                                ];
                        }));
          }));
    DecidrRenderer__Actions__Row.createRow({
          cells: answerColumnViews.map(function (param) {
                var columnView = param[1];
                return {
                        columnId: columnView.columnId,
                        columnViewId: columnView.id,
                        value: param[0],
                        componentTypeCode: columnView.componentTypeCode
                      };
              }),
          viewId: viewId
        }, organizationId, (function () {
            setState(function (prev) {
                  return {
                          answers: prev.answers.map(function (answer) {
                                return {
                                        question: answer.question,
                                        fieldRef: answer.fieldRef,
                                        answer: ""
                                      };
                              }),
                          submitting: false,
                          submitted: true
                        };
                });
          }), (function () {
            setState(function (state) {
                  return {
                          answers: state.answers,
                          submitting: false,
                          submitted: state.submitted
                        };
                });
          }), (function () {
            setState(function (state) {
                  return {
                          answers: state.answers,
                          submitting: false,
                          submitted: state.submitted
                        };
                });
          }));
  };
  if (state.submitted) {
    return JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$SubmittedNotice, {
                title: props.submittedTitle,
                subtitle: props.submittedSubtitle,
                foregroundColor: foregroundColor
              });
  } else {
    return JsxRuntime.jsxs(Primitives__Group.make, {
                gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                children: [
                  state.answers.map(function (answer, answerIndex) {
                        return JsxRuntime.jsxs(Primitives__Group.make, {
                                    gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                    children: [
                                      JsxRuntime.jsxs(Primitives__DecidrText.make, {
                                            style: {
                                              fontWeight: 500
                                            },
                                            children: [
                                              answer.question.title,
                                              JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$RequiredIndicator, {
                                                    required: answer.question.required
                                                  })
                                            ],
                                            color: foregroundColor
                                          }),
                                      JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils.MultipleChoicesNotice.make, {
                                            choices: answer.question.choices,
                                            foregroundColor: foregroundColor
                                          }),
                                      JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils.AnswerInput.make, {
                                            choices: answer.question.choices,
                                            value: answer.answer,
                                            placeholder: Core__Option.getOr(answer.question.placeholder, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultPlaceholder),
                                            onChange: (function (value) {
                                                setState(function (state) {
                                                      return {
                                                              answers: state.answers.map(function (answer, index) {
                                                                    if (index === answerIndex) {
                                                                      return {
                                                                              question: answer.question,
                                                                              fieldRef: answer.fieldRef,
                                                                              answer: value
                                                                            };
                                                                    } else {
                                                                      return answer;
                                                                    }
                                                                  }),
                                                              submitting: state.submitting,
                                                              submitted: state.submitted
                                                            };
                                                    });
                                              }),
                                            onSubmitEditing: (function () {
                                                var answers = state.answers;
                                                var submitting = state.submitting;
                                                if ((answerIndex + 1 | 0) >= answers.length) {
                                                  if (submitting) {
                                                    return ;
                                                  } else {
                                                    return handleSubmitAnswers(viewId, organizationId, answers);
                                                  }
                                                }
                                                var nextAnswer = answers[answerIndex + 1 | 0];
                                                Core__Option.forEach(nextAnswer, (function (nextAnswer) {
                                                        Primitives__InputUtils.focusField(nextAnswer.fieldRef);
                                                      }));
                                              }),
                                            ref: Primitives__InputUtils.ignoreRefType(answer.fieldRef)
                                          })
                                    ]
                                  }, answer.question.title + "-" + answerIndex.toString());
                      }),
                  JsxRuntime.jsx(Primitives__Button.make, {
                        title: "Submit",
                        style: props.buttonStyle,
                        onPress: (function (_e) {
                            handleSubmitAnswers(viewId, organizationId, state.answers);
                          }),
                        busy: state.submitting
                      })
                ]
              });
  }
}

var buttonStyle_alignSelf = "center";

var buttonStyle_marginTop = Caml_option.some(Primitives__Styling.space("cs_4"));

var buttonStyle_width = Caml_option.some(Primitives__Styling.dp(200));

var buttonStyle = {
  alignSelf: buttonStyle_alignSelf,
  marginTop: buttonStyle_marginTop,
  width: buttonStyle_width
};

function parseFlow(flow) {
  switch (flow) {
    case "form" :
        return "Form";
    case "pager" :
        return "Pager";
    default:
      return ;
  }
}

function DecidrRenderer__Blocks__Survey$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return DecidrRenderer__Blocks__Utils.getString(settings, context, code);
  };
  var getList = function (code) {
    return DecidrRenderer__Blocks__Utils.getList(settings, context, code);
  };
  var viewId$1 = getString(viewId);
  var organizationId$1 = getString(organizationId);
  var questionGroups$1 = getList(questionGroups);
  var questionGroups$2 = Core__Option.getOr(Core__Option.map(questionGroups$1, (function (__x) {
              return Core__Array.filterMap(__x, (function (__x) {
                            var questions = Core__Option.flatMap((function (__x) {
                                      return __x["questions"];
                                    })(__x), Core__JSON.Decode.array);
                            var questions$1 = Core__Option.getOr(Core__Option.map(Core__Option.map(questions, (function (__x) {
                                            return Core__Array.filterMap(__x, Core__JSON.Decode.object);
                                          })), (function (__x) {
                                        return Core__Array.filterMap(__x, (function (__x) {
                                                      var title = Core__Option.flatMap((function (__x) {
                                                                return __x["title"];
                                                              })(__x), Core__JSON.Decode.string);
                                                      var required = Core__Option.flatMap((function (__x) {
                                                                return __x["required"];
                                                              })(__x), Core__JSON.Decode.bool);
                                                      var placeholder = Core__Option.flatMap((function (__x) {
                                                                return __x["placeholder"];
                                                              })(__x), Core__JSON.Decode.string);
                                                      var columnView$1 = Core__Option.flatMap(DecidrRenderer__Blocks__Utils.getObject(Renderer__Settings.deserialize(Renderer__SettingsJSON.fromJson(__x)), context, columnView), DecidrRenderer__Blocks__Utils.parseColumnView);
                                                      var choices = Core__Option.flatMap(Core__Option.flatMap((function (__x) {
                                                                    return __x[DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.choices];
                                                                  })(__x), Core__JSON.Decode.object), (function (__x) {
                                                              return DecidrRenderer__Blocks__SurveyAndChatUtils.Choices.parse(__x, context);
                                                            }));
                                                      if (title !== undefined) {
                                                        return {
                                                                title: title,
                                                                required: required,
                                                                placeholder: placeholder,
                                                                choices: choices,
                                                                columnView: columnView$1
                                                              };
                                                      }
                                                      
                                                    }));
                                      })), []);
                            if (questions$1.length !== 0) {
                              return {
                                      questions: questions$1
                                    };
                            }
                            
                          }));
            })), []);
  var foregroundColor$1 = getString(foregroundColor);
  var submittedTitle$1 = Core__Option.getOr(getString(submittedTitle), "Thank you for your submission!");
  var submittedSubtitle$1 = getString(submittedSubtitle);
  var flow$1 = Core__Option.getOr(Core__Option.flatMap(getString(flow), parseFlow), "Form");
  if (questionGroups$2.length !== 0 && !questionGroups$2.every(function (param) {
          return param.questions.length === 0;
        })) {
    if (flow$1 === "Pager") {
      return JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$Pager, {
                  foregroundColor: foregroundColor$1,
                  viewId: viewId$1,
                  organizationId: organizationId$1,
                  buttonStyle: buttonStyle,
                  questionGroups: questionGroups$2,
                  submittedTitle: submittedTitle$1,
                  submittedSubtitle: submittedSubtitle$1
                });
    } else {
      return JsxRuntime.jsx(DecidrRenderer__Blocks__Survey$Form, {
                  foregroundColor: foregroundColor$1,
                  viewId: viewId$1,
                  organizationId: organizationId$1,
                  buttonStyle: buttonStyle,
                  questionGroups: questionGroups$2,
                  submittedTitle: submittedTitle$1,
                  submittedSubtitle: submittedSubtitle$1
                });
    }
  } else {
    return null;
  }
}

var Component = {
  make: DecidrRenderer__Blocks__Survey$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [{
    code: "general",
    name: "General",
    settings: [
      {
        code: viewId,
        name: "View ID",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: organizationId,
        name: "Organization ID",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: questionGroups,
        name: "Question Groups",
        dataType: {
          TAG: "List",
          _0: {
            itemSchema: [{
                key: "questions",
                dataType: {
                  TAG: "List",
                  _0: {
                    itemSchema: [
                      {
                        key: "title",
                        dataType: {
                          TAG: "String",
                          _0: {}
                        }
                      },
                      {
                        key: "placeholder",
                        dataType: {
                          TAG: "String",
                          _0: {}
                        }
                      },
                      {
                        key: "required",
                        dataType: {
                          TAG: "Bool",
                          _0: {
                            defaultValue: false
                          }
                        }
                      },
                      {
                        key: DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.choices,
                        dataType: {
                          TAG: "Object",
                          _0: [
                            {
                              key: DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.multiple,
                              dataType: {
                                TAG: "Bool",
                                _0: {
                                  defaultValue: false
                                }
                              }
                            },
                            {
                              key: DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.options,
                              dataType: {
                                TAG: "List",
                                _0: {
                                  itemSchema: [
                                    {
                                      key: "value",
                                      dataType: {
                                        TAG: "String",
                                        _0: {}
                                      }
                                    },
                                    {
                                      key: "rowId",
                                      dataType: {
                                        TAG: "String",
                                        _0: {}
                                      }
                                    }
                                  ],
                                  defaultValues: [],
                                  allowEmpty: true
                                }
                              }
                            }
                          ]
                        }
                      },
                      {
                        key: columnView,
                        dataType: {
                          TAG: "Object",
                          _0: [
                            {
                              key: "id",
                              dataType: {
                                TAG: "String",
                                _0: {}
                              }
                            },
                            {
                              key: "name",
                              dataType: {
                                TAG: "String",
                                _0: {}
                              }
                            },
                            {
                              key: "column_id",
                              dataType: {
                                TAG: "String",
                                _0: {}
                              }
                            },
                            {
                              key: "component_type_code",
                              dataType: {
                                TAG: "String",
                                _0: {}
                              }
                            }
                          ]
                        }
                      }
                    ],
                    defaultValues: [],
                    allowEmpty: true
                  }
                }
              }],
            defaultValues: [],
            allowEmpty: true
          }
        }
      },
      {
        code: flow,
        name: "Flow",
        dataType: {
          TAG: "Enum",
          _0: {
            values: [
              {
                value: "pager",
                display: "Pager"
              },
              {
                value: "form",
                display: "Form"
              }
            ],
            allowEmpty: false
          }
        }
      },
      {
        code: submittedTitle,
        name: "Submitted Title",
        dataType: {
          TAG: "String",
          _0: {
            exampleValue: "Thank you for your submission!"
          }
        }
      },
      {
        code: submittedSubtitle,
        name: "Submitted Subtitle",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: foregroundColor,
        name: "Foreground Color",
        dataType: {
          TAG: "String",
          _0: {
            exampleValue: "#000000"
          }
        }
      }
    ]
  }];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

var minRendererVersion = Renderer__Version.current;

export {
  blockCode ,
  version ,
  minRendererVersion ,
  descriptor ,
}
/* buttonStyle Not a pure module */
