// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesTableMutationsMoveTableMutation_graphql from "../../../relay-codegen/generated/SpacesTableMutationsMoveTableMutation_graphql.bs.js";
import * as SpacesTableMutationsCreateSpaceTableMutation_graphql from "../../../relay-codegen/generated/SpacesTableMutationsCreateSpaceTableMutation_graphql.bs.js";
import * as SpacesTableMutationsDeleteSpaceTableMutation_graphql from "../../../relay-codegen/generated/SpacesTableMutationsDeleteSpaceTableMutation_graphql.bs.js";
import * as SpacesTableMutationsUpdateSpaceTableMutation_graphql from "../../../relay-codegen/generated/SpacesTableMutationsUpdateSpaceTableMutation_graphql.bs.js";

var convertVariables = SpacesTableMutationsCreateSpaceTableMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesTableMutationsCreateSpaceTableMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesTableMutationsCreateSpaceTableMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesTableMutationsCreateSpaceTableMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesTableMutationsCreateSpaceTableMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useCreate() {
  var match = use();
  var addTable = match[0];
  var handleAddTable = function (spaceId, name, onCompleted, onError, cacheUpdater) {
    addTable({
          name: name,
          spaceId: spaceId
        }, undefined, undefined, Core__Option.map(cacheUpdater, (function (cacheUpdater) {
                return function (store, response) {
                  cacheUpdater(store, response.createSpaceTable.table.id);
                };
              })), (function (param, errors) {
            var table = param.createSpaceTable.table;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating table", "Spaces__Table__Mutations useCreate", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted(table.id, table.defaultView.id);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating table", "Spaces__Table__Mutations useCreate", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleAddTable,
          match[1]
        ];
}

var convertVariables$1 = SpacesTableMutationsDeleteSpaceTableMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesTableMutationsDeleteSpaceTableMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesTableMutationsDeleteSpaceTableMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesTableMutationsDeleteSpaceTableMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesTableMutationsDeleteSpaceTableMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useDelete() {
  var match = use$1();
  var deleteTable = match[0];
  var handleDeleteTable = function (tableId, onCompleted, onError, cacheUpdater) {
    deleteTable({
          id: tableId
        }, undefined, undefined, Core__Option.map(cacheUpdater, (function (cacheUpdater) {
                return function (store, response) {
                  cacheUpdater(store, response.deleteSpaceTable.deletedTableId);
                };
              })), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error deleting table", "Spaces__Table__Mutations useDelete", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error deleting table", "Spaces__Table__Mutations useDelete", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleDeleteTable,
          match[1]
        ];
}

var convertVariables$2 = SpacesTableMutationsUpdateSpaceTableMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesTableMutationsUpdateSpaceTableMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesTableMutationsUpdateSpaceTableMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesTableMutationsUpdateSpaceTableMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesTableMutationsUpdateSpaceTableMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function useUpdate() {
  var match = use$2();
  var updateTable = match[0];
  var handleUpdateTable = function (tableId, name, onCompleted, onError) {
    updateTable({
          name: name,
          tableId: tableId
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating table", "Spaces__Table__Mutations useUpdate", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating table", "Spaces__Table__Mutations useUpdate", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleUpdateTable,
          match[1]
        ];
}

var convertVariables$3 = SpacesTableMutationsMoveTableMutation_graphql.Internal.convertVariables;

var convertResponse$3 = SpacesTableMutationsMoveTableMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$3 = SpacesTableMutationsMoveTableMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$3, SpacesTableMutationsMoveTableMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, SpacesTableMutationsMoveTableMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

function useMove() {
  var match = use$3();
  var moveTable = match[0];
  var handleMoveTable = function (tableId, spaceId, onCompleted, onError) {
    moveTable({
          newSpaceId: spaceId,
          tableId: tableId
        }, undefined, undefined, (function (store, response) {
            RelayUtils.Helpers.removeFromArray(store, response.moveTableToAnotherSpace.oldSpaceId, tableId, "tables");
            RelayUtils.Helpers.addToArray(store, spaceId, tableId, "tables");
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error moving table", "Spaces__Table__Mutations useMove", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error moving table", "Spaces__Table__Mutations useMove", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleMoveTable,
          match[1]
        ];
}

export {
  useCreate ,
  useDelete ,
  useUpdate ,
  useMove ,
}
/*  Not a pure module */
