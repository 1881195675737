// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"rowId":{"c":"DecidrNodeId.RelayAppSync"},"columnViewId":{"c":"DecidrNodeId.RelayAppSync"},"columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"updateImageCell_cell":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"updateImageCell_cell":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var wrapRawResponseConverter = {"__root":{"updateImageCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateImageCell_cell_image_size_width":{"b":""},"updateImageCell_cell_image_size_height":{"b":""},"updateImageCell_cell_image_id":{"c":"DecidrNodeId.RelayAppSync"},"updateImageCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"updateImageCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateImageCell_cell_image_size_width":{"b":""},"updateImageCell_cell_image_size_height":{"b":""},"updateImageCell_cell_image_id":{"c":"DecidrNodeId.RelayAppSync"},"updateImageCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnViewId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "imageUrl"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowId"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "columnId",
        "variableName": "columnId"
      },
      {
        "kind": "Variable",
        "name": "columnViewId",
        "variableName": "columnViewId"
      },
      {
        "kind": "Variable",
        "name": "imageUrl",
        "variableName": "imageUrl"
      },
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Size",
    "kind": "LinkedField",
    "name": "size",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesCellsMutations_updateImageCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateImageCellPayload",
        "kind": "LinkedField",
        "name": "updateImageCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageCell",
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "SpacesImageCell_imageCell",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "ImageInfo",
                    "kind": "LinkedField",
                    "name": "image",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "SpacesImageInfo_imageInfo",
                        "selections": (v5/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesCellsMutations_updateImageCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateImageCellPayload",
        "kind": "LinkedField",
        "name": "updateImageCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "ImageCell",
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageInfo",
                "kind": "LinkedField",
                "name": "image",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "6d5991e27e2b9bcff47f5d4c51855811",
    "id": null,
    "metadata": {},
    "name": "SpacesCellsMutations_updateImageCellMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesCellsMutations_updateImageCellMutation(\n  $rowId: ID!\n  $columnId: ID!\n  $columnViewId: ID!\n  $imageUrl: String!\n) {\n  updateImageCell(input: {rowId: $rowId, columnId: $columnId, columnViewId: $columnViewId, imageUrl: $imageUrl}) {\n    cell {\n      ...SpacesImageCell_imageCell\n    }\n  }\n}\n\nfragment SpacesImageCell_imageCell on ImageCell {\n  image {\n    ...SpacesImageInfo_imageInfo\n    id\n  }\n  rowId\n  columnId\n}\n\nfragment SpacesImageInfo_imageInfo on ImageInfo {\n  id\n  url\n  size {\n    width\n    height\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
