// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesSpaceMutationsCreateSpaceMutation_graphql from "../../../relay-codegen/generated/SpacesSpaceMutationsCreateSpaceMutation_graphql.bs.js";
import * as SpacesSpaceMutationsDeleteSpaceMutation_graphql from "../../../relay-codegen/generated/SpacesSpaceMutationsDeleteSpaceMutation_graphql.bs.js";
import * as SpacesSpaceMutationsUpdateSpaceMutation_graphql from "../../../relay-codegen/generated/SpacesSpaceMutationsUpdateSpaceMutation_graphql.bs.js";

var convertVariables = SpacesSpaceMutationsCreateSpaceMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesSpaceMutationsCreateSpaceMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesSpaceMutationsCreateSpaceMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesSpaceMutationsCreateSpaceMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesSpaceMutationsCreateSpaceMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useCreate() {
  var match = use();
  var createSpace = match[0];
  var handleCreateSpace = function (appId, name, onCompleted, onError, cacheUpdater, relayConnectionsToUpdateOpt) {
    var relayConnectionsToUpdate = relayConnectionsToUpdateOpt !== undefined ? relayConnectionsToUpdateOpt : [];
    createSpace({
          appId: appId,
          name: name,
          relayConnectionsToUpdate: relayConnectionsToUpdate
        }, undefined, undefined, (function (store, response) {
            var platformId = DecidrNodeId.toString(DecidrNodeId.make("Platform", DecidrNodeId.getInternalId(appId)));
            Core__Option.forEach(Caml_option.nullable_to_opt(store.get(platformId)), (function (platformRP) {
                    Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(platformRP, "spaces", {
                              first: 200
                            }), (function (spacesConnection) {
                            RelayUtils.Helpers.addUniqueEdgeToConnection(store, spacesConnection, DecidrNodeId.toString(response.createSpace.space.id), "SpaceEdge", "last");
                          }));
                  }));
            Core__Option.forEach(cacheUpdater, (function (cacheUpdater) {
                    cacheUpdater(store, response.createSpace.space.id);
                  }));
          }), (function (param, errors) {
            var space = param.createSpace.space;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating space", "Spaces__Space__Mutations useCreate", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted(space.id, space.name);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating space", "Spaces__Space__Mutations useCreate", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleCreateSpace,
          match[1]
        ];
}

var convertVariables$1 = SpacesSpaceMutationsDeleteSpaceMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesSpaceMutationsDeleteSpaceMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesSpaceMutationsDeleteSpaceMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesSpaceMutationsDeleteSpaceMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesSpaceMutationsDeleteSpaceMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useDelete() {
  var match = use$1();
  var deleteSpace = match[0];
  var handleDeleteSpace = function (platformId, spaceId, onCompleted, onError, relayConnectionsToUpdateOpt) {
    var relayConnectionsToUpdate = relayConnectionsToUpdateOpt !== undefined ? relayConnectionsToUpdateOpt : [];
    deleteSpace({
          id: spaceId,
          relayConnectionsToUpdate: relayConnectionsToUpdate
        }, undefined, undefined, (function (store, response) {
            Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(platformId))), (function (platformRP) {
                    var possibleArguments = [
                      {
                        first: 20
                      },
                      {
                        first: 200
                      }
                    ];
                    possibleArguments.forEach(function ($$arguments) {
                          Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(platformRP, "spaces", Caml_option.some($$arguments)), (function (spacesConnection) {
                                  RelayRuntime.ConnectionHandler.deleteNode(spacesConnection, DecidrNodeId.toString(response.deleteSpace.deletedSpaceId));
                                }));
                        });
                  }));
          }), (function (param, errors) {
            var deletedSpaceId = param.deleteSpace.deletedSpaceId;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error deleting space", "Spaces__Space__Mutations useDelete", (function (message, param) {
                    onError(message);
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(deletedSpaceId);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error deleting space", "Spaces__Space__Mutations useDelete", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleDeleteSpace,
          match[1]
        ];
}

var convertVariables$2 = SpacesSpaceMutationsUpdateSpaceMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesSpaceMutationsUpdateSpaceMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesSpaceMutationsUpdateSpaceMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesSpaceMutationsUpdateSpaceMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesSpaceMutationsUpdateSpaceMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function useUpdate() {
  var match = use$2();
  var updateSpace = match[0];
  var handleUpdateSpace = function (spaceId, name, onSuccess, onError) {
    updateSpace({
          name: name,
          spaceId: spaceId
        }, undefined, undefined, undefined, (function (_result, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating space", "Spaces__Space__Mutations useUpdate", onError, onSuccess, undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating space", "Spaces__Space__Mutations useUpdate", onError);
          }), undefined);
  };
  return [
          handleUpdateSpace,
          match[1]
        ];
}

export {
  useCreate ,
  useDelete ,
  useUpdate ,
}
/*  Not a pure module */
