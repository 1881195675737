// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";

var Trigger = {
  make: Tamagui.Popover.Trigger
};

var Content = {
  make: Tamagui.Popover.Content
};

var Close = {
  make: Tamagui.Popover.Close
};

var Adapt_Contents = {
  make: Tamagui.Popover.Adapt.Contents
};

var Adapt_make = Tamagui.Popover.Adapt;

var Adapt = {
  Contents: Adapt_Contents,
  make: Adapt_make
};

var Sheet_Frame = {
  make: Tamagui.Popover.Sheet.Frame
};

var Sheet_Overlay = {
  make: Tamagui.Popover.Sheet.Overlay
};

var Sheet_make = Tamagui.Popover.Sheet;

var Sheet = {
  Frame: Sheet_Frame,
  Overlay: Sheet_Overlay,
  make: Sheet_make
};

var ScrollView = {
  make: Tamagui.Popover.ScrollView
};

var Arrow = {
  make: Tamagui.Popover.Arrow
};

var make = Tamagui.Popover;

export {
  Trigger ,
  Content ,
  Close ,
  Adapt ,
  Sheet ,
  ScrollView ,
  Arrow ,
  make ,
}
/* Trigger Not a pure module */
