// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Ui__Shared from "../shared/Spaces__Ui__Shared.bs.js";
import * as DecidrErrorBoundary from "../../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Spinner from "../../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Primitives__Error__Message from "../../../../primitives/src/error/message/Primitives__Error__Message.bs.js";
import * as SpacesUiPlatformPickerQuery_graphql from "../../../../relay-codegen/generated/SpacesUiPlatformPickerQuery_graphql.bs.js";
import * as SpacesUiPlatformPickerBase_query_graphql from "../../../../relay-codegen/generated/SpacesUiPlatformPickerBase_query_graphql.bs.js";

var convertFragment = SpacesUiPlatformPickerBase_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesUiPlatformPickerBase_query_graphql.node, convertFragment, fRef);
}

function Spaces__Ui__PlatformPicker$Base(props) {
  var onSelectPlatform = props.onSelectPlatform;
  var match = use(props.queryRef);
  return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListContainer.make, {
              children: match.platforms.edges.map(function (param) {
                    var match = param.node;
                    var id = match.id;
                    return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListItem.make, {
                                onPress: (function () {
                                    onSelectPlatform(id);
                                  }),
                                title: match.name
                              }, DecidrNodeId.toString(id));
                  })
            });
}

var convertVariables = SpacesUiPlatformPickerQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesUiPlatformPickerQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesUiPlatformPickerQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesUiPlatformPickerQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiPlatformPickerQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiPlatformPickerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesUiPlatformPickerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesUiPlatformPickerQuery_graphql.node, convertVariables);

function Spaces__Ui__PlatformPicker(props) {
  var match = Auth__Hooks.useAuth();
  var match$1 = use$1({
        skipQuery: match.authStatus !== "authenticated"
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "Spaces__Ui__PlatformPicker Base",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__PlatformPicker$Base, {
                              queryRef: match$1.fragmentRefs,
                              onSelectPlatform: props.onSelectPlatform
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                              size: "small"
                            }))
                  }),
              FallbackComponent: (function (param) {
                  return JsxRuntime.jsx(Primitives__Error__Message.make, {
                              error: "Error loading platforms"
                            });
                })
            });
}

var make = Spaces__Ui__PlatformPicker;

export {
  make ,
}
/* use Not a pure module */
