import { ReactNode, useState } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  ErrorBoundaryProps as ReactErrorBoundaryProps,
  FallbackProps,
} from "react-error-boundary";

export type FallbackComponent = React.ComponentType<
  FallbackProps & {
    errorMessage: string;
    errorId: string | undefined;
  }
>;

interface ErrorBoundaryProps
  extends Omit<
    ReactErrorBoundaryProps,
    "FallbackComponent" | "fallback" | "fallbackRender"
  > {
  // REVISIT `fallback` and `fallbackRender` are typed incorrectly (or the props union itself)
  // in "react-error-boundary": "4.0.10"
  // and since we don't use them, I omitted them.
  debugContext: string;
  captureExceptionImpl?: ({
    error,
    debugContext,
  }: {
    error: Error;
    debugContext: string;
  }) => string | undefined;
  children?: ReactNode;
  FallbackComponent: FallbackComponent;
}

export const ErrorBoundary = ({
  debugContext,
  FallbackComponent,
  captureExceptionImpl,
  ...otherProps
}: ErrorBoundaryProps) => {
  const [errorMessage, setErrorMessage] = useState("");
  const [errorId, setErrorId] = useState<string>();

  return (
    <ReactErrorBoundary
      onError={(error, _info) => {
        setErrorMessage(error.message);
        if (captureExceptionImpl) {
          const exception = captureExceptionImpl({
            error,
            debugContext: debugContext,
          });
          setErrorId(exception != undefined ? exception : undefined);
        } else {
          console.warn("ErrorBoundary: captureExceptionImpl not provided", {
            error,
            debugContext,
          });
        }
      }}
      FallbackComponent={props => {
        return (
          <FallbackComponent
            {...props}
            errorId={errorId}
            errorMessage={errorMessage}
          />
        );
      }}
      {...otherProps}
    />
  );
};
