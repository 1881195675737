// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils from "./utils";
import * as Caml_option from "rescript/lib/es6/caml_option.js";

function makeSpecificStyles(specificStyles) {
  return {
          $s: specificStyles.s,
          $m: specificStyles.m,
          $l: specificStyles.l,
          $xl: specificStyles.xl
        };
}

function makeResponsiveStyle(prim0, prim1, prim2) {
  return Utils.makeResponsiveStyle(prim0, prim1 !== undefined ? Caml_option.valFromOption(prim1) : undefined);
}

function extractCommonStyles(prim) {
  return Utils.extractCommonStyles(prim);
}

var Utils_emptySpecificStyles = {
  s: {},
  m: {},
  l: {},
  xl: {}
};

var Utils$1 = {
  emptySpecificStyles: Utils_emptySpecificStyles,
  makeSpecificStyles: makeSpecificStyles,
  makeResponsiveStyle: makeResponsiveStyle,
  extractCommonStyles: extractCommonStyles
};

var Media;

var Tokens;

export {
  Utils$1 as Utils,
  Media ,
  Tokens ,
}
/* ./utils Not a pure module */
