// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "AudioCell",
              "CurrencyCell",
              "DateCell",
              "FileCell",
              "ImageCell",
              "LookupCell",
              "MultiSelectCell",
              "NumberCell",
              "PercentCell",
              "SwitchCell",
              "TextCell",
              "VideoCell"
            ]);
}

var fragmentConverter = {"__root":{"VideoCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"VideoCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"VideoCell":{"f":""},"TextCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"TextCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"TextCell":{"f":""},"SwitchCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"SwitchCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"SwitchCell":{"f":""},"PercentCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"PercentCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"PercentCell":{"f":""},"NumberCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"NumberCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"NumberCell":{"f":""},"MultiSelectCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"MultiSelectCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"MultiSelectCell":{"f":""},"LookupCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"LookupCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"LookupCell":{"f":""},"ImageCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"ImageCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"ImageCell":{"f":""},"FileCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"FileCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"FileCell":{"f":""},"DateCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"DateCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"DateCell":{"f":""},"CurrencyCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"CurrencyCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"CurrencyCell":{"f":""},"AudioCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"AudioCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"AudioCell":{"f":""},"":{"u":"fragment"}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment: unwrap_fragment
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "kind": "InlineDataFragment",
  "name": "SpacesCells_cell"
};

var wrap_fragment = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment ,
  wrap_fragment ,
  Internal ,
  Utils ,
  node ,
}
/* DecidrNodeId Not a pure module */
