/* TypeScript file generated from Storage.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as StorageJS from './Storage.bs.js';

export const getString: (_1:string) => (undefined | string) = StorageJS.getString as any;

export const setString: (key:string, value:string) => void = StorageJS.setString as any;

export const getFloat: (_1:string) => (undefined | number) = StorageJS.getFloat as any;

export const setFloat: (key:string, value:number) => void = StorageJS.setFloat as any;

export const getInt: (_1:string) => (undefined | number) = StorageJS.getInt as any;

export const setInt: (key:string, value:number) => void = StorageJS.setInt as any;

export const getBool: (_1:string) => (undefined | boolean) = StorageJS.getBool as any;

export const setBool: (key:string, value:boolean) => void = StorageJS.setBool as any;

export const deleteKey: (_1:string) => void = StorageJS.deleteKey as any;

export const clearAll: () => void = StorageJS.clearAll as any;
