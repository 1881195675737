// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as DateTime from "../../../../date-time/src/DateTime.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__Time from "../../../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as App__DateUtils from "../../../../app/src/utils/date/App__DateUtils.bs.js";
import LodashDebounce from "lodash.debounce";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Toggle from "../../../../primitives/src/toggle/Primitives__Toggle.bs.js";
import * as Spaces__Row__Utils from "../../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__DecidrImage from "../../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as Primitives__DecidrToast from "../../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as RescriptTamagui__Styled from "../../../../rescript-tamagui/src/styled/RescriptTamagui__Styled.bs.js";
import * as Spaces__Cells__Mutations from "../../cells/Spaces__Cells__Mutations.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as Spaces__Block__RowJsonData from "../../block/Spaces__Block__RowJsonData.bs.js";
import * as RescriptTamagui__Icon__Expand from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Expand.bs.js";
import * as RescriptTamagui__Icon__ChevronDown from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__ChevronDown.bs.js";

function Spaces__View__TableLayout__Elements$CondensedInput(props) {
  var match = React.useState(function () {
        return false;
      });
  var setFocused = match[1];
  return JsxRuntime.jsx(ReactNative.TextInput, {
              onBlur: (function (param) {
                  setFocused(function (param) {
                        return false;
                      });
                }),
              onChangeText: props.onChange,
              onFocus: (function (param) {
                  setFocused(function (param) {
                        return true;
                      });
                }),
              value: props.value,
              style: {
                borderColor: match[0] ? Primitives__Styling.colorValue({
                        NAME: "border",
                        VAL: {
                          NAME: "highlight",
                          VAL: "subdued"
                        }
                      }) : Primitives__Styling.colorValue("transparent"),
                borderWidth: 2,
                flex: 1,
                height: Caml_option.some(Primitives__Styling.dp(40 + 4)),
                minWidth: ReactNative.Platform.OS === "web" ? Caml_option.some(Primitives__Styling.dp(0)) : undefined,
                padding: Caml_option.some(Primitives__Styling.dp(4))
              }
            });
}

function Spaces__View__TableLayout__Elements$Header(props) {
  var iconUrl = props.iconUrl;
  var iconUrl$1 = React.useMemo((function () {
          return iconUrl;
        }), [iconUrl]);
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                alignItems: "center",
                paddingLeft: Caml_option.some(Primitives__Styling.space("cs_1")),
                paddingRight: Caml_option.some(Primitives__Styling.space("cs_1"))
              },
              children: [
                iconUrl$1 !== undefined ? JsxRuntime.jsx(Primitives__DecidrImage.make, {
                        resizeMode: "contain",
                        src: iconUrl$1,
                        size: {
                          TAG: "Square",
                          _0: Primitives__Styling.dp(14)
                        },
                        alt: "icon"
                      }) : JsxRuntime.jsx(Primitives__Group.make, {
                        style: {
                          backgroundColor: Primitives__Styling.color({
                                NAME: "background",
                                VAL: "default"
                              }),
                          height: Caml_option.some(Primitives__Styling.dp(14)),
                          width: Caml_option.some(Primitives__Styling.dp(14))
                        }
                      }),
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        textAlign: "left",
                        flex: 1
                      },
                      children: Caml_option.some(props.text),
                      numberOfLines: 1
                    }),
                JsxRuntime.jsx(ReactNative.View, {
                      children: Caml_option.some(JsxRuntime.jsx(Primitives__Icon.make, {
                                size: "ic_3",
                                color: Primitives__Styling.color({
                                      NAME: "icon",
                                      VAL: {
                                        NAME: "neutral",
                                        VAL: "subdued"
                                      }
                                    }),
                                icon: RescriptTamagui__Icon__ChevronDown.make
                              }))
                    })
              ]
            });
}

function useTableToast() {
  var toast = Primitives__DecidrToast.use();
  return function (message) {
    toast.show(message, {
          duration: 3000
        });
  };
}

function Spaces__View__TableLayout__Elements$TextCell(props) {
  var columnViewId = props.columnViewId;
  var columnId = props.columnId;
  var rowId = props.rowId;
  var toast = useTableToast();
  var match = Primitives__Input.use(Core__Option.getOr(props.text, ""), undefined, undefined);
  var onChange = match.onChange;
  var match$1 = Spaces__Cells__Mutations.useUpdateCell(rowId, columnId, columnViewId);
  var updateCell = match$1[0];
  var save = React.useCallback(LodashDebounce((function (value) {
              var tmp = value === "" ? null : value;
              updateCell(tmp, (function (message) {
                      toast(message);
                    }), undefined);
            }), Utils__Time.secondInMs), [
        rowId,
        columnId,
        columnViewId
      ]);
  var handleOnChange = function (value) {
    onChange(value);
    save(value);
  };
  return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$CondensedInput, {
              value: match.value,
              onChange: handleOnChange
            });
}

function Spaces__View__TableLayout__Elements$DateCell(props) {
  var date = DateTime.parseIsoStringOpt(props.date);
  if (date !== undefined) {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                style: {
                  textAlign: "center"
                },
                children: Caml_option.some(App__DateUtils.getDateFromDate(Caml_option.valFromOption(date))),
                numberOfLines: 1
              });
  } else {
    return null;
  }
}

function Spaces__View__TableLayout__Elements$NumberCell(props) {
  var columnViewId = props.columnViewId;
  var columnId = props.columnId;
  var rowId = props.rowId;
  var toast = useTableToast();
  var match = Primitives__Input.use(Core__Option.getOr(Core__Option.map(props.number, (function (prim) {
                  return prim.toString();
                })), ""), undefined, undefined);
  var onChange = match.onChange;
  var match$1 = Spaces__Cells__Mutations.useUpdateCell(rowId, columnId, columnViewId);
  var updateCell = match$1[0];
  var save = React.useCallback(LodashDebounce((function (value) {
              updateCell(Core__Option.getOr(Core__Option.map(value, (function (value) {
                              return value;
                            })), null), (function (message) {
                      toast(message);
                    }), undefined);
            }), Utils__Time.secondInMs), [
        rowId,
        columnId,
        columnViewId
      ]);
  var handleOnChange = function (value) {
    var number = parseFloat(value);
    if (isFinite(number) && !isNaN(number)) {
      onChange(value);
      if (value !== "") {
        return save(number);
      } else {
        return save(undefined);
      }
    }
    
  };
  return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$CondensedInput, {
              value: match.value,
              onChange: handleOnChange
            });
}

function Spaces__View__TableLayout__Elements$SwitchCell(props) {
  var value = props.switched;
  var toast = useTableToast();
  var match = React.useState(function () {
        return Core__Option.getOr(value, false);
      });
  var setValue = match[1];
  var match$1 = Spaces__Cells__Mutations.useUpdateCell(props.rowId, props.columnId, props.columnViewId);
  var updateCell = match$1[0];
  var handleOnChange = function (value) {
    setValue(function (param) {
          return value;
        });
    updateCell(value, (function (message) {
            toast(message);
          }), undefined);
  };
  return JsxRuntime.jsx(Primitives__Toggle.make, {
              value: match[0],
              onChange: handleOnChange
            });
}

function Spaces__View__TableLayout__Elements$ImageCell(props) {
  return JsxRuntime.jsx(Primitives__DecidrImage.make, {
              src: props.url,
              size: {
                TAG: "Square",
                _0: Primitives__Styling.dp(24)
              },
              style: {
                borderRadius: Primitives__Styling.radiusDp(2)
              }
            });
}

function Spaces__View__TableLayout__Elements$NotAvailableInTableView(props) {
  return JsxRuntime.jsx(Primitives__DecidrText.make, {
              children: "Expand to see"
            });
}

function Spaces__View__TableLayout__Elements$NotEditableInTableView(props) {
  return JsxRuntime.jsx(Primitives__DecidrText.make, {
              children: "Expand to edit"
            });
}

function Spaces__View__TableLayout__Elements$Cell(props) {
  var componentTypeCode = props.componentTypeCode;
  var viewId = props.viewId;
  var columnViewId = props.columnViewId;
  var columnId = props.columnId;
  var rowId = props.rowId;
  var data = Spaces__Row__Utils.makeBlockData(props.cell, componentTypeCode, props.tableLinkInfo, props.columnLinkInfo, viewId, rowId, columnId);
  if (data.TAG !== "Ok") {
    return null;
  }
  var text = data._0;
  switch (text.TAG) {
    case "Text" :
        var text$1 = text._0;
        if (componentTypeCode === "date") {
          if (text$1 !== undefined) {
            return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$DateCell, {
                        date: text$1
                      });
          } else {
            return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NotEditableInTableView, {});
          }
        } else if (componentTypeCode === "type-row-json-data") {
          return JsxRuntime.jsx(Spaces__Block__RowJsonData.make, {
                      rowId: rowId,
                      viewId: viewId
                    });
        } else if (componentTypeCode === "type-select-view") {
          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NotAvailableInTableView, {});
        } else {
          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$TextCell, {
                      rowId: rowId,
                      columnId: columnId,
                      columnViewId: columnViewId,
                      text: text$1
                    });
        }
    case "ReadOnlyDate" :
        var date = text._0;
        if (date !== undefined) {
          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$DateCell, {
                      date: date
                    });
        } else {
          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NotEditableInTableView, {});
        }
    case "Number" :
        return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NumberCell, {
                    number: text._0,
                    rowId: rowId,
                    columnId: columnId,
                    columnViewId: columnViewId
                  });
    case "Switch" :
        return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$SwitchCell, {
                    switched: text._0,
                    rowId: rowId,
                    columnId: columnId,
                    columnViewId: columnViewId
                  });
    case "Image" :
        var switched = text._0;
        if (switched !== undefined) {
          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$ImageCell, {
                      url: switched.url
                    });
        } else {
          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NotEditableInTableView, {});
        }
    case "MultiSelect" :
        return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NotAvailableInTableView, {});
    default:
      return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$NotAvailableInTableView, {});
  }
}

var Cell = {
  make: Spaces__View__TableLayout__Elements$Cell
};

function Spaces__View__TableLayout__Elements$ExpandButton(props) {
  var onExpand = props.onExpand;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__Icon.make, {
                    size: "ic_2",
                    color: Primitives__Styling.color({
                          NAME: "icon",
                          VAL: {
                            NAME: "neutral",
                            VAL: "subdued"
                          }
                        }),
                    icon: RescriptTamagui__Icon__Expand.make
                  }),
              onPress: (function (param) {
                  onExpand();
                })
            });
}

function Spaces__View__TableLayout__Elements$CellContainer(props) {
  var onExpand = props.onExpand;
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                alignItems: "center",
                justifyContent: "center"
              },
              children: [
                ReactUtils__SimpleReact.whenTrue(props.index === 0, {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements$ExpandButton, {
                                      onExpand: onExpand
                                    });
                        })
                    }),
                JsxRuntime.jsx(ReactNative.View, {
                      style: {
                        flex: 1,
                        height: Primitives__Styling.dp(40 + 4),
                        justifyContent: "center"
                      },
                      children: Caml_option.some(props.children)
                    })
              ]
            });
}

var CellContainer = {
  make: Spaces__View__TableLayout__Elements$CellContainer
};

function Spaces__View__TableLayout__Elements$HeaderContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                justifyContent: "center",
                paddingBottom: Caml_option.some(Primitives__Styling.dp(4)),
                paddingTop: Caml_option.some(Primitives__Styling.dp(4))
              },
              children: Caml_option.some(props.children)
            });
}

var HeaderContainer = {
  make: Spaces__View__TableLayout__Elements$HeaderContainer
};

function make(props) {
  return JsxRuntime.jsx("th", props);
}

var make$1 = RescriptTamagui__Styled.make(make, {
      acceptsClassName: true
    });

function make$2(props) {
  return JsxRuntime.jsx("td", props);
}

var make$3 = RescriptTamagui__Styled.make(make$2, {
      acceptsClassName: true
    });

var make$4 = React.forwardRef(function (props, ref) {
      var newrecord = Caml_obj.obj_dup(props);
      return JsxRuntime.jsx("tr", (newrecord.ref = Core__Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
                          return prim;
                        })), newrecord));
    });

var make$5 = RescriptTamagui__Styled.make(make$4, {
      acceptsClassName: true
    });

function make$6(props) {
  return JsxRuntime.jsx("thead", props);
}

var make$7 = RescriptTamagui__Styled.make(make$6, {
      acceptsClassName: true
    });

function make$8(props) {
  return JsxRuntime.jsx("table", props);
}

var make$9 = RescriptTamagui__Styled.make(make$8, {
      acceptsClassName: true
    });

var make$10 = React.forwardRef(function (props, ref) {
      var newrecord = Caml_obj.obj_dup(props);
      return JsxRuntime.jsx("div", (newrecord.ref = Core__Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
                          return prim;
                        })), newrecord));
    });

var make$11 = RescriptTamagui__Styled.make(make$10, {
      acceptsClassName: true
    });

function make$12(props) {
  return JsxRuntime.jsx("tbody", props);
}

var make$13 = RescriptTamagui__Styled.make(make$12, {
      acceptsClassName: true
    });

var rowHeight = 40;

var rowBordersSize = 4;

var Header = {
  make: Spaces__View__TableLayout__Elements$Header
};

var StyledTh = {
  make: make$1
};

var StyledTd = {
  make: make$3
};

var StyledTr = {
  make: make$5
};

var StyledThead = {
  make: make$7
};

var StyledTable = {
  make: make$9
};

var StyledDiv = {
  make: make$11
};

var StyledTbody = {
  make: make$13
};

export {
  rowHeight ,
  rowBordersSize ,
  Header ,
  Cell ,
  CellContainer ,
  HeaderContainer ,
  StyledTh ,
  StyledTd ,
  StyledTr ,
  StyledThead ,
  StyledTable ,
  StyledDiv ,
  StyledTbody ,
}
/* make Not a pure module */
