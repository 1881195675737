// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";

function Primitives__Grid$ItemContainer(props) {
  var children = props.children;
  var kind = props.kind;
  if (typeof kind !== "object") {
    return children;
  }
  var numColumns = kind.VAL;
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1 / numColumns,
                minWidth: Caml_option.some(Primitives__Styling.dp((props.listWidth - numColumns * props.itemSpacing - 2 * props.sidePadding) / numColumns))
              },
              children: Caml_option.some(children)
            });
}

function Primitives__Grid(props) {
  var padding = props.padding;
  var numColumns = props.numColumns;
  var itemSpacing = props.itemSpacing;
  var listWidth = props.listWidth;
  var keyExtractor = props.keyExtractor;
  var renderItem = props.renderItem;
  var data = props.data;
  var kind = numColumns === 0 || numColumns === 1 ? "list" : ({
        NAME: "grid",
        VAL: numColumns
      });
  var tmp;
  if (typeof kind === "object") {
    var numColumns$1 = kind.VAL;
    var modulo = Caml_int32.mod_(data.length, numColumns$1);
    tmp = modulo === 0 ? null : Core__Array.make(1, numColumns$1 - modulo | 0).map(function (i) {
            return JsxRuntime.jsx(Primitives__Group.make, {}, i.toString());
          });
  } else {
    tmp = null;
  }
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gapDp(itemSpacing)),
              orientation: kind === "list" ? "vertical" : "horizontal",
              style: {
                backgroundColor: Core__Option.getOr(props.backgroundColor, Primitives__Styling.color({
                          NAME: "background",
                          VAL: "default"
                        })),
                flexWrap: kind === "list" ? undefined : "wrap",
                padding: Caml_option.some(Primitives__Styling.dp(padding))
              },
              children: [
                data.map(function (item) {
                      return JsxRuntime.jsx(Primitives__Grid$ItemContainer, {
                                  kind: kind,
                                  children: renderItem(item),
                                  listWidth: listWidth,
                                  itemSpacing: itemSpacing,
                                  sidePadding: padding
                                }, keyExtractor(item));
                    }),
                tmp
              ]
            });
}

var make = Primitives__Grid;

export {
  make ,
}
/* Primitives__Group Not a pure module */
