const appPrefix = "decidr";
export const userIdKey = `${appPrefix}-user-id`;
export const userEmailKey = `${appPrefix}-user-email`;
export const userPhoneKey = `${appPrefix}-user-phone`;
export const anonymousIdKey = `${appPrefix}-anonymous-id`;
export const lastBulkSendTimestampKey = `${appPrefix}-last-bulk-send-timestamp`;
export const networkInfoKey = `${appPrefix}-network-info`;
export const pageViewEventKey = `${appPrefix}-page-view-event`;
export const identifyEventKey = `${appPrefix}-identify-event`;
export const readMessageEventKey = `${appPrefix}-read-message-event`;
