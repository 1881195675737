// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Spaces__Row from "../row/Spaces__Row.bs.js";
import * as Utils__Time from "../../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Utils__Array from "../../../utils/src/array/Utils__Array.bs.js";
import * as ReactNative from "react-native";
import LodashDebounce from "lodash.debounce";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Grid from "../../../primitives/src/grid/Primitives__Grid.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Search from "../../../primitives/src/search/Primitives__Search.bs.js";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Spaces__View__Utils from "../view/Spaces__View__Utils.bs.js";
import * as Primitives__IconButton from "../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Spaces__Renderer__Meta from "../renderer/Spaces__Renderer__Meta.bs.js";
import * as Spaces__Row__Mutations from "../row/Spaces__Row__Mutations.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Row__ItemRenderer from "../row/renderer/Spaces__Row__ItemRenderer.bs.js";
import * as Spaces__Block__MultiSelect from "./Spaces__Block__MultiSelect.bs.js";
import * as RescriptTamagui__Icon__Plus from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Plus.bs.js";
import * as Spaces__ComponentType__Code from "../componentType/Spaces__ComponentType__Code.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as RescriptTamagui__Icon__Settings from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Settings.bs.js";
import * as Spaces__View__DefaultItemLayout from "../view/Spaces__View__DefaultItemLayout.bs.js";
import * as Spaces__ItemLayoutRendererContext from "../renderer/Spaces__ItemLayoutRendererContext.bs.js";
import * as SpacesBlockExperimentalRelatedListQuery_graphql from "../../../relay-codegen/generated/SpacesBlockExperimentalRelatedListQuery_graphql.bs.js";
import * as SpacesBlockExperimentalRelatedListData_view_graphql from "../../../relay-codegen/generated/SpacesBlockExperimentalRelatedListData_view_graphql.bs.js";
import * as SpacesBlockExperimentalRelatedListPaginatedQuery_graphql from "../../../relay-codegen/generated/SpacesBlockExperimentalRelatedListPaginatedQuery_graphql.bs.js";

var convertFragment = SpacesBlockExperimentalRelatedListData_view_graphql.Internal.convertFragment;

var makeRefetchVariables = SpacesBlockExperimentalRelatedListPaginatedQuery_graphql.Types.makeRefetchVariables;

var convertRefetchVariables = SpacesBlockExperimentalRelatedListPaginatedQuery_graphql.Internal.convertVariables;

function usePagination(fRef) {
  return RescriptRelay_Fragment.usePaginationFragment(SpacesBlockExperimentalRelatedListData_view_graphql.node, fRef, convertFragment, convertRefetchVariables);
}

function useData(sourceViewRef) {
  var match = usePagination(sourceViewRef);
  var loadNext = match.loadNext;
  var view = match.data;
  var match$1 = Spaces__Row__Utils.LayoutElement.get(view.coreConfig, "itemLayout");
  var storedFieldMapping = match$1.fieldMapping;
  var contextSchema = Core__Option.getOr(match$1.contextSchema, Spaces__View__DefaultItemLayout.contextSchema);
  var node = Core__Option.getOr(match$1.node, Spaces__View__DefaultItemLayout.node);
  var orderedColumnViews = Utils__Array.toSorted(view.columnViews, (function (a, b) {
          return a.order - b.order;
        }));
  var columnInfos = Core__Array.filterMap(orderedColumnViews.filter(function (param) {
            return !param.isHidden;
          }), (function (param) {
          var columnId = param.columnId;
          var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
          return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                        return {
                                columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                componentTypeCode: componentTypeCode
                              };
                      }));
        }));
  var fieldMapping = Object.entries(storedFieldMapping).length === 0 ? Spaces__View__Utils.guessFieldMapping(columnInfos, contextSchema) : storedFieldMapping;
  var validFieldMapping = Spaces__View__Utils.withoutInvalidFieldMapping(fieldMapping, columnInfos, contextSchema);
  var extractData = function (row) {
    return Spaces__Row.ListDataFragment.readInline(row.fragmentRefs);
  };
  var handleLoadNextPage = function (countOpt) {
    var count = countOpt !== undefined ? countOpt : 24;
    loadNext(count, undefined);
  };
  return {
          sourceView: {
            columnViews: orderedColumnViews,
            coreConfig: view.coreConfig,
            data: view.data,
            id: view.id,
            viewType: view.viewType
          },
          hasNext: match.hasNext,
          loadNext: handleLoadNextPage,
          isLoadingNext: match.isLoadingNext,
          extractData: extractData,
          itemLayout: Spaces__View__DefaultItemLayout.RelatedDataItemLayout.make(node),
          validFieldMapping: validFieldMapping,
          refetch: match.refetch
        };
}

function useSearch(refetch) {
  var match = React.useTransition();
  var startTransition = match[1];
  var handleSearch = function (searchTerm, onSearchStarted) {
    var searchTerm$1 = searchTerm.trim();
    Core__Option.forEach(onSearchStarted, (function (onSearchStarted) {
            onSearchStarted();
          }));
    startTransition(function () {
          refetch(makeRefetchVariables(undefined, undefined, undefined, undefined, undefined, Caml_option.some(searchTerm$1)), "store-and-network", undefined);
        });
  };
  return {
          searching: match[0],
          handleSearch: handleSearch
        };
}

function Spaces__Block__ExperimentalRelatedList$Data$SearchBox(props) {
  var search = props.search;
  var match = Primitives__Input.use("", undefined, undefined);
  var onChange = match.onChange;
  var performSearch = React.useCallback(LodashDebounce((function (searchTerm) {
              search(searchTerm);
            }), Utils__Time.secondInMs), [search]);
  var handleOnChange = function (searchTerm) {
    onChange(searchTerm);
    performSearch(searchTerm);
  };
  var handleClear = function () {
    performSearch("");
  };
  return JsxRuntime.jsx(Primitives__Search.make, {
              value: match.value,
              onChange: handleOnChange,
              placeholder: "Search",
              onClear: handleClear,
              searching: props.searching
            });
}

function getOpenNewlyCreatedRow(coreConfig) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[Spaces__Block__MultiSelect.Data.developerSettingsOpenNewlyCreatedRowKey];
                      })), Core__JSON.Decode.bool), Spaces__Block__MultiSelect.Data.openNewlyCreatedRowDefault);
}

function Spaces__Block__ExperimentalRelatedList$Data(props) {
  var callerViewId = props.callerViewId;
  var callerColumnViewId = props.callerColumnViewId;
  var callerRowId = props.callerRowId;
  var handleRowAction = props.handleRowAction;
  var loadNextCount = props.loadNextCount;
  var match = Spaces__ItemLayoutRendererContext.use();
  var onNavigateToRow = match.onNavigateToLinkedRow;
  var match$1 = useData(props.sourceViewRef);
  var validFieldMapping = match$1.validFieldMapping;
  var itemLayout = match$1.itemLayout;
  var extractData = match$1.extractData;
  var isLoadingNext = match$1.isLoadingNext;
  var loadNext = match$1.loadNext;
  var sourceView = match$1.sourceView;
  var match$2 = useSearch(match$1.refetch);
  var handleSearch = match$2.handleSearch;
  var match$3 = React.useState(function () {
        return 0;
      });
  var setListWidth = match$3[1];
  var listWidth = match$3[0];
  var match$4 = Spaces__Row__Mutations.useCreate();
  var createRow = match$4[0];
  var numColumns = React.useMemo((function () {
          if (sourceView.viewType.code === "grid") {
            if (listWidth > 1300) {
              return 3;
            } else if (listWidth > 800) {
              return 2;
            } else {
              return 1;
            }
          } else {
            return 1;
          }
        }), [
        sourceView.viewType.code,
        listWidth
      ]);
  var openNewlyCreatedRow = getOpenNewlyCreatedRow(sourceView.coreConfig);
  return JsxRuntime.jsx(ReactNative.View, {
              onLayout: (function (param) {
                  var nativeEvent = param.nativeEvent;
                  setListWidth(function (param) {
                        return nativeEvent.layout.width;
                      });
                }),
              style: {
                flex: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Primitives__Group.make, {
                        style: {
                          backgroundColor: Primitives__Styling.color({
                                NAME: "background",
                                VAL: "default"
                              }),
                          flex: 1
                        },
                        children: [
                          JsxRuntime.jsxs(Primitives__Group.make, {
                                gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                                orientation: "horizontal",
                                style: {
                                  paddingLeft: Caml_option.some(Primitives__Styling.dp(6)),
                                  paddingRight: Caml_option.some(Primitives__Styling.dp(6)),
                                  paddingTop: Caml_option.some(Primitives__Styling.dp(6))
                                },
                                children: [
                                  JsxRuntime.jsx(Primitives__Group.make, {
                                        style: {
                                          flex: 1
                                        },
                                        children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__ExperimentalRelatedList$Data$SearchBox, {
                                                  searching: match$2.searching,
                                                  search: (function (searchTerm) {
                                                      handleSearch(searchTerm, undefined);
                                                    })
                                                }))
                                      }),
                                  ReactUtils__SimpleReact.renderIfSome(props.onSettingsPress, (function (onSettingsPress) {
                                          return JsxRuntime.jsx(Primitives__IconButton.make, {
                                                      icon: RescriptTamagui__Icon__Settings.make,
                                                      onPress: (function (param) {
                                                          onSettingsPress();
                                                        }),
                                                      size: "small"
                                                    });
                                        })),
                                  JsxRuntime.jsx(Primitives__IconButton.make, {
                                        icon: RescriptTamagui__Icon__Plus.make,
                                        onPress: (function (param) {
                                            createRow(sourceView.id, {
                                                  viewId: callerViewId,
                                                  rowId: callerRowId,
                                                  columnViewId: callerColumnViewId
                                                }, (function (createdRowId, viewId) {
                                                    if (openNewlyCreatedRow) {
                                                      return Core__Option.forEach(onNavigateToRow, (function (onNavigateToRow) {
                                                                    onNavigateToRow(viewId, createdRowId, callerColumnViewId);
                                                                  }));
                                                    }
                                                    
                                                  }), undefined);
                                          }),
                                        size: "small",
                                        busy: match$4[1]
                                      })
                                ]
                              }),
                          JsxRuntime.jsx(Primitives__Grid.make, {
                                data: sourceView.data.edges,
                                renderItem: (function (param) {
                                    var node = param.node;
                                    return JsxRuntime.jsx(Spaces__Row__ItemRenderer.make, {
                                                itemLayout: itemLayout,
                                                rowId: DecidrNodeId.magicallyToInternalId(node.id),
                                                cells: extractData(node).cells,
                                                fieldMapping: validFieldMapping,
                                                columnViews: Core__Array.filterMap(sourceView.columnViews, (function (param) {
                                                        var name = param.name;
                                                        var id = param.id;
                                                        var columnId = param.columnId;
                                                        var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
                                                        return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                                                                      return {
                                                                              id: id,
                                                                              columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                                                              name: name,
                                                                              componentTypeCode: componentTypeCode
                                                                            };
                                                                    }));
                                                      })),
                                                viewId: sourceView.id,
                                                handleRowAction: handleRowAction
                                              });
                                  }),
                                keyExtractor: (function (param) {
                                    return DecidrInternalId.toString(DecidrNodeId.magicallyToInternalId(param.node.id));
                                  }),
                                listWidth: listWidth,
                                itemSpacing: 6,
                                numColumns: numColumns,
                                padding: 6
                              }),
                          ReactUtils__SimpleReact.whenTrue(match$1.hasNext, {
                                LAZY_DONE: false,
                                VAL: (function () {
                                    return JsxRuntime.jsx(Primitives__Group.make, {
                                                style: {
                                                  alignItems: "center",
                                                  paddingBottom: Caml_option.some(Primitives__Styling.dp(6))
                                                },
                                                children: Caml_option.some(JsxRuntime.jsx(Primitives__Button.make, {
                                                          title: "Load more",
                                                          onPress: (function (_e) {
                                                              loadNext(loadNextCount);
                                                            }),
                                                          size: "small",
                                                          busy: isLoadingNext
                                                        }))
                                              });
                                  })
                              })
                        ]
                      }))
            });
}

var convertVariables = SpacesBlockExperimentalRelatedListQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockExperimentalRelatedListQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockExperimentalRelatedListQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockExperimentalRelatedListQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockExperimentalRelatedListQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockExperimentalRelatedListQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockExperimentalRelatedListQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockExperimentalRelatedListQuery_graphql.node, convertVariables);

function Spaces__Block__ExperimentalRelatedList(props) {
  var callerViewId = props.callerViewId;
  var callerColumnViewId = props.callerColumnViewId;
  var callerRowId = props.callerRowId;
  var sourceViewId = props.sourceViewId;
  var match = Spaces__ItemLayoutRendererContext.use();
  var handleRowAction = match.handleRowAction;
  var match$1 = use({
        caller: {
          columnViewId: DecidrNodeId.toString(callerColumnViewId),
          rowId: DecidrInternalId.toString(callerRowId),
          viewId: DecidrNodeId.toString(callerViewId)
        },
        parentRowId: DecidrNodeId.magicallyFromInternalId(callerRowId),
        sourceViewId: sourceViewId
      }, undefined, undefined, undefined);
  var node = match$1.node;
  if (node !== undefined) {
    if (node.__typename === "View") {
      return JsxRuntime.jsx(Spaces__Block__ExperimentalRelatedList$Data, {
                  sourceViewRef: node.fragmentRefs,
                  handleRowAction: (function (viewId, rowId, action) {
                      handleRowAction(viewId, rowId, action, Spaces__Renderer__Meta.make([[
                                  Spaces__Renderer__Meta.Key.linkColumnViewId,
                                  DecidrNodeId.toString(callerColumnViewId)
                                ]]));
                    }),
                  callerRowId: callerRowId,
                  callerColumnViewId: callerColumnViewId,
                  callerViewId: callerViewId,
                  onSettingsPress: props.onSettingsPress
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a View", "Spaces__Block__Experimental__RelatedList", {
          sourceViewId: sourceViewId,
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Block__Experimental__RelatedList", {
        sourceViewId: sourceViewId
      });
  return null;
}

var make = Spaces__Block__ExperimentalRelatedList;

export {
  make ,
}
/* use Not a pure module */
