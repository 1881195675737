// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"tableId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"updateTable_table_views_id":{"c":"DecidrNodeId.RelayAppSync"},"updateTable_table_views":{"f":""},"updateTable_table_id":{"c":"DecidrNodeId.RelayAppSync"},"updateTable_table_defaultView_id":{"c":"DecidrNodeId.RelayAppSync"},"updateTable_table_defaultView":{"f":""},"updateTable_table":{"f":""}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"updateTable_table_views_id":{"c":"DecidrNodeId.RelayAppSync"},"updateTable_table_views":{"f":""},"updateTable_table_id":{"c":"DecidrNodeId.RelayAppSync"},"updateTable_table_defaultView_id":{"c":"DecidrNodeId.RelayAppSync"},"updateTable_table_defaultView":{"f":""},"updateTable_table":{"f":""}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "name"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tableId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "id",
        "variableName": "tableId"
      },
      {
        "kind": "Variable",
        "name": "name",
        "variableName": "name"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v4 = [
  (v3/*: any*/),
  {
    "args": null,
    "kind": "FragmentSpread",
    "name": "SpacesViewBasicInfo_view"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "name",
  "storageKey": null
},
v6 = [
  (v3/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "concreteType": "Size",
    "kind": "LinkedField",
    "name": "size",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "width",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "height",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
],
v7 = {
  "alias": null,
  "args": null,
  "concreteType": "ImageInfo",
  "kind": "LinkedField",
  "name": "image",
  "plural": false,
  "selections": (v6/*: any*/),
  "storageKey": null
},
v8 = [
  (v3/*: any*/),
  (v5/*: any*/),
  (v7/*: any*/),
  {
    "alias": "viewType",
    "args": null,
    "concreteType": "ViewType",
    "kind": "LinkedField",
    "name": "type",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "ImageInfo",
        "kind": "LinkedField",
        "name": "icon",
        "plural": false,
        "selections": (v6/*: any*/),
        "storageKey": null
      },
      (v3/*: any*/)
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesTableMutationsUpdateSpaceTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateTablePayload",
        "kind": "LinkedField",
        "name": "updateTable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Table",
            "kind": "LinkedField",
            "name": "table",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "View",
                "kind": "LinkedField",
                "name": "defaultView",
                "plural": false,
                "selections": (v4/*: any*/),
                "storageKey": null
              },
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SpacesTableBasicInfo_table"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "View",
                "kind": "LinkedField",
                "name": "views",
                "plural": true,
                "selections": (v4/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesTableMutationsUpdateSpaceTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "UpdateTablePayload",
        "kind": "LinkedField",
        "name": "updateTable",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Table",
            "kind": "LinkedField",
            "name": "table",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "View",
                "kind": "LinkedField",
                "name": "defaultView",
                "plural": false,
                "selections": (v8/*: any*/),
                "storageKey": null
              },
              (v5/*: any*/),
              (v7/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "View",
                "kind": "LinkedField",
                "name": "views",
                "plural": true,
                "selections": (v8/*: any*/),
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "8d8fcd101bc69abb87439d3325ab568f",
    "id": null,
    "metadata": {},
    "name": "SpacesTableMutationsUpdateSpaceTableMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesTableMutationsUpdateSpaceTableMutation(\n  $tableId: ID!\n  $name: String\n) {\n  updateTable(input: {id: $tableId, name: $name}) {\n    table {\n      id\n      defaultView {\n        id\n        ...SpacesViewBasicInfo_view\n      }\n      ...SpacesTableBasicInfo_table\n      views {\n        id\n        ...SpacesViewBasicInfo_view\n      }\n    }\n  }\n}\n\nfragment SpacesImageInfo_imageInfo on ImageInfo {\n  id\n  url\n  size {\n    width\n    height\n  }\n}\n\nfragment SpacesTableBasicInfo_table on Table {\n  id\n  name\n  ...SpacesTableImage_table\n}\n\nfragment SpacesTableImage_table on Table {\n  image {\n    ...SpacesImageInfo_imageInfo\n    id\n  }\n}\n\nfragment SpacesViewBasicInfo_view on View {\n  id\n  name\n  ...SpacesViewImage_view\n}\n\nfragment SpacesViewImage_view on View {\n  image {\n    ...SpacesImageInfo_imageInfo\n    id\n  }\n  viewType: type {\n    icon {\n      ...SpacesImageInfo_imageInfo\n      id\n    }\n    id\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
