// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Blocks__View from "@pageblocks/blocks/src/Blocks__View.bs.js";
import * as Blocks__Image from "@pageblocks/blocks/src/Blocks__Image.bs.js";
import * as Blocks__Index from "@pageblocks/blocks/src/Blocks__Index.bs.js";
import * as Blocks__Input from "@pageblocks/blocks/src/Blocks__Input.bs.js";
import * as Blocks__Scroll from "@pageblocks/blocks/src/Blocks__Scroll.bs.js";
import * as Blocks__Select from "@pageblocks/blocks/src/Blocks__Select.bs.js";
import * as Blocks__Switch from "@pageblocks/blocks/src/Blocks__Switch.bs.js";
import * as DecidrRenderer__IconBlock from "../blocks/DecidrRenderer__IconBlock.bs.js";
import * as DecidrRenderer__TextBlock from "../blocks/DecidrRenderer__TextBlock.bs.js";
import * as DecidrRenderer__Blocks__Chat from "../blocks/DecidrRenderer__Blocks__Chat.bs.js";
import * as DecidrRenderer__Blocks__Text from "../blocks/DecidrRenderer__Blocks__Text.bs.js";
import * as DecidrRenderer__Blocks__Survey from "../blocks/DecidrRenderer__Blocks__Survey.bs.js";
import * as DecidrRenderer__Blocks__Fragment from "../blocks/DecidrRenderer__Blocks__Fragment.bs.js";
import * as DecidrRenderer__Blocks__LinkIcon from "../blocks/DecidrRenderer__Blocks__LinkIcon.bs.js";
import * as DecidrRenderer__Blocks__PageTitle from "../blocks/DecidrRenderer__Blocks__PageTitle.bs.js";
import * as DecidrRenderer__Blocks__ChatPerPage from "../blocks/DecidrRenderer__Blocks__ChatPerPage.bs.js";
import * as DecidrRenderer__Blocks__MetaDescription from "../blocks/DecidrRenderer__Blocks__MetaDescription.bs.js";
import * as DecidrRenderer__Blocks__SurveyWithAIQuestions from "../blocks/DecidrRenderer__Blocks__SurveyWithAIQuestions.bs.js";

function registerBlocks() {
  Blocks__Index.registerBlock(Blocks__View.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__TextBlock.descriptor);
  Blocks__Index.registerBlock(Blocks__Image.descriptor);
  Blocks__Index.registerBlock(Blocks__Input.descriptor);
  Blocks__Index.registerBlock(Blocks__Switch.descriptor);
  Blocks__Index.registerBlock(Blocks__Select.descriptor);
  Blocks__Index.registerBlock(Blocks__Scroll.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__IconBlock.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__LinkIcon.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__MetaDescription.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__PageTitle.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__Survey.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__Text.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__Chat.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__Fragment.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__SurveyWithAIQuestions.descriptor);
  Blocks__Index.registerBlock(DecidrRenderer__Blocks__ChatPerPage.descriptor);
}

export {
  registerBlocks ,
}
/* Blocks__View Not a pure module */
