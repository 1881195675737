// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Tamagui from "tamagui";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as RescriptLodash from "../../../rescript-lodash/src/RescriptLodash.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as Primitives__TextButton from "../button/Primitives__TextButton.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";

var context = React.createContext({
      state: "rest",
      focused: false,
      touched: false
    });

var make = context.Provider;

function make$1(param) {
  var onPress = param.onPress;
  var match = React.useContext(context);
  return JsxRuntime.jsx(Primitives__TextButton.make, {
              title: param.text,
              onPress: (function (param) {
                  onPress();
                }),
              disabled: match.state === "disabled",
              focusable: param.focusable
            });
}

function make$2(param) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: param.style,
              children: Caml_option.some(param.element({
                        size: "ic_4",
                        color: Primitives__Styling.color({
                              NAME: "icon",
                              VAL: {
                                NAME: "neutral",
                                VAL: "default"
                              }
                            })
                      })),
              onPress: param.onPress
            });
}

function make$3(param) {
  var show = param.show;
  var match = React.useContext(context);
  var state = match.state;
  var color = state === "error" ? Primitives__Styling.color({
          NAME: "text",
          VAL: {
            NAME: "error",
            VAL: "default"
          }
        }) : (
      state === "disabled" ? Primitives__Styling.color({
              NAME: "text",
              VAL: {
                NAME: "on_surface",
                VAL: "disabled"
              }
            }) : (
          state === "success" ? Primitives__Styling.color({
                  NAME: "text",
                  VAL: {
                    NAME: "success",
                    VAL: "default"
                  }
                }) : Primitives__Styling.color({
                  NAME: "text",
                  VAL: {
                    NAME: "on_surface",
                    VAL: "subdued"
                  }
                })
        )
    );
  var element = JsxRuntime.jsxs(Primitives__Group.make, {
        gap: Caml_option.some(Primitives__Styling.gap("cs_1")),
        orientation: "horizontal",
        style: {
          alignItems: "center"
        },
        children: [
          ReactUtils__SimpleReact.renderIfSome(param.icon, (function (icon) {
                  return icon({
                              size: "ic_2",
                              color: color
                            });
                })),
          JsxRuntime.jsx(Primitives__DecidrText.make, {
                style: {
                  flex: 1
                },
                kind: "body3",
                children: Caml_option.some(param.text),
                color: color
              })
        ]
      });
  if (show !== undefined && show === "touched" && !match.touched) {
    return null;
  } else {
    return element;
  }
}

function make$4(param) {
  var match = React.useContext(context);
  var newrecord = Caml_obj.obj_dup(Core__Option.getOr(param.style, {}));
  return JsxRuntime.jsxs(Primitives__DecidrText.make, {
              style: (newrecord.flex = 1, newrecord),
              kind: "body2",
              children: [
                param.text,
                ReactUtils__SimpleReact.whenTrue(Core__Option.getOr(param.required, false), {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                      children: " *",
                                      color: Primitives__Styling.color("error_1")
                                    });
                        })
                    })
              ],
              color: match.focused ? Primitives__Styling.color({
                      NAME: "text",
                      VAL: {
                        NAME: "highlight",
                        VAL: "default"
                      }
                    }) : (
                  match.state === "disabled" ? Primitives__Styling.color({
                          NAME: "text",
                          VAL: {
                            NAME: "on_surface",
                            VAL: "disabled"
                          }
                        }) : Primitives__Styling.color({
                          NAME: "text",
                          VAL: {
                            NAME: "on_surface",
                            VAL: "default"
                          }
                        })
                ),
              numberOfLines: 1
            });
}

var responsiveFontSizeStyles = Primitives__DecidrText.makeTextResponsiveStyles("body2");

function use(initialValue, validator, config) {
  var match = React.useState(function () {
        return {
                value: initialValue,
                validation: undefined
              };
      });
  var setState = match[1];
  var match$1 = match[0];
  var value = match$1.value;
  var onChange = React.useCallback((function (value) {
          if (!Core__Option.getOr(Core__Option.map(config, (function (param) {
                        return param.validateOnKeystroke;
                      })), true)) {
            return setState(function (prev) {
                        return {
                                value: value,
                                validation: prev.validation
                              };
                      });
          }
          var validation = Core__Option.flatMap(validator, (function (validator) {
                  return validator(value);
                }));
          setState(function (param) {
                return {
                        value: value,
                        validation: validation
                      };
              });
        }), [
        validator,
        setState,
        config
      ]);
  var handleValidate = React.useCallback((function () {
          var validation = Core__Option.flatMap(validator, (function (validator) {
                  return validator(value);
                }));
          setState(function (prev) {
                return {
                        value: prev.value,
                        validation: validation
                      };
              });
        }), [
        validator,
        setState,
        value
      ]);
  return {
          value: value,
          validation: match$1.validation,
          onChange: onChange,
          validate: handleValidate
        };
}

function Primitives__Input$HeaderContainer(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                alignItems: "center",
                justifyContent: "space-between"
              },
              children: Caml_option.some(props.children)
            });
}

var make$5 = React.forwardRef(function (props, ref) {
      var labelStyle = props.labelStyle;
      var __required = props.required;
      var __multiline = props.multiline;
      var inputContainerStyle = props.inputContainerStyle;
      var validation = props.validation;
      var action = props.action;
      var __disabled = props.disabled;
      var label = props.label;
      var onBlur = props.onBlur;
      var onFocus = props.onFocus;
      var value = props.value;
      var disabled = __disabled !== undefined ? __disabled : false;
      var multiline = __multiline !== undefined ? __multiline : false;
      var required = __required !== undefined ? __required : false;
      var match = React.useState(function () {
            return false;
          });
      var setTouched = match[1];
      var touched = match[0];
      var state = React.useMemo((function () {
              if (disabled) {
                return "disabled";
              } else if (validation !== undefined && touched) {
                if (validation === "success") {
                  return "success";
                } else {
                  return "error";
                }
              } else {
                return "rest";
              }
            }), [
            touched,
            disabled,
            validation
          ]);
      var match$1 = React.useState(function () {
            return false;
          });
      var setFocused = match$1[1];
      var focused = match$1[0];
      var defaultStyle_backgroundColor = Primitives__Styling.color({
            NAME: "background",
            VAL: "default"
          });
      var defaultStyle_borderColor = focused ? Primitives__Styling.color({
              NAME: "border",
              VAL: {
                NAME: "highlight",
                VAL: "subdued"
              }
            }) : (
          state === "error" ? Primitives__Styling.color({
                  NAME: "border",
                  VAL: {
                    NAME: "error",
                    VAL: "subdued"
                  }
                }) : (
              state === "success" ? Primitives__Styling.color({
                      NAME: "border",
                      VAL: {
                        NAME: "success",
                        VAL: "default"
                      }
                    }) : Primitives__Styling.color("transparent")
            )
        );
      var defaultStyle_borderRadius = Primitives__Styling.radius("rad_5");
      var defaultStyle_borderWidth = 2;
      var defaultStyle_alignItems = "center";
      var defaultStyle_paddingBottom = Caml_option.some(Primitives__Styling.space("cs_3"));
      var defaultStyle_paddingLeft = Caml_option.some(Primitives__Styling.space("cs_5"));
      var defaultStyle_paddingRight = Caml_option.some(Primitives__Styling.space("cs_5"));
      var defaultStyle_paddingTop = Caml_option.some(Primitives__Styling.space("cs_3"));
      var defaultStyle = {
        backgroundColor: defaultStyle_backgroundColor,
        borderColor: defaultStyle_borderColor,
        borderRadius: defaultStyle_borderRadius,
        borderWidth: defaultStyle_borderWidth,
        alignItems: defaultStyle_alignItems,
        paddingBottom: defaultStyle_paddingBottom,
        paddingLeft: defaultStyle_paddingLeft,
        paddingRight: defaultStyle_paddingRight,
        paddingTop: defaultStyle_paddingTop
      };
      var tmp;
      tmp = state === "disabled" ? Primitives__Styling.color({
              NAME: "text",
              VAL: {
                NAME: "on_background",
                VAL: "disabled"
              }
            }) : (
          value === "" ? Primitives__Styling.color({
                  NAME: "text",
                  VAL: {
                    NAME: "on_background",
                    VAL: "subdued"
                  }
                }) : Core__Option.getOr(props.inputColorOverride, Primitives__Styling.color({
                      NAME: "text",
                      VAL: {
                        NAME: "on_background",
                        VAL: "default"
                      }
                    }))
        );
      var sharedProps_ref = Core__Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
              return prim;
            }));
      var sharedProps_value = value;
      var sharedProps_onChangeText = props.onChange;
      var sharedProps_onFocus = (function (e) {
          Core__Option.forEach(onFocus, (function (onFocus) {
                  onFocus(e);
                }));
          setFocused(function (param) {
                return true;
              });
        });
      var sharedProps_onBlur = (function (e) {
          if (!touched) {
            setTouched(function (param) {
                  return true;
                });
          }
          Core__Option.forEach(onBlur, (function (onBlur) {
                  onBlur(e);
                }));
          setFocused(function (param) {
                return false;
              });
        });
      var sharedProps_onSubmitEditing = props.onSubmitEditing;
      var sharedProps_onKeyPress = props.onKeyPress;
      var sharedProps_placeholder = props.placeholder;
      var sharedProps_blurOnSubmit = props.blurOnSubmit;
      var sharedProps_returnKeyType = props.returnKeyType;
      var sharedProps_disabled = state === "disabled";
      var sharedProps_secureTextEntry = props.secureTextEntry;
      var sharedProps_unstyled = true;
      var sharedProps_$s = responsiveFontSizeStyles.$s;
      var sharedProps_$m = responsiveFontSizeStyles.$m;
      var sharedProps_$l = responsiveFontSizeStyles.$l;
      var sharedProps_$xl = responsiveFontSizeStyles.$xl;
      var sharedProps_col = tmp;
      var sharedProps_f = 1;
      var sharedProps_h = props.inputHeight;
      var sharedProps_fontFamily = "$decidrFont";
      var sharedProps_autoCapitalize = props.autoCapitalize;
      var sharedProps_autoFocus = props.autoFocus;
      var sharedProps_onChange = props.onChangeEvent;
      var sharedProps_onLayout = props.onLayout;
      var sharedProps_selection = props.selection;
      var sharedProps = {
        ref: sharedProps_ref,
        value: sharedProps_value,
        onChangeText: sharedProps_onChangeText,
        onFocus: sharedProps_onFocus,
        onBlur: sharedProps_onBlur,
        onSubmitEditing: sharedProps_onSubmitEditing,
        onKeyPress: sharedProps_onKeyPress,
        placeholder: sharedProps_placeholder,
        blurOnSubmit: sharedProps_blurOnSubmit,
        returnKeyType: sharedProps_returnKeyType,
        disabled: sharedProps_disabled,
        secureTextEntry: sharedProps_secureTextEntry,
        unstyled: sharedProps_unstyled,
        $s: sharedProps_$s,
        $m: sharedProps_$m,
        $l: sharedProps_$l,
        $xl: sharedProps_$xl,
        col: sharedProps_col,
        f: sharedProps_f,
        h: sharedProps_h,
        fontFamily: sharedProps_fontFamily,
        autoCapitalize: sharedProps_autoCapitalize,
        autoFocus: sharedProps_autoFocus,
        onChange: sharedProps_onChange,
        onLayout: sharedProps_onLayout,
        selection: sharedProps_selection
      };
      var tmp$1;
      if (multiline) {
        var newrecord = Caml_obj.obj_dup(sharedProps);
        tmp$1 = JsxRuntime.jsx(Tamagui.TextArea, (newrecord.multiline = true, newrecord));
      } else {
        tmp$1 = JsxRuntime.jsx(Tamagui.Input, sharedProps);
      }
      return JsxRuntime.jsxs(Primitives__Group.make, {
                  gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                  style: props.style,
                  specificStyles: props.specificStyles,
                  children: [
                    JsxRuntime.jsx(make, {
                          value: {
                            state: state,
                            focused: focused,
                            touched: touched
                          },
                          children: label !== undefined ? (
                              action !== undefined ? JsxRuntime.jsxs(Primitives__Input$HeaderContainer, {
                                      children: [
                                        JsxRuntime.jsx(make$4, {
                                              text: label,
                                              required: required,
                                              style: labelStyle
                                            }),
                                        JsxRuntime.jsx(make$1, action)
                                      ]
                                    }) : JsxRuntime.jsx(Primitives__Input$HeaderContainer, {
                                      children: JsxRuntime.jsx(make$4, {
                                            text: label,
                                            required: required,
                                            style: labelStyle
                                          })
                                    })
                            ) : (
                              action !== undefined ? JsxRuntime.jsx(Primitives__Input$HeaderContainer, {
                                      children: JsxRuntime.jsx(make$1, action)
                                    }) : null
                            )
                        }),
                    JsxRuntime.jsxs(Primitives__Group.make, {
                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                          orientation: "horizontal",
                          style: inputContainerStyle !== undefined ? RescriptLodash.merge([
                                  defaultStyle,
                                  inputContainerStyle(state, focused)
                                ]) : defaultStyle,
                          children: [
                            tmp$1,
                            ReactUtils__SimpleReact.renderIfSome(props.right, (function (right) {
                                    return JsxRuntime.jsx(make$2, right);
                                  }))
                          ]
                        }),
                    JsxRuntime.jsx(make, {
                          value: {
                            state: state,
                            focused: focused,
                            touched: touched
                          },
                          children: ReactUtils__SimpleReact.renderIfSome(props.helper, (function (helper) {
                                  return JsxRuntime.jsx(make$3, helper);
                                }))
                        })
                  ]
                });
    });

var KeyEvent;

var InputStatus = {};

var Action = {};

var Right = {};

var Helper = {};

export {
  KeyEvent ,
  InputStatus ,
  Action ,
  Right ,
  Helper ,
  use ,
  make$5 as make,
}
/* context Not a pure module */
