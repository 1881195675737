// Generated by ReScript, PLEASE EDIT WITH CARE


var messagesViewId = "MTtWaWV3OzA3ZjBjMDliLTRjMTEtNDMxOS1hYjFhLWU5YzM2Mzg3MGJiYg==";

var organizationsViewId = "MTtWaWV3OzY5Y2YyODUzLTg2NmUtNDYwMi05ZjQzLTE4MTA4NmUzNDU5Ng==";

var organizationMemberHumansViewId = "MTtWaWV3O2NlMDA3NDM5LTBkNWEtNGMzOC04NzMyLWQ2MjI3NjE4YWRmYw==";

var organizationMemberAIsViewId = "MTtWaWV3OzU0MGExM2VjLTQ4Y2ItNDQzNC1hZGE0LWY3OWI5YjZlNjE3Mw==";

var collectionsViewId = "MTtWaWV3O2QwZTdiYWM4LTUyZDAtNDQ0Ny1hYWQxLWFlNTQ2ZDE1MmVhZA==";

var viewsViewId = "MTtWaWV3OzAyYWY0MzJiLTZmYjUtNGRlOS1hYTIwLTg0NjUyM2I2ZGMxMg==";

var viewsInsightsViewId = "MTtWaWV3OzVhYTdkZmI4LWVlYzYtNGZlYy1hODZjLTRhNGNjZDk2OWUzZQ==";

var columnViewsViewId = "MTtWaWV3OzIwZWRiY2I1LTg4NjEtNDA5NS04NTdhLWVjMTg3OWFjZjc0OQ==";

var systemViewIds = [
  messagesViewId,
  organizationsViewId,
  organizationMemberHumansViewId,
  organizationMemberAIsViewId,
  collectionsViewId,
  viewsViewId,
  columnViewsViewId,
  viewsInsightsViewId
];

export {
  messagesViewId ,
  organizationsViewId ,
  organizationMemberHumansViewId ,
  organizationMemberAIsViewId ,
  collectionsViewId ,
  viewsViewId ,
  viewsInsightsViewId ,
  columnViewsViewId ,
  systemViewIds ,
}
/* No side effect */
