// Generated by ReScript, PLEASE EDIT WITH CARE

import * as $$Storage from "./storage";

function getAnonymousId(prim) {
  return $$Storage.getAnonymousId();
}

function setAnonymousId(prim) {
  $$Storage.setAnonymousId(prim);
}

function getUserProperties(prim) {
  return $$Storage.getUserProperties();
}

function setUserProperties(prim) {
  $$Storage.setUserProperties(prim);
}

function getNetworkInfo(prim) {
  return $$Storage.getNetworkInfo();
}

function setNetworkInfo(prim) {
  $$Storage.setNetworkInfo(prim);
}

function getLastBulkSendTimestamp(prim) {
  return $$Storage.getLastBulkSendTimestamp();
}

function setLastBulkSendTimestamp(prim) {
  $$Storage.setLastBulkSendTimestamp();
}

function getAllEventsToSend(prim) {
  return $$Storage.getAllEventsToSend();
}

function modifyOrAddEventsLocally(prim) {
  $$Storage.modifyOrAddEventsLocally(prim);
}

function removeEvents(prim) {
  $$Storage.removeEvents(prim);
}

export {
  getAnonymousId ,
  setAnonymousId ,
  getUserProperties ,
  setUserProperties ,
  getNetworkInfo ,
  setNetworkInfo ,
  getLastBulkSendTimestamp ,
  setLastBulkSendTimestamp ,
  getAllEventsToSend ,
  modifyOrAddEventsLocally ,
  removeEvents ,
}
/* ./storage Not a pure module */
