// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Uuid from "uuid";
import * as Utils__Time from "../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Analytics__Context from "./context/Analytics__Context.bs.js";
import * as Analytics__Storage from "./storage/Analytics__Storage.bs.js";
import * as RescriptExpo__Network from "../../rescript-expo/src/network/RescriptExpo__Network.bs.js";

var intervalInMs = 10 * Utils__Time.minuteInMs;

async function makeEventsData(eventTypes) {
  var anonymousId = {
    contents: Analytics__Storage.getAnonymousId()
  };
  if (Core__Option.isNone(anonymousId.contents)) {
    var newAnonymousId = Uuid.v4();
    Analytics__Storage.setAnonymousId(newAnonymousId);
    anonymousId.contents = newAnonymousId;
  }
  var networkInfo = Analytics__Storage.getNetworkInfo();
  var networkTypeRef = Core__Option.flatMap(networkInfo, (function (networkInfo) {
          return networkInfo.networkType;
        }));
  var ipAddressRef = {
    contents: Core__Option.flatMap(networkInfo, (function (networkInfo) {
            return networkInfo.ipAddress;
          }))
  };
  var isNetworkInfoStale = Core__Option.getOr(Core__Option.map(networkInfo, (function (networkInfo) {
              return networkInfo.timestamp + intervalInMs < Date.now();
            })), true);
  if (isNetworkInfoStale) {
    try {
      var match = await Promise.all([
            RescriptExpo__Network.getNetworkStateAsync(),
            RescriptExpo__Network.getIpAddressAsync()
          ]);
      var ipAddressResult = match[1];
      var networkInfo$1 = match[0];
      networkTypeRef = networkInfo$1.type;
      ipAddressRef.contents = ipAddressResult;
      Analytics__Storage.setNetworkInfo({
            networkType: networkInfo$1.type,
            ipAddress: ipAddressResult,
            timestamp: Date.now()
          });
    }
    catch (exn){
      
    }
  }
  var userInfo = Analytics__Storage.getUserProperties();
  var networkType = Core__Option.map(networkTypeRef, (function (networkInfoType) {
          return networkInfoType;
        }));
  return eventTypes.map(function (eventType) {
              var tmp;
              switch (eventType.TAG) {
                case "PageViewEvent" :
                    tmp = "Page view";
                    break;
                case "IdentifyEvent" :
                    tmp = "Identify";
                    break;
                case "ReadMessageEvent" :
                    tmp = "Read message";
                    break;
                
              }
              var tmp$1;
              switch (eventType.TAG) {
                case "PageViewEvent" :
                    tmp$1 = {
                      pageView: eventType._0
                    };
                    break;
                case "IdentifyEvent" :
                    tmp$1 = {
                      identity: eventType._0
                    };
                    break;
                case "ReadMessageEvent" :
                    tmp$1 = {
                      readMessage: eventType._0
                    };
                    break;
                
              }
              var tmp$2;
              switch (eventType.TAG) {
                case "PageViewEvent" :
                    tmp$2 = "PageView";
                    break;
                case "IdentifyEvent" :
                    tmp$2 = "Identify";
                    break;
                case "ReadMessageEvent" :
                    tmp$2 = "ReadMessage";
                    break;
                
              }
              return {
                      eventId: Uuid.v4(),
                      anonymousId: Core__Option.getOr(anonymousId.contents, ""),
                      context: Analytics__Context.makeContext({
                            ipAddress: ipAddressRef.contents,
                            networkType: networkType
                          }),
                      name: tmp,
                      properties: tmp$1,
                      timestamp: new Date().toISOString(),
                      type: tmp$2,
                      userId: Core__Option.flatMap(userInfo, (function (user) {
                              return user.id;
                            }))
                    };
            });
}

export {
  makeEventsData ,
}
/* uuid Not a pure module */
