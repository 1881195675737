import { uploadData, getUrl, remove } from "aws-amplify/storage";
import { v4 as uuid } from "uuid";

interface ExtendedBlob extends Blob {
  close?: () => void;
}

export const getBlob = (uri: string) =>
  new Promise<ExtendedBlob>((resolve, reject) => {
    const xhr = new XMLHttpRequest();

    xhr.onload = () => {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
      resolve(xhr.response);
    };

    xhr.onerror = () => {
      reject(new TypeError("Network request failed"));
    };

    xhr.responseType = "blob";

    xhr.open("GET", uri, true);

    xhr.send(null);
  });

// TODO REVISIT Check if getBlob is still needed
// https://github.com/expo/expo/issues/1870

const publishToS3 = (makeRandomId: () => string) => (url: string) =>
  getBlob(url)
    .then(blob =>
      uploadData({
        key: makeRandomId(),
        data: blob,
        options: {
          contentType: blob.type,
        },
      }).result.then(({ key }) => {
        console.log("Storage.uploadData success", key);
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        blob.close?.();
        return getUrl({ key }).then(publicUrl => {
          console.log("Storage.getUrl success", publicUrl);
          return {
            url: publicUrl,
            key,
          };
        });
      })
    )
    .catch(r => {
      console.log("publishToUrl failure", r);
      throw r;
    });

export const deleteFromS3 = (key: string) =>
  remove({ key })
    .then(x => {
      console.log("Deleted from S3", x);
    })
    .catch(r => {
      console.log("Error deleting from S3", r);
    });

// (makeRandomId?: () => string) => <T>(uri: string, next: (url: string) => Promise<T>) => Promise<T>
// <T>(uri: string, next: (url: string) => Promise<T>) => Promise<T>

export const makeWithTempUploadIfNeeded = <T>({
  uri,
  next,
}: {
  uri: string;
  next: ({ key, url }: { key: string; url: string }) => Promise<T>;
}) => {
  if (uri.startsWith("http")) {
    return next({ key: "", url: uri });
  }

  console.log("publishing media to S3", new Date());
  return publishToS3(uuid)(uri)
    .then(({ url, key }) => {
      console.log(
        "media published to S3, starting upload to the backend",
        new Date()
      );
      return next({ key: key, url: url.url.toString() }).then(result => {
        console.log("media processed by the backend", new Date());
        // deleteFromS3(key); // fire and forget
        return result;
      });
    })
    .catch(r => {
      console.log("uploadMedia failure", r);
      throw r;
    });
};
