// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";
import * as SpacesBlockLookupRefetchQuery_graphql from "./SpacesBlockLookupRefetchQuery_graphql.bs.js";

var Types = {};

function unwrap_fragment_cell_cell(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["LookupCell"]);
}

var fragmentConverter = {"__root":{"cell_cell_LookupCell":{"f":""},"cell_cell":{"u":"fragment_cell_cell"}}};

var fragmentConverterMap = {
  fragment_cell_cell: unwrap_fragment_cell_cell
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

function makeNode(rescript_graphql_node_SpacesBlockLookupRefetchQuery) {
  return ({
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "columnId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "rowId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "refetch": {
      "connection": null,
      "fragmentPathInResult": [],
      "operation": rescript_graphql_node_SpacesBlockLookupRefetchQuery
    }
  },
  "name": "SpacesBlockLookupRefetchableData_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "columnId",
          "variableName": "columnId"
        },
        {
          "kind": "Variable",
          "name": "rowId",
          "variableName": "rowId"
        }
      ],
      "concreteType": "CellPayload",
      "kind": "LinkedField",
      "name": "cell",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": null,
          "kind": "LinkedField",
          "name": "cell",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                {
                  "args": null,
                  "kind": "FragmentSpread",
                  "name": "SpacesBlockLookup_lookupCell"
                }
              ],
              "type": "LookupCell",
              "abstractKey": null
            }
          ],
          "storageKey": null
        },
        {
          "kind": "ClientExtension",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__id",
              "storageKey": null
            }
          ]
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
});
}

var node = makeNode(SpacesBlockLookupRefetchQuery_graphql.node);

var wrap_fragment_cell_cell = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_cell_cell ,
  wrap_fragment_cell_cell ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
