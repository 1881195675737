// Generated by ReScript, PLEASE EDIT WITH CARE

import * as UiReactNative from "@aws-amplify/ui-react-native";

var make = UiReactNative.Authenticator.Provider;

export {
  make ,
}
/* make Not a pure module */
