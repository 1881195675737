// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as Primitives__ScrollView from "../scrollView/Primitives__ScrollView.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Primitives__Skeleton__Text from "../skeleton/Primitives__Skeleton__Text.bs.js";
import * as Primitives__UnstyledButton from "../button/Primitives__UnstyledButton.bs.js";

function Primitives__Select$Item(props) {
  var __inlineSecondaryText = props.inlineSecondaryText;
  var __disabled = props.disabled;
  var onPress = props.onPress;
  var __selected = props.selected;
  var selected = __selected !== undefined ? __selected : false;
  var disabled = __disabled !== undefined ? __disabled : false;
  var inlineSecondaryText = __inlineSecondaryText !== undefined ? __inlineSecondaryText : false;
  var secondaryTextElement = ReactUtils__SimpleReact.renderIfSome(props.secondaryText, (function (secondaryText) {
          return JsxRuntime.jsx(Primitives__DecidrText.make, {
                      kind: "body3",
                      children: Caml_option.some(inlineSecondaryText ? " (" + secondaryText + ")" : secondaryText),
                      color: Primitives__Styling.color({
                            NAME: "text",
                            VAL: {
                              NAME: "on_surface",
                              VAL: "subdued"
                            }
                          })
                    });
        }));
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    style: {
                      backgroundColor: Primitives__Styling.color(selected ? "primary_3" : (
                              disabled ? "neutral_7" : ({
                                    NAME: "surface",
                                    VAL: "default"
                                  })
                            )),
                      padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                    },
                    hoverStyle: disabled ? undefined : ({
                          backgroundColor: Primitives__Styling.color("primary_3")
                        }),
                    children: [
                      JsxRuntime.jsxs(Primitives__DecidrText.make, {
                            style: {
                              fontWeight: selected ? 500 : 400
                            },
                            children: [
                              props.text,
                              inlineSecondaryText ? secondaryTextElement : null
                            ]
                          }),
                      inlineSecondaryText ? null : secondaryTextElement
                    ]
                  }),
              onPress: (function (param) {
                  onPress();
                }),
              disabled: disabled
            });
}

var Item = {
  make: Primitives__Select$Item
};

function Primitives__Select$TriggerLoading(props) {
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var newrecord = Caml_obj.obj_dup(style);
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: (newrecord.paddingTop = Caml_option.some(Primitives__Styling.space("cs_2")), newrecord.paddingBottom = Caml_option.some(Primitives__Styling.space("cs_2")), newrecord.padding = Caml_option.some(Primitives__Styling.space("cs_3")), newrecord.borderWidth = 1.5, newrecord.borderColor = Primitives__Styling.color("transparent"), newrecord.backgroundColor = Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }), newrecord),
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Skeleton__Text.make, {}))
            });
}

var TriggerLoading = {
  make: Primitives__Select$TriggerLoading
};

function Primitives__Select$Trigger(props) {
  var __borderColor = props.borderColor;
  var onPress = props.onPress;
  var borderColor = __borderColor !== undefined ? __borderColor : Primitives__Styling.color("transparent");
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__Group.make, {
                    style: {
                      backgroundColor: Primitives__Styling.color({
                            NAME: "surface",
                            VAL: "default"
                          }),
                      borderColor: borderColor,
                      borderRadius: Primitives__Styling.radius("rad_2"),
                      borderWidth: 1,
                      padding: Caml_option.some(Primitives__Styling.space("cs_3")),
                      paddingBottom: Caml_option.some(Primitives__Styling.space("cs_2")),
                      paddingTop: Caml_option.some(Primitives__Styling.space("cs_2"))
                    },
                    hoverStyle: {
                      backgroundColor: Primitives__Styling.color("primary_3"),
                      borderColor: Primitives__Styling.color("primary_2")
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                              style: {
                                fontWeight: 500
                              },
                              children: Caml_option.some(props.text),
                              numberOfLines: 1
                            }))
                  }),
              style: props.style,
              onPress: (function (param) {
                  onPress();
                })
            });
}

var Trigger = {
  make: Primitives__Select$Trigger
};

function Primitives__Select(props) {
  var __emptyDataText = props.emptyDataText;
  var gap = props.gap;
  var keyExtractor = props.keyExtractor;
  var renderItem = props.renderItem;
  var selectedItem = props.selectedItem;
  var data = props.data;
  var __title = props.title;
  var title = __title !== undefined ? __title : "Select";
  var emptyDataText = __emptyDataText !== undefined ? __emptyDataText : "No available data";
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var handleClose = function () {
    setShow(function (param) {
          return false;
        });
  };
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                props.trigger(selectedItem, (function () {
                        setShow(function (param) {
                              return true;
                            });
                      })),
                JsxRuntime.jsx(Primitives__Modal.make, {
                      children: JsxRuntime.jsx(Primitives__Modal.LongContentWebFix.make, {
                            children: JsxRuntime.jsxs(Primitives__Group.make, {
                                  gap: Caml_option.some(Core__Option.getOr(gap, Primitives__Styling.gap("cs_2"))),
                                  style: {
                                    backgroundColor: Primitives__Styling.color({
                                          NAME: "surface",
                                          VAL: "default"
                                        }),
                                    flex: 1,
                                    paddingTop: Caml_option.some(Primitives__Styling.space("cs_3"))
                                  },
                                  children: [
                                    JsxRuntime.jsx(Primitives__DecidrText.make, {
                                          style: {
                                            fontWeight: 500,
                                            paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3"))
                                          },
                                          kind: "body1",
                                          children: Caml_option.some(title)
                                        }),
                                    JsxRuntime.jsx(Primitives__ScrollView.make, {
                                          style: {
                                            borderColor: Primitives__Styling.color({
                                                  NAME: "border",
                                                  VAL: {
                                                    NAME: "neutral",
                                                    VAL: "default"
                                                  }
                                                }),
                                            borderTopWidth: 1
                                          },
                                          children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                                    gap: Caml_option.some(Core__Option.getOr(gap, Primitives__Styling.gap("cs_2"))),
                                                    style: {
                                                      backgroundColor: Primitives__Styling.color({
                                                            NAME: "surface",
                                                            VAL: "default"
                                                          }),
                                                      flex: 1,
                                                      paddingBottom: Caml_option.some(Primitives__Styling.space("cs_3"))
                                                    },
                                                    children: Caml_option.some(data.length !== 0 ? data.map(function (item) {
                                                                return JsxRuntime.jsx(Primitives__Group.make, {
                                                                            children: Caml_option.some(renderItem({
                                                                                      item: item,
                                                                                      selectedItem: selectedItem,
                                                                                      close: handleClose
                                                                                    }))
                                                                          }, keyExtractor(item));
                                                              }) : JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                                style: {
                                                                  textAlign: "center",
                                                                  paddingTop: Caml_option.some(Primitives__Styling.space("cs_3"))
                                                                },
                                                                children: Caml_option.some(emptyDataText)
                                                              }))
                                                  }))
                                        })
                                  ]
                                })
                          }),
                      show: match[0],
                      onClose: (function () {
                          setShow(function (param) {
                                return false;
                              });
                        }),
                      maxWidth: 400
                    })
              ]
            });
}

var make = Primitives__Select;

export {
  Item ,
  TriggerLoading ,
  Trigger ,
  make ,
}
/* react Not a pure module */
