// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Result from "@rescript/core/src/Core__Result.bs.js";
import * as Utils__Base64 from "../../utils/src/base64/Utils__Base64.bs.js";
import * as DecidrInternalId from "../../internal-id/src/DecidrInternalId.bs.js";
import * as ErrorHandling__UnexpectedError from "../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";

function parseTypename(str) {
  switch (str) {
    case "" :
        return {
                TAG: "Error",
                _0: "Empty typename provided"
              };
    case "App" :
        return {
                TAG: "Ok",
                _0: "App"
              };
    case "Channel" :
        return {
                TAG: "Ok",
                _0: "Channel"
              };
    case "ColumnView" :
        return {
                TAG: "Ok",
                _0: "ColumnView"
              };
    case "Component" :
        return {
                TAG: "Ok",
                _0: "Component"
              };
    case "ComponentType" :
        return {
                TAG: "Ok",
                _0: "ComponentType"
              };
    case "Message" :
        return {
                TAG: "Ok",
                _0: "Message"
              };
    case "Organization" :
        return {
                TAG: "Ok",
                _0: "Organization"
              };
    case "OrganizationMember" :
        return {
                TAG: "Ok",
                _0: "OrganizationMember"
              };
    case "OrganizationMemberRole" :
        return {
                TAG: "Ok",
                _0: "OrganizationMemberRole"
              };
    case "Platform" :
        return {
                TAG: "Ok",
                _0: "Platform"
              };
    case "Role" :
        return {
                TAG: "Ok",
                _0: "Role"
              };
    case "Row" :
        return {
                TAG: "Ok",
                _0: "Row"
              };
    case "Space" :
        return {
                TAG: "Ok",
                _0: "Space"
              };
    case "Table" :
        return {
                TAG: "Ok",
                _0: "Table"
              };
    case "Template" :
        return {
                TAG: "Ok",
                _0: "Template"
              };
    case "TemplateVersion" :
        return {
                TAG: "Ok",
                _0: "TemplateVersion"
              };
    case "User" :
        return {
                TAG: "Ok",
                _0: "User"
              };
    case "View" :
        return {
                TAG: "Ok",
                _0: "View"
              };
    case "ViewFilter" :
        return {
                TAG: "Ok",
                _0: "ViewFilter"
              };
    case "ViewFilterCriterion" :
        return {
                TAG: "Ok",
                _0: "ViewFilterCriterion"
              };
    default:
      return {
              TAG: "Error",
              _0: "Unknown typename provided: \"" + str + "\""
            };
  }
}

function unpack(nodeId) {
  var match = Utils__Base64.decode(nodeId).split(";");
  var len = match.length;
  if (len >= 4) {
    return {
            TAG: "Error",
            _0: "Could not unpack nodeId (too many components found): " + nodeId
          };
  }
  switch (len) {
    case 0 :
    case 1 :
    case 2 :
        return {
                TAG: "Error",
                _0: "Could not unpack nodeId (too few components found): " + nodeId
              };
    case 3 :
        var match$1 = match[0];
        if (match$1 !== "1") {
          return {
                  TAG: "Error",
                  _0: "Could not unpack nodeId (too many components found): " + nodeId
                };
        }
        var typenameStr = match[1];
        var internalId = match[2];
        var internalId$1 = DecidrInternalId.fromString(internalId);
        if (internalId$1 === undefined) {
          return {
                  TAG: "Error",
                  _0: "Could not unpack nodeId (invalid internalId)"
                };
        }
        var internalId$2 = Caml_option.valFromOption(internalId$1);
        return Core__Result.map(parseTypename(typenameStr), (function (typename) {
                      return {
                              version: 1,
                              typename: typename,
                              internalId: internalId$2
                            };
                    }));
    
  }
}

function unpackExn(id) {
  var components = unpack(id);
  if (components.TAG === "Ok") {
    return components._0;
  } else {
    return Js_exn.raiseError(components._0);
  }
}

function makeCompositeString(version, typename, internalId) {
  return version.toString() + ";" + typename + ";" + DecidrInternalId.toString(internalId);
}

function make(typename, internalId) {
  return Utils__Base64.encode(makeCompositeString(1, typename, internalId));
}

function getInternalId(x) {
  return unpackExn(x).internalId;
}

function magicallyToInternalId(x) {
  return x;
}

function magicallyFromInternalId(x) {
  return x;
}

function magicallyFromCellId(x) {
  return x;
}

function magicallyFromSortFieldId(x) {
  return x;
}

function magicallyToSortFieldId(x) {
  return x;
}

function toString(x) {
  return x;
}

function fromString(id) {
  var message = unpack(id);
  if (message.TAG === "Ok") {
    var match = message._0;
    return Utils__Base64.encode(makeCompositeString(match.version, match.typename, match.internalId));
  }
  ErrorHandling__UnexpectedError.handle("Invalid Node ID", "DecidrNodeId fromString", {
        id: id,
        message: message._0
      });
}

function silentlyFromString(id) {
  var _message = unpack(id);
  if (_message.TAG !== "Ok") {
    return ;
  }
  var match = _message._0;
  return Utils__Base64.encode(makeCompositeString(match.version, match.typename, match.internalId));
}

function parse(json) {
  var id = Core__JSON.Decode.string(json);
  if (id !== undefined) {
    return id;
  } else {
    return Js_exn.raiseError("Error parsing node ID - couldn't decode string");
  }
}

function serialize(t) {
  return t;
}

var RelayAppSync = {
  parse: parse,
  serialize: serialize
};

var empty = "";

export {
  make ,
  getInternalId ,
  magicallyToInternalId ,
  magicallyFromInternalId ,
  magicallyFromCellId ,
  magicallyFromSortFieldId ,
  magicallyToSortFieldId ,
  empty ,
  toString ,
  fromString ,
  silentlyFromString ,
  RelayAppSync ,
}
/* Utils__Base64 Not a pure module */
