import { useState } from "react";
import { useMessages, useScrollToBottom } from "../utils/hooks";
import { columnView as ColumnView } from "@decidr/renderer/src/blocks/DecidrRenderer__Blocks__Utils.gen";

type ChatProps = {
  showSideContent: boolean;
  sideContentSource: string;
  sideContentType?: "image" | "iframe";
  prompt: string;
  starterMessage: string;
  columnViews: ColumnView[];
  AssistantMessageComponent: ({ content }: { content: string }) => JSX.Element;
  UserMessageComponent: ({ content }: { content: string }) => JSX.Element;
  assistantId: string;
  sideContentPosition: "left" | "right";
  viewId: string;
  organizationId: string;
  submitFlow: "chat" | "thread" | "question" | undefined;
};

export const Chat = ({
  showSideContent,
  sideContentSource,
  sideContentType = "image",
  prompt,
  starterMessage,
  columnViews,
  AssistantMessageComponent,
  UserMessageComponent,
  assistantId,
  sideContentPosition,
  viewId,
  organizationId,
  submitFlow,
}: ChatProps) => {
  const { ref: chatContainerRef, scrollToBottom } = useScrollToBottom();
  const [input, setInput] = useState("");
  const { state, handleSubmit } = useMessages({
    starterMessage,
    columnViews,
    scrollToBottom,
    prompt,
    resetInput: () => setInput(""),
    assistantId,
    viewId,
    organizationId,
    submitFlow,
  });

  const handleChange = (event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setInput(event.target.value);
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLTextAreaElement>) => {
    if (event.key === "Enter" && !event.shiftKey) {
      event.preventDefault(); // Prevent the default action to avoid newline
      handleSubmit(input); // Submit form
    }
  };

  const handleFormSubmission =
    (input: string) => (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      handleSubmit(input);
    };

  return (
    <div
      style={{
        display: "flex",
        height: "100%",
        maxHeight: "100vh",
        flex: sideContentType === "iframe" ? 1 : undefined,
        flexDirection: sideContentPosition === "right" ? "row" : "row-reverse",
      }}
    >
      <div style={{ flex: 1, display: "flex" }}>
        <div
          style={{
            flex: 1,
            position: "relative",
            display: "flex",
            flexDirection: "column",
          }}
        >
          <div
            ref={chatContainerRef}
            style={{
              flex: 1,
              display: "flex",
              flexDirection: "column",
              overflowY: "auto",
              maxHeight: "80vh",
              padding: "16px 0 120px 0",
            }}
          >
            <div
              style={{
                position: "relative",
                display: "flex",
                flexDirection: "column",
                width: "100%",
                maxWidth: "640px",
                margin: "0 auto",
                gap: "12px",
                padding: "0px 24px",
              }}
            >
              {state.messages.map(({ role, content }, i) =>
                role === "assistant" ? (
                  <AssistantMessageComponent key={i} content={content} />
                ) : (
                  <UserMessageComponent key={i} content={content} />
                )
              )}
            </div>
          </div>
          {/* 
            HACK for onSubmit, which otherwise fails eslint with Promise-returning function provided to attribute where a void return was expected
            https://stackoverflow.com/questions/74190256/eslint-promise-returning-function-provided-to-attribute-where-a-void-return-was 
          */}
          <form onSubmit={e => void handleFormSubmission(input)(e)}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                padding: "16px 24px",
              }}
            >
              <div
                style={{
                  flex: 1,
                  maxWidth: "640px",
                  display: "flex",
                  flexDirection: "column",
                }}
              >
                <textarea
                  style={{
                    border: "1px solid #ededed",
                    borderRadius: "12px",
                    boxShadow: "0 0 12px rgba(0, 0, 0, 0.1)",
                    padding: "12px",
                    opacity: state.state === "Done" ? 0.5 : 1,
                    cursor: state.state === "Done" ? "not-allowed" : "default",
                  }}
                  disabled={state.state === "Done"}
                  value={input}
                  placeholder="Say something..."
                  onChange={handleChange}
                  onKeyDown={handleKeyDown}
                />
              </div>
            </div>
          </form>
        </div>
      </div>
      {showSideContent ? (
        <div className="chat-right-side-content" style={{ flex: 1 }}>
          {sideContentType === "image" ? (
            <img
              src={sideContentSource}
              style={{
                width: "100%",
                height: "100%",
                objectFit: "cover",
              }}
            />
          ) : (
            <iframe
              src={sideContentSource}
              width="100%"
              height="100%"
              style={{
                borderWidth: 0,
                borderRight:
                  sideContentPosition === "left"
                    ? "1px solid #ededed"
                    : undefined,
                borderLeft:
                  sideContentPosition === "right"
                    ? "1px solid #ededed"
                    : undefined,
              }}
            />
          )}
        </div>
      ) : null}
    </div>
  );
};
