// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Toast from "@tamagui/toast";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as RescriptTamagui__Icon__X from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__X.bs.js";
import * as Primitives__UnstyledButton from "../button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__IconProps from "../../../rescript-tamagui/src/icons/RescriptTamagui__IconProps.bs.js";

function use() {
  var toast = Toast.useToastController();
  var handleShow = function (title, options) {
    toast.show(title, options);
  };
  var handleError = function (title, message) {
    toast.show(title, {
          message: message,
          duration: 5000,
          toastType: "error"
        });
  };
  return {
          nativeToast: toast.nativeToast,
          show: handleShow,
          error: handleError,
          hide: toast.hide
        };
}

function Primitives__DecidrToast(props) {
  var currentToast = Toast.useToastState();
  if (currentToast == null) {
    return null;
  }
  if (currentToast.isHandledNatively) {
    return null;
  }
  var match = currentToast.toastType;
  return JsxRuntime.jsx(Toast.Toast, {
              children: Caml_option.some(JsxRuntime.jsxs(Primitives__Group.make, {
                        style: {
                          backgroundColor: Primitives__Styling.color({
                                NAME: "surface",
                                VAL: "default"
                              })
                        },
                        children: [
                          JsxRuntime.jsx(Toast.Toast.Close, {
                                children: Caml_option.some(JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                                          children: JsxRuntime.jsx(RescriptTamagui__Icon__X.make, {
                                                size: Caml_option.some(RescriptTamagui__IconProps.Size.dp(16)),
                                                color: Primitives__Styling.color({
                                                      NAME: "icon",
                                                      VAL: {
                                                        NAME: "neutral",
                                                        VAL: "subdued"
                                                      }
                                                    })
                                              }),
                                          style: {
                                            position: "absolute",
                                            right: Caml_option.some(Primitives__Styling.dp(0)),
                                            top: Caml_option.some(Primitives__Styling.space("cs_2"))
                                          }
                                        })),
                                asChild: true
                              }),
                          JsxRuntime.jsx(Toast.Toast.Title, {
                                children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                          style: {
                                            fontWeight: 500
                                          },
                                          children: Caml_option.some(currentToast.title),
                                          color: match !== undefined ? (
                                              match === "success" ? Primitives__Styling.color("success_1") : Primitives__Styling.color("error_1")
                                            ) : undefined
                                        }))
                              }),
                          ReactUtils__SimpleReact.renderIfSome(currentToast.message, (function (message) {
                                  return JsxRuntime.jsx(Toast.Toast.Description, {
                                              children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                        kind: "caption",
                                                        children: Caml_option.some(message)
                                                      }))
                                            });
                                }))
                        ]
                      })),
              duration: currentToast.duration,
              viewportName: currentToast.viewportName,
              enterStyle: {
                x: Caml_option.some(Primitives__Styling.dp(200)),
                opacity: 0,
                scale: 0.5
              },
              exitStyle: {
                x: Caml_option.some(Primitives__Styling.dp(200)),
                opacity: 0,
                scale: 1
              },
              y: 0,
              x: 0,
              opacity: 1,
              scale: 1,
              animation: "quick",
              borderRadius: 6,
              maxWidth: Caml_option.some(Primitives__Styling.dp(350)),
              maxHeight: Caml_option.some(Primitives__Styling.dp(150)),
              minWidth: Caml_option.some(Primitives__Styling.dp(300)),
              minHeight: Caml_option.some(Primitives__Styling.dp(80)),
              justifyContent: "center",
              bc: Primitives__Styling.color({
                    NAME: "surface",
                    VAL: "default"
                  }),
              bw: 1,
              boc: Primitives__Styling.color({
                    NAME: "border",
                    VAL: {
                      NAME: "neutral",
                      VAL: "default"
                    }
                  }),
              zi: 100001
            }, currentToast.id);
}

var Provider;

var make = Primitives__DecidrToast;

export {
  Provider ,
  use ,
  make ,
}
/* @tamagui/toast Not a pure module */
