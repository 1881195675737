// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Space__Mutations from "../../space/Spaces__Space__Mutations.bs.js";
import * as Spaces__Ui__PlatformSpacePicker from "../platformSpacePicker/Spaces__Ui__PlatformSpacePicker.bs.js";
import * as Spaces__Ui__SimpleOneInputModal from "../simpleOneInputModal/Spaces__Ui__SimpleOneInputModal.bs.js";

function Spaces__Ui__CreateSpaceModal(props) {
  var __relayConnectionsToUpdate = props.relayConnectionsToUpdate;
  var cacheUpdater = props.cacheUpdater;
  var onCompleted = props.onCompleted;
  var platformId = props.platformId;
  var relayConnectionsToUpdate = __relayConnectionsToUpdate !== undefined ? __relayConnectionsToUpdate : [];
  var match = Spaces__Space__Mutations.useCreate();
  var createSpace = match[0];
  return JsxRuntime.jsx(Spaces__Ui__SimpleOneInputModal.make, {
              show: props.show,
              onClose: props.onClose,
              onButtonPress: (function (value, onError, onSuccess) {
                  createSpace(DecidrNodeId.make("App", DecidrNodeId.getInternalId(platformId)), value, (function (spaceId, spaceName) {
                          onCompleted(spaceId, spaceName);
                          onSuccess();
                        }), onError, cacheUpdater, [RelayRuntime.ConnectionHandler.getConnectionID(DecidrNodeId.toString(platformId), Spaces__Ui__PlatformSpacePicker.Spaces.PlatformFragment.Operation.connectionKey, {
                                searchTerm: ""
                              })].concat(relayConnectionsToUpdate));
                }),
              label: "New space name",
              placeholder: "My space",
              loading: match[1],
              initialValue: "New space",
              title: "Add space"
            });
}

var make = Spaces__Ui__CreateSpaceModal;

export {
  make ,
}
/* DecidrNodeId Not a pure module */
