// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Tamagui from "tamagui";

function make(prim0, prim1) {
  return Tamagui.styled(prim0, prim1);
}

export {
  make ,
}
/* tamagui Not a pure module */
