// Generated by ReScript, PLEASE EDIT WITH CARE


var Key = {};

var columnId = "columnId";

var name = "name";

var icon = "icon";

var componentTypeCode = "componentTypeCode";

var Keys = {
  rowId: "rowId",
  columnId: columnId,
  columnViewId: "columnViewId",
  name: name,
  icon: icon,
  viewId: "viewId",
  organizationId: "organizationId",
  componentTypeCode: componentTypeCode
};

var columnId$1 = {
  key: columnId,
  value: "String"
};

var name$1 = {
  key: name,
  value: "String"
};

var icon$1 = {
  key: icon,
  value: "String"
};

var componentTypeCode$1 = {
  key: componentTypeCode,
  value: "String"
};

var KeyValue = {
  columnId: columnId$1,
  name: name$1,
  icon: icon$1,
  componentTypeCode: componentTypeCode$1
};

var Common = {
  Keys: Keys,
  KeyValue: KeyValue
};

var text = "text";

var Keys$1 = {
  text: text
};

var schema = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: text,
      value: "String"
    }
  ]
};

var $$Text = {
  Keys: Keys$1,
  schema: schema
};

var date = "date";

var Keys$2 = {
  date: date
};

var schema$1 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    {
      key: date,
      value: "String"
    },
    icon$1,
    name$1
  ]
};

var $$Date = {
  Keys: Keys$2,
  schema: schema$1
};

var number = "number";

var Keys$3 = {
  number: number
};

var schema$2 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: number,
      value: "Float"
    }
  ]
};

var $$Number = {
  Keys: Keys$3,
  schema: schema$2
};

var switched = "switch";

var Keys$4 = {
  switched: switched
};

var schema$3 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: switched,
      value: "Bool"
    }
  ]
};

var Switch = {
  Keys: Keys$4,
  schema: schema$3
};

var sourceViewId = "sourceViewId";

var callerRowId = "callerRowId";

var tableLinkInfoId = "tableLinkInfoId";

var Keys$5 = {
  sourceViewId: sourceViewId,
  callerRowId: callerRowId,
  tableLinkInfoId: tableLinkInfoId
};

var schema$4 = {
  TAG: "Object",
  _0: [
    {
      key: callerRowId,
      value: "String"
    },
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: sourceViewId,
      value: "String"
    },
    {
      key: tableLinkInfoId,
      value: "String"
    }
  ]
};

var RelatedList = {
  Keys: Keys$5,
  schema: schema$4
};

var primitiveColumnViewId = "primitiveColumnViewId";

var referencedColumnViewId = "referencedColumnViewId";

var tableLinkInfoId$1 = "tableLinkInfoId";

var Keys$6 = {
  primitiveColumnViewId: primitiveColumnViewId,
  referencedColumnViewId: referencedColumnViewId,
  tableLinkInfoId: tableLinkInfoId$1
};

var schema$5 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: primitiveColumnViewId,
      value: "String"
    },
    {
      key: referencedColumnViewId,
      value: "String"
    },
    {
      key: tableLinkInfoId$1,
      value: "String"
    }
  ]
};

var Lookup = {
  Keys: Keys$6,
  schema: schema$5
};

var tableLinkInfoId$2 = "tableLinkInfoId";

var Keys$7 = {
  tableLinkInfoId: tableLinkInfoId$2
};

var schema$6 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: tableLinkInfoId$2,
      value: "String"
    }
  ]
};

var Rollup = {
  Keys: Keys$7,
  schema: schema$6
};

var date$1 = "date";

var Keys$8 = {
  date: date$1
};

var schema$7 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    {
      key: date$1,
      value: "String"
    },
    icon$1,
    name$1
  ]
};

var ReadOnlyDate = {
  Keys: Keys$8,
  schema: schema$7
};

var sourceViewId$1 = "sourceViewId";

var tableLinkInfoId$3 = "tableLinkInfoId";

var Keys$9 = {
  sourceViewId: sourceViewId$1,
  tableLinkInfoId: tableLinkInfoId$3
};

var schema$8 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: sourceViewId$1,
      value: "String"
    },
    {
      key: tableLinkInfoId$3,
      value: "String"
    }
  ]
};

var MultiSelect = {
  Keys: Keys$9,
  schema: schema$8
};

var selectedRowId = "selectedRowId";

var sourceViewId$2 = "sourceViewId";

var Keys$10 = {
  selectedRowId: selectedRowId,
  sourceViewId: sourceViewId$2
};

var schema$9 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: selectedRowId,
      value: "String"
    },
    {
      key: sourceViewId$2,
      value: "String"
    }
  ]
};

var SystemLink = {
  Keys: Keys$10,
  schema: schema$9
};

var imageId = "imageId";

var imageUrl = "imageUrl";

var imageWidth = "imageWidth";

var imageHeight = "imageHeight";

var Keys$11 = {
  imageId: imageId,
  imageUrl: imageUrl,
  imageWidth: imageWidth,
  imageHeight: imageHeight
};

var schema$10 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    {
      key: imageHeight,
      value: "Float"
    },
    {
      key: imageId,
      value: "String"
    },
    {
      key: imageUrl,
      value: "String"
    },
    {
      key: imageWidth,
      value: "Float"
    },
    name$1
  ]
};

var $$Image = {
  Keys: Keys$11,
  schema: schema$10
};

var fileUrl = "fileUrl";

var mimetype = "mimetype";

var size = "size";

var Keys$12 = {
  fileUrl: fileUrl,
  mimetype: mimetype,
  size: size
};

var schema$11 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    {
      key: fileUrl,
      value: "String"
    },
    {
      key: mimetype,
      value: "String"
    },
    {
      key: size,
      value: "Float"
    },
    icon$1,
    name$1
  ]
};

var $$File = {
  Keys: Keys$12,
  schema: schema$11
};

var inline = "inline";

var Keys$13 = {
  inline: inline
};

var schema$12 = {
  TAG: "Object",
  _0: [
    componentTypeCode$1,
    icon$1,
    {
      key: inline,
      value: "Bool"
    },
    name$1
  ]
};

var Container = {
  Keys: Keys$13,
  schema: schema$12
};

var sourceViewIdToOpen = "sourceViewIdToOpen";

var callerColumnId = "callerColumnId";

var callerColumnViewId = "callerColumnViewId";

var callerRowId$1 = "callerRowId";

var Keys$14 = {
  sourceViewIdToOpen: sourceViewIdToOpen,
  callerColumnId: callerColumnId,
  callerColumnViewId: callerColumnViewId,
  callerRowId: callerRowId$1
};

var schema$13 = {
  TAG: "Object",
  _0: [
    {
      key: callerColumnId,
      value: "String"
    },
    {
      key: callerColumnViewId,
      value: "String"
    },
    {
      key: callerRowId$1,
      value: "String"
    },
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: sourceViewIdToOpen,
      value: "String"
    }
  ]
};

var LinkRowsModal = {
  Keys: Keys$14,
  schema: schema$13
};

var viewId = "viewId";

var Keys$15 = {
  viewId: viewId
};

var schema$14 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1,
    {
      key: viewId,
      value: "String"
    }
  ]
};

var SelectView = {
  Keys: Keys$15,
  schema: schema$14
};

var schema$15 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1
  ]
};

var RowJsonData = {
  schema: schema$15
};

var schema$16 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1
  ]
};

var RowMembership = {
  schema: schema$16
};

var schema$17 = {
  TAG: "Object",
  _0: [
    columnId$1,
    componentTypeCode$1,
    icon$1,
    name$1
  ]
};

var RowMembershipHistory = {
  schema: schema$17
};

export {
  Key ,
  Common ,
  $$Text ,
  $$Date ,
  $$Number ,
  Switch ,
  RelatedList ,
  Lookup ,
  Rollup ,
  ReadOnlyDate ,
  MultiSelect ,
  SystemLink ,
  $$Image ,
  $$File ,
  Container ,
  LinkRowsModal ,
  SelectView ,
  RowJsonData ,
  RowMembership ,
  RowMembershipHistory ,
}
/* No side effect */
