// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__Cells from "../cells/Spaces__Cells.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__ScrollView from "../../../primitives/src/scrollView/Primitives__ScrollView.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesBlockRollupCellQuery_graphql from "../../../relay-codegen/generated/SpacesBlockRollupCellQuery_graphql.bs.js";
import * as SpacesBlockRollupRefetchQuery_graphql from "../../../relay-codegen/generated/SpacesBlockRollupRefetchQuery_graphql.bs.js";
import * as SpacesBlockRollupRefetchableData_query_graphql from "../../../relay-codegen/generated/SpacesBlockRollupRefetchableData_query_graphql.bs.js";

function Spaces__Block__Rollup$Data$OptionalTextDisplay(props) {
  var text = props.text;
  if (text !== undefined && text !== "") {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                style: {
                  maxWidth: Caml_option.some(Primitives__Styling.dp(200))
                },
                children: Caml_option.some(text),
                numberOfLines: 1
              });
  }
  return JsxRuntime.jsx(Primitives__DecidrText.make, {
              style: {
                fontStyle: "italic"
              },
              children: "Empty"
            });
}

function Spaces__Block__Rollup$Data$CellDataDisplay(props) {
  var cell = props.cell;
  if (cell.TAG === "Text") {
    return JsxRuntime.jsx(Spaces__Block__Rollup$Data$OptionalTextDisplay, {
                text: cell._0
              });
  }
  ErrorHandling__UnexpectedError.handle("Not a rollup cell data type", "Spaces__Block__Rollup CellDataDisplay", {
        cell: cell
      });
  return null;
}

function Spaces__Block__Rollup$Data(props) {
  var cell = Spaces__Cells.CellFragment.readInline(props.cellRef);
  var cellInfo = Spaces__Cells.getCellSignature(cell);
  var cellData = Core__Option.map(cellInfo, (function (cellInfo) {
          return Spaces__Row__Utils.makeBlockData(cell, "text", undefined, undefined, DecidrNodeId.empty, cellInfo.rowId, cellInfo.columnId);
        }));
  var tmp;
  if (cellData !== undefined) {
    if (cellData.TAG === "Ok") {
      tmp = JsxRuntime.jsx(Spaces__Block__Rollup$Data$CellDataDisplay, {
            cell: cellData._0
          });
    } else {
      ErrorHandling__UnexpectedError.handle("Error parsing cell data", "Spaces__Block__Rollup Data", {
            message: cellData._0
          });
      tmp = JsxRuntime.jsx(Primitives__DecidrText.make, {
            children: "Error"
          });
    }
  } else {
    tmp = null;
  }
  return JsxRuntime.jsx(Primitives__ScrollView.make, {
              horizontal: true,
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                        gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                        orientation: "horizontal",
                        children: Caml_option.some(tmp)
                      }))
            });
}

function Spaces__Block__Rollup$Data$Loading(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1,
                padding: Caml_option.some(Primitives__Styling.dp(6))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                        size: "small"
                      }))
            });
}

var Loading = {
  make: Spaces__Block__Rollup$Data$Loading
};

var convertFragment = SpacesBlockRollupRefetchableData_query_graphql.Internal.convertFragment;

var convertRefetchVariables = SpacesBlockRollupRefetchQuery_graphql.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(SpacesBlockRollupRefetchableData_query_graphql.node, convertFragment, convertRefetchVariables, fRef);
}

function Spaces__Block__Rollup$RollupRefetchableData(props) {
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var match = useRefetchable(props.cellQueryRef);
  var refetch = match[1];
  var data = match[0];
  ReactRelay.useSubscribeToInvalidationState([data.cell.__id], (function () {
          refetch({
                columnId: Caml_option.some(DecidrNodeId.magicallyFromInternalId(callerColumnId)),
                rowId: Caml_option.some(DecidrNodeId.magicallyFromInternalId(callerRowId))
              }, undefined, undefined);
        }));
  var match$1 = data.cell.cell;
  if (match$1 !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Rollup$Data, {
                cellRef: match$1.fragmentRefs
              });
  } else {
    ErrorHandling__UnexpectedError.handle("No cell data found", "Spaces__Block__Rollup", {
          callerRowId: callerRowId,
          callerColumnId: callerColumnId
        });
    return null;
  }
}

var convertVariables = SpacesBlockRollupCellQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockRollupCellQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockRollupCellQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockRollupCellQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockRollupCellQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockRollupCellQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockRollupCellQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockRollupCellQuery_graphql.node, convertVariables);

function Spaces__Block__Rollup(props) {
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var match = use({
        columnId: DecidrNodeId.magicallyFromInternalId(callerColumnId),
        rowId: DecidrNodeId.magicallyFromInternalId(callerRowId)
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(Spaces__Block__Rollup$RollupRefetchableData, {
              cellQueryRef: match.fragmentRefs,
              callerRowId: callerRowId,
              callerColumnId: callerColumnId
            });
}

var Data = {
  Loading: Loading
};

var make = Spaces__Block__Rollup;

export {
  Data ,
  make ,
}
/* use Not a pure module */
