// Generated by ReScript, PLEASE EDIT WITH CARE


var breakpoints = [
  "s",
  "m",
  "l",
  "xl"
];

export {
  breakpoints ,
}
/* No side effect */
