/* TypeScript file generated from Primitives__DecidrToast.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as Primitives__DecidrToastJS from './Primitives__DecidrToast.bs.js';

export type props = {};

export const make: React.ComponentType<{}> = Primitives__DecidrToastJS.make as any;
