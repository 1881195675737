// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function App__RelayProvider(props) {
  return JsxRuntime.jsx(RescriptRelay.Context.Provider.make, {
              environment: props.environment,
              children: props.children
            });
}

var make = App__RelayProvider;

export {
  make ,
}
/* RescriptRelay Not a pure module */
