/* TypeScript file generated from App__RelayEnv.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as App__RelayEnvJS from './App__RelayEnv.bs.js';

import type {Environment_t as RescriptRelay_Environment_t} from '../../../../rescript-shims/RescriptRelay.shim';

export const makeEnvironment: () => RescriptRelay_Environment_t = App__RelayEnvJS.makeEnvironment as any;
