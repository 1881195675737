// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Spaces__Row from "../../row/Spaces__Row.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__Cells from "../../cells/Spaces__Cells.bs.js";

function getCell(rowId, columnId, rows) {
  return Core__Option.flatMap(Core__Option.map(Core__Option.map(rows.find(function (param) {
                          return DecidrNodeId.magicallyToInternalId(param.node.id) === rowId;
                        }), (function (param) {
                        return Spaces__Row.ListDataFragment.readInline(param.node.fragmentRefs);
                      })), (function (param) {
                    return param.cells.map(function (param) {
                                return Spaces__Cells.CellFragment.readInline(param.fragmentRefs);
                              });
                  })), (function (cells) {
                return cells.find(function (cell) {
                            return Core__Option.getOr(Core__Option.map(Spaces__Cells.getCellSignature(cell), (function (cellSignature) {
                                              if (cellSignature.rowId === rowId) {
                                                return cellSignature.columnId === columnId;
                                              } else {
                                                return false;
                                              }
                                            })), false);
                          });
              }));
}

function columnAccessorFn(row, columnId) {
  return Core__Option.flatMap(row.cells.find(function (cell) {
                  if (cell !== undefined) {
                    return cell.columnId === columnId;
                  } else {
                    return false;
                  }
                }), (function (x) {
                return x;
              }));
}

var Table;

export {
  Table ,
  getCell ,
  columnAccessorFn ,
}
/* Spaces__Row Not a pure module */
