// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as Primitives__Icon from "../icon/Primitives__Icon.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "./Primitives__Button.bs.js";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";

function Primitives__IconButton(props) {
  var __scaleOnHover = props.scaleOnHover;
  var __hideBreakpoints = props.hideBreakpoints;
  var __busy = props.busy;
  var __disabled = props.disabled;
  var __specificStyles = props.specificStyles;
  var __style = props.style;
  var __size = props.size;
  var icon = props.icon;
  var __kind = props.kind;
  var kind = __kind !== undefined ? __kind : "primary";
  var size = __size !== undefined ? __size : "large";
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var specificStyles = __specificStyles !== undefined ? __specificStyles : TamaguiConfig.Utils.emptySpecificStyles;
  var disabled = __disabled !== undefined ? __disabled : false;
  var busy = __busy !== undefined ? __busy : false;
  var hideBreakpoints = __hideBreakpoints !== undefined ? __hideBreakpoints : [];
  var scaleOnHover = __scaleOnHover !== undefined ? __scaleOnHover : false;
  var large = React.useMemo((function () {
          return size === "large";
        }), [size]);
  var newrecord = Caml_obj.obj_dup(style);
  return JsxRuntime.jsx(Primitives__Button.make, {
              style: (newrecord.paddingTop = Caml_option.some(Core__Option.getOr(style.paddingTop, Primitives__Styling.space(large ? "cs_3" : "cs_2"))), newrecord.paddingRight = Caml_option.some(Core__Option.getOr(style.paddingRight, Primitives__Styling.space(large ? "cs_3" : "cs_2"))), newrecord.paddingLeft = Caml_option.some(Core__Option.getOr(style.paddingLeft, Primitives__Styling.space(large ? "cs_3" : "cs_2"))), newrecord.paddingBottom = Caml_option.some(Core__Option.getOr(style.paddingBottom, Primitives__Styling.space(large ? "cs_3" : "cs_2"))), newrecord),
              specificStyles: specificStyles,
              onPress: props.onPress,
              icon: (function (param) {
                  return JsxRuntime.jsx(Primitives__Icon.make, {
                              size: large ? "ic_3" : "ic_1",
                              color: param.color,
                              icon: icon
                            });
                }),
              kind: kind,
              disabled: disabled,
              busy: busy,
              hideBreakpoints: hideBreakpoints,
              scaleOnHover: scaleOnHover,
              focusable: props.focusable
            });
}

var make = Primitives__IconButton;

export {
  make ,
}
/* react Not a pure module */
