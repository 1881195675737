// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["Organization"]);
}

var fragmentConverter = {"__root":{"node_Organization_platforms_id":{"c":"DecidrNodeId.RelayAppSync"},"node_Organization_id":{"c":"DecidrNodeId.RelayAppSync"},"node":{"u":"fragment_node"}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment_node: unwrap_fragment_node
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "skipQuery"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesBlockSelectViewOrganizationPlatform_query",
  "selections": [
    {
      "condition": "skipQuery",
      "kind": "Condition",
      "passingValue": false,
      "selections": [
        {
          "alias": null,
          "args": [
            {
              "kind": "Variable",
              "name": "id",
              "variableName": "organizationId"
            }
          ],
          "concreteType": null,
          "kind": "LinkedField",
          "name": "node",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "__typename",
              "storageKey": null
            },
            {
              "kind": "InlineFragment",
              "selections": [
                (v0/*: any*/),
                {
                  "alias": null,
                  "args": null,
                  "concreteType": "Platform",
                  "kind": "LinkedField",
                  "name": "platforms",
                  "plural": true,
                  "selections": [
                    (v0/*: any*/)
                  ],
                  "storageKey": null
                }
              ],
              "type": "Organization",
              "abstractKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());

var wrap_fragment_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_node ,
  wrap_fragment_node ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
