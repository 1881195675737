// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Icon from "../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as RescriptTamagui__Icon__X from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__X.bs.js";
import * as RescriptTamagui__Icon__Unlink from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Unlink.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as RescriptTamagui__Icon__MoreVertical from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__MoreVertical.bs.js";

var blockCode = "decidr-icon";

function DecidrRenderer__IconBlock$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Renderer__Settings.getString(settings, code, context);
  };
  var icon = Core__Option.flatMap(getString("icon"), (function (icon) {
          switch (icon) {
            case "unlink" :
                return RescriptTamagui__Icon__Unlink.make;
            case "v-more" :
                return RescriptTamagui__Icon__MoreVertical.make;
            case "x" :
                return RescriptTamagui__Icon__X.make;
            default:
              return ;
          }
        }));
  var size = Core__Option.flatMap(getString("size"), (function (size) {
          switch (size) {
            case "ic_1" :
                return "ic_1";
            case "ic_2" :
                return "ic_2";
            case "ic_3" :
                return "ic_3";
            case "ic_4" :
                return "ic_4";
            default:
              return ;
          }
        }));
  if (icon !== undefined) {
    return JsxRuntime.jsx(Primitives__Icon.make, {
                size: Core__Option.getOr(size, "ic_2"),
                color: getString("color"),
                icon: icon
              });
  } else {
    ErrorHandling__UnexpectedError.handle("Unsupported icon", "DecidrRenderer__IconBlock", {
          icon: getString("icon")
        });
    return null;
  }
}

var Component = {
  make: DecidrRenderer__IconBlock$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

export {
  blockCode ,
  version ,
  descriptor ,
}
/* Primitives__Icon Not a pure module */
