// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"tableId":{"c":"DecidrNodeId.RelayAppSync"},"newSpaceId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"moveTableToAnotherSpace_table_space_id":{"c":"DecidrNodeId.RelayAppSync"},"moveTableToAnotherSpace_oldSpaceId":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"moveTableToAnotherSpace_table_space_id":{"c":"DecidrNodeId.RelayAppSync"},"moveTableToAnotherSpace_oldSpaceId":{"c":"DecidrNodeId.RelayAppSync"}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "newSpaceId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "tableId"
},
v2 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "newSpaceId",
        "variableName": "newSpaceId"
      },
      {
        "kind": "Variable",
        "name": "tableId",
        "variableName": "tableId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "oldSpaceId",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "concreteType": "Space",
  "kind": "LinkedField",
  "name": "space",
  "plural": false,
  "selections": [
    (v4/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "name",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesTableMutationsMoveTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "MoveTableToAnotherSpacePayload",
        "kind": "LinkedField",
        "name": "moveTableToAnotherSpace",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Table",
            "kind": "LinkedField",
            "name": "table",
            "plural": false,
            "selections": [
              (v5/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesTableMutationsMoveTableMutation",
    "selections": [
      {
        "alias": null,
        "args": (v2/*: any*/),
        "concreteType": "MoveTableToAnotherSpacePayload",
        "kind": "LinkedField",
        "name": "moveTableToAnotherSpace",
        "plural": false,
        "selections": [
          (v3/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "Table",
            "kind": "LinkedField",
            "name": "table",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              (v4/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "c8151ef9622919236488c62689aa3bf3",
    "id": null,
    "metadata": {},
    "name": "SpacesTableMutationsMoveTableMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesTableMutationsMoveTableMutation(\n  $tableId: ID!\n  $newSpaceId: ID!\n) {\n  moveTableToAnotherSpace(input: {tableId: $tableId, newSpaceId: $newSpaceId}) {\n    oldSpaceId\n    table {\n      space {\n        id\n        name\n      }\n      id\n    }\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
