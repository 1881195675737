// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__ScrollView from "../../../../primitives/src/scrollView/Primitives__ScrollView.bs.js";
import * as Primitives__DecidrImage from "../../../../primitives/src/image/Primitives__DecidrImage.bs.js";

function Spaces__Ui__ImagePreview(props) {
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Primitives__Modal.LongContentWebFix.make, {
                    children: JsxRuntime.jsxs(Primitives__Group.make, {
                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                          style: {
                            backgroundColor: Primitives__Styling.color({
                                  NAME: "surface",
                                  VAL: "default"
                                }),
                            flex: 1
                          },
                          children: [
                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                  style: {
                                    fontWeight: 500,
                                    paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3"))
                                  },
                                  kind: "body1",
                                  children: "Full image viewer"
                                }),
                            JsxRuntime.jsx(Primitives__ScrollView.make, {
                                  style: {
                                    borderColor: Primitives__Styling.color({
                                          NAME: "border",
                                          VAL: {
                                            NAME: "neutral",
                                            VAL: "default"
                                          }
                                        }),
                                    borderTopWidth: 1
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                            gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                            style: {
                                              backgroundColor: Primitives__Styling.color({
                                                    NAME: "surface",
                                                    VAL: "default"
                                                  }),
                                              alignItems: "center",
                                              flex: 1,
                                              padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                                            },
                                            children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrImage.make, {
                                                      resizeMode: "contain",
                                                      src: props.url,
                                                      size: {
                                                        TAG: "Rect",
                                                        width: Primitives__Styling.dp(props.width),
                                                        height: Primitives__Styling.dp(props.height)
                                                      },
                                                      style: {
                                                        borderRadius: Primitives__Styling.radiusDp(4)
                                                      }
                                                    }))
                                          }))
                                })
                          ]
                        })
                  }),
              show: props.show,
              onClose: props.onClose,
              maxWidth: 600
            });
}

var make = Spaces__Ui__ImagePreview;

export {
  make ,
}
/* Primitives__Group Not a pure module */
