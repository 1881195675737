// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__Spinner from "../../../rescript-tamagui/src/spinner/RescriptTamagui__Spinner.bs.js";

function make(param) {
  return JsxRuntime.jsx(RescriptTamagui__Spinner.make, {
              size: param.size,
              color: Core__Option.getOr(param.color, Primitives__Styling.color("primary_1"))
            });
}

var Size;

export {
  Size ,
  make ,
}
/* react/jsx-runtime Not a pure module */
