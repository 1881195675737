// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DecidrInternalId from "../../internal-id/src/DecidrInternalId.bs.js";

function make(rowId, columnId) {
  return DecidrInternalId.toString(rowId) + "_" + DecidrInternalId.toString(columnId);
}

function toString(t) {
  return t;
}

export {
  make ,
  toString ,
}
/* DecidrInternalId Not a pure module */
