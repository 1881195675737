// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__Button from "../../../rescript-tamagui/src/button/RescriptTamagui__Button.bs.js";

var style_borderColor = Primitives__Styling.color("transparent");

var style_borderRadius = Primitives__Styling.radius("rad_3");

var style_borderWidth = 1;

var style_alignSelf = "flex-start";

var style = {
  borderColor: style_borderColor,
  borderRadius: style_borderRadius,
  borderWidth: style_borderWidth,
  alignSelf: style_alignSelf
};

var focusStyle_borderColor = Primitives__Styling.color("primary_3");

var focusStyle = {
  borderColor: focusStyle_borderColor
};

var Focused = {
  style: style,
  focusStyle: focusStyle
};

function Primitives__UnstyledButton(props) {
  var __disabled = props.disabled;
  var onPress = props.onPress;
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var disabled = __disabled !== undefined ? __disabled : false;
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle(style, undefined, undefined));
  var style$1 = stylesDict["common"];
  return JsxRuntime.jsx(RescriptTamagui__Button.make, {
              style: Core__Option.map(style$1, (function (__x) {
                      return Primitives__Styling.Utils.omitStyleKeys(__x, [
                                  "backgroundColor",
                                  "borderColor",
                                  "borderWidth"
                                ]);
                    })),
              hoverStyle: props.hoverStyle,
              focusStyle: props.focusStyle,
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              children: Caml_option.some(props.children),
              onPress: Core__Option.flatMap(onPress, (function (onPress) {
                      if (!disabled) {
                        return (function ($$event) {
                                  onPress($$event);
                                });
                      }
                      
                    })),
              onHoverIn: props.onHoverIn,
              onHoverOut: props.onHoverOut,
              onLongPress: props.onLongPress,
              unstyled: true,
              cur: Core__Option.isSome(onPress) && !disabled ? "pointer" : undefined,
              bc: Core__Option.flatMap(style$1, (function (s) {
                      return s.backgroundColor;
                    })),
              boc: Core__Option.getOr(Core__Option.flatMap(style$1, (function (s) {
                          return s.borderColor;
                        })), Primitives__Styling.color("transparent")),
              bw: Core__Option.getOr(Core__Option.flatMap(style$1, (function (s) {
                          return s.borderWidth;
                        })), 1),
              focusable: Core__Option.getOr(props.focusable, Core__Option.isSome(onPress) ? true : false)
            });
}

var make = Primitives__UnstyledButton;

export {
  Focused ,
  make ,
}
/* style Not a pure module */
