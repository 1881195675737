// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../../relay-utils/src/RelayUtils.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Table__Mutations from "../../table/Spaces__Table__Mutations.bs.js";
import * as Spaces__Ui__SimpleOneInputModal from "../simpleOneInputModal/Spaces__Ui__SimpleOneInputModal.bs.js";

function Spaces__Ui__CreateTableModal(props) {
  var spaceId = props.spaceId;
  var onCompleted = props.onCompleted;
  var match = Spaces__Table__Mutations.useCreate();
  var createTable = match[0];
  return JsxRuntime.jsx(Spaces__Ui__SimpleOneInputModal.make, {
              show: props.show,
              onClose: props.onClose,
              onButtonPress: (function (value, onError, onSuccess) {
                  createTable(spaceId, value, (function (tableId, viewId) {
                          onCompleted(viewId, tableId);
                          onSuccess();
                        }), onError, (function (store, tableId) {
                          RelayUtils.Helpers.addToArray(store, spaceId, tableId, "tables");
                        }));
                }),
              label: "New table name",
              placeholder: "My table",
              loading: match[1],
              initialValue: "New table",
              title: "Add table"
            });
}

var make = Spaces__Ui__CreateTableModal;

export {
  make ,
}
/* RelayUtils Not a pure module */
