// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as DateTime from "../../../date-time/src/DateTime.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrDatepicker from "../../../datepicker/src/DecidrDatepicker.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__Block__Common from "./Spaces__Block__Common.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";

function Spaces__Block__Date(props) {
  var initialValue = props.value;
  var name = props.name;
  var toast = Primitives__DecidrToast.use();
  var match = React.useState(function () {
        return Core__Option.flatMap(initialValue, DateTime.parseIsoStringOpt);
      });
  var setDate = match[1];
  var match$1 = Spaces__Cells__Mutations.useUpdateCell(props.rowId, props.columnId, props.columnViewId);
  var updateCell = match$1[0];
  var handleError = React.useCallback((function () {
          toast.error("Error saving " + name + " cell", undefined);
        }), [name]);
  var handleOnChange = function (newDate) {
    setDate(function (param) {
          return Caml_option.some(newDate);
        });
    toast.hide();
    updateCell(DateTime.toIsoString(newDate), (function (param) {
            handleError();
          }), undefined);
  };
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                alignItems: "center"
              },
              children: [
                JsxRuntime.jsx(DecidrDatepicker.make, {
                      value: match[0],
                      onChange: handleOnChange
                    }),
                ReactUtils__SimpleReact.whenTrue(match$1[1], {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Spaces__Block__Common.SavingIndicator.make, {});
                        })
                    })
              ]
            });
}

var make = Spaces__Block__Date;

export {
  make ,
}
/* react Not a pure module */
