// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__Time from "../../../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import LodashDebounce from "lodash.debounce";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Search from "../../../../primitives/src/search/Primitives__Search.bs.js";
import * as Spaces__Ui__Shared from "../shared/Spaces__Ui__Shared.bs.js";
import * as DecidrErrorBoundary from "../../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Spinner from "../../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Primitives__Error__Message from "../../../../primitives/src/error/message/Primitives__Error__Message.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesUiPlatformSpacePickerQuery_graphql from "../../../../relay-codegen/generated/SpacesUiPlatformSpacePickerQuery_graphql.bs.js";
import * as SpacesUiPlatformSpacePickerBase_query_graphql from "../../../../relay-codegen/generated/SpacesUiPlatformSpacePickerBase_query_graphql.bs.js";
import * as SpacesUiPlatformSpacePickerSpaces_platform_graphql from "../../../../relay-codegen/generated/SpacesUiPlatformSpacePickerSpaces_platform_graphql.bs.js";
import * as SpacesUiPlatformSpacePickerSpacesPaginatedQuery_graphql from "../../../../relay-codegen/generated/SpacesUiPlatformSpacePickerSpacesPaginatedQuery_graphql.bs.js";

var convertFragment = SpacesUiPlatformSpacePickerSpaces_platform_graphql.Internal.convertFragment;

var makeRefetchVariables = SpacesUiPlatformSpacePickerSpacesPaginatedQuery_graphql.Types.makeRefetchVariables;

var convertRefetchVariables = SpacesUiPlatformSpacePickerSpacesPaginatedQuery_graphql.Internal.convertVariables;

function usePagination(fRef) {
  return RescriptRelay_Fragment.usePaginationFragment(SpacesUiPlatformSpacePickerSpaces_platform_graphql.node, fRef, convertFragment, convertRefetchVariables);
}

function useSearch(refetch) {
  var match = React.useTransition();
  var startTransition = match[1];
  var handleSearch = function (searchTerm, onSearchStarted) {
    var searchTerm$1 = searchTerm.trim();
    Core__Option.forEach(onSearchStarted, (function (onSearchStarted) {
            onSearchStarted();
          }));
    startTransition(function () {
          refetch(makeRefetchVariables(undefined, undefined, undefined, Caml_option.some(searchTerm$1)), "store-and-network", undefined);
        });
  };
  return {
          searching: match[0],
          handleSearch: handleSearch
        };
}

function Spaces__Ui__PlatformSpacePicker$Spaces$SearchBox(props) {
  var search = props.search;
  var match = Primitives__Input.use("", undefined, undefined);
  var onChange = match.onChange;
  var value = match.value;
  var performSearch = React.useCallback(LodashDebounce((function (searchTerm) {
              search(searchTerm);
            }), Utils__Time.secondInMs), [search]);
  var handleOnChange = function (searchTerm) {
    onChange(searchTerm);
    performSearch(searchTerm);
  };
  var handleClear = function () {
    handleOnChange("");
  };
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                paddingBottom: Caml_option.some(Primitives__Styling.space("cs_2"))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Search.make, {
                        value: value,
                        onChange: handleOnChange,
                        placeholder: "Search spaces...",
                        onClear: handleClear,
                        searching: props.searching,
                        showClearButton: value !== ""
                      }))
            });
}

function Spaces__Ui__PlatformSpacePicker$Spaces(props) {
  var onSelectSpace = props.onSelectSpace;
  var match = usePagination(props.platformRef);
  var isLoadingNext = match.isLoadingNext;
  var loadNext = match.loadNext;
  var match$1 = useSearch(match.refetch);
  var handleSearch = match$1.handleSearch;
  return JsxRuntime.jsxs(Spaces__Ui__Shared.SimpleListContainer.make, {
              children: [
                JsxRuntime.jsx(Spaces__Ui__PlatformSpacePicker$Spaces$SearchBox, {
                      searching: match$1.searching,
                      search: (function (searchTerm) {
                          handleSearch(searchTerm, undefined);
                        })
                    }),
                match.data.spaces.edges.map(function (param) {
                      var match = param.node;
                      var name = match.name;
                      var id = match.id;
                      return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListItem.make, {
                                  onPress: (function () {
                                      onSelectSpace(id, name);
                                    }),
                                  title: name
                                }, DecidrNodeId.toString(id));
                    }),
                ReactUtils__SimpleReact.whenTrue(match.hasNext, {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Primitives__Group.make, {
                                      style: {
                                        alignItems: "center",
                                        paddingBottom: Caml_option.some(Primitives__Styling.space("cs_3"))
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Primitives__Button.make, {
                                                title: "Load more",
                                                onPress: (function (_e) {
                                                    loadNext(20, undefined);
                                                  }),
                                                size: "small",
                                                busy: isLoadingNext
                                              }))
                                    });
                        })
                    })
              ]
            });
}

var convertFragment$1 = SpacesUiPlatformSpacePickerBase_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesUiPlatformSpacePickerBase_query_graphql.node, convertFragment$1, fRef);
}

function Spaces__Ui__PlatformSpacePicker$Base(props) {
  var match = use(props.queryRef);
  var node = match.node;
  if (node !== undefined) {
    if (node.__typename === "Platform") {
      return JsxRuntime.jsx(Spaces__Ui__PlatformSpacePicker$Spaces, {
                  platformRef: node.fragmentRefs,
                  onSelectSpace: props.onSelectSpace
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a Platform", "Spaces__Ui__PlatformSpacePicker Base", {
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Ui__PlatformSpacePicker Base", undefined);
  return null;
}

var convertVariables = SpacesUiPlatformSpacePickerQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesUiPlatformSpacePickerQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesUiPlatformSpacePickerQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesUiPlatformSpacePickerQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiPlatformSpacePickerQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiPlatformSpacePickerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesUiPlatformSpacePickerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesUiPlatformSpacePickerQuery_graphql.node, convertVariables);

function Spaces__Ui__PlatformSpacePicker(props) {
  var match = Auth__Hooks.useAuth();
  var match$1 = use$1({
        platformId: props.platformId,
        skipQuery: match.authStatus !== "authenticated"
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "Spaces__Ui__PlatformSpacePicker Base",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__PlatformSpacePicker$Base, {
                              queryRef: match$1.fragmentRefs,
                              onSelectSpace: props.onSelectSpace
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                              size: "small"
                            }))
                  }),
              FallbackComponent: (function (param) {
                  return JsxRuntime.jsx(Primitives__Error__Message.make, {
                              error: "Error loading spaces"
                            });
                })
            });
}

var Spaces = {
  PlatformFragment: {
    Operation: {
      connectionKey: "SpacesUiPlatformSpacePickerSpaces_platform_spaces"
    }
  }
};

var make = Spaces__Ui__PlatformSpacePicker;

export {
  Spaces ,
  make ,
}
/* use Not a pure module */
