// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";

var context = React.createContext({
      handleRowAction: (function (param, param$1, param$2, param$3) {
          
        })
    });

function use() {
  return React.useContext(context);
}

var make = context.Provider;

var Provider = {
  make: make
};

export {
  use ,
  Provider ,
}
/* context Not a pure module */
