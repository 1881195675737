// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSDateTime from "../../relay-appsync/src/RelayAppSync__AWSDateTime.bs.js";

var Types = {};

var variablesConverter = {"mobileScreenInput":{"width":{"b":""},"height":{"b":""}},"mobileNetworkInput":{},"mobileContextInput":{"screen":{"r":"mobileScreenInput"},"os":{"r":"mobileOSInput"},"network":{"r":"mobileNetworkInput"},"device":{"r":"mobileDeviceInput"},"app":{"r":"mobileAppInput"}},"webNetworkInput":{},"webContextInput":{"screen":{"r":"webScreenInput"},"network":{"r":"webNetworkInput"}},"mobileAppInput":{},"pageViewEventInput":{},"webScreenInput":{"width":{"b":""},"height":{"b":""}},"eventPropertiesInput":{"readMessage":{"r":"readMessageEventInput"},"pageView":{"r":"pageViewEventInput"},"identity":{"r":"identifyEventInput"}},"mobileDeviceInput":{},"eventsInput":{"events":{"r":"eventInput"}},"eventInput":{"userId":{"c":"DecidrNodeId.RelayAppSync"},"timestamp":{"c":"RelayAppSync.AWSDateTime"},"properties":{"r":"eventPropertiesInput"},"eventId":{"c":"DecidrNodeId.RelayAppSync"},"context":{"r":"eventContextInput"},"anonymousId":{"c":"DecidrNodeId.RelayAppSync"}},"mobileOSInput":{},"readMessageEventInput":{"timeSpent":{"b":""},"messageId":{"c":"DecidrNodeId.RelayAppSync"}},"identifyEventInput":{"userId":{"c":"DecidrNodeId.RelayAppSync"},"id":{"c":"DecidrNodeId.RelayAppSync"}},"eventContextInput":{"webContext":{"r":"webContextInput"},"mobileContext":{"r":"mobileContextInput"}},"__root":{"input":{"r":"eventsInput"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize,
  "RelayAppSync.AWSDateTime": RelayAppSync__AWSDateTime.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"track_eventIds":{"ca":"DecidrNodeId.RelayAppSync"}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"track_eventIds":{"ca":"DecidrNodeId.RelayAppSync"}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var wrapRawResponseConverter = {"__root":{"track_eventIds":{"ca":"DecidrNodeId.RelayAppSync"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"track_eventIds":{"ca":"DecidrNodeId.RelayAppSync"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

function eventType_decode($$enum) {
  if ($$enum === "PageView" || $$enum === "Identify" || $$enum === "ReadMessage") {
    return $$enum;
  }
  
}

function eventType_fromString(str) {
  return eventType_decode(str);
}

var Utils = {
  eventType_decode: eventType_decode,
  eventType_fromString: eventType_fromString
};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "input",
        "variableName": "input"
      }
    ],
    "concreteType": "EventsPayload",
    "kind": "LinkedField",
    "name": "track",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "eventIds",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "AppAnalyticsTrackMutation_trackMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "AppAnalyticsTrackMutation_trackMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "7a0032176b6680e5d321cea6391ad8ad",
    "id": null,
    "metadata": {},
    "name": "AppAnalyticsTrackMutation_trackMutation",
    "operationKind": "mutation",
    "text": "mutation AppAnalyticsTrackMutation_trackMutation(\n  $input: EventsInput!\n) {\n  track(input: $input) {\n    eventIds\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
