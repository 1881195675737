// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Blocks__Image from "@pageblocks/blocks/src/Blocks__Image.bs.js";
import * as Blocks__Index from "@pageblocks/blocks/src/Blocks__Index.bs.js";
import * as Renderer__Tree from "@pageblocks/renderer/src/Renderer__Tree.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DecidrRenderer__TextBlock from "../blocks/DecidrRenderer__TextBlock.bs.js";

var exampleTree_block = {
  meta: {
    version: Blocks__Image.version
  },
  code: Blocks__Image.blockCode,
  settings: {
    spacing: 20,
    maxWidth: 400,
    height: 250,
    alignItems: "center",
    justifyContent: "center",
    backgroundImage: "https://randomwordgenerator.com/img/picture-generator/54e7dc4b4c50b10ff3d8992cc12c30771037dbf85254794075277ad39049_640.jpg",
    backgroundImageOpacity: 0.6,
    backgroundColor: "black",
    borderRadius: 12
  },
  triggerActions: null
};

var exampleTree_children = [
  {
    id: "3",
    label: "3",
    block: {
      meta: {
        version: Blocks__Image.version
      },
      code: Blocks__Image.blockCode,
      settings: {
        backgroundColor: "transparent",
        width: 80,
        height: 80,
        backgroundImage: "https://cdn.shopify.com/s/files/1/0620/5544/8775/files/moon-fill.png?v=1654245858"
      },
      triggerActions: null
    },
    children: []
  },
  {
    id: "4",
    label: "4",
    block: {
      meta: {
        version: DecidrRenderer__TextBlock.version
      },
      code: DecidrRenderer__TextBlock.blockCode,
      settings: {
        text: "Welcome to Decidr 👋",
        color: "white",
        kind: "subtitle2",
        fontWeight: "700"
      },
      triggerActions: null
    }
  }
];

var exampleTree = {
  id: "root",
  label: "root",
  block: exampleTree_block,
  children: exampleTree_children
};

function DecidrRenderer__Renderer(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  return JsxRuntime.jsx(Renderer__Tree.Renderer.make, {
              node: props.node,
              context: context,
              getBlock: Blocks__Index.getBlock,
              handleAction: Core__Option.map(props.handleAction, (function (handleAction) {
                      return function (param, param$1, action) {
                        handleAction(action);
                      };
                    }))
            });
}

var make = DecidrRenderer__Renderer;

export {
  exampleTree ,
  make ,
}
/* Blocks__Image Not a pure module */
