// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"viewType_id":{"c":"DecidrNodeId.RelayAppSync"},"rowCount":{"b":""},"id":{"c":"DecidrNodeId.RelayAppSync"},"coreConfig":{"c":"RelayAppSync.AWSJSON"},"columnViews_id":{"c":"DecidrNodeId.RelayAppSync"},"columnViews_componentType_id":{"c":"DecidrNodeId.RelayAppSync"},"columnViews_columnId":{"c":"DecidrNodeId.RelayAppSync"},"columnViews":{"f":""}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = [
  (v0/*: any*/),
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "code",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerColumnViewId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerRowId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerViewId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesBlockMultiSelect_view",
  "selections": [
    (v0/*: any*/),
    {
      "alias": null,
      "args": null,
      "concreteType": "ColumnView",
      "kind": "LinkedField",
      "name": "columnViews",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SpacesCache_columnView"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "order",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHidden",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "columnId",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ComponentType",
          "kind": "LinkedField",
          "name": "componentType",
          "plural": false,
          "selections": (v1/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewType",
      "args": null,
      "concreteType": "ViewType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": (v1/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "fields": [
            {
              "kind": "Variable",
              "name": "columnViewId",
              "variableName": "callerColumnViewId"
            },
            {
              "kind": "Variable",
              "name": "rowId",
              "variableName": "callerRowId"
            },
            {
              "kind": "Variable",
              "name": "viewId",
              "variableName": "callerViewId"
            }
          ],
          "kind": "ObjectValue",
          "name": "caller"
        }
      ],
      "kind": "ScalarField",
      "name": "rowCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coreConfig",
      "storageKey": null
    }
  ],
  "type": "View",
  "abstractKey": null
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
