// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_int32 from "rescript/lib/es6/caml_int32.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as ReactRelay from "react-relay";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrCellId from "../../../cell-id/src/DecidrCellId.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__Cells from "../cells/Spaces__Cells.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__ScrollView from "../../../primitives/src/scrollView/Primitives__ScrollView.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Primitives__DecidrImage from "../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as Spaces__Ui__ImagePreview from "../ui/imagePreview/Spaces__Ui__ImagePreview.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as Spaces__ComponentType__Code from "../componentType/Spaces__ComponentType__Code.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesBlockLookupCellQuery_graphql from "../../../relay-codegen/generated/SpacesBlockLookupCellQuery_graphql.bs.js";
import * as SpacesBlockLookup_lookupCell_graphql from "../../../relay-codegen/generated/SpacesBlockLookup_lookupCell_graphql.bs.js";
import * as SpacesBlockLookupRefetchQuery_graphql from "../../../relay-codegen/generated/SpacesBlockLookupRefetchQuery_graphql.bs.js";
import * as SpacesBlockLookupRefetchableData_query_graphql from "../../../relay-codegen/generated/SpacesBlockLookupRefetchableData_query_graphql.bs.js";
import * as SpacesBlockLookupPrimitiveColumnViewQuery_graphql from "../../../relay-codegen/generated/SpacesBlockLookupPrimitiveColumnViewQuery_graphql.bs.js";
import * as SpacesBlockLookupPrimitiveColumnView_query_graphql from "../../../relay-codegen/generated/SpacesBlockLookupPrimitiveColumnView_query_graphql.bs.js";

function Spaces__Block__Lookup$ViewFullTextModal(props) {
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Primitives__Modal.LongContentWebFix.make, {
                    children: JsxRuntime.jsxs(Primitives__Group.make, {
                          gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                          style: {
                            backgroundColor: Primitives__Styling.color({
                                  NAME: "surface",
                                  VAL: "default"
                                }),
                            flex: 1
                          },
                          children: [
                            JsxRuntime.jsx(Primitives__DecidrText.make, {
                                  style: {
                                    fontWeight: 500,
                                    paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3"))
                                  },
                                  kind: "body1",
                                  children: "Full text viewer"
                                }),
                            JsxRuntime.jsx(Primitives__ScrollView.make, {
                                  style: {
                                    borderColor: Primitives__Styling.color({
                                          NAME: "border",
                                          VAL: {
                                            NAME: "neutral",
                                            VAL: "default"
                                          }
                                        }),
                                    borderTopWidth: 1
                                  },
                                  children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                            gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                                            style: {
                                              backgroundColor: Primitives__Styling.color({
                                                    NAME: "surface",
                                                    VAL: "default"
                                                  }),
                                              flex: 1,
                                              padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                                            },
                                            children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                      children: Caml_option.some(props.text)
                                                    }))
                                          }))
                                })
                          ]
                        })
                  }),
              show: props.show,
              onClose: props.onClose,
              maxWidth: 600
            });
}

var convertFragment = SpacesBlockLookup_lookupCell_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockLookup_lookupCell_graphql.node, convertFragment, fRef);
}

function Spaces__Block__Lookup$Data$OptionalTextDisplay(props) {
  var text = props.text;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var tmp;
  var exit = 0;
  if (text !== undefined && text !== "") {
    tmp = JsxRuntime.jsx(Primitives__DecidrText.make, {
          children: Caml_option.some(text),
          numberOfLines: 3
        });
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Primitives__DecidrText.make, {
          style: {
            fontStyle: "italic"
          },
          children: "Empty"
        });
  }
  var tmp$1;
  tmp$1 = text !== undefined && text !== "" ? (function (param) {
        setShow(function (param) {
              return true;
            });
      }) : undefined;
  var tmp$2;
  tmp$2 = text !== undefined && text !== "" ? JsxRuntime.jsx(Spaces__Block__Lookup$ViewFullTextModal, {
          text: text,
          show: match[0],
          onClose: (function () {
              setShow(function (param) {
                    return false;
                  });
            })
        }) : null;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                      children: JsxRuntime.jsx(Primitives__Group.make, {
                            style: {
                              backgroundColor: Primitives__Styling.color({
                                    NAME: "background",
                                    VAL: "default"
                                  }),
                              borderColor: Primitives__Styling.color({
                                    NAME: "border",
                                    VAL: {
                                      NAME: "neutral",
                                      VAL: "default"
                                    }
                                  }),
                              borderRadius: Primitives__Styling.radius("rad_3"),
                              borderWidth: 1,
                              padding: Caml_option.some(Primitives__Styling.space("cs_2"))
                            },
                            children: Caml_option.some(tmp)
                          }),
                      onPress: tmp$1
                    }),
                tmp$2
              ]
            });
}

function Spaces__Block__Lookup$Data$OptionalImageDisplay(props) {
  var image = props.image;
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  if (image === undefined) {
    return JsxRuntime.jsx(Primitives__Group.make, {
                style: {
                  backgroundColor: Primitives__Styling.color({
                        NAME: "background",
                        VAL: "default"
                      }),
                  borderRadius: Primitives__Styling.radiusDp(8),
                  alignItems: "center",
                  height: Caml_option.some(Primitives__Styling.dp(100)),
                  justifyContent: "center",
                  width: Caml_option.some(Primitives__Styling.dp(100))
                },
                children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                          style: {
                            textAlign: "center"
                          },
                          children: "No image"
                        }))
              });
  }
  var match$1 = image.size;
  var height = match$1.height;
  var width = match$1.width;
  var url = image.url;
  var size = width > height ? ({
        TAG: "Rect",
        width: Primitives__Styling.dp(Caml_int32.div(width, height) * 100),
        height: Primitives__Styling.dp(100)
      }) : (
      height > width ? ({
            TAG: "Rect",
            width: Primitives__Styling.dp(Caml_int32.div(width, height) * 100),
            height: Primitives__Styling.dp(100)
          }) : ({
            TAG: "Square",
            _0: Primitives__Styling.dp(100)
          })
    );
  var aspectRatio = width / height;
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                      children: JsxRuntime.jsx(Primitives__DecidrImage.make, {
                            src: url,
                            size: size,
                            style: {
                              borderRadius: Primitives__Styling.radiusDp(8)
                            }
                          }),
                      onPress: (function (param) {
                          setShow(function (param) {
                                return true;
                              });
                        })
                    }),
                JsxRuntime.jsx(Spaces__Ui__ImagePreview.make, {
                      url: url,
                      width: 400,
                      height: 400 / aspectRatio,
                      show: match[0],
                      onClose: (function () {
                          setShow(function (param) {
                                return false;
                              });
                        })
                    })
              ]
            });
}

function Spaces__Block__Lookup$Data$CellDataDisplay(props) {
  var cell = props.cell;
  switch (cell.TAG) {
    case "Text" :
    case "ReadOnlyDate" :
        return JsxRuntime.jsx(Spaces__Block__Lookup$Data$OptionalTextDisplay, {
                    text: cell._0
                  });
    case "Number" :
        return JsxRuntime.jsx(Spaces__Block__Lookup$Data$OptionalTextDisplay, {
                    text: Core__Option.map(cell._0, (function (prim) {
                            return prim.toString();
                          }))
                  });
    case "Switch" :
        return JsxRuntime.jsx(Spaces__Block__Lookup$Data$OptionalTextDisplay, {
                    text: Core__Option.map(cell._0, (function (switched) {
                            if (switched) {
                              return "on";
                            } else {
                              return "off";
                            }
                          }))
                  });
    case "Image" :
        return JsxRuntime.jsx(Spaces__Block__Lookup$Data$OptionalImageDisplay, {
                    image: cell._0
                  });
    case "File" :
        return JsxRuntime.jsx(Spaces__Block__Lookup$Data$OptionalTextDisplay, {
                    text: Core__Option.map(cell._0, (function (file) {
                            return file.url;
                          }))
                  });
    case "Rollup" :
        return null;
    default:
      ErrorHandling__UnexpectedError.handle("Not a lookup cell data type", "Spaces__Block__Lookup CellDataDisplay", {
            cell: cell
          });
      return null;
  }
}

function Spaces__Block__Lookup$Data(props) {
  var primitiveComponentTypeCode = props.primitiveComponentTypeCode;
  var match = use(props.lookupCellRef);
  var cells = match.data.provisionedCells.map(function (param) {
        return Spaces__Cells.CellFragment.readInline(param.cell.fragmentRefs);
      });
  var cellsData = Core__Array.filterMap(cells, (function (cell) {
          var cellInfo = Spaces__Cells.getCellSignature(cell);
          return Core__Option.map(cellInfo, (function (cellInfo) {
                        return [
                                DecidrCellId.make(cellInfo.rowId, cellInfo.columnId),
                                Spaces__Row__Utils.makeBlockData(cell, primitiveComponentTypeCode, undefined, undefined, DecidrNodeId.empty, cellInfo.rowId, cellInfo.columnId)
                              ];
                      }));
        }));
  var isText = cells.every(function (cell) {
        if (cell.__typename === "TextCell") {
          return true;
        } else {
          return false;
        }
      });
  var content = JsxRuntime.jsx(Primitives__Group.make, {
        gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
        orientation: isText ? "vertical" : "horizontal",
        children: Caml_option.some(cellsData.map(function (param, index) {
                  var cell = param[1];
                  var id = param[0];
                  if (cell.TAG === "Ok") {
                    return JsxRuntime.jsx(Spaces__Block__Lookup$Data$CellDataDisplay, {
                                cell: cell._0
                              }, DecidrCellId.toString(id) + "-" + index.toString());
                  }
                  ErrorHandling__UnexpectedError.handle("Error parsing cell data", "Spaces__Block__Lookup Data", {
                        message: cell._0
                      });
                  return JsxRuntime.jsx(Primitives__DecidrText.make, {
                              children: "Error"
                            }, DecidrCellId.toString(id) + "-" + index.toString());
                }))
      });
  if (isText) {
    return content;
  } else {
    return JsxRuntime.jsx(Primitives__ScrollView.make, {
                horizontal: true,
                children: Caml_option.some(content)
              });
  }
}

function Spaces__Block__Lookup$Data$Loading(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1,
                padding: Caml_option.some(Primitives__Styling.dp(6))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                        size: "small"
                      }))
            });
}

var Loading = {
  make: Spaces__Block__Lookup$Data$Loading
};

var convertFragment$1 = SpacesBlockLookupRefetchableData_query_graphql.Internal.convertFragment;

var convertRefetchVariables = SpacesBlockLookupRefetchQuery_graphql.Internal.convertVariables;

function useRefetchable(fRef) {
  return RescriptRelay_Fragment.useRefetchableFragment(SpacesBlockLookupRefetchableData_query_graphql.node, convertFragment$1, convertRefetchVariables, fRef);
}

var convertFragment$2 = SpacesBlockLookupPrimitiveColumnView_query_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockLookupPrimitiveColumnView_query_graphql.node, convertFragment$2, fRef);
}

function Spaces__Block__Lookup$LookupRefetchableData(props) {
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var match = useRefetchable(props.cellQueryRef);
  var refetch = match[1];
  var data = match[0];
  var match$1 = use$1(props.columnViewQueryFragment);
  var node = match$1.node;
  ReactRelay.useSubscribeToInvalidationState([data.cell.__id], (function () {
          var match = data.cell.cell;
          if (match !== undefined && match.__typename !== "LookupCell") {
            return ErrorHandling__UnexpectedError.handle("Not a lookup cell", "Spaces__Block__Lookup useSubscribeToInvalidationState", {
                        typename: match._0
                      });
          }
          refetch({
                columnId: Caml_option.some(DecidrNodeId.magicallyFromInternalId(callerColumnId)),
                rowId: Caml_option.some(DecidrNodeId.magicallyFromInternalId(callerRowId))
              }, undefined, undefined);
        }));
  var componentTypeCode = node !== undefined && node.__typename === "ColumnView" ? Spaces__ComponentType__Code.fromString(node.componentType.code) : undefined;
  var match$2 = data.cell.cell;
  if (match$2 !== undefined) {
    if (match$2.__typename === "LookupCell") {
      if (componentTypeCode !== undefined) {
        return JsxRuntime.jsx(Spaces__Block__Lookup$Data, {
                    lookupCellRef: match$2.fragmentRefs,
                    primitiveComponentTypeCode: componentTypeCode
                  });
      }
      var tmp;
      tmp = node !== undefined && node.__typename === "ColumnView" ? node.componentType.code : undefined;
      ErrorHandling__UnexpectedError.handle("Unsupported component type", "Spaces__Block__Lookup", {
            componentTypeCode: tmp
          });
      return null;
    }
    ErrorHandling__UnexpectedError.handle("Not a lookup cell", "Spaces__Block__Lookup", {
          typename: match$2._0
        });
    return null;
  }
  if (componentTypeCode !== undefined) {
    ErrorHandling__UnexpectedError.handle("No cell found", "Spaces__Block__Lookup", {
          callerRowId: callerRowId,
          callerColumnId: callerColumnId
        });
    return null;
  }
  var tmp$1;
  tmp$1 = node !== undefined && node.__typename === "ColumnView" ? node.componentType.code : undefined;
  ErrorHandling__UnexpectedError.handle("No cell found and unsupported component type", "Spaces__Block__Lookup useSubscribeToInvalidationState", {
        callerRowId: callerRowId,
        callerColumnId: callerColumnId,
        componentTypeCode: tmp$1
      });
  return null;
}

var convertVariables = SpacesBlockLookupCellQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockLookupCellQuery_graphql.Internal.convertResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockLookupCellQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockLookupCellQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockLookupCellQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockLookupCellQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockLookupCellQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockLookupCellQuery_graphql.node, convertVariables);

var convertVariables$1 = SpacesBlockLookupPrimitiveColumnViewQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockLookupPrimitiveColumnViewQuery_graphql.Internal.convertResponse;

var use$3 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockLookupPrimitiveColumnViewQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockLookupPrimitiveColumnViewQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockLookupPrimitiveColumnViewQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockLookupPrimitiveColumnViewQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesBlockLookupPrimitiveColumnViewQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesBlockLookupPrimitiveColumnViewQuery_graphql.node, convertVariables$1);

function Spaces__Block__Lookup(props) {
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var match = use$2({
        columnId: DecidrNodeId.magicallyFromInternalId(callerColumnId),
        rowId: DecidrNodeId.magicallyFromInternalId(callerRowId)
      }, undefined, undefined, undefined);
  var match$1 = use$3({
        primitiveColumnViewId: props.primitiveColumnViewId
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(Spaces__Block__Lookup$LookupRefetchableData, {
              cellQueryRef: match.fragmentRefs,
              columnViewQueryFragment: match$1.fragmentRefs,
              callerRowId: callerRowId,
              callerColumnId: callerColumnId
            });
}

var Data = {
  Loading: Loading
};

var make = Spaces__Block__Lookup;

export {
  Data ,
  make ,
}
/* use Not a pure module */
