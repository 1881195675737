// Generated by ReScript, PLEASE EDIT WITH CARE

import * as ErrorReporting from "../../error-reporting/src/ErrorReporting.bs.js";

var debugPrefix = "[UnexpectedError]";

function handle(message, debugContext, extraData) {
  ErrorReporting.captureErrorWithExtraData(message, debugPrefix + " " + debugContext, extraData);
  console.log(debugPrefix, debugContext, message, extraData);
}

export {
  handle ,
}
/* ErrorReporting Not a pure module */
