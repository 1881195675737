// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as Style$ReactNative from "rescript-react-native/src/apis/Style.bs.js";
import * as TamaguiConfig__Tokens from "../../../tamagui-config/src/tokens/TamaguiConfig__Tokens.bs.js";
import * as Utils from "@decidr/tamagui-config/src/utils";

var empty = {};

function dp(prim) {
  return prim;
}

function space(space$1) {
  return "$" + space$1;
}

var makeSpecificStyles = TamaguiConfig.Utils.makeSpecificStyles;

function token(radius) {
  return "$" + radius;
}

function dp$1(x) {
  return x;
}

var Radius = {
  token: token,
  dp: dp$1
};

function omitStyleKeys(style, styleKeys) {
  return Object.fromEntries(Object.entries(style).filter(function (param) {
                  return !styleKeys.includes(param[0]);
                }));
}

var Utils$1 = {
  omitStyleKeys: omitStyleKeys
};

function makeIconSizeToken(iconSize, breakpoint) {
  return "$" + TamaguiConfig__Tokens.IconSizes.makeIconSizesToken(iconSize, breakpoint);
}

function withDisplayNone(style, $$with) {
  if (!$$with) {
    return style;
  }
  var newrecord = Caml_obj.obj_dup(Core__Option.getOr(style, empty));
  return newrecord.display = "none", newrecord;
}

var color = TamaguiConfig__Tokens.Colors.token;

var colorValue = TamaguiConfig__Tokens.Colors.value;

var pct = Style$ReactNative.pct;

function token$1(prim) {
  return prim;
}

function getSizeTokenValue(prim) {
  return Utils.getSizeTokenValue(prim);
}

function Gap_toRNGap(prim) {
  return prim;
}

var Gap = {
  token: space,
  dp: dp,
  toRNGap: Gap_toRNGap
};

var radius = token;

var radiusDp = dp$1;

var gap = space;

var gapDp = dp;

var FontSize = {
  token: (function (prim) {
      return prim;
    })
};

export {
  empty ,
  dp ,
  pct ,
  token$1 as token,
  getSizeTokenValue ,
  space ,
  makeSpecificStyles ,
  Gap ,
  Radius ,
  radius ,
  radiusDp ,
  gap ,
  gapDp ,
  Utils$1 as Utils,
  makeIconSizeToken ,
  withDisplayNone ,
  color ,
  colorValue ,
  FontSize ,
}
/* TamaguiConfig Not a pure module */
