// Generated by ReScript, PLEASE EDIT WITH CARE


function unsafe(param) {
  
}

function promise(param) {
  
}

function promiseWithPayload(param) {
  
}

function resolveWithoutPayload(param) {
  return Promise.resolve();
}

export {
  unsafe ,
  promise ,
  promiseWithPayload ,
  resolveWithoutPayload ,
}
/* No side effect */
