// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["View"]);
}

var fragmentConverter = {"__root":{"node_View_id":{"c":"DecidrNodeId.RelayAppSync"},"node_View_coreConfig":{"c":"RelayAppSync.AWSJSON"},"node_View":{"f":""},"node":{"u":"fragment_node"}}};

var fragmentConverterMap = {
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse,
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment_node: unwrap_fragment_node
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerColumnViewId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerRowId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "callerViewId"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "sourceViewId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesBlockMultiSelectInlineSourceViewRows_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "sourceViewId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "coreConfig",
              "storageKey": null
            },
            {
              "args": [
                {
                  "fields": [
                    {
                      "kind": "Variable",
                      "name": "columnViewId",
                      "variableName": "callerColumnViewId"
                    },
                    {
                      "kind": "Variable",
                      "name": "rowId",
                      "variableName": "callerRowId"
                    },
                    {
                      "kind": "Variable",
                      "name": "viewId",
                      "variableName": "callerViewId"
                    }
                  ],
                  "kind": "ObjectValue",
                  "name": "caller"
                }
              ],
              "kind": "FragmentSpread",
              "name": "SpacesViewData_view"
            }
          ],
          "type": "View",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

var wrap_fragment_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_node ,
  wrap_fragment_node ,
  Internal ,
  Utils ,
  node ,
}
/* DecidrNodeId Not a pure module */
