/* TypeScript file generated from TamaguiConfig__Media.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as TamaguiConfig__MediaJS from './TamaguiConfig__Media.bs.js';

export type breakpoint = "s" | "m" | "l" | "xl";

export const breakpoints: breakpoint[] = TamaguiConfig__MediaJS.breakpoints as any;
