// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Blocks__Utils from "@pageblocks/blocks/src/utils/Blocks__Utils.bs.js";
import * as DecidrInternalId from "../../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Icon from "../../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Primitives__Popover from "../../../../primitives/src/popover/Primitives__Popover.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__Block__Date from "../../block/Spaces__Block__Date.bs.js";
import * as Spaces__Block__File from "../../block/Spaces__Block__File.bs.js";
import * as Spaces__Block__Json from "../../block/Spaces__Block__Json.bs.js";
import * as Spaces__Block__Text from "../../block/Spaces__Block__Text.bs.js";
import * as Primitives__Suspense from "../../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Spaces__Block__Image from "../../block/Spaces__Block__Image.bs.js";
import * as Spaces__Block__Common from "../../block/Spaces__Block__Common.bs.js";
import * as Spaces__Block__Lookup from "../../block/Spaces__Block__Lookup.bs.js";
import * as Spaces__Block__Number from "../../block/Spaces__Block__Number.bs.js";
import * as Spaces__Block__Rollup from "../../block/Spaces__Block__Rollup.bs.js";
import * as Spaces__Block__Switch from "../../block/Spaces__Block__Switch.bs.js";
import * as DecidrRenderer__Actions from "../../../../renderer/src/actions/DecidrRenderer__Actions.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Block__SelectView from "../../block/Spaces__Block__SelectView.bs.js";
import * as Spaces__Block__SystemLink from "../../block/Spaces__Block__SystemLink.bs.js";
import * as Spaces__Block__MultiSelect from "../../block/Spaces__Block__MultiSelect.bs.js";
import * as Spaces__Block__RowJsonData from "../../block/Spaces__Block__RowJsonData.bs.js";
import * as Spaces__Ui__BlockMenuItems from "../../ui/blockMenu/Spaces__Ui__BlockMenuItems.bs.js";
import * as RescriptTamagui__Icon__Edit from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Edit.bs.js";
import * as RescriptTamagui__Icon__Text from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Text.bs.js";
import * as Spaces__Block__ReadOnlyDate from "../../block/Spaces__Block__ReadOnlyDate.bs.js";
import * as Spaces__ComponentType__Code from "../../componentType/Spaces__ComponentType__Code.bs.js";
import * as RescriptTamagui__Icon__Image from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Image.bs.js";
import * as Spaces__Block__RowMembership from "../../block/Spaces__Block__RowMembership.bs.js";
import * as RescriptTamagui__Icon__EyeOff from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__EyeOff.bs.js";
import * as RescriptTamagui__Icon__Trash2 from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Trash2.bs.js";
import * as RescriptTamagui__Icon__Heading1 from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Heading1.bs.js";
import * as Spaces__Block__HttpRequestTrigger from "../../block/Spaces__Block__HttpRequestTrigger.bs.js";
import * as Spaces__ColumnView__ChannelOpener from "../../columnView/channelOpener/Spaces__ColumnView__ChannelOpener.bs.js";
import * as Spaces__Row__Renderer__BlockUtils from "./Spaces__Row__Renderer__BlockUtils.bs.js";
import * as Spaces__Block__ColumnCountInsights from "../../block/Spaces__Block__ColumnCountInsights.bs.js";
import * as RescriptTamagui__Icon__MoreVertical from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__MoreVertical.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "./Spaces__Row__Renderer__BlockContext.bs.js";
import * as RescriptTamagui__Icon__ArrowUpFromLine from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__ArrowUpFromLine.bs.js";
import * as Spaces__Block__ExperimentalRelatedList from "../../block/Spaces__Block__ExperimentalRelatedList.bs.js";
import * as RescriptTamagui__Icon__ArrowDownFromLine from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__ArrowDownFromLine.bs.js";

var blockCode = "space-text-editor";

function Spaces__Row__Renderer__Blocks$Text$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Text");
  var text = getString(Spaces__Row__Renderer__BlockContext.$$Text.Keys.text);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Text.make, {
                name: name,
                value: text,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId)
              });
  } else {
    return null;
  }
}

var Component = {
  make: Spaces__Row__Renderer__Blocks$Text$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var blockCode$1 = "space-long-text-editor";

function Spaces__Row__Renderer__Blocks$LongText$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Text");
  var text = getString(Spaces__Row__Renderer__BlockContext.$$Text.Keys.text);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Text.make, {
                name: name,
                value: text,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId),
                multiline: true
              });
  } else {
    return null;
  }
}

var Component$1 = {
  make: Spaces__Row__Renderer__Blocks$LongText$Component
};

var descriptor_meta$1 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$1 = [];

var descriptor_declaredSettings$1 = [];

var descriptor$1 = {
  meta: descriptor_meta$1,
  code: blockCode$1,
  component: Component$1,
  declaredTriggers: descriptor_declaredTriggers$1,
  declaredSettings: descriptor_declaredSettings$1
};

var blockCode$2 = "space-json-editor";

function Spaces__Row__Renderer__Blocks$Json$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Text");
  var text = getString(Spaces__Row__Renderer__BlockContext.$$Text.Keys.text);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Json.make, {
                name: name,
                value: text,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId)
              });
  } else {
    return null;
  }
}

var Component$2 = {
  make: Spaces__Row__Renderer__Blocks$Json$Component
};

var descriptor_meta$2 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$2 = [];

var descriptor_declaredSettings$2 = [];

var descriptor$2 = {
  meta: descriptor_meta$2,
  code: blockCode$2,
  component: Component$2,
  declaredTriggers: descriptor_declaredTriggers$2,
  declaredSettings: descriptor_declaredSettings$2
};

var blockCode$3 = "space-http-request-trigger-editor";

function Spaces__Row__Renderer__Blocks$HttpRequestTrigger$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var viewId = Spaces__Row__Renderer__BlockUtils.getViewId(context);
  if (rowId !== undefined && columnViewId !== undefined && viewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__HttpRequestTrigger.make, {
                rowId: Caml_option.valFromOption(rowId),
                columnViewId: Caml_option.valFromOption(columnViewId),
                viewId: Caml_option.valFromOption(viewId)
              });
  } else {
    return null;
  }
}

var Component$3 = {
  make: Spaces__Row__Renderer__Blocks$HttpRequestTrigger$Component
};

var descriptor_meta$3 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$3 = [];

var descriptor_declaredSettings$3 = [];

var descriptor$3 = {
  meta: descriptor_meta$3,
  code: blockCode$3,
  component: Component$3,
  declaredTriggers: descriptor_declaredTriggers$3,
  declaredSettings: descriptor_declaredSettings$3
};

var blockCode$4 = "space-column-count-insights";

function Spaces__Row__Renderer__Blocks$ColumnCountInsights$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  if (rowId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__ColumnCountInsights.make, {
                columnViewId: Caml_option.valFromOption(columnViewId),
                viewId: DecidrNodeId.make("View", Caml_option.valFromOption(rowId))
              });
  } else {
    return null;
  }
}

var Component$4 = {
  make: Spaces__Row__Renderer__Blocks$ColumnCountInsights$Component
};

var descriptor_meta$4 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$4 = [];

var descriptor_declaredSettings$4 = [];

var descriptor$4 = {
  meta: descriptor_meta$4,
  code: blockCode$4,
  component: Component$4,
  declaredTriggers: descriptor_declaredTriggers$4,
  declaredSettings: descriptor_declaredSettings$4
};

var blockCode$5 = "space-date-editor";

function Spaces__Row__Renderer__Blocks$Date$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Date");
  var date = getString(Spaces__Row__Renderer__BlockContext.$$Date.Keys.date);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Date.make, {
                name: name,
                value: date,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId)
              });
  } else {
    return null;
  }
}

var Component$5 = {
  make: Spaces__Row__Renderer__Blocks$Date$Component
};

var descriptor_meta$5 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$5 = [];

var descriptor_declaredSettings$5 = [];

var descriptor$5 = {
  meta: descriptor_meta$5,
  code: blockCode$5,
  component: Component$5,
  declaredTriggers: descriptor_declaredTriggers$5,
  declaredSettings: descriptor_declaredSettings$5
};

var blockCode$6 = "space-number-editor";

function Spaces__Row__Renderer__Blocks$Number$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var getFloat = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getFloat(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Number");
  var number = getFloat(Spaces__Row__Renderer__BlockContext.$$Number.Keys.number);
  var componentTypeCode = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.componentTypeCode), Spaces__ComponentType__Code.fromString);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Number.make, {
                name: name,
                value: number,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId),
                kind: componentTypeCode !== undefined ? (
                    componentTypeCode === "percent" ? "percent" : (
                        componentTypeCode === "currency" ? "currency" : (
                            componentTypeCode === "number" ? "number" : undefined
                          )
                      )
                  ) : undefined
              });
  } else {
    return null;
  }
}

var Component$6 = {
  make: Spaces__Row__Renderer__Blocks$Number$Component
};

var descriptor_meta$6 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$6 = [];

var descriptor_declaredSettings$6 = [];

var descriptor$6 = {
  meta: descriptor_meta$6,
  code: blockCode$6,
  component: Component$6,
  declaredTriggers: descriptor_declaredTriggers$6,
  declaredSettings: descriptor_declaredSettings$6
};

var blockCode$7 = "space-switch-editor";

function Spaces__Row__Renderer__Blocks$Switch$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var getBool = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getBool(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var switched = getBool(Spaces__Row__Renderer__BlockContext.Switch.Keys.switched);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Switch.make, {
                value: switched,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId)
              });
  } else {
    return null;
  }
}

var Component$7 = {
  make: Spaces__Row__Renderer__Blocks$Switch$Component
};

var descriptor_meta$7 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$7 = [];

var descriptor_declaredSettings$7 = [];

var descriptor$7 = {
  meta: descriptor_meta$7,
  code: blockCode$7,
  component: Component$7,
  declaredTriggers: descriptor_declaredTriggers$7,
  declaredSettings: descriptor_declaredSettings$7
};

var blockCode$8 = "space-related-list";

function Spaces__Row__Renderer__Blocks$RelatedList$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var callerViewId = Spaces__Row__Renderer__BlockUtils.getViewId(context);
  var callerColumnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var callerColumnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var sourceViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.RelatedList.Keys.sourceViewId), DecidrNodeId.fromString);
  var callerRowId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.RelatedList.Keys.callerRowId), DecidrInternalId.fromString);
  var tableLinkInfoId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.RelatedList.Keys.tableLinkInfoId), DecidrInternalId.fromString);
  var onTopRightViewSettingsTriggerActions = Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(props.triggerActions), (function (__x) {
              return __x["onTopRightViewSettings"];
            })), Blocks__Utils.getActions);
  var match = Spaces__Block__Common.makeRelatedListCallbacks(onTopRightViewSettingsTriggerActions, sourceViewId, props.handleAction);
  if (tableLinkInfoId !== undefined && callerRowId !== undefined && callerColumnId !== undefined && sourceViewId !== undefined && callerViewId !== undefined && callerColumnViewId !== undefined) {
    return JsxRuntime.jsx(React.Suspense, {
                children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__ExperimentalRelatedList.make, {
                          sourceViewId: Caml_option.valFromOption(sourceViewId),
                          callerRowId: Caml_option.valFromOption(callerRowId),
                          callerColumnViewId: Caml_option.valFromOption(callerColumnViewId),
                          callerViewId: Caml_option.valFromOption(callerViewId),
                          onSettingsPress: match.onSettingsPress
                        })),
                fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Block__MultiSelect.Data.Loading.make, {}))
              });
  } else {
    return null;
  }
}

var Component$8 = {
  make: Spaces__Row__Renderer__Blocks$RelatedList$Component
};

var descriptor_meta$8 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$8 = [];

var descriptor_declaredSettings$8 = [];

var descriptor$8 = {
  meta: descriptor_meta$8,
  code: blockCode$8,
  component: Component$8,
  declaredTriggers: descriptor_declaredTriggers$8,
  declaredSettings: descriptor_declaredSettings$8
};

var blockCode$9 = "space-lookup";

function Spaces__Row__Renderer__Blocks$Lookup$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var referencedColumnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Lookup.Keys.referencedColumnViewId), DecidrNodeId.fromString);
  var primitiveColumnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Lookup.Keys.primitiveColumnViewId), DecidrNodeId.fromString);
  var tableLinkInfoId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Lookup.Keys.tableLinkInfoId), DecidrInternalId.fromString);
  var callerRowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var callerColumnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  if (tableLinkInfoId !== undefined && referencedColumnViewId !== undefined && primitiveColumnViewId !== undefined && callerRowId !== undefined && callerColumnId !== undefined) {
    return JsxRuntime.jsx(React.Suspense, {
                children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__Lookup.make, {
                          tableLinkInfoId: Caml_option.valFromOption(tableLinkInfoId),
                          referencedColumnViewId: Caml_option.valFromOption(referencedColumnViewId),
                          primitiveColumnViewId: Caml_option.valFromOption(primitiveColumnViewId),
                          callerRowId: Caml_option.valFromOption(callerRowId),
                          callerColumnId: Caml_option.valFromOption(callerColumnId)
                        })),
                fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Block__Lookup.Data.Loading.make, {}))
              });
  } else {
    return null;
  }
}

var Component$9 = {
  make: Spaces__Row__Renderer__Blocks$Lookup$Component
};

var descriptor_meta$9 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$9 = [];

var descriptor_declaredSettings$9 = [];

var descriptor$9 = {
  meta: descriptor_meta$9,
  code: blockCode$9,
  component: Component$9,
  declaredTriggers: descriptor_declaredTriggers$9,
  declaredSettings: descriptor_declaredSettings$9
};

var blockCode$10 = "space-rollup";

function Spaces__Row__Renderer__Blocks$Rollup$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var tableLinkInfoId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Rollup.Keys.tableLinkInfoId), DecidrInternalId.fromString);
  var callerRowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var callerColumnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  if (tableLinkInfoId !== undefined && callerRowId !== undefined && callerColumnId !== undefined) {
    return JsxRuntime.jsx(React.Suspense, {
                children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__Rollup.make, {
                          tableLinkInfoId: Caml_option.valFromOption(tableLinkInfoId),
                          callerRowId: Caml_option.valFromOption(callerRowId),
                          callerColumnId: Caml_option.valFromOption(callerColumnId)
                        })),
                fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Block__Rollup.Data.Loading.make, {}))
              });
  } else {
    return null;
  }
}

var Component$10 = {
  make: Spaces__Row__Renderer__Blocks$Rollup$Component
};

var descriptor_meta$10 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$10 = [];

var descriptor_declaredSettings$10 = [];

var descriptor$10 = {
  meta: descriptor_meta$10,
  code: blockCode$10,
  component: Component$10,
  declaredTriggers: descriptor_declaredTriggers$10,
  declaredSettings: descriptor_declaredSettings$10
};

var blockCode$11 = "space-read-only-date";

function Spaces__Row__Renderer__Blocks$ReadOnlyDate$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var date = getString(Spaces__Row__Renderer__BlockContext.ReadOnlyDate.Keys.date);
  return JsxRuntime.jsx(Spaces__Block__ReadOnlyDate.make, {
              value: date
            });
}

var Component$11 = {
  make: Spaces__Row__Renderer__Blocks$ReadOnlyDate$Component
};

var descriptor_meta$11 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$11 = [];

var descriptor_declaredSettings$11 = [];

var descriptor$11 = {
  meta: descriptor_meta$11,
  code: blockCode$11,
  component: Component$11,
  declaredTriggers: descriptor_declaredTriggers$11,
  declaredSettings: descriptor_declaredSettings$11
};

var blockCode$12 = "space-multi-select";

function Spaces__Row__Renderer__Blocks$MultiSelect$Component(props) {
  var triggerActions = props.triggerActions;
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var callerRowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var callerViewId = Spaces__Row__Renderer__BlockUtils.getViewId(context);
  var callerColumnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var callerColumnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var sourceViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.MultiSelect.Keys.sourceViewId), DecidrNodeId.fromString);
  var onTopRightViewSettingsTriggerActions = Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
              return __x["onTopRightViewSettings"];
            })), Blocks__Utils.getActions);
  var onTopRightLinkTriggerActions = Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
              return __x["onTopRightLink"];
            })), Blocks__Utils.getActions);
  var match = Spaces__Block__Common.makeMultiSelectCallbacks(onTopRightViewSettingsTriggerActions, onTopRightLinkTriggerActions, sourceViewId, callerRowId, callerColumnId, callerColumnViewId, props.handleAction);
  if (callerRowId !== undefined && callerColumnId !== undefined && sourceViewId !== undefined && callerViewId !== undefined && callerColumnViewId !== undefined) {
    return JsxRuntime.jsx(Primitives__Group.make, {
                children: Caml_option.some(JsxRuntime.jsx(React.Suspense, {
                          children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__MultiSelect.make, {
                                    sourceViewId: Caml_option.valFromOption(sourceViewId),
                                    callerRowId: Caml_option.valFromOption(callerRowId),
                                    callerColumnId: Caml_option.valFromOption(callerColumnId),
                                    callerViewId: Caml_option.valFromOption(callerViewId),
                                    callerColumnViewId: Caml_option.valFromOption(callerColumnViewId),
                                    onLinkPress: match.onLinkPress
                                  })),
                          fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Block__MultiSelect.Data.Loading.make, {}))
                        }))
              });
  } else {
    return null;
  }
}

var Component$12 = {
  make: Spaces__Row__Renderer__Blocks$MultiSelect$Component
};

var descriptor_meta$12 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$12 = [];

var descriptor_declaredSettings$12 = [];

var descriptor$12 = {
  meta: descriptor_meta$12,
  code: blockCode$12,
  component: Component$12,
  declaredTriggers: descriptor_declaredTriggers$12,
  declaredSettings: descriptor_declaredSettings$12
};

var blockCode$13 = "space-system-link";

function Spaces__Row__Renderer__Blocks$SystemLink$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var sourceViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.SystemLink.Keys.sourceViewId), DecidrNodeId.fromString);
  var selectedRowId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.SystemLink.Keys.selectedRowId), DecidrInternalId.fromString);
  var organizationId = Spaces__Row__Renderer__BlockUtils.getOrganizationId(context);
  if (selectedRowId !== undefined && sourceViewId !== undefined && columnViewId !== undefined && organizationId !== undefined) {
    return JsxRuntime.jsx(Primitives__Group.make, {
                children: Caml_option.some(JsxRuntime.jsx(React.Suspense, {
                          children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__SystemLink.make, {
                                    sourceViewId: Caml_option.valFromOption(sourceViewId),
                                    selectedRowId: Caml_option.valFromOption(selectedRowId),
                                    columnViewId: Caml_option.valFromOption(columnViewId),
                                    organizationId: Caml_option.valFromOption(organizationId)
                                  })),
                          fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Block__SystemLink.Data.Loading.make, {}))
                        }))
              });
  } else {
    return null;
  }
}

var Component$13 = {
  make: Spaces__Row__Renderer__Blocks$SystemLink$Component
};

var descriptor_meta$13 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$13 = [];

var descriptor_declaredSettings$13 = [];

var descriptor$13 = {
  meta: descriptor_meta$13,
  code: blockCode$13,
  component: Component$13,
  declaredTriggers: descriptor_declaredTriggers$13,
  declaredSettings: descriptor_declaredSettings$13
};

var blockCode$14 = "space-image";

function Spaces__Row__Renderer__Blocks$Image$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var getFloat = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getFloat(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Image");
  var id = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageId), DecidrInternalId.fromString);
  var url = getString(Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageUrl);
  var width = getFloat(Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageWidth);
  var height = getFloat(Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageHeight);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__Image.make, {
                name: name,
                value: Core__Option.map(url, (function (url) {
                        return {
                                id: Core__Option.getOr(id, DecidrInternalId.empty),
                                url: url,
                                size: {
                                  width: Core__Option.getOr(Core__Option.map(width, (function (prim) {
                                              return prim | 0;
                                            })), 300),
                                  height: Core__Option.getOr(Core__Option.map(height, (function (prim) {
                                              return prim | 0;
                                            })), 300)
                                }
                              };
                      })),
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId)
              });
  } else {
    return null;
  }
}

var Component$14 = {
  make: Spaces__Row__Renderer__Blocks$Image$Component
};

var descriptor_meta$14 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$14 = [];

var descriptor_declaredSettings$14 = [];

var descriptor$14 = {
  meta: descriptor_meta$14,
  code: blockCode$14,
  component: Component$14,
  declaredTriggers: descriptor_declaredTriggers$14,
  declaredSettings: descriptor_declaredSettings$14
};

var blockCode$15 = "space-file";

function Spaces__Row__Renderer__Blocks$File$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var getFloat = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getFloat(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var url = getString(Spaces__Row__Renderer__BlockContext.$$File.Keys.fileUrl);
  var mimetype = getString(Spaces__Row__Renderer__BlockContext.$$File.Keys.mimetype);
  var size = getFloat(Spaces__Row__Renderer__BlockContext.$$File.Keys.size);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "File");
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__File.make, {
                name: name,
                value: url !== undefined && mimetype !== undefined && size !== undefined ? ({
                      url: url,
                      mimetype: mimetype,
                      size: size
                    }) : undefined,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId)
              });
  } else {
    return null;
  }
}

var Component$15 = {
  make: Spaces__Row__Renderer__Blocks$File$Component
};

var descriptor_meta$15 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$15 = [];

var descriptor_declaredSettings$15 = [];

var descriptor$15 = {
  meta: descriptor_meta$15,
  code: blockCode$15,
  component: Component$15,
  declaredTriggers: descriptor_declaredTriggers$15,
  declaredSettings: descriptor_declaredSettings$15
};

var blockCode$16 = "space-container";

function extendActionsPayloadWithColumnViewId(actions, columnViewId) {
  return Spaces__Block__Common.extendActionsPayloadWithEntries(actions, [[
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.columnViewId),
                DecidrNodeId.toString(columnViewId)
              ]]);
}

function extendActionsPayloadWithAddBlockPayload(actions, columnViewId, direction, tableLinkInfoId, sourceViewId, columnViewName, kind, componentTypeId, componentTypeCode) {
  return Spaces__Block__Common.extendActionsPayloadWithEntries(actions, [
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.columnViewId),
                DecidrNodeId.toString(columnViewId)
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.columnViewId),
                DecidrNodeId.toString(columnViewId)
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.addBlockDirection),
                direction
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.tableLinkInfoId),
                Core__Option.getOr(Core__Option.map(tableLinkInfoId, (function (tableLinkInfoId) {
                            return DecidrInternalId.toString(tableLinkInfoId);
                          })), null)
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.sourceViewId),
                Core__Option.getOr(Core__Option.map(sourceViewId, (function (sourceViewId) {
                            return DecidrNodeId.toString(sourceViewId);
                          })), null)
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.addBlockComponentTypeId),
                Core__Option.getOr(Core__Option.map(componentTypeId, (function (componentTypeId) {
                            return DecidrNodeId.toString(componentTypeId);
                          })), null)
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.addBlockComponentTypeCode),
                Core__Option.getOr(Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                            return componentTypeCode;
                          })), null)
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.columnViewName),
                columnViewName
              ],
              [
                DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.addBlockKind),
                Core__Option.getOr(Core__Option.map(kind, (function (kind) {
                            return kind;
                          })), null)
              ]
            ]);
}

function Spaces__Row__Renderer__Blocks$Container$Component(props) {
  var handleAction = props.handleAction;
  var triggerActions = props.triggerActions;
  var children = props.children;
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var getBool = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getBool(settings, context, code);
  };
  var name = getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name);
  var icon = getString(Spaces__Row__Renderer__BlockContext.Common.Keys.icon);
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var organizationId = Spaces__Row__Renderer__BlockUtils.getOrganizationId(context);
  var inline = getBool(Spaces__Row__Renderer__BlockContext.Container.Keys.inline);
  var tableLinkInfoId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.MultiSelect.Keys.tableLinkInfoId), DecidrInternalId.fromString);
  var sourceViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.MultiSelect.Keys.sourceViewId), DecidrNodeId.fromString);
  var componentTypeCode = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.componentTypeCode), Spaces__ComponentType__Code.fromString);
  var onChannelClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
                  return __x["onChannelClick"];
                })), Blocks__Utils.getActions), []);
  var onDeleteClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
                  return __x["onDeleteClick"];
                })), Blocks__Utils.getActions), []);
  var onAddClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
                  return __x["onAddClick"];
                })), Blocks__Utils.getActions), []);
  var onEditClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
                  return __x["onEditClick"];
                })), Blocks__Utils.getActions), []);
  var onHideClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
                  return __x["onHideClick"];
                })), Blocks__Utils.getActions), []);
  var onTopRightViewSettingsTriggerActions = Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
              return __x["onTopRightViewSettings"];
            })), Blocks__Utils.getActions);
  var handleChannelClick = onChannelClickActions.length !== 0 && handleAction !== undefined ? (function (channelId) {
        Spaces__Block__Common.extendActionsPayloadWithEntries(onChannelClickActions, [[
                  DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.channelId),
                  DecidrNodeId.toString(channelId)
                ]]).forEach(function (__x) {
              handleAction(__x);
            });
      }) : undefined;
  var handleEditClick = onEditClickActions.length !== 0 && handleAction !== undefined ? (function (columnViewId) {
        extendActionsPayloadWithColumnViewId(onEditClickActions, columnViewId).forEach(function (__x) {
              handleAction(__x);
            });
      }) : undefined;
  var handleHideClick = onHideClickActions.length !== 0 && handleAction !== undefined ? (function (columnViewId) {
        extendActionsPayloadWithColumnViewId(onHideClickActions, columnViewId).forEach(function (__x) {
              handleAction(__x);
            });
      }) : undefined;
  var handleDeleteClick = onDeleteClickActions.length !== 0 && handleAction !== undefined ? (function (columnViewId) {
        extendActionsPayloadWithColumnViewId(onDeleteClickActions, columnViewId).forEach(function (__x) {
              handleAction(__x);
            });
      }) : undefined;
  var handleAddClick = onAddClickActions.length !== 0 && handleAction !== undefined ? (function (columnViewId, direction, tableLinkInfoId, sourceViewId, kind, componentTypeId, componentTypeCode) {
        extendActionsPayloadWithAddBlockPayload(onAddClickActions, columnViewId, direction, tableLinkInfoId, sourceViewId, Core__Option.getOr(name, "Block"), kind, componentTypeId, componentTypeCode).forEach(function (__x) {
              handleAction(__x);
            });
      }) : undefined;
  var match = Spaces__Block__Common.makeMultiSelectCallbacks(onTopRightViewSettingsTriggerActions, undefined, sourceViewId, rowId, columnId, columnViewId, handleAction);
  var handleLinkedViewSettingsClick = match.onSettingsPress;
  var hasTopRightExtension = Core__Option.isSome(handleChannelClick) || Core__Option.isSome(handleDeleteClick) || Core__Option.isSome(handleAddClick);
  var extensions;
  if (columnViewId !== undefined && organizationId !== undefined && hasTopRightExtension && rowId !== undefined && columnId !== undefined) {
    var columnId$1 = Caml_option.valFromOption(columnId);
    var rowId$1 = Caml_option.valFromOption(rowId);
    var organizationId$1 = Caml_option.valFromOption(organizationId);
    var columnViewId$1 = Caml_option.valFromOption(columnViewId);
    extensions = {
      topRight: Caml_option.some(JsxRuntime.jsx(Primitives__Popover.make, {
                children: JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      style: {
                        minWidth: Caml_option.some(Primitives__Styling.dp(200)),
                        padding: Caml_option.some(Primitives__Styling.space("cs_2"))
                      },
                      children: [
                        JsxRuntime.jsx(Spaces__Block__Common.AIFillBlockMenuItem.make, {
                              componentTypeCode: componentTypeCode,
                              columnId: columnId$1,
                              rowId: rowId$1,
                              columnViewId: columnViewId$1
                            }),
                        ReactUtils__SimpleReact.renderIfSome(handleChannelClick, (function (handleChannelClick) {
                                return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                            children: [
                                              componentTypeCode !== undefined && !(componentTypeCode === "type-current-logged-in-user" || componentTypeCode === "places" || componentTypeCode === "type-created-in-view" || componentTypeCode === "type-row-membership-history" || componentTypeCode === "nearby-places" || componentTypeCode === "related-list" || componentTypeCode === "manual-http-request" || componentTypeCode === "stripe" || componentTypeCode === "type-created-by" || componentTypeCode === "type-owned-by" || componentTypeCode === "type-created-from-column-view" || componentTypeCode === "qr-code-reader" || componentTypeCode === "type-last-updated-date" || componentTypeCode === "type-last-updated-by" || componentTypeCode === "barcode-scanner" || componentTypeCode === "type-created-from-row" || componentTypeCode === "type-row-membership" || componentTypeCode === "column-count-insights" || componentTypeCode === "type-created-from-view" || componentTypeCode === "type-row-json-data" || componentTypeCode === "type-created-date" || componentTypeCode === "json") ? JsxRuntime.jsx(Spaces__Block__Common.CellChannelOpener.make, {
                                                      rowId: rowId$1,
                                                      columnId: columnId$1,
                                                      organizationId: organizationId$1,
                                                      onChannelPress: (function (channelId) {
                                                          handleChannelClick(channelId);
                                                        })
                                                    }) : null,
                                              JsxRuntime.jsx(Spaces__ColumnView__ChannelOpener.make, {
                                                    columnViewId: columnViewId$1,
                                                    organizationId: organizationId$1,
                                                    onChannelPress: (function (channelId) {
                                                        handleChannelClick(channelId);
                                                      })
                                                  })
                                            ]
                                          });
                              })),
                        ReactUtils__SimpleReact.renderIfSome(handleAddClick, (function (handleAddClick) {
                                var blocks = [
                                  {
                                    title: "Heading",
                                    icon: RescriptTamagui__Icon__Heading1.make,
                                    componentTypeCode: "heading"
                                  },
                                  {
                                    title: "Paragraph",
                                    icon: RescriptTamagui__Icon__Text.make,
                                    componentTypeCode: "paragraph"
                                  },
                                  {
                                    title: "Image",
                                    icon: RescriptTamagui__Icon__Image.make,
                                    componentTypeCode: "content-image"
                                  }
                                ];
                                return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                            children: [
                                              JsxRuntime.jsx(Primitives__Popover.DividerWithTitle.make, {
                                                    title: "Add new Block"
                                                  }),
                                              JsxRuntime.jsx(Primitives__Popover.Item.make, {
                                                    text: "Above",
                                                    icon: RescriptTamagui__Icon__ArrowUpFromLine.make,
                                                    onPress: (function () {
                                                        handleAddClick(columnViewId$1, "before", undefined, undefined, undefined, undefined, undefined);
                                                      })
                                                  }),
                                              JsxRuntime.jsx(Primitives__Popover.Item.make, {
                                                    text: "Below",
                                                    icon: RescriptTamagui__Icon__ArrowDownFromLine.make,
                                                    onPress: (function () {
                                                        handleAddClick(columnViewId$1, "after", undefined, undefined, undefined, undefined, undefined);
                                                      })
                                                  }),
                                              JsxRuntime.jsx(Primitives__Popover.DividerWithTitle.make, {
                                                    title: "Quick add below for record"
                                                  }),
                                              JsxRuntime.jsx(Primitives__Suspense.make, {
                                                    fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__BlockMenuItems.ConnectedAddSpecificBlocks.Loading.make, {
                                                              blocks: blocks
                                                            })),
                                                    children: JsxRuntime.jsx(Spaces__Ui__BlockMenuItems.ConnectedAddSpecificBlocks.make, {
                                                          onAdd: (function (componentTypeId, componentTypeCode) {
                                                              handleAddClick(columnViewId$1, "after", undefined, undefined, undefined, Caml_option.some(componentTypeId), componentTypeCode);
                                                            }),
                                                          blocks: blocks
                                                        })
                                                  }),
                                              componentTypeCode === "multi_select" ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                                      children: [
                                                        JsxRuntime.jsx(Primitives__Popover.DividerWithTitle.make, {
                                                              title: "Link actions"
                                                            }),
                                                        JsxRuntime.jsx(Primitives__Suspense.make, {
                                                              fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__BlockMenuItems.ConnectedAddLookupAndRollupBlock.Loading.make, {})),
                                                              children: JsxRuntime.jsx(Spaces__Ui__BlockMenuItems.ConnectedAddLookupAndRollupBlock.make, {
                                                                    tableLinkInfoId: tableLinkInfoId,
                                                                    sourceViewId: sourceViewId,
                                                                    onAddLookup: (function (tableLinkInfoId, componentTypeId) {
                                                                        handleAddClick(columnViewId$1, "after", Caml_option.some(tableLinkInfoId), undefined, "lookup", Caml_option.some(componentTypeId), undefined);
                                                                      }),
                                                                    onAddRollup: (function (tableLinkInfoId, sourceViewId, componentTypeId) {
                                                                        handleAddClick(columnViewId$1, "after", Caml_option.some(tableLinkInfoId), Caml_option.some(sourceViewId), "rollup", Caml_option.some(componentTypeId), undefined);
                                                                      })
                                                                  })
                                                            }),
                                                        JsxRuntime.jsx(Spaces__Ui__BlockMenuItems.ConnectedSourceViewSettingsMenuItem.make, {
                                                              sourceViewId: sourceViewId,
                                                              onSettingsPress: handleLinkedViewSettingsClick
                                                            })
                                                      ]
                                                    }) : null
                                            ]
                                          });
                              })),
                        JsxRuntime.jsx(Primitives__Popover.DividerWithTitle.make, {
                              title: "Block actions"
                            }),
                        ReactUtils__SimpleReact.renderIfSome(handleHideClick, (function (handleHideClick) {
                                return JsxRuntime.jsx(Primitives__Popover.Item.make, {
                                            text: "Hide",
                                            icon: RescriptTamagui__Icon__EyeOff.make,
                                            onPress: (function () {
                                                handleHideClick(columnViewId$1);
                                              })
                                          });
                              })),
                        ReactUtils__SimpleReact.renderIfSome(handleEditClick, (function (handleEditClick) {
                                return JsxRuntime.jsx(Primitives__Popover.Item.make, {
                                            text: "Edit",
                                            icon: RescriptTamagui__Icon__Edit.make,
                                            onPress: (function () {
                                                handleEditClick(columnViewId$1);
                                              })
                                          });
                              })),
                        ReactUtils__SimpleReact.renderIfSome(handleDeleteClick, (function (handleDeleteClick) {
                                return JsxRuntime.jsx(JsxRuntime.Fragment, {
                                            children: Caml_option.some(JsxRuntime.jsx(Primitives__Popover.Item.make, {
                                                      color: Primitives__Styling.color({
                                                            NAME: "icon",
                                                            VAL: {
                                                              NAME: "error",
                                                              VAL: "default"
                                                            }
                                                          }),
                                                      text: "Delete",
                                                      icon: RescriptTamagui__Icon__Trash2.make,
                                                      onPress: (function () {
                                                          handleDeleteClick(columnViewId$1);
                                                        })
                                                    }))
                                          });
                              }))
                      ]
                    }),
                trigger: JsxRuntime.jsx(Primitives__Icon.make, {
                      size: "ic_3",
                      icon: RescriptTamagui__Icon__MoreVertical.make
                    }),
                placement: "bottom-end"
              }))
    };
  } else {
    extensions = undefined;
  }
  if (name === undefined) {
    return null;
  }
  if (componentTypeCode !== undefined && (componentTypeCode === "heading" || componentTypeCode === "content-image" || componentTypeCode === "paragraph")) {
    return JsxRuntime.jsx(Spaces__Block__Common.ContentContainer.make, {
                children: Core__Option.getOr(children, null),
                extensions: extensions
              });
  }
  return JsxRuntime.jsx(Spaces__Block__Common.Container.make, {
              name: name,
              icon: icon,
              children: Core__Option.getOr(children, null),
              extensions: extensions,
              inline: inline
            });
}

var Component$16 = {
  make: Spaces__Row__Renderer__Blocks$Container$Component
};

var descriptor_meta$16 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$16 = [];

var descriptor_declaredSettings$16 = [];

var descriptor$16 = {
  meta: descriptor_meta$16,
  code: blockCode$16,
  component: Component$16,
  declaredTriggers: descriptor_declaredTriggers$16,
  declaredSettings: descriptor_declaredSettings$16
};

var blockCode$17 = "space-view-editor";

function Spaces__Row__Renderer__Blocks$SelectView$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Spaces__Row__Renderer__BlockUtils.getString(settings, context, code);
  };
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var columnViewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId), DecidrNodeId.fromString);
  var organizationId = Spaces__Row__Renderer__BlockUtils.getOrganizationId(context);
  var columnId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.columnId), DecidrInternalId.fromString);
  var name = Core__Option.getOr(getString(Spaces__Row__Renderer__BlockContext.Common.Keys.name), "Select View");
  var viewId = Core__Option.flatMap(getString(Spaces__Row__Renderer__BlockContext.SelectView.Keys.viewId), DecidrNodeId.fromString);
  if (rowId !== undefined && columnId !== undefined && columnViewId !== undefined && organizationId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__SelectView.make, {
                name: name,
                viewId: viewId,
                rowId: Caml_option.valFromOption(rowId),
                columnId: Caml_option.valFromOption(columnId),
                columnViewId: Caml_option.valFromOption(columnViewId),
                organizationId: Caml_option.valFromOption(organizationId)
              });
  } else {
    return null;
  }
}

var Component$17 = {
  make: Spaces__Row__Renderer__Blocks$SelectView$Component
};

var descriptor_meta$17 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$17 = [];

var descriptor_declaredSettings$17 = [];

var descriptor$17 = {
  meta: descriptor_meta$17,
  code: blockCode$17,
  component: Component$17,
  declaredTriggers: descriptor_declaredTriggers$17,
  declaredSettings: descriptor_declaredSettings$17
};

var blockCode$18 = "space-row-json-data";

function Spaces__Row__Renderer__Blocks$RowJsonData$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  var viewId = Spaces__Row__Renderer__BlockUtils.getViewId(context);
  if (rowId !== undefined && viewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__RowJsonData.make, {
                rowId: Caml_option.valFromOption(rowId),
                viewId: Caml_option.valFromOption(viewId)
              });
  } else {
    return null;
  }
}

var Component$18 = {
  make: Spaces__Row__Renderer__Blocks$RowJsonData$Component
};

var descriptor_meta$18 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$18 = [];

var descriptor_declaredSettings$18 = [];

var descriptor$18 = {
  meta: descriptor_meta$18,
  code: blockCode$18,
  component: Component$18,
  declaredTriggers: descriptor_declaredTriggers$18,
  declaredSettings: descriptor_declaredSettings$18
};

var blockCode$19 = "space-row-membership";

function Spaces__Row__Renderer__Blocks$RowMembership$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  if (rowId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__RowMembership.Live.make, {
                rowId: Caml_option.valFromOption(rowId)
              });
  } else {
    return null;
  }
}

var Component$19 = {
  make: Spaces__Row__Renderer__Blocks$RowMembership$Component
};

var descriptor_meta$19 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$19 = [];

var descriptor_declaredSettings$19 = [];

var descriptor$19 = {
  meta: descriptor_meta$19,
  code: blockCode$19,
  component: Component$19,
  declaredTriggers: descriptor_declaredTriggers$19,
  declaredSettings: descriptor_declaredSettings$19
};

var blockCode$20 = "space-row-membership-history";

function Spaces__Row__Renderer__Blocks$RowMembershipHistory$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var rowId = Spaces__Row__Renderer__BlockUtils.getRowId(context);
  if (rowId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__RowMembership.$$History.make, {
                rowId: Caml_option.valFromOption(rowId)
              });
  } else {
    return null;
  }
}

var Component$20 = {
  make: Spaces__Row__Renderer__Blocks$RowMembershipHistory$Component
};

var descriptor_meta$20 = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers$20 = [];

var descriptor_declaredSettings$20 = [];

var descriptor$20 = {
  meta: descriptor_meta$20,
  code: blockCode$20,
  component: Component$20,
  declaredTriggers: descriptor_declaredTriggers$20,
  declaredSettings: descriptor_declaredSettings$20
};

var $$Text = {
  blockCode: blockCode,
  version: 1,
  descriptor: descriptor
};

var LongText = {
  blockCode: blockCode$1,
  version: 1,
  descriptor: descriptor$1
};

var Json = {
  blockCode: blockCode$2,
  version: 1,
  descriptor: descriptor$2
};

var HttpRequestTrigger = {
  blockCode: blockCode$3,
  version: 1,
  descriptor: descriptor$3
};

var ColumnCountInsights = {
  blockCode: blockCode$4,
  version: 1,
  descriptor: descriptor$4
};

var $$Date = {
  blockCode: blockCode$5,
  version: 1,
  descriptor: descriptor$5
};

var $$Number = {
  blockCode: blockCode$6,
  version: 1,
  descriptor: descriptor$6
};

var Switch = {
  blockCode: blockCode$7,
  version: 1,
  descriptor: descriptor$7
};

var RelatedList = {
  blockCode: blockCode$8,
  version: 1,
  descriptor: descriptor$8
};

var Lookup = {
  blockCode: blockCode$9,
  version: 1,
  descriptor: descriptor$9
};

var Rollup = {
  blockCode: blockCode$10,
  version: 1,
  descriptor: descriptor$10
};

var ReadOnlyDate = {
  blockCode: blockCode$11,
  version: 1,
  descriptor: descriptor$11
};

var MultiSelect = {
  blockCode: blockCode$12,
  version: 1,
  descriptor: descriptor$12
};

var SystemLink = {
  blockCode: blockCode$13,
  version: 1,
  descriptor: descriptor$13
};

var $$Image = {
  blockCode: blockCode$14,
  version: 1,
  descriptor: descriptor$14
};

var $$File = {
  blockCode: blockCode$15,
  version: 1,
  descriptor: descriptor$15
};

var Container = {
  blockCode: blockCode$16,
  version: 1,
  descriptor: descriptor$16
};

var SelectView = {
  blockCode: blockCode$17,
  version: 1,
  descriptor: descriptor$17
};

var RowJsonData = {
  blockCode: blockCode$18,
  version: 1,
  descriptor: descriptor$18
};

var RowMembership = {
  blockCode: blockCode$19,
  version: 1,
  descriptor: descriptor$19
};

var RowMembershipHistory = {
  blockCode: blockCode$20,
  version: 1,
  descriptor: descriptor$20
};

export {
  $$Text ,
  LongText ,
  Json ,
  HttpRequestTrigger ,
  ColumnCountInsights ,
  $$Date ,
  $$Number ,
  Switch ,
  RelatedList ,
  Lookup ,
  Rollup ,
  ReadOnlyDate ,
  MultiSelect ,
  SystemLink ,
  $$Image ,
  $$File ,
  Container ,
  SelectView ,
  RowJsonData ,
  RowMembership ,
  RowMembershipHistory ,
}
/* react Not a pure module */
