// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Hooks from "./hooks";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__InputUtils from "../../../../primitives/src/input/Primitives__InputUtils.bs.js";
import * as Renderer__SettingsJSON from "@pageblocks/renderer/src/Renderer__SettingsJSON.bs.js";
import * as Primitives__DecidrToast from "../../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as Primitives__UnstyledButton from "../../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as DecidrRenderer__Blocks__Utils from "../DecidrRenderer__Blocks__Utils.bs.js";

function useScrollToBottom(prim) {
  return Hooks.useScrollToBottom();
}

function useMessages(prim) {
  return Hooks.useMessages(prim);
}

var Hooks$1 = {
  useScrollToBottom: useScrollToBottom,
  useMessages: useMessages
};

var multiple = "multiple";

var options = "options";

var rowId = "rowId";

var SettingKeys = {
  choices: "choices",
  multiple: multiple,
  options: options,
  rowId: rowId
};

function parse(option, context) {
  var value = Core__Option.flatMap((function (__x) {
            return __x["value"];
          })(option), Core__JSON.Decode.string);
  var rowId$1 = DecidrRenderer__Blocks__Utils.getString(Renderer__Settings.deserialize(Renderer__SettingsJSON.fromJson(option)), context, rowId);
  if (value !== undefined && value !== "") {
    if (rowId$1 !== undefined && rowId$1 !== "") {
      return {
              value: value,
              rowId: rowId$1
            };
    } else {
      return {
              value: value,
              rowId: undefined
            };
    }
  }
  
}

var ChoiceOption = {
  parse: parse
};

function parse$1(choices, context) {
  var multiple$1 = Core__Option.getOr(Core__Option.flatMap((function (__x) {
                return __x[multiple];
              })(choices), Core__JSON.Decode.bool), false);
  var options$1 = Core__Option.map(Core__Option.map(Core__Option.flatMap((function (__x) {
                    return __x[options];
                  })(choices), Core__JSON.Decode.array), (function (__x) {
              return Core__Array.filterMap(__x, Core__JSON.Decode.object);
            })), (function (__x) {
          return Core__Array.filterMap(__x, (function (__x) {
                        return parse(__x, context);
                      }));
        }));
  if (options$1 !== undefined) {
    return {
            multiple: multiple$1,
            options: options$1
          };
  }
  
}

var Choices = {
  parse: parse$1
};

function DecidrRenderer__Blocks__SurveyAndChatUtils$MultipleChoicesNotice(props) {
  var choices = props.choices;
  if (choices !== undefined && choices.multiple) {
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                style: {
                  opacity: 0.5
                },
                children: "You can select multiple options",
                color: props.foregroundColor
              });
  } else {
    return null;
  }
}

var MultipleChoicesNotice = {
  make: DecidrRenderer__Blocks__SurveyAndChatUtils$MultipleChoicesNotice
};

var answerSeparator = ":_separator_:";

function DecidrRenderer__Blocks__SurveyAndChatUtils$ChoicesInput(props) {
  var onSelect = props.onSelect;
  var selectedValue = props.value;
  var choices = props.choices;
  var toast = Primitives__DecidrToast.use();
  var shouldSubmitRowIds = choices.options.some(function (option) {
        return Core__Option.isSome(option.rowId);
      });
  return JsxRuntime.jsx(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: Caml_option.some(choices.options.map(function (option) {
                        var value = shouldSubmitRowIds ? option.rowId : option.value;
                        var values = selectedValue === "" ? [] : selectedValue.split(answerSeparator);
                        var match = choices.multiple;
                        var selected = value !== undefined ? (
                            match ? values.some(function (x) {
                                    return x === value;
                                  }) : selectedValue === value
                          ) : false;
                        return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                                    children: JsxRuntime.jsx(Primitives__DecidrText.make, {
                                          style: {
                                            fontWeight: 500
                                          },
                                          children: Caml_option.some(option.value)
                                        }),
                                    style: {
                                      backgroundColor: Primitives__Styling.color(selected ? "secondary_4" : ({
                                                NAME: "surface",
                                                VAL: "default"
                                              })),
                                      borderColor: Primitives__Styling.color({
                                            NAME: "border",
                                            VAL: selected ? ({
                                                  NAME: "highlight",
                                                  VAL: "subdued"
                                                }) : ({
                                                  NAME: "neutral",
                                                  VAL: "default"
                                                })
                                          }),
                                      borderRadius: Primitives__Styling.radius("rad_2"),
                                      borderWidth: 2,
                                      padding: Caml_option.some(Primitives__Styling.space("cs_4"))
                                    },
                                    onPress: (function (_e) {
                                        if (value === undefined) {
                                          return toast.error("Unable to select option", "Sorry, something went wrong. Contact the support team.");
                                        }
                                        if (!choices.multiple) {
                                          return onSelect(value);
                                        }
                                        var newValues = selected ? values.filter(function (x) {
                                                return x !== value;
                                              }) : values.concat([value]);
                                        onSelect(newValues.join(answerSeparator));
                                      }),
                                    focusStyle: {
                                      borderColor: Primitives__Styling.color({
                                            NAME: "border",
                                            VAL: {
                                              NAME: "highlight",
                                              VAL: "default"
                                            }
                                          })
                                    },
                                    hoverStyle: {
                                      backgroundColor: Primitives__Styling.color(selected ? "secondary_4" : "primary_4"),
                                      borderColor: Primitives__Styling.color({
                                            NAME: "border",
                                            VAL: selected ? ({
                                                  NAME: "highlight",
                                                  VAL: "subdued"
                                                }) : ({
                                                  NAME: "neutral",
                                                  VAL: "hover"
                                                })
                                          })
                                    }
                                  }, option.value + Core__Option.getOr(Core__Option.map(option.rowId, (function (rowId) {
                                              return "-" + rowId;
                                            })), ""));
                      }))
            });
}

var ChoicesInput = {
  answerSeparator: answerSeparator,
  make: DecidrRenderer__Blocks__SurveyAndChatUtils$ChoicesInput
};

var make = React.forwardRef(function (props, ref) {
      var onChange = props.onChange;
      var value = props.value;
      var choices = props.choices;
      if (choices !== undefined && choices.options.length !== 0) {
        return JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils$ChoicesInput, {
                    choices: choices,
                    value: value,
                    onSelect: onChange
                  });
      }
      return JsxRuntime.jsx(Primitives__Input.make, {
                  value: value,
                  onChange: onChange,
                  onSubmitEditing: props.onSubmitEditing,
                  placeholder: props.placeholder,
                  autoFocus: props.autoFocus,
                  ref: Primitives__InputUtils.ignoreRefType(Core__Option.map((ref == null) ? undefined : Caml_option.some(ref), (function (prim) {
                              return prim;
                            })))
                });
    });

var AnswerInput = {
  make: make
};

function makeMessageContext(context, content, name, image) {
  var context$1 = Core__Option.getOr(Core__JSON.Decode.object(Renderer__Context.toJson(context)), {});
  var $$extends = [
    [
      "message_content",
      content
    ],
    [
      "user_display_name",
      name
    ],
    [
      "user_display_image",
      image
    ]
  ];
  return Renderer__Context.fromJson(Object.fromEntries(Object.entries(context$1).concat($$extends)));
}

function parseSideContentPositionWithDefault(position) {
  if (position === undefined) {
    return "right";
  }
  switch (position) {
    case "left" :
        return "left";
    case "right" :
        return "right";
    default:
      return "right";
  }
}

var Infra;

var Utils;

var defaultAiName = "Your Personal Assistant";

var defaultAiImage = "https://cdn.filestackcontent.com/Rv9J9qOlT8G9z852uC3w";

var defaultUserName = "You";

var defaultUserImage = "https://icons.veryicon.com/png/o/internet--web/prejudice/user-128.png";

var defaultSideContentSource = "https://images.unsplash.com/photo-1451438143976-4e1aa4f7f153?q=80&w=3024&auto=format&fit=crop";

var defaultPlaceholder = "Type your answer here...";

export {
  Infra ,
  Utils ,
  Hooks$1 as Hooks,
  defaultAiName ,
  defaultAiImage ,
  defaultUserName ,
  defaultUserImage ,
  defaultSideContentSource ,
  defaultPlaceholder ,
  SettingKeys ,
  ChoiceOption ,
  Choices ,
  MultipleChoicesNotice ,
  ChoicesInput ,
  AnswerInput ,
  makeMessageContext ,
  parseSideContentPositionWithDefault ,
}
/* make Not a pure module */
