// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as DateTime from "../../date-time/src/DateTime.bs.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";

function parse(json) {
  var dateStr = Core__JSON.Decode.string(json);
  if (dateStr !== undefined) {
    return DateTime.parseIsoStringExn(dateStr);
  } else {
    return Js_exn.raiseError("Error parsing date");
  }
}

function serialize(t) {
  return DateTime.toIsoString(t);
}

export {
  parse ,
  serialize ,
}
/* DateTime Not a pure module */
