// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ErrorReporting from "../../error-reporting/src/ErrorReporting.bs.js";

var debugPrefix = "[RelayMutationCompleted]";

function handle(errors, message, debugContext, onError, onSuccess, onCompleted) {
  Core__Option.forEach(onCompleted, (function (onCompleted) {
          onCompleted();
        }));
  if (errors === undefined) {
    return Core__Option.forEach(onSuccess, (function (onSuccess) {
                  onSuccess();
                }));
  }
  var reason = Core__Option.flatMap(Core__Option.flatMap(errors.errors, (function (__x) {
              return __x[0];
            })), (function (error) {
          return error.message;
        }));
  ErrorReporting.captureError(message, debugPrefix + " " + debugContext, errors);
  console.log(debugPrefix, debugContext, message, errors);
  Core__Option.forEach(onError, (function (onError) {
          onError(message, reason);
        }));
}

export {
  handle ,
}
/* ErrorReporting Not a pure module */
