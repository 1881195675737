// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Utils__Time from "../../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import LodashDebounce from "lodash.debounce";
import * as Primitives__Input from "../../../primitives/src/input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__Block__Common from "./Spaces__Block__Common.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";
import * as Spaces__Row__PageStateContext from "../row/pageStateContext/Spaces__Row__PageStateContext.bs.js";
import * as Primitives__AutoExpandingInput from "../../../primitives/src/input/Primitives__AutoExpandingInput.bs.js";

function Spaces__Block__Json(props) {
  var columnViewId = props.columnViewId;
  var columnId = props.columnId;
  var rowId = props.rowId;
  var initialValue = props.value;
  var name = props.name;
  var toast = Primitives__DecidrToast.use();
  var isReadOnly = Spaces__Row__PageStateContext.useReadOnly();
  var match = React.useState(function () {
        if (initialValue !== undefined && initialValue !== "") {
          return "View";
        } else {
          return "NoValue";
        }
      });
  var setMode = match[1];
  var match$1 = Primitives__Input.use(Core__Option.getOr(Core__Option.flatMap(Core__Option.map(initialValue, (function (prim) {
                      return JSON.parse(prim);
                    })), (function (__x) {
                  return JSON.stringify(__x, null, 2);
                })), ""), undefined, undefined);
  var onChange = match$1.onChange;
  var value = match$1.value;
  var match$2 = React.useState(function () {
        
      });
  var setError = match$2[1];
  var error = match$2[0];
  var match$3 = Spaces__Cells__Mutations.useUpdateCell(rowId, columnId, columnViewId);
  var updatingCell = match$3[1];
  var updateCell = match$3[0];
  var clearErrorState = function () {
    toast.hide();
    setError(function (param) {
          
        });
  };
  var handleError = React.useCallback((function (message) {
          setError(function (param) {
                return message;
              });
          toast.error("Error saving " + name + " cell", undefined);
        }), [name]);
  var save = React.useCallback(LodashDebounce((function (value) {
              clearErrorState();
              var tmp = value === "" ? null : value;
              updateCell(tmp, handleError, undefined);
            }), Utils__Time.secondInMs), [
        rowId,
        columnId,
        columnViewId
      ]);
  var handleOnChange = function (value) {
    onChange(value);
    save(value);
  };
  var newrecord = Caml_obj.obj_dup(Primitives__DecidrText.WhiteSpacePreWrap.hack);
  return JsxRuntime.jsx(Spaces__Block__Common.WithReadOnlyText.make, {
              text: value,
              isReadOnly: isReadOnly,
              children: JsxRuntime.jsx(Spaces__Block__Common.EditableViewer.make, {
                    mode: match[0],
                    renderEditor: (function (autoFocus) {
                        return JsxRuntime.jsx(Primitives__AutoExpandingInput.make, {
                                    value: value,
                                    onChange: handleOnChange,
                                    placeholder: name,
                                    style: {
                                      flex: 1
                                    },
                                    right: updatingCell ? ({
                                          element: (function (param) {
                                              return JsxRuntime.jsx(Spaces__Block__Common.SavingIndicator.make, {});
                                            }),
                                          style: {
                                            position: "absolute",
                                            right: Caml_option.some(Primitives__Styling.space("cs_2")),
                                            top: Caml_option.some(Primitives__Styling.space("cs_2"))
                                          }
                                        }) : undefined,
                                    validation: Core__Option.map(error, (function (param) {
                                            return "error";
                                          })),
                                    autoFocus: autoFocus,
                                    minInputHeight: 160
                                  });
                      }),
                    viewer: JsxRuntime.jsx(Spaces__Block__Common.ReadOnlyText.make, {
                          children: value,
                          style: (newrecord.paddingTop = Caml_option.some(Primitives__Styling.space("cs_3")), newrecord.paddingRight = Caml_option.some(Primitives__Styling.space("cs_5")), newrecord.paddingLeft = Caml_option.some(Primitives__Styling.space("cs_5")), newrecord.paddingBottom = Caml_option.some(Primitives__Styling.space("cs_3")), newrecord.borderWidth = 1, newrecord.borderColor = Primitives__Styling.colorValue("transparent"), newrecord)
                        }),
                    onEdit: (function () {
                        setMode(function (param) {
                              return "Edit";
                            });
                      }),
                    onDismiss: (function () {
                        setMode(function (param) {
                              return "View";
                            });
                      })
                  })
            });
}

var make = Spaces__Block__Json;

export {
  make ,
}
/* react Not a pure module */
