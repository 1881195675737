// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Analytics__ContextRe from "./Analytics__ContextRe";

function makeContext(prim) {
  return Analytics__ContextRe.makeContext(prim);
}

export {
  makeContext ,
}
/* ./Analytics__ContextRe Not a pure module */
