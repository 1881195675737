// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DateTime__DateFns from "./DateTime__DateFns.bs.js";
import * as ErrorHandling__UnexpectedError from "../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";

function parseIsoString(str) {
  var maybeDate = DateTime__DateFns.parseISO(str);
  if (DateTime__DateFns.isValid(maybeDate)) {
    return {
            TAG: "Ok",
            _0: maybeDate
          };
  } else {
    return {
            TAG: "Error",
            _0: "Error parsing date"
          };
  }
}

function parseIsoStringOpt(value) {
  var date = parseIsoString(value);
  if (date.TAG === "Ok") {
    return Caml_option.some(date._0);
  }
  ErrorHandling__UnexpectedError.handle("Error parsing date string", "DateTime parseIsoStringOpt", {
        value: value,
        message: date._0
      });
}

function parseIsoStringExn(str) {
  var maybeDate = DateTime__DateFns.parseISO(str);
  if (DateTime__DateFns.isValid(maybeDate)) {
    return maybeDate;
  } else {
    return Js_exn.raiseError("Error parsing date");
  }
}

function toString(t, fmt) {
  var fmtString = typeof fmt === "object" ? fmt.VAL : (
      fmt === "time" ? "HH:mm" : (
          fmt === "dateTime" ? "yyyy-MM-dd HH:mm" : (
              fmt === "fullTime" ? "HH:mm:ss" : (
                  fmt === "dateLongTime" ? "yyyy-MM-dd HH:mm:ss" : (
                      fmt === "longDateLongTime" ? "yyyy-MMM-dd HH:mm:ss" : (
                          fmt === "longDateTime24Hours" ? "yyyy-MMM-dd HH:mm" : (
                              fmt === "longDate" ? "yyyy-MMM-dd" : (
                                  fmt === "longDateTime12Hours" ? "yyyy-MMM-dd hh:mm aa" : "yyyy-MM-dd"
                                )
                            )
                        )
                    )
                )
            )
        )
    );
  return DateTime__DateFns.format(t, fmtString);
}

var toIsoString = DateTime__DateFns.formatISO;

export {
  parseIsoString ,
  parseIsoStringOpt ,
  parseIsoStringExn ,
  toIsoString ,
  toString ,
}
/* DateTime__DateFns Not a pure module */
