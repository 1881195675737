// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"organizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"channelId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"removeOrganizationMemberFromChannel_removedOrganizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"removeOrganizationMemberFromChannel_channelId":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"removeOrganizationMemberFromChannel_removedOrganizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"removeOrganizationMemberFromChannel_channelId":{"c":"DecidrNodeId.RelayAppSync"}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var wrapRawResponseConverter = {"__root":{"removeOrganizationMemberFromChannel_removedOrganizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"removeOrganizationMemberFromChannel_channelId":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"removeOrganizationMemberFromChannel_removedOrganizationMemberId":{"c":"DecidrNodeId.RelayAppSync"},"removeOrganizationMemberFromChannel_channelId":{"c":"DecidrNodeId.RelayAppSync"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "channelId"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "organizationMemberId"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "channelId",
            "variableName": "channelId"
          },
          {
            "kind": "Variable",
            "name": "organizationMemberId",
            "variableName": "organizationMemberId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "RemoveOrganizationMemberFromChannelPayload",
    "kind": "LinkedField",
    "name": "removeOrganizationMemberFromChannel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "channelId",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "removedOrganizationMemberId",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "2030e560b09087d1f6aec5e2242e9366",
    "id": null,
    "metadata": {},
    "name": "SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesChannelMutationsRemoveOrganizationMemberFromChannelMutation(\n  $channelId: ID!\n  $organizationMemberId: ID!\n) {\n  removeOrganizationMemberFromChannel(input: {channelId: $channelId, organizationMemberId: $organizationMemberId}) {\n    channelId\n    removedOrganizationMemberId\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
