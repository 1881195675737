// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Primitives__Input from "./Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

function getScrollHeight(target) {
  if (!(target == null)) {
    return target.scrollHeight;
  }
  
}

function Primitives__AutoExpandingInput(props) {
  var maxInputHeight = props.maxInputHeight;
  var minInputHeight = props.minInputHeight;
  var match = React.useState(function () {
        
      });
  var setContentHeight = match[1];
  var contentHeight = match[0];
  return JsxRuntime.jsx(Primitives__Input.make, {
              value: props.value,
              onChange: props.onChange,
              onSubmitEditing: props.onSubmitEditing,
              onKeyPress: props.onKeyPress,
              label: props.label,
              placeholder: props.placeholder,
              right: props.right,
              validation: props.validation,
              style: props.style,
              multiline: true,
              autoFocus: props.autoFocus,
              inputHeight: contentHeight !== undefined ? (
                  maxInputHeight !== undefined ? (
                      contentHeight <= minInputHeight ? Math.min(minInputHeight, maxInputHeight) : Math.min(contentHeight, maxInputHeight)
                    ) : (
                      contentHeight <= minInputHeight ? minInputHeight : contentHeight
                    )
                ) : minInputHeight,
              onChangeEvent: (function ($$event) {
                  var target = $$event.target;
                  setContentHeight(function (param) {
                        return getScrollHeight(target);
                      });
                }),
              onLayout: (function ($$event) {
                  var target = $$event.nativeEvent.target;
                  setContentHeight(function (param) {
                        return getScrollHeight(target);
                      });
                }),
              selection: props.selection
            });
}

var make = Primitives__AutoExpandingInput;

export {
  make ,
}
/* react Not a pure module */
