// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Icon from "../icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as RescriptTamagui__Popover from "../../../rescript-tamagui/src/popover/RescriptTamagui__Popover.bs.js";
import * as Primitives__UnstyledButton from "../button/Primitives__UnstyledButton.bs.js";

function Primitives__Popover$DividerWithTitle(props) {
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              orientation: "horizontal",
              style: {
                alignItems: "center"
              },
              children: [
                JsxRuntime.jsx(Primitives__Group.make, {
                      style: {
                        backgroundColor: Primitives__Styling.color({
                              NAME: "border",
                              VAL: {
                                NAME: "neutral",
                                VAL: "default"
                              }
                            }),
                        height: Caml_option.some(Primitives__Styling.dp(1)),
                        width: Caml_option.some(Primitives__Styling.dp(4))
                      }
                    }),
                JsxRuntime.jsx(Primitives__DecidrText.make, {
                      style: {
                        fontWeight: 500,
                        paddingRight: Caml_option.some(Primitives__Styling.space("cs_2"))
                      },
                      kind: "body3",
                      children: Caml_option.some(props.title)
                    }),
                JsxRuntime.jsx(Primitives__Group.make, {
                      style: {
                        backgroundColor: Primitives__Styling.color({
                              NAME: "border",
                              VAL: {
                                NAME: "neutral",
                                VAL: "default"
                              }
                            }),
                        flex: 1,
                        height: Caml_option.some(Primitives__Styling.dp(1))
                      }
                    })
              ]
            });
}

var DividerWithTitle = {
  make: Primitives__Popover$DividerWithTitle
};

function Primitives__Popover$Item(props) {
  var __withAutoClose = props.withAutoClose;
  var __children = props.children;
  var onPress = props.onPress;
  var color = props.color;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var withAutoClose = __withAutoClose !== undefined ? __withAutoClose : true;
  var element = JsxRuntime.jsxs(Primitives__UnstyledButton.make, {
        children: [
          children,
          JsxRuntime.jsxs(Primitives__Group.make, {
                gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                orientation: "horizontal",
                style: {
                  alignItems: "center",
                  paddingLeft: Caml_option.some(Primitives__Styling.space("cs_3")),
                  paddingRight: Caml_option.some(Primitives__Styling.space("cs_4"))
                },
                children: [
                  JsxRuntime.jsx(Primitives__Icon.make, {
                        size: "ic_3",
                        color: color,
                        icon: props.icon
                      }),
                  JsxRuntime.jsx(Primitives__DecidrText.make, {
                        style: {
                          fontWeight: 500
                        },
                        children: Caml_option.some(props.text),
                        color: color
                      })
                ]
              })
        ],
        onPress: (function (param) {
            onPress();
          }),
        hoverStyle: {
          backgroundColor: Primitives__Styling.color("primary_4")
        }
      });
  if (withAutoClose) {
    return JsxRuntime.jsx(RescriptTamagui__Popover.Close.make, {
                children: Caml_option.some(element),
                asChild: true
              });
  } else {
    return element;
  }
}

var Item = {
  make: Primitives__Popover$Item
};

function Primitives__Popover(props) {
  var __placement = props.placement;
  var placement = __placement !== undefined ? __placement : "bottom-start";
  return JsxRuntime.jsxs(RescriptTamagui__Popover.make, {
              children: [
                JsxRuntime.jsx(RescriptTamagui__Popover.Trigger.make, {
                      children: Caml_option.some(props.trigger),
                      cur: "pointer"
                    }),
                JsxRuntime.jsx(RescriptTamagui__Popover.Content.make, {
                      style: {
                        borderRadius: 12,
                        paddingBottom: Caml_option.some(Primitives__Styling.dp(0)),
                        paddingLeft: Caml_option.some(Primitives__Styling.dp(0)),
                        paddingRight: Caml_option.some(Primitives__Styling.dp(0)),
                        paddingTop: Caml_option.some(Primitives__Styling.dp(0))
                      },
                      enterStyle: {
                        x: Caml_option.some(Primitives__Styling.dp(0)),
                        y: Caml_option.some(Primitives__Styling.dp(-10)),
                        opacity: 0
                      },
                      exitStyle: {
                        x: Caml_option.some(Primitives__Styling.dp(0)),
                        y: Caml_option.some(Primitives__Styling.dp(-10)),
                        opacity: 0
                      },
                      children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                style: {
                                  backgroundColor: Primitives__Styling.color({
                                        NAME: "surface",
                                        VAL: "default"
                                      }),
                                  borderColor: Primitives__Styling.color({
                                        NAME: "border",
                                        VAL: {
                                          NAME: "neutral",
                                          VAL: "default"
                                        }
                                      }),
                                  borderRadius: Primitives__Styling.radius("rad_3"),
                                  borderWidth: 1
                                },
                                children: Caml_option.some(JsxRuntime.jsx(RescriptTamagui__Popover.ScrollView.make, {
                                          children: props.children
                                        }))
                              })),
                      x: Caml_option.some(Primitives__Styling.dp(0)),
                      y: Caml_option.some(Primitives__Styling.dp(0)),
                      opacity: 1,
                      animation: "quick"
                    })
              ],
              placement: placement,
              allowFlip: true
            });
}

var Close;

var make = Primitives__Popover;

export {
  DividerWithTitle ,
  Close ,
  Item ,
  make ,
}
/* Primitives__Icon Not a pure module */
