// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Blocks__Text from "@pageblocks/blocks/src/Blocks__Text.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as RescriptLodash from "../../../rescript-lodash/src/RescriptLodash.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Blocks__SettingsRnAdapter from "@pageblocks/blocks/src/utils/Blocks__SettingsRnAdapter.bs.js";

function removeStyles(styleDict, remove) {
  return Object.fromEntries(Object.entries(styleDict).filter(function (param) {
                  return !remove.includes(param[0]);
                }));
}

function makeStyleFromSettings(settings, context) {
  return RescriptLodash.merge([
              removeStyles(Blocks__SettingsRnAdapter.Style.font(settings, context), ["color"]),
              Blocks__SettingsRnAdapter.Style.flex(settings, context, undefined, undefined),
              Blocks__SettingsRnAdapter.Style.background(settings, context),
              Blocks__SettingsRnAdapter.Style.padding(settings, context),
              Blocks__SettingsRnAdapter.Style.margin(settings, context),
              Blocks__SettingsRnAdapter.Style.border(settings, context),
              Blocks__SettingsRnAdapter.Style.position(settings, context)
            ]);
}

function DecidrRenderer__TextBlock$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return Renderer__Settings.getString(settings, code, context);
  };
  var getFloat = function (code) {
    return Renderer__Settings.getFloat(settings, code, context);
  };
  var defaultText = getString("defaultText");
  var text = Core__Option.orElse(getString("text"), Core__Option.map(getFloat("text"), (function (prim) {
              return prim.toString();
            })));
  var newrecord = Caml_obj.obj_dup(makeStyleFromSettings(settings, context));
  return JsxRuntime.jsxs(Primitives__DecidrText.make, {
              style: (newrecord.fontStyle = text !== undefined || defaultText === undefined ? undefined : "italic", newrecord),
              kind: Core__Option.map(getString("kind"), Primitives__DecidrText.kindFromString),
              children: [
                Core__Option.getOr(Core__Option.orElse(text, defaultText), ""),
                Core__Option.getOr(props.children, null)
              ],
              color: text !== undefined || defaultText === undefined ? getString("color") : Primitives__Styling.color("neutral_3"),
              numberOfLines: Core__Option.map(getFloat("numberOfLines"), (function (prim) {
                      return prim | 0;
                    }))
            });
}

var Component = {
  make: DecidrRenderer__TextBlock$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [];

var descriptor = {
  meta: descriptor_meta,
  code: Blocks__Text.blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var blockCode = Blocks__Text.blockCode;

var version = 1;

export {
  blockCode ,
  version ,
  descriptor ,
}
/* Blocks__Text Not a pure module */
