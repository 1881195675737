// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function makeWithTempUploadIfNeeded(prim) {
  return Index.makeWithTempUploadIfNeeded(prim);
}

function deleteFromS3(prim) {
  return Index.deleteFromS3(prim);
}

export {
  makeWithTempUploadIfNeeded ,
  deleteFromS3 ,
}
/* ./index Not a pure module */
