// Generated by ReScript, PLEASE EDIT WITH CARE

import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Error__Message from "../../../primitives/src/error/message/Primitives__Error__Message.bs.js";

function App__ErrorMessage(props) {
  var __textAlign = props.textAlign;
  var textAlign = __textAlign !== undefined ? __textAlign : "center";
  return JsxRuntime.jsx(Primitives__Error__Message.make, {
              error: props.error,
              kind: props.kind,
              textAlign: textAlign
            });
}

var make = App__ErrorMessage;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
