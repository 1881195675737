// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils__Array from "../../../utils/src/array/Utils__Array.bs.js";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";

function sortColumnInfosByPreferredBlockCodes(columnViews, preferences) {
  var preferenceDict = {};
  var defaultPreferenceIndex = preferences.length;
  preferences.forEach(function (code, index) {
        preferenceDict[code] = index;
      });
  return Utils__Array.toSorted(columnViews, (function (cv1, cv2) {
                var preferenceIndexA = Core__Option.getOr(preferenceDict[cv1.componentTypeCode], defaultPreferenceIndex);
                var preferenceIndexB = Core__Option.getOr(preferenceDict[cv2.componentTypeCode], defaultPreferenceIndex);
                return preferenceIndexA - preferenceIndexB | 0;
              }));
}

var preferredBlockCodesForDefaultItemLayoutMapping = [
  "text",
  "address",
  "number",
  "currency"
];

function guessFieldMapping(columnInfos, itemLayoutSchema) {
  var sortedColumnInfos = sortColumnInfosByPreferredBlockCodes(columnInfos, preferredBlockCodesForDefaultItemLayoutMapping);
  return Spaces__Row__Utils.guessFieldMapping(sortedColumnInfos, itemLayoutSchema);
}

function withoutInvalidFieldMapping(fieldMapping, columnInfos, itemLayoutSchema) {
  return Spaces__Row__Utils.withoutInvalidFieldMapping(fieldMapping, columnInfos, itemLayoutSchema);
}

export {
  guessFieldMapping ,
  withoutInvalidFieldMapping ,
}
/* Spaces__Row__Utils Not a pure module */
