// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../../../internal-id/src/DecidrInternalId.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Accessor from "@pageblocks/renderer/src/Renderer__Accessor.bs.js";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "./Spaces__Row__Renderer__BlockContext.bs.js";

function getString(settings, context, key) {
  return Renderer__Settings.getString(settings, key, context);
}

function getFloat(settings, context, key) {
  return Renderer__Settings.getFloat(settings, key, context);
}

function getBool(settings, context, key) {
  return Renderer__Settings.getBool(settings, key, context);
}

function getObject(settings, context, key) {
  return Renderer__Settings.getObject(settings, key, context);
}

function getList(settings, context, key) {
  return Renderer__Settings.getList(settings, key, context);
}

function getRowId(context) {
  return Core__Option.flatMap(Core__Option.flatMap(Renderer__Context.extractValue(context, Renderer__Accessor.Path.makeExn({
                          TAG: "Nested",
                          _0: [{
                              TAG: "Field",
                              _0: Spaces__Row__Renderer__BlockContext.Common.Keys.rowId
                            }]
                        })), Core__JSON.Decode.string), DecidrInternalId.fromString);
}

function getViewId(context) {
  return Core__Option.flatMap(Core__Option.flatMap(Renderer__Context.extractValue(context, Renderer__Accessor.Path.makeExn({
                          TAG: "Nested",
                          _0: [{
                              TAG: "Field",
                              _0: Spaces__Row__Renderer__BlockContext.Common.Keys.viewId
                            }]
                        })), Core__JSON.Decode.string), DecidrNodeId.fromString);
}

function getOrganizationId(context) {
  return Core__Option.flatMap(Core__Option.flatMap(Renderer__Context.extractValue(context, Renderer__Accessor.Path.makeExn({
                          TAG: "Nested",
                          _0: [{
                              TAG: "Field",
                              _0: Spaces__Row__Renderer__BlockContext.Common.Keys.organizationId
                            }]
                        })), Core__JSON.Decode.string), DecidrNodeId.fromString);
}

var Context;

export {
  Context ,
  getString ,
  getFloat ,
  getBool ,
  getObject ,
  getList ,
  getRowId ,
  getViewId ,
  getOrganizationId ,
}
/* DecidrNodeId Not a pure module */
