// DECIDR NOTE - Taken from Vercel AI SDK
// https://github.com/vercel/ai/blob/f038263d3ca4cb04fdbfeb4a05d2610e8402de9e/packages/core/core/util/parse-partial-json.ts

import SecureJSON from "secure-json-parse";
import { fixJson } from "./fix-json";

export function parsePartialJson(jsonText: string | undefined): unknown {
  if (jsonText == null) {
    return undefined;
  }

  try {
    // first attempt a regular JSON parse:
    return SecureJSON.parse(jsonText);
  } catch (ignored) {
    try {
      // then try to fix the partial JSON and parse it:
      const fixedJsonText = fixJson(jsonText);
      return SecureJSON.parse(fixedJsonText);
    } catch (ignored) {
      // ignored
    }
  }

  return undefined;
}
