// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as RescriptLodash from "../../../rescript-lodash/src/RescriptLodash.bs.js";
import * as Primitives__Icon from "../icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as RescriptTamagui__Dialog from "../../../rescript-tamagui/src/modal/RescriptTamagui__Dialog.bs.js";
import * as RescriptTamagui__Icon__X from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__X.bs.js";
import * as Primitives__UnstyledButton from "../button/Primitives__UnstyledButton.bs.js";

function Primitives__Modal$LongContentWebFix(props) {
  var children = props.children;
  if (ReactNative.Platform.OS !== "web") {
    return children;
  } else {
    return JsxRuntime.jsx("div", {
                children: children,
                style: {
                  display: "flex",
                  maxHeight: "70vh",
                  position: "relative",
                  flex: "1"
                }
              });
  }
}

var LongContentWebFix = {
  make: Primitives__Modal$LongContentWebFix
};

var absoluteStyle_position = "absolute";

var absoluteStyle_right = Caml_option.some(Primitives__Styling.space("cs_3"));

var absoluteStyle_top = Caml_option.some(Primitives__Styling.space("cs_3"));

var absoluteStyle = {
  position: absoluteStyle_position,
  right: absoluteStyle_right,
  top: absoluteStyle_top
};

function Primitives__Modal$CloseButton(props) {
  var newrecord = Caml_obj.obj_dup(props.style);
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__Icon.make, {
                    size: "ic_1",
                    color: Primitives__Styling.color("neutral_2"),
                    icon: RescriptTamagui__Icon__X.make
                  }),
              style: (newrecord.zIndex = 4, newrecord.padding = Caml_option.some(Primitives__Styling.space("cs_2")), newrecord.borderWidth = 1, newrecord.borderRadius = Primitives__Styling.radius("rad_2"), newrecord.borderColor = Primitives__Styling.color("neutral_3"), newrecord.backgroundColor = Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }), newrecord),
              onPress: props.onPress
            });
}

function make(param) {
  var onClose = param.onClose;
  var style = Core__Option.getOr(param.style, Primitives__Styling.empty);
  var newrecord = Caml_obj.obj_dup(style);
  return JsxRuntime.jsxs(RescriptTamagui__Dialog.make, {
              children: [
                JsxRuntime.jsxs(RescriptTamagui__Dialog.Portal.make, {
                      style: {
                        alignItems: "center",
                        flexDirection: "row",
                        justifyContent: "center",
                        zIndex: 99999
                      },
                      children: [
                        JsxRuntime.jsx(RescriptTamagui__Dialog.Overlay.make, {}, "overlay"),
                        JsxRuntime.jsxs(RescriptTamagui__Dialog.Content.make, {
                              style: RescriptLodash.merge([
                                    {
                                      backgroundColor: "white",
                                      flex: 1,
                                      maxHeight: Caml_option.some(Primitives__Styling.dp(800)),
                                      maxWidth: Caml_option.some(Primitives__Styling.dp(Core__Option.getOr(param.maxWidth, 1200))),
                                      paddingBottom: Caml_option.some(Primitives__Styling.dp(0)),
                                      paddingLeft: Caml_option.some(Primitives__Styling.dp(0)),
                                      paddingRight: Caml_option.some(Primitives__Styling.dp(0)),
                                      paddingTop: Caml_option.some(Primitives__Styling.dp(0))
                                    },
                                    Core__Option.getOr(param.contentStyle, Primitives__Styling.empty)
                                  ]),
                              children: [
                                JsxRuntime.jsx(Primitives__Group.make, {
                                      style: (newrecord.paddingTop = Caml_option.some(Core__Option.getOr(style.paddingTop, Primitives__Styling.space("cs_3"))), newrecord.overflow = "hidden", newrecord.borderRadius = Primitives__Styling.radius("rad_5"), newrecord.backgroundColor = Primitives__Styling.color({
                                              NAME: "surface",
                                              VAL: "default"
                                            }), newrecord),
                                      children: Caml_option.some(param.children)
                                    }),
                                ReactUtils__SimpleReact.renderIfSome(param.title, (function (title) {
                                        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                                    style: {
                                                      fontWeight: 500,
                                                      left: Caml_option.some(Primitives__Styling.space("cs_3")),
                                                      position: "absolute",
                                                      top: Caml_option.some(Primitives__Styling.space("cs_3")),
                                                      zIndex: 4
                                                    },
                                                    children: Caml_option.some(title)
                                                  });
                                      })),
                                Core__Option.getOr(param.hideCloseButton, false) ? null : JsxRuntime.jsx(RescriptTamagui__Dialog.Close.make, {
                                        children: Caml_option.some(JsxRuntime.jsx(Primitives__Modal$CloseButton, {
                                                  style: absoluteStyle
                                                })),
                                        asChild: true
                                      })
                              ]
                            }, "content")
                      ]
                    }),
                ReactUtils__SimpleReact.renderIfSome(param.showSheetOnBreakpoint, (function (showSheetOnBreakpoint) {
                        return JsxRuntime.jsx(RescriptTamagui__Dialog.Adapt.make, {
                                    children: Caml_option.some(JsxRuntime.jsxs(RescriptTamagui__Dialog.Sheet.make, {
                                              children: [
                                                JsxRuntime.jsx(RescriptTamagui__Dialog.Sheet.Frame.make, {
                                                      children: Caml_option.some(JsxRuntime.jsx(RescriptTamagui__Dialog.Adapt.Contents.make, {}))
                                                    }),
                                                JsxRuntime.jsx(RescriptTamagui__Dialog.Sheet.Overlay.make, {})
                                              ]
                                            })),
                                    when: showSheetOnBreakpoint
                                  });
                      }))
              ],
              open: param.show,
              onOpenChange: (function (isOpen) {
                  if (!isOpen) {
                    return onClose();
                  }
                  
                }),
              modal: param.modal
            });
}

function Primitives__Modal$Experimental$WebStyleInjection(props) {
  return JsxRuntime.jsx("style", {
              dangerouslySetInnerHTML: {
                __html: "body {overflow: hidden;}"
              }
            }, "disable-overflow");
}

function make$1(param) {
  var onClose = param.onClose;
  return JsxRuntime.jsxs(ReactNative.Modal, {
              onDismiss: onClose,
              transparent: true,
              visible: param.show,
              children: [
                JsxRuntime.jsx(Primitives__Modal$Experimental$WebStyleInjection, {}),
                JsxRuntime.jsx(Primitives__Group.make, {
                      style: {
                        backgroundColor: "black",
                        opacity: 0.4,
                        bottom: Caml_option.some(Primitives__Styling.dp(0)),
                        left: Caml_option.some(Primitives__Styling.dp(0)),
                        position: "absolute",
                        right: Caml_option.some(Primitives__Styling.dp(0)),
                        top: Caml_option.some(Primitives__Styling.dp(0)),
                        zIndex: 20
                      },
                      onPress: onClose,
                      cursor: "initial"
                    }),
                JsxRuntime.jsx(Primitives__Group.make, {
                      orientation: "horizontal",
                      style: {
                        alignItems: "center",
                        flex: 1,
                        justifyContent: "center",
                        padding: Caml_option.some(Primitives__Styling.space("ss_1"))
                      },
                      children: Caml_option.some(JsxRuntime.jsxs(Primitives__Group.make, {
                                style: {
                                  borderRadius: Primitives__Styling.radius("rad_6"),
                                  flex: 1,
                                  maxHeight: Caml_option.some(Primitives__Styling.dp(800)),
                                  maxWidth: Caml_option.some(Primitives__Styling.dp(Core__Option.getOr(param.webMaxWidth, 1200))),
                                  overflow: "hidden",
                                  zIndex: 21
                                },
                                children: [
                                  param.children,
                                  JsxRuntime.jsx(Primitives__Modal$CloseButton, {
                                        onPress: (function (param) {
                                            onClose();
                                          }),
                                        style: absoluteStyle
                                      })
                                ]
                              }))
                    })
              ]
            });
}

function make$2(param) {
  var onClose = param.onClose;
  return JsxRuntime.jsx(ReactNative.Modal, {
              onDismiss: onClose,
              visible: param.show,
              children: Caml_option.some(JsxRuntime.jsxs(Primitives__Group.make, {
                        style: {
                          backgroundColor: Primitives__Styling.color({
                                NAME: "background",
                                VAL: "default"
                              }),
                          flex: 1
                        },
                        children: [
                          JsxRuntime.jsx(Primitives__Modal$CloseButton, {
                                onPress: (function (param) {
                                    onClose();
                                  }),
                                style: {
                                  alignSelf: "flex-end",
                                  marginRight: Caml_option.some(Primitives__Styling.space("cs_2")),
                                  marginTop: Caml_option.some(Primitives__Styling.space("cs_2"))
                                }
                              }),
                          param.children
                        ]
                      }))
            });
}

function make$3(props) {
  if (ReactNative.Platform.OS === "web") {
    return JsxRuntime.jsx(make$1, props);
  } else {
    return JsxRuntime.jsx(make$2, props);
  }
}

var Experimental = {
  make: make$3
};

export {
  LongContentWebFix ,
  make ,
  Experimental ,
}
/* absoluteStyle Not a pure module */
