// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrCellId from "../../../cell-id/src/DecidrCellId.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as ReactNative from "react-native";
import * as Blocks__Utils from "@pageblocks/blocks/src/utils/Blocks__Utils.bs.js";
import * as Utils__Base64 from "../../../utils/src/base64/Utils__Base64.bs.js";
import * as RescriptLodash from "../../../rescript-lodash/src/RescriptLodash.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Icon from "../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DecidrErrorBoundary from "../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Popover from "../../../primitives/src/popover/Primitives__Popover.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Spaces__Block__Utils from "./Spaces__Block__Utils.bs.js";
import * as DecidrRenderer__Utils from "../../../renderer/src/utils/DecidrRenderer__Utils.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__IconButton from "../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as DecidrRenderer__Actions from "../../../renderer/src/actions/DecidrRenderer__Actions.bs.js";
import * as Primitives__DecidrImage from "../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";
import * as Spaces__Ui__ChannelButton from "../ui/channelButton/Spaces__Ui__ChannelButton.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as Spaces__Channel__Mutations from "../channel/Spaces__Channel__Mutations.bs.js";
import * as RescriptTamagui__Icon__Info from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Info.bs.js";
import * as RescriptTamagui__Icon__Check from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Check.bs.js";
import * as RescriptTamagui__Icon__Sparkles from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Sparkles.bs.js";
import * as RescriptTamagui__Icon__MessageCircle from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__MessageCircle.bs.js";
import * as SpacesBlockCommonCellChannelOpenerQuery_graphql from "../../../relay-codegen/generated/SpacesBlockCommonCellChannelOpenerQuery_graphql.bs.js";
import * as SpacesBlockCommonCellChannelOpener_query_graphql from "../../../relay-codegen/generated/SpacesBlockCommonCellChannelOpener_query_graphql.bs.js";

function Spaces__Block__Common$SavingIndicator(props) {
  return JsxRuntime.jsx(Primitives__Spinner.make, {
              size: "small",
              color: Primitives__Styling.color("neutral_2")
            });
}

var SavingIndicator = {
  make: Spaces__Block__Common$SavingIndicator
};

function Spaces__Block__Common$ErrorIndicator(props) {
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsx(Primitives__Icon.make, {
                    size: "ic_3",
                    color: Primitives__Styling.color({
                          NAME: "icon",
                          VAL: {
                            NAME: "error",
                            VAL: "default"
                          }
                        }),
                    icon: RescriptTamagui__Icon__Info.make
                  })
            });
}

var ErrorIndicator = {
  make: Spaces__Block__Common$ErrorIndicator
};

function extendActionsPayloadWithEntries(triggerActions, entriesToAdd) {
  return Core__Array.filterMap(triggerActions, (function (action) {
                return Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.map(Core__JSON.Decode.object(action), (function (prim) {
                                          return Object.entries(prim);
                                        })), (function (entries) {
                                      var definedPayloadEntries = Core__Option.getOr(Core__Option.map(Core__Option.flatMap(entries.find(function (param) {
                                                        return param[0] === "payload";
                                                      }), (function (param) {
                                                      return Core__JSON.Decode.object(param[1]);
                                                    })), (function (prim) {
                                                  return Object.entries(prim);
                                                })), []);
                                      return entries.concat([[
                                                    "payload",
                                                    Object.fromEntries(definedPayloadEntries.concat(entriesToAdd))
                                                  ]]);
                                    })), (function (prim) {
                                  return Object.fromEntries(prim);
                                })), (function (prim) {
                              return prim;
                            }));
              }));
}

function extendActionsPayloadWithMetadata(triggerActions, metadata) {
  return extendActionsPayloadWithEntries(triggerActions, DecidrRenderer__Utils.makePayloadEntries(metadata));
}

function makeMultiSelectCallbacks(onTopRightViewSettingsTriggerActions, onTopRightLinkTriggerActions, sourceViewId, rowId, columnId, columnViewId, handleAction) {
  var sourceViewId$1 = Core__Option.getOr(Core__Option.map(Core__Option.map(sourceViewId, DecidrNodeId.toString), (function (prim) {
              return prim;
            })), null);
  var callerRowId = Core__Option.getOr(Core__Option.map(Core__Option.map(rowId, DecidrInternalId.toString), (function (prim) {
              return prim;
            })), null);
  var callerColumnId = Core__Option.getOr(Core__Option.map(Core__Option.map(columnId, DecidrInternalId.toString), (function (prim) {
              return prim;
            })), null);
  var callerColumnViewId = Core__Option.getOr(Core__Option.map(Core__Option.map(columnViewId, DecidrNodeId.toString), (function (prim) {
              return prim;
            })), null);
  var metadata = [
    [
      DecidrRenderer__Actions.PayloadKey.sourceViewId,
      sourceViewId$1
    ],
    [
      DecidrRenderer__Actions.PayloadKey.callerRowId,
      callerRowId
    ],
    [
      DecidrRenderer__Actions.PayloadKey.callerColumnId,
      callerColumnId
    ],
    [
      DecidrRenderer__Actions.PayloadKey.callerColumnViewId,
      callerColumnViewId
    ]
  ];
  var onSettingsPress = Core__Option.flatMap(onTopRightViewSettingsTriggerActions, (function (onTopRightViewSettingsTriggerActions) {
          var actions = extendActionsPayloadWithMetadata(onTopRightViewSettingsTriggerActions, metadata);
          if (actions.length !== 0 && handleAction !== undefined) {
            return (function () {
                      actions.forEach(function (__x) {
                            handleAction(__x);
                          });
                    });
          }
          
        }));
  var onLinkPress = Core__Option.flatMap(onTopRightLinkTriggerActions, (function (onTopRightLinkTriggerActions) {
          var actions = extendActionsPayloadWithMetadata(onTopRightLinkTriggerActions, metadata);
          if (actions.length !== 0 && handleAction !== undefined) {
            return (function () {
                      actions.forEach(function (__x) {
                            handleAction(__x);
                          });
                    });
          }
          
        }));
  return {
          onSettingsPress: onSettingsPress,
          onLinkPress: onLinkPress
        };
}

function makeRelatedListCallbacks(onTopRightViewSettingsTriggerActions, sourceViewId, handleAction) {
  var sourceViewId$1 = Core__Option.getOr(Core__Option.map(Core__Option.map(sourceViewId, DecidrNodeId.toString), (function (prim) {
              return prim;
            })), null);
  var metadata = [[
      DecidrRenderer__Actions.PayloadKey.sourceViewId,
      sourceViewId$1
    ]];
  var onSettingsPress = Core__Option.flatMap(onTopRightViewSettingsTriggerActions, (function (onTopRightViewSettingsTriggerActions) {
          var actions = extendActionsPayloadWithMetadata(onTopRightViewSettingsTriggerActions, metadata);
          if (actions.length !== 0 && handleAction !== undefined) {
            return (function () {
                      actions.forEach(function (__x) {
                            handleAction(__x);
                          });
                    });
          }
          
        }));
  return {
          onSettingsPress: onSettingsPress
        };
}

var Extensions = {};

function Spaces__Block__Common$Container$IconPlaceholder(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "default"
                    }),
                height: Caml_option.some(Primitives__Styling.dp(14)),
                width: Caml_option.some(Primitives__Styling.dp(14))
              }
            });
}

function Spaces__Block__Common$Container(props) {
  var __inline = props.inline;
  var extensions = props.extensions;
  var children = props.children;
  var icon = props.icon;
  var inline = __inline !== undefined ? __inline : false;
  var bottomLeft = Core__Option.flatMap(extensions, (function (param) {
          return param.bottomLeft;
        }));
  var bottomRight = Core__Option.flatMap(extensions, (function (param) {
          return param.bottomRight;
        }));
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                borderRadius: Primitives__Styling.radius("rad_6"),
                padding: Caml_option.some(Primitives__Styling.space("cs_4"))
              },
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                      orientation: "horizontal",
                      style: {
                        alignItems: "center"
                      },
                      children: [
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                              orientation: "horizontal",
                              style: {
                                alignItems: "center"
                              },
                              children: [
                                icon !== undefined ? JsxRuntime.jsx(Primitives__DecidrImage.make, {
                                        resizeMode: "contain",
                                        tintColor: Primitives__Styling.colorValue({
                                              NAME: "text",
                                              VAL: {
                                                NAME: "on_background",
                                                VAL: "subdued"
                                              }
                                            }),
                                        src: icon,
                                        size: {
                                          TAG: "Square",
                                          _0: Primitives__Styling.dp(14)
                                        }
                                      }) : JsxRuntime.jsx(Spaces__Block__Common$Container$IconPlaceholder, {}),
                                JsxRuntime.jsx(Primitives__DecidrText.make, {
                                      children: Caml_option.some(props.name),
                                      numberOfLines: 1
                                    })
                              ]
                            }),
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                              orientation: "horizontal",
                              style: {
                                alignItems: "center",
                                flex: 1,
                                justifyContent: "flex-end"
                              },
                              children: [
                                inline ? children : null,
                                Core__Option.getOr(Core__Option.flatMap(extensions, (function (param) {
                                            return param.topRight;
                                          })), null)
                              ]
                            })
                      ]
                    }),
                inline ? null : children,
                Core__Option.isSome(bottomLeft) || Core__Option.isSome(bottomRight) ? JsxRuntime.jsxs(Primitives__Group.make, {
                        gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                        orientation: "horizontal",
                        reverse: Core__Option.isNone(bottomLeft),
                        style: {
                          alignItems: "center",
                          justifyContent: "space-between"
                        },
                        children: [
                          Core__Option.getOr(bottomLeft, null),
                          Core__Option.getOr(bottomRight, null)
                        ]
                      }) : null
              ]
            });
}

function Spaces__Block__Common$ContentContainer(props) {
  var extensions = props.extensions;
  var bottomLeft = Core__Option.flatMap(extensions, (function (param) {
          return param.bottomLeft;
        }));
  var bottomRight = Core__Option.flatMap(extensions, (function (param) {
          return param.bottomRight;
        }));
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                borderRadius: Primitives__Styling.radius("rad_6"),
                paddingBottom: Caml_option.some(Primitives__Styling.space("cs_2")),
                paddingLeft: Caml_option.some(Primitives__Styling.space("cs_4")),
                paddingRight: Caml_option.some(Primitives__Styling.space("cs_4")),
                paddingTop: Caml_option.some(Primitives__Styling.space("cs_2"))
              },
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      orientation: "horizontal",
                      children: [
                        JsxRuntime.jsx(ReactNative.View, {
                              style: {
                                flex: 1
                              },
                              children: Caml_option.some(props.children)
                            }),
                        JsxRuntime.jsx(Primitives__Group.make, {
                              style: {
                                borderBottomWidth: 1,
                                borderColor: Primitives__Styling.color("transparent"),
                                paddingBottom: Caml_option.some(Primitives__Styling.space("cs_4")),
                                paddingTop: Caml_option.some(Primitives__Styling.space("cs_4"))
                              },
                              children: Caml_option.some(Core__Option.getOr(Core__Option.flatMap(extensions, (function (param) {
                                              return param.topRight;
                                            })), null))
                            })
                      ]
                    }),
                Core__Option.isSome(bottomLeft) || Core__Option.isSome(bottomRight) ? JsxRuntime.jsxs(Primitives__Group.make, {
                        gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                        orientation: "horizontal",
                        reverse: Core__Option.isNone(bottomLeft),
                        style: {
                          alignItems: "center",
                          justifyContent: "space-between"
                        },
                        children: [
                          Core__Option.getOr(bottomLeft, null),
                          Core__Option.getOr(bottomRight, null)
                        ]
                      }) : null
              ]
            });
}

var ContentContainer = {
  make: Spaces__Block__Common$ContentContainer
};

function makeCellChannelHandler(triggerActions, handleAction, organizationId) {
  var onClickActions = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__JSON.Decode.object(triggerActions), (function (__x) {
                  return __x["onChannelClick"];
                })), Blocks__Utils.getActions), []);
  if (onClickActions.length !== 0 && handleAction !== undefined && organizationId !== undefined) {
    return (function (channelId) {
              extendActionsPayloadWithEntries(onClickActions, [[
                        DecidrRenderer__Actions.PayloadKey.toString(DecidrRenderer__Actions.PayloadKey.channelId),
                        DecidrNodeId.toString(channelId)
                      ]]).forEach(function (__x) {
                    handleAction(__x);
                  });
            });
  }
  
}

function Spaces__Block__Common$CellChannelOpener$ChannelButton(props) {
  var numberOfUnreadMessages = props.numberOfUnreadMessages;
  var __creatingChannel = props.creatingChannel;
  var __disabled = props.disabled;
  var onPress = props.onPress;
  var disabled = __disabled !== undefined ? __disabled : false;
  var creatingChannel = __creatingChannel !== undefined ? __creatingChannel : false;
  return JsxRuntime.jsx(Primitives__Popover.Item.make, {
              color: disabled || creatingChannel ? Primitives__Styling.color({
                      NAME: "icon",
                      VAL: {
                        NAME: "neutral",
                        VAL: "subdued"
                      }
                    }) : Core__Option.getOr(props.color, Primitives__Styling.color("neutral_1")),
              text: "Cell Chat",
              icon: RescriptTamagui__Icon__MessageCircle.make,
              onPress: (function () {
                  if (disabled || creatingChannel) {
                    return ;
                  } else {
                    return onPress();
                  }
                }),
              children: Caml_option.some(numberOfUnreadMessages !== undefined && numberOfUnreadMessages !== 0 ? JsxRuntime.jsx(Spaces__Ui__ChannelButton.UnreadMessagesIndicator.make, {}) : null)
            });
}

function Spaces__Block__Common$CellChannelOpener$Loading(props) {
  return JsxRuntime.jsx(Spaces__Block__Common$CellChannelOpener$ChannelButton, {
              onPress: (function () {
                  
                }),
              disabled: true
            });
}

var convertFragment = SpacesBlockCommonCellChannelOpener_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockCommonCellChannelOpener_query_graphql.node, convertFragment, fRef);
}

function Spaces__Block__Common$CellChannelOpener$ChannelOpener(props) {
  var onChannelPress = props.onChannelPress;
  var organizationId = props.organizationId;
  var columnId = props.columnId;
  var rowId = props.rowId;
  var match = use(props.queryRef);
  var match$1 = Spaces__Channel__Mutations.useCreateChannel();
  var creatingChannel = match$1[1];
  var createChannel = match$1[0];
  var match$2 = match.cell.cell;
  if (match$2 !== undefined) {
    var channels = match$2.channels;
    if (channels.length !== 0) {
      var channel = channels[0];
      return JsxRuntime.jsx(Spaces__Block__Common$CellChannelOpener$ChannelButton, {
                  onPress: (function () {
                      onChannelPress(channel.id);
                    }),
                  numberOfUnreadMessages: channel.numberOfUnreadMessages
                });
    }
    
  }
  var cellId = DecidrCellId.make(rowId, columnId);
  var cellResourceId = Utils__Base64.encode(DecidrCellId.toString(cellId));
  return JsxRuntime.jsx(Spaces__Block__Common$CellChannelOpener$ChannelButton, {
              onPress: (function () {
                  createChannel(organizationId, "Cell (" + DecidrInternalId.toString(rowId) + "/" + DecidrInternalId.toString(columnId) + ")", [], "CellResource", undefined, Caml_option.some(cellResourceId), (function (channelId) {
                          onChannelPress(channelId);
                        }), undefined, (function (store, response) {
                          RelayUtils.Helpers.addToArray(store, DecidrNodeId.magicallyFromCellId(cellId), response.createChannel.channel.id, "channels");
                        }));
                }),
              disabled: creatingChannel,
              creatingChannel: creatingChannel
            });
}

var convertVariables = SpacesBlockCommonCellChannelOpenerQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockCommonCellChannelOpenerQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockCommonCellChannelOpenerQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockCommonCellChannelOpenerQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockCommonCellChannelOpenerQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockCommonCellChannelOpenerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockCommonCellChannelOpenerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockCommonCellChannelOpenerQuery_graphql.node, convertVariables);

function Spaces__Block__Common$CellChannelOpener(props) {
  var columnId = props.columnId;
  var rowId = props.rowId;
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$1({
        columnId: DecidrNodeId.magicallyFromInternalId(columnId),
        rowId: DecidrNodeId.magicallyFromInternalId(rowId),
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "App__Spaces__Block__Common ChannelOpener",
              children: JsxRuntime.jsx(Primitives__Suspense.make, {
                    fallback: Caml_option.some(JsxRuntime.jsx(Spaces__Block__Common$CellChannelOpener$Loading, {})),
                    children: JsxRuntime.jsx(Spaces__Block__Common$CellChannelOpener$ChannelOpener, {
                          queryRef: match$1.fragmentRefs,
                          rowId: rowId,
                          columnId: columnId,
                          organizationId: props.organizationId,
                          onChannelPress: props.onChannelPress
                        })
                  }),
              FallbackComponent: (function (param) {
                  return null;
                })
            });
}

function Spaces__Block__Common$ReadOnlyText(props) {
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  return JsxRuntime.jsx(Primitives__DecidrText.make, {
              style: RescriptLodash.merge([
                    style,
                    Primitives__DecidrText.WhiteSpacePreWrap.hack
                  ]),
              children: Caml_option.some(props.children),
              tag: "p"
            });
}

var ReadOnlyText = {
  make: Spaces__Block__Common$ReadOnlyText
};

function Spaces__Block__Common$WithReadOnlyText(props) {
  if (props.isReadOnly) {
    return JsxRuntime.jsx(Spaces__Block__Common$ReadOnlyText, {
                children: props.text
              });
  } else {
    return props.children;
  }
}

var WithReadOnlyText = {
  make: Spaces__Block__Common$WithReadOnlyText
};

function Spaces__Block__Common$EditableViewer(props) {
  var onDismiss = props.onDismiss;
  var onEdit = props.onEdit;
  var renderEditor = props.renderEditor;
  switch (props.mode) {
    case "NoValue" :
        return renderEditor(false);
    case "Edit" :
        return JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                    orientation: "horizontal",
                    children: [
                      renderEditor(true),
                      JsxRuntime.jsx(Primitives__IconButton.make, {
                            kind: "secondary",
                            icon: RescriptTamagui__Icon__Check.make,
                            onPress: (function (param) {
                                onDismiss();
                              }),
                            style: {
                              alignSelf: "flex-start"
                            }
                          })
                    ]
                  });
    case "View" :
        return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                    children: props.viewer,
                    style: {
                      minHeight: Caml_option.some(Primitives__Styling.dp(40))
                    },
                    onPress: (function (param) {
                        onEdit();
                      })
                  });
    
  }
}

var EditableViewer = {
  make: Spaces__Block__Common$EditableViewer
};

function Spaces__Block__Common$AIFillBlockMenuItem(props) {
  var columnViewId = props.columnViewId;
  var rowId = props.rowId;
  var columnId = props.columnId;
  var componentTypeCode = props.componentTypeCode;
  var toast = Primitives__DecidrToast.use();
  var match = Spaces__Cells__Mutations.useAutoFillCell();
  var autoFillCell = match[0];
  if (componentTypeCode !== undefined && Spaces__Block__Utils.componentTypeSupportsAutoFill(componentTypeCode)) {
    return JsxRuntime.jsx(Primitives__Popover.Item.make, {
                color: Primitives__Styling.color("secondary_0"),
                text: "Tasks",
                icon: RescriptTamagui__Icon__Sparkles.make,
                onPress: (function () {
                    autoFillCell(rowId, columnId, columnViewId, (function () {
                            toast.show("Value queued for autofill", {});
                          }), (function (message, reason) {
                            toast.error(message, reason);
                          }));
                  })
              });
  } else {
    return null;
  }
}

var AIFillBlockMenuItem = {
  make: Spaces__Block__Common$AIFillBlockMenuItem
};

var Container = {
  Extensions: Extensions,
  make: Spaces__Block__Common$Container
};

var CellChannelOpener = {
  make: Spaces__Block__Common$CellChannelOpener
};

export {
  SavingIndicator ,
  ErrorIndicator ,
  makeMultiSelectCallbacks ,
  extendActionsPayloadWithEntries ,
  makeRelatedListCallbacks ,
  Container ,
  ContentContainer ,
  makeCellChannelHandler ,
  CellChannelOpener ,
  ReadOnlyText ,
  WithReadOnlyText ,
  EditableViewer ,
  AIFillBlockMenuItem ,
}
/* use Not a pure module */
