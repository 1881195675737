// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as ReactRelay from "react-relay";
import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

function makeRefetchVariables(skipQuery, sourceViewId) {
  return {
          skipQuery: skipQuery,
          sourceViewId: sourceViewId
        };
}

var Types = {
  makeRefetchVariables: makeRefetchVariables
};

function unwrap_response_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["View"]);
}

var variablesConverter = {"__root":{"sourceViewId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"node_View":{"f":""},"node":{"u":"response_node"}}};

var wrapResponseConverterMap = {
  response_node: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"node_View":{"f":""},"node":{"u":"response_node"}}};

var responseConverterMap = {
  response_node: unwrap_response_node
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  convertWrapRawResponse: convertWrapResponse,
  convertRawResponse: convertResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "skipQuery"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "sourceViewId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "sourceViewId"
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery",
    "selections": [
      {
        "condition": "skipQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "SpacesUiBlockMenuItemsViewSettingsMenu_view"
                  }
                ],
                "type": "View",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery",
    "selections": [
      {
        "condition": "skipQuery",
        "kind": "Condition",
        "passingValue": false,
        "selections": [
          {
            "alias": null,
            "args": (v2/*: any*/),
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "id",
                "storageKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "coreConfig",
                    "storageKey": null
                  }
                ],
                "type": "View",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "1cafee5150234384d4e48e3cce9732b3",
    "id": null,
    "metadata": {},
    "name": "SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery",
    "operationKind": "query",
    "text": "query SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery(\n  $sourceViewId: ID!\n  $skipQuery: Boolean!\n) {\n  node(id: $sourceViewId) @skip(if: $skipQuery) {\n    __typename\n    ... on View {\n      ...SpacesUiBlockMenuItemsViewSettingsMenu_view\n    }\n    id\n  }\n}\n\nfragment SpacesUiBlockMenuItemsViewSettingsMenu_view on View {\n  id\n  coreConfig\n}\n"
  }
};
})());

function load(environment, variables, fetchPolicy, fetchKey, networkCacheConfig) {
  return ReactRelay.loadQuery(environment, node, convertVariables(variables), {
              fetchKey: fetchKey,
              fetchPolicy: fetchPolicy,
              networkCacheConfig: networkCacheConfig
            });
}

function queryRefToObservable(token) {
  return Caml_option.nullable_to_opt(token.source);
}

function queryRefToPromise(token) {
  return new Promise((function (resolve, param) {
                var o = queryRefToObservable(token);
                if (o !== undefined) {
                  Caml_option.valFromOption(o).subscribe({
                        complete: (function () {
                            resolve({
                                  TAG: "Ok",
                                  _0: undefined
                                });
                          })
                      });
                  return ;
                } else {
                  return resolve({
                              TAG: "Error",
                              _0: undefined
                            });
                }
              }));
}

var wrap_response_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_response_node ,
  wrap_response_node ,
  Internal ,
  Utils ,
  node ,
  load ,
  queryRefToObservable ,
  queryRefToPromise ,
}
/* node Not a pure module */
