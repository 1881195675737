// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as RescriptNext__Head from "../../../rescript-next/src/head/RescriptNext__Head.bs.js";
import * as DecidrRenderer__Blocks__Utils from "./DecidrRenderer__Blocks__Utils.bs.js";

var blockCode = "page-title";

function DecidrRenderer__Blocks__PageTitle$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return DecidrRenderer__Blocks__Utils.getString(settings, context, code);
  };
  var title = getString("title");
  if (title !== undefined) {
    return JsxRuntime.jsx(RescriptNext__Head.make, {
                children: JsxRuntime.jsx("title", {
                      children: title
                    })
              });
  } else {
    return null;
  }
}

var Component = {
  make: DecidrRenderer__Blocks__PageTitle$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [{
    code: "general",
    name: "General",
    settings: [{
        code: "title",
        name: "Title",
        dataType: {
          TAG: "String",
          _0: {}
        }
      }]
  }];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

var minRendererVersion = Renderer__Version.current;

export {
  blockCode ,
  version ,
  minRendererVersion ,
  descriptor ,
}
/* react/jsx-runtime Not a pure module */
