// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App__Alert from "../../../app/src/alert/App__Alert.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as Core__Promise from "@rescript/core/src/Core__Promise.bs.js";
import * as ErrorReporting from "../../../error-reporting/src/ErrorReporting.bs.js";
import * as Utils__Ignored from "../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as ExpoImagePicker from "expo-image-picker";
import * as JsxRuntime from "react/jsx-runtime";
import * as Caml_js_exceptions from "rescript/lib/es6/caml_js_exceptions.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__UploadUtils from "../utils/upload/Spaces__UploadUtils.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__IconButton from "../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as Primitives__DecidrImage from "../../../primitives/src/image/Primitives__DecidrImage.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";
import * as Spaces__Ui__ImagePreview from "../ui/imagePreview/Spaces__Ui__ImagePreview.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__Icon__Trash2 from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Trash2.bs.js";
import * as RescriptTamagui__Icon__Upload from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Upload.bs.js";

function Spaces__Block__Image(props) {
  var columnId = props.columnId;
  var rowId = props.rowId;
  var value = props.value;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var error = match[0];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowAlert = match$2[1];
  var match$3 = React.useState(function () {
        return false;
      });
  var setShowImagePreview = match$3[1];
  var match$4 = Spaces__Cells__Mutations.useUpdateImageCell(rowId, columnId, props.columnViewId);
  var updateImageCell = match$4[0];
  var match$5 = Spaces__Cells__Mutations.useDeleteCell(rowId, columnId);
  var deletingImageCell = match$5[1];
  var deleteImageCell = match$5[0];
  var uploadPhoto = function () {
    setError(function (param) {
          
        });
    Utils__Ignored.promiseWithPayload(ExpoImagePicker.requestMediaLibraryPermissionsAsync().then(function (result) {
              if (result.granted) {
                Utils__Ignored.promiseWithPayload(ExpoImagePicker.launchImageLibraryAsync(undefined).then(function (imageLibraryResult) {
                          setLoading(function (param) {
                                return true;
                              });
                          var assets = imageLibraryResult.assets;
                          if (imageLibraryResult.canceled) {
                            setLoading(function (param) {
                                  return false;
                                });
                          } else {
                            var exit = 0;
                            if (assets !== undefined && assets.length > 0) {
                              var match = assets[0];
                              var uri = match.uri;
                              var exit$1 = 0;
                              if (uri !== undefined && match.width !== undefined && match.height !== undefined) {
                                var platform = ReactNative.Platform.OS;
                                if (platform === "android" || platform === "web" || platform === "ios") {
                                  Utils__Ignored.promise(Core__Promise.$$catch(Spaces__UploadUtils.makeWithTempUploadIfNeeded({
                                                uri: uri,
                                                next: (function (param) {
                                                    var key = param.key;
                                                    updateImageCell(param.url, (function () {
                                                            Utils__Ignored.promise(Spaces__UploadUtils.deleteFromS3(key));
                                                            setLoading(function (param) {
                                                                  return false;
                                                                });
                                                          }), (function (error) {
                                                            setError(function (param) {
                                                                  return {
                                                                          TAG: "UploadError",
                                                                          _0: error
                                                                        };
                                                                });
                                                            setLoading(function (param) {
                                                                  return false;
                                                                });
                                                          }));
                                                    return Promise.resolve();
                                                  })
                                              }), (function (exn) {
                                              var error = Core__Option.flatMap(Caml_js_exceptions.as_js_exn(exn), (function (x) {
                                                      return x.message;
                                                    }));
                                              if (error !== undefined) {
                                                console.log("error", error);
                                                if (error.includes("Network Error")) {
                                                  setError(function (param) {
                                                        return "NetworkConnectionError";
                                                      });
                                                } else {
                                                  setError(function (param) {
                                                        return {
                                                                TAG: "UnknownError",
                                                                _0: error
                                                              };
                                                      });
                                                }
                                              } else {
                                                setError(function (param) {
                                                      return {
                                                              TAG: "UnknownError",
                                                              _0: undefined
                                                            };
                                                    });
                                              }
                                              setLoading(function (param) {
                                                    return false;
                                                  });
                                              return Promise.resolve();
                                            })));
                                } else {
                                  ErrorReporting.captureWarning("Unsupported platform", "Spaces__Block__Image uploadPhoto", {
                                        platform: platform
                                      });
                                  setError(function (param) {
                                        return "UnsupportedPlatform";
                                      });
                                }
                              } else {
                                exit$1 = 2;
                              }
                              if (exit$1 === 2) {
                                ErrorReporting.captureWarning("Missing image info", "Spaces__Block__Image uploadPhoto", {
                                      assets: assets
                                    });
                                setError(function (param) {
                                      return "MissingImageInfo";
                                    });
                              }
                              
                            } else {
                              exit = 1;
                            }
                            if (exit === 1) {
                              ErrorReporting.captureWarning("No image selected", "Spaces__Block__Image uploadPhoto", {
                                    assets: assets
                                  });
                              setError(function (param) {
                                    return "NoImageSelected";
                                  });
                            }
                            
                          }
                          return imageLibraryResult;
                        }));
              } else {
                setError(function (param) {
                      return "PermissionDenied";
                    });
              }
              setLoading(function (param) {
                    return false;
                  });
              return result;
            }));
  };
  var deleteImage = function () {
    deleteImageCell((function (error) {
            setError(function (param) {
                  return {
                          TAG: "DeleteError",
                          _0: error
                        };
                });
          }), (function () {
            setShowAlert(function (param) {
                  return false;
                });
          }));
  };
  var tmp;
  if (error !== undefined) {
    var tmp$1;
    if (typeof error !== "object") {
      switch (error) {
        case "PermissionDenied" :
            tmp$1 = "Permission not granted. You need to grant permission in order to upload a photo.";
            break;
        case "NoImageSelected" :
            tmp$1 = "No image selected.";
            break;
        case "UnsupportedPlatform" :
            tmp$1 = "Unsupported platform.";
            break;
        case "MissingImageInfo" :
            tmp$1 = "Unexpected Error: Failed to get image info.";
            break;
        case "NetworkConnectionError" :
            tmp$1 = "Network connection error.";
            break;
        
      }
    } else {
      switch (error.TAG) {
        case "UploadError" :
        case "DeleteError" :
            tmp$1 = error._0;
            break;
        case "UnknownError" :
            var error$1 = error._0;
            tmp$1 = error$1 !== undefined ? "Unexpected error: " + error$1 : "Unknown error. Please try again.";
            break;
        
      }
    }
    tmp = JsxRuntime.jsx(Primitives__DecidrText.make, {
          style: {
            fontWeight: 500
          },
          children: Caml_option.some(tmp$1),
          color: Primitives__Styling.color({
                NAME: "text",
                VAL: {
                  NAME: "error",
                  VAL: "default"
                }
              })
        });
  } else {
    tmp = null;
  }
  var tmp$2;
  if (value !== undefined) {
    var match$6 = value.size;
    var height = match$6.height;
    var width = match$6.width;
    var url = value.url;
    var aspectRatio = width / height;
    var match$7 = width > height ? [
        {
          TAG: "Rect",
          width: Primitives__Styling.dp(300),
          height: Primitives__Styling.dp(300 / aspectRatio)
        },
        300
      ] : (
        height > width ? [
            {
              TAG: "Rect",
              width: Primitives__Styling.dp(300 * aspectRatio),
              height: Primitives__Styling.dp(300)
            },
            300 * aspectRatio
          ] : [
            {
              TAG: "Rect",
              width: Primitives__Styling.dp(300),
              height: Primitives__Styling.dp(300)
            },
            300
          ]
      );
    tmp$2 = JsxRuntime.jsxs(JsxRuntime.Fragment, {
          children: [
            JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                  children: JsxRuntime.jsx(Primitives__DecidrImage.make, {
                        src: url,
                        size: match$7[0],
                        style: {
                          borderRadius: Primitives__Styling.radiusDp(4)
                        },
                        alt: props.name
                      }),
                  style: {
                    maxWidth: Caml_option.some(Primitives__Styling.dp(match$7[1]))
                  },
                  onPress: (function (param) {
                      setShowImagePreview(function (param) {
                            return true;
                          });
                    })
                }),
            JsxRuntime.jsx(Spaces__Ui__ImagePreview.make, {
                  url: url,
                  width: 400,
                  height: 400 / aspectRatio,
                  show: match$3[0],
                  onClose: (function () {
                      setShowImagePreview(function (param) {
                            return false;
                          });
                    })
                })
          ]
        });
  } else {
    tmp$2 = JsxRuntime.jsx(Primitives__DecidrText.make, {
          children: "No image"
        });
  }
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              style: {
                minHeight: Caml_option.some(Primitives__Styling.dp(40)),
                paddingTop: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: [
                tmp,
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                      orientation: "horizontal",
                      children: [
                        JsxRuntime.jsx(Primitives__Group.make, {
                              style: {
                                flex: 1
                              },
                              children: Caml_option.some(tmp$2)
                            }),
                        JsxRuntime.jsxs(Primitives__Group.make, {
                              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                              orientation: "vertical",
                              style: {
                                alignItems: "flex-end"
                              },
                              children: [
                                JsxRuntime.jsx(Primitives__IconButton.make, {
                                      icon: RescriptTamagui__Icon__Upload.make,
                                      onPress: (function (param) {
                                          uploadPhoto();
                                        }),
                                      disabled: deletingImageCell,
                                      busy: loading
                                    }),
                                ReactUtils__SimpleReact.whenTrue(Core__Option.isSome(value), {
                                      LAZY_DONE: false,
                                      VAL: (function () {
                                          return JsxRuntime.jsx(Primitives__IconButton.make, {
                                                      icon: RescriptTamagui__Icon__Trash2.make,
                                                      onPress: (function (param) {
                                                          setShowAlert(function (param) {
                                                                return true;
                                                              });
                                                        }),
                                                      disabled: loading,
                                                      busy: deletingImageCell
                                                    });
                                        })
                                    })
                              ]
                            }),
                        JsxRuntime.jsx(App__Alert.make, {
                              title: "Delete image",
                              description: "Are you sure sure you want to delete this image?",
                              show: match$2[0],
                              onConfirm: deleteImage,
                              onCancel: (function () {
                                  setShowAlert(function (param) {
                                        return false;
                                      });
                                }),
                              busy: deletingImageCell
                            })
                      ]
                    })
              ]
            });
}

var make = Spaces__Block__Image;

export {
  make ,
}
/* react Not a pure module */
