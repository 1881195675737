/* TypeScript file generated from App__Analytics__Utils.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as App__Analytics__UtilsJS from './App__Analytics__Utils.bs.js';

export type pageViewProperties = {
  readonly path: (undefined | string); 
  readonly referrer: (undefined | string); 
  readonly search: (undefined | string); 
  readonly title: (undefined | string)
};

export const trackPageView: (_1:pageViewProperties) => void = App__Analytics__UtilsJS.trackPageView as any;
