// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Spaces__Row from "../row/Spaces__Row.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__View from "../view/Spaces__View.bs.js";
import * as Utils__Array from "../../../utils/src/array/Utils__Array.bs.js";
import * as ReactNative from "react-native";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Grid from "../../../primitives/src/grid/Primitives__Grid.bs.js";
import * as Primitives__Icon from "../../../primitives/src/icon/Primitives__Icon.bs.js";
import * as Utils__Countable from "../../../utils/src/countable/Utils__Countable.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Spaces__View__Utils from "../view/Spaces__View__Utils.bs.js";
import * as Primitives__Suspense from "../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__IconButton from "../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as Primitives__TextButton from "../../../primitives/src/button/Primitives__TextButton.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Spaces__Renderer__Meta from "../renderer/Spaces__Renderer__Meta.bs.js";
import * as Spaces__Row__Mutations from "../row/Spaces__Row__Mutations.bs.js";
import * as DecidrRenderer__Actions from "../../../renderer/src/actions/DecidrRenderer__Actions.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";
import * as Spaces__Row__ItemRenderer from "../row/renderer/Spaces__Row__ItemRenderer.bs.js";
import * as Primitives__UnstyledButton from "../../../primitives/src/button/Primitives__UnstyledButton.bs.js";
import * as RescriptTamagui__Icon__Link from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Link.bs.js";
import * as RescriptTamagui__Icon__Plus from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Plus.bs.js";
import * as Spaces__ComponentType__Code from "../componentType/Spaces__ComponentType__Code.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as Spaces__View__DefaultItemLayout from "../view/Spaces__View__DefaultItemLayout.bs.js";
import * as Spaces__ItemLayoutRendererContext from "../renderer/Spaces__ItemLayoutRendererContext.bs.js";
import * as SpacesBlockMultiSelectQuery_graphql from "../../../relay-codegen/generated/SpacesBlockMultiSelectQuery_graphql.bs.js";
import * as SpacesBlockMultiSelect_view_graphql from "../../../relay-codegen/generated/SpacesBlockMultiSelect_view_graphql.bs.js";
import * as Spaces__Row__Renderer__LinkRowsModal from "../row/renderer/Spaces__Row__Renderer__LinkRowsModal.bs.js";
import * as SpacesBlockMultiSelect_multiSelectCell_graphql from "../../../relay-codegen/generated/SpacesBlockMultiSelect_multiSelectCell_graphql.bs.js";
import * as SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql from "../../../relay-codegen/generated/SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.bs.js";
import * as SpacesBlockMultiSelectInlineSourceViewRows_query_graphql from "../../../relay-codegen/generated/SpacesBlockMultiSelectInlineSourceViewRows_query_graphql.bs.js";

function Spaces__Block__MultiSelect$EmptyInlineSourceViewRows(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                        style: {
                          textAlign: "center"
                        },
                        children: "No available items to link",
                        color: Primitives__Styling.color({
                              NAME: "text",
                              VAL: {
                                NAME: "on_surface",
                                VAL: "subdued"
                              }
                            })
                      }))
            });
}

function Spaces__Block__MultiSelect$InlineSourceViewRowsLinkingIndicator(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                left: Caml_option.some(Primitives__Styling.dp(0)),
                padding: Caml_option.some(Primitives__Styling.space("cs_2")),
                position: "absolute",
                right: Caml_option.some(Primitives__Styling.dp(0)),
                top: Caml_option.some(Primitives__Styling.space("cs_3")),
                zIndex: 10
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {}))
            });
}

var convertFragment = SpacesBlockMultiSelectInlineSourceViewRows_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockMultiSelectInlineSourceViewRows_query_graphql.node, convertFragment, fRef);
}

function Spaces__Block__MultiSelect$InlineSourceViewRows$Data(props) {
  var callerColumnViewId = props.callerColumnViewId;
  var linking = props.linking;
  var selectedRowInfos = props.selectedRowInfos;
  var onLinkRows = props.onLinkRows;
  var match = Spaces__ItemLayoutRendererContext.use();
  var handleRowAction = match.handleRowAction;
  var toast = Primitives__DecidrToast.use();
  var match$1 = use(props.queryRef);
  var node = match$1.node;
  var numberOfAllowedLinks = node !== undefined && node.__typename === "View" ? Spaces__Row__Renderer__LinkRowsModal.getNumberOfAllowedLinks(node.coreConfig) : undefined;
  var alreadySelectedRows = DecidrInternalId.$$Set.fromArray(selectedRowInfos.map(function (param) {
            return param.row.id;
          }));
  if (node !== undefined) {
    if (node.__typename === "View") {
      return JsxRuntime.jsx(Primitives__Group.make, {
                  gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                  style: {
                    backgroundColor: Primitives__Styling.color({
                          NAME: "background",
                          VAL: "default"
                        }),
                    flex: 1
                  },
                  children: Caml_option.some(JsxRuntime.jsx(Spaces__View.Data.CustomLayout.make, {
                            viewRef: node.fragmentRefs,
                            handleRowAction: (function (viewId, rowId, action) {
                                var parsedAction = DecidrRenderer__Actions.parseAction(action);
                                if (parsedAction !== undefined && typeof parsedAction !== "object" && parsedAction === "NavigateToRow") {
                                  if (linking) {
                                    return toast.error("Linking in progress", "Another item is being linked. Please wait for it to complete.");
                                  }
                                  var match = DecidrInternalId.$$Set.has(alreadySelectedRows, rowId);
                                  if (!match) {
                                    if (numberOfAllowedLinks !== undefined) {
                                      if (numberOfAllowedLinks > DecidrInternalId.$$Set.size(alreadySelectedRows)) {
                                        return onLinkRows([rowId], [], (function (message) {
                                                      toast.error("Can't link", message);
                                                    }));
                                      } else {
                                        return toast.error("Can't add more links", Core__Option.map(Utils__Countable.make(numberOfAllowedLinks, "link", "links", "links"), (function (countable) {
                                                          return "You are limited to " + countable;
                                                        })));
                                      }
                                    } else {
                                      return onLinkRows([rowId], [], (function (message) {
                                                    toast.error("Can't link", message);
                                                  }));
                                    }
                                  }
                                  var rowIdsToRemove = Core__Option.getOr(Core__Option.map(selectedRowInfos.find(function (selectedRowInfo) {
                                                return Caml_obj.equal(selectedRowInfo.row.id, rowId);
                                              }), (function (param) {
                                              return [param.id];
                                            })), []);
                                  return onLinkRows([], rowIdsToRemove, (function (message) {
                                                toast.error("Can't unlink", message);
                                              }));
                                }
                                handleRowAction(viewId, rowId, action, Spaces__Renderer__Meta.make([[
                                            Spaces__Renderer__Meta.Key.linkColumnViewId,
                                            DecidrNodeId.toString(callerColumnViewId)
                                          ]]));
                              }),
                            modifyItemLayout: (function (rowId) {
                                return DecidrInternalId.$$Set.has(alreadySelectedRows, rowId);
                              }),
                            overrideDefaultViewItemLayoutModifier: Spaces__View__DefaultItemLayout.MultiSelectInlineItemLayout.make,
                            renderList: (function (data, keyExtractor, renderItem) {
                                if (data.length === 0) {
                                  return JsxRuntime.jsx(Spaces__Block__MultiSelect$EmptyInlineSourceViewRows, {});
                                } else {
                                  return JsxRuntime.jsxs(Primitives__Group.make, {
                                              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                                              style: {
                                                padding: Caml_option.some(Primitives__Styling.space("cs_3"))
                                              },
                                              children: [
                                                ReactUtils__SimpleReact.whenTrue(linking, {
                                                      LAZY_DONE: false,
                                                      VAL: (function () {
                                                          return JsxRuntime.jsx(Spaces__Block__MultiSelect$InlineSourceViewRowsLinkingIndicator, {});
                                                        })
                                                    }),
                                                data.map(function (item) {
                                                      return JsxRuntime.jsx(React.Fragment, {
                                                                  children: renderItem(item)
                                                                }, keyExtractor(item));
                                                    })
                                              ]
                                            });
                                }
                              })
                          }))
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a View", "Spaces__Row__Renderer__LinkRowsModal Data", {
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Row__Renderer__LinkRowsModal Data", undefined);
  return null;
}

var convertVariables = SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockMultiSelectInlineSourceViewRowsQuery_graphql.node, convertVariables);

function Spaces__Block__MultiSelect$ConnectedInlineSourceViewRows(props) {
  var callerColumnViewId = props.callerColumnViewId;
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var match = Spaces__Cells__Mutations.useUpdateMultiSelectCell();
  var linkRows = match[0];
  var match$1 = Auth__Hooks.useAuth();
  var match$2 = use$1({
        callerColumnViewId: callerColumnViewId,
        callerRowId: DecidrNodeId.magicallyFromInternalId(callerRowId),
        callerViewId: props.callerViewId,
        skipQuery: match$1.authStatus !== "authenticated",
        sourceViewId: props.sourceViewId
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(Primitives__Suspense.make, {
              children: JsxRuntime.jsx(Spaces__Block__MultiSelect$InlineSourceViewRows$Data, {
                    queryRef: match$2.fragmentRefs,
                    onLinkRows: (function (rowIdsToAdd, rowIdsToRemove, onError) {
                        linkRows(callerRowId, callerColumnId, callerColumnViewId, undefined, rowIdsToAdd, rowIdsToRemove, onError, (function () {
                                
                              }));
                      }),
                    selectedRowInfos: props.selectedRowInfos,
                    linking: match[1],
                    callerColumnViewId: callerColumnViewId
                  })
            });
}

var convertFragment$1 = SpacesBlockMultiSelect_multiSelectCell_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockMultiSelect_multiSelectCell_graphql.node, convertFragment$1, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesBlockMultiSelect_multiSelectCell_graphql.node, convertFragment$1);
}

var convertFragment$2 = SpacesBlockMultiSelect_view_graphql.Internal.convertFragment;

function use$3(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockMultiSelect_view_graphql.node, convertFragment$2, fRef);
}

function useData(viewRef) {
  var view = use$3(viewRef);
  var match = Spaces__Row__Utils.LayoutElement.get(view.coreConfig, "itemLayout");
  var storedFieldMapping = match.fieldMapping;
  var contextSchema = Core__Option.getOr(match.contextSchema, Spaces__View__DefaultItemLayout.contextSchema);
  var node = Core__Option.getOr(match.node, Spaces__View__DefaultItemLayout.node);
  var orderedColumnViews = Utils__Array.toSorted(view.columnViews, (function (a, b) {
          return a.order - b.order;
        }));
  var columnInfos = Core__Array.filterMap(orderedColumnViews.filter(function (param) {
            return !param.isHidden;
          }), (function (param) {
          var columnId = param.columnId;
          var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
          return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                        return {
                                columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                componentTypeCode: componentTypeCode
                              };
                      }));
        }));
  var fieldMapping = Object.entries(storedFieldMapping).length === 0 ? Spaces__View__Utils.guessFieldMapping(columnInfos, contextSchema) : storedFieldMapping;
  var validFieldMapping = Spaces__View__Utils.withoutInvalidFieldMapping(fieldMapping, columnInfos, contextSchema);
  var withDefaultOpenAction = Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(view.coreConfig, Core__JSON.Decode.object), (function (__x) {
              return __x["developer_with_default_open_action"];
            })), Core__JSON.Decode.bool);
  var extractData = function (row) {
    return Spaces__Row.ListDataFragment.readInline(row.fragmentRefs);
  };
  return {
          view: {
            columnViews: orderedColumnViews,
            coreConfig: view.coreConfig,
            id: view.id,
            rowCount: view.rowCount,
            viewType: view.viewType
          },
          extractData: extractData,
          validFieldMapping: validFieldMapping,
          itemLayout: Spaces__View__DefaultItemLayout.MultiSelectItemLayout.make(node, withDefaultOpenAction)
        };
}

var developerSettingsInputTypeKey = "developer_settings_input_type";

var inputTypeDefault = "Add & link";

function getInputType(coreConfig) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[developerSettingsInputTypeKey];
                      })), Core__JSON.Decode.string), inputTypeDefault);
}

var developerSettingsOpenNewlyCreatedRowKey = "developer_settings_open_newly_created_row";

var developerSettingsDisplayInlinedKey = "developer_settings_display_inlined";

function getOpenNewlyCreatedRow(coreConfig) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[developerSettingsOpenNewlyCreatedRowKey];
                      })), Core__JSON.Decode.bool), true);
}

function getDisplayInlined(coreConfig) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[developerSettingsDisplayInlinedKey];
                      })), Core__JSON.Decode.bool), false);
}

function Spaces__Block__MultiSelect$Data$Loading(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1,
                padding: Caml_option.some(Primitives__Styling.dp(6))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                        size: "small"
                      }))
            });
}

var Loading = {
  make: Spaces__Block__MultiSelect$Data$Loading
};

function makeDataDisplayStyle(displayInline, rowCount, canLink, maxRowCountToRenderInline, hideUnselectedOptions) {
  var match = Caml_obj.lessequal(rowCount, maxRowCountToRenderInline);
  if (canLink && displayInline && !(hideUnselectedOptions || !match)) {
    return "InlinedOptionsWithHighlightedSelection";
  } else {
    return "OnlySelected";
  }
}

function makeHeaderDisplayStyle(displayInline, rowCount, maxRowCountToRenderInline, canAdd, canLink, reachedMaxLinks, hideUnselectedOptions) {
  var exit = 0;
  if (canAdd) {
    if (!canLink) {
      if (reachedMaxLinks) {
        return "None";
      } else {
        return "AddButton";
      }
    }
    exit = 1;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    if (!displayInline) {
      if (reachedMaxLinks) {
        return "None";
      } else if (canAdd) {
        return "SelectOptionsWithAddButton";
      } else if (canLink) {
        return "SelectOptions";
      } else {
        return "None";
      }
    }
    var exit$1 = 0;
    if (reachedMaxLinks && Caml_obj.lessequal(rowCount, maxRowCountToRenderInline)) {
      return {
              TAG: "UnselectedOptionsToggle",
              hideUnselectedOptions: hideUnselectedOptions
            };
    }
    if (canAdd) {
      if (Caml_obj.lessequal(rowCount, maxRowCountToRenderInline)) {
        return {
                TAG: "UnselectedOptionsToggleWithAddButton",
                hideUnselectedOptions: hideUnselectedOptions
              };
      }
      exit$1 = 2;
    } else {
      if (Caml_obj.lessequal(rowCount, maxRowCountToRenderInline)) {
        return {
                TAG: "UnselectedOptionsToggle",
                hideUnselectedOptions: hideUnselectedOptions
              };
      }
      exit$1 = 2;
    }
    if (exit$1 === 2) {
      if (reachedMaxLinks) {
        return "None";
      } else if (canAdd) {
        return "SelectOptionsWithAddButton";
      } else if (canLink) {
        return "SelectOptions";
      } else {
        return "None";
      }
    }
    
  }
  
}

function Spaces__Block__MultiSelect$Data$UnselectedOptionsToggle(props) {
  var onToggle = props.onToggle;
  return JsxRuntime.jsx(Primitives__TextButton.make, {
              title: props.toggled ? "Show unselected items" : "Hide unselected items",
              onPress: (function (param) {
                  onToggle();
                })
            });
}

function Spaces__Block__MultiSelect$Data$SelectOptionsButton(props) {
  var onStartSelection = props.onStartSelection;
  return JsxRuntime.jsx(Primitives__UnstyledButton.make, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                    orientation: "horizontal",
                    style: {
                      alignItems: "center",
                      justifyContent: "center"
                    },
                    children: [
                      JsxRuntime.jsx(Primitives__Icon.make, {
                            size: "ic_1",
                            color: Primitives__Styling.color({
                                  NAME: "text",
                                  VAL: {
                                    NAME: "on_background",
                                    VAL: "subdued"
                                  }
                                }),
                            icon: RescriptTamagui__Icon__Link.make
                          }),
                      JsxRuntime.jsx(Primitives__DecidrText.make, {
                            children: "Link item",
                            color: Primitives__Styling.color({
                                  NAME: "text",
                                  VAL: {
                                    NAME: "on_background",
                                    VAL: "subdued"
                                  }
                                })
                          })
                    ]
                  }),
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "default"
                    }),
                borderRadius: Primitives__Styling.radius("rad_5"),
                borderWidth: 2,
                flex: 1,
                paddingBottom: Caml_option.some(Primitives__Styling.space("cs_2")),
                paddingLeft: Caml_option.some(Primitives__Styling.space("cs_5")),
                paddingRight: Caml_option.some(Primitives__Styling.space("cs_5")),
                paddingTop: Caml_option.some(Primitives__Styling.space("cs_2"))
              },
              onPress: (function (_e) {
                  onStartSelection();
                })
            });
}

function Spaces__Block__MultiSelect$Data$AddButton(props) {
  var onAdd = props.onAdd;
  return JsxRuntime.jsx(Primitives__IconButton.make, {
              icon: RescriptTamagui__Icon__Plus.make,
              onPress: (function (param) {
                  onAdd();
                }),
              size: "small",
              busy: props.busy
            });
}

function Spaces__Block__MultiSelect$Data$Header(props) {
  var isAddBusy = props.isAddBusy;
  var onStartSelection = props.onStartSelection;
  var onAdd = props.onAdd;
  var onToggle = props.onToggle;
  var displayStyle = props.displayStyle;
  if (typeof displayStyle === "object") {
    if (displayStyle.TAG === "UnselectedOptionsToggle") {
      return JsxRuntime.jsx(Primitives__Group.make, {
                  orientation: "horizontal",
                  children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$UnselectedOptionsToggle, {
                            toggled: displayStyle.hideUnselectedOptions,
                            onToggle: onToggle
                          }))
                });
    } else {
      return JsxRuntime.jsxs(Primitives__Group.make, {
                  gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                  orientation: "horizontal",
                  style: {
                    justifyContent: "space-between"
                  },
                  children: [
                    JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$UnselectedOptionsToggle, {
                          toggled: displayStyle.hideUnselectedOptions,
                          onToggle: onToggle
                        }),
                    JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$AddButton, {
                          onAdd: onAdd,
                          busy: isAddBusy
                        })
                  ]
                });
    }
  }
  switch (displayStyle) {
    case "SelectOptions" :
        return JsxRuntime.jsx(Primitives__Group.make, {
                    orientation: "horizontal",
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$SelectOptionsButton, {
                              onStartSelection: onStartSelection
                            }))
                  });
    case "SelectOptionsWithAddButton" :
        return JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                    orientation: "horizontal",
                    style: {
                      alignItems: "center"
                    },
                    children: [
                      JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$SelectOptionsButton, {
                            onStartSelection: onStartSelection
                          }),
                      JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$AddButton, {
                            onAdd: onAdd,
                            busy: isAddBusy
                          })
                    ]
                  });
    case "AddButton" :
        return JsxRuntime.jsx(Primitives__Group.make, {
                    orientation: "horizontal",
                    style: {
                      justifyContent: "flex-end"
                    },
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$AddButton, {
                              onAdd: onAdd,
                              busy: isAddBusy
                            }))
                  });
    case "None" :
        return null;
    
  }
}

function Spaces__Block__MultiSelect$Data$OnlySelectedContent(props) {
  var cellInfo = props.cellInfo;
  var match = use$2(props.multiSelectCellRef);
  var selectedRows = match.data.selectedRows;
  var match$1 = useData(props.sourceViewRef);
  var itemLayout = match$1.itemLayout;
  var validFieldMapping = match$1.validFieldMapping;
  var extractData = match$1.extractData;
  var view = match$1.view;
  var match$2 = React.useState(function () {
        return 0;
      });
  var setListWidth = match$2[1];
  var listWidth = match$2[0];
  var match$3 = Spaces__ItemLayoutRendererContext.use();
  var handleRowAction = match$3.handleRowAction;
  var match$4 = React.useState(function () {
        return false;
      });
  var setShowAll = match$4[1];
  var showAll = match$4[0];
  var numColumns = React.useMemo((function () {
          if (view.viewType.code === "grid") {
            if (listWidth > 1300) {
              return 3;
            } else if (listWidth > 800) {
              return 2;
            } else {
              return 1;
            }
          } else {
            return 1;
          }
        }), [
        view.viewType.code,
        listWidth
      ]);
  var selectedRowsToDisplay = selectedRows.length <= 5 || showAll ? selectedRows : selectedRows.slice(0, 5);
  return JsxRuntime.jsxs(ReactNative.View, {
              onLayout: (function (param) {
                  var nativeEvent = param.nativeEvent;
                  setListWidth(function (param) {
                        return nativeEvent.layout.width;
                      });
                }),
              style: {
                flex: 1
              },
              children: [
                JsxRuntime.jsx(Primitives__Grid.make, {
                      data: selectedRowsToDisplay,
                      renderItem: (function (param) {
                          var row = param.row;
                          var id = param.id;
                          return JsxRuntime.jsx(Spaces__Row__ItemRenderer.make, {
                                      itemLayout: itemLayout,
                                      rowId: DecidrNodeId.magicallyToInternalId(row.id),
                                      cells: extractData(row).cells,
                                      fieldMapping: validFieldMapping,
                                      columnViews: Core__Array.filterMap(view.columnViews, (function (param) {
                                              var name = param.name;
                                              var id = param.id;
                                              var columnId = param.columnId;
                                              var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
                                              return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                                                            return {
                                                                    id: id,
                                                                    columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                                                    name: name,
                                                                    componentTypeCode: componentTypeCode
                                                                  };
                                                          }));
                                            })),
                                      viewId: view.id,
                                      handleRowAction: (function (viewId, rowId, action) {
                                          handleRowAction(viewId, rowId, action, Spaces__Renderer__Meta.make([
                                                    [
                                                      Spaces__Renderer__Meta.Key.selectedRowInfoId,
                                                      DecidrInternalId.toString(DecidrNodeId.magicallyToInternalId(id))
                                                    ],
                                                    [
                                                      Spaces__Renderer__Meta.Key.multiSelectRowId,
                                                      DecidrInternalId.toString(cellInfo.rowId)
                                                    ],
                                                    [
                                                      Spaces__Renderer__Meta.Key.multiSelectColumnId,
                                                      DecidrInternalId.toString(cellInfo.columnId)
                                                    ],
                                                    [
                                                      Spaces__Renderer__Meta.Key.linkColumnViewId,
                                                      DecidrNodeId.toString(cellInfo.columnViewId)
                                                    ]
                                                  ]));
                                        })
                                    });
                        }),
                      keyExtractor: (function (param) {
                          return DecidrInternalId.toString(DecidrNodeId.magicallyToInternalId(param.id));
                        }),
                      listWidth: listWidth,
                      itemSpacing: 6,
                      numColumns: numColumns,
                      padding: 6,
                      backgroundColor: Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(view.coreConfig, Core__JSON.Decode.object), (function (__x) {
                                  return __x["backgroundColor"];
                                })), Core__JSON.Decode.string)
                    }),
                ReactUtils__SimpleReact.whenTrue(selectedRows.length > 5, {
                      LAZY_DONE: false,
                      VAL: (function () {
                          return JsxRuntime.jsx(Primitives__Group.make, {
                                      style: {
                                        paddingTop: Caml_option.some(Primitives__Styling.space("cs_3"))
                                      },
                                      children: Caml_option.some(JsxRuntime.jsx(Primitives__Button.make, {
                                                title: showAll ? "Collapse" : "Show all",
                                                style: {
                                                  alignSelf: "center"
                                                },
                                                onPress: (function (param) {
                                                    setShowAll(function (prev) {
                                                          return !prev;
                                                        });
                                                  })
                                              }))
                                    });
                        })
                    })
              ]
            });
}

function Spaces__Block__MultiSelect$Data$InlinedOptionsWithHighlightedSelectionContent(props) {
  var match = useData(props.sourceViewRef);
  var multiSelectCell = useOpt(props.multiSelectCellRef);
  var selectedRowInfos = Core__Option.getOr(Core__Option.map(multiSelectCell, (function (param) {
              return param.data.selectedRows.map(function (param) {
                          return {
                                  id: DecidrNodeId.magicallyToInternalId(param.id),
                                  row: {
                                    id: DecidrNodeId.magicallyToInternalId(param.row.id)
                                  }
                                };
                        });
            })), []);
  return JsxRuntime.jsx(Spaces__Block__MultiSelect$ConnectedInlineSourceViewRows, {
              sourceViewId: match.view.id,
              callerRowId: props.callerRowId,
              callerColumnId: props.callerColumnId,
              callerColumnViewId: props.callerColumnViewId,
              callerViewId: props.callerViewId,
              selectedRowInfos: selectedRowInfos
            });
}

function Spaces__Block__MultiSelect$Data$Content(props) {
  var caller = props.caller;
  var multiSelectCellRef = props.multiSelectCellRef;
  var sourceViewRef = props.sourceViewRef;
  if (props.displayStyle === "InlinedOptionsWithHighlightedSelection") {
    return JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$InlinedOptionsWithHighlightedSelectionContent, {
                sourceViewRef: sourceViewRef,
                multiSelectCellRef: multiSelectCellRef,
                callerRowId: caller.rowId,
                callerColumnId: caller.columnId,
                callerColumnViewId: caller.columnViewId,
                callerViewId: caller.viewId
              });
  } else if (multiSelectCellRef !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$OnlySelectedContent, {
                multiSelectCellRef: Caml_option.valFromOption(multiSelectCellRef),
                sourceViewRef: sourceViewRef,
                cellInfo: {
                  rowId: caller.rowId,
                  columnId: caller.columnId,
                  columnViewId: caller.columnViewId
                }
              });
  } else {
    return null;
  }
}

function Spaces__Block__MultiSelect$Data(props) {
  var onStartSelection = props.onStartSelection;
  var caller = props.caller;
  var multiSelectCellRef = props.multiSelectCellRef;
  var sourceViewRef = props.sourceViewRef;
  var match = Spaces__ItemLayoutRendererContext.use();
  var onNavigateToLinkedRow = match.onNavigateToLinkedRow;
  var match$1 = useData(sourceViewRef);
  var sourceView = match$1.view;
  var cell = useOpt(multiSelectCellRef);
  var match$2 = Spaces__Row__Mutations.useCreate();
  var createRow = match$2[0];
  var match$3 = Spaces__Cells__Mutations.useLinkSingleRow();
  var linkRow = match$3[0];
  var numberOfSelectedRows = Core__Option.getOr(Core__Option.map(cell, (function (param) {
              return param.data.selectedRows.length;
            })), 0);
  var numberOfInitialSelectedRows = React.useMemo((function () {
          return numberOfSelectedRows;
        }), []);
  var match$4 = React.useState(function () {
        return numberOfInitialSelectedRows !== 0;
      });
  var setToggled = match$4[1];
  var toggled = match$4[0];
  var numberOfAllowedLinks = Spaces__Row__Renderer__LinkRowsModal.getNumberOfAllowedLinks(sourceView.coreConfig);
  var inputTypeSetting = getInputType(sourceView.coreConfig);
  var displayInline = getDisplayInlined(sourceView.coreConfig);
  var canAdd = inputTypeSetting === "Add & link" || inputTypeSetting === "Add only";
  var canLink = (inputTypeSetting === "Add & link" || inputTypeSetting === "Link only") && Core__Option.isSome(onStartSelection);
  var openNewlyCreatedRow = getOpenNewlyCreatedRow(sourceView.coreConfig);
  var reachedMaxLinks = numberOfAllowedLinks !== undefined ? numberOfSelectedRows >= numberOfAllowedLinks : false;
  var headerDisplayStyle = makeHeaderDisplayStyle(displayInline, sourceView.rowCount, 8, canAdd, canLink, reachedMaxLinks, toggled);
  var dataDisplayStyle = makeDataDisplayStyle(displayInline, sourceView.rowCount, canLink, 8, toggled);
  var handleAdd = function () {
    createRow(sourceView.id, {
          viewId: caller.viewId,
          rowId: caller.rowId,
          columnViewId: caller.columnViewId
        }, (function (createdRowId, viewId) {
            linkRow(caller.rowId, caller.columnId, caller.columnViewId, createdRowId, (function (_v) {
                    
                  }), (function () {
                    if (openNewlyCreatedRow) {
                      return Core__Option.forEach(onNavigateToLinkedRow, (function (onNavigateToLinkedRow) {
                                    onNavigateToLinkedRow(viewId, createdRowId, caller.columnViewId);
                                  }));
                    }
                    
                  }));
          }), undefined);
  };
  var handleToggle = function () {
    setToggled(function (prev) {
          return !prev;
        });
  };
  var handleStartSelection = function () {
    Core__Option.forEach(onStartSelection, (function (onStartSelection) {
            onStartSelection();
          }));
  };
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$Header, {
                      displayStyle: headerDisplayStyle,
                      onToggle: handleToggle,
                      onAdd: handleAdd,
                      onStartSelection: handleStartSelection,
                      isAddBusy: match$2[1] || match$3[1]
                    }),
                JsxRuntime.jsx(Spaces__Block__MultiSelect$Data$Content, {
                      displayStyle: dataDisplayStyle,
                      sourceViewRef: sourceViewRef,
                      multiSelectCellRef: multiSelectCellRef,
                      caller: caller
                    })
              ]
            });
}

var convertVariables$1 = SpacesBlockMultiSelectQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockMultiSelectQuery_graphql.Internal.convertResponse;

var use$4 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockMultiSelectQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockMultiSelectQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockMultiSelectQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockMultiSelectQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesBlockMultiSelectQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesBlockMultiSelectQuery_graphql.node, convertVariables$1);

function Spaces__Block__MultiSelect(props) {
  var onLinkPress = props.onLinkPress;
  var callerColumnViewId = props.callerColumnViewId;
  var callerViewId = props.callerViewId;
  var callerColumnId = props.callerColumnId;
  var callerRowId = props.callerRowId;
  var sourceViewId = props.sourceViewId;
  var match = use$4({
        callerColumnId: DecidrNodeId.magicallyFromInternalId(callerColumnId),
        callerColumnViewId: callerColumnViewId,
        callerRowId: DecidrNodeId.magicallyFromInternalId(callerRowId),
        callerViewId: callerViewId,
        sourceViewId: sourceViewId
      }, undefined, undefined, undefined);
  var node = match.node;
  var caller = {
    rowId: callerRowId,
    columnId: callerColumnId,
    columnViewId: callerColumnViewId,
    viewId: callerViewId
  };
  var match$1 = match.cell.cell;
  if (node === undefined) {
    if (match$1 !== undefined) {
      ErrorHandling__UnexpectedError.handle("No found", "Spaces__Block__MultiSelect", {
            sourceViewId: sourceViewId
          });
      return null;
    } else {
      ErrorHandling__UnexpectedError.handle("Not found and cell not found", "Spaces__Block__MultiSelect", {
            sourceViewId: sourceViewId,
            rowId: callerRowId,
            columnId: callerColumnId
          });
      return null;
    }
  }
  if (node.__typename === "View") {
    var sourceViewRef = node.fragmentRefs;
    if (match$1 === undefined) {
      return JsxRuntime.jsx(Spaces__Block__MultiSelect$Data, {
                  sourceViewRef: sourceViewRef,
                  multiSelectCellRef: undefined,
                  caller: caller,
                  onStartSelection: onLinkPress
                });
    }
    if (match$1.__typename === "MultiSelectCell") {
      return JsxRuntime.jsx(Spaces__Block__MultiSelect$Data, {
                  sourceViewRef: sourceViewRef,
                  multiSelectCellRef: Caml_option.some(match$1.fragmentRefs),
                  caller: caller,
                  onStartSelection: onLinkPress
                });
    }
    
  } else if (match$1 === undefined) {
    ErrorHandling__UnexpectedError.handle("Not a View and cell not found", "Spaces__Block__MultiSelect", {
          sourceViewId: sourceViewId,
          rowId: callerRowId,
          columnId: callerColumnId,
          typename: node._0
        });
    return null;
  }
  if (match$1.__typename === "MultiSelectCell") {
    ErrorHandling__UnexpectedError.handle("Not a View", "Spaces__Block__MultiSelect", {
          sourceViewId: sourceViewId,
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not a multi select cell", "Spaces__Block__MultiSelect", {
        typename: match$1._0
      });
  return null;
}

var Data = {
  Loading: Loading,
  developerSettingsInputTypeKey: developerSettingsInputTypeKey,
  inputTypeDefault: inputTypeDefault,
  developerSettingsOpenNewlyCreatedRowKey: developerSettingsOpenNewlyCreatedRowKey,
  openNewlyCreatedRowDefault: true,
  developerSettingsDisplayInlinedKey: developerSettingsDisplayInlinedKey,
  displayInlinedDefault: false
};

var make = Spaces__Block__MultiSelect;

export {
  Data ,
  make ,
}
/* use Not a pure module */
