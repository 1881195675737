import { ViewStyle } from "react-native";

import { breakpoint, breakpoints } from "./media/TamaguiConfig__Media.gen";
import {
  Space_makeSpaceToken as makeSpaceToken,
  Radius_makeRadiusToken as makeRadiusToken,
  Space_componentSpaceTokenPrefix as componentSpaceTokenPrefix,
  Space_sectionSpaceTokenPrefix as sectionSpaceTokenPrefix,
  Radius_radiusTokenPrefix as radiusTokenPrefix,
  Radius_t,
  Space_t,
} from "./tokens/TamaguiConfig__Tokens.gen";
import { getTokens } from "tamagui";

type MediaSpecificStyles = Partial<Record<`$${breakpoint}`, ViewStyle>>;

const SPACE_KEYS = {
  space: true,
  gap: true,
  padding: true,
  paddingHorizontal: true,
  paddingVertical: true,
  paddingLeft: true,
  paddingTop: true,
  paddingBottom: true,
  paddingRight: true,
  paddingEnd: true,
  paddingStart: true,
  margin: true,
  marginHorizontal: true,
  marginVertical: true,
  marginLeft: true,
  marginTop: true,
  marginBottom: true,
  marginRight: true,
  marginEnd: true,
  marginStart: true,
  x: true,
  y: true,
  scale: true,
  scaleX: true,
  scaleY: true,
  borderTopEndRadius: true,
  borderTopLeftRadius: true,
  borderTopRightRadius: true,
  borderTopStartRadius: true,
  borderBottomEndRadius: true,
  borderBottomLeftRadius: true,
  borderBottomRightRadius: true,
  borderBottomStartRadius: true,
  borderBottomWidth: true,
  borderLeftWidth: true,
  borderRadius: true,
  borderRightWidth: true,
  borderEndWidth: true,
  borderStartWidth: true,
  borderTopWidth: true,
  borderWidth: true,
  left: true,
  top: true,
  right: true,
  bottom: true,
  shadowOffset: true,
  height: true,
};

const RADIUS_KEYS = {
  borderTopEndRadius: true,
  borderTopLeftRadius: true,
  borderTopRightRadius: true,
  borderTopStartRadius: true,
  borderBottomEndRadius: true,
  borderBottomLeftRadius: true,
  borderBottomRightRadius: true,
  borderBottomStartRadius: true,
  borderRadius: true,
};

const spaceKeys = Object.entries(SPACE_KEYS).map(([key]) => key);

const radiusKeys = Object.entries(RADIUS_KEYS).map(([key]) => key);

const extractSpaceStyles = (style: ViewStyle) => {
  return Object.entries(style).filter(([key]) => spaceKeys.includes(key));
};

const extractRadiusStyles = (style: ViewStyle) => {
  return Object.entries(style).filter(([key]) => radiusKeys.includes(key));
};

const extractNonThemeStyles = (style: ViewStyle) => {
  return Object.entries(style).filter(
    ([key]) => !(spaceKeys.includes(key) || radiusKeys.includes(key))
  );
};

const transformSpaceValueForSpecificMedia = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  breakpoint: breakpoint
) => {
  if (typeof value === "string") {
    if (
      value.startsWith(componentSpaceTokenPrefix) ||
      value.startsWith(sectionSpaceTokenPrefix)
    ) {
      return makeSpaceToken(value as Space_t, breakpoint);
    } else {
      return value;
    }
  } else {
    return value;
  }
};

const transformRadiusValueForSpecificMedia = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  value: any,
  breakpoint: breakpoint
) => {
  if (typeof value === "string") {
    if (value.includes(radiusTokenPrefix)) {
      return makeRadiusToken(value as Radius_t, breakpoint);
    } else {
      return value;
    }
  } else {
    return value;
  }
};

const transformSpaceStyleForSpecificMedia = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  spaceStyle: [string, any][],
  breakpoint: breakpoint
) =>
  Object.fromEntries(
    spaceStyle.map(([key, value]) => [
      key,
      transformSpaceValueForSpecificMedia(value, breakpoint),
    ])
  );

const transformRadiusStyleForSpecificMedia = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  radiusStyle: [string, any][],
  breakpoint: breakpoint
) =>
  Object.fromEntries(
    radiusStyle.map(([key, value]) => [
      key,
      transformRadiusValueForSpecificMedia(value, breakpoint),
    ])
  );

const transformStyle = (style: ViewStyle, breakpoint: breakpoint) => {
  const spaceStyle = extractSpaceStyles(style);
  const radiusStyle = extractRadiusStyles(style);
  const otherStyle = extractNonThemeStyles(style);

  return {
    ...transformSpaceStyleForSpecificMedia(spaceStyle, breakpoint),
    ...transformRadiusStyleForSpecificMedia(radiusStyle, breakpoint),
    ...Object.fromEntries(otherStyle),
  };
};

export const makeResponsiveStyle = (
  common: ViewStyle,
  specific?: MediaSpecificStyles
) => {
  const specificStylesWithDefault = specific ?? {};

  const mediaStyles = Object.fromEntries(
    breakpoints.map(media => {
      return [
        media,
        {
          ...transformStyle(common, media),
          ...transformStyle(
            specificStylesWithDefault[`$${media}`] ?? {},
            media
          ),
        },
      ];
    })
  );

  return Object.fromEntries(
    Object.entries(mediaStyles).map(([key, value]) => [`$${key}`, value])
  );
};

export const extractCommonStyles = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  obj: Record<string, Record<string, any>>
) => {
  // Get the inner objects
  const innerObjects = Object.values(obj);

  // Find the common key-value pairs
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const common: Record<string, any> = {};
  for (const key in innerObjects[0]) {
    const value = innerObjects[0][key];
    if (
      innerObjects.every(
        innerObj => innerObj.hasOwnProperty(key) && innerObj[key] === value
      )
    ) {
      common[key] = value;
    }
  }

  // Remove common key-value pairs from each inner object
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const result: Record<string, any> = {};
  for (const innerObjKey in obj) {
    const innerObj = obj[innerObjKey];
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const newObj: Record<string, any> = {};
    for (const key in innerObj) {
      if (!(key in common)) {
        newObj[key] = innerObj[key];
      }
    }
    result[innerObjKey] = newObj;
  }

  // Add the common object to the result
  result.common = common;

  return result;
};

export const getSizeTokenValue = (size: string): number | undefined => {
  const tokens = getTokens();
  type Size = typeof tokens.size;

  type SizeToken = keyof Size;
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return tokens.size[size as SizeToken]?.val;
};
