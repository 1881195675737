// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Toggle from "../../../primitives/src/toggle/Primitives__Toggle.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__Block__Common from "./Spaces__Block__Common.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";

function Spaces__Block__Switch(props) {
  var value = props.value;
  var match = React.useState(function () {
        return Core__Option.getOr(value, false);
      });
  var setValue = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var error = match$1[0];
  var match$2 = Spaces__Cells__Mutations.useUpdateCell(props.rowId, props.columnId, props.columnViewId);
  var updateCell = match$2[0];
  var handleOnChange = function (value) {
    setValue(function (param) {
          return value;
        });
    setError(function (param) {
          
        });
    updateCell(value, (function (message) {
            setError(function (param) {
                  return message;
                });
          }), undefined);
  };
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
              orientation: "horizontal",
              style: {
                alignItems: "center"
              },
              children: [
                error !== undefined ? JsxRuntime.jsx(Spaces__Block__Common.ErrorIndicator.make, {
                        message: error
                      }) : null,
                JsxRuntime.jsx(Primitives__Toggle.make, {
                      value: match[0],
                      onChange: handleOnChange
                    })
              ]
            });
}

var make = Spaces__Block__Switch;

export {
  make ,
}
/* react Not a pure module */
