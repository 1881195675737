import { useMemo, useState } from "react";
import { RecordSource } from "relay-runtime";

import {
  DecidrToast,
  TamaguiProvider,
  TamaguiProviderProps,
  ToastProvider,
} from "@decidr/primitives";
import { Auth } from "@decidr/auth";
// import { useColorScheme } from "react-native";

import { ToastViewport } from "./ToastViewport";
import config from "../../tamagui.config";
import { make as RelayProvider } from "../relay/App__RelayProvider.gen";
import { makeEnvironment } from "../relay/App__RelayEnv.gen";

export function AppProvider({
  children,
  defaultTheme = "light",
  initialRelayStore,
  ...rest
}: Omit<TamaguiProviderProps, "config"> & {
  initialRelayStore?: Record<string, Record<string, string>>;
}) {
  // const scheme = useColorScheme();
  const [relayEnvironment, _setRelayEnvironment] = useState(() =>
    makeEnvironment()
  );

  useMemo(() => {
    if (initialRelayStore) {
      const recordStore = new RecordSource(initialRelayStore);
      // NOTE: Bug of relay-runtime merging client connection and server connection
      // so, i will delete connection edges by updating from recordStore
      // commitLocalUpdate(relayEnvironment, store => {
      //   recordStore.getRecordIDs().forEach(dataID => {
      //     store
      //       .get(dataID)
      //       ?.getLinkedRecords("edges")
      //       ?.forEach(edge => {
      //         store.delete(edge.getDataID());
      //       });
      //   });
      // });
      relayEnvironment.getStore().publish(recordStore);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialRelayStore]);

  return (
    <Auth.Provider>
      <RelayProvider environment={relayEnvironment}>
        <TamaguiProvider
          config={config}
          disableInjectCSS
          // defaultTheme={scheme === "dark" ? "dark" : "light"}
          defaultTheme={defaultTheme}
          {...rest}
        >
          <ToastProvider
            swipeDirection="horizontal"
            duration={6000}
            native={
              [
                /* uncomment the next line to do native toasts on mobile. NOTE: it'll require you making a dev build and won't work with Expo Go */
                // 'mobile'
              ]
            }
          >
            {children}

            <DecidrToast />
            <ToastViewport />
          </ToastProvider>
        </TamaguiProvider>
      </RelayProvider>
    </Auth.Provider>
  );
}
