// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["Organization"]);
}

var fragmentConverter = {"__root":{"node_Organization_members_edges_node_id":{"c":"DecidrNodeId.RelayAppSync"},"node_Organization_aiMembers_edges_node_id":{"c":"DecidrNodeId.RelayAppSync"},"node":{"u":"fragment_node"}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment_node: unwrap_fragment_node
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = [
  {
    "kind": "Literal",
    "name": "first",
    "value": 200
  }
],
v1 = [
  {
    "alias": null,
    "args": null,
    "concreteType": "OrganizationMemberEdge",
    "kind": "LinkedField",
    "name": "edges",
    "plural": true,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "OrganizationMember",
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "id",
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "organizationId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesBlockSystemLinkOrganizationMembersQuery_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "organizationId"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "OrganizationMemberConnection",
              "kind": "LinkedField",
              "name": "aiMembers",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "aiMembers(first:200)"
            },
            {
              "alias": null,
              "args": (v0/*: any*/),
              "concreteType": "OrganizationMemberConnection",
              "kind": "LinkedField",
              "name": "members",
              "plural": false,
              "selections": (v1/*: any*/),
              "storageKey": "members(first:200)"
            }
          ],
          "type": "Organization",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})());

var wrap_fragment_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_node ,
  wrap_fragment_node ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
