/* TypeScript file generated from Auth__Provider.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as Auth__ProviderJS from './Auth__Provider.bs.js';

export type props = { readonly children: JSX.Element };

export const make: React.ComponentType<{ readonly children: React.ReactNode }> = Auth__ProviderJS.make as any;
