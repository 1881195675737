// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as App__Alert from "../../../app/src/alert/App__Alert.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Utils__Ignored from "../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as App__ErrorMessage from "../../../app/src/error/App__ErrorMessage.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__UploadUtils from "../utils/upload/Spaces__UploadUtils.bs.js";
import * as ExpoDocumentPicker from "expo-document-picker";
import * as Spaces__DownloadUtils from "../utils/download/Spaces__DownloadUtils.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__IconButton from "../../../primitives/src/button/Primitives__IconButton.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";
import * as RescriptTamagui__Icon__Trash2 from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Trash2.bs.js";
import * as RescriptTamagui__Icon__Upload from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Upload.bs.js";
import * as RescriptTamagui__Icon__DownloadCloud from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__DownloadCloud.bs.js";

function Spaces__Block__File(props) {
  var columnId = props.columnId;
  var rowId = props.rowId;
  var value = props.value;
  var name = props.name;
  var match = React.useState(function () {
        
      });
  var setError = match[1];
  var match$1 = React.useState(function () {
        return false;
      });
  var setLoading = match$1[1];
  var loading = match$1[0];
  var match$2 = React.useState(function () {
        return false;
      });
  var setShowAlert = match$2[1];
  var showAlert = match$2[0];
  var match$3 = Spaces__Cells__Mutations.useUpdateFileCell(rowId, columnId, props.columnViewId);
  var updateFileCell = match$3[0];
  var match$4 = Spaces__Cells__Mutations.useDeleteCell(rowId, columnId);
  var deletingFileCell = match$4[1];
  var deleteFileCell = match$4[0];
  var uploadFile = function () {
    Utils__Ignored.promiseWithPayload(ExpoDocumentPicker.getDocumentAsync(undefined).then(function (documentResult) {
              setLoading(function (param) {
                    return true;
                  });
              var assets = documentResult.assets;
              if (documentResult.canceled) {
                setLoading(function (param) {
                      return false;
                    });
              } else if (assets !== undefined && assets.length !== 0) {
                Utils__Ignored.promise(Spaces__UploadUtils.makeWithTempUploadIfNeeded({
                          uri: assets[0].uri,
                          next: (function (param) {
                              var key = param.key;
                              updateFileCell(param.url, (function () {
                                      Utils__Ignored.promise(Spaces__UploadUtils.deleteFromS3(key));
                                      setLoading(function (param) {
                                            return false;
                                          });
                                    }), (function (error) {
                                      setError(function (param) {
                                            return error;
                                          });
                                      setLoading(function (param) {
                                            return false;
                                          });
                                    }));
                              return Promise.resolve();
                            })
                        }));
              }
              return Promise.resolve(documentResult);
            }));
  };
  var deleteFile = function () {
    deleteFileCell((function (error) {
            setError(function (param) {
                  return error;
                });
          }), (function () {
            setShowAlert(function (param) {
                  return false;
                });
          }));
  };
  var tmp;
  if (value !== undefined) {
    var url = value.url;
    tmp = JsxRuntime.jsxs(Primitives__Group.make, {
          children: [
            JsxRuntime.jsxs(Primitives__Group.make, {
                  gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                  orientation: "horizontal",
                  style: {
                    alignItems: "center"
                  },
                  children: [
                    JsxRuntime.jsx(Primitives__DecidrText.make, {
                          children: Caml_option.some(name + ":")
                        }),
                    JsxRuntime.jsx(Primitives__IconButton.make, {
                          icon: RescriptTamagui__Icon__DownloadCloud.make,
                          onPress: (function (param) {
                              Utils__Ignored.promise(Spaces__DownloadUtils.downloadFile({
                                          url: url,
                                          name: name
                                        }).then(function () {
                                        return Promise.resolve();
                                      }));
                            }),
                          busy: loading || deletingFileCell
                        })
                  ]
                }),
            JsxRuntime.jsx(Primitives__DecidrText.make, {
                  children: Caml_option.some("Type: " + value.mimetype)
                }),
            JsxRuntime.jsx(Primitives__DecidrText.make, {
                  children: Caml_option.some("Size: " + value.size.toString() + "B")
                })
          ]
        });
  } else {
    tmp = JsxRuntime.jsx(Primitives__DecidrText.make, {
          children: "No file"
        });
  }
  return JsxRuntime.jsxs(Primitives__Group.make, {
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      orientation: "horizontal",
                      style: {
                        justifyContent: "flex-end"
                      },
                      children: [
                        JsxRuntime.jsx(Primitives__IconButton.make, {
                              icon: RescriptTamagui__Icon__Upload.make,
                              onPress: (function (param) {
                                  uploadFile();
                                }),
                              size: "small",
                              busy: loading || deletingFileCell
                            }),
                        ReactUtils__SimpleReact.whenTrue(Core__Option.isSome(value), {
                              LAZY_DONE: false,
                              VAL: (function () {
                                  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                                              children: [
                                                JsxRuntime.jsx(Primitives__IconButton.make, {
                                                      icon: RescriptTamagui__Icon__Trash2.make,
                                                      onPress: (function (param) {
                                                          setShowAlert(function (param) {
                                                                return true;
                                                              });
                                                        }),
                                                      size: "small",
                                                      busy: loading || deletingFileCell
                                                    }),
                                                JsxRuntime.jsx(App__Alert.make, {
                                                      title: "Delete file",
                                                      description: "Are you sure sure you want to delete this file?",
                                                      show: showAlert,
                                                      onConfirm: (function () {
                                                          deleteFile();
                                                        }),
                                                      onCancel: (function () {
                                                          setShowAlert(function (param) {
                                                                return false;
                                                              });
                                                        }),
                                                      busy: deletingFileCell
                                                    })
                                              ]
                                            });
                                })
                            })
                      ]
                    }),
                tmp,
                JsxRuntime.jsx(App__ErrorMessage.make, {
                      error: match[0]
                    })
              ]
            });
}

var make = Spaces__Block__File;

export {
  make ,
}
/* react Not a pure module */
