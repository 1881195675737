import * as Sentry from "@sentry/nextjs";

const ignoredMessages = [
  "ResizeObserver loop completed with undelivered notifications",
];

// Function to filter out events with ignored messages
const withIgnoredMessages = (event: Sentry.Event | null) => {
  if (event) {
    const shouldIgnore = ignoredMessages.some(ignoredMessage =>
      event.message?.includes(ignoredMessage)
    );
    if (shouldIgnore) {
      return null;
    }
  }
  return event;
};

// Function to disable Sentry in development environment
const withDisabledDev = (event: Sentry.Event | null) => {
  const isLocalhost = event?.request?.url?.includes("localhost");
  const isDevEnv = process.env.NODE_ENV === "development";

  if (isLocalhost || isDevEnv) {
    return null;
  }
  return event;
};

const beforeSendPlugins = [withIgnoredMessages, withDisabledDev];

// Custom beforeSend function to apply the plugins
export const customBeforeSend = (
  event: Sentry.Event,
  _hint: Sentry.EventHint
) => {
  try {
    return beforeSendPlugins.reduce<Sentry.Event | null>(
      (acc, applyPlugin) => (acc ? applyPlugin(acc) : null),
      event
    );
  } catch (error) {
    console.error("Error in Sentry beforeSend:", error);
    // Returning event in case of error to avoid data loss
    return event;
  }
};
