/* TypeScript file generated from Utils__Email.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as Utils__EmailJS from './Utils__Email.bs.js';

export const redactEmailFromText: (_1:string) => string = Utils__EmailJS.redactEmailFromText as any;

export const extractEmailsFromText: (_1:string) => string[] = Utils__EmailJS.extractEmailsFromText as any;
