// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";

function Primitives__Skeleton__Text(props) {
  var __style = props.style;
  var __color = props.color;
  var __maxWidth = props.maxWidth;
  var maxWidth = __maxWidth !== undefined ? __maxWidth : 100;
  var color = __color !== undefined ? __color : Primitives__Styling.color("neutral_5");
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var newrecord = Caml_obj.obj_dup(style);
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: (newrecord.maxWidth = Caml_option.some(Primitives__Styling.dp(maxWidth)), newrecord.borderRadius = 4, newrecord.backgroundColor = color, newrecord),
              children: Caml_option.some(JsxRuntime.jsx(Primitives__DecidrText.make, {
                        kind: props.kind,
                        children: ".",
                        color: color
                      }))
            });
}

var make = Primitives__Skeleton__Text;

export {
  make ,
}
/* Primitives__Group Not a pure module */
