// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

var s_component_space = {
  _1: 4,
  _2: 8,
  _3: 12,
  _4: 16,
  _5: 20,
  _6: 24
};

var l_component_space = {
  _1: 2,
  _2: 4,
  _3: 8,
  _4: 12,
  _5: 16,
  _6: 20
};

var s_section_space = {
  _1: 12,
  _2: 16,
  _3: 20,
  _4: 24,
  _5: 32,
  _6: 40
};

var l_section_space = {
  _1: 16,
  _2: 20,
  _3: 24,
  _4: 32,
  _5: 40,
  _6: 80
};

var componentSpaces = [
  [
    "s",
    s_component_space
  ],
  [
    "m",
    s_component_space
  ],
  [
    "l",
    l_component_space
  ],
  [
    "xl",
    l_component_space
  ]
];

var componentSpaceTokenPrefix = "$cs";

var spaceTokenDelimiter = "_";

var componentSpaceTokens = Object.fromEntries(componentSpaces.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                componentSpaceTokenPrefix + spaceTokenDelimiter + breakpoint + param[0],
                                param[1]
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

var sectionSpaces = [
  [
    "s",
    s_section_space
  ],
  [
    "m",
    s_section_space
  ],
  [
    "l",
    l_section_space
  ],
  [
    "xl",
    l_section_space
  ]
];

var sectionSpaceTokenPrefix = "$ss";

var sectionSpaceTokens = Object.fromEntries(sectionSpaces.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                sectionSpaceTokenPrefix + spaceTokenDelimiter + breakpoint + param[0],
                                param[1]
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

function makeSpaceToken(space, breakpoint) {
  var parts = space.split(spaceTokenDelimiter);
  var kind = parts[0];
  var size = parts[1];
  if (kind !== undefined && size !== undefined) {
    return kind + spaceTokenDelimiter + breakpoint + spaceTokenDelimiter + size;
  } else {
    return Js_exn.raiseError("Couldn't parse space token");
  }
}

var s_radius = {
  _1: 2,
  _2: 4,
  _3: 6,
  _4: 8,
  _5: 10,
  _6: 12,
  _circle: 1000
};

var l_radius = {
  _1: 2,
  _2: 2,
  _3: 4,
  _4: 6,
  _5: 8,
  _6: 10,
  _circle: 1000
};

var radiuses = [
  [
    "s",
    s_radius
  ],
  [
    "m",
    s_radius
  ],
  [
    "l",
    l_radius
  ],
  [
    "xl",
    l_radius
  ]
];

var radiusTokenPrefix = "$rad";

var radiusTokenDelimiter = "_";

var radiusTokens = Object.fromEntries(radiuses.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                radiusTokenPrefix + radiusTokenDelimiter + breakpoint + param[0],
                                param[1]
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

function makeRadiusToken(radius, breakpoint) {
  var parts = radius.split(radiusTokenDelimiter);
  var kind = parts[0];
  var size = parts[1];
  if (kind !== undefined && size !== undefined) {
    return kind + radiusTokenDelimiter + breakpoint + radiusTokenDelimiter + size;
  } else {
    return Js_exn.raiseError("Couldn't parse radius token");
  }
}

var s_font = {
  title1: {
    size: 28,
    lineHeight: 35,
    letterSpacing: 0.32
  },
  title2: {
    size: 24,
    lineHeight: 30,
    letterSpacing: 0.32
  },
  title3: {
    size: 20,
    lineHeight: 25,
    letterSpacing: 0.32
  },
  title4: {
    size: 18,
    lineHeight: 24,
    letterSpacing: 0.0025
  },
  subtitle1: {
    size: 22,
    lineHeight: 27.5,
    letterSpacing: 0.32
  },
  subtitle2: {
    size: 20,
    lineHeight: 25,
    letterSpacing: 0.32
  },
  body1: {
    size: 18,
    lineHeight: 27,
    letterSpacing: 0.32
  },
  body2: {
    size: 16,
    lineHeight: 24,
    letterSpacing: 0.32
  },
  body3: {
    size: 14,
    lineHeight: 21,
    letterSpacing: 0.32
  },
  button: {
    size: 16,
    lineHeight: 20,
    letterSpacing: 1
  },
  caption: {
    size: 13,
    lineHeight: 19.5,
    letterSpacing: 0.32
  },
  overline: {
    size: 10,
    lineHeight: 12,
    letterSpacing: 1
  }
};

var l_font = {
  title1: {
    size: 56,
    lineHeight: 70,
    letterSpacing: 0.32
  },
  title2: {
    size: 40,
    lineHeight: 50,
    letterSpacing: 0.32
  },
  title3: {
    size: 28,
    lineHeight: 35,
    letterSpacing: 0.32
  },
  title4: {
    size: 24,
    lineHeight: 30,
    letterSpacing: 0.32
  },
  subtitle1: {
    size: 20,
    lineHeight: 25,
    letterSpacing: 0.32
  },
  subtitle2: {
    size: 18,
    lineHeight: 22.5,
    letterSpacing: 0.32
  },
  body1: {
    size: 16,
    lineHeight: 24,
    letterSpacing: 0.32
  },
  body2: {
    size: 14,
    lineHeight: 21,
    letterSpacing: 0.32
  },
  body3: {
    size: 12,
    lineHeight: 18,
    letterSpacing: 0.32
  },
  button: {
    size: 14,
    lineHeight: 17.5,
    letterSpacing: 1
  },
  caption: {
    size: 12,
    lineHeight: 18,
    letterSpacing: 0.32
  },
  overline: {
    size: 10,
    lineHeight: 12,
    letterSpacing: 1
  }
};

var fonts = [
  [
    "s",
    s_font
  ],
  [
    "m",
    s_font
  ],
  [
    "l",
    l_font
  ],
  [
    "xl",
    l_font
  ]
];

var fontTokenPrefix = "font";

var fontTokenDelimiter = "_";

var fontSizeTokens = Object.fromEntries(fonts.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                fontTokenPrefix + fontTokenDelimiter + breakpoint + fontTokenDelimiter + param[0],
                                param[1].size
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

var fontLineHeightTokens = Object.fromEntries(fonts.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                fontTokenPrefix + fontTokenDelimiter + breakpoint + fontTokenDelimiter + param[0],
                                param[1].lineHeight
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

var fontLetterSpacingTokens = Object.fromEntries(fonts.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                fontTokenPrefix + fontTokenDelimiter + breakpoint + fontTokenDelimiter + param[0],
                                param[1].letterSpacing
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

function makeCommonConfig(config) {
  return Object.fromEntries(fonts.map(function (param) {
                    var breakpoint = param[0];
                    return Object.entries(param[1]).map(function (param) {
                                return [
                                        fontTokenPrefix + fontTokenDelimiter + breakpoint + fontTokenDelimiter + param[0],
                                        config
                                      ];
                              });
                  }).flatMap(function (x) {
                  return x;
                }));
}

var s_iconSize = {
  _1: 16,
  _2: 18,
  _3: 20,
  _4: 24
};

var l_iconSize = {
  _1: 14,
  _2: 14,
  _3: 16,
  _4: 20
};

var iconSizes = [
  [
    "s",
    s_iconSize
  ],
  [
    "m",
    s_iconSize
  ],
  [
    "l",
    l_iconSize
  ],
  [
    "xl",
    l_iconSize
  ]
];

var iconSizesTokenPrefix = "$ic";

var iconSizesTokenDelimiter = "_";

var iconSizesTokens = Object.fromEntries(iconSizes.map(function (param) {
            var breakpoint = param[0];
            return Object.entries(param[1]).map(function (param) {
                        return [
                                iconSizesTokenPrefix + iconSizesTokenDelimiter + breakpoint + param[0],
                                param[1]
                              ];
                      });
          }).flatMap(function (x) {
          return x;
        }));

function makeIconSizesToken(iconSize, breakpoint) {
  var parts = iconSize.split(iconSizesTokenDelimiter);
  var kind = parts[0];
  var size = parts[1];
  if (kind !== undefined && size !== undefined) {
    return kind + iconSizesTokenDelimiter + breakpoint + iconSizesTokenDelimiter + size;
  } else {
    return Js_exn.raiseError("Couldn't parse radius token");
  }
}

var colors = [
  [
    "transparent",
    "transparent"
  ],
  [
    "primary_0",
    "#000000"
  ],
  [
    "primary_1",
    "#262626"
  ],
  [
    "primary_2",
    "#B2B2B2"
  ],
  [
    "primary_3",
    "#E5E5E5"
  ],
  [
    "primary_4",
    "#F2F2F2"
  ],
  [
    "secondary_0",
    "#0076DC"
  ],
  [
    "secondary_1",
    "#55B5FF"
  ],
  [
    "secondary_2",
    "#BBE1FF"
  ],
  [
    "secondary_3",
    "#EAF6FF"
  ],
  [
    "secondary_4",
    "#F8FCFF"
  ],
  [
    "neutral_1",
    "#0E3151"
  ],
  [
    "neutral_2",
    "#62798E"
  ],
  [
    "neutral_3",
    "#AFBAC5"
  ],
  [
    "neutral_4",
    "#C0C9D2"
  ],
  [
    "neutral_5",
    "#DFE3E8"
  ],
  [
    "neutral_6",
    "#E9ECEF"
  ],
  [
    "neutral_7",
    "#F3F5F7"
  ],
  [
    "neutral_8",
    "#F5F7F8"
  ],
  [
    "neutral_9",
    "#FFFFFF"
  ],
  [
    "error_0",
    "#C7111D"
  ],
  [
    "error_1",
    "#FF5560"
  ],
  [
    "error_2",
    "#FFBBBF"
  ],
  [
    "error_3",
    "#FFEBEC"
  ],
  [
    "error_4",
    "#FFF9F7"
  ],
  [
    "success_1",
    "#4AD15C"
  ],
  [
    "success_2",
    "#B7EDBE"
  ],
  [
    "success_3",
    "#E9F9EB"
  ],
  [
    "success_4",
    "#F8FDF8"
  ]
];

var tokens = Object.fromEntries(colors.map(function (param) {
          return [
                  param[0],
                  param[1]
                ];
        }));

function getColor(x) {
  if (typeof x !== "object") {
    if (x === "error_1") {
      return "error_1";
    } else if (x === "error_2") {
      return "error_2";
    } else if (x === "error_3") {
      return "error_3";
    } else if (x === "error_4") {
      return "error_4";
    } else if (x === "neutral_1") {
      return "neutral_1";
    } else if (x === "neutral_2") {
      return "neutral_2";
    } else if (x === "neutral_3") {
      return "neutral_3";
    } else if (x === "neutral_4") {
      return "neutral_4";
    } else if (x === "neutral_5") {
      return "neutral_5";
    } else if (x === "neutral_6") {
      return "neutral_6";
    } else if (x === "neutral_7") {
      return "neutral_7";
    } else if (x === "neutral_8") {
      return "neutral_8";
    } else if (x === "neutral_9") {
      return "neutral_9";
    } else if (x === "success_1") {
      return "success_1";
    } else if (x === "success_2") {
      return "success_2";
    } else if (x === "success_3") {
      return "success_3";
    } else if (x === "success_4") {
      return "success_4";
    } else if (x === "primary_0") {
      return "primary_0";
    } else if (x === "primary_1") {
      return "primary_1";
    } else if (x === "primary_2") {
      return "primary_2";
    } else if (x === "primary_3") {
      return "primary_3";
    } else if (x === "primary_4") {
      return "primary_4";
    } else if (x === "secondary_0") {
      return "secondary_0";
    } else if (x === "secondary_1") {
      return "secondary_1";
    } else if (x === "secondary_2") {
      return "secondary_2";
    } else if (x === "secondary_3") {
      return "secondary_3";
    } else if (x === "secondary_4") {
      return "secondary_4";
    } else if (x === "transparent") {
      return "transparent";
    } else {
      return "error_0";
    }
  }
  var variant = x.NAME;
  if (variant === "text") {
    var match = x.VAL;
    var variant$1 = match.NAME;
    if (variant$1 === "highlight") {
      if (match.VAL === "subdued") {
        return "secondary_2";
      } else {
        return "secondary_1";
      }
    }
    if (variant$1 === "error") {
      if (match.VAL === "subdued") {
        return "error_2";
      } else {
        return "error_1";
      }
    }
    if (variant$1 === "on_surface" || variant$1 === "on_background") {
      var match$1 = match.VAL;
      if (match$1 === "default") {
        return "neutral_1";
      } else if (match$1 === "subdued") {
        return "neutral_2";
      } else {
        return "neutral_3";
      }
    }
    if (variant$1 === "primary") {
      if (match.VAL === "subdued") {
        return "primary_2";
      } else {
        return "primary_1";
      }
    }
    if (variant$1 === "success") {
      if (match.VAL === "subdued") {
        return "success_2";
      } else {
        return "success_1";
      }
    }
    var match$2 = match.VAL;
    if (match$2 === "default") {
      return "neutral_9";
    } else if (match$2 === "subdued") {
      return "primary_2";
    } else {
      return "primary_3";
    }
  }
  if (variant === "surface") {
    if (x.VAL === "subdued") {
      return "neutral_8";
    } else {
      return "neutral_9";
    }
  }
  if (variant === "border") {
    var match$3 = x.VAL;
    var variant$2 = match$3.NAME;
    if (variant$2 === "highlight") {
      if (match$3.VAL === "subdued") {
        return "secondary_2";
      } else {
        return "secondary_1";
      }
    }
    if (variant$2 === "error") {
      if (match$3.VAL === "subdued") {
        return "error_2";
      } else {
        return "error_1";
      }
    }
    if (variant$2 === "success") {
      if (match$3.VAL === "subdued") {
        return "success_2";
      } else {
        return "success_1";
      }
    }
    var match$4 = match$3.VAL;
    if (match$4 === "default") {
      return "neutral_5";
    } else if (match$4 === "subdued") {
      return "neutral_6";
    } else if (match$4 === "hover") {
      return "neutral_4";
    } else if (match$4 === "pressed") {
      return "neutral_3";
    } else {
      return "neutral_8";
    }
  }
  if (variant === "action") {
    var match$5 = x.VAL;
    var variant$3 = match$5.NAME;
    if (variant$3 === "error") {
      var match$6 = match$5.VAL;
      if (match$6 === "pressed" || match$6 === "default") {
        return "error_1";
      } else if (match$6 === "hover") {
        return "error_0";
      } else {
        return "error_2";
      }
    }
    if (variant$3 === "primary") {
      var match$7 = match$5.VAL;
      if (match$7 === "hover" || match$7 === "default") {
        return "primary_1";
      } else if (match$7 === "pressed") {
        return "primary_0";
      } else {
        return "primary_2";
      }
    }
    var match$8 = match$5.VAL;
    if (match$8 === "hover" || match$8 === "default") {
      return "neutral_1";
    } else if (match$8 === "pressed") {
      return "neutral_2";
    } else {
      return "neutral_3";
    }
  }
  if (variant === "background") {
    if (x.VAL === "subdued") {
      return "neutral_6";
    } else {
      return "neutral_7";
    }
  }
  var match$9 = x.VAL;
  var variant$4 = match$9.NAME;
  if (variant$4 === "neutral") {
    var match$10 = match$9.VAL;
    if (match$10 === "default") {
      return "neutral_2";
    } else if (match$10 === "pressed" || match$10 === "subdued") {
      return "neutral_3";
    } else if (match$10 === "hover") {
      return "neutral_1";
    } else {
      return "neutral_4";
    }
  }
  if (variant$4 === "highlight") {
    if (match$9.VAL === "subdued") {
      return "secondary_2";
    } else {
      return "secondary_1";
    }
  }
  if (variant$4 === "error") {
    if (match$9.VAL === "subdued") {
      return "error_2";
    } else {
      return "error_1";
    }
  }
  if (variant$4 === "primary") {
    var match$11 = match$9.VAL;
    if (match$11 === "hover" || match$11 === "default") {
      return "primary_1";
    } else if (match$11 === "pressed") {
      return "primary_0";
    } else {
      return "primary_2";
    }
  }
  if (variant$4 === "success") {
    if (match$9.VAL === "subdued") {
      return "success_2";
    } else {
      return "success_1";
    }
  }
  var match$12 = match$9.VAL;
  if (match$12 === "default") {
    return "neutral_9";
  } else if (match$12 === "subdued") {
    return "primary_2";
  } else {
    return "primary_3";
  }
}

function token(x) {
  var color = getColor(x);
  return "$" + color;
}

function value(x) {
  var color = getColor(x);
  return Core__Option.getOr(Core__Option.map(colors.find(function (param) {
                      return param[0] === color;
                    }), (function (param) {
                    return param[1];
                  })), "#000000");
}

var Space = {
  componentSpaceTokens: componentSpaceTokens,
  sectionSpaceTokens: sectionSpaceTokens,
  componentSpaceTokenPrefix: componentSpaceTokenPrefix,
  sectionSpaceTokenPrefix: sectionSpaceTokenPrefix,
  makeSpaceToken: makeSpaceToken
};

var Radius = {
  radiusTokens: radiusTokens,
  radiusTokenPrefix: radiusTokenPrefix,
  radiusTokenDelimiter: radiusTokenDelimiter,
  makeRadiusToken: makeRadiusToken
};

var FontSize = {
  fontSizeTokens: fontSizeTokens,
  fontLineHeightTokens: fontLineHeightTokens,
  fontLetterSpacingTokens: fontLetterSpacingTokens,
  makeCommonConfig: makeCommonConfig
};

var IconSizes = {
  iconSizesTokens: iconSizesTokens,
  iconSizesTokenPrefix: iconSizesTokenPrefix,
  iconSizesTokenDelimiter: iconSizesTokenDelimiter,
  makeIconSizesToken: makeIconSizesToken
};

var Colors = {
  tokens: tokens,
  token: token,
  value: value
};

export {
  Space ,
  Radius ,
  FontSize ,
  IconSizes ,
  Colors ,
}
/* componentSpaceTokens Not a pure module */
