// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../../text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";

function Primitives__Error__Message(props) {
  var __textAlign = props.textAlign;
  var kind = props.kind;
  var textAlign = __textAlign !== undefined ? __textAlign : "center";
  return ReactUtils__SimpleReact.renderIfSome(props.error, (function (error) {
                return JsxRuntime.jsx(Primitives__DecidrText.make, {
                            style: {
                              fontWeight: 500,
                              textAlign: textAlign
                            },
                            kind: kind,
                            children: Caml_option.some(error),
                            color: Primitives__Styling.color({
                                  NAME: "text",
                                  VAL: {
                                    NAME: "error",
                                    VAL: "default"
                                  }
                                })
                          });
              }));
}

var make = Primitives__Error__Message;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
