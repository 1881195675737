// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";

var blockCode = "fragment";

function DecidrRenderer__Blocks__Fragment$Component(props) {
  var __children = props.children;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  return JsxRuntime.jsx(JsxRuntime.Fragment, {
              children: Caml_option.some(children)
            });
}

var Component = {
  make: DecidrRenderer__Blocks__Fragment$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

var minRendererVersion = Renderer__Version.current;

export {
  blockCode ,
  version ,
  minRendererVersion ,
  descriptor ,
}
/* react/jsx-runtime Not a pure module */
