// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function normalizeProperty(prim) {
  return prim;
}

function makeContext(networkInfo) {
  if (Core__Option.isNone(Caml_option.undefined_to_opt(typeof window === "undefined" ? undefined : window))) {
    return {};
  } else {
    return {
            webContext: {
              locale: Core__Option.map(Caml_option.undefined_to_opt(typeof navigator === "undefined" ? undefined : navigator), (function ($$navigator) {
                      return $$navigator.language;
                    })),
              location: Core__Option.map(Caml_option.undefined_to_opt(typeof location === "undefined" ? undefined : location), (function ($$location) {
                      return $$location.href;
                    })),
              network: {
                ip: networkInfo.ipAddress
              },
              origin: Core__Option.map(Caml_option.undefined_to_opt(typeof location === "undefined" ? undefined : location), (function ($$location) {
                      return $$location.origin;
                    })),
              screen: {
                height: Core__Option.map(Caml_option.undefined_to_opt(typeof screen === "undefined" ? undefined : screen), (function (screen) {
                        return screen.height;
                      })),
                orientation: Core__Option.map(Caml_option.undefined_to_opt(typeof screen === "undefined" ? undefined : screen), (function (screen) {
                        return Core__Option.map(screen.orientation, (function (orientation) {
                                      return orientation.type;
                                    }));
                      })),
                width: Core__Option.map(Caml_option.undefined_to_opt(typeof screen === "undefined" ? undefined : screen), (function (screen) {
                        return screen.width;
                      }))
              },
              userAgent: Core__Option.map(Caml_option.undefined_to_opt(typeof navigator === "undefined" ? undefined : navigator), (function ($$navigator) {
                      return $$navigator.userAgent;
                    }))
            }
          };
  }
}

export {
  normalizeProperty ,
  makeContext ,
}
/* No side effect */
