// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_response_updateCell_cell(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "CurrencyCell",
              "DateCell",
              "NumberCell",
              "PercentCell",
              "SwitchCell",
              "TextCell"
            ]);
}

function unwrap_rawResponse_updateCell_cell(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, [
              "CurrencyCell",
              "DateCell",
              "NumberCell",
              "PercentCell",
              "SwitchCell",
              "TextCell"
            ]);
}

var variablesConverter = {"__root":{"value":{"c":"RelayAppSync.AWSJSON"},"rowId":{"c":"DecidrNodeId.RelayAppSync"},"columnViewId":{"c":"DecidrNodeId.RelayAppSync"},"columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize,
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"updateCell_cell_TextCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_TextCell":{"f":""},"updateCell_cell_SwitchCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell":{"f":""},"updateCell_cell_PercentCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell":{"f":""},"updateCell_cell_NumberCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell":{"f":""},"updateCell_cell_DateCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell":{"f":""},"updateCell_cell_CurrencyCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell":{"f":""},"updateCell_cell":{"u":"response_updateCell_cell"}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize,
  response_updateCell_cell: RescriptRelay_Internal.wrapUnion
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"updateCell_cell_TextCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_TextCell":{"f":""},"updateCell_cell_SwitchCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell":{"f":""},"updateCell_cell_PercentCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell":{"f":""},"updateCell_cell_NumberCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell":{"f":""},"updateCell_cell_DateCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell":{"f":""},"updateCell_cell_CurrencyCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell":{"f":""},"updateCell_cell":{"u":"response_updateCell_cell"}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  response_updateCell_cell: unwrap_response_updateCell_cell
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var wrapRawResponseConverter = {"__root":{"updateCell_cell_TextCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_TextCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_TextCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell":{"u":"rawResponse_updateCell_cell"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize,
  rawResponse_updateCell_cell: RescriptRelay_Internal.wrapUnion
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"updateCell_cell_TextCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_TextCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_TextCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_SwitchCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_PercentCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_NumberCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_DateCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell_CurrencyCell_channels_id":{"c":"DecidrNodeId.RelayAppSync"},"updateCell_cell":{"u":"rawResponse_updateCell_cell"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  rawResponse_updateCell_cell: unwrap_rawResponse_updateCell_cell
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnViewId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "value"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "columnId",
        "variableName": "columnId"
      },
      {
        "kind": "Variable",
        "name": "columnViewId",
        "variableName": "columnViewId"
      },
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId"
      },
      {
        "kind": "Variable",
        "name": "value",
        "variableName": "value"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "text",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "concreteType": "Channel",
  "kind": "LinkedField",
  "name": "channels",
  "plural": true,
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    }
  ],
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "date",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "number",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "percent",
  "storageKey": null
},
v13 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "currency",
  "storageKey": null
},
v14 = {
  "alias": "switched",
  "args": null,
  "kind": "ScalarField",
  "name": "switch",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesCellsMutations_updateCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateCellPayload",
        "kind": "LinkedField",
        "name": "updateCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "SpacesTextCell_textCell",
                    "selections": [
                      (v6/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v9/*: any*/)
                ],
                "type": "TextCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "SpacesDateCell_dateCell",
                    "selections": [
                      (v10/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v9/*: any*/)
                ],
                "type": "DateCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "SpacesNumberCell_numberCell",
                    "selections": [
                      (v11/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v9/*: any*/)
                ],
                "type": "NumberCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "SpacesPercentCell_percentCell",
                    "selections": [
                      (v12/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v9/*: any*/)
                ],
                "type": "PercentCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "SpacesCurrencyCell_currencyCell",
                    "selections": [
                      (v13/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v9/*: any*/)
                ],
                "type": "CurrencyCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  {
                    "kind": "InlineDataFragmentSpread",
                    "name": "SpacesSwitchCell_switchCell",
                    "selections": [
                      (v14/*: any*/),
                      (v7/*: any*/),
                      (v8/*: any*/)
                    ],
                    "args": null,
                    "argumentDefinitions": []
                  },
                  (v9/*: any*/)
                ],
                "type": "SwitchCell",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesCellsMutations_updateCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateCellPayload",
        "kind": "LinkedField",
        "name": "updateCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              (v5/*: any*/),
              {
                "kind": "InlineFragment",
                "selections": [
                  (v6/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "TextCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v10/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "DateCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v11/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "NumberCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v12/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "PercentCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v13/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "CurrencyCell",
                "abstractKey": null
              },
              {
                "kind": "InlineFragment",
                "selections": [
                  (v14/*: any*/),
                  (v7/*: any*/),
                  (v8/*: any*/),
                  (v9/*: any*/)
                ],
                "type": "SwitchCell",
                "abstractKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "9e51ba78259c9655e14a011e2bf7ca2d",
    "id": null,
    "metadata": {},
    "name": "SpacesCellsMutations_updateCellMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesCellsMutations_updateCellMutation(\n  $rowId: ID!\n  $columnId: ID!\n  $columnViewId: ID!\n  $value: AWSJSON!\n) {\n  updateCell(input: {rowId: $rowId, columnId: $columnId, columnViewId: $columnViewId, value: $value}) {\n    cell {\n      __typename\n      ... on TextCell {\n        ...SpacesTextCell_textCell\n        channels {\n          id\n        }\n      }\n      ... on DateCell {\n        ...SpacesDateCell_dateCell\n        channels {\n          id\n        }\n      }\n      ... on NumberCell {\n        ...SpacesNumberCell_numberCell\n        channels {\n          id\n        }\n      }\n      ... on PercentCell {\n        ...SpacesPercentCell_percentCell\n        channels {\n          id\n        }\n      }\n      ... on CurrencyCell {\n        ...SpacesCurrencyCell_currencyCell\n        channels {\n          id\n        }\n      }\n      ... on SwitchCell {\n        ...SpacesSwitchCell_switchCell\n        channels {\n          id\n        }\n      }\n    }\n  }\n}\n\nfragment SpacesCurrencyCell_currencyCell on CurrencyCell {\n  currency\n  rowId\n  columnId\n}\n\nfragment SpacesDateCell_dateCell on DateCell {\n  date\n  rowId\n  columnId\n}\n\nfragment SpacesNumberCell_numberCell on NumberCell {\n  number\n  rowId\n  columnId\n}\n\nfragment SpacesPercentCell_percentCell on PercentCell {\n  percent\n  rowId\n  columnId\n}\n\nfragment SpacesSwitchCell_switchCell on SwitchCell {\n  switched: switch\n  rowId\n  columnId\n}\n\nfragment SpacesTextCell_textCell on TextCell {\n  text\n  rowId\n  columnId\n}\n"
  }
};
})());

var wrap_response_updateCell_cell = RescriptRelay_Internal.wrapUnion;

var wrap_rawResponse_updateCell_cell = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_response_updateCell_cell ,
  wrap_response_updateCell_cell ,
  unwrap_rawResponse_updateCell_cell ,
  wrap_rawResponse_updateCell_cell ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
