export const downloadFile = async ({
  url,
  name,
}: {
  url: string;
  name?: string;
}) => {
  try {
    // Fetch the resource
    const response = await fetch(url);
    const blob = await response.blob();

    // Create a Blob URL
    const blobUrl = URL.createObjectURL(blob);

    // Generate a download link
    const downloadLink = document.createElement("a");
    downloadLink.href = blobUrl;
    downloadLink.download = name ?? url;

    // Trigger the download
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);

    // Release the Blob URL
    URL.revokeObjectURL(blobUrl);
  } catch (e) {
    console.log("Error downloading file", e);
  }
};
