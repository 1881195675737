// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

function make(count, singular, plural, zero) {
  if (count < 0) {
    return ;
  } else if (count === 0) {
    return Core__Option.map(zero, (function (zero) {
                  return "0 " + zero;
                }));
  } else if (count === 1) {
    return "1 " + singular;
  } else {
    return count.toString() + " " + plural;
  }
}

export {
  make ,
}
/* No side effect */
