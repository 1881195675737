// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Blocks__Text from "@pageblocks/blocks/src/Blocks__Text.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";

var blockCode = "platform-text";

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_component = Blocks__Text.descriptor.component;

var descriptor_declaredTriggers = Blocks__Text.descriptor.declaredTriggers;

var descriptor_declaredSettings = Blocks__Text.descriptor.declaredSettings;

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: descriptor_component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var version = 1;

var minRendererVersion = Renderer__Version.current;

export {
  blockCode ,
  version ,
  minRendererVersion ,
  descriptor ,
}
/* Blocks__Text Not a pure module */
