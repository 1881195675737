// Generated by ReScript, PLEASE EDIT WITH CARE


function make(entries) {
  return Object.fromEntries(entries);
}

function empty() {
  return {};
}

function get(meta, key) {
  return meta[key];
}

function Key_toString(prim) {
  return prim;
}

var Key = {
  selectedRowInfoId: "selectedRowInfoId",
  multiSelectRowId: "callerRowId",
  multiSelectColumnId: "callerColumnId",
  linkColumnViewId: "callerColumnViewId",
  toString: Key_toString
};

export {
  Key ,
  make ,
  empty ,
  get ,
}
/* No side effect */
