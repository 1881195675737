// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as $$Date from "@tamagui-extras/date";

function DecidrDatepicker__Datepicker(props) {
  var onChange = props.onChange;
  var value = props.value;
  return JsxRuntime.jsx($$Date.LmDatepicker, {
              startDate: Core__Option.map(value, (function (prim) {
                      return prim;
                    })),
              value: Core__Option.map(value, (function (prim) {
                      return prim;
                    })),
              onChange: (function (param) {
                  var startDate = param.startDate;
                  if (!(startDate == null)) {
                    return onChange(startDate);
                  }
                  
                }),
              popoverProps: {
                allowFlip: true
              }
            });
}

var make = DecidrDatepicker__Datepicker;

export {
  make ,
}
/* react/jsx-runtime Not a pure module */
