// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as CamlinternalLazy from "rescript/lib/es6/camlinternalLazy.js";

function renderIfSome(x, fn) {
  return Core__Option.getOr(Core__Option.map(x, fn), null);
}

function leaveIfSome(__x) {
  return Core__Option.getOr(__x, null);
}

function whenTrue(x, element) {
  if (x) {
    return CamlinternalLazy.force(element);
  } else {
    return null;
  }
}

function whenNotFirst(x, element) {
  if (x !== 0) {
    return CamlinternalLazy.force(element);
  } else {
    return null;
  }
}

export {
  renderIfSome ,
  leaveIfSome ,
  whenTrue ,
  whenNotFirst ,
}
/* No side effect */
