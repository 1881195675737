import { FunctionComponent } from "react";
import { StackProps, getTokens } from "tamagui";

import {
  IconSizes_t,
  IconSizes_makeIconSizesToken as makeIconSizesToken,
} from "@decidr/tamagui-config/src/tokens/TamaguiConfig__Tokens.gen";
import { breakpoint } from "@decidr/tamagui-config/src/media/TamaguiConfig__Media.gen";

type BreakpointStyles = Pick<StackProps, `$${breakpoint}`>;

type IconProps = {
  size?: number | string;
  color?: string;
} & BreakpointStyles;

type IconComponent = FunctionComponent<IconProps>;

const tokens = getTokens();

type Size = typeof tokens.size;

type SizeToken = keyof Size;

export const Icon = ({
  color,
  size,
  icon: Icon,
}: {
  color?: string;
  size: IconSizes_t;
  icon: IconComponent;
}) => {
  const responsiveSize = {
    $s: {
      width:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "s")) as SizeToken]?.val,
      height:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "s")) as SizeToken]?.val,
    },
    $m: {
      width:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "m")) as SizeToken]?.val,
      height:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "m")) as SizeToken]?.val,
    },
    $l: {
      width:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "l")) as SizeToken]?.val,
      height:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "l")) as SizeToken]?.val,
    },
    $xl: {
      width:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "xl")) as SizeToken]?.val,
      height:
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
        tokens.size[("$" + makeIconSizesToken(size, "xl")) as SizeToken]?.val,
    },
  };
  return (
    <Icon
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      color={tokens.color[color ?? "$color1"]?.variable}
      {...responsiveSize}
    />
  );
};
