// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../internal-id/src/DecidrInternalId.bs.js";

function make(rowId, viewId) {
  return DecidrInternalId.toString(rowId) + "_" + DecidrNodeId.toString(viewId);
}

function toString(t) {
  return t;
}

function magicallyToNodeId(x) {
  return x;
}

function components(rowCacheId) {
  var parts = rowCacheId.split("_");
  var match = parts.length;
  if (match < 2) {
    if (match >= 0) {
      return Js_exn.raiseError("[RowCacheId] Too few components");
    } else {
      return Js_exn.raiseError("[RowCacheId] Too many components");
    }
  }
  if (match >= 3) {
    return Js_exn.raiseError("[RowCacheId] Too many components");
  }
  var rowId = DecidrInternalId.fromString(parts[0]);
  var rowId$1 = rowId !== undefined ? Caml_option.valFromOption(rowId) : Js_exn.raiseError("[RowCacheId] Failed to parse Row ID");
  var viewId = DecidrNodeId.fromString(parts[1]);
  var viewId$1 = viewId !== undefined ? Caml_option.valFromOption(viewId) : Js_exn.raiseError("[RowCacheId] Failed to parse View ID");
  return {
          rowId: rowId$1,
          viewId: viewId$1
        };
}

export {
  make ,
  toString ,
  magicallyToNodeId ,
  components ,
}
/* DecidrNodeId Not a pure module */
