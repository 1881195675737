import { Platform } from "react-native";
import { createTamagui, createTokens, createFont } from "tamagui";
import { createInterFont } from "@tamagui/font-inter";
import { shorthands } from "@tamagui/shorthands";
import { themes, tokens as originalTokens } from "@tamagui/themes";
import { createMedia } from "@tamagui/react-native-media-driver";

import { animations } from "./animations";
import { breakpoint } from "./media/TamaguiConfig__Media.gen";
import {
  Space_componentSpaceTokens,
  Space_sectionSpaceTokens,
  Radius_radiusTokens,
  FontSize_fontSizeTokens,
  FontSize_fontLetterSpacingTokens,
  FontSize_fontLineHeightTokens,
  FontSize_makeCommonConfig,
  IconSizes_iconSizesTokens,
  Colors_tokens,
} from "./tokens/TamaguiConfig__Tokens.gen";

export const robotoKey = "__Roboto_b0d14b";

const robotoFont =
  Platform.OS === "web"
    ? `${robotoKey}, Helvetica, Arial, sans-serif`
    : "Roboto";

const decidrFont = createFont({
  family: robotoFont,
  size: {
    ...FontSize_fontSizeTokens,
  },
  letterSpacing: {
    ...FontSize_fontLetterSpacingTokens,
  },
  lineHeight: {
    ...FontSize_fontLineHeightTokens,
  },
  transform: {
    ...FontSize_makeCommonConfig("none"),
  },
  weight: {
    ...FontSize_makeCommonConfig("400"),
  },
  face: {
    400: { normal: "Roboto" },
    500: { normal: "Roboto-Medium" },
    700: { normal: "Roboto-Bold" },
  },
});

const headingFont = createInterFont({
  size: {
    6: 15,
  },
  transform: {
    6: "uppercase",
    7: "none",
  },
  weight: {
    6: "400",
    7: "700",
  },
  color: {
    6: "$colorFocus",
    7: "$color",
  },
  letterSpacing: {
    5: 2,
    6: 1,
    7: 0,
    8: -1,
    9: -2,
    10: -3,
    12: -4,
    14: -5,
    15: -6,
  },
  face: {
    700: { normal: "InterBold" },
  },
});

const bodyFont = createInterFont(
  {
    face: {
      700: { normal: "InterBold" },
    },
  },
  {
    sizeSize: size => Math.round(size * 1.1),
    sizeLineHeight: size => Math.round(size * 1.1 + (size > 20 ? 10 : 10)),
  }
);

const tokens = createTokens({
  space: {
    // Original tokens needed for internal tamagui components
    ...originalTokens.space,
    ...Space_componentSpaceTokens,
    ...Space_sectionSpaceTokens,
  },
  color: {
    // Original tokens needed for internal tamagui components
    // ...originalTokens.color,
    ...Colors_tokens,
  },
  radius: {
    // Original tokens needed for internal tamagui components
    ...originalTokens.radius,
    ...Radius_radiusTokens,
  },
  size: {
    // Original tokens needed for internal tamagui components
    ...originalTokens.size,
    ...FontSize_fontSizeTokens,
    ...IconSizes_iconSizesTokens,
  },
  zIndex: {},
});

const media: Record<
  breakpoint,
  {
    minWidth?: number;
    maxWidth?: number;
  }
> = {
  s: { maxWidth: 767 },
  m: { minWidth: 768, maxWidth: 1279 },
  l: { minWidth: 1280, maxWidth: 1919 },
  xl: { minWidth: 1920 },
};

export const config = createTamagui({
  animations,
  shouldAddPrefersColorThemes: true,
  themeClassNameOnRoot: true,
  shorthands,
  fonts: {
    heading: headingFont,
    body: bodyFont,
    decidrFont: decidrFont,
  },
  themes: {
    ...themes,
    light: { ...themes.light, background: tokens.color["neutral_9"] },
    dark: { ...themes.dark, background: tokens.color["neutral_9"] },
  },
  tokens: {
    ...originalTokens,
    space: tokens.space,
    radius: tokens.radius,
    size: tokens.size,
    color: tokens.color,
  },
  media: createMedia(media),
});
