// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__View from "../view/Spaces__View.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Belt_SetString from "rescript/lib/es6/belt_SetString.js";
import * as ErrorReporting from "../../../error-reporting/src/ErrorReporting.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as DecidrRowCacheId from "../../../row-cache-id/src/DecidrRowCacheId.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as Spaces__RelayStoreHelpers from "../utils/Spaces__RelayStoreHelpers.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesRowMutationsCreateRowMutation_graphql from "../../../relay-codegen/generated/SpacesRowMutationsCreateRowMutation_graphql.bs.js";
import * as SpacesRowMutationsDeleteRowMutation_graphql from "../../../relay-codegen/generated/SpacesRowMutationsDeleteRowMutation_graphql.bs.js";
import * as SpacesRowMutationsCreateRowJSONMutation_graphql from "../../../relay-codegen/generated/SpacesRowMutationsCreateRowJSONMutation_graphql.bs.js";

var convertVariables = SpacesRowMutationsCreateRowMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesRowMutationsCreateRowMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesRowMutationsCreateRowMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesRowMutationsCreateRowMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesRowMutationsCreateRowMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useCreate() {
  var match = use();
  var addRow = match[0];
  var environment = RescriptRelay.useEnvironmentFromContext();
  var handleAddRow = function (viewId, createdFrom, onCompleted, relatedListColumnId) {
    addRow({
          createdFrom: Core__Option.map(createdFrom, (function (param) {
                  return {
                          createdFromColumnViewId: DecidrNodeId.toString(param.columnViewId),
                          createdFromRowId: DecidrInternalId.toString(param.rowId),
                          createdFromViewId: DecidrNodeId.toString(param.viewId)
                        };
                })),
          viewId: viewId
        }, undefined, undefined, (function (store, response) {
            var createdRowId = DecidrNodeId.magicallyToInternalId(response.createRow.row.id);
            var rowTableViewIds = response.createRow.row.table.views.map(function (param) {
                  return param.id;
                });
            var viewIdsToUpdate = rowTableViewIds.some(function (rowTableViewId) {
                  return viewId === rowTableViewId;
                }) ? rowTableViewIds : rowTableViewIds.concat([viewId]);
            viewIdsToUpdate.forEach(function (viewId) {
                  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(viewId))), (function (viewProxy) {
                          var $$arguments = createdFrom !== undefined ? ({
                                caller: {
                                  rowId: createdFrom.rowId,
                                  columnViewId: createdFrom.columnViewId,
                                  viewId: createdFrom.viewId
                                }
                              }) : undefined;
                          RelayUtils.Helpers.updateInt(viewProxy, "rowCount", (function (value) {
                                  return value + 1 | 0;
                                }), $$arguments);
                        }));
                });
            viewIdsToUpdate.forEach(function (viewId) {
                  var viewConnectionId = createdFrom !== undefined ? RelayRuntime.ConnectionHandler.getConnectionID(DecidrNodeId.toString(viewId), Spaces__View.Data.ViewFragment.Operation.connectionKey, {
                          searchTerm: "",
                          caller: {
                            columnViewId: createdFrom.columnViewId,
                            rowId: createdFrom.rowId,
                            viewId: createdFrom.viewId
                          }
                        }) : RelayRuntime.ConnectionHandler.getConnectionID(DecidrNodeId.toString(viewId), Spaces__View.Data.ViewFragment.Operation.connectionKey, {
                          searchTerm: ""
                        });
                  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(viewConnectionId)), (function (viewConnection) {
                          var nodeDataId = DecidrRowCacheId.toString(DecidrRowCacheId.make(createdRowId, viewId));
                          var rowRecordProxy = store.get(nodeDataId);
                          if (rowRecordProxy == null) {
                            var newRowRP = store.create(nodeDataId, "Row");
                            Core__Option.forEach(Caml_option.nullable_to_opt(store.get(response.createRow.row.__id)), (function (createdRowRP) {
                                    newRowRP.copyFieldsFrom(createdRowRP);
                                  }));
                          }
                          var addingToConnection = RelayUtils.Helpers.addUniqueEdgeToConnection(store, viewConnection, nodeDataId, "RowEdge", "first");
                          if (addingToConnection === "notFound") {
                            return ErrorReporting.captureLog("Node not found in cache", "Spaces__Row__Mutations", Caml_option.some(nodeDataId));
                          }
                          
                        }));
                });
            var relatedListColumnId$1 = Core__Option.getOr(relatedListColumnId, DecidrInternalId.empty);
            var cachedViews = Spaces__RelayStoreHelpers.readCachedViewIds(environment.getStore().getSource());
            var cachedRowsGetter = function (viewId) {
              return Spaces__RelayStoreHelpers.getCachedRowsToUpdate(environment.getStore().getSource(), viewId);
            };
            var cachedTableLinkInfos = Spaces__RelayStoreHelpers.readCachedTableLinkInfosToUpdate(environment.getStore().getSource(), viewId);
            cachedTableLinkInfos.forEach(function (tableLinkInfoId) {
                  var cachedViewsToUpdate = Spaces__RelayStoreHelpers.getAllViewsToUpdate(store, cachedViews, relatedListColumnId$1, undefined);
                  var viewIds = cachedViewsToUpdate.some(function (cachedViewId) {
                        return cachedViewId === viewId;
                      }) ? cachedViewsToUpdate : cachedViewsToUpdate.concat([viewId]);
                  Belt_SetString.forEach(Belt_SetString.fromArray(Core__Array.reduce(viewIds, [], (function (acc, viewId) {
                                  return acc.concat(cachedRowsGetter(viewId).map(DecidrInternalId.toString));
                                }))), (function (callerRowId) {
                          var relatedListDataConnectionId = RelayRuntime.ConnectionHandler.getConnectionID(RelayRuntime.ROOT_ID, "SpacesBlockRelatedList_relatedListData", {
                                searchTerm: "",
                                callerRowId: callerRowId,
                                tableLinkInfoId: DecidrInternalId.toString(tableLinkInfoId)
                              });
                          Core__Option.forEach(Caml_option.nullable_to_opt(store.get(relatedListDataConnectionId)), (function (relatedListDataConnection) {
                                  RelayUtils.Helpers.addUniqueEdgeToConnection(store, relatedListDataConnection, DecidrRowCacheId.toString(DecidrRowCacheId.make(createdRowId, viewId)), "RowEdge", "first");
                                }));
                        }));
                });
          }), (function (param, errors) {
            var row = param.createRow.row;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating record", "Spaces__Row__Mutations handleAddRow", undefined, (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted(DecidrNodeId.magicallyToInternalId(row.id), viewId);
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating record", "Spaces__Row__Mutations handleAddRow", undefined);
          }), undefined);
  };
  return [
          handleAddRow,
          match[1]
        ];
}

var convertVariables$1 = SpacesRowMutationsDeleteRowMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesRowMutationsDeleteRowMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesRowMutationsDeleteRowMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesRowMutationsDeleteRowMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesRowMutationsDeleteRowMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useDelete() {
  var match = use$1();
  var deleteRow = match[0];
  var environment = RescriptRelay.useEnvironmentFromContext();
  var handleDeleteRow = function (rowId, viewId, onCompleted, onError) {
    deleteRow({
          rowId: DecidrNodeId.magicallyFromInternalId(rowId),
          viewId: viewId
        }, undefined, undefined, (function (store, response) {
            var cachedViewIds = Spaces__RelayStoreHelpers.getCachedViewIdsFromRowId(environment.getStore().getSource(), DecidrNodeId.magicallyToInternalId(response.deleteRow.deletedRowId));
            cachedViewIds.forEach(function (viewId) {
                  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(viewId))), (function (viewProxy) {
                          RelayUtils.Helpers.updateInt(viewProxy, "rowCount", (function (value) {
                                  return value - 1 | 0;
                                }), undefined);
                        }));
                });
            cachedViewIds.forEach(function (singleViewId) {
                  var connectionToUpdate = RelayRuntime.ConnectionHandler.getConnectionID(DecidrNodeId.toString(singleViewId), Spaces__View.Data.ViewFragment.Operation.connectionKey, {
                        searchTerm: ""
                      });
                  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(connectionToUpdate)), (function (connectionRP) {
                          RelayRuntime.ConnectionHandler.deleteNode(connectionRP, DecidrRowCacheId.toString(DecidrRowCacheId.make(rowId, singleViewId)));
                        }));
                });
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error deleting row", "Spaces__Row__Mutations useDelete", onError, (function () {
                    onCompleted(viewId);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error deleting row", "Spaces__Row__Mutations useDelete", onError);
          }), undefined);
  };
  return [
          handleDeleteRow,
          match[1]
        ];
}

var convertVariables$2 = SpacesRowMutationsCreateRowJSONMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesRowMutationsCreateRowJSONMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesRowMutationsCreateRowJSONMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesRowMutationsCreateRowJSONMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesRowMutationsCreateRowJSONMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function triggerCreateRowJSON(environment, input, onSuccess, onCompleted, onError) {
  commitMutation(environment, {
        input: input
      }, undefined, undefined, undefined, (function (_response, errors) {
          ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating row", "Spaces__Row__Mutations triggerCreateRowJSON", onError, onSuccess, onCompleted);
        }), (function (errors) {
          ErrorHandling__RelayMutationError.handle(errors, "Error creating row", "Spaces__Row__Mutations triggerCreateRowJSON", onError);
        }), undefined);
}

export {
  useCreate ,
  useDelete ,
  triggerCreateRowJSON ,
}
/*  Not a pure module */
