// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrCellId from "../../../cell-id/src/DecidrCellId.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as DecidrRowCacheId from "../../../row-cache-id/src/DecidrRowCacheId.bs.js";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as Spaces__RelayStoreHelpers from "../utils/Spaces__RelayStoreHelpers.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesCellsMutations_deleteCellMutation_graphql from "../../../relay-codegen/generated/SpacesCellsMutations_deleteCellMutation_graphql.bs.js";
import * as SpacesCellsMutations_updateCellMutation_graphql from "../../../relay-codegen/generated/SpacesCellsMutations_updateCellMutation_graphql.bs.js";
import * as SpacesCellsMutations_autoFillCellMutation_graphql from "../../../relay-codegen/generated/SpacesCellsMutations_autoFillCellMutation_graphql.bs.js";
import * as SpacesCellsMutations_updateFileCellMutation_graphql from "../../../relay-codegen/generated/SpacesCellsMutations_updateFileCellMutation_graphql.bs.js";
import * as SpacesCellsMutations_updateImageCellMutation_graphql from "../../../relay-codegen/generated/SpacesCellsMutations_updateImageCellMutation_graphql.bs.js";
import * as SpacesCellsMutations_updateMultiSelectCellMutation_graphql from "../../../relay-codegen/generated/SpacesCellsMutations_updateMultiSelectCellMutation_graphql.bs.js";

function checkIfCellIsInArray(cellsRP, rowId, columnId) {
  return cellsRP.some(function (cellRP) {
              var cellRowId = Core__Option.flatMap(cellRP, (function (__x) {
                      return RelayUtils.RecordProxy.getValueString(__x, "rowId", undefined);
                    }));
              var cellColumnId = Core__Option.flatMap(cellRP, (function (__x) {
                      return RelayUtils.RecordProxy.getValueString(__x, "columnId", undefined);
                    }));
              if (cellRowId !== undefined && cellColumnId !== undefined && cellRowId === DecidrInternalId.toString(rowId)) {
                return cellColumnId === DecidrInternalId.toString(columnId);
              } else {
                return false;
              }
            });
}

function updateRowViewCellsArray(store, rowId, viewId, columnId, cellToInsertRP) {
  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrRowCacheId.toString(DecidrRowCacheId.make(rowId, viewId)))), (function (rowRP) {
          var cellsRP = RelayUtils.RecordProxy.getLinkedRecords(rowRP, "cells", undefined);
          Core__Option.forEach(cellsRP, (function (cellsRP) {
                  if (!checkIfCellIsInArray(cellsRP, rowId, columnId)) {
                    return RelayUtils.RecordProxy.setLinkedRecords(rowRP, cellsRP.concat([Caml_option.some(cellToInsertRP)]), "cells", undefined);
                  }
                  
                }));
        }));
}

function removeCellFromAllAffectedViews(environment, store, rowId, columnId) {
  var allCachedViewIds = Spaces__RelayStoreHelpers.readCachedViewIds(environment.getStore().getSource());
  var cellId = DecidrCellId.make(rowId, columnId);
  Spaces__RelayStoreHelpers.getAllViewsToUpdate(store, allCachedViewIds, columnId, Caml_option.some(rowId)).forEach(function (viewId) {
        Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrRowCacheId.toString(DecidrRowCacheId.make(rowId, viewId)))), (function (rowRP) {
                var cellsRP = RelayUtils.RecordProxy.getLinkedRecords(rowRP, "cells", undefined);
                Core__Option.forEach(cellsRP, (function (cellsRP) {
                        RelayUtils.RecordProxy.setLinkedRecords(rowRP, cellsRP.filter(function (cellRP) {
                                  var cellRowId = Core__Option.flatMap(Core__Option.flatMap(cellRP, (function (__x) {
                                              return RelayUtils.RecordProxy.getValueString(__x, "rowId", undefined);
                                            })), DecidrInternalId.fromString);
                                  var cellColumnId = Core__Option.flatMap(Core__Option.flatMap(cellRP, (function (__x) {
                                              return RelayUtils.RecordProxy.getValueString(__x, "columnId", undefined);
                                            })), DecidrInternalId.fromString);
                                  if (cellRowId !== undefined && cellColumnId !== undefined) {
                                    return cellId !== DecidrCellId.make(Caml_option.valFromOption(cellRowId), Caml_option.valFromOption(cellColumnId));
                                  } else {
                                    return true;
                                  }
                                }), "cells", undefined);
                      }));
              }));
      });
}

function updateOrAddCellToEveryAffectedView(environment, store, rowId, columnId) {
  var allCachedViewIds = Spaces__RelayStoreHelpers.readCachedViewIds(environment.getStore().getSource());
  var cellId = DecidrCellId.make(rowId, columnId);
  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrCellId.toString(cellId))), (function (cellToInsertRP) {
          Spaces__RelayStoreHelpers.getAllViewsToUpdate(store, allCachedViewIds, columnId, Caml_option.some(rowId)).forEach(function (viewId) {
                updateRowViewCellsArray(store, rowId, viewId, columnId, cellToInsertRP);
              });
          Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(store.getRoot(), "cell", {
                    rowId: rowId,
                    columnId: columnId
                  }), (function (cellRP) {
                  RelayUtils.RecordProxy.setLinkedRecord(cellRP, cellToInsertRP, "cell", undefined);
                }));
        }));
}

var convertVariables = SpacesCellsMutations_updateCellMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesCellsMutations_updateCellMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesCellsMutations_updateCellMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesCellsMutations_updateCellMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesCellsMutations_updateCellMutation_graphql.node, convertResponse, convertWrapRawResponse);

var convertVariables$1 = SpacesCellsMutations_deleteCellMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesCellsMutations_deleteCellMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesCellsMutations_deleteCellMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesCellsMutations_deleteCellMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesCellsMutations_deleteCellMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useUpdateCell(rowId, columnId, columnViewId) {
  var match = use();
  var updateCell = match[0];
  var match$1 = use$1();
  var deleteCell = match$1[0];
  var environment = RescriptRelay.useEnvironmentFromContext();
  var handleUpdateCell = function (value, onError, onCompleted) {
    var match = Core__JSON.Classify.classify(value);
    if (typeof match !== "object") {
      return deleteCell({
                  columnId: DecidrNodeId.magicallyFromInternalId(columnId),
                  rowId: DecidrNodeId.magicallyFromInternalId(rowId)
                }, undefined, undefined, (function (store, response) {
                    removeCellFromAllAffectedViews(environment, store, DecidrNodeId.magicallyToInternalId(response.deleteCell.deletedCellRowId), DecidrNodeId.magicallyToInternalId(response.deleteCell.deletedCellColumnId));
                    Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(store.getRoot(), "cell", {
                              rowId: rowId,
                              columnId: columnId
                            }), (function (cellRP) {
                            RelayUtils.RecordProxy.setLinkedRecordToNull(cellRP, "cell", undefined);
                          }));
                  }), (function (_response, errors) {
                    ErrorHandling__RelayMutationCompleted.handle(errors, "Error deleting cell", "Spaces__Cells__Mutation handleUpdateCell deleteCell", (function (param, param$1) {
                            onError("Couldn't delete value! Please try again.");
                          }), (function () {
                            Core__Option.forEach(onCompleted, (function (onCompleted) {
                                    onCompleted();
                                  }));
                          }), undefined);
                  }), (function (errors) {
                    ErrorHandling__RelayMutationError.handle(errors, "Error deleting cell", "Spaces__Cells__Mutation handleUpdateCell deleteCell", (function (param, param$1) {
                            onError("Couldn't delete value! Please try again.");
                          }));
                  }), undefined);
    } else {
      return updateCell({
                  columnId: DecidrNodeId.magicallyFromInternalId(columnId),
                  columnViewId: columnViewId,
                  rowId: DecidrNodeId.magicallyFromInternalId(rowId),
                  value: value
                }, undefined, undefined, (function (store, _response) {
                    updateOrAddCellToEveryAffectedView(environment, store, rowId, columnId);
                  }), (function (_response, errors) {
                    ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating value", "Spaces__Cells__Mutation useUpdateCell", (function (message, param) {
                            onError(message);
                          }), (function () {
                            Core__Option.forEach(onCompleted, (function (onCompleted) {
                                    onCompleted();
                                  }));
                          }), undefined);
                  }), (function (errors) {
                    ErrorHandling__RelayMutationError.handle(errors, "Error updating value", "Spaces__Cells__Mutation useUpdateCell", (function (message, param) {
                            onError(message);
                          }));
                  }), undefined);
    }
  };
  return [
          handleUpdateCell,
          match[1] || match$1[1]
        ];
}

function useDeleteCell(rowId, columnId) {
  var match = use$1();
  var deleteCell = match[0];
  var environment = RescriptRelay.useEnvironmentFromContext();
  var handleDeleteCell = function (onError, onCompleted) {
    deleteCell({
          columnId: DecidrNodeId.magicallyFromInternalId(columnId),
          rowId: DecidrNodeId.magicallyFromInternalId(rowId)
        }, undefined, undefined, (function (store, response) {
            removeCellFromAllAffectedViews(environment, store, DecidrNodeId.magicallyToInternalId(response.deleteCell.deletedCellRowId), DecidrNodeId.magicallyToInternalId(response.deleteCell.deletedCellColumnId));
            Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(store.getRoot(), "cell", {
                      rowId: rowId,
                      columnId: columnId
                    }), (function (cellRP) {
                    RelayUtils.RecordProxy.setLinkedRecordToNull(cellRP, "cell", undefined);
                  }));
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error deleting value", "Spaces__Cells__Mutation useDeleteCell", (function (message, param) {
                    onError(message);
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted();
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error deleting value", "Spaces__Cells__Mutation useDeleteCell", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleDeleteCell,
          match[1]
        ];
}

var convertVariables$2 = SpacesCellsMutations_updateFileCellMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesCellsMutations_updateFileCellMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesCellsMutations_updateFileCellMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesCellsMutations_updateFileCellMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesCellsMutations_updateFileCellMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function useUpdateFileCell(rowId, columnId, columnViewId) {
  var match = use$2();
  var updateFileCell = match[0];
  var environment = RescriptRelay.useEnvironmentFromContext();
  var handleUpdateFileCell = function (fileUrl, onCompleted, onError) {
    updateFileCell({
          columnId: DecidrNodeId.magicallyFromInternalId(columnId),
          columnViewId: columnViewId,
          fileUrl: fileUrl,
          rowId: DecidrNodeId.magicallyFromInternalId(rowId)
        }, undefined, undefined, (function (store, _response) {
            updateOrAddCellToEveryAffectedView(environment, store, rowId, columnId);
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating file value", "Spaces__Cells__Mutation useUpdateFileCell", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating file value", "Spaces__Cells__Mutation useUpdateFileCell", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleUpdateFileCell,
          match[1]
        ];
}

var convertVariables$3 = SpacesCellsMutations_updateImageCellMutation_graphql.Internal.convertVariables;

var convertResponse$3 = SpacesCellsMutations_updateImageCellMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$3 = SpacesCellsMutations_updateImageCellMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$3, SpacesCellsMutations_updateImageCellMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, SpacesCellsMutations_updateImageCellMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

function useUpdateImageCell(rowId, columnId, columnViewId) {
  var match = use$3();
  var updateImageCell = match[0];
  var environment = RescriptRelay.useEnvironmentFromContext();
  var handleUpdateImageCell = function (imageUrl, onCompleted, onError) {
    updateImageCell({
          columnId: DecidrNodeId.magicallyFromInternalId(columnId),
          columnViewId: columnViewId,
          imageUrl: imageUrl,
          rowId: DecidrNodeId.magicallyFromInternalId(rowId)
        }, undefined, undefined, (function (store, _response) {
            updateOrAddCellToEveryAffectedView(environment, store, rowId, columnId);
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating image value", "Spaces__Cells__Mutation useUpdateImageCell", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating image value", "Spaces__Cells__Mutation useUpdateImageCell", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleUpdateImageCell,
          match[1]
        ];
}

var convertVariables$4 = SpacesCellsMutations_updateMultiSelectCellMutation_graphql.Internal.convertVariables;

var convertResponse$4 = SpacesCellsMutations_updateMultiSelectCellMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$4 = SpacesCellsMutations_updateMultiSelectCellMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$4, SpacesCellsMutations_updateMultiSelectCellMutation_graphql.node, convertResponse$4, convertWrapRawResponse$4);

var use$4 = RescriptRelay_Mutation.useMutation(convertVariables$4, SpacesCellsMutations_updateMultiSelectCellMutation_graphql.node, convertResponse$4, convertWrapRawResponse$4);

function updateMultiSelectCellInCache(rowId, columnId, store, allCachedViewIds) {
  var cellId = DecidrCellId.make(rowId, columnId);
  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrCellId.toString(cellId))), (function (cellToInsertRP) {
          Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(store.getRoot(), "cell", {
                    rowId: rowId,
                    columnId: columnId
                  }), (function (cellRP) {
                  RelayUtils.RecordProxy.setLinkedRecord(cellRP, cellToInsertRP, "cell", undefined);
                  Spaces__RelayStoreHelpers.getAllViewsToUpdate(store, allCachedViewIds, columnId, undefined).forEach(function (viewId) {
                        updateRowViewCellsArray(store, rowId, viewId, columnId, cellToInsertRP);
                      });
                }));
        }));
}

function useUpdateMultiSelectCell() {
  var environment = RescriptRelay.useEnvironmentFromContext();
  var match = use$4();
  var updateMultiSelectCell = match[0];
  var handleUpdateMultiSelectCell = function (rowId, columnId, columnViewId, destinationViewId, addRowIds, removeSelectedRowInfoIds, onError, onCompleted) {
    updateMultiSelectCell({
          addRowIds: Core__Option.map(addRowIds, (function (__x) {
                  return __x.map(DecidrNodeId.magicallyFromInternalId);
                })),
          columnId: DecidrNodeId.magicallyFromInternalId(columnId),
          columnViewId: columnViewId,
          destinationViewId: destinationViewId,
          removeSelectedRowInfoIds: Core__Option.map(removeSelectedRowInfoIds, (function (__x) {
                  return __x.map(DecidrNodeId.magicallyFromInternalId);
                })),
          rowId: DecidrNodeId.magicallyFromInternalId(rowId)
        }, undefined, undefined, (function (store, response) {
            var allCachedViewIds = Spaces__RelayStoreHelpers.readCachedViewIds(environment.getStore().getSource());
            var match = response.updateMultiSelectCell.cell;
            if (match !== undefined) {
              var rowId$1 = DecidrNodeId.magicallyToInternalId(match.rowId);
              var columnId$1 = DecidrNodeId.magicallyToInternalId(match.columnId);
              updateMultiSelectCellInCache(rowId$1, columnId$1, store, allCachedViewIds);
            } else {
              Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(store.getRoot(), "cell", {
                        rowId: rowId,
                        columnId: columnId
                      }), (function (cellRP) {
                      RelayUtils.RecordProxy.setLinkedRecordToNull(cellRP, "cell", undefined);
                      Spaces__RelayStoreHelpers.getAllViewsToUpdate(store, allCachedViewIds, columnId, undefined).forEach(function (viewId) {
                            Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrRowCacheId.toString(DecidrRowCacheId.make(rowId, viewId)))), (function (rowRP) {
                                    Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecords(rowRP, "cells", undefined), (function (cellsRP) {
                                            RelayUtils.RecordProxy.setLinkedRecords(rowRP, cellsRP.filter(function (cellRP) {
                                                      var cellRowId = Core__Option.flatMap(cellRP, (function (__x) {
                                                              return RelayUtils.RecordProxy.getValueString(__x, "rowId", undefined);
                                                            }));
                                                      var cellColumnId = Core__Option.flatMap(cellRP, (function (__x) {
                                                              return RelayUtils.RecordProxy.getValueString(__x, "columnId", undefined);
                                                            }));
                                                      if (cellRowId !== undefined && cellColumnId !== undefined) {
                                                        return !(cellRowId === DecidrInternalId.toString(rowId) && cellColumnId === DecidrInternalId.toString(columnId));
                                                      } else {
                                                        return false;
                                                      }
                                                    }), "cells", undefined);
                                          }));
                                  }));
                          });
                    }));
            }
            var allCachedTableLinkInfosForColumn = Spaces__RelayStoreHelpers.readCachedTableLinkInfosByColumnId(environment.getStore().getSource(), columnId);
            allCachedTableLinkInfosForColumn.forEach(function (tableLinkInfoId) {
                  Spaces__RelayStoreHelpers.readCachedColumnsByColumnLinkInfoTableLinkId(environment.getStore().getSource(), tableLinkInfoId).forEach(function (lookupColumnId) {
                        Core__Option.forEach(RelayUtils.RecordProxy.getLinkedRecord(store.getRoot(), "cell", {
                                  rowId: rowId,
                                  columnId: lookupColumnId
                                }), RelayUtils.RecordProxy.invalidateRecord);
                      });
                });
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating link value", "Spaces__Cells__Mutation useUpdateMultiSelectCell", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted();
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating link value", "Spaces__Cells__Mutation useUpdateMultiSelectCell", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleUpdateMultiSelectCell,
          match[1]
        ];
}

function useLinkRows(rowId, columnId, columnViewId, destinationViewId) {
  var match = useUpdateMultiSelectCell();
  var updateMultiSelectCell = match[0];
  var handleLinkRows = function (rowIds, onError, onCompleted) {
    updateMultiSelectCell(rowId, columnId, columnViewId, destinationViewId, rowIds, undefined, onError, onCompleted);
  };
  return [
          handleLinkRows,
          match[1]
        ];
}

function useUnlinkRow() {
  var match = useUpdateMultiSelectCell();
  var updateMultiSelectCell = match[0];
  var handleLinkRows = function (rowId, columnId, columnViewId, selectedRowInfoId, onError, onCompleted) {
    updateMultiSelectCell(rowId, columnId, columnViewId, undefined, undefined, [selectedRowInfoId], onError, onCompleted);
  };
  return [
          handleLinkRows,
          match[1]
        ];
}

function useLinkSingleRow() {
  var match = useUpdateMultiSelectCell();
  var updateMultiSelectCell = match[0];
  var handleLinkSingleRow = function (rowId, columnId, columnViewId, addRowId, onError, onCompleted) {
    updateMultiSelectCell(rowId, columnId, columnViewId, undefined, [addRowId], undefined, onError, onCompleted);
  };
  return [
          handleLinkSingleRow,
          match[1]
        ];
}

var convertVariables$5 = SpacesCellsMutations_autoFillCellMutation_graphql.Internal.convertVariables;

var convertResponse$5 = SpacesCellsMutations_autoFillCellMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$5 = SpacesCellsMutations_autoFillCellMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$5, SpacesCellsMutations_autoFillCellMutation_graphql.node, convertResponse$5, convertWrapRawResponse$5);

var use$5 = RescriptRelay_Mutation.useMutation(convertVariables$5, SpacesCellsMutations_autoFillCellMutation_graphql.node, convertResponse$5, convertWrapRawResponse$5);

function useAutoFillCell() {
  var match = use$5();
  var autoFillCell = match[0];
  var handleAutoFillCell = function (rowId, columnId, columnViewId, onSuccess, onError) {
    autoFillCell({
          columnId: DecidrNodeId.magicallyFromInternalId(columnId),
          columnViewId: columnViewId,
          rowId: DecidrNodeId.magicallyFromInternalId(rowId)
        }, undefined, undefined, undefined, (function (response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error auto filling value", "Spaces__Cells__Mutation useAutoFillCell", (function (message, reason) {
                    onError(message, reason);
                  }), (function () {
                    if (response.autoFillCell.successfullyQueued) {
                      return onSuccess();
                    } else {
                      return onError("Can't auto fill value", response.autoFillCell.error);
                    }
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error auto filling value", "Spaces__Cells__Mutation useAutoFillCell", (function (message, reason) {
                    onError(message, reason);
                  }));
          }), undefined);
  };
  return [
          handleAutoFillCell,
          match[1]
        ];
}

export {
  useUpdateCell ,
  useDeleteCell ,
  useUpdateFileCell ,
  useUpdateImageCell ,
  useLinkRows ,
  useUpdateMultiSelectCell ,
  useUnlinkRow ,
  useLinkSingleRow ,
  useAutoFillCell ,
}
/*  Not a pure module */
