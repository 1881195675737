// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as AppAnalyticsTrackMutation_trackMutation_graphql from "../../../relay-codegen/generated/AppAnalyticsTrackMutation_trackMutation_graphql.bs.js";

var convertVariables = AppAnalyticsTrackMutation_trackMutation_graphql.Internal.convertVariables;

var convertResponse = AppAnalyticsTrackMutation_trackMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = AppAnalyticsTrackMutation_trackMutation_graphql.Internal.convertWrapRawResponse;

var commitMutation = RescriptRelay_Mutation.commitMutation(convertVariables, AppAnalyticsTrackMutation_trackMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, AppAnalyticsTrackMutation_trackMutation_graphql.node, convertResponse, convertWrapRawResponse);

var eventType_decode = AppAnalyticsTrackMutation_trackMutation_graphql.Utils.eventType_decode;

var eventType_fromString = AppAnalyticsTrackMutation_trackMutation_graphql.Utils.eventType_fromString;

var Operation;

var Types;

export {
  eventType_decode ,
  eventType_fromString ,
  Operation ,
  Types ,
  convertVariables ,
  convertResponse ,
  convertWrapRawResponse ,
  commitMutation ,
  use ,
}
/* commitMutation Not a pure module */
