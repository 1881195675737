// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as RescriptTamagui__Button from "../../../rescript-tamagui/src/button/RescriptTamagui__Button.bs.js";

var IconProps = {};

function Primitives__TextButton(props) {
  var __scaleOnHover = props.scaleOnHover;
  var __hideBreakpoints = props.hideBreakpoints;
  var __disabled = props.disabled;
  var __kind = props.kind;
  var onPress = props.onPress;
  var __specificStyles = props.specificStyles;
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var specificStyles = __specificStyles !== undefined ? __specificStyles : TamaguiConfig.Utils.emptySpecificStyles;
  var kind = __kind !== undefined ? __kind : "neutral";
  var disabled = __disabled !== undefined ? __disabled : false;
  var hideBreakpoints = __hideBreakpoints !== undefined ? __hideBreakpoints : [];
  var scaleOnHover = __scaleOnHover !== undefined ? __scaleOnHover : false;
  var newrecord = Caml_obj.obj_dup(style);
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle((newrecord.paddingRight = Caml_option.some(Primitives__Styling.dp(0)), newrecord.paddingLeft = Caml_option.some(Primitives__Styling.dp(0)), newrecord.justifyContent = "center", newrecord.gap = Primitives__Styling.Gap.toRNGap(Primitives__Styling.gap("cs_1")), newrecord.flexDirection = "row", newrecord.alignItems = "center", newrecord.borderWidth = 0, newrecord.borderRadius = Primitives__Styling.radius("rad_3"), newrecord.backgroundColor = Primitives__Styling.color("transparent"), newrecord), Caml_option.some(Primitives__Styling.makeSpecificStyles({
                    s: Primitives__Styling.withDisplayNone(specificStyles.s, hideBreakpoints.includes("s")),
                    m: Primitives__Styling.withDisplayNone(specificStyles.m, hideBreakpoints.includes("m")),
                    l: Primitives__Styling.withDisplayNone(specificStyles.l, hideBreakpoints.includes("l")),
                    xl: Primitives__Styling.withDisplayNone(specificStyles.xl, hideBreakpoints.includes("xl"))
                  })), undefined));
  var style$1 = stylesDict["common"];
  var foregroundColor = React.useMemo((function () {
          return Primitives__Styling.color(kind === "error" ? (
                        disabled ? ({
                              NAME: "action",
                              VAL: {
                                NAME: "error",
                                VAL: "disabled"
                              }
                            }) : ({
                              NAME: "action",
                              VAL: {
                                NAME: "error",
                                VAL: "default"
                              }
                            })
                      ) : (
                        kind === "primary" ? (
                            disabled ? ({
                                  NAME: "action",
                                  VAL: {
                                    NAME: "primary",
                                    VAL: "disabled"
                                  }
                                }) : ({
                                  NAME: "action",
                                  VAL: {
                                    NAME: "primary",
                                    VAL: "default"
                                  }
                                })
                          ) : (
                            disabled ? ({
                                  NAME: "action",
                                  VAL: {
                                    NAME: "neutral",
                                    VAL: "disabled"
                                  }
                                }) : ({
                                  NAME: "action",
                                  VAL: {
                                    NAME: "neutral",
                                    VAL: "default"
                                  }
                                })
                          )
                      ));
        }), [
        kind,
        disabled
      ]);
  return JsxRuntime.jsx(RescriptTamagui__Button.make, {
              space: 0,
              style: Core__Option.map(style$1, (function (__x) {
                      return Primitives__Styling.Utils.omitStyleKeys(__x, [
                                  "backgroundColor",
                                  "borderColor",
                                  "borderWidth"
                                ]);
                    })),
              hoverStyle: !disabled ? ({
                    transform: scaleOnHover ? [{
                          scale: 1.01
                        }] : undefined
                  }) : undefined,
              focusStyle: !disabled ? ({
                    backgroundColor: Primitives__Styling.color(kind === "error" ? "error_3" : (
                            kind === "primary" ? "primary_3" : "neutral_6"
                          ))
                  }) : undefined,
              pressStyle: !disabled ? ({
                    backgroundColor: Primitives__Styling.color(kind === "error" ? "error_3" : (
                            kind === "primary" ? "primary_3" : "neutral_6"
                          ))
                  }) : undefined,
              disabled: disabled,
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              children: Caml_option.some(ReactUtils__SimpleReact.renderIfSome(props.title, (function (title) {
                          return JsxRuntime.jsx(Primitives__DecidrText.make, {
                                      style: {
                                        fontWeight: 500
                                      },
                                      kind: "button",
                                      children: Caml_option.some(title),
                                      color: foregroundColor
                                    });
                        }))),
              onPress: (function (e) {
                  if (!disabled) {
                    return onPress(e);
                  }
                  
                }),
              icon: Core__Option.map(props.icon, (function (icon) {
                      return function (_props) {
                        return icon({
                                    size: "ic_1",
                                    color: foregroundColor
                                  });
                      };
                    })),
              iconAfter: Core__Option.map(props.iconAfter, (function (icon) {
                      return function (_props) {
                        return icon({
                                    size: "ic_1",
                                    color: foregroundColor
                                  });
                      };
                    })),
              unstyled: true,
              cur: "pointer",
              bc: Core__Option.flatMap(style$1, (function (s) {
                      return s.backgroundColor;
                    })),
              boc: Core__Option.getOr(Core__Option.flatMap(style$1, (function (s) {
                          return s.borderColor;
                        })), Primitives__Styling.color("transparent")),
              bw: Core__Option.getOr(Core__Option.flatMap(style$1, (function (s) {
                          return s.borderWidth;
                        })), 1),
              focusable: props.focusable
            });
}

var make = Primitives__TextButton;

export {
  IconProps ,
  make ,
}
/* react Not a pure module */
