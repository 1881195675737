// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as Primitives__Group from "../../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Input from "../../../../primitives/src/input/Primitives__Input.bs.js";
import * as Primitives__Modal from "../../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../../primitives/src/button/Primitives__Button.bs.js";
import * as Primitives__Select from "../../../../primitives/src/select/Primitives__Select.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__DecidrText from "../../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Spaces__View__Mutations from "../../view/Spaces__View__Mutations.bs.js";
import * as Primitives__Error__Message from "../../../../primitives/src/error/message/Primitives__Error__Message.bs.js";
import * as SpacesUiCreateViewModalViewTypesQuery_graphql from "../../../../relay-codegen/generated/SpacesUiCreateViewModalViewTypesQuery_graphql.bs.js";
import * as SpacesUiCreateViewModalViewTypeSelect_query_graphql from "../../../../relay-codegen/generated/SpacesUiCreateViewModalViewTypeSelect_query_graphql.bs.js";

var convertFragment = SpacesUiCreateViewModalViewTypeSelect_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesUiCreateViewModalViewTypeSelect_query_graphql.node, convertFragment, fRef);
}

function Spaces__Ui__CreateViewModal$ViewTypeSelect(props) {
  var onSelectViewType = props.onSelectViewType;
  var match = use(props.queryRef);
  var viewTypes = match.viewTypes;
  React.useEffect((function () {
          onSelectViewType(viewTypes.find(function (param) {
                    return param.isDefault;
                  }));
        }), []);
  return JsxRuntime.jsx(Primitives__Select.make, {
              title: "Choose View type",
              data: viewTypes,
              selectedItem: props.selectedViewType,
              renderItem: (function (param) {
                  var close = param.close;
                  var viewType = param.item;
                  return JsxRuntime.jsx(Primitives__Select.Item.make, {
                              selected: Core__Option.map(param.selectedItem, (function (selectedItem) {
                                      return selectedItem.id === viewType.id;
                                    })),
                              text: viewType.name,
                              onPress: (function () {
                                  close();
                                  onSelectViewType(viewType);
                                })
                            });
                }),
              keyExtractor: (function (param) {
                  return DecidrNodeId.toString(param.id);
                }),
              trigger: (function (selectedItem, show) {
                  var selectedItem$1 = Core__Option.flatMap(selectedItem, (function (selectedItem) {
                          return viewTypes.find(function (param) {
                                      return selectedItem.id === param.id;
                                    });
                        }));
                  return JsxRuntime.jsx(Primitives__Select.Trigger.make, {
                              text: selectedItem$1 !== undefined ? selectedItem$1.name : "Select",
                              onPress: (function (_e) {
                                  show();
                                }),
                              style: {
                                alignSelf: "flex-start"
                              }
                            });
                })
            });
}

var convertVariables = SpacesUiCreateViewModalViewTypesQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesUiCreateViewModalViewTypesQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesUiCreateViewModalViewTypesQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesUiCreateViewModalViewTypesQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiCreateViewModalViewTypesQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiCreateViewModalViewTypesQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesUiCreateViewModalViewTypesQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesUiCreateViewModalViewTypesQuery_graphql.node, convertVariables);

function Spaces__Ui__CreateViewModal$ConnectedViewTypeSelect(props) {
  var match = use$1(undefined, undefined, undefined, undefined);
  return JsxRuntime.jsx(React.Suspense, {
              children: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__CreateViewModal$ViewTypeSelect, {
                        queryRef: match.fragmentRefs,
                        onSelectViewType: props.onSelectViewType,
                        selectedViewType: props.selectedViewType
                      })),
              fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Select.TriggerLoading.make, {}))
            });
}

function Spaces__Ui__CreateViewModal$Content(props) {
  var __buttonTitle = props.buttonTitle;
  var __initialValue = props.initialValue;
  var onButtonPress = props.onButtonPress;
  var initialValue = __initialValue !== undefined ? __initialValue : "";
  var buttonTitle = __buttonTitle !== undefined ? __buttonTitle : "Create";
  var match = Primitives__Input.use(initialValue, undefined, undefined);
  var value = match.value;
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = React.useState(function () {
        
      });
  var setSelectedViewType = match$2[1];
  var selectedViewType = match$2[0];
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("ss_1")),
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                borderRadius: Primitives__Styling.radius("rad_4"),
                minWidth: Caml_option.some(Primitives__Styling.dp(250)),
                padding: Caml_option.some(Primitives__Styling.space("ss_2")),
                paddingTop: Caml_option.some(Primitives__Styling.space("ss_4"))
              },
              children: [
                JsxRuntime.jsx(Primitives__Input.make, {
                      value: value,
                      onChange: match.onChange,
                      label: props.label,
                      placeholder: props.placeholder
                    }),
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      children: [
                        JsxRuntime.jsx(Primitives__DecidrText.make, {
                              kind: "body2",
                              children: "Choose View type"
                            }),
                        JsxRuntime.jsx(Spaces__Ui__CreateViewModal$ConnectedViewTypeSelect, {
                              onSelectViewType: (function (viewType) {
                                  setSelectedViewType(function (param) {
                                        return viewType;
                                      });
                                }),
                              selectedViewType: selectedViewType
                            })
                      ]
                    }),
                JsxRuntime.jsx(Primitives__Error__Message.make, {
                      error: match$1[0]
                    }),
                JsxRuntime.jsx(Primitives__Button.make, {
                      title: buttonTitle,
                      onPress: (function (param) {
                          setError(function (param) {
                                
                              });
                          onButtonPress(value, selectedViewType, (function (message) {
                                  setError(function (param) {
                                        return message;
                                      });
                                }), (function () {
                                  setError(function (param) {
                                        
                                      });
                                }));
                        }),
                      disabled: value.trim() === "",
                      busy: props.loading
                    })
              ]
            });
}

function Spaces__Ui__CreateViewModal(props) {
  var onCompleted = props.onCompleted;
  var tableId = props.tableId;
  var match = Spaces__View__Mutations.useCreate();
  var createView = match[0];
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Spaces__Ui__CreateViewModal$Content, {
                    placeholder: "My View",
                    label: "New View name",
                    onButtonPress: (function (name, viewType, onError, onSuccess) {
                        createView(tableId, name, Core__Option.map(viewType, (function (param) {
                                    return DecidrNodeId.magicallyToInternalId(param.id);
                                  })), Core__Option.flatMap(viewType, (function (param) {
                                    if (param.code === "chatbot_prompt") {
                                      return Object.fromEntries([[
                                                    "chatbotPromptType_prompt",
                                                    "Insert your prompt"
                                                  ]]);
                                    }
                                    
                                  })), (function (viewId, viewName) {
                                onCompleted(viewId, viewName);
                                onSuccess();
                              }), onError);
                      }),
                    loading: match[1],
                    initialValue: "New View",
                    buttonTitle: "Create View"
                  }),
              show: props.show,
              onClose: props.onClose,
              maxWidth: 400
            });
}

var make = Spaces__Ui__CreateViewModal;

export {
  make ,
}
/* use Not a pure module */
