// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Ui__Shared from "../shared/Spaces__Ui__Shared.bs.js";
import * as DecidrErrorBoundary from "../../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Spinner from "../../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Primitives__Error__Message from "../../../../primitives/src/error/message/Primitives__Error__Message.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesUiSpaceTablePickerQuery_graphql from "../../../../relay-codegen/generated/SpacesUiSpaceTablePickerQuery_graphql.bs.js";
import * as SpacesUiSpaceTablePickerBase_query_graphql from "../../../../relay-codegen/generated/SpacesUiSpaceTablePickerBase_query_graphql.bs.js";

var convertFragment = SpacesUiSpaceTablePickerBase_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesUiSpaceTablePickerBase_query_graphql.node, convertFragment, fRef);
}

function Spaces__Ui__SpaceTablePicker$Base(props) {
  var onSelectTable = props.onSelectTable;
  var match = use(props.queryRef);
  var node = match.node;
  if (node !== undefined) {
    if (node.__typename === "Space") {
      return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListContainer.make, {
                  children: node.tables.map(function (param) {
                        var id = param.id;
                        return JsxRuntime.jsx(Spaces__Ui__Shared.SimpleListItem.make, {
                                    onPress: (function () {
                                        onSelectTable(id);
                                      }),
                                    title: param.name
                                  }, DecidrNodeId.toString(id));
                      })
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a Space", "Spaces__Ui__SpaceTablePicker Base", {
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Ui__SpaceTablePicker Base", undefined);
  return null;
}

var convertVariables = SpacesUiSpaceTablePickerQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesUiSpaceTablePickerQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesUiSpaceTablePickerQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesUiSpaceTablePickerQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiSpaceTablePickerQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiSpaceTablePickerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesUiSpaceTablePickerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesUiSpaceTablePickerQuery_graphql.node, convertVariables);

function Spaces__Ui__SpaceTablePicker(props) {
  var match = Auth__Hooks.useAuth();
  var match$1 = use$1({
        skipQuery: match.authStatus !== "authenticated",
        spaceId: props.spaceId
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "Spaces__Ui__SpaceTablePicker Base",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Ui__SpaceTablePicker$Base, {
                              queryRef: match$1.fragmentRefs,
                              onSelectTable: props.onSelectTable
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                              size: "small"
                            }))
                  }),
              FallbackComponent: (function (param) {
                  return JsxRuntime.jsx(Primitives__Error__Message.make, {
                              error: "Error loading tables"
                            });
                })
            });
}

var make = Spaces__Ui__SpaceTablePicker;

export {
  make ,
}
/* use Not a pure module */
