// Generated by ReScript, PLEASE EDIT WITH CARE


function Size_token(prim) {
  return prim;
}

function Size_dp(prim) {
  return prim;
}

var Size = {
  token: Size_token,
  dp: Size_dp
};

export {
  Size ,
}
/* No side effect */
