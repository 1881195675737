// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function encode(prim) {
  return Index.btoa(prim);
}

function decode(prim) {
  return Index.atob(prim);
}

export {
  encode ,
  decode ,
}
/* ./index Not a pure module */
