import { v4 as uuid } from "uuid";
import * as Network from "expo-network";

import { minuteInMs } from "@decidr/utils/src/time/Utils__Time.gen";

import { makeContext } from "./context/context";
import {
  getAnonymousId,
  setAnonymousId,
  getUserProperties,
  modifyOrAddEventsLocally,
  getAllEventsToSend,
  // setUserProperties,
  getLastBulkSendTimestamp,
  setLastBulkSendTimestamp,
} from "./storage/storage";
import { EventPayload, EventType } from "./types";

const getEventName = (event: EventType) => {
  switch (event.type) {
    case "PageView":
      return "Page View";
    case "Identify":
      return "Identify";
    case "ReadMessage":
      return "Read Message";
  }
};

export const makeEventData = async (event: EventType) => {
  let anonymousId = getAnonymousId();

  if (anonymousId == undefined) {
    const newAnonymousId = uuid();
    setAnonymousId(newAnonymousId);
    anonymousId = newAnonymousId;
  }

  const networkTypePromise = Network.getNetworkStateAsync();
  const ipAddressPromise = Network.getIpAddressAsync();

  const { networkType, ipAddress } = await Promise.all([
    networkTypePromise,
    ipAddressPromise,
  ]).then(([networkType, ipAddress]) => {
    return { networkType: networkType.type, ipAddress };
  });

  const userInfo = getUserProperties();
  if (userInfo?.userId == undefined) {
    // FIXME JB Add user info here
    // const session = await supabase.auth.getSession();
    // const userSession = session.data.session;
    // if (userSession) {
    //   setUserProperties({
    //     id: userSession.user.id,
    //     email: userSession.user.email,
    //     phone: userSession.user.phone,
    //   });
    // }
  }

  const eventPayload: EventPayload = {
    eventId: uuid(),
    name: getEventName(event),
    type: event.type,
    anonymousId: anonymousId,
    userId: userInfo?.id,
    timestamp: new Date().toISOString(),
    user: userInfo,
    properties: event.properties,
    context: makeContext({ networkType, ipAddress }),
  };
  return eventPayload;

  // try {
  //   let { data } = await supabase.from("basic_analytics").insert({
  //     id: eventPayload.messageId,
  //     name: eventPayload.name,
  //     type: eventPayload.type,
  //     anonymous_id: eventPayload.anonymousId,
  //     user_id: eventPayload.userId,
  //     timestamp: eventPayload.timestamp,
  //     user: eventPayload.user,
  //     properties: eventPayload.properties,
  //     context: eventPayload.context,
  //   });
  //   return data;
  // } catch (error) {
  //   console.log("error", error);
  //   return error;
  // }
};

export const sendEvent = async (event: EventType) => {
  const eventPayload = await makeEventData(event);
  console.log("eventPayload", eventPayload);
  try {
    // await sendEventData(eventPayload);
    return;
  } catch (error) {
    modifyOrAddEventsLocally([eventPayload]);
  }
};

export const sendAllEvents = async () => {
  const eventsToSend = getAllEventsToSend();

  try {
    await Promise.all(eventsToSend.map(() => {}));

    // const response = await sendEventData(eventsToSend);
    // if (response.success) {
    //   // clear all events
    //   removeEvents(eventsToSend.map(event => event.eventId));
    // }
  } catch (error) {
    // could not send events
  }
};

export const checkIfShouldSendEvents = () => {
  const lastBulkSendTimestamp = getLastBulkSendTimestamp();
  if (lastBulkSendTimestamp == undefined) {
    return true;
  }
  const now = Date.now();
  const timeSinceLastSend = now - lastBulkSendTimestamp;
  // 5 minutes
  if (timeSinceLastSend > 5 * minuteInMs) {
    return true;
  }
  return false;
};

export const checkAndSendEvents = async () => {
  const shouldSendEvents = checkIfShouldSendEvents();
  if (shouldSendEvents) {
    setLastBulkSendTimestamp();
    await sendAllEvents();
  }
};
