// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Blocks__Index from "@pageblocks/blocks/src/Blocks__Index.bs.js";
import * as Spaces__Row__Renderer__Blocks from "./Spaces__Row__Renderer__Blocks.bs.js";
import * as Spaces__Row__Renderer__LinkRowsModal from "./Spaces__Row__Renderer__LinkRowsModal.bs.js";
import * as Spaces__Row__Renderer__ViewNavigatorBlock from "./Spaces__Row__Renderer__ViewNavigatorBlock.bs.js";

function registerBlocks() {
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.$$Text.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.LongText.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.Json.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.$$Date.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.$$Number.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.MultiSelect.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.SystemLink.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.Switch.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.ReadOnlyDate.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.$$File.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.$$Image.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.RelatedList.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.Lookup.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.Rollup.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.Container.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__LinkRowsModal.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.SelectView.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__ViewNavigatorBlock.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.RowJsonData.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.RowMembership.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.RowMembershipHistory.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.HttpRequestTrigger.descriptor);
  Blocks__Index.registerBlock(Spaces__Row__Renderer__Blocks.ColumnCountInsights.descriptor);
}

export {
  registerBlocks ,
}
/* Spaces__Row__Renderer__Blocks Not a pure module */
