// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../../node-id/src/DecidrNodeId.bs.js";
import * as DecidrInternalId from "../../../../internal-id/src/DecidrInternalId.bs.js";
import * as Spaces__Row__Renderer__BlockContext from "./Spaces__Row__Renderer__BlockContext.bs.js";

function makeContextDataEntries(data, componentTypeCode) {
  return Core__Option.getOr(Core__Option.map(data, (function (data) {
                    switch (data.TAG) {
                      case "Text" :
                          var text = data._0;
                          if (componentTypeCode === "date") {
                            return Core__Option.getOr(Core__Option.map(text, (function (text) {
                                              return [[
                                                        Spaces__Row__Renderer__BlockContext.$$Date.Keys.date,
                                                        text
                                                      ]];
                                            })), []);
                          } else if (componentTypeCode === "type-select-view") {
                            return Core__Option.getOr(Core__Option.map(text, (function (text) {
                                              return [[
                                                        Spaces__Row__Renderer__BlockContext.SelectView.Keys.viewId,
                                                        text
                                                      ]];
                                            })), []);
                          } else {
                            return Core__Option.getOr(Core__Option.map(text, (function (text) {
                                              return [[
                                                        Spaces__Row__Renderer__BlockContext.$$Text.Keys.text,
                                                        text
                                                      ]];
                                            })), []);
                          }
                      case "ReadOnlyDate" :
                          return Core__Option.getOr(Core__Option.map(data._0, (function (date) {
                                            return [[
                                                      Spaces__Row__Renderer__BlockContext.ReadOnlyDate.Keys.date,
                                                      date
                                                    ]];
                                          })), []);
                      case "Number" :
                          return Core__Option.getOr(Core__Option.map(data._0, (function (number) {
                                            return [[
                                                      Spaces__Row__Renderer__BlockContext.$$Number.Keys.number,
                                                      number
                                                    ]];
                                          })), []);
                      case "Switch" :
                          return Core__Option.getOr(Core__Option.map(data._0, (function (switched) {
                                            return [[
                                                      Spaces__Row__Renderer__BlockContext.Switch.Keys.switched,
                                                      switched
                                                    ]];
                                          })), []);
                      case "Image" :
                          return Core__Option.getOr(Core__Option.map(data._0, (function (param) {
                                            var size = param.size;
                                            return [
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageId,
                                                      DecidrInternalId.toString(param.id)
                                                    ],
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageUrl,
                                                      param.url
                                                    ],
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageWidth,
                                                      size.width
                                                    ],
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$Image.Keys.imageHeight,
                                                      size.height
                                                    ]
                                                  ];
                                          })), []);
                      case "File" :
                          return Core__Option.getOr(Core__Option.map(data._0, (function (param) {
                                            return [
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$File.Keys.fileUrl,
                                                      param.url
                                                    ],
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$File.Keys.mimetype,
                                                      param.mimetype
                                                    ],
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.$$File.Keys.size,
                                                      param.size
                                                    ]
                                                  ];
                                          })), []);
                      case "MultiSelect" :
                          return [
                                  [
                                    Spaces__Row__Renderer__BlockContext.MultiSelect.Keys.sourceViewId,
                                    DecidrNodeId.toString(data.sourceViewId)
                                  ],
                                  [
                                    Spaces__Row__Renderer__BlockContext.MultiSelect.Keys.tableLinkInfoId,
                                    DecidrInternalId.toString(data.tableLinkInfoId)
                                  ]
                                ];
                      case "SystemLink" :
                          return Core__Option.getOr(Core__Option.map(data._0, (function (param) {
                                            return [
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.SystemLink.Keys.selectedRowId,
                                                      Core__Option.getOr(Core__Option.map(Core__Option.map(param.selectedRowId, DecidrInternalId.toString), (function (prim) {
                                                                  return prim;
                                                                })), null)
                                                    ],
                                                    [
                                                      Spaces__Row__Renderer__BlockContext.SystemLink.Keys.sourceViewId,
                                                      DecidrNodeId.toString(param.sourceViewId)
                                                    ]
                                                  ];
                                          })), []);
                      case "RelatedList" :
                          return [
                                  [
                                    Spaces__Row__Renderer__BlockContext.RelatedList.Keys.sourceViewId,
                                    DecidrNodeId.toString(data.sourceViewId)
                                  ],
                                  [
                                    Spaces__Row__Renderer__BlockContext.RelatedList.Keys.callerRowId,
                                    DecidrInternalId.toString(data.callerRowId)
                                  ],
                                  [
                                    Spaces__Row__Renderer__BlockContext.RelatedList.Keys.tableLinkInfoId,
                                    DecidrInternalId.toString(data.tableLinkInfoId)
                                  ]
                                ];
                      case "Lookup" :
                          return [
                                  [
                                    Spaces__Row__Renderer__BlockContext.Lookup.Keys.primitiveColumnViewId,
                                    DecidrNodeId.toString(data.primitiveColumnViewId)
                                  ],
                                  [
                                    Spaces__Row__Renderer__BlockContext.Lookup.Keys.referencedColumnViewId,
                                    DecidrNodeId.toString(data.referencedColumnViewId)
                                  ],
                                  [
                                    Spaces__Row__Renderer__BlockContext.Lookup.Keys.tableLinkInfoId,
                                    DecidrInternalId.toString(data.tableLinkInfoId)
                                  ]
                                ];
                      case "Rollup" :
                          return [[
                                    Spaces__Row__Renderer__BlockContext.Rollup.Keys.tableLinkInfoId,
                                    DecidrInternalId.toString(data.tableLinkInfoId)
                                  ]];
                      
                    }
                  })), []);
}

function makeContextCommonEntries(param) {
  return [
          [
            Spaces__Row__Renderer__BlockContext.Common.Keys.columnViewId,
            DecidrNodeId.toString(param.id)
          ],
          [
            Spaces__Row__Renderer__BlockContext.Common.Keys.name,
            param.name
          ],
          [
            Spaces__Row__Renderer__BlockContext.Common.Keys.columnId,
            DecidrInternalId.toString(param.columnId)
          ],
          [
            Spaces__Row__Renderer__BlockContext.Common.Keys.componentTypeCode,
            param.componentTypeCode
          ],
          [
            Spaces__Row__Renderer__BlockContext.Common.Keys.icon,
            Core__Option.getOr(Core__Option.map(param.iconUrl, (function (iconUrl) {
                        return iconUrl;
                      })), null)
          ]
        ];
}

var BlockContext;

export {
  BlockContext ,
  makeContextDataEntries ,
  makeContextCommonEntries ,
}
/* DecidrNodeId Not a pure module */
