// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml from "rescript/lib/es6/caml.js";
import * as Uuid from "uuid";
import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Belt_Id from "rescript/lib/es6/belt_Id.js";
import * as Belt_Set from "rescript/lib/es6/belt_Set.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";

function toString(x) {
  return x;
}

function fromString(id) {
  if (Uuid.validate(id)) {
    return id;
  }
  
}

function make(prim) {
  return Uuid.v4();
}

function parse(json) {
  var id = Core__JSON.Decode.string(json);
  if (id !== undefined) {
    return id;
  } else {
    return Js_exn.raiseError("Error parsing internal ID - couldn't decode string");
  }
}

function serialize(t) {
  return t;
}

var RelayAppSync = {
  parse: parse,
  serialize: serialize
};

var cmp = Caml.string_compare;

var Comparable = Belt_Id.MakeComparableU({
      cmp: cmp
    });

var toArray = Belt_Set.toArray;

function fromArray(__x) {
  return Belt_Set.fromArray(__x, Comparable);
}

var add = Belt_Set.add;

var empty = Belt_Set.fromArray([], Comparable);

var size = Belt_Set.size;

var has = Belt_Set.has;

var remove = Belt_Set.remove;

var eq = Belt_Set.eq;

var diff = Belt_Set.diff;

var empty$1 = "";

var Set_Comparable = {};

var $$Set = {
  Comparable: Set_Comparable,
  toArray: toArray,
  fromArray: fromArray,
  add: add,
  empty: empty,
  size: size,
  has: has,
  remove: remove,
  eq: eq,
  diff: diff
};

export {
  empty$1 as empty,
  toString ,
  fromString ,
  make ,
  RelayAppSync ,
  $$Set ,
}
/* Comparable Not a pure module */
