// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Popover from "../../../../primitives/src/popover/Primitives__Popover.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__Suspense from "../../../../primitives/src/suspense/Primitives__Suspense.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as RescriptTamagui__Icon__Settings from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Settings.bs.js";
import * as RescriptTamagui__Icon__Calculator from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__Calculator.bs.js";
import * as RescriptTamagui__Icon__FileSearch from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__FileSearch.bs.js";
import * as SpacesUiBlockMenuItemsComponentTypesQuery_graphql from "../../../../relay-codegen/generated/SpacesUiBlockMenuItemsComponentTypesQuery_graphql.bs.js";
import * as SpacesUiBlockMenuItemsViewSettingsMenu_view_graphql from "../../../../relay-codegen/generated/SpacesUiBlockMenuItemsViewSettingsMenu_view_graphql.bs.js";
import * as SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql from "../../../../relay-codegen/generated/SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.bs.js";

var convertVariables = SpacesUiBlockMenuItemsComponentTypesQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesUiBlockMenuItemsComponentTypesQuery_graphql.Internal.convertResponse;

var use = RescriptRelay_Query.useQuery(convertVariables, SpacesUiBlockMenuItemsComponentTypesQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesUiBlockMenuItemsComponentTypesQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiBlockMenuItemsComponentTypesQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiBlockMenuItemsComponentTypesQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesUiBlockMenuItemsComponentTypesQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesUiBlockMenuItemsComponentTypesQuery_graphql.node, convertVariables);

function Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$LookupItem(props) {
  return JsxRuntime.jsx(Primitives__Popover.Item.make, {
              color: props.color,
              text: "Add Lookup",
              icon: RescriptTamagui__Icon__FileSearch.make,
              onPress: props.onPress
            });
}

function Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$RollupItem(props) {
  return JsxRuntime.jsx(Primitives__Popover.Item.make, {
              color: props.color,
              text: "Add Rollup",
              icon: RescriptTamagui__Icon__Calculator.make,
              onPress: props.onPress
            });
}

function Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$Loading(props) {
  return JsxRuntime.jsxs(JsxRuntime.Fragment, {
              children: [
                JsxRuntime.jsx(Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$LookupItem, {
                      onPress: (function () {
                          
                        }),
                      color: Primitives__Styling.color("neutral_4")
                    }),
                JsxRuntime.jsx(Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$RollupItem, {
                      onPress: (function () {
                          
                        }),
                      color: Primitives__Styling.color("neutral_4")
                    })
              ]
            });
}

var Loading = {
  make: Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$Loading
};

function Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock(props) {
  var onAddRollup = props.onAddRollup;
  var onAddLookup = props.onAddLookup;
  var sourceViewId = props.sourceViewId;
  var tableLinkInfoId = props.tableLinkInfoId;
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use({
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  var componentTypes = match$1.componentTypes;
  var lookupComponentType = Core__Option.getOr(componentTypes, []).find(function (param) {
        return param.code === "lookup";
      });
  var rollupComponentType = Core__Option.getOr(componentTypes, []).find(function (param) {
        return param.code === "rollup-new";
      });
  var lookupMenuItem;
  if (tableLinkInfoId !== undefined && lookupComponentType !== undefined) {
    var id = lookupComponentType.id;
    var tableLinkInfoId$1 = Caml_option.valFromOption(tableLinkInfoId);
    lookupMenuItem = Caml_option.some(JsxRuntime.jsx(Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$LookupItem, {
              onPress: (function () {
                  onAddLookup(tableLinkInfoId$1, id);
                })
            }));
  } else {
    lookupMenuItem = undefined;
  }
  var rollupMenuItem;
  if (tableLinkInfoId !== undefined && sourceViewId !== undefined && rollupComponentType !== undefined) {
    var id$1 = rollupComponentType.id;
    var sourceViewId$1 = Caml_option.valFromOption(sourceViewId);
    var tableLinkInfoId$2 = Caml_option.valFromOption(tableLinkInfoId);
    rollupMenuItem = Caml_option.some(JsxRuntime.jsx(Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock$RollupItem, {
              onPress: (function () {
                  onAddRollup(tableLinkInfoId$2, sourceViewId$1, id$1);
                })
            }));
  } else {
    rollupMenuItem = undefined;
  }
  if (lookupMenuItem === undefined) {
    if (rollupMenuItem !== undefined) {
      return Caml_option.valFromOption(rollupMenuItem);
    } else {
      return null;
    }
  }
  var lookupMenuItem$1 = Caml_option.valFromOption(lookupMenuItem);
  if (rollupMenuItem !== undefined) {
    return JsxRuntime.jsxs(JsxRuntime.Fragment, {
                children: [
                  lookupMenuItem$1,
                  Caml_option.valFromOption(rollupMenuItem)
                ]
              });
  } else {
    return lookupMenuItem$1;
  }
}

function Spaces__Ui__BlockMenuItems$ConnectedAddSpecificBlocks$Loading(props) {
  return props.blocks.map(function (param, index) {
              var title = param.title;
              return JsxRuntime.jsx(Primitives__Popover.Item.make, {
                          color: Primitives__Styling.color("neutral_4"),
                          text: title,
                          icon: param.icon,
                          onPress: (function () {
                              
                            })
                        }, title + "-" + index.toString());
            });
}

var Loading$1 = {
  make: Spaces__Ui__BlockMenuItems$ConnectedAddSpecificBlocks$Loading
};

function Spaces__Ui__BlockMenuItems$ConnectedAddSpecificBlocks(props) {
  var onAdd = props.onAdd;
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use({
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  var componentTypes = match$1.componentTypes;
  var availableBlocks = Core__Array.filterMap(props.blocks, (function (param) {
          var componentTypeCode = param.componentTypeCode;
          var componentType = Core__Option.getOr(componentTypes, []).find(function (param) {
                return param.code === componentTypeCode;
              });
          if (componentType !== undefined) {
            return {
                    title: param.title,
                    icon: param.icon,
                    componentTypeId: componentType.id,
                    componentTypeCode: componentTypeCode
                  };
          }
          
        }));
  return availableBlocks.map(function (param, index) {
              var componentTypeCode = param.componentTypeCode;
              var componentTypeId = param.componentTypeId;
              var title = param.title;
              return JsxRuntime.jsx(Primitives__Popover.Item.make, {
                          text: title,
                          icon: param.icon,
                          onPress: (function () {
                              onAdd(componentTypeId, componentTypeCode);
                            })
                        }, title + "-" + index.toString());
            });
}

var ConnectedAddSpecificBlocks = {
  Loading: Loading$1,
  make: Spaces__Ui__BlockMenuItems$ConnectedAddSpecificBlocks
};

var developerSettingsCanAccessSettingsKey = "developer_settings_can_access_settings";

function getCanAccessSettings(coreConfig) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[developerSettingsCanAccessSettingsKey];
                      })), Core__JSON.Decode.bool), true);
}

var convertFragment = SpacesUiBlockMenuItemsViewSettingsMenu_view_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesUiBlockMenuItemsViewSettingsMenu_view_graphql.node, convertFragment, fRef);
}

function Spaces__Ui__BlockMenuItems$ConnectedSourceViewSettingsMenuItem$ViewSettingsMenuItem(props) {
  var onSettingsPress = props.onSettingsPress;
  var sourceView = use$1(props.sourceViewRef);
  var canAccessSettings = getCanAccessSettings(sourceView.coreConfig);
  if (canAccessSettings) {
    return JsxRuntime.jsx(Primitives__Popover.Item.make, {
                text: "Linked View settings",
                icon: RescriptTamagui__Icon__Settings.make,
                onPress: (function () {
                    onSettingsPress();
                  })
              });
  } else {
    return null;
  }
}

var convertVariables$1 = SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.Internal.convertResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesUiBlockMenuItemsConnectedSourceViewSettingsMenuItemQuery_graphql.node, convertVariables$1);

function Spaces__Ui__BlockMenuItems$ConnectedSourceViewSettingsMenuItem$Connected(props) {
  var sourceViewId = props.sourceViewId;
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$2({
        skipQuery: !isAuthenticated,
        sourceViewId: sourceViewId
      }, undefined, undefined, undefined);
  var node = match$1.node;
  if (node !== undefined) {
    if (node.__typename === "View") {
      return JsxRuntime.jsx(Spaces__Ui__BlockMenuItems$ConnectedSourceViewSettingsMenuItem$ViewSettingsMenuItem, {
                  sourceViewRef: node.fragmentRefs,
                  onSettingsPress: props.onSettingsPress
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a View", "App__Spaces__Block__Common ConnectedSourceViewSettingsMenuItem", {
          sourceViewId: sourceViewId,
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "App__Spaces__Block__Common ConnectedSourceViewSettingsMenuItem", {
        sourceViewId: sourceViewId
      });
  return null;
}

function Spaces__Ui__BlockMenuItems$ConnectedSourceViewSettingsMenuItem(props) {
  var onSettingsPress = props.onSettingsPress;
  var sourceViewId = props.sourceViewId;
  if (sourceViewId !== undefined && onSettingsPress !== undefined) {
    return JsxRuntime.jsx(Primitives__Suspense.make, {
                fallback: Caml_option.some(null),
                children: JsxRuntime.jsx(Spaces__Ui__BlockMenuItems$ConnectedSourceViewSettingsMenuItem$Connected, {
                      sourceViewId: Caml_option.valFromOption(sourceViewId),
                      onSettingsPress: onSettingsPress
                    })
              });
  } else {
    return null;
  }
}

var ConnectedAddLookupAndRollupBlock = {
  Loading: Loading,
  make: Spaces__Ui__BlockMenuItems$ConnectedAddLookupAndRollupBlock
};

var ConnectedSourceViewSettingsMenuItem = {
  developerSettingsCanAccessSettingsKey: developerSettingsCanAccessSettingsKey,
  canAccessSettingsDefault: true,
  make: Spaces__Ui__BlockMenuItems$ConnectedSourceViewSettingsMenuItem
};

export {
  ConnectedAddLookupAndRollupBlock ,
  ConnectedAddSpecificBlocks ,
  ConnectedSourceViewSettingsMenuItem ,
}
/* use Not a pure module */
