// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../../../primitives/src/modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as DecidrErrorBoundary from "../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";
import * as Primitives__ScrollView from "../../../primitives/src/scrollView/Primitives__ScrollView.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Spaces__Cells__Mutations from "../cells/Spaces__Cells__Mutations.bs.js";
import * as Primitives__Error__Message from "../../../primitives/src/error/message/Primitives__Error__Message.bs.js";
import * as Spaces__Ui__ViewPickerFlow from "../ui/viewPickerFlow/Spaces__Ui__ViewPickerFlow.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as SpacesBlockSelectViewViewDisplay_query_graphql from "../../../relay-codegen/generated/SpacesBlockSelectViewViewDisplay_query_graphql.bs.js";
import * as SpacesBlockSelectViewConnectedViewDisplayQuery_graphql from "../../../relay-codegen/generated/SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.bs.js";
import * as SpacesBlockSelectViewOrganizationPlatform_query_graphql from "../../../relay-codegen/generated/SpacesBlockSelectViewOrganizationPlatform_query_graphql.bs.js";
import * as SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql from "../../../relay-codegen/generated/SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.bs.js";

var convertFragment = SpacesBlockSelectViewOrganizationPlatform_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockSelectViewOrganizationPlatform_query_graphql.node, convertFragment, fRef);
}

function Spaces__Block__SelectView$OrganizationPlatform(props) {
  var match = use(props.queryRef);
  var node = match.node;
  if (node !== undefined) {
    if (node.__typename === "Organization") {
      var match$1 = node.platforms[0];
      if (match$1 !== undefined) {
        return JsxRuntime.jsx(Spaces__Ui__ViewPickerFlow.make, {
                    onSelectView: props.onSelectView,
                    initialFlow: {
                      TAG: "SelectSpace",
                      platformId: match$1.id
                    },
                    includeSpaceBackButton: false,
                    headingPaddingHorizontal: Caml_option.some(Primitives__Styling.space("cs_3"))
                  });
      } else {
        return JsxRuntime.jsx(Primitives__DecidrText.make, {
                    children: "This organization has no platform"
                  });
      }
    }
    ErrorHandling__UnexpectedError.handle("Not a Organization", "Spaces__Block__SelectView OrganizationPlatform", {
          typename: node._0
        });
    return JsxRuntime.jsx(Primitives__DecidrText.make, {
                children: "This organization doesn't exist"
              });
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Block__SelectView OrganizationPlatform", undefined);
  return JsxRuntime.jsx(Primitives__DecidrText.make, {
              children: "This organization doesn't exist"
            });
}

var convertVariables = SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockSelectViewConnectedOrganizationPlatformQuery_graphql.node, convertVariables);

function Spaces__Block__SelectView$ConnectedOrganizationPlatform(props) {
  var match = Auth__Hooks.useAuth();
  var match$1 = use$1({
        organizationId: props.organizationId,
        skipQuery: match.authStatus !== "authenticated"
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "Spaces__Block__SelectView OrganizationPlatform",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__SelectView$OrganizationPlatform, {
                              queryRef: match$1.fragmentRefs,
                              onSelectView: props.onSelectView
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                              size: "small"
                            }))
                  }),
              FallbackComponent: (function (param) {
                  return JsxRuntime.jsx(Primitives__Error__Message.make, {
                              error: "Error loading organization platform"
                            });
                })
            });
}

function Spaces__Block__SelectView$SelectViewModal(props) {
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsx(Primitives__Modal.LongContentWebFix.make, {
                    children: JsxRuntime.jsx(Primitives__ScrollView.make, {
                          children: Caml_option.some(JsxRuntime.jsx(Primitives__Group.make, {
                                    style: {
                                      paddingTop: Caml_option.some(Primitives__Styling.space("ss_3"))
                                    },
                                    children: Caml_option.some(JsxRuntime.jsx(Spaces__Block__SelectView$ConnectedOrganizationPlatform, {
                                              organizationId: props.organizationId,
                                              onSelectView: props.onSelectView
                                            }))
                                  }))
                        })
                  }),
              show: props.show,
              onClose: props.onClose,
              maxWidth: 600
            });
}

var convertFragment$1 = SpacesBlockSelectViewViewDisplay_query_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockSelectViewViewDisplay_query_graphql.node, convertFragment$1, fRef);
}

function Spaces__Block__SelectView$ViewDisplay(props) {
  var selecting = props.selecting;
  var onSelectView = props.onSelectView;
  var match = use$2(props.queryRef);
  var node = match.node;
  if (node !== undefined) {
    if (node.__typename === "View") {
      return JsxRuntime.jsxs(Primitives__Group.make, {
                  gap: Caml_option.some(Primitives__Styling.gap("cs_3")),
                  orientation: "horizontal",
                  style: {
                    alignItems: "center"
                  },
                  children: [
                    JsxRuntime.jsx(Primitives__DecidrText.make, {
                          style: {
                            fontWeight: 500
                          },
                          children: Caml_option.some(node.name)
                        }),
                    JsxRuntime.jsx(Primitives__Button.make, {
                          title: "Change",
                          onPress: (function (param) {
                              onSelectView();
                            }),
                          kind: "secondary",
                          size: "small",
                          busy: selecting
                        })
                  ]
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a View", "Spaces__Block__SelectView ViewDisplay", {
          typename: node._0
        });
    return JsxRuntime.jsx(Primitives__Button.make, {
                title: "Select View",
                onPress: (function (param) {
                    onSelectView();
                  }),
                kind: "secondary",
                size: "small",
                busy: selecting
              });
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__Block__SelectView ViewDisplay", undefined);
  return JsxRuntime.jsx(Primitives__Button.make, {
              title: "Select View",
              onPress: (function (param) {
                  onSelectView();
                }),
              kind: "secondary",
              size: "small",
              busy: selecting
            });
}

var convertVariables$1 = SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.Internal.convertResponse;

var use$3 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesBlockSelectViewConnectedViewDisplayQuery_graphql.node, convertVariables$1);

function Spaces__Block__SelectView$ConnectedViewDisplay(props) {
  var selecting = props.selecting;
  var onSelectView = props.onSelectView;
  var viewId = props.viewId;
  var match = Auth__Hooks.useAuth();
  var match$1 = use$3({
        skipQuery: match.authStatus !== "authenticated" || Core__Option.isNone(viewId),
        viewId: Core__Option.getOr(viewId, DecidrNodeId.empty)
      }, undefined, undefined, undefined);
  if (viewId !== undefined) {
    return JsxRuntime.jsx(Spaces__Block__SelectView$ViewDisplay, {
                queryRef: match$1.fragmentRefs,
                onSelectView: onSelectView,
                selecting: selecting
              });
  } else {
    return JsxRuntime.jsx(Primitives__Button.make, {
                title: "Select View",
                onPress: (function (param) {
                    onSelectView();
                  }),
                kind: "secondary",
                size: "small",
                busy: selecting
              });
  }
}

function Spaces__Block__SelectView(props) {
  var match = React.useState(function () {
        return false;
      });
  var setShow = match[1];
  var match$1 = React.useState(function () {
        
      });
  var setError = match$1[1];
  var match$2 = Spaces__Cells__Mutations.useUpdateCell(props.rowId, props.columnId, props.columnViewId);
  var updateCell = match$2[0];
  return JsxRuntime.jsxs(Primitives__Group.make, {
              gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
              children: [
                JsxRuntime.jsxs(Primitives__Group.make, {
                      gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                      orientation: "horizontal",
                      children: [
                        JsxRuntime.jsx(Spaces__Block__SelectView$ConnectedViewDisplay, {
                              viewId: props.viewId,
                              onSelectView: (function () {
                                  setShow(function (param) {
                                        return true;
                                      });
                                }),
                              selecting: match$2[1]
                            }),
                        JsxRuntime.jsx(Spaces__Block__SelectView$SelectViewModal, {
                              show: match[0],
                              onClose: (function () {
                                  setShow(function (param) {
                                        return false;
                                      });
                                }),
                              onSelectView: (function (selectedViewId, param) {
                                  setShow(function (param) {
                                        return false;
                                      });
                                  updateCell(DecidrNodeId.toString(selectedViewId), (function (message) {
                                          setError(function (param) {
                                                return message;
                                              });
                                        }), undefined);
                                }),
                              organizationId: props.organizationId
                            })
                      ]
                    }),
                JsxRuntime.jsx(Primitives__Error__Message.make, {
                      error: match$1[0]
                    })
              ]
            });
}

var make = Spaces__Block__SelectView;

export {
  make ,
}
/* use Not a pure module */
