// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Int from "@rescript/core/src/Core__Int.bs.js";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Renderer__Context from "@pageblocks/renderer/src/Renderer__Context.bs.js";
import * as Renderer__Version from "@pageblocks/renderer/src/Renderer__Version.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Button from "../../../primitives/src/button/Primitives__Button.bs.js";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Renderer__SettingsJSON from "@pageblocks/renderer/src/Renderer__SettingsJSON.bs.js";
import * as Primitives__DecidrToast from "../../../primitives/src/toast/Primitives__DecidrToast.bs.js";
import * as DecidrRenderer__Renderer from "../renderer/DecidrRenderer__Renderer.bs.js";
import * as DecidrRenderer__Actions__Row from "../actions/DecidrRenderer__Actions__Row.bs.js";
import * as DecidrRenderer__Blocks__Utils from "./DecidrRenderer__Blocks__Utils.bs.js";
import * as DecidrRenderer__Blocks__SurveyAndChatUtils from "./utils/DecidrRenderer__Blocks__SurveyAndChatUtils.bs.js";

var blockCode = "survey-with-ai-questions";

var viewId = "viewId";

var organizationId = "organizationId";

var aiName = "aiName";

var aiImage = "aiImage";

var userName = "userName";

var userImage = "userImage";

var showSideContent = "showSideContent";

var prompt = "prompt";

var assistantId = "assistantId";

var starterMessage = "starterMessage";

var pages = "pages";

var columnView = "columnView";

var foregroundColor = "foregroundColor";

var assistantMessageBlock = "assistantMessageBlock";

var userMessageBlock = "userMessageBlock";

var sideContentSource = "sideContentSource";

var sideContentPosition = "sideContentPosition";

var sideContentType = "sideContentType";

var SettingKeys = {
  viewId: viewId,
  organizationId: organizationId,
  aiName: aiName,
  aiImage: aiImage,
  userName: userName,
  userImage: userImage,
  showSideContent: showSideContent,
  prompt: prompt,
  assistantId: assistantId,
  starterMessage: starterMessage,
  pages: pages,
  columnView: columnView,
  foregroundColor: foregroundColor,
  assistantMessageBlock: assistantMessageBlock,
  userMessageBlock: userMessageBlock,
  sideContentSource: sideContentSource,
  sideContentPosition: sideContentPosition,
  sideContentType: sideContentType
};

function getMessageAnswer(answer, choices) {
  if (choices === undefined) {
    return answer;
  }
  var options = choices.options;
  if (options.some(function (option) {
          return Core__Option.isSome(option.rowId);
        })) {
    var rowIds = answer.split(DecidrRenderer__Blocks__SurveyAndChatUtils.ChoicesInput.answerSeparator);
    var answers = Core__Array.filterMap(rowIds, (function (rowId) {
            return Core__Option.map(options.find(function (option) {
                            return Core__Option.getOr(Core__Option.map(option.rowId, (function (optionRowId) {
                                              return optionRowId === rowId;
                                            })), false);
                          }), (function (option) {
                          return option.value;
                        }));
          }));
    return answers.join(", ");
  }
  var answers$1 = answer.split(DecidrRenderer__Blocks__SurveyAndChatUtils.ChoicesInput.answerSeparator);
  return answers$1.join(", ");
}

function parse(question, context) {
  var sideContentSource$1 = DecidrRenderer__Blocks__Utils.getString(Renderer__Settings.deserialize(Renderer__SettingsJSON.fromJson(question)), context, sideContentSource);
  var sideContentType$1 = Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap((function (__x) {
                    return __x[sideContentType];
                  })(question), Core__JSON.Decode.string), (function (x) {
              switch (x) {
                case "iframe" :
                    return "iframe";
                case "image" :
                    return "image";
                default:
                  return ;
              }
            })), "image");
  var placeholder = Core__Option.flatMap((function (__x) {
            return __x["placeholder"];
          })(question), Core__JSON.Decode.string);
  var columnView$1 = Core__Option.flatMap(DecidrRenderer__Blocks__Utils.getObject(Renderer__Settings.deserialize(Renderer__SettingsJSON.fromJson(question)), context, columnView), DecidrRenderer__Blocks__Utils.parseColumnView);
  var choices = Core__Option.flatMap(Core__Option.flatMap((function (__x) {
                return __x[DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.choices];
              })(question), Core__JSON.Decode.object), (function (__x) {
          return DecidrRenderer__Blocks__SurveyAndChatUtils.Choices.parse(__x, context);
        }));
  if (columnView$1 !== undefined) {
    return {
            sideContentSource: sideContentSource$1,
            sideContentType: sideContentType$1,
            placeholder: placeholder,
            choices: choices,
            columnView: columnView$1
          };
  }
  
}

var Page = {
  parse: parse
};

var Answer = {};

function DecidrRenderer__Blocks__SurveyWithAIQuestions$Content(props) {
  var sideContentPosition = props.sideContentPosition;
  var showSideContent = props.showSideContent;
  var pages = props.pages;
  var assistantMessageBlockNode = props.assistantMessageBlockNode;
  var userMessageBlockNode = props.userMessageBlockNode;
  var userImage = props.userImage;
  var userName = props.userName;
  var aiImage = props.aiImage;
  var aiName = props.aiName;
  var context = props.context;
  var organizationId = props.organizationId;
  var viewId = props.viewId;
  var toast = Primitives__DecidrToast.use();
  var match = DecidrRenderer__Blocks__SurveyAndChatUtils.Hooks.useScrollToBottom();
  var columnViews = pages.map(function (param, index) {
        var columnView = param.columnView;
        return {
                id: index.toString(),
                name: columnView.name,
                columnId: columnView.columnId,
                componentTypeCode: columnView.componentTypeCode,
                rows: columnView.rows
              };
      });
  var match$1 = React.useState(function () {
        return "";
      });
  var setInput = match$1[1];
  var match$2 = DecidrRenderer__Blocks__SurveyAndChatUtils.Hooks.useMessages({
        starterMessage: props.starterMessage,
        columnViews: columnViews,
        scrollToBottom: match.scrollToBottom,
        prompt: props.prompt,
        resetInput: (function () {
            setInput(function (param) {
                  return "";
                });
          }),
        assistantId: props.assistantId,
        viewId: viewId,
        organizationId: organizationId,
        submitFlow: "question"
      });
  var handleSubmitMessage = match$2.handleSubmit;
  var match$3 = match$2.state;
  var questioningColumnViewIndex = match$3.columnViewId;
  var messages = match$3.messages;
  var messageState = match$3.state;
  var match$4 = React.useState(function () {
        return {
                answers: pages.map(function (page) {
                      return {
                              page: page,
                              answer: ""
                            };
                    }),
                submitting: false,
                submitted: false
              };
      });
  var setState = match$4[1];
  var state = match$4[0];
  var currentAnswer = state.answers[Core__Option.getOr(Core__Option.flatMap(questioningColumnViewIndex, (function (x) {
                  return Core__Int.fromString(undefined, x);
                })), 0)];
  var sideContentSource = Core__Option.flatMap(currentAnswer, (function (param) {
          return param.page.sideContentSource;
        }));
  var sideContentType = Core__Option.getOr(Core__Option.map(currentAnswer, (function (param) {
              return param.page.sideContentType;
            })), "image");
  var showSideContent$1 = showSideContent !== undefined ? (
      showSideContent ? true : false
    ) : sideContentSource !== undefined;
  var handleFieldSubmitEditing = function (viewId, currentPageIndex, messageAnswer, answers, submitting, nextOrSubmitDisabled) {
    if ((currentPageIndex + 1 | 0) < pages.length) {
      if (!nextOrSubmitDisabled) {
        handleSubmitMessage(messageAnswer);
        return ;
      } else {
        return ;
      }
    } else if (submitting) {
      return ;
    } else {
      handleSubmitMessage(messageAnswer);
      if (viewId === undefined) {
        return toast.error("Missing required configuration for this action", undefined);
      }
      if (organizationId === undefined) {
        return toast.error("Missing required configuration for this action", undefined);
      }
      if (nextOrSubmitDisabled) {
        return ;
      }
      setState(function (state) {
            return {
                    answers: state.answers,
                    submitting: true,
                    submitted: state.submitted
                  };
          });
      var answerColumnViews = answers.map(function (param) {
            var answer = param.answer;
            var page = param.page;
            var match = page.choices;
            var answer$1 = match !== undefined && match.multiple ? answer.split(DecidrRenderer__Blocks__SurveyAndChatUtils.ChoicesInput.answerSeparator).join(",") : answer;
            return [
                    answer$1,
                    page.columnView
                  ];
          });
      DecidrRenderer__Actions__Row.createRow({
            cells: answerColumnViews.map(function (param) {
                  var columnView = param[1];
                  return {
                          columnId: columnView.columnId,
                          columnViewId: columnView.id,
                          value: param[0],
                          componentTypeCode: columnView.componentTypeCode
                        };
                }),
            viewId: viewId
          }, organizationId, (function () {
              setState(function (param) {
                    return {
                            answers: pages.map(function (page) {
                                  return {
                                          page: page,
                                          answer: ""
                                        };
                                }),
                            submitting: false,
                            submitted: true
                          };
                  });
            }), (function () {
              setState(function (state) {
                    return {
                            answers: state.answers,
                            submitting: false,
                            submitted: state.submitted
                          };
                  });
            }), (function () {
              setState(function (state) {
                    return {
                            answers: state.answers,
                            submitting: false,
                            submitted: state.submitted
                          };
                  });
            }));
      return ;
    }
  };
  var tmp;
  if (currentAnswer !== undefined) {
    var answer = currentAnswer.answer;
    var match$5 = currentAnswer.page;
    var choices = match$5.choices;
    var nextOrSubmitDisabled = answer.trim() === "";
    var lastMessageClientId = Core__Option.flatMap(messages[messages.length - 1 | 0], (function (message) {
            return message.clientId;
          }));
    var match$6 = Core__Option.flatMap(questioningColumnViewIndex, (function (x) {
            return Core__Int.fromString(undefined, x);
          }));
    tmp = messageState === "Ready" && !(lastMessageClientId !== undefined || match$6 === undefined) ? JsxRuntime.jsxs(JsxRuntime.Fragment, {
            children: [
              JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils.MultipleChoicesNotice.make, {
                    choices: choices
                  }),
              JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyAndChatUtils.AnswerInput.make, {
                    choices: choices,
                    value: match$1[0],
                    placeholder: Core__Option.getOr(match$5.placeholder, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultPlaceholder),
                    autoFocus: match$6 !== 0,
                    onChange: (function (value) {
                        setInput(function (param) {
                              return value;
                            });
                        setState(function (state) {
                              return {
                                      answers: state.answers.map(function (answer, index) {
                                            if (index === match$6) {
                                              return {
                                                      page: answer.page,
                                                      answer: value
                                                    };
                                            } else {
                                              return answer;
                                            }
                                          }),
                                      submitting: state.submitting,
                                      submitted: state.submitted
                                    };
                            });
                      }),
                    onSubmitEditing: (function () {
                        handleFieldSubmitEditing(viewId, match$6, getMessageAnswer(answer, choices), state.answers, state.submitting, nextOrSubmitDisabled);
                      })
                  })
            ]
          }) : null;
  } else {
    tmp = null;
  }
  var match$7 = Core__Option.flatMap(questioningColumnViewIndex, (function (x) {
          return Core__Int.fromString(undefined, x);
        }));
  var tmp$1;
  if (currentAnswer !== undefined && match$7 !== undefined) {
    var answer$1 = currentAnswer.answer;
    var choices$1 = currentAnswer.page.choices;
    var nextOrSubmitDisabled$1 = messageState === "WaitingResponse" || answer$1.trim() === "";
    tmp$1 = messageState === "Done" ? null : JsxRuntime.jsx("div", {
            children: JsxRuntime.jsx("div", {
                  children: JsxRuntime.jsx(Primitives__Button.make, {
                        title: "Next",
                        style: {
                          alignSelf: "center",
                          marginTop: Caml_option.some(Primitives__Styling.space("cs_4")),
                          width: Caml_option.some(Primitives__Styling.dp(200))
                        },
                        onPress: (function (_e) {
                            handleFieldSubmitEditing(viewId, match$7, getMessageAnswer(answer$1, choices$1), state.answers, state.submitting, nextOrSubmitDisabled$1);
                          }),
                        disabled: nextOrSubmitDisabled$1
                      }),
                  style: {
                    display: "flex",
                    maxWidth: "640px",
                    flex: "1",
                    flexDirection: "column"
                  }
                }),
            style: {
              display: "flex",
              padding: "16px 24px",
              justifyContent: "center"
            }
          });
  } else {
    tmp$1 = null;
  }
  return JsxRuntime.jsxs("div", {
              children: [
                JsxRuntime.jsx("div", {
                      children: JsxRuntime.jsxs("div", {
                            children: [
                              JsxRuntime.jsx("div", {
                                    children: JsxRuntime.jsxs("div", {
                                          children: [
                                            messages.map(function (message, index) {
                                                  if (message.role === "assistant") {
                                                    return JsxRuntime.jsx(DecidrRenderer__Renderer.make, {
                                                                node: assistantMessageBlockNode,
                                                                context: Caml_option.some(DecidrRenderer__Blocks__SurveyAndChatUtils.makeMessageContext(context, message.content, aiName, aiImage))
                                                              }, index.toString());
                                                  } else {
                                                    return JsxRuntime.jsx(DecidrRenderer__Renderer.make, {
                                                                node: userMessageBlockNode,
                                                                context: Caml_option.some(DecidrRenderer__Blocks__SurveyAndChatUtils.makeMessageContext(context, message.content, userName, userImage))
                                                              }, index.toString());
                                                  }
                                                }),
                                            tmp
                                          ],
                                          style: {
                                            display: "flex",
                                            margin: "0 auto",
                                            maxWidth: "640px",
                                            padding: "0px 24px",
                                            position: "relative",
                                            width: "100%",
                                            flexDirection: "column",
                                            gap: "12px"
                                          }
                                        }),
                                    ref: Caml_option.some(match.ref),
                                    style: {
                                      display: "flex",
                                      maxHeight: "88vh",
                                      overflowY: "auto",
                                      padding: "16px 0 120px 0",
                                      flex: "1",
                                      flexDirection: "column"
                                    }
                                  }),
                              tmp$1
                            ],
                            style: {
                              display: "flex",
                              position: "relative",
                              flex: "1",
                              flexDirection: "column"
                            }
                          }),
                      style: {
                        display: "flex",
                        flex: "1"
                      }
                    }),
                showSideContent$1 ? (
                    sideContentSource !== undefined ? JsxRuntime.jsx("div", {
                            children: sideContentType === "image" ? JsxRuntime.jsx("img", {
                                    style: {
                                      height: "100%",
                                      width: "100%",
                                      objectFit: "cover"
                                    },
                                    src: sideContentSource
                                  }) : JsxRuntime.jsx("iframe", {
                                    style: {
                                      borderRight: sideContentPosition === "left" ? "1px solid #ededed" : undefined,
                                      borderLeft: sideContentPosition === "right" ? "1px solid #ededed" : undefined,
                                      borderWidth: "0"
                                    },
                                    height: "100%",
                                    src: sideContentSource,
                                    width: "100%"
                                  }),
                            className: "chat-right-side-image",
                            style: {
                              flex: "1"
                            }
                          }) : JsxRuntime.jsx("div", {
                            children: JsxRuntime.jsx("div", {
                                  style: {
                                    height: "100%",
                                    width: "100%"
                                  }
                                }),
                            className: "chat-right-side-image",
                            style: {
                              flex: "1"
                            }
                          })
                  ) : null
              ],
              style: {
                display: "flex",
                height: "100%",
                maxHeight: "100vh",
                flex: sideContentType === "iframe" ? "1" : undefined,
                flexDirection: sideContentPosition === "right" ? "row" : "row-reverse"
              }
            });
}

var Content = {
  make: DecidrRenderer__Blocks__SurveyWithAIQuestions$Content
};

function DecidrRenderer__Blocks__SurveyWithAIQuestions$Component(props) {
  var __context = props.context;
  var context = __context !== undefined ? Caml_option.valFromOption(__context) : Renderer__Context.makeEmpty();
  var settings = Renderer__Settings.deserialize(props.settings);
  var getString = function (code) {
    return DecidrRenderer__Blocks__Utils.getString(settings, context, code);
  };
  var getBool = function (code) {
    return DecidrRenderer__Blocks__Utils.getBool(settings, context, code);
  };
  var getNode = function (code) {
    return DecidrRenderer__Blocks__Utils.getNode(settings, context, code);
  };
  var getList = function (code) {
    return DecidrRenderer__Blocks__Utils.getList(settings, context, code);
  };
  var viewId$1 = getString(viewId);
  var organizationId$1 = getString(organizationId);
  var aiName$1 = getString(aiName);
  var aiImage$1 = getString(aiImage);
  var userName$1 = getString(userName);
  var userImage$1 = getString(userImage);
  var showSideContent$1 = getBool(showSideContent);
  var prompt$1 = getString(prompt);
  var sideContentPosition$1 = getString(sideContentPosition);
  var assistantId$1 = getString(assistantId);
  var starterMessage$1 = getString(starterMessage);
  var assistantMessageBlock$1 = getNode(assistantMessageBlock);
  var userMessageBlock$1 = getNode(userMessageBlock);
  var pages$1 = getList(pages);
  var aiName$2 = Core__Option.getOr(aiName$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultAiName);
  var aiImage$2 = Core__Option.getOr(aiImage$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultAiImage);
  var userName$2 = Core__Option.getOr(userName$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultUserName);
  var userImage$2 = Core__Option.getOr(userImage$1, DecidrRenderer__Blocks__SurveyAndChatUtils.defaultUserImage);
  var prompt$2 = Core__Option.getOr(prompt$1, "");
  var sideContentPosition$2 = DecidrRenderer__Blocks__SurveyAndChatUtils.parseSideContentPositionWithDefault(sideContentPosition$1);
  var pages$2 = Core__Option.getOr(Core__Option.map(pages$1, (function (__x) {
              return Core__Array.filterMap(__x, (function (__x) {
                            return parse(__x, context);
                          }));
            })), []);
  if (pages$2.length !== 0 && starterMessage$1 !== undefined && assistantMessageBlock$1 !== undefined && userMessageBlock$1 !== undefined && assistantId$1 !== undefined) {
    return JsxRuntime.jsx(DecidrRenderer__Blocks__SurveyWithAIQuestions$Content, {
                viewId: viewId$1,
                organizationId: organizationId$1,
                context: context,
                aiName: aiName$2,
                aiImage: aiImage$2,
                userName: userName$2,
                userImage: userImage$2,
                userMessageBlockNode: userMessageBlock$1.node,
                assistantMessageBlockNode: assistantMessageBlock$1.node,
                starterMessage: starterMessage$1,
                pages: pages$2,
                showSideContent: showSideContent$1,
                prompt: prompt$2,
                assistantId: assistantId$1,
                sideContentPosition: sideContentPosition$2
              });
  } else {
    return null;
  }
}

var Component = {
  make: DecidrRenderer__Blocks__SurveyWithAIQuestions$Component
};

var descriptor_meta = {
  version: 1,
  minRendererVersion: Renderer__Version.current
};

var descriptor_declaredTriggers = [];

var descriptor_declaredSettings = [{
    code: "general",
    name: "General",
    settings: [
      {
        code: viewId,
        name: "View ID",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: organizationId,
        name: "Organization ID",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: aiName,
        name: "AI Name",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: aiImage,
        name: "AI Image",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: userName,
        name: "User Name",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: userImage,
        name: "User Image",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: showSideContent,
        name: "Show Side Content",
        dataType: {
          TAG: "Bool",
          _0: {
            defaultValue: true
          }
        }
      },
      {
        code: sideContentPosition,
        name: "Side Content Position",
        dataType: {
          TAG: "Enum",
          _0: {
            values: [
              {
                value: "left",
                display: "Left"
              },
              {
                value: "right",
                display: "Right"
              }
            ],
            allowEmpty: true
          }
        }
      },
      {
        code: prompt,
        name: "Prompt",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: assistantId,
        name: "Assistant IID",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: starterMessage,
        name: "Starter Message",
        dataType: {
          TAG: "String",
          _0: {}
        }
      },
      {
        code: pages,
        name: "Pages",
        dataType: {
          TAG: "List",
          _0: {
            itemSchema: [
              {
                key: "sideContentSource",
                dataType: {
                  TAG: "String",
                  _0: {}
                }
              },
              {
                key: "sideContentType",
                dataType: {
                  TAG: "String",
                  _0: {}
                }
              },
              {
                key: "placeholder",
                dataType: {
                  TAG: "String",
                  _0: {}
                }
              },
              {
                key: DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.choices,
                dataType: {
                  TAG: "Object",
                  _0: [
                    {
                      key: DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.multiple,
                      dataType: {
                        TAG: "Bool",
                        _0: {
                          defaultValue: false
                        }
                      }
                    },
                    {
                      key: DecidrRenderer__Blocks__SurveyAndChatUtils.SettingKeys.options,
                      dataType: {
                        TAG: "List",
                        _0: {
                          itemSchema: [
                            {
                              key: "value",
                              dataType: {
                                TAG: "String",
                                _0: {}
                              }
                            },
                            {
                              key: "rowId",
                              dataType: {
                                TAG: "String",
                                _0: {}
                              }
                            }
                          ],
                          defaultValues: [],
                          allowEmpty: true
                        }
                      }
                    }
                  ]
                }
              },
              {
                key: columnView,
                dataType: {
                  TAG: "Object",
                  _0: [
                    {
                      key: "id",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    },
                    {
                      key: "name",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    },
                    {
                      key: "column_id",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    },
                    {
                      key: "component_type_code",
                      dataType: {
                        TAG: "String",
                        _0: {}
                      }
                    }
                  ]
                }
              }
            ],
            defaultValues: [],
            allowEmpty: true
          }
        }
      },
      {
        code: foregroundColor,
        name: "Foreground Color",
        dataType: {
          TAG: "String",
          _0: {
            exampleValue: "#000000"
          }
        }
      }
    ]
  }];

var descriptor = {
  meta: descriptor_meta,
  code: blockCode,
  component: Component,
  declaredTriggers: descriptor_declaredTriggers,
  declaredSettings: descriptor_declaredSettings
};

var Infra;

var Utils;

var SurveyAndChatUtils;

var version = 1;

var minRendererVersion = Renderer__Version.current;

export {
  Infra ,
  Utils ,
  SurveyAndChatUtils ,
  blockCode ,
  version ,
  minRendererVersion ,
  SettingKeys ,
  getMessageAnswer ,
  Page ,
  Answer ,
  Content ,
  Component ,
  descriptor ,
}
/* react Not a pure module */
