/* TypeScript file generated from TamaguiConfig__Tokens.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as TamaguiConfig__TokensJS from './TamaguiConfig__Tokens.bs.js';

import type {Dict_t as Utils__Rescript11TSGenFix_Dict_t} from '../../../../packages/utils/src/Utils__Rescript11TSGenFix.gen';

import type {breakpoint as TamaguiConfig__Media_breakpoint} from '../../../../packages/tamagui-config/src/media/TamaguiConfig__Media.gen';

export type Space_t = 
    "cs_1"
  | "cs_2"
  | "cs_3"
  | "cs_4"
  | "cs_5"
  | "cs_6"
  | "ss_1"
  | "ss_2"
  | "ss_3"
  | "ss_4"
  | "ss_5"
  | "ss_6";

export type Radius_t = 
    "rad_1"
  | "rad_2"
  | "rad_3"
  | "rad_4"
  | "rad_5"
  | "rad_6"
  | "rad_circle";

export type FontSize_t = 
    "title1"
  | "title2"
  | "title3"
  | "title4"
  | "subtitle1"
  | "subtitle2"
  | "body1"
  | "body2"
  | "body3"
  | "button"
  | "caption"
  | "overline";

export type IconSizes_t = "ic_1" | "ic_2" | "ic_3" | "ic_4";

export const Space_componentSpaceTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.Space.componentSpaceTokens as any;

export const Space_sectionSpaceTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.Space.sectionSpaceTokens as any;

export const Space_componentSpaceTokenPrefix: string = TamaguiConfig__TokensJS.Space.componentSpaceTokenPrefix as any;

export const Space_sectionSpaceTokenPrefix: string = TamaguiConfig__TokensJS.Space.sectionSpaceTokenPrefix as any;

export const Space_makeSpaceToken: (_1:Space_t, _2:TamaguiConfig__Media_breakpoint) => string = TamaguiConfig__TokensJS.Space.makeSpaceToken as any;

export const Radius_radiusTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.Radius.radiusTokens as any;

export const Radius_radiusTokenPrefix: string = TamaguiConfig__TokensJS.Radius.radiusTokenPrefix as any;

export const Radius_radiusTokenDelimiter: string = TamaguiConfig__TokensJS.Radius.radiusTokenDelimiter as any;

export const Radius_makeRadiusToken: (_1:Radius_t, _2:TamaguiConfig__Media_breakpoint) => string = TamaguiConfig__TokensJS.Radius.makeRadiusToken as any;

export const FontSize_fontSizeTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.FontSize.fontSizeTokens as any;

export const FontSize_fontLineHeightTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.FontSize.fontLineHeightTokens as any;

export const FontSize_fontLetterSpacingTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.FontSize.fontLetterSpacingTokens as any;

export const FontSize_makeCommonConfig: <a>(_1:a) => Utils__Rescript11TSGenFix_Dict_t<a> = TamaguiConfig__TokensJS.FontSize.makeCommonConfig as any;

export const IconSizes_iconSizesTokens: Utils__Rescript11TSGenFix_Dict_t<number> = TamaguiConfig__TokensJS.IconSizes.iconSizesTokens as any;

export const IconSizes_iconSizesTokenPrefix: string = TamaguiConfig__TokensJS.IconSizes.iconSizesTokenPrefix as any;

export const IconSizes_iconSizesTokenDelimiter: string = TamaguiConfig__TokensJS.IconSizes.iconSizesTokenDelimiter as any;

export const IconSizes_makeIconSizesToken: (_1:IconSizes_t, _2:TamaguiConfig__Media_breakpoint) => string = TamaguiConfig__TokensJS.IconSizes.makeIconSizesToken as any;

export const Colors_tokens: Utils__Rescript11TSGenFix_Dict_t<string> = TamaguiConfig__TokensJS.Colors.tokens as any;

export const Colors: { tokens: Utils__Rescript11TSGenFix_Dict_t<string> } = TamaguiConfig__TokensJS.Colors as any;

export const Radius: {
  radiusTokenDelimiter: string; 
  radiusTokenPrefix: string; 
  radiusTokens: Utils__Rescript11TSGenFix_Dict_t<number>; 
  makeRadiusToken: (_1:Radius_t, _2:TamaguiConfig__Media_breakpoint) => string
} = TamaguiConfig__TokensJS.Radius as any;

export const FontSize: {
  fontLetterSpacingTokens: Utils__Rescript11TSGenFix_Dict_t<number>; 
  fontLineHeightTokens: Utils__Rescript11TSGenFix_Dict_t<number>; 
  makeCommonConfig: <a>(_1:a) => Utils__Rescript11TSGenFix_Dict_t<a>; 
  fontSizeTokens: Utils__Rescript11TSGenFix_Dict_t<number>
} = TamaguiConfig__TokensJS.FontSize as any;

export const IconSizes: {
  makeIconSizesToken: (_1:IconSizes_t, _2:TamaguiConfig__Media_breakpoint) => string; 
  iconSizesTokenPrefix: string; 
  iconSizesTokenDelimiter: string; 
  iconSizesTokens: Utils__Rescript11TSGenFix_Dict_t<number>
} = TamaguiConfig__TokensJS.IconSizes as any;

export const Space: {
  componentSpaceTokens: Utils__Rescript11TSGenFix_Dict_t<number>; 
  makeSpaceToken: (_1:Space_t, _2:TamaguiConfig__Media_breakpoint) => string; 
  sectionSpaceTokens: Utils__Rescript11TSGenFix_Dict_t<number>; 
  componentSpaceTokenPrefix: string; 
  sectionSpaceTokenPrefix: string
} = TamaguiConfig__TokensJS.Space as any;
