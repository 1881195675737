// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Renderer__Settings from "@pageblocks/renderer/src/Renderer__Settings.bs.js";

function getString(settings, context, key) {
  return Renderer__Settings.getString(settings, key, context);
}

function getFloat(settings, context, key) {
  return Renderer__Settings.getFloat(settings, key, context);
}

function getBool(settings, context, key) {
  return Renderer__Settings.getBool(settings, key, context);
}

function getObject(settings, context, key) {
  return Renderer__Settings.getObject(settings, key, context);
}

function getList(settings, context, key) {
  return Renderer__Settings.getList(settings, key, context);
}

function getNode(settings, context, key) {
  return Renderer__Settings.getNode(settings, key, context);
}

function parseRow(dict) {
  var id = Core__Option.flatMap(dict["id"], Core__JSON.Decode.string);
  var name = Core__Option.flatMap(dict["name"], Core__JSON.Decode.string);
  if (id !== undefined && name !== undefined) {
    return {
            id: id,
            name: name
          };
  }
  
}

function parseColumnView(dict) {
  var id = Core__Option.flatMap(dict["id"], Core__JSON.Decode.string);
  var name = Core__Option.flatMap(dict["name"], Core__JSON.Decode.string);
  var columnId = Core__Option.flatMap(dict["column_id"], Core__JSON.Decode.string);
  var componentTypeCode = Core__Option.flatMap(dict["component_type_code"], Core__JSON.Decode.string);
  var rows = Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.map(Core__Option.flatMap(dict["rows"], Core__JSON.Decode.object), (function (prim) {
                      return Object.entries(prim);
                    })), (function (entries) {
                  return entries.map(function (param) {
                              return param[1];
                            });
                })), (function (rows) {
              return Core__Array.filterMap(rows, Core__JSON.Decode.object);
            })), (function (rows) {
          return Core__Array.filterMap(rows, parseRow);
        }));
  if (id !== undefined && name !== undefined && columnId !== undefined && componentTypeCode !== undefined) {
    return {
            id: id,
            name: name,
            columnId: columnId,
            componentTypeCode: componentTypeCode,
            rows: rows
          };
  }
  
}

var Infra;

export {
  Infra ,
  getString ,
  getFloat ,
  getBool ,
  getObject ,
  getList ,
  getNode ,
  parseColumnView ,
}
/* No side effect */
