// Generated by ReScript, PLEASE EDIT WITH CARE

import * as RelayUtils from "../../../relay-utils/src/RelayUtils.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Spaces__View from "./Spaces__View.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as RescriptRelay_Mutation from "rescript-relay/src/RescriptRelay_Mutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";
import * as SpacesViewMutationsCopyViewMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsCopyViewMutation_graphql.bs.js";
import * as SpacesViewMutationsCreateViewMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsCreateViewMutation_graphql.bs.js";
import * as SpacesViewMutationsDeleteViewMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsDeleteViewMutation_graphql.bs.js";
import * as SpacesViewMutationsUpdateViewNameMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsUpdateViewNameMutation_graphql.bs.js";
import * as SpacesViewMutationsUpdateViewIsPublicMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsUpdateViewIsPublicMutation_graphql.bs.js";
import * as SpacesViewMutationsUpdateViewCoreConfigMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsUpdateViewCoreConfigMutation_graphql.bs.js";
import * as SpacesViewMutationsUpdateViewDescriptionMutation_graphql from "../../../relay-codegen/generated/SpacesViewMutationsUpdateViewDescriptionMutation_graphql.bs.js";

var convertVariables = SpacesViewMutationsCreateViewMutation_graphql.Internal.convertVariables;

var convertResponse = SpacesViewMutationsCreateViewMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse = SpacesViewMutationsCreateViewMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables, SpacesViewMutationsCreateViewMutation_graphql.node, convertResponse, convertWrapRawResponse);

var use = RescriptRelay_Mutation.useMutation(convertVariables, SpacesViewMutationsCreateViewMutation_graphql.node, convertResponse, convertWrapRawResponse);

function useCreate() {
  var match = use();
  var addView = match[0];
  var handleAddView = function (tableId, name, viewTypeId, coreConfig, onCompleted, onError) {
    addView({
          coreConfig: coreConfig,
          name: name,
          tableId: DecidrNodeId.magicallyFromInternalId(DecidrNodeId.getInternalId(tableId)),
          viewTypeId: Core__Option.map(viewTypeId, DecidrNodeId.magicallyFromInternalId)
        }, undefined, undefined, (function (store, response) {
            RelayUtils.Helpers.addToArray(store, tableId, response.createView.view.id, "views");
            Core__Option.forEach(Caml_option.nullable_to_opt(store.get(response.createView.view.__id)), (function (viewRP) {
                    viewRP.setValue(0, "timesLinked", undefined);
                  }));
          }), (function (param, errors) {
            var view = param.createView.view;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error creating view", "Spaces__View__Mutations useCreate", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted(view.id, view.name);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error creating view", "Spaces__View__Mutations useCreate", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleAddView,
          match[1]
        ];
}

var convertVariables$1 = SpacesViewMutationsDeleteViewMutation_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesViewMutationsDeleteViewMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$1 = SpacesViewMutationsDeleteViewMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$1, SpacesViewMutationsDeleteViewMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

var use$1 = RescriptRelay_Mutation.useMutation(convertVariables$1, SpacesViewMutationsDeleteViewMutation_graphql.node, convertResponse$1, convertWrapRawResponse$1);

function useDelete() {
  var match = use$1();
  var deleteView = match[0];
  var handleDeleteView = function (id, tableId, onCompleted, onError) {
    deleteView({
          id: id
        }, undefined, undefined, (function (store, response) {
            RelayUtils.Helpers.removeFromArray(store, tableId, response.deleteView.deletedViewId, "views");
          }), (function (param, errors) {
            var deletedViewId = param.deleteView.deletedViewId;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error deleting view", "Spaces__View__Mutations useDelete", (function (message, param) {
                    onError(message);
                  }), (function () {
                    onCompleted(deletedViewId);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error deleting view", "Spaces__View__Mutations useDelete", (function (message, param) {
                    onError(message);
                  }));
          }), undefined);
  };
  return [
          handleDeleteView,
          match[1]
        ];
}

var convertVariables$2 = SpacesViewMutationsUpdateViewCoreConfigMutation_graphql.Internal.convertVariables;

var convertResponse$2 = SpacesViewMutationsUpdateViewCoreConfigMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$2 = SpacesViewMutationsUpdateViewCoreConfigMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$2, SpacesViewMutationsUpdateViewCoreConfigMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

var use$2 = RescriptRelay_Mutation.useMutation(convertVariables$2, SpacesViewMutationsUpdateViewCoreConfigMutation_graphql.node, convertResponse$2, convertWrapRawResponse$2);

function useUpdateCoreConfig() {
  var match = use$2();
  var updateView = match[0];
  var handleUpdateView = function (viewId, coreConfig, onCompleted, onError, reason) {
    updateView({
          coreConfig: coreConfig,
          viewId: viewId
        }, undefined, undefined, (function (store, _response) {
            if (reason !== undefined) {
              var viewConnectionId = RelayRuntime.ConnectionHandler.getConnectionID(DecidrNodeId.toString(viewId), Spaces__View.Data.ViewFragment.Operation.connectionKey, {
                    searchTerm: ""
                  });
              return Core__Option.forEach(Caml_option.nullable_to_opt(store.get(viewConnectionId)), (function (viewConnectionRP) {
                            RelayUtils.RecordProxy.invalidateRecord(viewConnectionRP);
                          }));
            }
            
          }), (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating view core config", "Spaces__View__Mutations useUpdateCoreConfig", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted();
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating view core config", "Spaces__View__Mutations useUpdateCoreConfig", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleUpdateView,
          match[1]
        ];
}

var convertVariables$3 = SpacesViewMutationsUpdateViewDescriptionMutation_graphql.Internal.convertVariables;

var convertResponse$3 = SpacesViewMutationsUpdateViewDescriptionMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$3 = SpacesViewMutationsUpdateViewDescriptionMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$3, SpacesViewMutationsUpdateViewDescriptionMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

var use$3 = RescriptRelay_Mutation.useMutation(convertVariables$3, SpacesViewMutationsUpdateViewDescriptionMutation_graphql.node, convertResponse$3, convertWrapRawResponse$3);

function useUpdateDescription() {
  var match = use$3();
  var updateView = match[0];
  var handleUpdateView = function (viewId, description, onCompleted, onError) {
    updateView({
          description: description,
          viewId: viewId
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating view description", "Spaces__View__Mutations useUpdateDescription", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted();
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating view description", "Spaces__View__Mutations useUpdateDescription", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleUpdateView,
          match[1]
        ];
}

var convertVariables$4 = SpacesViewMutationsUpdateViewIsPublicMutation_graphql.Internal.convertVariables;

var convertResponse$4 = SpacesViewMutationsUpdateViewIsPublicMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$4 = SpacesViewMutationsUpdateViewIsPublicMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$4, SpacesViewMutationsUpdateViewIsPublicMutation_graphql.node, convertResponse$4, convertWrapRawResponse$4);

var use$4 = RescriptRelay_Mutation.useMutation(convertVariables$4, SpacesViewMutationsUpdateViewIsPublicMutation_graphql.node, convertResponse$4, convertWrapRawResponse$4);

function useUpdateIsPublic() {
  var match = use$4();
  var updateView = match[0];
  var handleUpdateView = function (viewId, isPublic, onCompleted, onError) {
    updateView({
          isPublic: isPublic,
          viewId: viewId
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating view public status", "Spaces__View__Mutations useUpdateIsPublic", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted();
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating view public status", "Spaces__View__Mutations useUpdateIsPublic", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleUpdateView,
          match[1]
        ];
}

var convertVariables$5 = SpacesViewMutationsUpdateViewNameMutation_graphql.Internal.convertVariables;

var convertResponse$5 = SpacesViewMutationsUpdateViewNameMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$5 = SpacesViewMutationsUpdateViewNameMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$5, SpacesViewMutationsUpdateViewNameMutation_graphql.node, convertResponse$5, convertWrapRawResponse$5);

var use$5 = RescriptRelay_Mutation.useMutation(convertVariables$5, SpacesViewMutationsUpdateViewNameMutation_graphql.node, convertResponse$5, convertWrapRawResponse$5);

function useUpdateName() {
  var match = use$5();
  var updateView = match[0];
  var handleUpdateView = function (viewId, name, onCompleted, onError) {
    updateView({
          name: name,
          viewId: viewId
        }, undefined, undefined, undefined, (function (_response, errors) {
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error updating view name", "Spaces__View__Mutations useUpdateName", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }), (function () {
                    Core__Option.forEach(onCompleted, (function (onCompleted) {
                            onCompleted();
                          }));
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error updating view name", "Spaces__View__Mutations useUpdateName", (function (message, param) {
                    Core__Option.forEach(onError, (function (onError) {
                            onError(message);
                          }));
                  }));
          }), undefined);
  };
  return [
          handleUpdateView,
          match[1]
        ];
}

var convertVariables$6 = SpacesViewMutationsCopyViewMutation_graphql.Internal.convertVariables;

var convertResponse$6 = SpacesViewMutationsCopyViewMutation_graphql.Internal.convertResponse;

var convertWrapRawResponse$6 = SpacesViewMutationsCopyViewMutation_graphql.Internal.convertWrapRawResponse;

RescriptRelay_Mutation.commitMutation(convertVariables$6, SpacesViewMutationsCopyViewMutation_graphql.node, convertResponse$6, convertWrapRawResponse$6);

var use$6 = RescriptRelay_Mutation.useMutation(convertVariables$6, SpacesViewMutationsCopyViewMutation_graphql.node, convertResponse$6, convertWrapRawResponse$6);

function useCopy() {
  var match = use$6();
  var copyView = match[0];
  var handleCopyView = function (tableId, name, viewId, onCompleted, onError) {
    copyView({
          name: name,
          viewId: viewId
        }, undefined, undefined, (function (store, response) {
            RelayUtils.Helpers.addToArray(store, tableId, response.copyView.copiedView.id, "views");
          }), (function (param, errors) {
            var copiedView = param.copyView.copiedView;
            ErrorHandling__RelayMutationCompleted.handle(errors, "Error copying view", "Spaces__View__Mutations useCopy", (function (message, reason) {
                    onError(message, reason);
                  }), (function () {
                    onCompleted(copiedView.id, copiedView.name);
                  }), undefined);
          }), (function (errors) {
            ErrorHandling__RelayMutationError.handle(errors, "Error copying view", "Spaces__View__Mutations useCopy", (function (message, reason) {
                    onError(message, reason);
                  }));
          }), undefined);
  };
  return [
          handleCopyView,
          match[1]
        ];
}

export {
  useCreate ,
  useDelete ,
  useUpdateCoreConfig ,
  useUpdateDescription ,
  useUpdateIsPublic ,
  useUpdateName ,
  useCopy ,
}
/*  Not a pure module */
