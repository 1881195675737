// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DateTime from "../../../date-time/src/DateTime.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__DecidrText from "../../../primitives/src/text/Primitives__DecidrText.bs.js";

function Spaces__Block__ReadOnlyDate(props) {
  var date = Core__Option.flatMap(props.value, DateTime.parseIsoStringOpt);
  var dateString = date !== undefined ? DateTime.toString(Caml_option.valFromOption(date), {
          NAME: "custom",
          VAL: "MMM dd yyyy hh:mm aa"
        }) : "";
  return JsxRuntime.jsx(Primitives__DecidrText.make, {
              children: Caml_option.some(dateString)
            });
}

var make = Spaces__Block__ReadOnlyDate;

export {
  make ,
}
/* DateTime Not a pure module */
