// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrCellId from "../../../cell-id/src/DecidrCellId.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";
import * as Api from "aws-amplify/api";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as DecidrRowCacheId from "../../../row-cache-id/src/DecidrRowCacheId.bs.js";

function makeNetwork() {
  var graphqlClient = Api.generateClient();
  return RelayRuntime.Network.create((function (operation, variables, _cacheConfig, _uploadables) {
                return graphqlClient.graphql({
                            query: operation.text,
                            variables: variables
                          });
              }), (function (operation, variables, _cacheConfig) {
                return RelayRuntime.Observable.create(function (sink) {
                            var subscription = graphqlClient.graphql({
                                    query: operation.text,
                                    variables: variables
                                  }).subscribe({
                                  next: sink.next,
                                  error: sink.error,
                                  complete: sink.complete,
                                  closed: sink.closed
                                });
                            return {
                                    unsubscribe: subscription.unsubscribe,
                                    closed: subscription.closed
                                  };
                          });
              }));
}

function makeEnvironment() {
  return RescriptRelay.Environment.make(makeNetwork(), RescriptRelay.Store.make(new RelayRuntime.RecordSource(undefined), 10, undefined), (function (nodeObj, typeName) {
                var cells = [
                  "AudioCell",
                  "CurrencyCell",
                  "DateCell",
                  "FileCell",
                  "ImageCell",
                  "MultiSelectCell",
                  "NumberCell",
                  "PercentCell",
                  "SwitchCell",
                  "TextCell",
                  "VideoCell"
                ];
                if (cells.includes(typeName)) {
                  var rowId = Core__Option.flatMap(nodeObj.rowId, DecidrInternalId.fromString);
                  var columnId = Core__Option.flatMap(nodeObj.columnId, DecidrInternalId.fromString);
                  if (rowId !== undefined && columnId !== undefined) {
                    return DecidrCellId.toString(DecidrCellId.make(Caml_option.valFromOption(rowId), Caml_option.valFromOption(columnId)));
                  } else {
                    return nodeObj.id;
                  }
                }
                if (typeName !== "Row") {
                  if (typeName === "SelectedRowInfo") {
                    return nodeObj.row.id;
                  } else {
                    return nodeObj.id;
                  }
                }
                var rowId$1 = DecidrInternalId.fromString(nodeObj.id);
                var viewId = Core__Option.flatMap(nodeObj.viewId, DecidrNodeId.fromString);
                if (rowId$1 !== undefined && viewId !== undefined) {
                  return DecidrRowCacheId.toString(DecidrRowCacheId.make(Caml_option.valFromOption(rowId$1), Caml_option.valFromOption(viewId)));
                } else {
                  return nodeObj.id;
                }
              }), undefined, undefined, undefined, undefined);
}

export {
  makeEnvironment ,
}
/* DecidrCellId Not a pure module */
