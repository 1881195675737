// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Js_exn from "rescript/lib/es6/js_exn.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayRuntime from "relay-runtime";

var Disposable = {};

var getLinkedRecords = RescriptRelay.RecordProxy.getLinkedRecords;

function getConnectionNodeIdsExn(connectionRecordProxy) {
  var edges = getLinkedRecords(connectionRecordProxy, "edges", undefined);
  if (edges !== undefined) {
    return Core__Array.filterMap(edges, (function (edge) {
                  return Core__Option.flatMap(Core__Option.flatMap(edge, (function (__x) {
                                    return Caml_option.nullable_to_opt(__x.getLinkedRecord("node", undefined));
                                  })), (function (__x) {
                                return Caml_option.nullable_to_opt(__x.getValue("id", undefined));
                              }));
                }));
  } else {
    return Js_exn.raiseError("Not a connection proxy record - edges not found");
  }
}

function addUniqueEdgeToConnection(store, connectionProxy, nodeDataId, edgeType, position) {
  var nodeProxy = store.get(nodeDataId);
  if (nodeProxy == null) {
    return "notFound";
  }
  var currentNodes = getConnectionNodeIdsExn(connectionProxy);
  if (currentNodes.some(function (item) {
          return item === nodeDataId;
        })) {
    return "alreadyExists";
  }
  var newEdge = RelayRuntime.ConnectionHandler.createEdge(store, connectionProxy, nodeProxy, edgeType);
  if (position === "first") {
    RelayRuntime.ConnectionHandler.insertEdgeBefore(connectionProxy, newEdge, undefined);
  } else {
    RelayRuntime.ConnectionHandler.insertEdgeAfter(connectionProxy, newEdge, undefined);
  }
  return "added";
}

function updateInt(recordProxy, name, updater, $$arguments) {
  Core__Option.forEach(Caml_option.nullable_to_opt(recordProxy.getValue(name, $$arguments !== undefined ? Caml_option.valFromOption($$arguments) : undefined)), (function (value) {
          recordProxy.setValue(updater(value), name, $$arguments !== undefined ? Caml_option.valFromOption($$arguments) : undefined);
        }));
}

function baseAddToArray(store, parentObjectId, objectToAddId, parentArrayFieldName, $$arguments) {
  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(parentObjectId))), (function (parentRP) {
          var arrayRP = getLinkedRecords(parentRP, parentArrayFieldName, $$arguments);
          var newObjectToAddRP = store.get(DecidrNodeId.toString(objectToAddId));
          if (arrayRP !== undefined && !(newObjectToAddRP == null)) {
            return parentRP.setLinkedRecords(arrayRP.concat([Caml_option.some(newObjectToAddRP)]), parentArrayFieldName, $$arguments !== undefined ? Caml_option.valFromOption($$arguments) : undefined);
          }
          
        }));
}

function addToArray(store, parentObjectId, objectToAddId, parentArrayFieldName) {
  baseAddToArray(store, parentObjectId, objectToAddId, parentArrayFieldName, undefined);
}

function addToArrayWithArguments(store, parentObjectId, objectToAddId, parentArrayFieldName, $$arguments) {
  baseAddToArray(store, parentObjectId, objectToAddId, parentArrayFieldName, Caml_option.some($$arguments));
}

function baseRemoveFromArray(store, parentObjectId, objectToRemoveId, parentArrayFieldName, $$arguments) {
  Core__Option.forEach(Caml_option.nullable_to_opt(store.get(DecidrNodeId.toString(parentObjectId))), (function (parentRP) {
          Core__Option.forEach(getLinkedRecords(parentRP, parentArrayFieldName, $$arguments), (function (arrayRP) {
                  parentRP.setLinkedRecords(arrayRP.filter(function (objectRP) {
                            return Core__Option.getOr(Core__Option.map(Core__Option.flatMap(objectRP, (function (objectRP) {
                                                  return Caml_option.nullable_to_opt(objectRP.getValue("id", undefined));
                                                })), (function (id) {
                                              return id !== DecidrNodeId.toString(objectToRemoveId);
                                            })), false);
                          }), parentArrayFieldName, $$arguments !== undefined ? Caml_option.valFromOption($$arguments) : undefined);
                }));
        }));
}

function removeFromArray(store, parentObjectId, objectToRemoveId, parentArrayFieldName) {
  baseRemoveFromArray(store, parentObjectId, objectToRemoveId, parentArrayFieldName, undefined);
}

function removeFromArrayWithArguments(store, parentObjectId, objectToRemoveId, parentArrayFieldName, $$arguments) {
  baseRemoveFromArray(store, parentObjectId, objectToRemoveId, parentArrayFieldName, Caml_option.some($$arguments));
}

var ConnectionHandler;

var RawRelay;

var RecordSourceSelectorProxy;

function RecordProxy_getLinkedRecord(prim0, prim1, prim2) {
  return Caml_option.nullable_to_opt(prim0.getLinkedRecord(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined));
}

function RecordProxy_setLinkedRecord(prim0, prim1, prim2, prim3) {
  return prim0.setLinkedRecord(prim1, prim2, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

function RecordProxy_setLinkedRecordToNull(prim0, prim1, prim2) {
  return prim0.setValue(null, prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined);
}

function RecordProxy_setLinkedRecords(prim0, prim1, prim2, prim3) {
  return prim0.setLinkedRecords(prim1, prim2, prim3 !== undefined ? Caml_option.valFromOption(prim3) : undefined);
}

function RecordProxy_getValueString(prim0, prim1, prim2) {
  return Caml_option.nullable_to_opt(prim0.getValue(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined));
}

function RecordProxy_getValueBool(prim0, prim1, prim2) {
  return Caml_option.nullable_to_opt(prim0.getValue(prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined));
}

function RecordProxy_setLinkedRecordToUndefined(prim0, prim1, prim2) {
  return prim0.setValue(undefined, prim1, prim2 !== undefined ? Caml_option.valFromOption(prim2) : undefined);
}

function RecordProxy_invalidateRecord(prim) {
  prim.invalidateRecord();
}

var RecordProxy = {
  getLinkedRecord: RecordProxy_getLinkedRecord,
  getLinkedRecords: getLinkedRecords,
  setLinkedRecord: RecordProxy_setLinkedRecord,
  setLinkedRecordToNull: RecordProxy_setLinkedRecordToNull,
  setLinkedRecords: RecordProxy_setLinkedRecords,
  getValueString: RecordProxy_getValueString,
  getValueBool: RecordProxy_getValueBool,
  setLinkedRecordToUndefined: RecordProxy_setLinkedRecordToUndefined,
  invalidateRecord: RecordProxy_invalidateRecord
};

var Helpers = {
  getConnectionNodeIdsExn: getConnectionNodeIdsExn,
  addUniqueEdgeToConnection: addUniqueEdgeToConnection,
  updateInt: updateInt,
  addToArray: addToArray,
  addToArrayWithArguments: addToArrayWithArguments,
  removeFromArray: removeFromArray,
  removeFromArrayWithArguments: removeFromArrayWithArguments
};

export {
  Disposable ,
  ConnectionHandler ,
  RawRelay ,
  RecordSourceSelectorProxy ,
  RecordProxy ,
  Helpers ,
}
/* DecidrNodeId Not a pure module */
