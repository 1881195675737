/* eslint-disable @typescript-eslint/no-unnecessary-condition, @typescript-eslint/no-unsafe-argument, @typescript-eslint/no-unsafe-member-access */

import { useRef, useState } from "react";
import { AssistantStream } from "openai/lib/AssistantStream";
import { parsePartialJson } from "./parse-partial-json";
import { redactEmailFromText } from "@decidr/utils/src/email/Utils__Email.gen";
import { columnView as ColumnView } from "../DecidrRenderer__Blocks__Utils.gen";

export const useScrollToBottom = () => {
  const chatContainerRef = useRef<HTMLDivElement>(null);

  const scrollToBottom = () => {
    chatContainerRef.current?.scrollTo({
      top: chatContainerRef.current.scrollHeight,
      behavior: "smooth",
    });
  };

  return {
    ref: chatContainerRef,
    scrollToBottom,
  };
};

const CLIENT_ID = "new-message";

export type Answer = {
  column: string;
  answer: string;
};

export type MessageObject = {
  column: string | undefined;
  message: string | undefined;
  done: boolean | undefined;
};

export type ResponseObject = {
  next: MessageObject;
  previous: Answer;
};

export type Message = {
  role: "assistant" | "user";
  raw: string | undefined;
  content: string;
  clientId?: string;
};

type State = {
  state: "Ready" | "WaitingResponse" | "Done";
  messages: Message[];
  columnViewId: string | undefined;
  threadId: string | undefined;
  rowId: string | undefined;
  done: boolean | undefined;
};

type SendMessageApi = (
  messages: Message[],
  message: Message,
  columnViews: ColumnView[],
  prompt: string,
  threadId: string | undefined,
  assistantId: string,
  viewId: string | undefined,
  organizationId: string | undefined,
  rowId: string | undefined
) => Promise<Response>;

const sendMessageToChatAPI: SendMessageApi = (
  messages,
  message,
  columnViews,
  prompt,
  threadId,
  assistantId,
  viewId,
  organizationId,
  rowId
) => {
  return fetch("/api/chat", {
    method: "POST",
    body: JSON.stringify({
      messages,
      message: {
        role: message.role,
        content: redactEmailFromText(message.content),
      },
      columnViews,
      prompt,
      runId: threadId,
      assistantId,
      viewId,
      organizationId,
      rowId,
    }),
  });
};

const sendMessageToThreadAPI: SendMessageApi = (
  messages,
  message,
  columnViews,
  prompt,
  threadId,
  assistantId,
  viewId,
  organizationId,
  rowId
) => {
  return fetch("/api/thread", {
    method: "POST",
    body: JSON.stringify({
      messages,
      message: {
        role: message.role,
        content: redactEmailFromText(message.content),
      },
      columnViews,
      prompt,
      runId: threadId,
      assistantId,
      viewId,
      organizationId,
      rowId,
    }),
  });
};

const sendMessageToQuestionAPI: SendMessageApi = (
  _messages,
  message,
  columnViews,
  prompt,
  threadId,
  assistantId,
  _viewId,
  _organizationId,
  _rowId
) => {
  return fetch("/api/question", {
    method: "POST",
    body: JSON.stringify({
      message: {
        role: message.role,
        content: redactEmailFromText(message.content),
      },
      columnViews,
      prompt,
      runId: threadId,
      assistantId,
    }),
  });
};

type UseMessagesInput = {
  starterMessage: string;
  columnViews: ColumnView[];
  scrollToBottom: () => void;
  prompt: string;
  resetInput: () => void;
  assistantId: string;
  viewId: string | undefined;
  organizationId: string | undefined;
  submitFlow: "chat" | "thread" | "question" | undefined;
};

export const useMessages = ({
  starterMessage,
  columnViews,
  scrollToBottom,
  prompt,
  resetInput,
  assistantId,
  viewId,
  organizationId,
  submitFlow = "chat",
}: UseMessagesInput) => {
  const sendMessage =
    submitFlow === "chat"
      ? sendMessageToChatAPI
      : submitFlow === "thread"
      ? sendMessageToThreadAPI
      : sendMessageToQuestionAPI;

  const [state, setState] = useState<State>({
    state: "Ready",
    messages: [
      {
        role: "assistant",
        raw: `{ "message": "${starterMessage}", "column": "0" }`,
        content: starterMessage,
      },
    ],
    columnViewId: "0",
    threadId: undefined,
    rowId: undefined,
    done: false,
  });

  const appendMessage = (content: string) => {
    setState(prev => ({
      ...prev,
      messages: [
        ...prev.messages,
        {
          clientId: CLIENT_ID,
          raw: undefined,
          content: content,
          role: "assistant",
        },
      ],
    }));
  };

  const appendToLastMessage = (chunk: string) => {
    setState(prev => {
      const newMessages: Message[] = [];
      let columnViewId: string | undefined;
      let done: boolean | undefined;
      prev.messages.forEach(message => {
        if (message.clientId === CLIENT_ID) {
          const newRaw = (message.raw ?? "") + chunk;
          const currentObject =
            submitFlow === "chat" || submitFlow === "question"
              ? (parsePartialJson(newRaw) as MessageObject)
              : (parsePartialJson(newRaw) as ResponseObject)?.next;
          columnViewId = currentObject?.column;
          done = currentObject?.done === true;
          newMessages.push({
            ...message,
            raw: newRaw,
            content: currentObject?.message ?? "",
          });
        } else {
          newMessages.push(message);
        }
      });
      return {
        ...prev,
        messages: newMessages,
        columnViewId: columnViewId ?? prev.columnViewId,
        done: done ?? prev.done,
      };
    });
    scrollToBottom();
  };

  const handleMessageCreated = () => {
    appendMessage("");
  };

  const handleMessageContent = (content: string) => {
    appendToLastMessage(content);
  };

  const handleRunStepCreated = (threadId: string, rowId: string) => {
    setState(prev => ({
      ...prev,
      threadId,
      rowId,
    }));
  };

  const handleRunCompleted = () => {
    setState(prev => ({
      ...prev,
      state: prev.done ? "Done" : "Ready",
      messages: prev.messages.map(message => ({
        ...message,
        clientId: undefined,
      })),
    }));
  };

  const handleReadableStream = (stream: AssistantStream) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    stream.on("event", (event: any) => {
      switch (event.event) {
        case "decidr_run_created":
          handleRunStepCreated(event.run_id, event.row_id);
          break;
        case "decidr_run_completed":
          handleRunCompleted();
          break;
        case "decidr_message_created":
          handleMessageCreated();
          break;
        case "decidr_message_content":
          handleMessageContent(event.chunk);
          break;
        default:
          break;
      }
    });
  };

  const handleSubmit = async (input: string) => {
    if (state.state !== "Ready") {
      return;
    }

    const newMessage: Message = {
      content: input,
      role: "user",
      raw: undefined,
    };

    const newMessages: Message[] = [...state.messages, newMessage];

    setState(prev => ({
      ...prev,
      state: "WaitingResponse",
      messages: newMessages,
    }));
    resetInput();
    scrollToBottom();

    const response = await sendMessage(
      newMessages,
      newMessage,
      columnViews,
      prompt,
      state.threadId,
      assistantId,
      viewId,
      organizationId,
      state.rowId
    );

    scrollToBottom();

    if (!response.body) {
      console.error("Internal Error - No body");
      return;
    }

    const stream = AssistantStream.fromReadableStream(response.body);
    handleReadableStream(stream);
  };

  return { state, handleSubmit };
};
