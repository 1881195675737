// Generated by ReScript, PLEASE EDIT WITH CARE

import Head from "next/head";

var make = Head;

export {
  make ,
}
/* make Not a pure module */
