// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Primitives__Alert from "../../../primitives/src/alert/Primitives__Alert.bs.js";

var make = Primitives__Alert.make;

export {
  make ,
}
/* Primitives__Alert Not a pure module */
