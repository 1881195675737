// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_obj from "rescript/lib/es6/caml_obj.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Spaces__Row from "../row/Spaces__Row.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Utils__Array from "../../../utils/src/array/Utils__Array.bs.js";
import * as ReactNative from "react-native";
import * as Spaces__Cells from "../cells/Spaces__Cells.bs.js";
import * as DecidrInternalId from "../../../internal-id/src/DecidrInternalId.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as Spaces__ImageInfo from "../imageInfo/Spaces__ImageInfo.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as Spaces__View__Utils from "./Spaces__View__Utils.bs.js";
import * as ReactTable from "@tanstack/react-table";
import * as Primitives__DecidrList from "../../../primitives/src/list/Primitives__DecidrList.bs.js";
import * as Renderer__SettingsJSON from "@pageblocks/renderer/src/Renderer__SettingsJSON.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ReactVirtual from "@tanstack/react-virtual";
import * as ReactUtils__SimpleReact from "../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Row__ItemRenderer from "../row/renderer/Spaces__Row__ItemRenderer.bs.js";
import * as SpacesViewData_view_graphql from "../../../relay-codegen/generated/SpacesViewData_view_graphql.bs.js";
import * as Spaces__ComponentType__Code from "../componentType/Spaces__ComponentType__Code.bs.js";
import * as SpacesViewImage_view_graphql from "../../../relay-codegen/generated/SpacesViewImage_view_graphql.bs.js";
import * as Spaces__View__DefaultItemLayout from "./Spaces__View__DefaultItemLayout.bs.js";
import * as SpacesViewBasicInfo_view_graphql from "../../../relay-codegen/generated/SpacesViewBasicInfo_view_graphql.bs.js";
import * as Spaces__View__TableLayout__Utils from "./tableLayout/Spaces__View__TableLayout__Utils.bs.js";
import * as Spaces__View__TableLayout__Elements from "./tableLayout/Spaces__View__TableLayout__Elements.bs.js";
import * as SpacesViewDataPaginatedQuery_graphql from "../../../relay-codegen/generated/SpacesViewDataPaginatedQuery_graphql.bs.js";

var convertFragment = SpacesViewImage_view_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesViewImage_view_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesViewImage_view_graphql.node, convertFragment);
}

function use$1(viewRef) {
  var match = use(viewRef);
  var image = Spaces__ImageInfo.useOpt(Core__Option.map(match.image, (function (param) {
              return param.fragmentRefs;
            })));
  var viewTypeIcon = Spaces__ImageInfo.useOpt(Core__Option.map(match.viewType.icon, (function (param) {
              return param.fragmentRefs;
            })));
  if (image !== undefined) {
    return {
            id: image.id,
            url: image.url
          };
  } else {
    return Core__Option.map(viewTypeIcon, (function (param) {
                  return {
                          id: param.id,
                          url: param.url
                        };
                }));
  }
}

function useOpt$1(viewRef) {
  var view = useOpt(viewRef);
  var image = Spaces__ImageInfo.useOpt(Core__Option.flatMap(view, (function (param) {
              return Core__Option.map(param.image, (function (param) {
                            return param.fragmentRefs;
                          }));
            })));
  return Core__Option.map(image, (function (param) {
                return {
                        id: param.id,
                        url: param.url
                      };
              }));
}

var convertFragment$1 = SpacesViewBasicInfo_view_graphql.Internal.convertFragment;

function use$2(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesViewBasicInfo_view_graphql.node, convertFragment$1, fRef);
}

function useOpt$2(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesViewBasicInfo_view_graphql.node, convertFragment$1);
}

function use$3(viewRef) {
  var match = use$2(viewRef);
  var image = use$1(match.fragmentRefs);
  return {
          id: match.id,
          name: match.name,
          image: image
        };
}

function useOpt$3(viewRef) {
  var view = useOpt$2(viewRef);
  var image = useOpt$1(Core__Option.map(view, (function (param) {
              return param.fragmentRefs;
            })));
  if (view !== undefined) {
    return {
            id: view.id,
            name: view.name,
            image: image
          };
  }
  
}

function Spaces__View$Data$WithContainer(props) {
  var children = props.children;
  var renderContainer = props.renderContainer;
  if (renderContainer !== undefined) {
    return renderContainer(children);
  } else {
    return children;
  }
}

var convertFragment$2 = SpacesViewData_view_graphql.Internal.convertFragment;

var makeRefetchVariables = SpacesViewDataPaginatedQuery_graphql.Types.makeRefetchVariables;

var convertRefetchVariables = SpacesViewDataPaginatedQuery_graphql.Internal.convertVariables;

function usePagination(fRef) {
  return RescriptRelay_Fragment.usePaginationFragment(SpacesViewData_view_graphql.node, fRef, convertFragment$2, convertRefetchVariables);
}

function getRowPrimaryValue(columnViews, row) {
  var orderedVisibleColumnViews = Utils__Array.toSorted(columnViews.filter(function (param) {
            return !param.isHidden;
          }), (function (a, b) {
          return a.order - b.order;
        }));
  return Core__Option.getOr(Core__Option.map(Utils__Array.toSorted(row.cells, (function (cellA, cellB) {
                          var getCellOrder = function (cell) {
                            return Core__Option.map(orderedVisibleColumnViews.find(function (param) {
                                            if (cell.__typename === "__unselected") {
                                              return false;
                                            } else {
                                              return param.columnId === cell.columnId;
                                            }
                                          }), (function (param) {
                                          return param.order;
                                        }));
                          };
                          var cellAOrder = getCellOrder(cellA);
                          var cellBOrder = getCellOrder(cellB);
                          if (cellAOrder !== undefined) {
                            if (cellBOrder !== undefined) {
                              return cellAOrder - cellBOrder;
                            } else {
                              return -1;
                            }
                          } else if (cellBOrder !== undefined) {
                            return 1;
                          } else {
                            return 0;
                          }
                        })).find(function (cell) {
                      if (cell.__typename === "__unselected") {
                        return false;
                      } else {
                        return true;
                      }
                    }), (function (cell) {
                    switch (cell.__typename) {
                      case "CurrencyCell" :
                          return cell.currency;
                      case "NumberCell" :
                          return cell.number;
                      case "PercentCell" :
                          return cell.percent;
                      case "TextCell" :
                          return cell.text;
                      case "__unselected" :
                          return null;
                      
                    }
                  })), null);
}

function useData(viewRef) {
  var match = usePagination(viewRef);
  var loadNext = match.loadNext;
  var view = match.data;
  var match$1 = Spaces__Row__Utils.LayoutElement.get(view.coreConfig, "itemLayout");
  var storedFieldMapping = match$1.fieldMapping;
  var contextSchema = Core__Option.getOr(match$1.contextSchema, Spaces__View__DefaultItemLayout.contextSchema);
  var node = Core__Option.getOr(match$1.node, Spaces__View__DefaultItemLayout.node);
  var orderedColumnViews = Utils__Array.toSorted(view.columnViews, (function (a, b) {
            return a.order - b.order;
          })).filter(function (param) {
        return !param.isHidden;
      });
  var supportedColumnViews = Core__Array.filterMap(orderedColumnViews, (function (param) {
          var name = param.name;
          var id = param.id;
          var match = param.componentType;
          var icon = match.icon;
          var columnId = param.columnId;
          var componentTypeCode = Spaces__ComponentType__Code.fromString(match.code);
          return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                        return {
                                id: id,
                                columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                name: name,
                                componentTypeCode: componentTypeCode,
                                componentTypeIconUrl: Core__Option.map(icon, (function (param) {
                                        return param.url;
                                      }))
                              };
                      }));
        }));
  var columnInfos = Core__Array.filterMap(orderedColumnViews, (function (param) {
          var columnId = param.columnId;
          var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
          return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                        return {
                                columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                componentTypeCode: componentTypeCode
                              };
                      }));
        }));
  var fieldMapping = Object.entries(storedFieldMapping).length === 0 ? Spaces__View__Utils.guessFieldMapping(columnInfos, contextSchema) : storedFieldMapping;
  var validFieldMapping = Spaces__View__Utils.withoutInvalidFieldMapping(fieldMapping, columnInfos, contextSchema);
  var extractData = function (row) {
    return Spaces__Row.ListDataFragment.readInline(row.fragmentRefs);
  };
  var handleLoadNextPage = function (countOpt) {
    var count = countOpt !== undefined ? countOpt : 24;
    loadNext(count, undefined);
  };
  return {
          view: {
            columnViews: orderedColumnViews,
            coreConfig: view.coreConfig,
            data: view.data,
            id: view.id,
            viewType: view.viewType
          },
          hasNext: match.hasNext,
          loadNext: handleLoadNextPage,
          isLoadingNext: match.isLoadingNext,
          extractData: extractData,
          itemLayout: node,
          validFieldMapping: validFieldMapping,
          refetch: match.refetch,
          supportedColumnViews: supportedColumnViews,
          rawColumnViews: view.columnViews
        };
}

function useSearch(refetch) {
  var match = React.useTransition();
  var startTransition = match[1];
  var handleSearch = function (searchTerm, onSearchStarted) {
    var searchTerm$1 = searchTerm.trim();
    Core__Option.forEach(onSearchStarted, (function (onSearchStarted) {
            onSearchStarted();
          }));
    startTransition(function () {
          refetch(makeRefetchVariables(undefined, undefined, undefined, undefined, Caml_option.some(searchTerm$1)), "store-and-network", undefined);
        });
  };
  return {
          searching: match[0],
          handleSearch: handleSearch
        };
}

var FixedHeaderComponent = {};

function overrideRootItemLayoutBorderColor(itemLayout) {
  var overriddenSettings = Renderer__SettingsJSON.addEntries(itemLayout.block.settings, [[
          "borderColor",
          Renderer__SettingsJSON.ValueJSON.string(Primitives__Styling.colorValue("primary_2"))
        ]]);
  var newrecord = Caml_obj.obj_dup(itemLayout);
  var init = itemLayout.block;
  newrecord.block = {
    meta: init.meta,
    code: init.code,
    settings: overriddenSettings,
    triggerActions: init.triggerActions
  };
  return newrecord;
}

function getNumberCells(rowEdge) {
  return Core__Array.filterMap(rowEdge.node.cells, (function (cell) {
                if (cell.__typename === "NumberCell") {
                  return [
                          DecidrNodeId.magicallyToInternalId(cell.columnId),
                          cell.number
                        ];
                }
                
              }));
}

function getOrderCellValue(numberCells, orderColumnId) {
  return Core__Option.map(numberCells.find(function (param) {
                  var columnId = param[0];
                  return Core__Option.getOr(Core__Option.map(orderColumnId, (function (orderColumnId) {
                                    return columnId === orderColumnId;
                                  })), false);
                }), (function (param) {
                return param[1];
              }));
}

function withDefaultViewItemLayoutModifier(node) {
  return Spaces__View__DefaultItemLayout.ViewDataItemLayout.make(node);
}

function Spaces__View$Data$ListLayout(props) {
  var modifyItemLayout = props.modifyItemLayout;
  var handleRowAction = props.handleRowAction;
  var fixedHeaderComponent = props.fixedHeaderComponent;
  var loadNextCount = props.loadNextCount;
  var match = useData(props.viewRef);
  var rawColumnViews = match.rawColumnViews;
  var supportedColumnViews = match.supportedColumnViews;
  var validFieldMapping = match.validFieldMapping;
  var itemLayout = match.itemLayout;
  var extractData = match.extractData;
  var isLoadingNext = match.isLoadingNext;
  var loadNext = match.loadNext;
  var hasNext = match.hasNext;
  var view = match.view;
  var match$1 = useSearch(match.refetch);
  var handleSearch = match$1.handleSearch;
  var searching = match$1.searching;
  var orderColumnId = Core__Option.map(supportedColumnViews.find(function (param) {
            return param.name === "Order";
          }), (function (param) {
          return param.columnId;
        }));
  var itemSeparatorComponent = React.useCallback((function () {
          return JsxRuntime.jsx(Primitives__Group.make, {
                      style: {
                        height: Caml_option.some(Primitives__Styling.space("cs_3"))
                      }
                    });
        }), []);
  var fixedHeaderComponent$1 = React.useMemo((function () {
          return ReactUtils__SimpleReact.renderIfSome(fixedHeaderComponent, (function (fixedHeaderComponent) {
                        return fixedHeaderComponent({
                                    handleSearch: handleSearch,
                                    searching: searching
                                  });
                      }));
        }), [
        searching,
        handleSearch
      ]);
  var orderedRows = Utils__Array.toSorted(view.data.edges, (function (rowEdge1, rowEdge2) {
          var orderCellRow1 = getOrderCellValue(getNumberCells(rowEdge1), orderColumnId);
          var orderCellRow2 = getOrderCellValue(getNumberCells(rowEdge2), orderColumnId);
          if (orderCellRow1 !== undefined) {
            if (orderCellRow2 !== undefined) {
              return orderCellRow1 - orderCellRow2;
            } else {
              return -1;
            }
          } else if (orderCellRow2 !== undefined) {
            return 1;
          } else {
            return 0;
          }
        }));
  return JsxRuntime.jsxs(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "default"
                    }),
                flex: 1
              },
              children: [
                fixedHeaderComponent$1,
                JsxRuntime.jsx(Spaces__View$Data$WithContainer, {
                      renderContainer: props.renderContainer,
                      children: JsxRuntime.jsx(Primitives__DecidrList.make, {
                            contentContainerStyle: {
                              paddingTop: Caml_option.some(Primitives__Styling.dp(12)),
                              paddingLeft: Caml_option.some(Primitives__Styling.dp(12)),
                              paddingBottom: Caml_option.some(Primitives__Styling.dp(12))
                            },
                            estimatedItemSize: 150,
                            data: orderedRows,
                            keyExtractor: (function (param, _index) {
                                return DecidrInternalId.toString(DecidrNodeId.magicallyToInternalId(param.node.id));
                              }),
                            renderItem: (function (param) {
                                var node = param.item.node;
                                var rowId = DecidrNodeId.magicallyToInternalId(node.id);
                                var shouldModifyItemLayout = Core__Option.getOr(Core__Option.map(modifyItemLayout, (function (modifyItemLayout) {
                                            return modifyItemLayout(rowId);
                                          })), false);
                                return JsxRuntime.jsx(Primitives__Group.make, {
                                            style: {
                                              flex: 1,
                                              marginRight: Caml_option.some(Primitives__Styling.dp(12))
                                            },
                                            children: Caml_option.some(JsxRuntime.jsx(Spaces__Row__ItemRenderer.make, {
                                                      itemLayout: shouldModifyItemLayout ? overrideRootItemLayoutBorderColor(Spaces__View__DefaultItemLayout.ViewDataItemLayout.make(itemLayout)) : Spaces__View__DefaultItemLayout.ViewDataItemLayout.make(itemLayout),
                                                      rowId: rowId,
                                                      cells: extractData(node).cells,
                                                      fieldMapping: validFieldMapping,
                                                      columnViews: supportedColumnViews,
                                                      viewId: view.id,
                                                      handleRowAction: Core__Option.map(handleRowAction, (function (handleRowAction) {
                                                              return function (viewId, rowId, action) {
                                                                handleRowAction(viewId, rowId, action, getRowPrimaryValue(rawColumnViews, node));
                                                              };
                                                            }))
                                                    }))
                                          });
                              }),
                            onEndReached: (function () {
                                if (hasNext) {
                                  return loadNext(loadNextCount);
                                }
                                
                              }),
                            onEndReachedThreshold: 0.2,
                            extraData: {
                              modifyItemLayout: modifyItemLayout
                            },
                            ItemSeparatorComponent: itemSeparatorComponent,
                            ListFooterComponent: (function () {
                                return ReactUtils__SimpleReact.whenTrue(isLoadingNext, {
                                            LAZY_DONE: false,
                                            VAL: (function () {
                                                return JsxRuntime.jsx(Primitives__Group.make, {
                                                            style: {
                                                              paddingTop: Caml_option.some(Primitives__Styling.space("cs_4"))
                                                            },
                                                            children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                                                                      size: "small"
                                                                    }))
                                                          });
                                              })
                                          });
                              })
                          })
                    })
              ]
            });
}

var ListLayout = {
  make: Spaces__View$Data$ListLayout
};

function Spaces__View$Data$GridLayout(props) {
  var modifyItemLayout = props.modifyItemLayout;
  var handleRowAction = props.handleRowAction;
  var fixedHeaderComponent = props.fixedHeaderComponent;
  var loadNextCount = props.loadNextCount;
  var match = useData(props.viewRef);
  var supportedColumnViews = match.supportedColumnViews;
  var validFieldMapping = match.validFieldMapping;
  var itemLayout = match.itemLayout;
  var extractData = match.extractData;
  var isLoadingNext = match.isLoadingNext;
  var loadNext = match.loadNext;
  var hasNext = match.hasNext;
  var view = match.view;
  var match$1 = useSearch(match.refetch);
  var handleSearch = match$1.handleSearch;
  var searching = match$1.searching;
  var match$2 = React.useState(function () {
        return 0;
      });
  var setListWidth = match$2[1];
  var listWidth = match$2[0];
  var numColumns = React.useMemo((function () {
          if (listWidth > 1300) {
            return 3;
          } else if (listWidth > 800) {
            return 2;
          } else {
            return 1;
          }
        }), [listWidth]);
  var itemSeparatorComponent = React.useCallback((function () {
          return JsxRuntime.jsx(Primitives__Group.make, {
                      style: {
                        height: Caml_option.some(Primitives__Styling.space("cs_3"))
                      }
                    });
        }), []);
  var fixedHeaderComponent$1 = React.useMemo((function () {
          return ReactUtils__SimpleReact.renderIfSome(fixedHeaderComponent, (function (fixedHeaderComponent) {
                        return fixedHeaderComponent({
                                    handleSearch: handleSearch,
                                    searching: searching
                                  });
                      }));
        }), [
        searching,
        handleSearch
      ]);
  return JsxRuntime.jsx(ReactNative.View, {
              onLayout: (function (param) {
                  var nativeEvent = param.nativeEvent;
                  setListWidth(function (param) {
                        return nativeEvent.layout.width;
                      });
                }),
              style: {
                flex: 1
              },
              children: Caml_option.some(JsxRuntime.jsxs(Primitives__Group.make, {
                        style: {
                          backgroundColor: Primitives__Styling.color({
                                NAME: "background",
                                VAL: "default"
                              }),
                          flex: 1
                        },
                        children: [
                          fixedHeaderComponent$1,
                          JsxRuntime.jsx(Spaces__View$Data$WithContainer, {
                                renderContainer: props.renderContainer,
                                children: JsxRuntime.jsx(Primitives__DecidrList.make, {
                                      contentContainerStyle: {
                                        paddingTop: Caml_option.some(Primitives__Styling.dp(12)),
                                        paddingLeft: Caml_option.some(Primitives__Styling.dp(12)),
                                        paddingBottom: Caml_option.some(Primitives__Styling.dp(12))
                                      },
                                      estimatedItemSize: 150,
                                      data: view.data.edges,
                                      keyExtractor: (function (param, _index) {
                                          return DecidrInternalId.toString(DecidrNodeId.magicallyToInternalId(param.node.id));
                                        }),
                                      renderItem: (function (param) {
                                          var node = param.item.node;
                                          var rowId = DecidrNodeId.magicallyToInternalId(node.id);
                                          var shouldModifyItemLayout = Core__Option.getOr(Core__Option.map(modifyItemLayout, (function (modifyItemLayout) {
                                                      return modifyItemLayout(rowId);
                                                    })), false);
                                          return JsxRuntime.jsx(Primitives__Group.make, {
                                                      style: {
                                                        flex: 1,
                                                        marginRight: Caml_option.some(Primitives__Styling.dp(12))
                                                      },
                                                      children: Caml_option.some(JsxRuntime.jsx(Spaces__Row__ItemRenderer.make, {
                                                                itemLayout: shouldModifyItemLayout ? overrideRootItemLayoutBorderColor(Spaces__View__DefaultItemLayout.ViewDataItemLayout.make(itemLayout)) : Spaces__View__DefaultItemLayout.ViewDataItemLayout.make(itemLayout),
                                                                rowId: rowId,
                                                                cells: extractData(node).cells,
                                                                fieldMapping: validFieldMapping,
                                                                columnViews: supportedColumnViews,
                                                                viewId: view.id,
                                                                handleRowAction: handleRowAction
                                                              }))
                                                    });
                                        }),
                                      numColumns: numColumns,
                                      onEndReached: (function () {
                                          if (hasNext) {
                                            return loadNext(loadNextCount);
                                          }
                                          
                                        }),
                                      onEndReachedThreshold: 0.2,
                                      extraData: {
                                        modifyItemLayout: modifyItemLayout
                                      },
                                      ItemSeparatorComponent: itemSeparatorComponent,
                                      ListFooterComponent: (function () {
                                          return ReactUtils__SimpleReact.whenTrue(isLoadingNext, {
                                                      LAZY_DONE: false,
                                                      VAL: (function () {
                                                          return JsxRuntime.jsx(Primitives__Spinner.make, {
                                                                      size: "small"
                                                                    });
                                                        })
                                                    });
                                        })
                                    })
                              })
                        ]
                      }))
            });
}

var GridLayout = {
  make: Spaces__View$Data$GridLayout
};

function Spaces__View$Data$CompactGridLayout(props) {
  var onNavigateToRow = props.onNavigateToRow;
  var fixedHeaderComponent = props.fixedHeaderComponent;
  var loadNextCount = props.loadNextCount;
  var match = useData(props.viewRef);
  var supportedColumnViews = match.supportedColumnViews;
  var isLoadingNext = match.isLoadingNext;
  var loadNext = match.loadNext;
  var hasNext = match.hasNext;
  var view = match.view;
  var match$1 = useSearch(match.refetch);
  var handleSearch = match$1.handleSearch;
  var searching = match$1.searching;
  var tableContainerRef = React.useRef(null);
  var fixedHeaderComponent$1 = React.useMemo((function () {
          return ReactUtils__SimpleReact.renderIfSome(fixedHeaderComponent, (function (fixedHeaderComponent) {
                        return fixedHeaderComponent({
                                    handleSearch: handleSearch,
                                    searching: searching
                                  });
                      }));
        }), [
        searching,
        handleSearch
      ]);
  var columns = React.useMemo((function () {
          return supportedColumnViews.map(function (columnView) {
                      return {
                              accessorFn: (function (row) {
                                  return Spaces__View__TableLayout__Utils.columnAccessorFn(row, columnView.columnId);
                                }),
                              id: columnView.columnId,
                              minSize: 100,
                              maxSize: 150,
                              header: (function () {
                                  return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.Header.make, {
                                              text: columnView.name,
                                              iconUrl: columnView.componentTypeIconUrl
                                            });
                                }),
                              enableSorting: false,
                              meta: {
                                columnViewId: columnView.id,
                                componentTypeCode: columnView.componentTypeCode
                              }
                            };
                    });
        }), [supportedColumnViews.map(function (param) {
                  return param.id;
                }).map(DecidrNodeId.toString).join(",")]);
  var fetchMoreOnBottomReached = React.useCallback((function (containerRefElement) {
          Core__Option.forEach((containerRefElement == null) ? undefined : Caml_option.some(containerRefElement), (function (containerRefElement) {
                  var scrollHeight = containerRefElement.scrollHeight;
                  var scrollTop = containerRefElement.scrollTop;
                  var clientHeight = containerRefElement.clientHeight;
                  if (scrollHeight - scrollTop - clientHeight < 300 && !isLoadingNext && hasNext) {
                    return loadNext(loadNextCount);
                  }
                  
                }));
        }), [
        loadNext,
        isLoadingNext,
        hasNext
      ]);
  var rowsCacheKey = view.data.edges.map(function (param) {
          return DecidrNodeId.toString(param.node.id);
        }).join(",");
  var rows = React.useMemo((function () {
          return view.data.edges.map(function (row) {
                      return {
                              id: DecidrNodeId.magicallyToInternalId(row.node.id),
                              cells: Spaces__Row.ListDataFragment.readInline(row.node.fragmentRefs).cells.map(function (cell) {
                                    return Core__Option.map(Spaces__Cells.getCellSignature(Spaces__Cells.CellFragment.readInline(cell.fragmentRefs)), (function (param) {
                                                  return {
                                                          rowId: param.rowId,
                                                          columnId: param.columnId
                                                        };
                                                }));
                                  })
                            };
                    });
        }), [
        rowsCacheKey,
        view.id
      ]);
  var table = ReactTable.useReactTable({
        data: rows,
        columns: columns,
        debugTable: false,
        getCoreRowModel: ReactTable.getCoreRowModel(),
        getSortedRowModel: Caml_option.some(ReactTable.getSortedRowModel())
      });
  var match$2 = table.getRowModel();
  var rows$1 = match$2.rows;
  var rowVirtualizer = ReactVirtual.useVirtualizer({
        count: hasNext ? rows$1.length + 1 | 0 : rows$1.length,
        estimateSize: (function () {
            return Spaces__View__TableLayout__Elements.rowHeight + Spaces__View__TableLayout__Elements.rowBordersSize;
          }),
        overscan: 10,
        getScrollElement: (function () {
            return tableContainerRef.current;
          })
      });
  var headerGroups = table.getHeaderGroups();
  var virtualizedItems = rowVirtualizer.getVirtualItems();
  var totalSize = rowVirtualizer.getTotalSize();
  var match$3 = virtualizedItems.length > 0 ? [
      virtualizedItems[0].start - rowVirtualizer.options.scrollMargin,
      totalSize - virtualizedItems[virtualizedItems.length - 1 | 0].end
    ] : [
      0,
      0
    ];
  var after = match$3[1];
  var before = match$3[0];
  return JsxRuntime.jsxs(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "surface",
                      VAL: "default"
                    }),
                flex: 1
              },
              children: [
                fixedHeaderComponent$1,
                JsxRuntime.jsx(Spaces__View$Data$WithContainer, {
                      renderContainer: props.renderContainer,
                      children: JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledDiv.make, {
                            onScroll: (function (e) {
                                fetchMoreOnBottomReached(e.target);
                              }),
                            ref: Caml_option.some(tableContainerRef),
                            f: 1,
                            overflow: "auto",
                            style: {
                              overflowAnchor: "none"
                            },
                            children: JsxRuntime.jsxs(Spaces__View__TableLayout__Elements.StyledTable.make, {
                                  w: Caml_option.some(Primitives__Styling.pct(100)),
                                  style: {
                                    borderCollapse: "collapse",
                                    tableLayout: "fixed"
                                  },
                                  children: [
                                    JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledThead.make, {
                                          t: Caml_option.some(Primitives__Styling.dp(-1)),
                                          pos: "sticky",
                                          bc: Primitives__Styling.color({
                                                NAME: "surface",
                                                VAL: "default"
                                              }),
                                          bw: 0,
                                          zi: 3,
                                          children: headerGroups.map(function (headerGroup) {
                                                return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTr.make, {
                                                            h: Caml_option.some(Primitives__Styling.dp(Spaces__View__TableLayout__Elements.rowHeight)),
                                                            children: headerGroup.headers.map(function (header) {
                                                                  return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTh.make, {
                                                                              colSpan: header.colSpan,
                                                                              w: Caml_option.some(Primitives__Styling.dp(150)),
                                                                              bw: 1,
                                                                              boc: Primitives__Styling.color("neutral_5"),
                                                                              children: ReactUtils__SimpleReact.whenTrue(!header.isPlaceholder, {
                                                                                    LAZY_DONE: false,
                                                                                    VAL: (function () {
                                                                                        return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.HeaderContainer.make, {
                                                                                                    children: ReactTable.flexRender(header.column.columnDef.header, header.getContext())
                                                                                                  });
                                                                                      })
                                                                                  })
                                                                            }, header.id);
                                                                })
                                                          }, headerGroup.id);
                                              })
                                        }),
                                    JsxRuntime.jsxs(Spaces__View__TableLayout__Elements.StyledTbody.make, {
                                          children: [
                                            ReactUtils__SimpleReact.whenTrue(before > 0, {
                                                  LAZY_DONE: false,
                                                  VAL: (function () {
                                                      return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTr.make, {
                                                                  children: JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTd.make, {
                                                                        colSpan: columns.length,
                                                                        h: Caml_option.some(Primitives__Styling.dp(before))
                                                                      })
                                                                });
                                                    })
                                                }),
                                            virtualizedItems.map(function (virtualRow) {
                                                  var isLoaderRow = virtualRow.index > (rows$1.length - 1 | 0);
                                                  var tmp;
                                                  if (isLoaderRow) {
                                                    tmp = JsxRuntime.jsx(Primitives__Spinner.make, {});
                                                  } else {
                                                    var row = rows$1[virtualRow.index];
                                                    tmp = row.getVisibleCells().map(function (cell, index) {
                                                          return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTd.make, {
                                                                      w: Caml_option.some(Primitives__Styling.dp(cell.column.getSize())),
                                                                      h: Caml_option.some(Primitives__Styling.dp(Spaces__View__TableLayout__Elements.rowHeight)),
                                                                      bw: 1,
                                                                      p: Caml_option.some(Primitives__Styling.dp(0)),
                                                                      boc: Primitives__Styling.color("neutral_5"),
                                                                      children: Caml_option.some(JsxRuntime.jsx(Spaces__View__TableLayout__Elements.CellContainer.make, {
                                                                                children: JsxRuntime.jsx(Spaces__View__TableLayout__Elements.Cell.make, {
                                                                                      rowId: row.original.id,
                                                                                      columnId: cell.column.columnDef.id,
                                                                                      columnViewId: cell.column.columnDef.meta.columnViewId,
                                                                                      viewId: view.id,
                                                                                      componentTypeCode: Spaces__ComponentType__Code.unsafeFromString(cell.column.columnDef.meta.componentTypeCode),
                                                                                      cell: Spaces__View__TableLayout__Utils.getCell(row.original.id, cell.column.columnDef.id, view.data.edges)
                                                                                    }),
                                                                                index: index,
                                                                                onExpand: (function () {
                                                                                    onNavigateToRow(row.original.id, view.id);
                                                                                  })
                                                                              }))
                                                                    }, cell.id);
                                                        });
                                                  }
                                                  return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTr.make, {
                                                              h: Caml_option.some(Primitives__Styling.dp(Spaces__View__TableLayout__Elements.rowHeight + Spaces__View__TableLayout__Elements.rowBordersSize)),
                                                              hoverStyle: {
                                                                backgroundColor: Primitives__Styling.color("neutral_8")
                                                              },
                                                              children: tmp
                                                            }, virtualRow.key);
                                                }),
                                            ReactUtils__SimpleReact.whenTrue(after > 0, {
                                                  LAZY_DONE: false,
                                                  VAL: (function () {
                                                      return JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTr.make, {
                                                                  children: JsxRuntime.jsx(Spaces__View__TableLayout__Elements.StyledTd.make, {
                                                                        colSpan: columns.length,
                                                                        h: Caml_option.some(Primitives__Styling.dp(after))
                                                                      })
                                                                });
                                                    })
                                                })
                                          ]
                                        })
                                  ]
                                })
                          })
                    })
              ]
            });
}

function Spaces__View$Data$CustomLayout(props) {
  var overrideDefaultViewItemLayoutModifier = props.overrideDefaultViewItemLayoutModifier;
  var modifyItemLayout = props.modifyItemLayout;
  var handleRowAction = props.handleRowAction;
  var fixedHeaderComponent = props.fixedHeaderComponent;
  var match = useData(props.viewRef);
  var supportedColumnViews = match.supportedColumnViews;
  var validFieldMapping = match.validFieldMapping;
  var itemLayout = match.itemLayout;
  var extractData = match.extractData;
  var view = match.view;
  var match$1 = useSearch(match.refetch);
  var handleSearch = match$1.handleSearch;
  var searching = match$1.searching;
  var orderColumnId = Core__Option.map(supportedColumnViews.find(function (param) {
            return param.name === "Order";
          }), (function (param) {
          return param.columnId;
        }));
  var fixedHeaderComponent$1 = React.useMemo((function () {
          return ReactUtils__SimpleReact.renderIfSome(fixedHeaderComponent, (function (fixedHeaderComponent) {
                        return fixedHeaderComponent({
                                    handleSearch: handleSearch,
                                    searching: searching
                                  });
                      }));
        }), [
        searching,
        handleSearch
      ]);
  var orderedRows = Utils__Array.toSorted(view.data.edges, (function (rowEdge1, rowEdge2) {
          var orderCellRow1 = getOrderCellValue(getNumberCells(rowEdge1), orderColumnId);
          var orderCellRow2 = getOrderCellValue(getNumberCells(rowEdge2), orderColumnId);
          if (orderCellRow1 !== undefined) {
            if (orderCellRow2 !== undefined) {
              return orderCellRow1 - orderCellRow2;
            } else {
              return -1;
            }
          } else if (orderCellRow2 !== undefined) {
            return 1;
          } else {
            return 0;
          }
        }));
  return JsxRuntime.jsxs(Primitives__Group.make, {
              style: {
                backgroundColor: Primitives__Styling.color({
                      NAME: "background",
                      VAL: "default"
                    }),
                flex: 1
              },
              children: [
                fixedHeaderComponent$1,
                JsxRuntime.jsx(Spaces__View$Data$WithContainer, {
                      renderContainer: props.renderContainer,
                      children: props.renderList(orderedRows, (function (param) {
                              return DecidrInternalId.toString(DecidrNodeId.magicallyToInternalId(param.node.id));
                            }), (function (param) {
                              var node = param.node;
                              var rowId = DecidrNodeId.magicallyToInternalId(node.id);
                              var shouldModifyItemLayout = Core__Option.getOr(Core__Option.map(modifyItemLayout, (function (modifyItemLayout) {
                                          return modifyItemLayout(rowId);
                                        })), false);
                              var withViewItemLayoutModifier = Core__Option.getOr(overrideDefaultViewItemLayoutModifier, withDefaultViewItemLayoutModifier);
                              return JsxRuntime.jsx(Spaces__Row__ItemRenderer.make, {
                                          itemLayout: shouldModifyItemLayout ? overrideRootItemLayoutBorderColor(withViewItemLayoutModifier(itemLayout)) : withViewItemLayoutModifier(itemLayout),
                                          rowId: rowId,
                                          cells: extractData(node).cells,
                                          fieldMapping: validFieldMapping,
                                          columnViews: supportedColumnViews,
                                          viewId: view.id,
                                          handleRowAction: handleRowAction
                                        });
                            }))
                    })
              ]
            });
}

var CustomLayout = {
  make: Spaces__View$Data$CustomLayout
};

var $$Image = {
  use: use$1
};

var BasicInfo = {
  use: use$3,
  useOpt: useOpt$3
};

var Data_ViewFragment = {
  Types: undefined,
  Operation: {
    connectionKey: "SpacesView_view_data"
  }
};

var Data_CompactGridLayout = {
  make: Spaces__View$Data$CompactGridLayout
};

var Data = {
  ViewFragment: Data_ViewFragment,
  FixedHeaderComponent: FixedHeaderComponent,
  ListLayout: ListLayout,
  GridLayout: GridLayout,
  CompactGridLayout: Data_CompactGridLayout,
  CustomLayout: CustomLayout
};

export {
  $$Image ,
  BasicInfo ,
  Data ,
}
/* react Not a pure module */
