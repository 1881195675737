// Generated by ReScript, PLEASE EDIT WITH CARE

import * as App__Config from "../../../app/src/config/App__Config.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";

function createRow(input, organizationId, onSuccess, onCompleted, onError) {
  var headers = new Headers({
        "Content-type": "application/json",
        accept: "application/json",
        "x-decidr-auth": JSON.stringify({
              organizationId: organizationId
            }),
        "x-api-key": App__Config.values.apiKey
      });
  return fetch(App__Config.values.apiUrl, {
                  method: "POST",
                  body: Core__Option.map(JSON.stringify({
                            query: "mutation CreateRowJSON($input: CreateRowJSONInput!) { createRowJSON(input: $input) }",
                            variables: {
                              input: {
                                json: JSON.stringify(input)
                              }
                            }
                          }), (function (prim) {
                          return prim;
                        })),
                  headers: Caml_option.some(headers)
                }).then(function (resp) {
                if (resp.ok) {
                  return resp.json().then(function (_json) {
                              Core__Option.forEach(onSuccess, (function (onSuccess) {
                                      onSuccess();
                                    }));
                            });
                } else {
                  return resp.json().then(function (response) {
                                ErrorHandling__UnexpectedError.handle("Error creating row", "DecidrRenderer__Actions__Row createRow", {
                                      response: response,
                                      input: input
                                    });
                              }).then(function () {
                              Core__Option.forEach(onError, (function (onError) {
                                      onError();
                                    }));
                            });
                }
              }).then(function () {
              Core__Option.forEach(onCompleted, (function (onCompleted) {
                      onCompleted();
                    }));
            });
}

function createEmptyRow(viewId, organizationId, onSuccess, onCompleted, onError) {
  return createRow({
              cells: [],
              viewId: viewId
            }, organizationId, onSuccess, onCompleted, onError);
}

function updateRow(input, organizationId, onSuccess, onCompleted, onError) {
  var headers = new Headers({
        "Content-type": "application/json",
        accept: "application/json",
        "x-decidr-auth": JSON.stringify({
              organizationId: organizationId
            }),
        "x-api-key": App__Config.values.apiKey
      });
  return fetch(App__Config.values.apiUrl, {
                  method: "POST",
                  body: Core__Option.map(JSON.stringify({
                            query: "mutation UpdateRowJSON($input: UpdateRowJSONInput!) { updateRowJSON(input: $input) }",
                            variables: {
                              input: {
                                json: JSON.stringify(input)
                              }
                            }
                          }), (function (prim) {
                          return prim;
                        })),
                  headers: Caml_option.some(headers)
                }).then(function (resp) {
                if (resp.ok) {
                  return resp.json().then(function (_json) {
                              Core__Option.forEach(onSuccess, (function (onSuccess) {
                                      onSuccess();
                                    }));
                            });
                } else {
                  return resp.json().then(function (response) {
                                ErrorHandling__UnexpectedError.handle("Error updating row", "DecidrRenderer__Actions__Row updateRow", {
                                      response: response,
                                      input: input
                                    });
                              }).then(function () {
                              Core__Option.forEach(onError, (function (onError) {
                                      onError();
                                    }));
                            });
                }
              }).then(function () {
              Core__Option.forEach(onCompleted, (function (onCompleted) {
                      onCompleted();
                    }));
            });
}

export {
  createRow ,
  createEmptyRow ,
  updateRow ,
}
/* App__Config Not a pure module */
