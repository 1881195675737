// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"channelId":{"c":"DecidrNodeId.RelayAppSync"},"botIds":{"ca":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"addBotsToChannel_channel_id":{"c":"DecidrNodeId.RelayAppSync"},"addBotsToChannel_channel_aiMembers_id":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"addBotsToChannel_channel_id":{"c":"DecidrNodeId.RelayAppSync"},"addBotsToChannel_channel_aiMembers_id":{"c":"DecidrNodeId.RelayAppSync"}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var wrapRawResponseConverter = {"__root":{"addBotsToChannel_channel_id":{"c":"DecidrNodeId.RelayAppSync"},"addBotsToChannel_channel_aiMembers_id":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"addBotsToChannel_channel_id":{"c":"DecidrNodeId.RelayAppSync"},"addBotsToChannel_channel_aiMembers_id":{"c":"DecidrNodeId.RelayAppSync"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "botIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "channelId"
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "botOrganizationMembersIds",
            "variableName": "botIds"
          },
          {
            "kind": "Variable",
            "name": "channelId",
            "variableName": "channelId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AddBotsToChannelPayload",
    "kind": "LinkedField",
    "name": "addBotsToChannel",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "concreteType": "Channel",
        "kind": "LinkedField",
        "name": "channel",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          {
            "alias": null,
            "args": null,
            "concreteType": "OrganizationMember",
            "kind": "LinkedField",
            "name": "aiMembers",
            "plural": true,
            "selections": [
              (v2/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "name",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "surname",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "ImageResource",
                "kind": "LinkedField",
                "name": "avatar",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "url",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesChannelMutations_addBotsToChannelMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v1/*: any*/),
      (v0/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesChannelMutations_addBotsToChannelMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "0b197a177113d5a37ffeadaecac1296a",
    "id": null,
    "metadata": {},
    "name": "SpacesChannelMutations_addBotsToChannelMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesChannelMutations_addBotsToChannelMutation(\n  $channelId: ID!\n  $botIds: [ID!]!\n) {\n  addBotsToChannel(input: {channelId: $channelId, botOrganizationMembersIds: $botIds}) {\n    channel {\n      id\n      aiMembers {\n        id\n        name\n        surname\n        avatar {\n          url\n        }\n      }\n    }\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
