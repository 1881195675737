// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Index from "./index";

function downloadFile(prim) {
  return Index.downloadFile(prim);
}

export {
  downloadFile ,
}
/* ./index Not a pure module */
