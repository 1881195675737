// Generated by ReScript, PLEASE EDIT WITH CARE

import RuntimeConfigJson from "./runtime-config.json";

var values = RuntimeConfigJson;

export {
  values ,
}
/* values Not a pure module */
