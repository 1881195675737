// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"rowId":{"c":"DecidrNodeId.RelayAppSync"},"removeSelectedRowInfoIds":{"ca":"DecidrNodeId.RelayAppSync"},"destinationViewId":{"c":"DecidrNodeId.RelayAppSync"},"columnViewId":{"c":"DecidrNodeId.RelayAppSync"},"columnId":{"c":"DecidrNodeId.RelayAppSync"},"addRowIds":{"ca":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"updateMultiSelectCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_viewId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell":{"f":""}}};

var wrapResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, wrapResponseConverterMap, null);
}

var responseConverter = {"__root":{"updateMultiSelectCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_viewId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell":{"f":""}}};

var responseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, responseConverterMap, undefined);
}

var wrapRawResponseConverter = {"__root":{"updateMultiSelectCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_viewId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"updateMultiSelectCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_viewId":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_row_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_data_selectedRows_id":{"c":"DecidrNodeId.RelayAppSync"},"updateMultiSelectCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: wrapResponseConverterMap,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: responseConverterMap,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "addRowIds"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnViewId"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "destinationViewId"
},
v4 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "removeSelectedRowInfoIds"
},
v5 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowId"
},
v6 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "addRowIds",
        "variableName": "addRowIds"
      },
      {
        "kind": "Variable",
        "name": "columnId",
        "variableName": "columnId"
      },
      {
        "kind": "Variable",
        "name": "columnViewId",
        "variableName": "columnViewId"
      },
      {
        "kind": "Variable",
        "name": "removeSelectedRowInfoIds",
        "variableName": "removeSelectedRowInfoIds"
      },
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId"
      },
      {
        "kind": "Variable",
        "name": "viewId",
        "variableName": "destinationViewId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnId",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "concreteType": "MultiSelectData",
  "kind": "LinkedField",
  "name": "data",
  "plural": false,
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "SelectedRowInfo",
      "kind": "LinkedField",
      "name": "selectedRows",
      "plural": true,
      "selections": [
        (v9/*: any*/),
        {
          "alias": null,
          "args": null,
          "concreteType": "Row",
          "kind": "LinkedField",
          "name": "row",
          "plural": false,
          "selections": [
            (v9/*: any*/),
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "viewId",
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/),
      (v4/*: any*/),
      (v5/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesCellsMutations_updateMultiSelectCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UpdateMultiSelectCellPayload",
        "kind": "LinkedField",
        "name": "updateMultiSelectCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultiSelectCell",
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "SpacesMultiSelectCellCache_multiSelectCell"
              },
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v5/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v0/*: any*/),
      (v4/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesCellsMutations_updateMultiSelectCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v6/*: any*/),
        "concreteType": "UpdateMultiSelectCellPayload",
        "kind": "LinkedField",
        "name": "updateMultiSelectCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "MultiSelectCell",
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              (v7/*: any*/),
              (v8/*: any*/),
              (v10/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "873ddadcb1d73ea36b63d06677adad3d",
    "id": null,
    "metadata": {},
    "name": "SpacesCellsMutations_updateMultiSelectCellMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesCellsMutations_updateMultiSelectCellMutation(\n  $rowId: ID!\n  $columnId: ID!\n  $columnViewId: ID!\n  $addRowIds: [ID!]\n  $removeSelectedRowInfoIds: [ID!]\n  $destinationViewId: ID\n) {\n  updateMultiSelectCell(input: {rowId: $rowId, columnId: $columnId, columnViewId: $columnViewId, addRowIds: $addRowIds, removeSelectedRowInfoIds: $removeSelectedRowInfoIds, viewId: $destinationViewId}) {\n    cell {\n      ...SpacesMultiSelectCellCache_multiSelectCell\n      rowId\n      columnId\n      data {\n        selectedRows {\n          id\n          row {\n            id\n            viewId\n          }\n        }\n      }\n    }\n  }\n}\n\nfragment SpacesMultiSelectCellCache_multiSelectCell on MultiSelectCell {\n  rowId\n  columnId\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
