// Generated by ReScript, PLEASE EDIT WITH CARE


function toSorted(arr, fn) {
  arr.sort(fn);
  return arr;
}

function toReversed(arr) {
  arr.reverse();
  return arr;
}

export {
  toSorted ,
  toReversed ,
}
/* No side effect */
