// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"rowId":{"c":"DecidrNodeId.RelayAppSync"},"columnViewId":{"c":"DecidrNodeId.RelayAppSync"},"columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {"__root":{"updateFileCell_cell":{"f":""}}};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {"__root":{"updateFileCell_cell":{"f":""}}};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var wrapRawResponseConverter = {"__root":{"updateFileCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateFileCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var wrapRawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, wrapRawResponseConverterMap, null);
}

var rawResponseConverter = {"__root":{"updateFileCell_cell_rowId":{"c":"DecidrNodeId.RelayAppSync"},"updateFileCell_cell_columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var rawResponseConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse
};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, rawResponseConverterMap, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: wrapRawResponseConverterMap,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: rawResponseConverterMap,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnViewId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "fileUrl"
},
v3 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowId"
},
v4 = [
  {
    "fields": [
      {
        "kind": "Variable",
        "name": "columnId",
        "variableName": "columnId"
      },
      {
        "kind": "Variable",
        "name": "columnViewId",
        "variableName": "columnViewId"
      },
      {
        "kind": "Variable",
        "name": "fileUrl",
        "variableName": "fileUrl"
      },
      {
        "kind": "Variable",
        "name": "rowId",
        "variableName": "rowId"
      }
    ],
    "kind": "ObjectValue",
    "name": "input"
  }
],
v5 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "mimetype",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "size",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "url",
    "storageKey": null
  }
],
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnId",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/),
      (v3/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesCellsMutations_updateFileCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateFileCellPayload",
        "kind": "LinkedField",
        "name": "updateFileCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FileCell",
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              {
                "kind": "InlineDataFragmentSpread",
                "name": "SpacesFileCell_fileCell",
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "FileInfo",
                    "kind": "LinkedField",
                    "name": "file",
                    "plural": false,
                    "selections": [
                      {
                        "kind": "InlineDataFragmentSpread",
                        "name": "SpacesFileInfo_fileInfo",
                        "selections": (v5/*: any*/),
                        "args": null,
                        "argumentDefinitions": []
                      }
                    ],
                    "storageKey": null
                  },
                  (v6/*: any*/),
                  (v7/*: any*/)
                ],
                "args": null,
                "argumentDefinitions": []
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v3/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesCellsMutations_updateFileCellMutation",
    "selections": [
      {
        "alias": null,
        "args": (v4/*: any*/),
        "concreteType": "UpdateFileCellPayload",
        "kind": "LinkedField",
        "name": "updateFileCell",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "FileCell",
            "kind": "LinkedField",
            "name": "cell",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "FileInfo",
                "kind": "LinkedField",
                "name": "file",
                "plural": false,
                "selections": (v5/*: any*/),
                "storageKey": null
              },
              (v6/*: any*/),
              (v7/*: any*/)
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "037efec373d3e7f225d9e4bf17ed9d5c",
    "id": null,
    "metadata": {},
    "name": "SpacesCellsMutations_updateFileCellMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesCellsMutations_updateFileCellMutation(\n  $rowId: ID!\n  $columnId: ID!\n  $columnViewId: ID!\n  $fileUrl: String!\n) {\n  updateFileCell(input: {rowId: $rowId, columnId: $columnId, columnViewId: $columnViewId, fileUrl: $fileUrl}) {\n    cell {\n      ...SpacesFileCell_fileCell\n    }\n  }\n}\n\nfragment SpacesFileCell_fileCell on FileCell {\n  file {\n    ...SpacesFileInfo_fileInfo\n  }\n  rowId\n  columnId\n}\n\nfragment SpacesFileInfo_fileInfo on FileInfo {\n  mimetype\n  size\n  url\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
