export type Record = {
  __typename: string;
  id: string;
  viewId?: string;
  sourceView?: { __ref: string };
  columnId?: string;
  tableLinkInfo?: { __ref: string };
  columnLinkInfo?: { __ref: string };
  tableLink?: { __ref: string };
};

export type Source = { _records: Map<string, Record> };

const getStoreCachedRecords = (source: Source): Record[] => {
  return [...source._records.values()];
};

export const readCachedViewIds = (source: Source) => {
  return getStoreCachedRecords(source)
    .filter(({ __typename }) => __typename === "View")
    .map(({ id }) => id);
};

export const getCachedRowsToUpdate = (source: Source, viewId: string) => {
  return getStoreCachedRecords(source)
    .filter(
      ({ __typename, viewId: rowViewId }) =>
        __typename === "Row" && viewId === rowViewId
    )
    .map(({ id }) => id);
};

export const getCachedViewIdsFromRowId = (source: Source, rowId: string) => {
  return getStoreCachedRecords(source)
    .filter(({ __typename, id }) => __typename === "Row" && id === rowId)
    .map(({ viewId }) => viewId)
    .filter(x => x !== undefined);
};

export const readCachedTableLinkInfosToUpdate = (
  source: Source,
  sourceViewId: string
) => {
  return getStoreCachedRecords(source)
    .filter(
      ({ __typename, sourceView }) =>
        __typename === "TableLinkInfo" && sourceView?.__ref === sourceViewId
    )
    .map(({ id }) => id);
};

export const readCachedTableLinkInfosByColumnId = (
  source: Source,
  givenColumnId: string
) => {
  return getStoreCachedRecords(source)
    .filter(
      ({ __typename, columnId }) =>
        __typename === "ColumnView" && columnId === givenColumnId
    )
    .map(({ tableLinkInfo }) => tableLinkInfo?.__ref)
    .filter(x => x);
};

export const readCachedColumnsByColumnLinkInfoTableLinkId = (
  source: Source,
  tableLinkInfoId: string
) => {
  const columnViewRecords = getStoreCachedRecords(source).filter(
    ({ __typename }) => __typename === "ColumnView"
  );
  const columnLinkInfoRecords = getStoreCachedRecords(source).filter(
    ({ __typename, tableLink }) =>
      __typename === "ColumnLinkInfo" && tableLink?.__ref === tableLinkInfoId
  );

  return columnViewRecords
    .filter(
      ({ columnLinkInfo }) =>
        columnLinkInfo &&
        columnLinkInfoRecords.some(({ id }) => id === columnLinkInfo.__ref)
    )
    .map(({ columnId }) => columnId)
    .filter(x => x);
};
