/* TypeScript file generated from App__RelayProvider.resi by genType. */

/* eslint-disable */
/* tslint:disable */

import * as App__RelayProviderJS from './App__RelayProvider.bs.js';

import type {Environment_t as RescriptRelay_Environment_t} from '../../../../rescript-shims/RescriptRelay.shim';

export type props<children,environment> = { readonly children: children; readonly environment: environment };

export const make: React.ComponentType<{ readonly children: React.ReactNode; readonly environment: RescriptRelay_Environment_t }> = App__RelayProviderJS.make as any;
