// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as SpacesImageInfo_imageInfo_graphql from "../../../relay-codegen/generated/SpacesImageInfo_imageInfo_graphql.bs.js";

var convertFragment = SpacesImageInfo_imageInfo_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesImageInfo_imageInfo_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesImageInfo_imageInfo_graphql.node, convertFragment);
}

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(SpacesImageInfo_imageInfo_graphql.node, convertFragment, fRef);
}

var ImageInfoFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readInline: readInline
};

function use$1(imageInfoRef) {
  return readInline(imageInfoRef);
}

function useOpt$1(imageInfoRef) {
  return Core__Option.map(imageInfoRef, (function (x) {
                return readInline(x);
              }));
}

export {
  ImageInfoFragment ,
  use$1 as use,
  useOpt$1 as useOpt,
}
/* RescriptRelay_Fragment Not a pure module */
