// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Auth__Hooks from "../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Array from "@rescript/core/src/Core__Array.bs.js";
import * as Spaces__Row from "../row/Spaces__Row.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as Primitives__Group from "../../../primitives/src/group/Primitives__Group.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Spaces__Row__Utils from "../row/Spaces__Row__Utils.bs.js";
import * as Primitives__Spinner from "../../../primitives/src/spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as Spaces__View__Utils from "../view/Spaces__View__Utils.bs.js";
import * as App__View__SystemViews from "../../../app/src/view/App__View__SystemViews.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as Spaces__Renderer__Meta from "../renderer/Spaces__Renderer__Meta.bs.js";
import * as Spaces__Row__ItemRenderer from "../row/renderer/Spaces__Row__ItemRenderer.bs.js";
import * as Spaces__ComponentType__Code from "../componentType/Spaces__ComponentType__Code.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as Spaces__View__DefaultItemLayout from "../view/Spaces__View__DefaultItemLayout.bs.js";
import * as Spaces__ItemLayoutRendererContext from "../renderer/Spaces__ItemLayoutRendererContext.bs.js";
import * as SpacesBlockSystemLinkQuery_graphql from "../../../relay-codegen/generated/SpacesBlockSystemLinkQuery_graphql.bs.js";
import * as SpacesBlockSystemLink_view_graphql from "../../../relay-codegen/generated/SpacesBlockSystemLink_view_graphql.bs.js";
import * as SpacesBlockSystemLinkOrganizationMembersQuery_graphql from "../../../relay-codegen/generated/SpacesBlockSystemLinkOrganizationMembersQuery_graphql.bs.js";
import * as SpacesBlockSystemLinkOrganizationMembersQuery_query_graphql from "../../../relay-codegen/generated/SpacesBlockSystemLinkOrganizationMembersQuery_query_graphql.bs.js";

var convertFragment = SpacesBlockSystemLinkOrganizationMembersQuery_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockSystemLinkOrganizationMembersQuery_query_graphql.node, convertFragment, fRef);
}

var convertFragment$1 = SpacesBlockSystemLink_view_graphql.Internal.convertFragment;

function use$1(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesBlockSystemLink_view_graphql.node, convertFragment$1, fRef);
}

function useData(viewRef) {
  var view = use$1(viewRef);
  var match = Spaces__Row__Utils.LayoutElement.get(view.coreConfig, "itemLayout");
  var storedFieldMapping = match.fieldMapping;
  var contextSchema = Core__Option.getOr(match.contextSchema, Spaces__View__DefaultItemLayout.contextSchema);
  var node = Core__Option.getOr(match.node, Spaces__View__DefaultItemLayout.node);
  var columnInfos = Core__Array.filterMap(view.columnViews.filter(function (param) {
            return !param.isHidden;
          }), (function (param) {
          var columnId = param.columnId;
          var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
          return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                        return {
                                columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                componentTypeCode: componentTypeCode
                              };
                      }));
        }));
  var fieldMapping = Object.entries(storedFieldMapping).length === 0 ? Spaces__View__Utils.guessFieldMapping(columnInfos, contextSchema) : storedFieldMapping;
  var validFieldMapping = Spaces__View__Utils.withoutInvalidFieldMapping(fieldMapping, columnInfos, contextSchema);
  var extractData = function (row) {
    return Spaces__Row.ListDataFragment.readInline(row.fragmentRefs);
  };
  return {
          view: view,
          extractData: extractData,
          validFieldMapping: validFieldMapping,
          itemLayout: Spaces__View__DefaultItemLayout.SystemLinkItemLayout.make(node)
        };
}

function Spaces__Block__SystemLink$Data(props) {
  var callerColumnViewId = props.callerColumnViewId;
  var match = useData(props.sourceViewRef);
  var view = match.view;
  var match$1 = Spaces__ItemLayoutRendererContext.use();
  var handleRowAction = match$1.handleRowAction;
  var organizationQuery = use(props.organizationQueryRef);
  var match$2 = organizationQuery.node;
  var match$3 = match$2 !== undefined ? (
      match$2.__typename === "Organization" ? [
          match$2.members.edges.map(function (param) {
                return DecidrNodeId.getInternalId(param.node.id);
              }),
          match$2.aiMembers.edges.map(function (param) {
                return DecidrNodeId.getInternalId(param.node.id);
              })
        ] : [
          [],
          []
        ]
    ) : [
      [],
      []
    ];
  var ais = match$3[1];
  var humans = match$3[0];
  var row = view.row;
  if (row !== undefined) {
    return JsxRuntime.jsx(Spaces__Row__ItemRenderer.make, {
                itemLayout: match.itemLayout,
                rowId: DecidrNodeId.magicallyToInternalId(row.id),
                cells: match.extractData(row).cells,
                fieldMapping: match.validFieldMapping,
                columnViews: Core__Array.filterMap(view.columnViews, (function (param) {
                        var name = param.name;
                        var id = param.id;
                        var columnId = param.columnId;
                        var componentTypeCode = Spaces__ComponentType__Code.fromString(param.componentType.code);
                        return Core__Option.map(componentTypeCode, (function (componentTypeCode) {
                                      return {
                                              id: id,
                                              columnId: DecidrNodeId.magicallyToInternalId(columnId),
                                              name: name,
                                              componentTypeCode: componentTypeCode
                                            };
                                    }));
                      })),
                viewId: view.id,
                handleRowAction: (function (viewId, rowId, action) {
                    var viewId$1 = humans.includes(rowId) ? App__View__SystemViews.organizationMemberHumansViewId : (
                        ais.includes(rowId) ? App__View__SystemViews.organizationMemberAIsViewId : viewId
                      );
                    handleRowAction(viewId$1, rowId, action, Spaces__Renderer__Meta.make([[
                                Spaces__Renderer__Meta.Key.linkColumnViewId,
                                DecidrNodeId.toString(callerColumnViewId)
                              ]]));
                  })
              });
  } else {
    return null;
  }
}

function Spaces__Block__SystemLink$Data$Loading(props) {
  return JsxRuntime.jsx(Primitives__Group.make, {
              style: {
                flex: 1,
                padding: Caml_option.some(Primitives__Styling.dp(6))
              },
              children: Caml_option.some(JsxRuntime.jsx(Primitives__Spinner.make, {
                        size: "small"
                      }))
            });
}

var Loading = {
  make: Spaces__Block__SystemLink$Data$Loading
};

var convertVariables = SpacesBlockSystemLinkQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesBlockSystemLinkQuery_graphql.Internal.convertResponse;

var use$2 = RescriptRelay_Query.useQuery(convertVariables, SpacesBlockSystemLinkQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesBlockSystemLinkQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockSystemLinkQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockSystemLinkQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesBlockSystemLinkQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesBlockSystemLinkQuery_graphql.node, convertVariables);

var convertVariables$1 = SpacesBlockSystemLinkOrganizationMembersQuery_graphql.Internal.convertVariables;

var convertResponse$1 = SpacesBlockSystemLinkOrganizationMembersQuery_graphql.Internal.convertResponse;

var use$3 = RescriptRelay_Query.useQuery(convertVariables$1, SpacesBlockSystemLinkOrganizationMembersQuery_graphql.node, convertResponse$1);

RescriptRelay_Query.useLoader(convertVariables$1, SpacesBlockSystemLinkOrganizationMembersQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesBlockSystemLinkOrganizationMembersQuery_graphql.node, convertResponse$1, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesBlockSystemLinkOrganizationMembersQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.fetchPromised(SpacesBlockSystemLinkOrganizationMembersQuery_graphql.node, convertResponse$1, convertVariables$1);

RescriptRelay_Query.retain(SpacesBlockSystemLinkOrganizationMembersQuery_graphql.node, convertVariables$1);

function Spaces__Block__SystemLink(props) {
  var sourceViewId = props.sourceViewId;
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$2({
        rowId: DecidrNodeId.magicallyFromInternalId(props.selectedRowId),
        skipQuery: !isAuthenticated,
        sourceViewId: sourceViewId
      }, undefined, undefined, undefined);
  var node = match$1.node;
  var match$2 = use$3({
        organizationId: props.organizationId,
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  if (node !== undefined) {
    if (node.__typename === "View") {
      return JsxRuntime.jsx(Spaces__Block__SystemLink$Data, {
                  organizationQueryRef: match$2.fragmentRefs,
                  sourceViewRef: node.fragmentRefs,
                  callerColumnViewId: props.columnViewId
                });
    }
    ErrorHandling__UnexpectedError.handle("No view data found", "Spaces__Block__SystemLink", {
          sourceViewId: sourceViewId,
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("No view data found", "Spaces__Block__SystemLink", {
        sourceViewId: sourceViewId
      });
  return null;
}

var Data = {
  Loading: Loading
};

var make = Spaces__Block__SystemLink;

export {
  Data ,
  make ,
}
/* use Not a pure module */
