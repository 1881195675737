// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";

var Types = {};

var variablesConverter = {"__root":{"rowId":{"c":"DecidrNodeId.RelayAppSync"},"columnViewId":{"c":"DecidrNodeId.RelayAppSync"},"columnId":{"c":"DecidrNodeId.RelayAppSync"}}};

var variablesConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.serialize
};

function convertVariables(v) {
  return RescriptRelay.convertObj(v, variablesConverter, variablesConverterMap, undefined);
}

var wrapResponseConverter = {};

function convertWrapResponse(v) {
  return RescriptRelay.convertObj(v, wrapResponseConverter, undefined, null);
}

var responseConverter = {};

function convertResponse(v) {
  return RescriptRelay.convertObj(v, responseConverter, undefined, undefined);
}

var wrapRawResponseConverter = {};

function convertWrapRawResponse(v) {
  return RescriptRelay.convertObj(v, wrapRawResponseConverter, undefined, null);
}

var rawResponseConverter = {};

function convertRawResponse(v) {
  return RescriptRelay.convertObj(v, rawResponseConverter, undefined, undefined);
}

var Internal = {
  variablesConverter: variablesConverter,
  variablesConverterMap: variablesConverterMap,
  convertVariables: convertVariables,
  wrapResponseConverter: wrapResponseConverter,
  wrapResponseConverterMap: undefined,
  convertWrapResponse: convertWrapResponse,
  responseConverter: responseConverter,
  responseConverterMap: undefined,
  convertResponse: convertResponse,
  wrapRawResponseConverter: wrapRawResponseConverter,
  wrapRawResponseConverterMap: undefined,
  convertWrapRawResponse: convertWrapRawResponse,
  rawResponseConverter: rawResponseConverter,
  rawResponseConverterMap: undefined,
  convertRawResponse: convertRawResponse
};

var Utils = {};

var node = ((function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnId"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "columnViewId"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "rowId"
},
v3 = [
  {
    "alias": null,
    "args": [
      {
        "fields": [
          {
            "kind": "Variable",
            "name": "columnId",
            "variableName": "columnId"
          },
          {
            "kind": "Variable",
            "name": "columnViewId",
            "variableName": "columnViewId"
          },
          {
            "kind": "Variable",
            "name": "rowId",
            "variableName": "rowId"
          }
        ],
        "kind": "ObjectValue",
        "name": "input"
      }
    ],
    "concreteType": "AutoFillCellPayload",
    "kind": "LinkedField",
    "name": "autoFillCell",
    "plural": false,
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "successfullyQueued",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "error",
        "storageKey": null
      }
    ],
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "SpacesCellsMutations_autoFillCellMutation",
    "selections": (v3/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "SpacesCellsMutations_autoFillCellMutation",
    "selections": (v3/*: any*/)
  },
  "params": {
    "cacheID": "22ae69397a67188a23ced427f4f8a5a4",
    "id": null,
    "metadata": {},
    "name": "SpacesCellsMutations_autoFillCellMutation",
    "operationKind": "mutation",
    "text": "mutation SpacesCellsMutations_autoFillCellMutation(\n  $rowId: ID!\n  $columnId: ID!\n  $columnViewId: ID!\n) {\n  autoFillCell(input: {rowId: $rowId, columnId: $columnId, columnViewId: $columnViewId}) {\n    successfullyQueued\n    error\n  }\n}\n"
  }
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
