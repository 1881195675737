export const getHoursAndMinutesFromDate = (date: Date) => {
  return Intl.DateTimeFormat("en-AU", {
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
};

export const getDayAndDateFromDate = (date: Date) => {
  return Intl.DateTimeFormat("en-AU", {
    weekday: "long",
    year:
      new Date().getFullYear() != date.getFullYear() ? "numeric" : undefined,
    month: "long",
    day: "numeric",
  }).format(date);
};

export const getDateFromDate = (date: Date) => {
  return Intl.DateTimeFormat("en-AU", {
    year:
      new Date().getFullYear() != date.getFullYear() ? "numeric" : undefined,
    month: "long",
    day: "numeric",
  }).format(date);
};

export const getFullDateTimeFromDate = (date: Date) => {
  return Intl.DateTimeFormat("en-AU", {
    year:
      new Date().getFullYear() != date.getFullYear() ? "numeric" : undefined,
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "2-digit",
  }).format(date);
};
