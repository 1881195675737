// Generated by ReScript, PLEASE EDIT WITH CARE

import * as DecidrDatepicker__Datepicker from "./DecidrDatepicker__Datepicker.bs.js";

var $$Range;

var make = DecidrDatepicker__Datepicker.make;

export {
  $$Range ,
  make ,
}
/* DecidrDatepicker__Datepicker Not a pure module */
