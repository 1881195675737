// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RescriptRelay_Internal from "rescript-relay/src/RescriptRelay_Internal.bs.js";

var Types = {};

function unwrap_fragment_node(__x) {
  return RescriptRelay_Internal.unwrapUnion(__x, ["Platform"]);
}

var fragmentConverter = {"__root":{"node_Platform_id":{"c":"DecidrNodeId.RelayAppSync"},"node_Platform":{"f":""},"node":{"u":"fragment_node"}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  fragment_node: unwrap_fragment_node
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "id"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesUiPlatformSpacePickerBase_query",
  "selections": [
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "id"
        }
      ],
      "concreteType": null,
      "kind": "LinkedField",
      "name": "node",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "__typename",
          "storageKey": null
        },
        {
          "kind": "InlineFragment",
          "selections": [
            {
              "alias": null,
              "args": null,
              "kind": "ScalarField",
              "name": "id",
              "storageKey": null
            },
            {
              "args": null,
              "kind": "FragmentSpread",
              "name": "SpacesUiPlatformSpacePickerSpaces_platform"
            }
          ],
          "type": "Platform",
          "abstractKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Query",
  "abstractKey": null
};

var wrap_fragment_node = RescriptRelay_Internal.wrapUnion;

export {
  Types ,
  unwrap_fragment_node ,
  wrap_fragment_node ,
  Internal ,
  Utils ,
  node ,
}
/* DecidrNodeId Not a pure module */
