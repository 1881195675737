// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Primitives__Icon from "../icon/Primitives__Icon.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as Primitives__Input from "../input/Primitives__Input.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Spinner from "../spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as RescriptTamagui__Icon__X from "../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__X.bs.js";

function Primitives__Search(props) {
  var __showClearButton = props.showClearButton;
  var searching = props.searching;
  var onClear = props.onClear;
  var showClearButton = __showClearButton !== undefined ? __showClearButton : true;
  return JsxRuntime.jsx(Primitives__Input.make, {
              value: props.value,
              onChange: props.onChange,
              placeholder: props.placeholder,
              right: {
                element: (function (param) {
                    var color = param.color;
                    if (searching) {
                      return JsxRuntime.jsx(Primitives__Spinner.make, {
                                  size: "small",
                                  color: color
                                });
                    } else if (showClearButton) {
                      return JsxRuntime.jsx(Primitives__Group.make, {
                                  children: Caml_option.some(JsxRuntime.jsx(Primitives__Icon.make, {
                                            size: "ic_3",
                                            color: color,
                                            icon: RescriptTamagui__Icon__X.make
                                          })),
                                  onPress: (function () {
                                      onClear();
                                    })
                                });
                    } else {
                      return null;
                    }
                  })
              },
              style: props.style,
              inputContainerStyle: (function (state, focused) {
                  return {
                          backgroundColor: Primitives__Styling.color({
                                NAME: "surface",
                                VAL: "default"
                              }),
                          borderColor: focused ? Primitives__Styling.color({
                                  NAME: "border",
                                  VAL: {
                                    NAME: "highlight",
                                    VAL: "default"
                                  }
                                }) : (
                              state === "error" ? Primitives__Styling.color({
                                      NAME: "border",
                                      VAL: {
                                        NAME: "error",
                                        VAL: "default"
                                      }
                                    }) : (
                                  state === "success" ? Primitives__Styling.color({
                                          NAME: "border",
                                          VAL: {
                                            NAME: "success",
                                            VAL: "default"
                                          }
                                        }) : Primitives__Styling.color({
                                          NAME: "border",
                                          VAL: {
                                            NAME: "neutral",
                                            VAL: "default"
                                          }
                                        })
                                )
                            ),
                          borderRadius: Primitives__Styling.radius("rad_3"),
                          paddingBottom: Caml_option.some(Primitives__Styling.space("cs_1")),
                          paddingLeft: Caml_option.some(Primitives__Styling.space("cs_4")),
                          paddingRight: Caml_option.some(Primitives__Styling.space("cs_4")),
                          paddingTop: Caml_option.some(Primitives__Styling.space("cs_1"))
                        };
                }),
              autoFocus: props.autoFocus
            });
}

var make = Primitives__Search;

export {
  make ,
}
/* Primitives__Icon Not a pure module */
