// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

var fullEmailRegex = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

var simpleEmailRegex = /^[A-Za-z0-9]([A-Za-z0-9_.-]*[A-Za-z0-9])*@[A-Za-z0-9]([A-Za-z0-9_.-]*[A-Za-z0-9])*\.[A-Za-z0-9]{2,}$/;

function looksLikeEmail(email) {
  if (fullEmailRegex.test(email)) {
    return "yes";
  } else if (simpleEmailRegex.test(email)) {
    return "maybe";
  } else {
    return "no";
  }
}

var emailRegexForRedact = /[A-Za-z0-9]([A-Za-z0-9_.-]*[A-Za-z0-9])*@[A-Za-z0-9]([A-Za-z0-9_.-]*[A-Za-z0-9])*\.[A-Za-z0-9]{2,}/g;

function redactEmailFromText(text) {
  return text.replace(emailRegexForRedact, (function (match, param, param$1) {
                var parts = match.split("@");
                var localPart = Core__Option.getOr(parts[0], "");
                var domain = Core__Option.getOr(parts[1], "");
                var redactedLocalPart = localPart.charAt(0) + "******";
                var domainNameEndIndex = domain.indexOf(".");
                var redactedDomainName = domain.charAt(0) + "******";
                var rest = domain.slice(domainNameEndIndex);
                return redactedLocalPart + "@" + redactedDomainName + rest;
              }));
}

function extractEmailsFromText(text) {
  var emails = text.match(emailRegexForRedact);
  if (emails == null) {
    return [];
  } else {
    return emails.map(function (email) {
                return email.trim();
              });
  }
}

export {
  looksLikeEmail ,
  redactEmailFromText ,
  extractEmailsFromText ,
}
/* No side effect */
