// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";

function parse(json) {
  var x = Core__JSON.Decode.string(json);
  if (x !== undefined) {
    return JSON.parse(x);
  } else {
    return null;
  }
}

function serialize(prim) {
  return JSON.stringify(prim);
}

export {
  parse ,
  serialize ,
}
/* No side effect */
