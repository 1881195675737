// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as Primitives__Group from "../group/Primitives__Group.bs.js";
import * as Primitives__Modal from "../modal/Primitives__Modal.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Spinner from "../spinner/Primitives__Spinner.bs.js";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";
import * as Primitives__DecidrText from "../text/Primitives__DecidrText.bs.js";
import * as Primitives__Error__Message from "../error/message/Primitives__Error__Message.bs.js";
import * as Primitives__UnstyledButton from "../button/Primitives__UnstyledButton.bs.js";

function make(param) {
  var withoutCancel = param.withoutCancel;
  var onCancel = param.onCancel;
  var onConfirm = param.onConfirm;
  var busy = Core__Option.getOr(param.busy, false);
  var destructive = Core__Option.getOr(param.destructive, true);
  var tmp;
  var exit = 0;
  if (withoutCancel !== undefined && withoutCancel) {
    tmp = null;
  } else {
    exit = 1;
  }
  if (exit === 1) {
    tmp = JsxRuntime.jsx(Primitives__UnstyledButton.make, {
          children: JsxRuntime.jsx(Primitives__DecidrText.make, {
                style: {
                  fontWeight: 500
                },
                kind: "button",
                children: Caml_option.some(Core__Option.getOr(param.cancelText, "Cancel")),
                color: Primitives__Styling.color({
                      NAME: "text",
                      VAL: {
                        NAME: "on_background",
                        VAL: "default"
                      }
                    })
              }),
          style: {
            backgroundColor: Primitives__Styling.color({
                  NAME: "background",
                  VAL: "default"
                }),
            borderRadius: Primitives__Styling.radius("rad_3"),
            padding: Caml_option.some(Primitives__Styling.space("cs_2"))
          },
          onPress: (function (param) {
              onCancel();
            }),
          hoverStyle: {
            backgroundColor: busy ? undefined : Primitives__Styling.color({
                    NAME: "background",
                    VAL: "subdued"
                  })
          },
          disabled: busy
        });
  }
  return JsxRuntime.jsx(Primitives__Modal.make, {
              children: JsxRuntime.jsxs(Primitives__Group.make, {
                    gap: Caml_option.some(Primitives__Styling.gap("ss_1")),
                    style: {
                      alignItems: "center",
                      justifyContent: "center",
                      paddingBottom: Caml_option.some(Primitives__Styling.space("ss_1")),
                      paddingLeft: Caml_option.some(Primitives__Styling.space("ss_1")),
                      paddingRight: Caml_option.some(Primitives__Styling.space("ss_1")),
                      paddingTop: Caml_option.some(Primitives__Styling.space("ss_5"))
                    },
                    children: [
                      JsxRuntime.jsxs(Primitives__Group.make, {
                            gap: Caml_option.some(Primitives__Styling.gap("cs_2")),
                            children: [
                              JsxRuntime.jsx(Primitives__DecidrText.make, {
                                    style: {
                                      textAlign: "center"
                                    },
                                    children: Caml_option.some(param.description)
                                  }),
                              JsxRuntime.jsx(Primitives__Error__Message.make, {
                                    error: param.error
                                  })
                            ]
                          }),
                      JsxRuntime.jsxs(Primitives__Group.make, {
                            gap: Caml_option.some(Primitives__Styling.gap("cs_4")),
                            orientation: "horizontal",
                            style: {
                              justifyContent: "center"
                            },
                            children: [
                              tmp,
                              JsxRuntime.jsx(Primitives__UnstyledButton.make, {
                                    children: busy ? JsxRuntime.jsx(Primitives__Spinner.make, {
                                            size: "small",
                                            color: Primitives__Styling.color({
                                                  NAME: "icon",
                                                  VAL: {
                                                    NAME: "on_primary",
                                                    VAL: "default"
                                                  }
                                                })
                                          }) : JsxRuntime.jsx(Primitives__DecidrText.make, {
                                            style: {
                                              fontWeight: 500
                                            },
                                            kind: "button",
                                            children: Caml_option.some(Core__Option.getOr(param.confirmText, "Confirm")),
                                            color: Primitives__Styling.color({
                                                  NAME: "text",
                                                  VAL: {
                                                    NAME: "on_primary",
                                                    VAL: "default"
                                                  }
                                                })
                                          }),
                                    style: {
                                      backgroundColor: destructive ? Primitives__Styling.color({
                                              NAME: "action",
                                              VAL: {
                                                NAME: "error",
                                                VAL: "default"
                                              }
                                            }) : Primitives__Styling.color("primary_1"),
                                      borderRadius: Primitives__Styling.radius("rad_3"),
                                      padding: Caml_option.some(Primitives__Styling.space("cs_2"))
                                    },
                                    onPress: (function (param) {
                                        onConfirm();
                                      }),
                                    hoverStyle: {
                                      backgroundColor: busy ? undefined : (
                                          destructive ? Primitives__Styling.color({
                                                  NAME: "action",
                                                  VAL: {
                                                    NAME: "error",
                                                    VAL: "hover"
                                                  }
                                                }) : Primitives__Styling.color("primary_2")
                                        )
                                    },
                                    disabled: busy
                                  })
                            ]
                          })
                    ]
                  }),
              show: param.show,
              onClose: onCancel,
              title: param.title,
              hideCloseButton: true,
              contentStyle: {
                alignItems: "center",
                justifyContent: "center",
                maxWidth: Caml_option.some(Primitives__Styling.dp(400))
              }
            });
}

export {
  make ,
}
/* Primitives__Group Not a pure module */
