// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Utils__Time from "../../../utils/src/time/Utils__Time.bs.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as DecidrNodeId from "../../../node-id/src/DecidrNodeId.bs.js";
import * as App__RelayEnv from "../relay/App__RelayEnv.bs.js";
import * as Utils__Ignored from "../../../utils/src/ignored/Utils__Ignored.bs.js";
import * as Analytics__Event from "../../../analytics/src/Analytics__Event.bs.js";
import * as Analytics__Storage from "../../../analytics/src/storage/Analytics__Storage.bs.js";
import * as App__Analytics__TrackMutation from "./App__Analytics__TrackMutation.bs.js";
import * as ErrorHandling__RelayMutationError from "../../../error-handling/src/ErrorHandling__RelayMutationError.bs.js";
import * as ErrorHandling__RelayMutationCompleted from "../../../error-handling/src/ErrorHandling__RelayMutationCompleted.bs.js";

var intervalInMs = 5 * Utils__Time.minuteInMs;

function sendAllStoredEvents() {
  var allEvents = Analytics__Storage.getAllEventsToSend();
  App__Analytics__TrackMutation.commitMutation(App__RelayEnv.makeEnvironment(), {
        input: {
          events: allEvents.map(function ($$event) {
                return {
                        anonymousId: $$event.anonymousId,
                        context: $$event.context,
                        eventId: $$event.eventId,
                        name: $$event.name,
                        properties: $$event.properties,
                        timestamp: new Date($$event.timestamp),
                        type: $$event.type,
                        userId: $$event.userId
                      };
              })
        }
      }, undefined, undefined, undefined, (function (param, errors) {
          var track = param.track;
          ErrorHandling__RelayMutationCompleted.handle(errors, "Error sending analytics events", "App__Analytics__Utils sendAllStoredEvents", undefined, (function () {
                  Analytics__Storage.removeEvents(track.eventIds.map(DecidrNodeId.toString));
                  Analytics__Storage.setLastBulkSendTimestamp();
                }), undefined);
        }), (function (errors) {
          ErrorHandling__RelayMutationError.handle(errors, "Error sending analytics events", "App__Analytics__Utils sendAllStoredEvents", undefined);
        }), undefined);
}

async function sendOrStoreEvents(eventTypes) {
  var eventsToStore = await Analytics__Event.makeEventsData(eventTypes);
  Analytics__Storage.modifyOrAddEventsLocally(eventsToStore);
  var lastTimeStamp = Analytics__Storage.getLastBulkSendTimestamp();
  if (Core__Option.getOr(Core__Option.map(lastTimeStamp, (function (lastTimeStamp) {
                return lastTimeStamp + intervalInMs < Date.now();
              })), true)) {
    return sendAllStoredEvents();
  }
  
}

function trackPageView(properties) {
  Utils__Ignored.promise(sendOrStoreEvents([{
              TAG: "PageViewEvent",
              _0: {
                path: properties.path,
                referrer: properties.referrer,
                search: properties.search,
                title: properties.title
              }
            }]));
}

export {
  trackPageView ,
  sendAllStoredEvents ,
  sendOrStoreEvents ,
}
/* DecidrNodeId Not a pure module */
