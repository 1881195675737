// Generated by ReScript, PLEASE EDIT WITH CARE

import * as BaseText from "./BaseText";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as TamaguiConfig from "../../../tamagui-config/src/TamaguiConfig.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as Primitives__Styling from "../styling/Primitives__Styling.bs.js";

var hack = ReactNative.Platform.OS === "web" ? ({
      whiteSpace: "pre-wrap"
    }) : Primitives__Styling.empty;

var WhiteSpacePreWrap = {
  hack: hack
};

function makeTextResponsiveStyles(prim) {
  return BaseText.makeTextResponsiveStyles(prim);
}

var make = BaseText.BaseText;

function kindFromString(kind) {
  switch (kind) {
    case "body1" :
        return "body1";
    case "body2" :
        return "body2";
    case "body3" :
        return "body3";
    case "button" :
        return "button";
    case "caption" :
        return "caption";
    case "overline" :
        return "overline";
    case "subtitle1" :
        return "subtitle1";
    case "subtitle2" :
        return "subtitle2";
    case "title1" :
        return "title1";
    case "title2" :
        return "title2";
    case "title3" :
        return "title3";
    case "title4" :
        return "title4";
    default:
      return "body2";
  }
}

function Primitives__DecidrText(props) {
  var __children = props.children;
  var __specificStyles = props.specificStyles;
  var __kind = props.kind;
  var __style = props.style;
  var style = __style !== undefined ? __style : Primitives__Styling.empty;
  var kind = __kind !== undefined ? __kind : "body2";
  var specificStyles = __specificStyles !== undefined ? __specificStyles : TamaguiConfig.Utils.emptySpecificStyles;
  var children = __children !== undefined ? Caml_option.valFromOption(__children) : null;
  var stylesDict = TamaguiConfig.Utils.extractCommonStyles(TamaguiConfig.Utils.makeResponsiveStyle(style, Caml_option.some(Primitives__Styling.makeSpecificStyles(specificStyles)), undefined));
  var style$1 = stylesDict["common"];
  return JsxRuntime.jsx(make, {
              children: Caml_option.some(children),
              style: Core__Option.map(style$1, (function (__x) {
                      return Primitives__Styling.Utils.omitStyleKeys(__x, [
                                  "flex",
                                  "fontWeight"
                                ]);
                    })),
              kind: kind,
              $s: stylesDict["$s"],
              $m: stylesDict["$m"],
              $l: stylesDict["$l"],
              $xl: stylesDict["$xl"],
              col: Core__Option.getOr(props.color, Primitives__Styling.color({
                        NAME: "text",
                        VAL: {
                          NAME: "on_surface",
                          VAL: "default"
                        }
                      })),
              numberOfLines: props.numberOfLines,
              f: Core__Option.flatMap(style$1, (function (s) {
                      return s.flex;
                    })),
              fow: Core__Option.flatMap(style$1, (function (s) {
                      return s.fontWeight;
                    })),
              hoverStyle: props.hoverStyle,
              selectable: props.selectable,
              tag: props.tag
            });
}

var make$1 = Primitives__DecidrText;

export {
  WhiteSpacePreWrap ,
  makeTextResponsiveStyles ,
  kindFromString ,
  make$1 as make,
}
/* hack Not a pure module */
