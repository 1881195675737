// Generated by ReScript, PLEASE EDIT WITH CARE

import * as FlashList from "@shopify/flash-list";

function scrollToEnd(prim0, prim1) {
  prim0.scrollToEnd(prim1);
}

function scrollToOffset(prim0, prim1) {
  prim0.scrollToOffset(prim1);
}

var ListHeaderComponent = {};

var FlashListElementElement;

var make = FlashList.FlashList;

export {
  FlashListElementElement ,
  scrollToEnd ,
  scrollToOffset ,
  ListHeaderComponent ,
  make ,
}
/* make Not a pure module */
