// Generated by ReScript, PLEASE EDIT WITH CARE
/* @generated */

import * as DecidrNodeId from "../../node-id/src/DecidrNodeId.bs.js";
import * as RescriptRelay from "rescript-relay/src/RescriptRelay.bs.js";
import * as RelayAppSync__AWSJSON from "../../relay-appsync/src/RelayAppSync__AWSJSON.bs.js";

var Types = {};

var fragmentConverter = {"__root":{"viewType_id":{"c":"DecidrNodeId.RelayAppSync"},"row_id":{"c":"DecidrNodeId.RelayAppSync"},"row":{"f":""},"id":{"c":"DecidrNodeId.RelayAppSync"},"coreConfig":{"c":"RelayAppSync.AWSJSON"},"columnViews_id":{"c":"DecidrNodeId.RelayAppSync"},"columnViews_componentType_id":{"c":"DecidrNodeId.RelayAppSync"},"columnViews_columnId":{"c":"DecidrNodeId.RelayAppSync"},"columnViews":{"f":""},"":{"f":""}}};

var fragmentConverterMap = {
  "DecidrNodeId.RelayAppSync": DecidrNodeId.RelayAppSync.parse,
  "RelayAppSync.AWSJSON": RelayAppSync__AWSJSON.parse
};

function convertFragment(v) {
  return RescriptRelay.convertObj(v, fragmentConverter, fragmentConverterMap, undefined);
}

var Internal = {
  fragmentConverter: fragmentConverter,
  fragmentConverterMap: fragmentConverterMap,
  convertFragment: convertFragment
};

var Utils = {};

var node = ((function(){
var v0 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "columnId",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "code",
  "storageKey": null
},
v3 = [
  (v0/*: any*/),
  (v2/*: any*/)
],
v4 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SpacesCache_row"
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "rowId",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "url",
  "storageKey": null
},
v8 = [
  {
    "kind": "InlineDataFragmentSpread",
    "name": "SpacesFileInfo_fileInfo",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "mimetype",
        "storageKey": null
      },
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "size",
        "storageKey": null
      },
      (v7/*: any*/)
    ],
    "args": null,
    "argumentDefinitions": ([]/*: any*/)
  }
],
v9 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SpacesMultiSelectCellCache_multiSelectCell"
},
v10 = {
  "args": null,
  "kind": "FragmentSpread",
  "name": "SpacesLookupCellCache_lookupCell"
};
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "rowId"
    }
  ],
  "kind": "Fragment",
  "metadata": null,
  "name": "SpacesBlockSystemLink_view",
  "selections": [
    (v0/*: any*/),
    {
      "args": [
        {
          "kind": "Variable",
          "name": "rowId",
          "variableName": "rowId"
        }
      ],
      "kind": "FragmentSpread",
      "name": "SpacesViewColumnViewRendererCommon_view"
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "callerRowId",
          "variableName": "rowId"
        }
      ],
      "concreteType": "ColumnView",
      "kind": "LinkedField",
      "name": "columnViews",
      "plural": true,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "SpacesCache_columnView"
        },
        (v0/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "isHidden",
          "storageKey": null
        },
        (v1/*: any*/),
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "concreteType": "ComponentType",
          "kind": "LinkedField",
          "name": "componentType",
          "plural": false,
          "selections": (v3/*: any*/),
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewType",
      "args": null,
      "concreteType": "ViewType",
      "kind": "LinkedField",
      "name": "type",
      "plural": false,
      "selections": (v3/*: any*/),
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "coreConfig",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Variable",
          "name": "id",
          "variableName": "rowId"
        }
      ],
      "concreteType": "Row",
      "kind": "LinkedField",
      "name": "row",
      "plural": false,
      "selections": [
        (v0/*: any*/),
        (v4/*: any*/),
        {
          "kind": "InlineDataFragmentSpread",
          "name": "SpacesRowListData_row",
          "selections": [
            (v4/*: any*/),
            (v0/*: any*/),
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "cells",
              "plural": true,
              "selections": [
                (v5/*: any*/),
                {
                  "kind": "InlineDataFragmentSpread",
                  "name": "SpacesCells_cell",
                  "selections": [
                    (v5/*: any*/),
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesTextCell_textCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "text",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "TextCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesNumberCell_numberCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "number",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "NumberCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesPercentCell_percentCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "percent",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "PercentCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesCurrencyCell_currencyCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "currency",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "CurrencyCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesSwitchCell_switchCell",
                          "selections": [
                            {
                              "alias": "switched",
                              "args": null,
                              "kind": "ScalarField",
                              "name": "switch",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "SwitchCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesDateCell_dateCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "kind": "ScalarField",
                              "name": "date",
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "DateCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesImageCell_imageCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "ImageInfo",
                              "kind": "LinkedField",
                              "name": "image",
                              "plural": false,
                              "selections": [
                                {
                                  "kind": "InlineDataFragmentSpread",
                                  "name": "SpacesImageInfo_imageInfo",
                                  "selections": [
                                    (v0/*: any*/),
                                    (v7/*: any*/),
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Size",
                                      "kind": "LinkedField",
                                      "name": "size",
                                      "plural": false,
                                      "selections": [
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "width",
                                          "storageKey": null
                                        },
                                        {
                                          "alias": null,
                                          "args": null,
                                          "kind": "ScalarField",
                                          "name": "height",
                                          "storageKey": null
                                        }
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "args": null,
                                  "argumentDefinitions": []
                                }
                              ],
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "ImageCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesAudioCell_audioCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FileInfo",
                              "kind": "LinkedField",
                              "name": "audio",
                              "plural": false,
                              "selections": (v8/*: any*/),
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "AudioCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesVideoCell_videoCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FileInfo",
                              "kind": "LinkedField",
                              "name": "video",
                              "plural": false,
                              "selections": (v8/*: any*/),
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "VideoCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesFileCell_fileCell",
                          "selections": [
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "FileInfo",
                              "kind": "LinkedField",
                              "name": "file",
                              "plural": false,
                              "selections": (v8/*: any*/),
                              "storageKey": null
                            },
                            (v6/*: any*/),
                            (v1/*: any*/)
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "FileCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        (v9/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesMultiSelectCellData_multiSelectCell",
                          "selections": [
                            (v9/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "MultiSelectData",
                              "kind": "LinkedField",
                              "name": "data",
                              "plural": false,
                              "selections": [
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "View",
                                  "kind": "LinkedField",
                                  "name": "sourceView",
                                  "plural": false,
                                  "selections": [
                                    (v0/*: any*/),
                                    {
                                      "alias": "viewType",
                                      "args": null,
                                      "concreteType": "ViewType",
                                      "kind": "LinkedField",
                                      "name": "type",
                                      "plural": false,
                                      "selections": [
                                        (v2/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                },
                                {
                                  "alias": null,
                                  "args": null,
                                  "concreteType": "SelectedRowInfo",
                                  "kind": "LinkedField",
                                  "name": "selectedRows",
                                  "plural": true,
                                  "selections": [
                                    {
                                      "alias": null,
                                      "args": null,
                                      "concreteType": "Row",
                                      "kind": "LinkedField",
                                      "name": "row",
                                      "plural": false,
                                      "selections": [
                                        (v4/*: any*/),
                                        (v0/*: any*/)
                                      ],
                                      "storageKey": null
                                    }
                                  ],
                                  "storageKey": null
                                }
                              ],
                              "storageKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "MultiSelectCell",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": [
                        (v6/*: any*/),
                        (v1/*: any*/),
                        (v10/*: any*/),
                        {
                          "kind": "InlineDataFragmentSpread",
                          "name": "SpacesLookupCellData_lookupCell",
                          "selections": [
                            (v10/*: any*/),
                            {
                              "alias": null,
                              "args": null,
                              "concreteType": "LookupCellData",
                              "kind": "LinkedField",
                              "name": "data",
                              "plural": false,
                              "selections": [
                                (v5/*: any*/)
                              ],
                              "storageKey": null
                            }
                          ],
                          "args": null,
                          "argumentDefinitions": []
                        }
                      ],
                      "type": "LookupCell",
                      "abstractKey": null
                    }
                  ],
                  "args": null,
                  "argumentDefinitions": []
                }
              ],
              "storageKey": null
            }
          ],
          "args": null,
          "argumentDefinitions": []
        }
      ],
      "storageKey": null
    }
  ],
  "type": "View",
  "abstractKey": null
};
})());

export {
  Types ,
  Internal ,
  Utils ,
  node ,
}
/* node Not a pure module */
