// Generated by ReScript, PLEASE EDIT WITH CARE

import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as SpacesNumberCell_numberCell_graphql from "../../../relay-codegen/generated/SpacesNumberCell_numberCell_graphql.bs.js";

var convertFragment = SpacesNumberCell_numberCell_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesNumberCell_numberCell_graphql.node, convertFragment, fRef);
}

function useOpt(fRef) {
  return RescriptRelay_Fragment.useFragmentOpt(fRef !== undefined ? Caml_option.some(Caml_option.valFromOption(fRef)) : undefined, SpacesNumberCell_numberCell_graphql.node, convertFragment);
}

function readInline(fRef) {
  return RescriptRelay_Fragment.readInlineData(SpacesNumberCell_numberCell_graphql.node, convertFragment, fRef);
}

var DataFragment = {
  Types: undefined,
  Operation: undefined,
  convertFragment: convertFragment,
  use: use,
  useOpt: useOpt,
  readInline: readInline
};

export {
  DataFragment ,
}
/* RescriptRelay_Fragment Not a pure module */
