// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as ReactNative from "react-native";
import * as RescriptFlashList from "../../../rescript-flash-list/src/RescriptFlashList.bs.js";

function scrollToEnd(ref) {
  setTimeout((function () {
          Core__Option.forEach(Caml_option.nullable_to_opt(ref.current), (function (ref) {
                  RescriptFlashList.scrollToEnd(ref, {
                        animated: true
                      });
                }));
        }), 200);
}

function scrollToStart(ref) {
  setTimeout((function () {
          Core__Option.forEach(Caml_option.nullable_to_opt(ref.current), (function (ref) {
                  RescriptFlashList.scrollToOffset(ref, {
                        animated: true,
                        offset: 0
                      });
                }));
        }), 200);
}

function useReverseScrollOnWeb(listRef) {
  React.useEffect((function () {
          if (ReactNative.Platform.OS !== "web") {
            return ;
          } else {
            return Core__Option.flatMap(Core__Option.flatMap(Caml_option.nullable_to_opt(listRef.current), (function (current) {
                              return current.getScrollableNode();
                            })), (function (scrollNode) {
                          var listener = scrollNode.addEventListener("wheel", (function (e) {
                                  scrollNode.scrollTop = scrollNode.scrollTop - e.deltaY | 0;
                                  return e.preventDefault();
                                }));
                          return (function () {
                                    scrollNode.removeEventListener("wheel", listener);
                                  });
                        }));
          }
        }), []);
}

var Utils = {
  scrollToEnd: scrollToEnd,
  scrollToStart: scrollToStart,
  useReverseScrollOnWeb: useReverseScrollOnWeb
};

var scrollToEnd$1 = RescriptFlashList.scrollToEnd;

var scrollToOffset = RescriptFlashList.scrollToOffset;

var ListHeaderComponent = RescriptFlashList.ListHeaderComponent;

var make = RescriptFlashList.make;

export {
  scrollToEnd$1 as scrollToEnd,
  scrollToOffset ,
  Utils ,
  ListHeaderComponent ,
  make ,
}
/* react Not a pure module */
