// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";

var context = React.createContext({
      state: undefined,
      setReadOnly: (function (param) {
          
        })
    });

function use() {
  return React.useContext(context);
}

var make = context.Provider;

var Provider = {
  make: make
};

function useReadOnly() {
  var match = React.useContext(context);
  return Core__Option.getOr(Core__Option.map(match.state, (function (param) {
                    return param.readOnly;
                  })), false);
}

export {
  context ,
  use ,
  Provider ,
  useReadOnly ,
}
/* context Not a pure module */
