// Generated by ReScript, PLEASE EDIT WITH CARE

import * as React from "react";
import * as Core__JSON from "@rescript/core/src/Core__JSON.bs.js";
import * as RelayUtils from "../../../../relay-utils/src/RelayUtils.bs.js";
import * as Auth__Hooks from "../../../../auth/src/hooks/Auth__Hooks.bs.js";
import * as Caml_option from "rescript/lib/es6/caml_option.js";
import * as Core__Option from "@rescript/core/src/Core__Option.bs.js";
import * as JsxRuntime from "react/jsx-runtime";
import * as DecidrErrorBoundary from "../../../../error-boundary/src/DecidrErrorBoundary.bs.js";
import * as Primitives__Popover from "../../../../primitives/src/popover/Primitives__Popover.bs.js";
import * as Primitives__Styling from "../../../../primitives/src/styling/Primitives__Styling.bs.js";
import * as RescriptRelay_Query from "rescript-relay/src/RescriptRelay_Query.bs.js";
import * as RescriptRelay_Fragment from "rescript-relay/src/RescriptRelay_Fragment.bs.js";
import * as ReactUtils__SimpleReact from "../../../../react-utils/src/simpleReact/ReactUtils__SimpleReact.bs.js";
import * as Spaces__Ui__ChannelButton from "../../ui/channelButton/Spaces__Ui__ChannelButton.bs.js";
import * as Spaces__Channel__Mutations from "../../channel/Spaces__Channel__Mutations.bs.js";
import * as ErrorHandling__UnexpectedError from "../../../../error-handling/src/ErrorHandling__UnexpectedError.bs.js";
import * as RescriptTamagui__Icon__MessageCircle from "../../../../rescript-tamagui/src/icons/RescriptTamagui__Icon__MessageCircle.bs.js";
import * as SpacesColumnViewChannelOpenerQuery_graphql from "../../../../relay-codegen/generated/SpacesColumnViewChannelOpenerQuery_graphql.bs.js";
import * as SpacesColumnViewChannelOpener_query_graphql from "../../../../relay-codegen/generated/SpacesColumnViewChannelOpener_query_graphql.bs.js";

function Spaces__ColumnView__ChannelOpener$ChannelButton(props) {
  var numberOfUnreadMessages = props.numberOfUnreadMessages;
  var __creatingChannel = props.creatingChannel;
  var __disabled = props.disabled;
  var onPress = props.onPress;
  var disabled = __disabled !== undefined ? __disabled : false;
  var creatingChannel = __creatingChannel !== undefined ? __creatingChannel : false;
  return JsxRuntime.jsx(Primitives__Popover.Item.make, {
              color: disabled || creatingChannel ? Primitives__Styling.color({
                      NAME: "icon",
                      VAL: {
                        NAME: "neutral",
                        VAL: "subdued"
                      }
                    }) : Core__Option.getOr(props.color, Primitives__Styling.color("neutral_1")),
              text: "Block Chat",
              icon: RescriptTamagui__Icon__MessageCircle.make,
              onPress: (function () {
                  if (disabled || creatingChannel) {
                    return ;
                  } else {
                    return onPress();
                  }
                }),
              children: Caml_option.some(numberOfUnreadMessages !== undefined && numberOfUnreadMessages !== 0 ? JsxRuntime.jsx(Spaces__Ui__ChannelButton.UnreadMessagesIndicator.make, {}) : null)
            });
}

var developerSettingsHideColumnViewChatKey = "developer_setting_hide_column_view_chats";

function getHideColumnViewChat(coreConfig) {
  return Core__Option.getOr(Core__Option.flatMap(Core__Option.flatMap(Core__Option.flatMap(coreConfig, Core__JSON.Decode.object), (function (__x) {
                        return __x[developerSettingsHideColumnViewChatKey];
                      })), Core__JSON.Decode.bool), false);
}

var convertFragment = SpacesColumnViewChannelOpener_query_graphql.Internal.convertFragment;

function use(fRef) {
  return RescriptRelay_Fragment.useFragment(SpacesColumnViewChannelOpener_query_graphql.node, convertFragment, fRef);
}

function Spaces__ColumnView__ChannelOpener$ChannelOpener(props) {
  var onChannelPress = props.onChannelPress;
  var organizationId = props.organizationId;
  var match = use(props.queryRef);
  var node = match.node;
  var match$1 = Spaces__Channel__Mutations.useCreateChannel();
  var creatingChannel = match$1[1];
  var createChannel = match$1[0];
  if (node !== undefined) {
    if (node.__typename === "ColumnView") {
      var channels = node.channels;
      if (channels.length !== 0) {
        var showColumnViewChat = !getHideColumnViewChat(node.view.coreConfig);
        var channel = channels[0];
        return ReactUtils__SimpleReact.whenTrue(showColumnViewChat, {
                    LAZY_DONE: false,
                    VAL: (function () {
                        return JsxRuntime.jsx(Spaces__ColumnView__ChannelOpener$ChannelButton, {
                                    onPress: (function () {
                                        onChannelPress(channel.id);
                                      }),
                                    numberOfUnreadMessages: channel.numberOfUnreadMessages
                                  });
                      })
                  });
      }
      var view = node.view;
      var name = node.name;
      var columnViewId = node.id;
      var showColumnViewChat$1 = !getHideColumnViewChat(view.coreConfig);
      return ReactUtils__SimpleReact.whenTrue(showColumnViewChat$1, {
                  LAZY_DONE: false,
                  VAL: (function () {
                      return JsxRuntime.jsx(Spaces__ColumnView__ChannelOpener$ChannelButton, {
                                  onPress: (function () {
                                      createChannel(organizationId, view.table.name + "/" + view.name + "/" + name, [], "ColumnViewResource", undefined, Caml_option.some(columnViewId), (function (channelId) {
                                              onChannelPress(channelId);
                                            }), undefined, (function (store, response) {
                                              RelayUtils.Helpers.addToArray(store, columnViewId, response.createChannel.channel.id, "channels");
                                            }));
                                    }),
                                  disabled: creatingChannel,
                                  creatingChannel: creatingChannel
                                });
                    })
                });
    }
    ErrorHandling__UnexpectedError.handle("Not a ColumnView", "Spaces__ColumnView__ChannelOpener ChannelOpener", {
          typename: node._0
        });
    return null;
  }
  ErrorHandling__UnexpectedError.handle("Not found", "Spaces__ColumnView__ChannelOpener ChannelOpener", undefined);
  return null;
}

var convertVariables = SpacesColumnViewChannelOpenerQuery_graphql.Internal.convertVariables;

var convertResponse = SpacesColumnViewChannelOpenerQuery_graphql.Internal.convertResponse;

var use$1 = RescriptRelay_Query.useQuery(convertVariables, SpacesColumnViewChannelOpenerQuery_graphql.node, convertResponse);

RescriptRelay_Query.useLoader(convertVariables, SpacesColumnViewChannelOpenerQuery_graphql.node, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.usePreloaded(SpacesColumnViewChannelOpenerQuery_graphql.node, convertResponse, (function (prim) {
        return prim;
      }));

RescriptRelay_Query.$$fetch(SpacesColumnViewChannelOpenerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.fetchPromised(SpacesColumnViewChannelOpenerQuery_graphql.node, convertResponse, convertVariables);

RescriptRelay_Query.retain(SpacesColumnViewChannelOpenerQuery_graphql.node, convertVariables);

function Spaces__ColumnView__ChannelOpener(props) {
  var match = Auth__Hooks.useAuth();
  var authStatus = match.authStatus;
  var isAuthenticated = React.useMemo((function () {
          return authStatus === "authenticated";
        }), [authStatus]);
  var match$1 = use$1({
        columnViewId: props.columnViewId,
        skipQuery: !isAuthenticated
      }, undefined, undefined, undefined);
  return JsxRuntime.jsx(DecidrErrorBoundary.make, {
              debugContext: "Spaces__ColumnView__ChannelOpener ChannelOpener",
              children: JsxRuntime.jsx(React.Suspense, {
                    children: Caml_option.some(JsxRuntime.jsx(Spaces__ColumnView__ChannelOpener$ChannelOpener, {
                              queryRef: match$1.fragmentRefs,
                              organizationId: props.organizationId,
                              onChannelPress: props.onChannelPress
                            })),
                    fallback: Caml_option.some(JsxRuntime.jsx(Spaces__ColumnView__ChannelOpener$ChannelButton, {
                              onPress: (function () {
                                  
                                }),
                              disabled: true
                            }))
                  }),
              FallbackComponent: (function (param) {
                  return null;
                })
            });
}

var developerSettingsHideColumnViewChatDefault = false;

var make = Spaces__ColumnView__ChannelOpener;

export {
  developerSettingsHideColumnViewChatKey ,
  developerSettingsHideColumnViewChatDefault ,
  make ,
}
/* use Not a pure module */
