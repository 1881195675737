import { ResourcesConfig, Amplify } from "aws-amplify";
import config from "../config/runtime-config.json";

type LibraryOptions = Parameters<typeof Amplify.configure>[1];

export const baseAmplifyConfig: ResourcesConfig = {
  API: {
    GraphQL: {
      endpoint: config.apiUrl,
      defaultAuthMode: "userPool",
    },
  },
  Auth: {
    Cognito: {
      // OPTIONAL - Amazon Cognito User Pool ID
      userPoolId: config.cognitoUserPoolId,
      // OPTIONAL - Amazon Cognito Web Client ID
      userPoolClientId: config.cognitoUserPoolWebClientId,
      // REQUIRED only for Federated Authentication - Amazon Cognito Identity Pool ID
      identityPoolId: config.cognitoIdentityPoolId,
      // REQUIRED - Amazon Cognito Region
      // region: config.cognitoRegion,
    },
  },
  Storage: {
    S3: {
      bucket: config.tempImageUploadS3Bucket,
      region: config.cognitoRegion,
    },
  },
};

export const defaultAmplifyLibraryOptions: LibraryOptions = {
  API: {
    GraphQL: {
      headers: () =>
        Promise.resolve({
          "x-decidr-auth": JSON.stringify({ organizationId: null }),
        }),
    },
  },
  Storage: {
    S3: {
      defaultAccessLevel: "private",
    },
  },
};
